import React from 'react';
import { AppSetupList, NoInternetConnection } from 'components';
import * as api from 'api';
import { columnsConfig } from './config';

export default function ReferralSourcesPage() {
  return (
    <NoInternetConnection>
      <AppSetupList
        btnLabel="btn.new.referral"
        columns={columnsConfig}
        title="referral.list.title"
        modalType="CREATE_REF_SOURCE"
        fetchData={api.fetchRefSources}
        placeholder={{
          imgName: 'refferalSources',
          titleLabel: 'state.referralSource.title',
          descriptionLabel: 'state.referralSource.description',
          btnLabel: 'state.referralSource.button',
        }}
      />
    </NoInternetConnection>
  );
}
