import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { IntlLabel, LabelKey } from '@plandok/i18n';

export default function Link(props: { to: any; label: LabelKey }) {
  return (
    <RouterLink to={props.to}>
      <IntlLabel label={props.label} />
    </RouterLink>
  );
}
