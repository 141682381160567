import { Spin } from 'antd';
import React from 'react';
import { Text } from '@plandok/core';
import { useUserQuery } from 'graphql/queries/userQuery.generated';
import * as SC from '../styles';

export default function HeaderUsage() {
  const { data: userData, loading: userLoading } = useUserQuery();

  const DEFAULT_NOTIFICATION_CHARGE = 0;

  const notificationCharge = userData?.user?.partner?.notificationCharge;
  const formattedCharge = `€${notificationCharge?.toFixed(2) || DEFAULT_NOTIFICATION_CHARGE}`;

  return (
    <Spin spinning={userLoading}>
      <SC.ContainerUsage>
        <Text size="base" label="header.usage" colorType="base" lh="small" weight="semiBold" />
        <Text size="base" mb="none" colorType="base" lh="small">
          {formattedCharge}
        </Text>
      </SC.ContainerUsage>
    </Spin>
  );
}
