import { IntlLabel } from '@plandok/i18n';
import { Icon } from 'antd';
import React from 'react';

import * as SC from '../styles';

export default function AddAppointment(props: any) {
  const addAppointment = () => {
    const lastElement = props.fields.value?.[props.fields.value?.length - 1];

    if (lastElement) {
      props.fields.push({
        employeeId: props.defaultEmployeeId,
        startTime: Math.min((lastElement.startTime || 0) + (lastElement.duration || 0), 1440),
      });
      return;
    }
    return props.fields.push({ employeeId: props.defaultEmployeeId });
  };
  return (
    <SC.AddItem onClick={props.active ? addAppointment : undefined} active={props.active} data-add-appointment-btn>
      <Icon type="plus-circle" theme="filled" /> <IntlLabel label="service.add.another" />
    </SC.AddItem>
  );
}
