import React from 'react';
import { IntlLabel } from '@plandok/i18n';
import { Text } from '@plandok/core';

export const appointmentsByStaffColumns = [
  {
    title: <IntlLabel label="table.appointmentsByStaff.staffMember" />,
    key: 'user',
    dataIndex: 'user',
    render: (value: string) => (
      <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
        {value}
      </Text>
    ),
  },
  {
    title: <IntlLabel label="table.appointmentsByStaff.appointments" />,
    key: 'totalCount',
    dataIndex: 'totalCount',
    render: (value: string) => (
      <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
        {value}
      </Text>
    ),
  },
  {
    title: <IntlLabel label="table.appointmentsByStaff.totalValue" />,
    key: 'totalPrice',
    dataIndex: 'totalPrice',
    render: (value: any) => {
      const totalPrice = JSON.parse(JSON.stringify(value));
      return (
        <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
          {totalPrice.amount} {totalPrice.currency}
        </Text>
      );
    },
  },
];

export const appointmentsByServiceColumns = [
  {
    title: <IntlLabel label="table.appointmentsByService.serviceName" />,
    key: 'name',
    dataIndex: 'name',
    render: (value: string) => (
      <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
        {value}
      </Text>
    ),
  },
  {
    title: <IntlLabel label="table.appointmentsByService.appointments" />,
    key: 'totalCount',
    dataIndex: 'totalCount',
    render: (value: string) => (
      <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
        {value}
      </Text>
    ),
  },
  {
    title: <IntlLabel label="table.appointmentsByService.totalValue" />,
    key: 'totalPrice',
    dataIndex: 'totalPrice',
    render: (value: any) => {
      const totalPrice = JSON.parse(JSON.stringify(value));
      return (
        <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
          {totalPrice.amount} {totalPrice.currency}
        </Text>
      );
    },
  },
];
