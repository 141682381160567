import React from 'react';
import { IntlLabel } from '@plandok/i18n';
import { MobileBackButton } from 'components';

export default function CalendarSettingsMobileHeader() {
  return (
    <>
      <MobileBackButton />
      <div>
        <IntlLabel label="calendar.settings.title" />
      </div>
      <div />
    </>
  );
}
