import React from 'react';
import { IntlLabel } from '@plandok/i18n';
import { useModal } from '@plandok/core';
import { MenuItem } from 'components';
import SidebarIcons from './SidebarIcons';

export type ButtonProps = {
  collapsed: boolean;
  isFixed: boolean;
  setIsVisibleUpdateBtn?: (value: boolean) => void;
  setIsVisibleUpdateNotification?: (value: boolean) => void;
};

export default function SupportButton({ collapsed, isFixed, ...props }: ButtonProps) {
  const [, { showModal }] = useModal();

  const openHelpModal = () => showModal({ type: 'HELP_MODAL' });

  return (
    <MenuItem
      {...props}
      style={{
        position: isFixed ? 'fixed' : 'inherit',
        width: isFixed ? '111px' : '108px',
        bottom: '10px',
      }}
      title={<IntlLabel label="sidebar.help" />}
      siderCollapsed={collapsed}
      onClick={openHelpModal}
    >
      <div data-sidebar-support-icon>
        <i>{SidebarIcons.support}</i>
        <span>
          <IntlLabel label="sidebar.help" />
        </span>
      </div>
    </MenuItem>
  );
}
