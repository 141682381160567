import { IntlTemplate } from '@plandok/i18n';
import { PaymentDataType } from 'pages/plans/PaymentConfirmationPage/index';
import * as SC from 'pages/plans/UpdateSubscriptionPage/styles';
import React from 'react';
import { Text } from '@plandok/core';

function PaymentSummaryComponent({ paymentData }: { paymentData?: PaymentDataType }) {
  return (
    <SC.SummaryCardContainer>
      <Text label="reports.summary.title" weight="semiBold" className="summary" />
      <SC.ServiceItem>
        <div>
          <Text
            colorType="base"
            size="medium"
            weight="semiBold"
            lh="mlarge"
            label={
              paymentData?.paymentType === 'notification_charge'
                ? 'payment.tab.title.notification'
                : 'payment.tab.title.sub'
            }
          />
          <Text colorType="base" mb="none">
            {paymentData?.description}
          </Text>
        </div>
        <Text colorType="base" mb="none">
          {`${parseFloat(paymentData?.price?.priceTaxExcl ?? '0').toFixed(2) || 0}€`}
        </Text>
      </SC.ServiceItem>
      <SC.ContainerTotal>
        <div>
          <Text mb="none" size="large" lh="xxlarge">
            <IntlTemplate label="appointment.vat" params={[paymentData?.price.tax ?? 0]} />
          </Text>
          <Text lh="xxlarge" size="large" colorType="base" mb="none" ml="large">
            {`${parseFloat(paymentData?.price?.taxAmount ?? '0').toFixed(2) || 0}€`}
          </Text>
        </div>
        <SC.Total>
          <Text size="large" lh="xxlarge" colorType="base" mb="none" label="appointment.total" />
          <Text lh="xxlarge" size="large" weight="semiBold" colorType="base" mb="none" ml="large">
            {`${parseFloat(paymentData?.price?.priceTaxIncl ?? '0').toFixed(2) || 0}€`}
          </Text>
        </SC.Total>
      </SC.ContainerTotal>
    </SC.SummaryCardContainer>
  );
}

export default PaymentSummaryComponent;
