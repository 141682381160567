import React, { memo, useContext } from 'react';
import axios from 'axios';
import { ReactIntl } from '@plandok/i18n';
import { AuthLayout, NoInternetConnection } from 'components';
import { LANGUAGE_HEADER } from 'constants/api';
import { RoutePath } from 'constants/routes';
import { useLocation } from 'hooks/router';
import * as api from 'api';
import RegisterFooter from './components/RegisterFooter';
import RegisterForm from './components/RegisterForm';
import { useOnLogin } from './useOnLogin';
import * as helpers from './helpers';

function RegistrationPage() {
  const { location } = useLocation();
  const { lang } = useContext(ReactIntl);
  const onRegisterSuccess = useOnLogin();

  const onRegister = (form: any) => {
    axios.defaults.headers[LANGUAGE_HEADER] = helpers.extractLanguageFromUrl(location);

    return api.register({ ...form, employeeLanguageIsoCode: lang, companyTimeFormat: '24h' }).then((res) => {
      if (res?.errors) {
        return res;
      }

      return api.login({ email: form.employeeEmail, password: form.employeePassword });
    });
  };

  return (
    <NoInternetConnection>
      <AuthLayout
        title="register.title"
        mobileDescription="register.description"
        page={RoutePath.REGISTRATION}
        isRegistration
      >
        <AuthLayout.Card>
          <RegisterForm onSubmit={onRegister} onSuccess={onRegisterSuccess} />
        </AuthLayout.Card>
        <br />
        <RegisterFooter />
      </AuthLayout>
    </NoInternetConnection>
  );
}

export default memo(RegistrationPage);
