import React from 'react';

import { IntlLabel } from '@plandok/i18n';
import IconEmptyClientList from './IconEmptyClientList';
import * as SC from '../styles';

export default function EmptyListHolder() {
  return (
    <SC.EmptyContainer isPadding>
      <i>
        <IconEmptyClientList />
      </i>
      <div>
        <IntlLabel label="appointment.empty.list" />
      </div>
    </SC.EmptyContainer>
  );
}
