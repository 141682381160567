import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import React, { useEffect, useState } from 'react';
import { message, Spin } from 'antd';
import slugify from 'react-slugify';
import { pick } from 'lodash';
import {
  Form,
  useLocation,
  Text,
  composeValidators,
  validateEmail,
  validateRequired,
  Field,
  Button,
} from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import {
  appointmentChangeTimeSettingOptions,
  appointmentBookingsLimitOptions,
  earliestAppointmentSettingOptions,
  latestAppointmentSettingOptions,
  timeSlotIntervalSettingOptions,
} from 'constants/data';
import { useOnlineBookingSettingsQuery } from 'graphql/queries/onlineBookingSettings.generated';
import { mutate, mutation } from '../../../../graphql';
import { BookingSettingsForm } from './types';
import * as SC from './styles';

type FieldType = 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL' | 'PHONE_NUMBER';

const getOptionsByField = (field: FieldType) => [
  {
    value: field,
    label: <Text colorType="base" weight="normal" size="small" mb="none" label="ob.settings.requiredField.title" />,
  },
  {
    value: '',
    label: <Text colorType="base" weight="normal" size="small" mb="none" label="ob.settings.optionalField.title" />,
  },
];

const getSingleOptionByField = (field: FieldType) =>
  getOptionsByField(field).slice(0, getOptionsByField(field).length - 1);

export default function BookingSettings() {
  const { loading, data } = useOnlineBookingSettingsQuery();
  const { goBack } = useLocation();

  const firstName = 'FIRST_NAME';
  const serviceLimit = data?.onlineBookingSettings?.serviceLimit;

  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const changeLastName = ({ target: { value } }: RadioChangeEvent) => setLastName(value);
  const changePhoneNumber = ({ target: { value } }: RadioChangeEvent) => setPhoneNumber(value);
  const changeEmail = ({ target: { value } }: RadioChangeEvent) => setEmail(value);

  const onSubmit = (form: BookingSettingsForm) => {
    const onlineRequiredField = [firstName, lastName, email, phoneNumber].filter(Boolean);

    return mutate(mutation.UPDATE_BOOKING_SETTINGS)({
      ...pick(
        form,
        'earliestAppointment',
        'latestAppointment',
        'timeSlotInterval',
        'allowEmployeeSelection',
        'importantInformation',
        'appointmentChangeTime',
        'notifyBookedEmployee',
        'notifySpecificEmail',
        'notificationSpecificEmail',
        'partnerSlug',
        'serviceLimit'
      ),
      onlineRequiredField,
    });
  };

  const onSuccess = () => message.success(<IntlLabel label="notification.success.update" />);

  useEffect(() => {
    const onlineRequiredField = data?.onlineBookingSettings?.onlineRequiredField;

    if (onlineRequiredField) {
      const setField = (fieldName: string) => onlineRequiredField.find((field: string) => field === fieldName) || '';

      setLastName(setField('LAST_NAME'));
      setEmail(setField('EMAIL'));
      setPhoneNumber(setField('PHONE_NUMBER'));
    }
  }, [data]);

  return (
    <Spin spinning={loading}>
      <SC.Container>
        <Form
          onSubmit={onSubmit}
          onSuccess={onSuccess}
          initialValues={{ ...data?.onlineBookingSettings, serviceLimit: serviceLimit || 0 }}
        >
          {({ submitting, values }: any) => (
            <>
              <SC.FormInfoContainer>
                <Text label="ob.settings.booking.title" weight="semiBold" size="large" mb="small" />
                <Text label="ob.settings.booking.description" size="small" mb="none" color="#61749D" />
              </SC.FormInfoContainer>
              <SC.Card>
                <Field.Select
                  name="earliestAppointment"
                  label="input.earliestAppointment.label"
                  placeholder="input.earliestAppointment.placeholder"
                  options={earliestAppointmentSettingOptions}
                  translate
                />
                <Field.Select
                  name="latestAppointment"
                  label="input.latestAppointment.label"
                  placeholder="input.latestAppointment.placeholder"
                  options={latestAppointmentSettingOptions}
                  translate
                />
                <Field.Select
                  name="timeSlotInterval"
                  label="input.timeSlots.label"
                  placeholder="input.timeSlots.placeholder"
                  options={timeSlotIntervalSettingOptions}
                  translate
                />
                <Field.Checkbox name="allowEmployeeSelection" textKey="checkbox.allowCustomers" type="checkbox" />
                <Field.TextArea
                  name="importantInformation"
                  label="input.importantInfo.label"
                  placeholder="input.importantInfo.placeholder"
                />
              </SC.Card>

              <SC.FormInfoContainer>
                <Text label="ob.settings.cancellation.title" weight="semiBold" size="large" mb="small" />
                <Text label="ob.settings.cancellation.description" size="small" mb="none" color="#61749D" />
              </SC.FormInfoContainer>
              <SC.Card>
                <Field.Select
                  name="appointmentChangeTime"
                  label="input.changeAppointment.label"
                  placeholder="input.changeAppointment.placeholder"
                  options={appointmentChangeTimeSettingOptions}
                  translate
                />
              </SC.Card>

              <SC.FormInfoContainer>
                <Text label="ob.settings.required.title" weight="semiBold" size="large" mb="small" />
                <Text label="ob.settings.required.description" size="small" mb="none" color="#61749D" />
              </SC.FormInfoContainer>
              <SC.Card>
                <SC.RadioRow>
                  <SC.RadioTitle
                    colorType="base"
                    weight="semiBold"
                    size="small"
                    mb="none"
                    label="ob.settings.nameField.title"
                  />

                  <Radio.Group options={getSingleOptionByField('FIRST_NAME')} value={firstName} />
                </SC.RadioRow>

                <SC.RadioRow>
                  <SC.RadioTitle
                    colorType="base"
                    weight="semiBold"
                    size="small"
                    mb="none"
                    label="ob.settings.lastNameField.title"
                  />

                  <Radio.Group onChange={changeLastName} options={getOptionsByField('LAST_NAME')} value={lastName} />
                </SC.RadioRow>

                <SC.RadioRow>
                  <SC.RadioTitle
                    colorType="base"
                    weight="semiBold"
                    size="small"
                    mb="none"
                    label="ob.settings.phoneField.title"
                  />

                  <Radio.Group
                    onChange={changePhoneNumber}
                    options={getOptionsByField('PHONE_NUMBER')}
                    value={phoneNumber}
                  />
                </SC.RadioRow>

                <SC.RadioRow>
                  <SC.RadioTitle
                    colorType="base"
                    weight="semiBold"
                    size="small"
                    mb="none"
                    label="ob.settings.emailField.title"
                  />

                  <Radio.Group onChange={changeEmail} options={getOptionsByField('EMAIL')} value={email} />
                </SC.RadioRow>
              </SC.Card>

              <SC.FormInfoContainer>
                <Text label="ob.bookingLimit.title" weight="semiBold" size="large" mb="small" />
                <Text label="ob.bookingLimit.description" size="small" mb="none" color="#61749D" />
              </SC.FormInfoContainer>
              <SC.Card>
                <Field.Select
                  name="serviceLimit"
                  label="ob.bookingLimit.label"
                  placeholder="ob.bookingLimit.unlimited.title"
                  options={appointmentBookingsLimitOptions}
                  translate
                />
              </SC.Card>

              <SC.FormInfoContainer>
                <Text label="ob.settings.notifications.title" weight="semiBold" size="large" mb="small" />
                <Text label="ob.settings.notifications.description" size="small" mb="none" color="#61749D" />
              </SC.FormInfoContainer>
              <SC.Card style={{ paddingTop: '10px' }} className="notification-emails">
                <Field.Checkbox name="notifyBookedEmployee" textKey="checkbox.sendToBooked" type="checkbox" />
                <Field.Checkbox name="notifySpecificEmail" textKey="checkbox.sendSpecificEmail" type="checkbox" />
                {values.notifySpecificEmail ? (
                  <Field.Input
                    name="notificationSpecificEmail"
                    label="input.specificEmail.label"
                    placeholder="input.specificEmail.placeholder"
                    validate={composeValidators(validateRequired, validateEmail)}
                  />
                ) : (
                  <Field.Input
                    name="notificationSpecificEmail"
                    label="input.specificEmail.label"
                    placeholder="input.specificEmail.placeholder"
                    validate={validateEmail}
                  />
                )}
              </SC.Card>

              <SC.FormInfoContainer>
                <Text label="ob.settings.locationsUrl.title" weight="semiBold" size="large" mb="small" />
                <Text label="ob.settings.locationsUrl.description" size="small" mb="none" color="#61749D" />
              </SC.FormInfoContainer>
              <SC.Card style={{ paddingTop: '10px' }}>
                <Field.Input
                  name="partnerSlug"
                  label="input.locationsUrl.label"
                  placeholder="input.locationsUrl.placeholder"
                />
                <IntlLabel label={`https://book.plandok.com/partner/${slugify(values.partnerSlug)}`} />
              </SC.Card>
              <SC.ActionButtons>
                <Button label="btn.cancel" type="primary" ghost upperCase={false} onClick={goBack} />
                <Button label="btn.save" type="primary" htmlType="submit" upperCase={false} loading={submitting} />
              </SC.ActionButtons>
            </>
          )}
        </Form>
      </SC.Container>
    </Spin>
  );
}
