import styled from 'styled-components';
import { Card } from '@plandok/core';

export const MainInfo = styled(Card.Title)`
  display: flex;
  flex-direction: column;
  align-items: center;

  [data-client-info-avatar] {
    height: 70px;
    width: 70px;
    line-height: 70px;
    font-size: 30px;
    margin-bottom: 15px;
  }
  [data-client-info-name] {
    font-size: 28px;
    text-align: center;
    color: ${({ theme }) => theme.primaryColor};
    margin-bottom: 15px;
  }
`;

export const ItemList = styled.ul`
  padding: 0;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    [data-info-item-icon] {
      color: #adb6cc;
      font-size: 20px;
      margin-right: 20px;
    }
    [data-info-item-text] {
      line-height: 20px;
      div:first-of-type {
        font-weight: 500;
        color: ${({ theme }) => theme.primaryColor};
      }
      div:nth-of-type(2) {
        color: #adb6cc;
        font-size: 14px;
      }
    }
  }
  li:first-child {
    [data-info-item-text] {
      div:nth-of-type(2) {
        color: #53a9ff;
      }
    }
  }
`;
