import styled, { css } from 'styled-components';
import { mediaMobileDown } from '@plandok/core';
import { HashTabs } from 'components';

export const HashTabsWrapper = styled(HashTabs)`
  overflow: inherit;
  ${mediaMobileDown(css`
    .ant-tabs-bar {
      display: none;
    }
  `)}
`;
