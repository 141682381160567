import React from 'react';

import * as RSH from 'react-sortable-hoc';

import TableRow from './TableRow';

function TableBody(props: any) {
  return (
    <tbody className="ant-table-tbody">
      {(props.data || []).map((value: any, i: number) => {
        const onItemClick = () => (props.onItemClick || console.info)(value.id);
        return (
          <TableRow key={`${value.id}-${i}`} value={value} columns={props.columns} index={i} onClick={onItemClick} />
        );
      })}
    </tbody>
  );
}

export default RSH.SortableContainer(TableBody);
