import styled, { css } from 'styled-components';
import { Button, Card, mediaLgDown, Text } from '@plandok/core';
import { PriceType } from 'pages/plans/BillingPage/PricingPage/constants';

export const PlansPageContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  min-height: 100vh;
  padding: 100px 16px;

  ${mediaLgDown(css`
    align-items: center;
    flex-direction: column;
  `)}
`;

export const PricingItem = styled(Card)`
  max-width: ${({ theme }) => (theme.planType === PriceType.FREE ? 'max-content' : '440px')};
  margin-top: ${({ theme }) => (theme.planType === PriceType.FREE && !theme.active ? '140px' : '0')};
  margin-right: ${({ theme }) => theme.planType === PriceType.FREE && '32px'};
  border: ${({ theme }) => (theme.active ? '1px solid #2472E8' : 'none')};
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  padding: 64px 45px 48px 48px;
  border-radius: 20px;

  ${mediaLgDown(css`
    width: 343px;
    padding: 40px 32px 32px 24px;
    text-align: center;
    margin-right: ${({ theme }) => theme.planType === PriceType.FREE && '0'};
    &:first-child {
      margin-top: ${({ theme }) => (theme.planType === PriceType.FREE ? '0' : '32px')};
    }
    &:last-child {
      margin-top: ${({ theme }) => theme.plansPage && '32px'};
    }
  `)}
`;

export const PricingInfoContainer = styled.div`
  border-bottom: 1px solid #e8e8e8;
`;

export const PlanName = styled(Text)`
  margin-bottom: ${({ theme }) => (theme.plansPage && theme.planType !== PriceType.FREE ? '25px' : '4px')};
  line-height: 28px;
`;

export const WrapperPrice = styled.div`
  margin-bottom: ${({ theme }) => (theme.plansPage ? '20px' : '4px')};
  display: flex;
  div:nth-child(2) {
    margin-top: 40px;
    ${mediaLgDown(css`
      margin-top: 28px;
    `)}
  }
  ${mediaLgDown(css`
    margin-bottom: 12px;
  `)}
`;

export const PriceDescriptionInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  line-height: 21px;

  div {
    span:last-child {
      border-bottom: 1px solid #2472e8;
    }
  }

  :hover {
    cursor: pointer;
  }
  ${mediaLgDown(css`
    margin-bottom: 16px;
  `)}
`;

export const StaffCountInfo = styled(Text)`
  line-height: 21px;
`;

export const Price = styled(Text)`
  font-size: 60px;
  line-height: 70px;
  ${mediaLgDown(css`
    font-size: 48px;
    line-height: 56px;
  `)}
`;

export const CostInfo = styled(Text)`
  color: #7e8fb0;
  line-height: 23px;
  text-align: start;
`;

export const ContainerFeatures = styled.div`
  padding-top: ${({ theme }) => (theme.planType === PriceType.FREE ? '32px' : '24px')};
  padding-bottom: ${({ theme }) => (theme.planType === PriceType.FREE ? '42px' : '13px')};
  ${mediaLgDown(css`
    padding-top: ${({ theme }) => (theme.planType === PriceType.FREE ? '32px' : '32px')};
    padding-bottom: ${({ theme }) => (theme.planType === PriceType.FREE ? '0' : '13px')};
  `)}
`;

export const ItemFeature = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  line-height: 21px;
  text-align: start;
  div:first-of-type {
    margin-right: ${({ theme }) => (theme.planType === PriceType.FREE ? '18px' : '20px')};
    svg {
      stroke: #2472e8;
    }
  }
`;

export const BtnUpgrade = styled(Button)`
  padding: 11px 53px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  background: #2472e8;
  ${mediaLgDown(css`
    padding: 10px 63px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
  `)}
`;

export const BtnContainer = styled.div`
  padding-bottom: 26px;
`;

export const BtnCancelSubscription = styled(Button)`
  padding: 11px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  ${mediaLgDown(css`
    padding: 11px 30px;
    font-weight: 600;
  `)}
`;

export const ContainerCounter = styled.div`
  svg {
    cursor: pointer;
  }
  ${mediaLgDown(css`
    div {
      justify-content: center;
    }
  `)}
`;

export const InputCalc = styled.input`
  font-size: 20px;
  border: 1px solid #adb6cc;
  border-radius: 5px;
  margin: 0 8px;
  width: 64px;
  height: 36px;
  text-align: center;
  padding: 0;
  color: #13316d;
`;
