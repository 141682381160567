import { Divider, Icon } from 'antd';
import React from 'react';
import { ContentLong, Card } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { useUserQuery } from 'graphql/queries/userQuery.generated';
import { AppointmentItem } from 'components';
import { IClientDetails } from 'types/api';
import * as SC from './styles';

type ClientAppointmentsBlockProps = {
  data: IClientDetails;
  pathName?: string;
  timeFormat: string;
};

export default function ClientAppointmentsBlock({ data, pathName, timeFormat }: ClientAppointmentsBlockProps) {
  const showUpcoming = !!data.upcomingBookings?.bookingsCount;
  const showPast = !!data.pastBookings?.bookingsCount;
  const backPath = pathName;

  const { data: userData } = useUserQuery();
  const isPremium = userData?.user?.partner?.subscription.premium ?? false;

  const renderPrintHeader = (
    <SC.PrintHeader>
      <SC.PrintHeaderUsername>
        {data.profile.firstName || ''} {data.profile.lastName || ''}
      </SC.PrintHeaderUsername>
      <SC.PrintHeaderContactRow>{data.profile.phoneNumber || ''}</SC.PrintHeaderContactRow>
      <SC.PrintHeaderContactRow>{data.profile.email || ''}</SC.PrintHeaderContactRow>
    </SC.PrintHeader>
  );

  return (
    <SC.Container as={Card}>
      <SC.CalendarTitle as={Card.Title}>
        <Icon type="calendar" />
        <span>
          <IntlLabel label="appointment.list.title" />
        </span>
      </SC.CalendarTitle>
      {showUpcoming && (
        <>
          <ContentLong
            visibleCount={3}
            defaultVisibility
            isPremium={isPremium}
            title={
              <>
                {renderPrintHeader}
                <IntlLabel label="upcoming.list.title" /> ({data.upcomingBookings?.bookingsCount})
              </>
            }
          >
            {data.upcomingBookings.bookings?.map((booking) => (
              <AppointmentItem
                noBorder
                noPadding
                key={booking.bookingId}
                value={booking}
                redirectOnClick
                backPath={backPath}
                timeFormat={timeFormat}
              />
            ))}
          </ContentLong>
          <Divider />
        </>
      )}
      {showPast && (
        <ContentLong
          isPremium={isPremium}
          title={
            <>
              {renderPrintHeader}
              <IntlLabel label="past.list.title" /> ({data.pastBookings?.bookingsCount})
            </>
          }
        >
          {data.pastBookings.bookings?.map((booking) => (
            <AppointmentItem
              noBorder
              noPadding
              key={booking.bookingId}
              value={booking}
              redirectOnClick
              backPath={backPath}
              timeFormat={timeFormat}
            />
          ))}
        </ContentLong>
      )}
    </SC.Container>
  );
}
