import { flow, noop } from 'lodash';
import React from 'react';
import { Button, CustomIcon, Form, Text, useLocation, withModal } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { RoutePath } from 'constants/routes';
import { NotReleased } from 'components';
import * as api from 'api';
import * as SC from '../styles';

function CampaignUpgradeModal(props: any) {
  const { navigate } = useLocation();

  const upgrade = () => {
    navigate(RoutePath.BILLING);
    props.onClose();
  };

  return (
    <NotReleased>
      <Form
        onSubmit={(form: any) => api.closeAppointment(props.modalProps.Number, form)}
        onSuccess={flow(props.onClose, props.modalProps?.onSuccess || noop)}
      >
        {({ submitting }: any) => (
          <SC.CampaignUpgradeContainer>
            <CustomIcon type="premiumIcon" />
            <Text label="Ready for an upgrade?" size="mlarge" weight="semiBold" mb="medium" />
            <Text size="medium" mb="large">
              <IntlLabel label="Auto-sending Campaigns is for Premium users only. Please upgrade to premium version in order to use this feature. " />
            </Text>
            <Button type="primary" loading={submitting} onClick={upgrade} upperCase={false}>
              <Text size="base" colorType="white" mb="none">
                <IntlLabel label="Upgrade Now!" />
              </Text>
            </Button>
            <Button loading={submitting} onClick={props.onClose} upperCase={false}>
              <Text size="base" colorType="base" mb="none">
                <IntlLabel label="app.skip.button.title" />
              </Text>
            </Button>
          </SC.CampaignUpgradeContainer>
        )}
      </Form>
    </NotReleased>
  );
}

export default withModal(null, { contentClassName: 'upgrade-modal-content' })(CampaignUpgradeModal);
