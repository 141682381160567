import React, { useState } from 'react';
import { Checkbox, Icon } from 'antd';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import {
  useModal,
  winBackClientOption,
  getIsMobile,
  useLocation,
  CustomIcon,
  Form,
  Text,
  Field,
  Button,
} from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { ModifyMassLayout } from 'components';
import { RoutePath } from 'constants/routes';
import * as SC from '../styles';

export default function WinBackClientForm(props: any) {
  const isMobile = getIsMobile();
  const [stateSMS, setStateSMS] = useState(true);
  const [stateEmail, setStateEmail] = useState(true);
  const { navigate } = useLocation();
  const [, { showModal }] = useModal();

  const editMessagePage = () => navigate(RoutePath.CAMPAIGN_SETUP);
  const editSelection = () => {
    showModal({
      type: 'EDIT_SERVICE_SELECTION_MODAL',
      modalProps: {},
    });
  };
  const handleSubmit = (form: any) =>
    props.onSubmit({
      ...form,
      price: form.price || 0,
    });

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        onSuccess={flow(props.onSuccess, props.modalProps?.onSuccess || noop)}
        initialValues={props.initialValues}
      >
        {({ formError, submitting }: any) => {
          return (
            <>
              <SC.HeaderText>
                <CustomIcon type="clientAttract" />
                <Text size={isMobile ? 'medium' : 'mlarge'} weight="semiBold" mb="small">
                  <IntlLabel label="Win Back Clients" />
                </Text>
                <Text size={isMobile ? 'small' : 'base'}>
                  <IntlLabel label="This campaign sends to clients who did not return after a certain period of time." />
                </Text>
              </SC.HeaderText>
              <SC.CardContainer>
                <SC.Text>
                  <Text label="Audience" size="mlarge" weight="semiBold" />
                </SC.Text>
                <Field.Row>
                  <Field.Select
                    name="win"
                    label="Clients who didn’t return in the last"
                    options={winBackClientOption}
                    defaultValue="365"
                    md={15}
                  />
                  <SC.DayItem>
                    <Text label="days" size={isMobile ? 'small' : 'base'} mb="none" />
                  </SC.DayItem>
                </Field.Row>
                <Text size="mlarge" weight="semiBold" mb="none">
                  <IntlLabel label="Channel" />
                </Text>
                <Text size={isMobile ? 'small' : 'base'} mb="small">
                  <IntlLabel label="Choose what channels campaign will be sent" />
                </Text>
                <SC.CheckedBtn>
                  <Checkbox checked={stateSMS} onClick={() => setStateSMS(!stateSMS)}>
                    <span>
                      <IntlLabel label="SMS" />
                    </span>
                  </Checkbox>
                  <Checkbox checked={stateEmail} onClick={() => setStateEmail(!stateEmail)}>
                    <span>
                      <IntlLabel label="Email" />
                    </span>
                  </Checkbox>
                </SC.CheckedBtn>
                <Text size="mlarge" weight="semiBold" mb="none">
                  <IntlLabel label="Services" />
                </Text>
                <SC.CampaignsServiceGroup>
                  <Text size={isMobile ? 'small' : 'base'} mb="none">
                    <IntlLabel label="Applied to " />
                    <strong>
                      <IntlLabel label=" all " />
                    </strong>
                    <IntlLabel label=" services" />
                  </Text>
                  <SC.CampaignsCardBtn onClick={editSelection}>
                    <CustomIcon type="edit" />
                    <IntlLabel label="Edit Selection" />
                  </SC.CampaignsCardBtn>
                </SC.CampaignsServiceGroup>
              </SC.CardContainer>
              <Form.Alert text={formError} />
              <ModifyMassLayout.Footer>
                <Button
                  block
                  type="primary"
                  label="Edit message"
                  htmlType="submit"
                  loading={submitting}
                  upperCase={false}
                  onClick={editMessagePage}
                >
                  <Icon type="arrow-right" style={{ marginLeft: '10px' }} />
                </Button>
              </ModifyMassLayout.Footer>
            </>
          );
        }}
      </Form>
    </>
  );
}
