import { mediaSmDown } from '../../../../../helpers/styles';
import styled, { css } from 'styled-components';

export const Preview = styled.div`
  min-width: 300px;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  font-size: 18px;
  line-height: 30px;
  color: #13316d;
  word-break: break-all;
  white-space: pre-wrap;

  ${mediaSmDown(css`
    min-width: 270px;
    font-size: 16px;
    margin: -25px auto 0 auto;
  `)}
`;
