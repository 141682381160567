import axios from 'axios';
import React from 'react';
// @ts-ignore
import useGlobalHook from 'use-global-hook';

import LocalStorage from '../../api/axios/localStorage';

const initialState = {
  displayName: '',
  currencyIsoCode: 'EUR',
  languageIsoCode: 'en',
  country: 'BY',
  locale: 'en-ZW',
  isAuthenticated: false,
  isLoading: false,
  timeZone: '',
};

const setInitialState = (obj: any) => LocalStorage.setAppContext(obj);

const getInitialState = () => {
  const context = LocalStorage.getAppContext();
  try {
    if (context) {
      axios.defaults.headers['Accept-Language'] = (context || ({} as any)).languageIsoCode || 'en';
      return { ...context, isAuthenticated: true, isLoading: false };
    }
    return { ...initialState, isAuthenticated: false, isLoading: false };
  } catch (e) {
    return { ...initialState, isAuthenticated: false, isLoading: false };
  }
};

const actions = {
  setAppContext: (store: any, state: any) => {
    setInitialState({ ...state });
    store.setState({ ...state, isAuthenticated: true });
  },
  setTimeZone: (store: any, timeZone: string) => {
    const nextState = { ...store.state, timeZone };
    setInitialState(nextState);
    store.setState(nextState);
  },
  updateCompanyDetails: (store: any, { country, timeZone }: any) => {
    const nextState = { ...store.state, country, timeZone };
    setInitialState(nextState);
    store.setState(nextState);
  },
  setDisplayName: (store: any, displayName: string) => {
    const nextState = { ...store.state, displayName };
    setInitialState(nextState);
    store.setState(nextState);
  },
  resetAppContext: (store: any) => {
    LocalStorage.clearAll();
    store.setState(initialState);
  },
  changeLanguage: (store: any, state: any) => {
    store.setState({ ...store.state, languageIsoCode: state });
  },
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useAppContext = useGlobalHook(React, getInitialState(), actions);
