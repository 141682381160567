import styled, { css } from 'styled-components';
import { mediaMobileDown } from '@plandok/core';

const SBox = styled.div<any>`
  background: white;
  border: ${(p) => p.theme.boxBorderDecoration};

  ${mediaMobileDown(css`
    border: none;
  `)}
` as any;

export default SBox;
