import { Field as FinalField } from 'react-final-form';
import React, { useState } from 'react';
import { flow, noop } from 'lodash';
import { Col } from 'antd';
import {
  CustomIcon,
  useLocation,
  useModal,
  withModal,
  validateRequired,
  Form,
  Text,
  Field,
  Button,
} from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { repeatEndsAfterIntervals, repeatFrequencyIntervals } from 'constants/data';
import { dateFieldFormatterProps } from 'helpers/date/field';
import { AppointmentSpecificDate } from 'components';
import { RoutePath } from 'constants/routes';
import { useStore } from 'store/store';
import { AppRepeatEndsAfterIntervals, AppRepeatFrequencyIntervals } from '../../../../graphql';
import * as SC from '../styles';
import '../styles/styles.less';

function RepeatAppointmentModal({
  modalProps: {
    onExit,
    onSuccess,
    frequency,
    repeatingValue,
    hasRepeat,
    isPremium,
    openPremium,
    recurringOptions,
    initialDate,
  },
}: any) {
  const [, { hideModal }] = useModal();
  const { navigate } = useLocation();
  const [hasSpecificDate, setHasSpecificDate] = useState(false);
  const [hasRepeatingField, setHasRepeatingField] = useState(false);

  const setFrequency = useStore((state) => state.setFrequency);
  const setRepeatingValue = useStore((state) => state.setRepeatingValue);
  const setSpecificDate = useStore((state) => state.setSpecificDate);
  const specificDate = useStore((state) => state.specificDate);

  const defaultSelectedFrequency =
    repeatFrequencyIntervals.find((interval) => interval.name === frequency)?.code ?? recurringOptions?.frequency;

  const getDefaultRepeatValue = () => {
    if (specificDate) {
      return AppRepeatEndsAfterIntervals.UNTIL_DATE;
    }
    if (recurringOptions?.repeating_value) {
      return recurringOptions?.repeating_value;
    }
    if (!recurringOptions)
      return (
        repeatEndsAfterIntervals.find((repeat) => repeat.name === repeatingValue)?.code ??
        repeatEndsAfterIntervals[0].code
      );

    return repeatEndsAfterIntervals[0].code;
  };

  const onCloseUpgradeToPremiumModal = () => hideModal('REPEAT_APPOINTMENT_MODAL');

  const onRepeatValueChange = (value: string) => setHasSpecificDate(value === 'UNTIL_DATE');

  const goToPricingPage = () => {
    onCloseUpgradeToPremiumModal();
    navigate(RoutePath.BILLING);
  };

  const success = () => {
    if (hasRepeatingField) {
      onSuccess();
    }
    onExit();
  };

  return (
    <Form
      onSubmit={async ({ frequency: formFrequency, repeatingValue: formRepeatingValue, specificDate }: any) => {
        if (!isPremium && hasRepeatingField) {
          openPremium();
        }

        if (isPremium) {
          setFrequency(formFrequency);
          setRepeatingValue(hasSpecificDate ? specificDate : formRepeatingValue);
          setSpecificDate(hasSpecificDate ? specificDate : '');
        }
      }}
      onSuccess={flow(!isPremium && hasRepeatingField ? noop : success)}
    >
      {({ values, submitting }: any) => {
        if (values.repeatingValue) {
          onRepeatValueChange(values.repeatingValue);
        }
        if (values.frequency || values.frequency === AppRepeatFrequencyIntervals.DOESNT_REPEAT) {
          setHasRepeatingField(false);
        }
        if (values.frequency && values.frequency !== AppRepeatFrequencyIntervals.DOESNT_REPEAT) {
          setHasRepeatingField(true);
        }
        if (hasRepeat && values.frequency !== AppRepeatFrequencyIntervals.DOESNT_REPEAT) {
          setHasRepeatingField(true);
        }
        if (values.specificDate && values.repeatingValue === 'UNTIL_DATE') {
          setSpecificDate(values.specificDate);
        }

        return (
          <SC.RepeatContainer>
            {!isPremium && (
              <SC.UpgradeBlock>
                <CustomIcon type="caution" />
                <SC.WrappedText colorType="base" weight="semiBold" size="small" mb="none">
                  <span onClick={goToPricingPage}>
                    <IntlLabel label="rp.upgradeToPremiumLink.title" />
                  </span>
                  <Text colorType="base" weight="normal" size="small" mb="none">
                    <IntlLabel label="rp.upgradeToPremium.title" />
                  </Text>
                </SC.WrappedText>
              </SC.UpgradeBlock>
            )}
            <SC.FieldRow>
              <Field.Select
                md={24}
                name="frequency"
                label="rp.input.frequency.title"
                options={repeatFrequencyIntervals}
                validate={validateRequired}
                defaultValue={defaultSelectedFrequency ? defaultSelectedFrequency : repeatFrequencyIntervals[0].code}
                translate
              />
            </SC.FieldRow>
            {hasRepeatingField && (
              <SC.FieldRow>
                <Field.Select
                  name="repeatingValue"
                  options={repeatEndsAfterIntervals}
                  validate={validateRequired}
                  label="rp.input.endsAfter.title"
                  span={hasSpecificDate ? 11 : 24}
                  defaultValue={getDefaultRepeatValue()}
                  translate
                />
                {hasSpecificDate && (
                  <Col className="mobile-date-picker">
                    <FinalField
                      name="specificDate"
                      component={AppointmentSpecificDate as any}
                      defaultValue={specificDate}
                      initialDate={initialDate}
                      {...dateFieldFormatterProps}
                    />
                  </Col>
                )}
              </SC.FieldRow>
            )}

            <div className="justify-between">
              <Button type="ghost" label="btn.close" upperCase={false} onClick={onExit} />
              <Button type="primary" label="btn.save" htmlType="submit" loading={submitting} upperCase={false} />
            </div>
          </SC.RepeatContainer>
        );
      }}
    </Form>
  );
}

export default withModal('rp.repeatAppointment.title', { contentClassName: 'repeat-appointment-modal' })(
  RepeatAppointmentModal
);
