import React from 'react';
import { AppLayout, NoInternetConnection } from 'components';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { useGetProfile } from 'hooks/info/useGetProfile';
import withFormApi from 'hocs/form/withFormApi';
import * as api from 'api';
import PersonalSettingsMobileHeader from './components/PersonalSettingsMobileHeader';
import PersonalSettingForm from './components/PersonalSettingForm';

const SettingsForm = withFormApi({
  detailsAction: api.fetchProfileDetails,
  createAction: api.editProfileDetails,
  editAction: api.editProfileDetails,
  emptyId: true,
})(PersonalSettingForm);

export default function PersonalSettingsPage() {
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const getProfile = useGetProfile();

  return (
    <NoInternetConnection>
      <AppLayout
        mobileHeader={PersonalSettingsMobileHeader}
        headerTitle="title.mySettings"
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <div className="hidden-md" style={{ height: '80px' }} />
        <AppLayout.Container>
          <SettingsForm onSuccess={async () => await getProfile()} />
        </AppLayout.Container>
      </AppLayout>
    </NoInternetConnection>
  );
}
