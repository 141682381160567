import { Elements } from '@stripe/react-stripe-js';
import { Spin } from 'antd';
import * as api from 'api';
import SubscriptionSummaryCard from 'pages/plans/components/SubscriptionSummaryCard';
import React, { useEffect, useState } from 'react';
import { IntlLabel } from '@plandok/i18n';
import { AppLayout, NoInternetConnection } from 'components';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import useStripeElement from 'hooks/use-stripe-element';
import CheckoutForm from 'pages/plans/components/CheckoutForm';

export default function UpdateSubscriptionPage() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [estimatedCostsData, setEstimatedCostsData] = useState<any>({});
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const { isStripeLoading, stripePromise } = useStripeElement();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let response = await api.fetchEstimatedCosts({ staffCount: 1 });
      setIsLoading(false);

      setEstimatedCostsData(response.data);
    })();
    return () => {};
  }, []);

  const onPaymentSubmit = async (paymentMethod: string, billingInformation: any) => {
    console.log(paymentMethod, billingInformation);
    return await api.upgradePlan({ paymentMethod: paymentMethod, billingInformation });
  };

  return (
    <NoInternetConnection>
      <AppLayout
        mobileHeader={() => (
          <>
            <div />
            <div>
              <IntlLabel label="payment.step.title" />
            </div>
            <div />
          </>
        )}
        headerTitle="payment.step.title"
        isSubscriptionLoading={isStripeLoading || isSubscriptionLoading}
        planType={planType}
      >
        <Spin spinning={isLoading}>
          <SubscriptionSummaryCard estimatedCostsData={estimatedCostsData} />
        </Spin>

        {stripePromise && (
          <Elements stripe={stripePromise}>
            <CheckoutForm onPaymentSubmit={onPaymentSubmit} total={`${estimatedCostsData?.total?.toFixed(2) || 0}€`} />
          </Elements>
        )}
      </AppLayout>
    </NoInternetConnection>
  );
}
