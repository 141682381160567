import styled, { css } from 'styled-components';
import { Button } from 'antd';
import { mediaMobileDown } from '@plandok/core';

type StyledButtonProps = {
  color: ColorEnum;
  margin?: string;
};

type StyledContainerProps = {
  zIndex: number;
};

export enum ColorEnum {
  success = 'success',
  cancel = 'cancel',
}

export const StyledContainer = styled.div<StyledContainerProps>`
  background: #0a1c43;
  position: fixed;
  bottom: 3rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: ${({ zIndex }) => zIndex};
  padding: 21px 70px 27px 54px;
  border-radius: 10px;
  align-items: center;
  width: 884px;
  display: flex;

  ${mediaMobileDown(css`
    flex-direction: column;
    padding: 23px 35px 27px 30px;
    bottom: 5rem;
    width: calc(100% - 32px);
  `)};
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  background: ${({ color }) => (color === ColorEnum.success ? '#136EF6' : '#26385B')};
  margin: ${({ margin }) => margin ?? 'inherit'};
  padding: 11px 43px;
  border-radius: 5px;
  border: none;

  ${mediaMobileDown(css`
    div {
      font-size: 14px;
    }
  `)};
`;

export const StyledTextBlock = styled.div`
  margin-right: 20px;

  div {
    :first-child {
      margin-bottom: 6px;
    }
    :last-child {
      margin-bottom: 0;
    }
  }

  ${mediaMobileDown(css`
    margin: 0 0 21px;

    div {
      :first-child {
        font-size: 20px;
      }
      :last-child {
        font-size: 14px;
      }
    }
  `)};
`;
