import styled, { css } from 'styled-components';
import { mediaMobileDown, mediaMdUp } from '../../../../helpers/styles';

const SBox = styled.div<any>`
  background: white;
  border: ${(p) => p.theme.boxBorderDecoration};

  ${mediaMobileDown(css`
    border: none;
  `)}
` as any;

export const Container = styled(SBox)<any>`
  background: white;
  display: flex;
  flex-direction: column;
  min-height: ${(props) => props.minHeight || 'initial'};
  border-radius: ${(props) => props.borderRadius || 'inherit'};
  ${(p) =>
    p.withShadow
      ? css`
          box-shadow: 0 3px 25px rgba(19, 49, 109, 0.16) !important;
          border: none;
        `
      : ''};

  ${mediaMdUp(css<any>`
    padding: ${(props) => (props.noPadding ? '0' : '35px')};
    border-radius: ${(props) => props.borderRadius || props.theme.borderRadiusBase};
  `)}
  ${mediaMobileDown(css<any>`
    padding: ${(props) => (props.noPadding ? '0' : props.padding ? props.padding : '0 15px')};
  `)}
` as any;

export const CardImage = styled.div<any>`
  border-top-right-radius: ${(props) => props.theme.borderRadiusBase};
  border-top-left-radius: ${(props) => props.theme.borderRadiusBase};
  background: url('${(p) => p.imgUrl}');
  background-repeat: no-repeat;
  background-size: auto;
`;

export const Title = styled.div`
  margin: -10px -35px 20px -35px;
  border-bottom: 1px solid #e9ecf3;
  justify-content: space-between;
  display: flex;
  padding: 0 2rem 1.5625rem;
  align-items: center;
  ${mediaMobileDown(css`
    margin: 0 0 20px 0;
    padding: 15px 0;
    flex-direction: column;
    align-items: stretch;
  `)}
`;
