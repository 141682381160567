import React from 'react';

import { Menu } from 'antd';
import { IntlLabel } from '@plandok/i18n';

export default function BookingOptionsMenu({ editProfile, turnOfProfile, ...props }: any) {
  return (
    <Menu className="app-common-dropdown" {...props}>
      <Menu.Item onClick={editProfile}>
        <IntlLabel label="ob.menu.edit.profile" />
      </Menu.Item>
      <Menu.Item onClick={turnOfProfile}>
        <IntlLabel label="ob.menu.turnOff.profile" />
      </Menu.Item>
    </Menu>
  );
}
