import styled, { css } from 'styled-components';
import { Card, mediaSmDown, mediaXlDown } from '@plandok/core';

export const SummaryCardContainer = styled(Card)`
  margin: 20px auto 60px;
  width: 593px;
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  padding: 24px 40px 32px 41px;
  .summary {
    line-height: 28px;
    font-size: 24px;
    margin-bottom: 12px;
  }
  ${mediaXlDown(css`
    border-radius: 5px;
  `)}
  ${mediaSmDown(css`
    width: calc(100vw - 20px);
    padding: 20px 14px 24px;
    margin: 6px auto 40px;
    .summary {
      line-height: 33px;
      font-size: 28px;
      margin-bottom: 6px;
    }
  `)}
`;

export const ValidationCardContainer = styled.div`
  margin: 20px 0;
  width: 600px;
  ${mediaSmDown(css`
    width: calc(100vw - 10px);
    margin: 6px auto;
  `)}
`;

export const ServiceItem = styled.div`
  padding: 18px 0px 25px;
  border-bottom: 1px solid #dfe6f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    div:nth-child(1) {
      margin-bottom: 7px;
      line-height: 21px;
    }
    div:nth-child(2) {
      font-size: 16px;
      line-height: 19px;
    }
  }
  div:nth-child(2) {
    font-size: 18px;
    line-height: 21px;
  }
  ${mediaSmDown(css`
    padding: 24px 0;
    div {
      div:nth-child(1) {
        margin-bottom: 12px;
      }
      div:nth-child(2) {
        font-size: 14px;
        line-height: 16px;
      }
    }
    div:nth-child(2) {
      font-size: 14px;
      line-height: 16px;
    }
  `)}
`;

export const ContainerTotal = styled.div`
  padding-top: 24px;
  margin-left: auto;
  div {
    width: max-content;
    display: flex;
    justify-content: space-between;
  }
`;

export const Total = styled.div`
  margin-top: 16px;
  margin-left: auto;
  ${mediaSmDown(css`
    margin-top: 19px;
  `)}
`;
