import { Dropdown, Menu } from 'antd';
import React from 'react';
import { IconButton, useModal } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import * as SC from '../styles';

const MoreButtonExpendedMenu = ({ perView, resetCampaign, ...props }: any) => (
  <SC.CampaignsCardDropdown>
    <Menu className="app-common-dropdown br-default" {...props}>
      <Menu.Item onClick={perView}>
        <IntlLabel label="Peview" />
      </Menu.Item>
      <Menu.Item onClick={resetCampaign} className="br-bottom-default">
        <IntlLabel label="Reset Campaign" />
      </Menu.Item>
    </Menu>
  </SC.CampaignsCardDropdown>
);

export default function MoreButtonExpended(Props: any) {
  const [, { showModal }] = useModal();

  const perView = () => {};

  const resetCampaign = () => {
    showModal({
      type: 'RESET_CAMPAIGN_MODAL',
      modalProps: { String },
    });
  };

  return (
    <>
      <Dropdown
        trigger={['click']}
        overlay={() => <MoreButtonExpendedMenu perView={perView} resetCampaign={resetCampaign} />}
        placement="bottomRight"
      >
        <SC.Control>
          <IconButton icon="cardDots" />
        </SC.Control>
      </Dropdown>
    </>
  );
}
