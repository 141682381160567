import { RoutePath, OnlineBookingHash } from '../../../constants/routes';
import { PermissionType } from '../../../constants/auth';

export const cardSettings = {
  ACCOUNT: {
    title: 'title.accountSettings',
    elements: [
      {
        path: RoutePath.COMPANY_DETAILS,
        titleKey: 'title.accountSetup',
        descriptionKey: 'title.accountSetup.description',
        permission: PermissionType.ACCOUNT_SETTINGS,
        premiumFeatureOnly: false,
      },
      {
        path: RoutePath.RESOURCES,
        titleKey: 'title.resources',
        descriptionKey: 'title.resources.description',
        permission: PermissionType.ACCOUNT_SETTINGS,
        premiumFeatureOnly: false,
      },
      {
        path: RoutePath.LOCATIONS,
        titleKey: 'title.locations',
        descriptionKey: 'title.locations.description',
        permission: PermissionType.ACCESS_ALL_LOCATIONS,
        premiumFeatureOnly: false,
      },
      {
        path: RoutePath.CALENDAR_SETTINGS,
        titleKey: 'title.calendarSettings',
        descriptionKey: 'title.calendarSettings.description',
        premiumFeatureOnly: false,
      },
      {
        path: RoutePath.BILLING,
        mobilePath: RoutePath.BILLING_SELECT,
        titleKey: 'title.billingPlan',
        descriptionKey: 'title.billingPlan.description',
        permission: PermissionType.ACCOUNT_SETTINGS,
        premiumFeatureOnly: true,
      },
    ],
  },
  CLIENT: {
    title: 'title.clientSettings',
    permission: PermissionType.CLIENT_SETTINGS,
    elements: [
      {
        path: RoutePath.REFERRAL_SOURCES,
        titleKey: 'title.referrals',
        descriptionKey: 'title.referrals.description',
        premiumFeatureOnly: false,
      },
      {
        path: RoutePath.SETUP_NOTIFICATIONS,
        titleKey: 'title.clientNotifications',
        descriptionKey: 'title.clientNotifications.description',
        premiumFeatureOnly: true,
      },
      {
        path: RoutePath.CANCEL_REASONS,
        titleKey: 'title.cancellationReasons',
        descriptionKey: 'title.cancellationReasons.description',
        premiumFeatureOnly: false,
      },
    ],
  },
  BOOKING: {
    title: 'title.onlineBookingSettings',
    permission: PermissionType.ONLINE_BOOKINGS,
    elements: [
      {
        path: `${RoutePath.BOOKING}#${OnlineBookingHash.SETTINGS}`,
        titleKey: 'ob.title',
        descriptionKey: 'title.onlineBooking.description',
        premiumFeatureOnly: false,
      },
    ],
  },
};
