import axios from 'axios';

export const exportDataToCSV = async (endpoint: string, fileName: string, params?: any) => {
  const response = await axios.get(`/${endpoint}`, {
    responseType: 'arraybuffer',
    ...(params && { params }),
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body?.removeChild?.(link);
};
