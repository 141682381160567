import React from 'react';

import { IntlDate, IntlLabel } from '@plandok/i18n';
import differenceInDays from 'date-fns/differenceInDays';

import { ClosedDate } from '../types';

export const closedDatesColumns = [
  {
    title: <IntlLabel label="table.staff.dataRange" />,
    dataIndex: 'startDate',
    key: 'startDate',
    render: (value: Date, closedDate: ClosedDate) => {
      const startDate = new Date(value);
      const endDate = new Date(closedDate.endDate);

      return (
        <div>
          <IntlDate date={startDate} dateFormat="EEE, dd MMM yyyy" /> -&nbsp;
          <IntlDate date={endDate} dateFormat="EEE, dd MMM yyyy" />
        </div>
      );
    },
  },
  {
    title: <IntlLabel label="table.staff.numberOfDays" />,
    dataIndex: 'endDate',
    key: 'endDate',
    render: (value: string, closedDate: ClosedDate) => {
      const endDate = new Date(value);
      const startDate = new Date(closedDate.startDate);
      const daysDiff = differenceInDays(endDate, startDate);

      const numberOfDays = () => {
        if (daysDiff === 0) return `1 Day`;
        return `${daysDiff} Days`;
      };

      return <span>{numberOfDays()}</span>;
    },
  },
  {
    title: <IntlLabel label="table.staff.location" />,
    key: 'locations',
    dataIndex: 'locations',
    render: (date: string, values: any) => {
      const locationNames = values.locations.map((location: any) => location.name).join(', ');
      return <span>{locationNames}</span>;
    },
  },
  {
    title: <IntlLabel label="table.staff.description" />,
    key: 'description',
    dataIndex: 'description',
  },
];
