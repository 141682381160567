import styled from 'styled-components';
import { Button } from '@plandok/core';

export const Container = styled.div`
  color: #61749d;

  h1 {
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 26px;
    color: inherit;
  }

  div {
    font-size: 18px;
  }
`;

export const ButtonBack = styled(Button)`
  color: #136ef6 !important;
  margin-bottom: 20px;
  padding: 0;
  height: 19px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }
`;
