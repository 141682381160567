import { getIsMobile } from '@plandok/core';
import { IntlLabel, LabelKey } from '@plandok/i18n';
import cn from 'classnames';
import { Tabs } from 'antd';
import React from 'react';
import useGetHasPermission from 'hooks/permission/useGetHasPermission';
import { StaffTabHash } from 'constants/routes';
import { useLocation } from 'hooks/router';
import { staffTabConfig } from '../config';

type StaffTabsProps = {
  children: JSX.Element[];
};

type Tab = {
  title: LabelKey;
  path: string;
  permission: string;
};

export default function StaffTabs({ children }: StaffTabsProps) {
  const { navigate, location } = useLocation();
  const isMobile = getIsMobile();
  const getHasPermission = useGetHasPermission();
  const resultTabs = staffTabConfig.filter(({ permission }: { permission: string }) => getHasPermission(permission));
  const handleTabClick = (key: string) => navigate(`#${key}`);
  const activeKey = (location.hash || '').replace('#', '') || StaffTabHash.STAFF_MEMBERS;

  const getActiveTab = () => {
    const activeIndex = resultTabs.findIndex((tabConfig: any) => tabConfig.path === activeKey);
    return children[activeIndex].props.children || children[activeIndex];
  };

  return isMobile ? (
    getActiveTab()
  ) : (
    <Tabs
      className={cn('page-tabs-content-overflow', { 'staff-page__tabs--mobile': getIsMobile() }, 'staff-page__tabs')}
      defaultActiveKey={activeKey}
      activeKey={activeKey}
      onChange={handleTabClick}
      animated={false}
    >
      {resultTabs.map((tabConfig: Tab) => {
        const renderTabPane = children.find(({ key }) => key === tabConfig.permission);

        return (
          <Tabs.TabPane tab={<IntlLabel label={tabConfig.title} />} key={tabConfig.path}>
            {renderTabPane || null}
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
}
