import { Spin, Table as AntTable } from 'antd';
import React from 'react';
import ReportsPagination from './ReportsPagination';
import Pagination from './Pagination';
import * as SC from '../styles';

interface DesktopTableProps {
  columns: any[];
  page: number;
  count: number;
  totalCount: number;
  isLoading: boolean;
  fetchData: () => void;
  data: any[];
  selectedPage?: number;
  viewDetails?: Function;
  hidePageSwitcher?: boolean;
  paginationSpacing?: string;
  className?: string;
  isCustomPagination?: boolean;
  loadMore?: (page: number) => void;
}

export default function DesktopTable(props: DesktopTableProps) {
  return (
    <>
      <SC.Container>
        <Spin spinning={props.isLoading}>
          <AntTable
            columns={props.columns}
            dataSource={props.data}
            pagination={false}
            rowKey="key"
            footer={() => ''}
            className={props.className}
            onRowClick={(e: any) => props?.viewDetails?.(e?.id, e)}
          />
        </Spin>
      </SC.Container>
      {props.isCustomPagination ? (
        <ReportsPagination
          hidePageSwitcher={props.hidePageSwitcher}
          paginationSpacing={props.paginationSpacing}
          totalCount={props.totalCount}
          count={props.count}
          onChange={props.loadMore}
          selectedPage={props.selectedPage}
        />
      ) : (
        <Pagination page={props.page} totalCount={props.totalCount} count={props.count} onChange={props.fetchData} />
      )}
    </>
  );
}
