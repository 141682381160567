import React, { useEffect, useState } from 'react';
import { Button, Form, IconButton } from '@plandok/core';
import { LabelKey } from '@plandok/i18n';
import BookingFlowHeader from './components/BookingFlowHeader';
import { CreateBookingStepType } from '../types';
import * as SC from '../styles';
import { weekDays } from './steps/WorkingHoursStep';

interface BookingFlowStepProps {
  step: number;
  onBack: any;
  onNext: any;
  stepConfig: CreateBookingStepType;
  nextStepTitle?: LabelKey;
  initialValues?: any;
  onClose: any;
  error?: string;
  formErrors?: any;
  onSuccess(): any;
}

export default function BookingFlowStep({
  stepConfig: { component: StepComponent, isSmallForm, ...stepConfig },
  ...props
}: BookingFlowStepProps) {
  const isLastStep = props.step === 4;
  const [defaultValues, setDefaultValues] = useState<any>();
  const [allEnabled, setAllEnabled] = useState<boolean>(false);

  useEffect(() => {
    props.initialValues?.id && setDefaultValues(props.initialValues);

    // Check if all hours are empty
    // If all hours are empty, set allEnabled to true, else false
    props.initialValues?.id &&
      setAllEnabled(() => {
        const emptyHours: any[] = Object.values(props.initialValues?.workingHours).reduce((res: any[], val: any) => {
          val !== null && res.push(val);
          return res;
        }, []);

        return emptyHours.length === 0;
      });
  }, [props.initialValues]);

  useEffect(() => {
    // If all hours are empty, enable all working hours
    allEnabled &&
      setDefaultValues((prev: any) => {
        return {
          ...prev,
          workingHours: Object.fromEntries(new Map(weekDays.map((d: any) => [d.name, { open: true }]))),
        };
      });
  }, [allEnabled]);

  return (
    <Form initialValues={defaultValues} onSubmit={props.onNext} onSuccess={props.onSuccess}>
      {({ values, submitting, formError }: any) => {
        const formAlert = (formError && formError !== 'validation') || props.error;
        const globalInputError = props?.formErrors?.input;

        return (
          <>
            <SC.HeaderContainer>
              <BookingFlowHeader {...stepConfig} step={props.step + 1} nextStepTitle={props.nextStepTitle} />
              <div data-booking-flow-close>
                <IconButton icon="close" onClick={props.onClose} size="40px" />
              </div>
            </SC.HeaderContainer>
            <SC.BodyContent isSmall={isSmallForm}>
              <SC.Card padding={props.step === 1 ? '30px 0' : ''}>
                <StepComponent values={values} formErrors={props.formErrors} />
              </SC.Card>
              <SC.FormAlertContainer>
                {formAlert && <Form.Alert text={props.error || formError} />}
                {globalInputError && <Form.Alert text={globalInputError} />}
              </SC.FormAlertContainer>
              <SC.ActionsContainer>
                <Button label="btn.back" onClick={props.onBack} upperCase={false} type="primary" ghost />
                <Button
                  label={isLastStep ? 'btn.publish' : 'btn.continue'}
                  type="primary"
                  htmlType="submit"
                  upperCase={false}
                  loading={submitting}
                />
              </SC.ActionsContainer>
            </SC.BodyContent>
          </>
        );
      }}
    </Form>
  );
}
