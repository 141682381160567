import React from 'react';
import { Text } from '@plandok/core';
import * as SC from './styles';

type NotificationProps = {
  title: string;
  description: string;
  onOkBtnTitle: string;
  onCancelBtnTitle: string;
  onOk: () => void;
  onCancel: () => void;
  zIndex: number;
};

export default function Notification({
  title,
  description,
  onOkBtnTitle,
  onCancelBtnTitle,
  onOk,
  onCancel,
  zIndex,
}: NotificationProps) {
  return (
    <SC.StyledContainer zIndex={zIndex}>
      <SC.StyledTextBlock>
        <Text colorType="white" mb="xsmall" size="mlarge" weight="semiBold" label={title} />
        <Text colorType="white" mb="none" size="base" weight="normal" label={description} />
      </SC.StyledTextBlock>
      <div className="d-flex">
        <SC.StyledButton color={SC.ColorEnum.success} onClick={onOk}>
          <Text colorType="white" mb="none" size="base" weight="medium" label={onOkBtnTitle} />
        </SC.StyledButton>
        <SC.StyledButton color={SC.ColorEnum.cancel} onClick={onCancel} margin="0 0 0 8px">
          <Text colorType="white" mb="none" size="base" weight="medium" label={onCancelBtnTitle} />
        </SC.StyledButton>
      </div>
    </SC.StyledContainer>
  );
}
