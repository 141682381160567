/* eslint-disable */
import { Icon } from 'antd';
import React from 'react';
import { useLocation } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { RoutePath } from 'constants/routes';
import { SidebarIcons } from 'components';
import * as SC from './styles';

export default function MobileSupportBtn() {
  const { navigate } = useLocation();

  const goToHelpPage = () => navigate(RoutePath.HELP_MOBILE);

  return (
    <SC.Item onClick={goToHelpPage}>
      <a href="javascript:void(0)">
        <i>{SidebarIcons.support}</i>
        <IntlLabel label="sidebar.help" />
        <Icon type="right" />
      </a>
    </SC.Item>
  );
}
