import { useEffect, useState } from 'react';
import * as api from '../api';

export default function useGetLocationStaffPlan() {
  const [isPlanStaffLocLoading, setIsPlanStaffLocLoading] = useState(false);

  const [planType, setPlanType] = useState('free');
  const [staffCount, setStaffCount] = useState<number>(1);
  const [locationsCount, setLocationsCount] = useState(1);
  const [planId, setPlanId] = useState('');

  useEffect(() => {
    setIsPlanStaffLocLoading(true);

    (async () => {
      try {
        const { data } = await api.fetchSubscriptionInfo();

        setLocationsCount(data.locationCount);
        setStaffCount(data.employeeCount);
        setPlanType(data.planType);
        setPlanId(data.currentPlanId);
      } catch (e) {
        console.error('Error when getting sub-bil/sub');
      } finally {
        setIsPlanStaffLocLoading(false);
      }
    })();
  }, []);

  return { planType, locationsCount, staffCount, planId, isPlanStaffLocLoading };
}
