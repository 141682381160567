import { Input } from 'antd';
import ClearDatePicker from './ClearDatePicker';
import React from 'react';

const DatePickerInput = ({ onClear, value, onClick, onInputChange, placeholder, disabled, ...props }: any) => {
  return (
    <>
      <Input
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        value={value || ''}
        onClick={onClick}
        inputMode="none"
        {...props}
      />
      {onClear && (
        <ClearDatePicker
          onClick={() => {
            onInputChange('');
            onClear();
          }}
        />
      )}
    </>
  );
};

export default DatePickerInput;
