import { AppointmentStatus, EmployeeScheduleRange, NotificationTypeEnum } from 'api/api.d';

export * from '../api/api.d';

export type NotificationType = NotificationTypeEnum;

export type AppointmentStatusType = AppointmentStatus['status'];

export type IEmployeeScheduleRange = EmployeeScheduleRange;

export interface IClientAppointment {
  bookingId: string;
  appointmentId?: string;
  appointmentDate?: string;
  serviceName?: string;
  duration?: number;
  price?: number;
  startTime?: number;
  employeeFirstName?: string;
  employeeLastName?: string;
  status?: 'new' | 'confirmed' | 'no_show' | 'completed';
}

export interface IClientDetails {
  profile: {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    address?: string;
    birthdayYear?: string;
    birthdayMonth?: string;
    birthdayDay?: string;
    city?: string;
    postalCode?: string;
    gender?: string;
    notes?: string;
  };
  upcomingBookings: {
    bookingsCount: number;
    bookings: IClientAppointment[];
  };
  pastBookings: {
    bookingsCount: number;
    bookings: IClientAppointment[];
  };
  statistics: {
    totalBookingsCount: number;
    completedBookingsCount: number;
    cancelledBookingsCount: number;
    missedBookingsCount: number;
    bookingsTotalPrice: number;
  };
  bookings: {};
}

export interface IClientAppointmentDetails {
  bookingsTotalCount: number;
  bookingsTotalPrice: number;
  profile: {
    firstName?: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    // missing
    notes?: string;
  };
  upcomingBookings: any[];
}
