import pick from 'lodash/pick';
import React from 'react';
import { Field } from '@plandok/core';
import { dateFieldFormatterProps } from 'helpers/date/field';
import { mutation, mutate } from '../../../../graphql';
import withModifyModal from '../withModifyModal';
import { MultiCheckboxField } from 'components';

function ViewClosedDateModal({ isEdit }: any) {
  const setAllAsDefault = (onChange: any, options: any) => onChange(options.map((e: any) => e.code));

  return (
    <>
      <Field.Row>
        <Field.Date
          name="startDate"
          label="input.startDate.label"
          // placeholderText="input.startDate.description.label" // TODO: fix text from labels
          placeholderText="Select start date"
          md={12}
          {...dateFieldFormatterProps}
        />
        <Field.Date
          name="endDate"
          label="input.endDate.label"
          // placeholderText="input.startDate.description.label" // TODO: fix text from labels
          placeholderText="Select end date"
          md={12}
          {...dateFieldFormatterProps}
        />
      </Field.Row>
      <Field.Input
        name="description"
        label="input.description.label"
        placeholder="input.closedDatedDescription.placeholder"
      />
      <Field.AsyncSelect
        selectComponent={MultiCheckboxField}
        name="locationIds"
        customLabel="All locations"
        isDescription={false}
        asyncType="location"
        description="input.multiLocation.description"
        mode="multi"
        onInitialLoad={!isEdit && setAllAsDefault}
      />
    </>
  );
}

export default withModifyModal({
  label: 'closedDate.create.modal.title',
  editLabel: 'closedDate.edit.modal.title',
  createAction: mutate(mutation.CREATE_CLOSED_DATE),
  editAction: (id: string, val: any) =>
    mutate(mutation.UPDATE_CLOSED_DATE)(pick(val, 'id', 'startDate', 'endDate', 'description', 'locationIds')),
  deleteAction: (id: string) => mutate(mutation.DELETE_CLOSED_DATE)({ id }),
})(ViewClosedDateModal);
