import styled from 'styled-components';

export const AppointmentItem = styled.div<any>`
  display: flex;
  border-bottom: ${(props) => (props.noBorder ? 'none' : '1px solid #e9ecf3')};
  padding: ${(props) => (props.noPadding ? '15px 0' : '15px')};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  [data-appointment-item-date] {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #adb6cc;
    margin-right: 15px;
    div:first-of-type {
      font-size: 22px;
      font-weight: 600;
      line-height: 1.1;
    }
    div {
      font-size: 14px;
      line-height: 1.1;
    }
  }

  [data-appointment-item-info] {
    line-height: 18px;
    color: ${(props) => props.theme.primaryColor};
    div:nth-of-type(2) {
      font-weight: 600;
    }
  }

  [data-appointment-item-amount] {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: ${(props) => props.theme.primaryColor};
    font-weight: 600;
    font-size: 18px;
  }
`;
