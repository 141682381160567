import React, { useEffect } from 'react';
import * as api from 'api';
import { useAppContext } from '@plandok/core';
import history from '../../history';
import { RoutePath } from '../../constants/routes';
import calendarState from '../../pages/dashboard/CalendarPage/store/calendar.state';
import { graphqlClient } from '../../graphql';

export default function LogoutPage() {
  const [, { resetAppContext }] = useAppContext();

  useEffect(() => {
    calendarState.resetFilter();
    resetAppContext();
    api.logout();
    history.push(RoutePath.LOGIN);
    graphqlClient?.resetStore();
    graphqlClient?.clearStore();
    // eslint-disable-next-line
  }, []);
  return <div>Loading</div>;
}
