import React from 'react';

import { ReactNode } from 'react';

export default function TableContainer(props: { children: ReactNode }) {
  return (
    <div className="ant-table ant-table-default ant-table-scroll-position-left">
      <div className="ant-table-content">
        <div className="ant-table-body">
          <table className="">{props.children}</table>
        </div>
      </div>
    </div>
  );
}
