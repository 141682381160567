import { mediaSmDown } from '../../../../helpers/styles';
import styled, { css } from 'styled-components';

type DropdownContainerProps = {
  isGroups?: boolean;
};

type SelectContainerProps = {
  isWorkingTime?: boolean;
};

export const DropdownContainer = styled.div<DropdownContainerProps>`
  div.ant-select-dropdown-menu-item-group-title {
    margin: 0;
  }

  .ant-select-dropdown-menu-item {
    padding: ${({ isGroups }) => (isGroups ? '0 12px 0 0' : '5px 12px')};
  }

  li.ant-select-dropdown-menu-item:not(.ant-select-dropdown-menu-item-disabled) {
    border-radius: 0;
  }

  ${mediaSmDown(css`
    padding: ${({ isGroups }: DropdownContainerProps) => (isGroups ? '50px 20px 20px 0' : 'inherit')};
    position: ${({ isGroups }: DropdownContainerProps) => (isGroups ? 'fixed' : 'inherit')};
    background: ${({ isGroups }: DropdownContainerProps) => (isGroups ? '#fff' : 'inherit')};
    top: ${({ isGroups }: DropdownContainerProps) => (isGroups ? '0' : 'inherit')};
    left: ${({ isGroups }: DropdownContainerProps) => (isGroups ? '0' : 'inherit')};
    right: ${({ isGroups }: DropdownContainerProps) => (isGroups ? '0' : 'inherit')};
    bottom: ${({ isGroups }: DropdownContainerProps) => (isGroups ? '0' : 'inherit')};

    .ant-select-dropdown-menu {
      height: ${({ isGroups }: DropdownContainerProps) => (isGroups ? 'calc(100vh - 11rem)' : 'inherit')};
      margin-bottom: ${({ isGroups }: DropdownContainerProps) => (isGroups ? '1.5rem' : 'inherit')};
      max-height: inherit;
    }
    .ant-select-dropdown-menu-item-group-title {
      font-size: 20px;
      border-bottom: none;
    }
    .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
      padding-left: ${({ isGroups }: DropdownContainerProps) => (isGroups ? '0' : 'inherit')};
    }
    li.ant-select-dropdown-menu-item:not(.ant-select-dropdown-menu-item-disabled) {
      padding: ${({ isGroups }: DropdownContainerProps) => (isGroups ? '0' : '5px 12px')};
    }

    li.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active {
      background-color: #fff;
    }
  `)}
`;

export const CloseIcon = styled.div`
  font-size: 20px;
  color: #adb6cc;
  position: absolute;
  right: 20px;
  top: 16px;
  cursor: pointer;
`;

export const SearchDiv = styled.div`
  margin-bottom: 20px;
  input {
    border-radius: 0px !important;
    border: none;
    background-color: whitesmoke;
    top: 10px;
    left: 10px;
    padding-left: 5px;
    height: 35px;
  }
  .ant-input-suffix {
    top: 80% !important;
    right: 0px !important;
  }
  ${mediaSmDown(css`
    input {
      border-bottom: 1px solid #adb6cc;
      background-color: white;
      &:hover {
        border-bottom: 1px solid #6de1f7 !important;
      }
    }
  `)}
`;

export const AddBtnItem = styled.div`
  color: #14316d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
  i {
    margin-right: 10px;
  }
`;

export const SelectContainer = styled.div<SelectContainerProps>`
  display: ${({ isWorkingTime }: SelectContainerProps) => (isWorkingTime ? 'flex' : 'inherit')};
  flex-direction: ${({ isWorkingTime }: SelectContainerProps) => (isWorkingTime ? 'column' : 'inherit')};
`;
