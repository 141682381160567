// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from '../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportClientsQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  from: Types.Scalars['Date'];
  to: Types.Scalars['Date'];
}>;

export type ReportClientsQuery = {
  __typename?: 'AppQuery';
  reports?: {
    __typename?: 'App_Reports';
    clientsReport?: {
      __typename?: 'App_ClientReportConnection';
      totalCount?: number | null;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor?: string | null;
        endCursor?: string | null;
      };
      edges?: Array<{
        __typename?: 'App_ClientReportEdge';
        cursor: string;
        node?: {
          __typename?: 'App_ClientReport';
          id: any;
          firstName?: string | null;
          lastName?: string | null;
          appointments: number;
          noShows: number;
          gender: Types.Gender;
          createdAt?: any | null;
          lastAppointmentAt?: any | null;
          lastLocation?: string | null;
          daysAbsent?: number | null;
          totalSales: { __typename?: 'Money'; amount: string; currency: string };
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export const ReportClientsDocument = gql`
  query ReportClients($offset: Int, $limit: Int, $from: Date!, $to: Date!) {
    reports(from: $from, to: $to, offset: $offset, limit: $limit) {
      clientsReport {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            firstName
            lastName
            appointments
            noShows
            totalSales {
              amount
              currency
            }
            gender
            createdAt
            lastAppointmentAt
            lastLocation
            daysAbsent
          }
        }
      }
    }
  }
`;

/**
 * __useReportClientsQuery__
 *
 * To run a query within a React component, call `useReportClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportClientsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useReportClientsQuery(
  baseOptions: Apollo.QueryHookOptions<ReportClientsQuery, ReportClientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportClientsQuery, ReportClientsQueryVariables>(ReportClientsDocument, options);
}
export function useReportClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportClientsQuery, ReportClientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportClientsQuery, ReportClientsQueryVariables>(ReportClientsDocument, options);
}
export type ReportClientsQueryHookResult = ReturnType<typeof useReportClientsQuery>;
export type ReportClientsLazyQueryHookResult = ReturnType<typeof useReportClientsLazyQuery>;
export type ReportClientsQueryResult = Apollo.QueryResult<ReportClientsQuery, ReportClientsQueryVariables>;
