import React from 'react';
import { Text, useLocation } from '@plandok/core';
import { PremiumFeatureLabel } from 'components';
import { RoutePath } from 'constants/routes';
import * as SC from '../../SetupForm/styles';

interface MobileTitleBlockProps {
  premium: boolean;
  labelSettings: string;
}

export default function MobileTitleBlock({ premium, labelSettings }: MobileTitleBlockProps) {
  const { navigate } = useLocation();

  const upgrade = () => navigate(RoutePath.BILLING);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '50px 0px 15px' }}>
        <Text colorType="base" mb="none" size="medium" weight="semiBold" label={labelSettings} lh="mlarge" />
        {!premium && <PremiumFeatureLabel size="small" weight="medium" lh="base" />}
      </div>
      {!premium && (
        <SC.UpgradeButton
          label="button.reminder.upgrade"
          type="primary"
          smallRadius
          onClick={upgrade}
          upperCase={false}
        />
      )}
    </>
  );
}
