// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from '../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportBookingsByCancellationQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  from: Types.Scalars['Date'];
  to: Types.Scalars['Date'];
  locationId?: Types.InputMaybe<Types.Scalars['Uuid']>;
  employeeId?: Types.InputMaybe<Types.Scalars['Uuid']>;
}>;

export type ReportBookingsByCancellationQuery = {
  __typename?: 'AppQuery';
  reports?: {
    __typename?: 'App_Reports';
    appointmentCancellations?: {
      __typename?: 'App_ReportCancellationsConnection';
      totalCount?: number | null;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor?: string | null;
        endCursor?: string | null;
      };
      edges?: Array<{
        __typename?: 'App_ReportCancellationsEdge';
        cursor: string;
        node?: {
          __typename?: 'App_ReportAppointmentCancellations';
          id?: string | null;
          clientFirstName?: string | null;
          clientLastName?: string | null;
          serviceName?: string | null;
          scheduledDate?: any | null;
          cancelledDate?: any | null;
          cancelledByFirstName?: string | null;
          cancelledByLastName?: string | null;
          reasonName?: string | null;
          price: { __typename?: 'Money'; amount: string; currency: string };
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export const ReportBookingsByCancellationDocument = gql`
  query ReportBookingsByCancellation(
    $offset: Int
    $limit: Int
    $from: Date!
    $to: Date!
    $locationId: Uuid
    $employeeId: Uuid
  ) {
    reports(from: $from, to: $to, offset: $offset, limit: $limit) {
      appointmentCancellations(locationId: $locationId, employeeId: $employeeId) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            clientFirstName
            clientLastName
            serviceName
            scheduledDate
            cancelledDate
            cancelledByFirstName
            cancelledByLastName
            reasonName
            price {
              amount
              currency
            }
          }
        }
      }
    }
  }
`;

/**
 * __useReportBookingsByCancellationQuery__
 *
 * To run a query within a React component, call `useReportBookingsByCancellationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportBookingsByCancellationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportBookingsByCancellationQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      locationId: // value for 'locationId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useReportBookingsByCancellationQuery(
  baseOptions: Apollo.QueryHookOptions<ReportBookingsByCancellationQuery, ReportBookingsByCancellationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportBookingsByCancellationQuery, ReportBookingsByCancellationQueryVariables>(
    ReportBookingsByCancellationDocument,
    options
  );
}
export function useReportBookingsByCancellationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportBookingsByCancellationQuery,
    ReportBookingsByCancellationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportBookingsByCancellationQuery, ReportBookingsByCancellationQueryVariables>(
    ReportBookingsByCancellationDocument,
    options
  );
}
export type ReportBookingsByCancellationQueryHookResult = ReturnType<typeof useReportBookingsByCancellationQuery>;
export type ReportBookingsByCancellationLazyQueryHookResult = ReturnType<
  typeof useReportBookingsByCancellationLazyQuery
>;
export type ReportBookingsByCancellationQueryResult = Apollo.QueryResult<
  ReportBookingsByCancellationQuery,
  ReportBookingsByCancellationQueryVariables
>;
