import flow from 'lodash/flow';
import React from 'react';
import { Mutation, withModal, Button } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import * as SC from '../styles';

function ConfirmModal({ hideAllModals, modalProps = { closeAll: true }, onClose }: any) {
  const onSuccess = () => {
    if (modalProps.closeAll) {
      hideAllModals();
    } else {
      onClose();
    }

    if (modalProps.onSuccess) {
      modalProps.onSuccess();
    }
  };

  return (
    <div>
      <div>
        <IntlLabel label={modalProps.label} /> <br />
      </div>
      <SC.ModalFooter>
        <Mutation action={modalProps.action} id={modalProps.id} onSuccess={onSuccess}>
          {({ loading, action }: any) => (
            <>
              <Button disabled={loading} label="btn.cancel" onClick={flow(onClose, modalProps?.onExit)} />
              <Button loading={loading} label="btn.confirm" type="primary" onClick={action} />
            </>
          )}
        </Mutation>
      </SC.ModalFooter>
    </div>
  );
}

export default withModal(null)(ConfirmModal as any);
