import { Pagination as AntPagination } from 'antd';
import React from 'react';
import { Text } from '@plandok/core';
import { useStore } from 'store/store';
import * as SC from './styles';

export default function ReportsPagination({
  count = 0,
  totalCount,
  selectedPage,
  hidePageSwitcher,
  maxCount = 10,
  paginationSpacing,
  onChange,
}: any) {
  const page = selectedPage ?? useStore((state) => state.page);

  return (
    <SC.Container paginationSpacing={paginationSpacing} isCenter={hidePageSwitcher}>
      <SC.TotalNumbers>
        <Text
          size="base"
          label="table.pagination.showing.title"
          weight="normal"
          style={{ color: '#ADB6CC' }}
          mb="none"
        />
        <Text size="base" weight="medium" style={{ color: '#62759D' }} mb="none">
          {(page - 1) * maxCount} to {(page - 1) * maxCount + count} of {totalCount}
        </Text>
        <Text
          size="base"
          label="table.pagination.results.title"
          weight="normal"
          style={{ color: '#ADB6CC' }}
          mb="none"
        />
      </SC.TotalNumbers>
      {!!totalCount && !hidePageSwitcher && (
        <AntPagination
          total={totalCount}
          pageSize={maxCount}
          defaultCurrent={page}
          key={page}
          className="pagination-component"
          onChange={onChange}
        />
      )}
    </SC.Container>
  );
}
