import styled, { css } from 'styled-components';
import { mediaMdUp } from '@plandok/core';

export const MobileContainer = styled.div`
  padding: 15px 70px 15px 15px;
  background: #f8f9fc;
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  z-index: 3;
  ${mediaMdUp(css`
    display: none;
  `)}
`;

export const PopoverTitle = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;

export const PopoverDescription = styled.div`
  margin-top: 0.5rem;
  max-width: 14rem;
`;
