import { Icon } from 'antd';
import React from 'react';
import { IntlLabel, IntlMoney } from '@plandok/i18n';
import { Card } from '@plandok/core';
import { IClientDetails } from 'types/api';
import * as SC from './style';

export default function ClientTotalBlock({ data }: { data: IClientDetails }) {
  const { statistics } = data;

  return (
    <SC.Container as={Card} noPadding>
      <SC.Divider />
      <SC.ContainerTitle>
        <div>
          <div data-total-item-main>
            <Icon type="pie-chart" theme="filled" />
          </div>
          <div data-total-item-minor>
            <IntlLabel label="client.statistics" />
          </div>
        </div>
      </SC.ContainerTitle>
      <SC.TotalNumberContainer>
        <div data-total-numbers-row>
          <div>
            <div data-total-item-main>
              <IntlMoney value={statistics.bookingsTotalPrice} />
            </div>
            <div data-total-item-minor>
              <IntlLabel label="client.totalSales" />
            </div>
          </div>
        </div>
        <div data-total-numbers-row>
          <div>
            <div data-total-item-main>{statistics.totalBookingsCount}</div>
            <div data-total-item-minor>
              <IntlLabel label="client.allBooking" />
            </div>
          </div>
          <div>
            <div data-total-item-main>{statistics.completedBookingsCount}</div>
            <div data-total-item-minor>
              <IntlLabel label="client.completed" />
            </div>
          </div>
          <div>
            <div data-total-item-main>{statistics.cancelledBookingsCount}</div>
            <div data-total-item-minor>
              <IntlLabel label="client.cancelled" />
            </div>
          </div>
          <div>
            <div data-total-item-main>{statistics.missedBookingsCount}</div>
            <div data-total-item-minor>
              <IntlLabel label="client.noShow" />
            </div>
          </div>
        </div>
      </SC.TotalNumberContainer>
      <SC.Divider />
    </SC.Container>
  );
}
