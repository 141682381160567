import { PaymentTabHash } from 'constants/routes';

export const paymentTabs: any = [
  {
    path: PaymentTabHash.DETAILS,
    title: 'payment.card.details',
  },
  {
    path: PaymentTabHash.INFORMATION,
    title: 'billing.tab.billing',
  },
];

export const style = {
  base: {
    color: 'grey',
    fontSize: '16px',
    lineHeight: '19px',
    '::placeholder': {
      color: '#B3BCD0',
    },
  },
  invalid: {
    color: '#ca2a2a',
  },
};

export const PriceType = {
  FREE: 'free',
  PREMIUM: 'premium',
};

export const PriceTypeForPlans = {
  FREE: 'free',
  PREMIUM: 'premium',
};
