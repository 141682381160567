import styled from 'styled-components';
import React from 'react';
import { getIsMobile, Link, Card } from '@plandok/core';
import { Element } from '../config';
import * as SC from './styles';

interface ReportCardProps {
  title: string;
  description: string;
  elements: Element[];
}

const StyledLink = styled(Link)`
  color: #136ef6;
  font-size: 18px;
  font-weight: 500;
  text-transform: none;
`;

const ReportCard = ({ title, description, elements }: ReportCardProps) => {
  return (
    <Card padding={getIsMobile() ? '1rem 1.1rem' : 'inherit'} borderRadius="5px">
      <SC.Title size="large" weight="semiBold" label={title} colorType="base" />
      <SC.Description label={description} size="base" colorType="base" mb="none" />
      {elements.map((element) => (
        <SC.ElementItem key={element.title}>
          <SC.ButtonWrapper type="link" upperCase={false}>
            <StyledLink label={element.title} to={element.path} />
          </SC.ButtonWrapper>
          <SC.ElementDescription label={element.description} size="base" className="mb-0" />
        </SC.ElementItem>
      ))}
    </Card>
  );
};

export default ReportCard;
