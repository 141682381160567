import { useElements } from '@stripe/react-stripe-js';
import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useStripeHook } from '@plandok/core';
import { style } from 'pages/plans/BillingPage/PricingPage/constants';
import useQuery from 'hooks/data/useQuery';
import * as api from 'api';
import CardBillingInformation from 'components/common/CardBillingInformation';

export type BillingInformationType = {
  address: string;
  city: string;
  company: string;
  country: string;
  firstName: string;
  lastName: string;
  onlineCustomerId: string | null;
  postCode: string;
  registrationNumber: string | null;
  vatNumber: string | null;
};

interface CheckoutFormProps {
  total: string;
  onPaymentSubmit: (paymentMethod: string, billingInformation: any) => Object;
}

export default function CheckoutForm({ total, onPaymentSubmit }: CheckoutFormProps) {
  const { data: billingInformation, isLoading: isBillingLoading } = useQuery({ fetchData: api.fetchBillingInfo });

  const elements = useElements();
  const stripe = useStripeHook();

  useEffect(() => {
    if (elements) {
      stripe.loadComponent(elements, style);
    }
  }, [elements, stripe]);

  return (
    <Spin spinning={isBillingLoading}>
      <CardBillingInformation total={total} onPaymentSubmit={onPaymentSubmit} billingInformation={billingInformation} />
    </Spin>
  );
}
