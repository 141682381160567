import React from 'react';
import { AppTable } from 'components';
import * as api from 'api';
import { getIsMobile } from '@plandok/core';
import { WrapperInvoice } from './styled';
import { invoiceColumns, getMobileColumn } from './appTableConfig';

export default function InvoicesPage() {
  const isMobile = getIsMobile();
  return (
    <WrapperInvoice>
      <AppTable
        type="invoices"
        columns={invoiceColumns}
        mobileClassName="app_clients-list--mobile"
        mobileRowComponent={getMobileColumn(isMobile)}
        apiConfig={{
          fetchData: api.fetchInvoices,
          magicMinus: true,
          actualOffset: true,
          mapping: {
            pageKey: 'page[offset]',
            limitKey: 'page[limit]',
            dataKey: 'data',
            totalCountKey: 'meta.pagination.total',
            searchKey: 'query',
          },
        }}
      />
    </WrapperInvoice>
  );
}
