import { flow, noop } from 'lodash';
import React from 'react';
import { Button, Form, Text, useLocation, withModal } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { RoutePath } from 'constants/routes';
import { NotReleased } from 'components';
import * as api from 'api';
import * as SC from '../styles';

function CampaignActivationConfirmModal(props: any) {
  const { navigate } = useLocation();
  const dashboard = () => {
    navigate(RoutePath.MARKETING);
    props.onClose();
  };

  return (
    <NotReleased>
      <Form
        onSubmit={(form: any) => api.closeAppointment(props.modalProps.Number, form)}
        onSuccess={flow(props.onClose, props.modalProps?.onSuccess || noop)}
      >
        {({ submitting }: any) => (
          <SC.CampaignActivationContainer>
            <img src="/img/illustrations/checked.svg" alt="placeholder" />
            <Text label="Campaign activated!" size="mlarge" weight="semiBold" mb="none">
              <IntlLabel label="Campaign activated!" />
            </Text>
            <Text label="Your can pause or edit this Campaign at any time." size="small">
              <IntlLabel label="Your can pause or edit this Campaign at any time." />
            </Text>
            <Button
              type="primary"
              label="Back to dashboard"
              loading={submitting}
              onClick={dashboard}
              upperCase={false}
            />
          </SC.CampaignActivationContainer>
        )}
      </Form>
    </NotReleased>
  );
}

export default withModal(null, { contentClassName: 'campaign-activation-modal' })(CampaignActivationConfirmModal);
