import styled from 'styled-components';

export const PhoneCallButton = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  padding: 8px;
  margin-left: 2rem;
  border-radius: 3rem;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e9ecf3;

  svg:not(:root) {
    overflow: visible;
  }

  :hover {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  }
`;
