import React from 'react';

import { IntlLabel, LabelKey } from '@plandok/i18n';
import Icon from 'antd/lib/icon';
import { useHotkeys } from 'hooks/hotkey';
import { useLocation } from 'hooks/router';
import { ReactNode } from 'react';

import ModifyFooter from './components/ModifyFooter';
import { useStore } from 'store/store';
import * as SC from './styles';

function ModifyLayout(props: {
  children: ReactNode;
  title?: LabelKey;
  className?: string;
  mdWhite?: boolean;
  style?: any;
  onClose?: any;
}) {
  const setRescheduleItemDate = useStore((state) => state.setRescheduleItemDate);
  const setRescheduleItemTime = useStore((state) => state.setRescheduleItemTime);
  const setRescheduleItemStaff = useStore((state) => state.setRescheduleItemStaff);

  const { goBack } = useLocation();

  const helperOnClose = () => {
    goBack();
    setRescheduleItemDate(undefined);
    setRescheduleItemTime(undefined);
    setRescheduleItemStaff('');
  };

  const close = () => (props.onClose ? props.onClose() : helperOnClose());
  useHotkeys('esc', close);

  return (
    <SC.Container className={props.className} mdWhite={props.mdWhite} style={props.style}>
      <SC.CloseIcon onClick={close}>
        <Icon type="close-circle" />
      </SC.CloseIcon>
      <SC.Content>
        {props.title && (
          <SC.Title>
            <IntlLabel label={props.title} />
          </SC.Title>
        )}
        {props.children}
      </SC.Content>
    </SC.Container>
  );
}

ModifyLayout.Footer = ModifyFooter;

export default ModifyLayout;
