import { Text } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import React from 'react';
import { App_AppointmentsAnalytics } from '../../../../../../graphql/generatedTypes';

import * as SC from './styles';

type TotalAppointmentsStatusProps = {
  appointments: App_AppointmentsAnalytics;
};

const TotalAppointmentsStatus = ({ appointments }: TotalAppointmentsStatusProps) => (
  <SC.TotalStatus>
    <div>
      <Text size="base" weight="normal" colorType="base" mb="small">
        <IntlLabel label="analytics.completed.title" />
      </Text>
      <Text size="base" weight="normal" colorType="base" mb="small">
        <IntlLabel label="analytics.notCompleted.title" />
      </Text>
      <Text size="base" weight="normal" colorType="base" mb="small">
        <IntlLabel label="analytics.canceled.title" />
      </Text>
      <Text size="base" weight="normal" colorType="base" mb="small">
        <IntlLabel label="analytics.noShowAppointments.title" />
      </Text>
    </div>
    <SC.NumbersColumn>
      <SC.Inliner>
        <Text size="base" weight="semiBold" colorType="base" mb="small">
          {appointments.completedCount}
        </Text>
        <Text size="base" weight="normal" colorType="base" mb="small" ml="xxsmall">
          ({appointments.completedPercent}%)
        </Text>
      </SC.Inliner>
      <SC.Inliner>
        <Text size="base" weight="semiBold" colorType="base" mb="small">
          {appointments.notCompletedCount}
        </Text>
        <Text size="base" weight="normal" colorType="base" mb="small" ml="xxsmall">
          ({appointments.notCompletedPercent}%)
        </Text>
      </SC.Inliner>
      <SC.Inliner>
        <Text size="base" weight="semiBold" colorType="base" mb="small">
          {appointments.cancelledCount}
        </Text>
        <Text size="base" weight="normal" colorType="base" mb="small" ml="xxsmall">
          ({appointments.cancelledPercent}%)
        </Text>
      </SC.Inliner>
      <SC.Inliner>
        <Text size="base" weight="semiBold" colorType="base" mb="small">
          {appointments.noShowCount}
        </Text>
        <Text size="base" weight="normal" colorType="base" mb="small" ml="xxsmall">
          ({appointments.noShowPercent}%)
        </Text>
      </SC.Inliner>
    </SC.NumbersColumn>
  </SC.TotalStatus>
);

export default TotalAppointmentsStatus;
