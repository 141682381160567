import { sortBy, flow } from 'lodash';
import { observer } from 'mobx-react';
import { endOfWeek } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { getIsMobile, PrintButton, SelectInput, Text, AuthBlock, formatTime } from '@plandok/core';
import { IntlDate, IntlLabel, useIntlOptions } from '@plandok/i18n';
import { useUserQuery } from 'graphql/queries/userQuery.generated';
import calendarState from '../../store/calendar.state';
import CalendarAddItemBtn from '../CalendarAddItemBtn';
import { viewTypeOptions } from 'constants/data';
import { PermissionType } from 'constants/auth';
import { DateFilterItem } from 'components';
import { staffOptions } from './constants';
import * as SC from './styles';

type CalendarFilterProps = {
  openSetBlockTime: () => void;
  createAppointment: () => void;
  timeFormat: string;
};

function CalendarFilter({ openSetBlockTime, createAppointment, timeFormat }: CalendarFilterProps) {
  const [lastScroll, setLastScroll] = useState(0);

  const { convertOptions } = useIntlOptions();
  const { data: userData } = useUserQuery();
  const isMobile = getIsMobile();

  const sortBookings = sortBy(calendarState?.data?.bookings, ['appointmentDate', 'startTime']);
  const showAllStaff = calendarState?.isLoading || calendarState.employeesOptions?.length > 1;
  const isLocationSelectVisible = calendarState.locationOptions?.length > 1;
  const isPremium = userData?.user?.partner?.subscription.premium ?? false;
  const isStaffSelectVisible = !!calendarState.employeesOptions.length;
  const isWeekMode = calendarState.filterParams.viewType === 'week';
  const rangeDate = calendarState.filterParams.date;

  const setLastTopScroll = () => {
    const calendarBodyElement = document.getElementById('epic-calendar__body');

    if (calendarBodyElement?.scrollTop) {
      setLastScroll(calendarBodyElement.scrollTop);
    }
  };

  useEffect(() => {
    const calendarBodyElement = document.getElementById('epic-calendar__body');

    calendarBodyElement?.scroll?.({ top: lastScroll });
  }, [lastScroll]);

  return (
    <SC.StyledFilterContainer isMobile={isMobile}>
      <div data-filter-group>
        {isLocationSelectVisible && (
          <div data-filter-item-select>
            <SelectInput
              options={calendarState.locationOptions}
              onChange={calendarState.changeFilter('location')}
              value={calendarState.filterParams.location}
              onCustomChange={setLastTopScroll}
            />
          </div>
        )}
        <div data-filter-item-select>
          {isStaffSelectVisible && (
            <SelectInput
              options={[
                ...(showAllStaff ? convertOptions(staffOptions) : []),
                ...(calendarState.employeesOptions || []),
              ]}
              onChange={calendarState.changeFilter('staff')}
              value={calendarState.filterParams.staff}
              onCustomChange={setLastTopScroll}
            />
          )}
        </div>
      </div>
      <div data-filter-group data-filter-date-group>
        <DateFilterItem
          date={calendarState.filterParams.date}
          changeDate={flow(calendarState.changeFilter('date'), setLastTopScroll)}
          viewType={calendarState.filterParams.viewType}
        />
      </div>
      <div data-filter-group>
        <PrintButton isPremium={isPremium}>
          <Text mb="none" weight="semiBold" color="#000">
            <IntlLabel label="calendar.print.appointmentsBetween.title" />
            {isWeekMode ? (
              <>
                <IntlDate date={rangeDate} dateFormat="dd MMMM yyyy" />
                <IntlLabel label="calendar.print.appointmentsAnd.title" />
                <IntlDate date={endOfWeek(rangeDate)} dateFormat="dd MMMM yyyy" />
              </>
            ) : (
              <IntlDate date={rangeDate} dateFormat="dd MMMM yyyy" />
            )}
          </Text>
          {sortBookings?.map((booking: any) => (
            <SC.PrintContainer key={booking.id}>
              <SC.PrintHeader>
                <Text mb="none" weight="semiBold" size="xsmall" color="#000">
                  <IntlDate date={new Date(booking.appointmentDate)} dateFormat="dd MMMM yyyy" />
                </Text>
                &nbsp;
                <Text mb="none" weight="semiBold" size="xsmall" color="#000">
                  {booking.clientFirstName} {booking.clientLastName}
                </Text>
              </SC.PrintHeader>
              <SC.PrintContent>
                <Text mb="none" size="xsmall" color="#000">
                  {formatTime(booking.startTime, timeFormat)} -&nbsp;
                  {formatTime(booking.startTime + booking.duration, timeFormat)}&nbsp;
                </Text>
                <Text mb="none" size="xsmall" color="#000">
                  {booking.duration}
                  &nbsp;
                  <IntlLabel label="dropdown.time.minutes" />
                  &nbsp;
                </Text>
                <Text mb="none" size="xsmall" color="#000">
                  {booking.employeeFirstName} {booking.employeeLastName}&nbsp;
                </Text>
                <Text mb="none" size="xsmall" color="#000">
                  {booking.serviceName}
                </Text>
              </SC.PrintContent>
            </SC.PrintContainer>
          ))}
        </PrintButton>
        <div data-filter-item-select>
          <SelectInput
            options={convertOptions(viewTypeOptions)}
            value={calendarState.filterParams.viewType}
            onChange={calendarState.changeFilter('viewType')}
          />
        </div>
        <div>
          <AuthBlock section={PermissionType.CAN_BOOK_APPOINTMENTS}>
            <CalendarAddItemBtn openSetBlockTime={openSetBlockTime} createAppointment={createAppointment} />
          </AuthBlock>
        </div>
      </div>
    </SC.StyledFilterContainer>
  );
}

export default observer(CalendarFilter);
