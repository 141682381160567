import { format, isYesterday, isValid, subDays } from 'date-fns';
import { useCallback, useState, useEffect } from 'react';
import { useReportAppointmentListQuery } from 'graphql/queries/reportAppointmentList.generated';
import { useReportClientsQuery } from 'graphql/queries/reportClients.generated';
import { tableStoreFactory } from 'components';
import { useStore } from 'store/store';

type FromDate = Date | string | null;

type TimeRangeData = {
  timeRange: Date;
  startDate: Date | null;
  endDate: Date | null;
};

type QueryKey = 'clientsReport' | 'appointmentsReport';

export const useQueryReports = (key: QueryKey) => {
  const [timeRangeData, setTimeRangeData] = useState<TimeRangeData>({} as TimeRangeData);
  const locationId = useStore(useCallback((state) => state.locationId, []));
  const employeeId = useStore(useCallback((state) => state.employeeId, []));

  const setPage = useStore(useCallback((state) => state.setPage, []));
  const storedPage = useStore(useCallback((state) => state.page, []));

  const offset = storedPage === 0 ? 0 : (storedPage - 1) * 10;

  const getFromDate = (): FromDate => {
    const isTimeRangeSelected = isValid(timeRangeData.timeRange) && !timeRangeData.startDate && !timeRangeData.endDate;
    const isNoDaysSelected = !isValid(timeRangeData.timeRange) && !isValid(timeRangeData.endDate);
    const isCustomRangeSelected = isValid(timeRangeData.startDate) && isValid(timeRangeData.endDate);

    const formattedStartDate =
      timeRangeData.startDate && isValid(timeRangeData.startDate) ? format(timeRangeData.startDate, 'yyyy-MM-dd') : '';
    const formattedTimeRange = isValid(timeRangeData.timeRange) ? format(timeRangeData.timeRange, 'yyyy-MM-dd') : '';
    const defaultFormatLast7Days = format(subDays(new Date(), 7), 'yyyy-MM-dd');

    switch (true) {
      case isTimeRangeSelected:
        return formattedTimeRange;
      case isNoDaysSelected:
        return defaultFormatLast7Days;
      case isCustomRangeSelected:
        return formattedStartDate;
      default:
        return defaultFormatLast7Days;
    }
  };

  const getToDate = (): FromDate => {
    switch (true) {
      case isValid(timeRangeData.endDate):
        return timeRangeData.endDate && format(timeRangeData.endDate, 'yyyy-MM-dd');
      case isYesterday(new Date(getFromDate() as Date)):
        return from;
      default:
        return format(new Date(), 'yyyy-MM-dd');
    }
  };

  const from = getFromDate();
  const to = getToDate();

  const variables = {
    offset,
    limit: 10,
    from,
    to,
    locationId: locationId || null,
    employeeId: employeeId || null,
  };

  const queries: Record<QueryKey, any> = {
    clientsReport: useReportClientsQuery,
    appointmentsReport: useReportAppointmentListQuery,
  };

  const { data, loading, error } = queries[key]({ variables });

  const refetchData = () => tableStoreFactory.getTableStoreInstance(key).fetchFreshData(data?.reports);

  const loadMore = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  useEffect(() => {
    setPage(1);
  }, [setPage]);

  return {
    data,
    loading,
    error,
    refetchData,
    loadMore,
    setTimeRangeData,
    from,
    to,
    setPage,
  };
};
