import React from 'react';
import { RoutePath, MarketingHash } from 'constants/routes';
import { ModifyMassLayout, NotReleased } from 'components';
import MessageForm from './components/MessageForm';
import withFormApi from 'hocs/form/withFormApi';
export const MassageFormWithApi = withFormApi({
  backPath: `${RoutePath.MARKETING}#${MarketingHash.MASSMESSAGES}`,
  successMessage: 'Message',
})(MessageForm);

export default function ModifyMessagePage() {
  return (
    <NotReleased>
      <ModifyMassLayout title="Message" step="SMS setup. Step 1 of 4">
        <MassageFormWithApi />
      </ModifyMassLayout>
    </NotReleased>
  );
}
