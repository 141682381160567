import React, { useState, ReactNode } from 'react';
import { Icon } from 'antd';
import PrintButton from '../PrintButton';
import * as SC from './styles';

type ContentLongProps = {
  title: ReactNode;
  isPremium?: boolean;
  children?: ReactNode[];
  visibleCount?: number;
  defaultVisibility?: boolean;
};

export default function ContentLong({
  title,
  isPremium,
  children,
  visibleCount = 1,
  defaultVisibility = false,
}: ContentLongProps) {
  const [open, setOpen] = useState(defaultVisibility);
  const toggleOpen = () => setOpen(!open);

  return (
    <>
      <SC.PrintContainer>
        <SC.Toggle onClick={toggleOpen}>
          {open ? (
            <>
              {title} <Icon type="up" />
            </>
          ) : (
            <>
              {title} <Icon type="down" />
            </>
          )}
        </SC.Toggle>
        <PrintButton isPremium={isPremium || false} label="calendar.btn.print.title">
          {title}
          {children}
        </PrintButton>
      </SC.PrintContainer>
      <SC.Container open={open} visibleCount={visibleCount}>
        {React.Children.map(children, (child, i) => {
          if (!open && i > visibleCount) {
            return null;
          }
          return child;
        })}
      </SC.Container>
    </>
  );
}
