import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Icon, Row } from 'antd';
import { TextInput, useModal } from '@plandok/core';
import { withIntlLabel } from '@plandok/i18n';
import {
  AppLayout,
  PageControlContainer,
  LinkTabs,
  AppTable,
  tableStoreFactory,
  NoInternetConnection,
} from 'components';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { RoutePath } from 'constants/routes';
import * as api from 'api';
import MobileClientsFilter from '../ClientsPage/components/ClientFilter/components/MobileClientsFilter';
import MessagesMobileHeader from './components/MessagesMobileHeader';
import { getMessagesColumns, settingTabs } from './config';
import * as SC from './styles';

const SearchInput = withIntlLabel({ propKey: 'placeholder' })(TextInput);

const createMessageRow =
  (showMessage: any) =>
  ({ value }: any) => {
    const columns = getMessagesColumns(showMessage);

    return (
      <Row type="flex" justify="space-between" align="middle" className="w-100">
        <SC.MessageContainer>
          {columns.map((e, i) => (
            <div key={i} style={{ minWidth: e?.width }}>
              {(e as any)?.render?.(value)}
            </div>
          ))}
        </SC.MessageContainer>
      </Row>
    );
  };

export default observer(function MessagesPage() {
  const tableStore = useMemo(() => tableStoreFactory.getTableStoreInstance('messages'), []);
  const [query, setQuery] = useState('');
  const [, { showModal }] = useModal();

  const showMessage = (text: any) => showModal({ type: 'VIEW_MESSAGE', modalProps: { text } });

  const { isSubscriptionLoading, planType } = useGetCurrentPlan();

  return (
    <NoInternetConnection>
      <AppLayout
        mobileHeader={MessagesMobileHeader}
        headerTitle="client.messages.title"
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <LinkTabs tabs={settingTabs} className="hidden-md" />
        <PageControlContainer mobileHidden>
          <PageControlContainer.Item>
            {!tableStore.showPlaceholder && (
              <SearchInput
                suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.45)' }} />}
                search
                value={query}
                onChange={({ target: { value } }: any) => setQuery(value)}
                placeholder="placeholder.email"
              />
            )}
          </PageControlContainer.Item>
        </PageControlContainer>
        <MobileClientsFilter />
        <AppTable
          searchStr={query && query?.length > 2 ? query : undefined}
          type="messages"
          columns={getMessagesColumns(showMessage)}
          mobileRowComponent={createMessageRow(showMessage)}
          apiConfig={{
            fetchData: api.fetchNotificationsLogs,
            magicMinus: true,
            actualOffset: true,
            mapping: {
              pageKey: 'page[offset]',
              maxCountKey: 'page[limit]',
              dataKey: 'data',
              totalCountKey: 'meta.pagination.total',
              searchKey: 'search_phrase',
              emptyStateKey: 'meta.emptyState',
            },
          }}
          showPlaceholder
          placeholder={{
            imgName: 'messages',
            titleLabel: 'state.messageLog.title',
            descriptionLabel: 'state.messageLog.description',
            linkLabel: 'state.messageLog.link',
            path: RoutePath.SETUP_NOTIFICATIONS,
          }}
        />
      </AppLayout>
    </NoInternetConnection>
  );
});
