import { useContext } from 'react';
import { __RouterContext as RouterContext } from 'react-router';

export function useRouter() {
  return useContext(RouterContext);
}

export function useParams() {
  const { match } = useRouter();
  return match.params;
}

export function useHistory() {
  const { history } = useRouter();
  return history;
}

export function useLocation() {
  const { location, history, match } = useRouter();

  function navigate(to: string, id?: string, { replace = false, backPath }: any = {}) {
    let nextPath = id ? `${to}/${id}` : to;

    if (backPath) {
      nextPath = `${nextPath}?backPath=${backPath}`;
    }
    if (replace) {
      history.replace(nextPath);
    } else {
      history.push(nextPath);
    }
  }

  function goBack() {
    history.goBack();
  }

  const urlQuery = (): any | undefined => {
    const searchParams = new URLSearchParams(location.search);
    const params = {} as any;
    for (let [key, value] of searchParams.entries() as any) {
      params[key] = value;
    }
    return params;
  };

  return {
    params: match.params,
    location,
    navigate,
    goBack,
    urlQuery,
  };
}
