import styled from 'styled-components';
import { Row } from 'antd';

export const Container = styled(Row)`
  padding: 40px 15px 15px 15px;
`;

export const ClientItem = styled.div`
  padding: 15px;
`;
