import { Icon, Spin } from 'antd';
import React from 'react';
import { Text, useModal } from '@plandok/core';
import { useBookingLocationsQuery } from 'graphql/queries/bookingLocations.generated';
import { App_Location } from 'graphql/generatedTypes';
import BookingProfileCard from './components/BookingProfileCard/BookingProfileCard';
import * as SC from './styles';

export default function BookingProfile() {
  const { data, loading, refetch } = useBookingLocationsQuery();
  const [, { showModal }] = useModal();

  const locations = data?.locations?.edges?.map((e: any) => e?.node)?.filter(Boolean) as App_Location[];

  const openCreateLocationModal = () =>
    showModal({
      type: 'CREATE_LOCATION',
      modalProps: { onSuccess: refetch },
    });

  return (
    <SC.Container>
      {loading ? (
        <Spin spinning />
      ) : (
        <>
          <SC.AddButton bold upperCase={false} onClick={openCreateLocationModal} minorBtn>
            <Icon type="plus" />{' '}
            <Text label="btn.addExtraLocation" size="base" colorType="white" weight="semiBold" mb="none" />
          </SC.AddButton>
          {locations?.map((e, i) => (
            <BookingProfileCard location={e} key={e?.id || i} refetch={refetch} />
          ))}
        </>
      )}
    </SC.Container>
  );
}
