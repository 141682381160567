import React from 'react';
import { IntlLabel, LabelKey } from '@plandok/i18n';
import { CustomIcon } from '@plandok/core';
import { CallAction } from 'components';

export default function ClientInfoItem(props: { icon: any; label: LabelKey; text?: any; isPhone?: boolean }) {
  if (!props.text) {
    return null;
  }

  return (
    <li>
      <div data-info-item-icon>
        <CustomIcon type={props.icon} />
      </div>
      <div data-info-item-text>
        <div>
          <IntlLabel label={props.label} />
        </div>
        <IntlLabel label={props.text} />
      </div>
      {props.isPhone && <CallAction phoneNumber={props.text} />}
    </li>
  );
}
