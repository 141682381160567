import styled, { css } from 'styled-components';
import React, { useEffect } from 'react';
import { Spin } from 'antd';
import cn from 'classnames';
import { mediaMobileDown, getIsMobile } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { AppLayout, HashTabs, MobileBackButton, NoInternetConnection } from 'components';
import { BillingTabHash } from 'constants/routes';
import useQuery from 'hooks/data/useQuery';
import * as api from 'api';
import EstimatedCostsPage from './EstimatedCostsPage';
import { PriceType } from './PricingPage/constants';
import PaymentMethodPage from './PaymentMethodPage';
import BillingInfoPage from './BillingInfoPage';
import InvoicesPage from './InvoicesPage';
import PricingPage from './PricingPage';
import './style.less';
import { billingTabs } from './config';

const Container = styled.div`
  overflow: auto;
  padding: 0 30px;
  min-height: calc(100vh - 131px);

  ${mediaMobileDown(
    css`
      max-height: calc(100vh - 60px - 50px - 60px);
      padding: 0 8px;
    `
  )};
`;

const BillingPage = () => {
  const { data, isLoading, fetchData }: any = useQuery({
    fetchData: api.fetchSubscriptionInfo,
  });

  useEffect(() => {
    (() => {
      const isCacheWasCleared = localStorage.getItem('clearedCache1');

      if ('caches' in window && !isCacheWasCleared) {
        caches.keys().then((names) => names.forEach((name) => caches.delete(name)));
        localStorage.setItem('clearedCache1', 'true');
        window.location.reload();
      }
    })();
  }, []);

  const tabsWithoutPaymentMethod = billingTabs.filter(({ path }) => path !== BillingTabHash.PAYMENT_METHOD);
  const isFreePlan = data?.type === PriceType.FREE;
  const isMobile = getIsMobile();

  return (
    <NoInternetConnection>
      <AppLayout
        mobileHeader={() => (
          <>
            <MobileBackButton />
            <div>
              <IntlLabel label="title.billingPlan" />
            </div>
            <div />
          </>
        )}
        headerTitle="title.billingPlan"
        className={cn('billing-page', { 'billing-page__is-mobile': isMobile })}
        planType={data?.type}
        isSubscriptionLoading={isLoading}
      >
        <Spin spinning={isLoading}>
          <HashTabs
            tabs={isFreePlan ? tabsWithoutPaymentMethod : billingTabs}
            defaultTab={BillingTabHash.PLAN}
            isChildrenObserver
            className="page-tabs-content-overflow"
          >
            <Container>
              <PricingPage fetchCurrentPlan={fetchData} />
            </Container>
            <Container>
              <EstimatedCostsPage />
            </Container>
            <Container>
              <BillingInfoPage />
            </Container>
            <Container>
              <InvoicesPage />
            </Container>
            {!isFreePlan && (
              <Container>
                <PaymentMethodPage />
              </Container>
            )}
          </HashTabs>
        </Spin>
      </AppLayout>
    </NoInternetConnection>
  );
};

export default BillingPage;
