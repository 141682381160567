import styled from 'styled-components';

export const FormTooltipContainer = styled.div`
  display: inline;
  padding-left: 5px;
  i {
    height: 13px;
    width: 13px;
    color: #adb6cc;
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.primaryColor};
    }
  }
`;
