import React from 'react';
import { ModalMap } from './constants/constants';
import { useModal } from '@plandok/core';

export default function ModalContainer() {
  const [{ modals }, { hideModal, hideAllModals }] = useModal();

  return modals.map(({ type, modalProps, visible }: any) => {
    const ModalComponent = (ModalMap as any)[type];
    const hideCurrentModal = () => hideModal(type);

    return (
      <ModalComponent
        key={type}
        modalProps={modalProps}
        onClose={hideCurrentModal}
        hideAllModals={hideAllModals}
        visible={visible}
      />
    );
  });
}
