import styled, { css } from 'styled-components';
import { Upload } from 'antd';
import { UploadContainerProps } from '../index';

export const UploadContainer = styled(Upload)<UploadContainerProps>`
  .ant-upload {
    display: flex;
    align-items: center;
  }

  .ant-upload-list-item-progress {
    display: none;
  }

  .ant-upload-list {
    display: ${({ hideList }) => (hideList ? 'none' : 'inherit')};
  }

  .ant-upload-list-item-uploading-text {
    display: none;
  }

  .ant-upload.ant-upload-select-picture-card {
    border: none;
    background-color: inherit;
    height: 0;
    margin-bottom: 0;
    margin-top: 8px;
  }

  .ant-upload-list-item-error {
    border: none;
  }

  .ant-upload-list-item-info::before {
    background-color: inherit;
  }

  .ant-upload-list-item-thumbnail img {
    border-radius: 50%;
  }

  .ant-upload-list-item-thumbnail {
    opacity: 1;
  }

  .ant-upload-list-item {
    width: 100px;
    height: 100px;
    padding: 0;
    border: 0;
  }

  .ant-upload-list-item-info {
    border: 3px solid #fff;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgb(38 60 122 / 20%);
  }

  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    padding: 0;
  }

  .anticon svg {
    display: none;
  }

  ${({ uploaded }) => css`
    svg {
      z-index: 10;
      position: ${uploaded ? 'absolute' : 'inherit'};
      bottom: ${uploaded ? '7px' : 'inherit'};
      left: ${uploaded ? '-9px' : 'inherit'};
      top: ${uploaded ? '-103px' : 'auto'};
      width: ${uploaded ? '120px' : 'auto'};
      height: ${uploaded ? '122px' : 'auto'};
    }
  `}
`;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PopoverRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;

  &:hover {
    cursor: pointer;
    background: #d4d4d4;

    &:first-child {
      border-radius: 5px 5px 0 0;
    }

    &:last-child {
      border-radius: 0 0 5px 5px;
    }
  }
`;

export const OptionButton = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
