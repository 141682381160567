import React from 'react';
import { Button, Text, getIsMobile } from '@plandok/core';
import { NotReleased, Placeholder } from 'components';
import { MarketingHash } from 'constants/routes';
import { useLocation } from 'hooks/router';
import * as SC from './styles';

export default function MarketingOverview() {
  const { navigate } = useLocation();
  const redirectCreateMarketingMass = () => navigate(`#${MarketingHash.MASSMESSAGES}`);
  const redirectCreateMarketingCampaign = () => navigate(`#${MarketingHash.CAMPAIGNS}`);
  const isMobile = getIsMobile();

  return (
    <NotReleased>
      <SC.Container>
        <Placeholder
          mobileTitleContent={
            <Text label="Mass messages" weight="bold" size="large" data-marketing-placeholder-mobile-title />
          }
          imgName={`${!isMobile ? 'Mass-messages' : 'mass messages'}`}
          titleLabel="Mass messages"
          descriptionLabel="Send custom marketing messages to your clients via SMS or Email with few simple clicks."
          position="relative"
          containerComponent={SC.PlaceholderContainer}
          templateOptions={{ description: true }}
          btnLabel="Send message"
          onClick={redirectCreateMarketingMass}
        />
        <SC.ActionContainer>
          <Button label="Send message" type="primary" onClick={redirectCreateMarketingMass} upperCase={false} />
        </SC.ActionContainer>
        <Placeholder
          mobileTitleContent={
            <Text label="Auto-sending campaigns" weight="bold" size="large" data-marketing-placeholder-mobile-title />
          }
          imgName={`${!isMobile ? 'auto-sending-campaign' : 'campaigns'}`}
          titleLabel="Auto-sending campaigns"
          descriptionLabel="Create personalised campaigns based on events like clients' birthdays and more. Send cross multiple channels to deliver campaigns by email and text message."
          position="relative"
          containerComponent={SC.PlaceholderContainer}
          templateOptions={{ description: true }}
          btnLabel="New Campaign"
          onClick={redirectCreateMarketingCampaign}
          direction={true}
        />
        <SC.ActionContainer>
          <Button label="New Campaign" type="primary" onClick={redirectCreateMarketingCampaign} upperCase={false} />
        </SC.ActionContainer>
      </SC.Container>
    </NotReleased>
  );
}
