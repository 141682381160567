import './api';
import './app.d';
import './styles/app/index.less';
import '@plandok/core/styles/index.less';

import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { IoC as CoreIoC, theme } from '@plandok/core';
import { getInitialLanguage, IntlProvider, IoC as IntlIoC } from '@plandok/i18n';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import ReactDOM from 'react-dom';
import { ReactQueryConfigProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';

import App from './App';
import ErrorBoundary from './ErrorBoundary';
import { getAsyncSelectConfig } from './helpers/app/lookups';
import { useCountry, useCurrencyCode } from './hooks/info/useCurrencyCode';
import * as serviceWorkers from './serviceWorkers';
import { Integrations } from '@sentry/tracing';
import { graphqlClient } from './graphql';

LogRocket.init('zbckxa/plandok');

// Registration of service that will be called in
// Components to get currencyCode in i18n library
IntlIoC.registerService({ useCurrencyCode });
// Components to get asyncSelect components
CoreIoC.registerService({ getAsyncSelectConfig, useCountry });

if (process.env.NODE_ENV === 'production') {
  const version = process.env.REACT_APP_SENTRY_RELEASE || undefined;
  if (version) {
    console.log('Hi there 👋, you are using version ' + version);
  }

  if (process.env.REACT_APP_DATADOG_APP_ID && process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
    // Datadog RUM Browser Monitoring init
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APP_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      sampleRate: 100,
      trackInteractions: true,
      version: version,
    });
  }

  if (process.env.REACT_APP_SENTRY_API_URL) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_API_URL,
      release: version,
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing()],
      ignoreErrors: ['Non-Error promise rejection captured'],
    });
  }
}

const queryConfig = {
  refetchOnMount: false,
  refetchAllOnWindowFocus: false,
  staleTime: 0,
};

ReactDOM.render(
  <ErrorBoundary>
    <ApolloProvider client={graphqlClient}>
      <ReactQueryConfigProvider config={queryConfig}>
        <ThemeProvider theme={theme}>
          <IntlProvider getInitialLanguage={getInitialLanguage}>
            <App />
          </IntlProvider>
        </ThemeProvider>
      </ReactQueryConfigProvider>
    </ApolloProvider>
  </ErrorBoundary>,
  document.getElementById('app')
);

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept();
}

serviceWorkers.register();
