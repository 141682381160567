import React from 'react';
import { ModifyLayout, NoInternetConnection } from 'components';
import { useHistory, useParams } from 'hooks/router';
import withFormApi from 'hocs/form/withFormApi';
import { RoutePath } from 'constants/routes';
import * as api from 'api';
import ServiceForm from './components/ServiceForm';

export const ServiceFormWithApi = withFormApi({
  editAction: api.editService,
  detailsAction: api.fetchServiceDetails,
  createAction: api.createService,
  backPath: RoutePath.SERVICES,
  successMessage: 'Service Created',
})(ServiceForm);

export default function ModifyServicePage() {
  const params: any = useParams();
  const { location } = useHistory();
  const queryParams = new URLSearchParams(location.search || '');
  const serviceGroupId = queryParams.get('serviceGroupId');
  const isEdit = params && params.id;

  return (
    <NoInternetConnection>
      <ModifyLayout title={isEdit ? 'service.edit.title' : 'service.new.title'}>
        <ServiceFormWithApi initialValues={{ duration: 60, serviceGroupId, isBookableOnline: true }} />
      </ModifyLayout>
    </NoInternetConnection>
  );
}
