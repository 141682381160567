import { AppointmentCancellationsHash, AppointmentsSummaryHash, RoutePath } from '../../../../constants/routes';

export enum KeyPath {
  appointmentsReport = 'appointmentsReport',
  appointmentsSummary = 'appointmentsSummary',
  appointmentCancellations = 'appointmentCancellations',
  clientsReport = 'clientsReport',
  reports = 'reports',
}

export type Element = {
  path: string;
  keyPath: KeyPath;
  title: string;
  description: string;
};

export type Setting = {
  title: string;
  description: string;
  elements: Element[];
};

export type ReportCardSettings = {
  APPOINTMENT: Setting;
  CLIENT: Setting;
};

export const reportCardSettings: ReportCardSettings = {
  APPOINTMENT: {
    title: 'reports.appointments.title',
    description: 'reports.appointments.description',
    elements: [
      {
        path: RoutePath.REPORTS_APPOINTMENT_LIST,
        keyPath: KeyPath.appointmentsReport,
        title: 'reports.appointmentsList.title',
        description: 'reports.appointmentsList.description',
      },
      {
        path: RoutePath.REPORTS_APPOINTMENT_SUMMARY,
        keyPath: KeyPath.appointmentsSummary,
        title: 'reports.appointmentsSummary.title',
        description: 'reports.appointmentsSummary.description',
      },
      {
        path: RoutePath.REPORTS_APPOINTMENT_CANCELLATIONS,
        keyPath: KeyPath.appointmentCancellations,
        title: 'reports.appointmentsCancellations.title',
        description: 'reports.appointmentsCancellations.description',
      },
    ],
  },
  CLIENT: {
    title: 'reports.clients.title',
    description: 'reports.clients.description',
    elements: [
      {
        path: RoutePath.REPORTS_CLIENTS,
        keyPath: KeyPath.clientsReport,
        title: 'reports.clientsList.title',
        description: 'reports.clientsList.description',
      },
    ],
  },
};

export const appointmentsSummaryTabsConfig: any = [
  {
    path: AppointmentsSummaryHash.STAFF,
    title: 'reports.appointmentsByStaff.title',
  },
  {
    path: AppointmentsSummaryHash.SERVICE,
    title: 'reports.appointmentsByService.title',
  },
];

export const appointmentCancellationsTabsConfig: any = [
  {
    path: AppointmentCancellationsHash.SUMMARY,
    title: 'reports.summary.title',
  },
  {
    path: AppointmentCancellationsHash.CANCELLATIONS,
    title: 'reports.appointmentCancellations.title',
  },
];

export const appointmentsSummaryHeadersConfig = {
  first: [
    { label: 'Staff Member', key: 'staffMember' },
    { label: 'Appointments', key: 'totalCount' },
    { label: 'Total Value', key: 'totalPrice' },
  ],
  second: [
    { label: 'Service Name', key: 'name' },
    { label: 'Appointments', key: 'appointments' },
    { label: 'Total Value', key: 'totalValue' },
  ],
};

export const appointmentsListHeadersConfig = [
  { label: 'Appointment', key: 'appointment' },
  { label: 'Client', key: 'client' },
  { label: 'Service', key: 'service' },
  { label: 'Date', key: 'date' },
  { label: 'Time', key: 'time' },
  { label: 'Duration', key: 'duration' },
  { label: 'Location', key: 'location' },
  { label: 'Staff', key: 'staff' },
  { label: 'Price', key: 'price' },
  { label: 'Status', key: 'status' },
];

export const appointmentCancellationHeadersConfig = {
  first: [
    { label: 'Reason', key: 'reason' },
    { label: 'Total Count', key: 'totalCount' },
  ],
  second: [
    { label: 'Ref', key: 'ref' },
    { label: 'Client', key: 'client' },
    { label: 'Service', key: 'service' },
    { label: 'Schedule Date', key: 'scheduleDate' },
    { label: 'Cancelled Date', key: 'cancelledDate' },
    { label: 'Cancelled By', key: 'cancelledBy' },
    { label: 'Price', key: 'price' },
  ],
};

export const appointmentClientsHeadersConfig = [
  { label: 'Name', key: 'name' },
  { label: 'Appointments', key: 'appointments' },
  { label: 'No Shows', key: 'noShows' },
  { label: 'Total Sales', key: 'totalSales' },
  { label: 'Gender', key: 'gender' },
  { label: 'Added', key: 'createdAt' },
  { label: 'Days Absent', key: 'daysAbsent' },
  { label: 'Last Appointment', key: 'lastAppointmentAt' },
  { label: 'Last Location', key: 'lastLocation' },
];
