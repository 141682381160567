import axios from './axios';
import { LocalStorage } from '@plandok/core';

const appToken = LocalStorage.getAuthToken();
axios.defaults.baseURL = `${process.env.REACT_APP_API_ENDPOINT}/api`;
if (appToken) {
  axios.defaults.headers.Authorization = `Bearer ${appToken}`;
}

export * from './auth';
export * from './client';
export * from './setup';
export * from './calendar';
export * from './billing';
