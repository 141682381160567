/* eslint-disable react/jsx-curly-brace-presence */
import { Pagination as AntPagination } from 'antd';
import React from 'react';

import * as SC from './styles';

export default function Pagination({ page, count = 0, totalCount, maxCount = 10, onChange }: any) {
  return (
    <SC.Container>
      <SC.TotalNumbers>
        Showing{' '}
        <b>
          {(page - 1) * maxCount} to {(page - 1) * maxCount + count} of {totalCount}
        </b>{' '}
        results
      </SC.TotalNumbers>
      {!!totalCount && (
        <AntPagination
          total={totalCount}
          pageSize={maxCount}
          defaultCurrent={page}
          key={page}
          className="pagination-component"
          onChange={onChange}
        />
      )}
    </SC.Container>
  );
}
