import { Spin } from 'antd';
import React from 'react';
import { AppSetupList, InfoPlaceholder, NoInternetConnection } from 'components';
import useGetCurrentLocationsCount from 'hooks/use-get-current-locations-count';
import { PriceType } from 'pages/plans/BillingPage/PricingPage/constants';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import useQuery from 'hooks/data/useQuery';
import useAlert from 'hooks/use-alert';
import * as api from 'api';
import { locationColumns } from './config';

export default function LocationsPage() {
  const { isAlertLoading, subscriptionCancelled, notificationCancelled } = useAlert();
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const { locationsCount } = useGetCurrentLocationsCount();

  const { data }: any = useQuery({
    fetchData: api.fetchPlans,
    extractDataFunc: (_) => _,
  });

  const planId = data?.meta.currentPlanId;

  return (
    <NoInternetConnection>
      <Spin spinning={isSubscriptionLoading}>
        <AppSetupList
          btnLabel="btn.new.location"
          columns={locationColumns}
          title="title.locations"
          modalType={
            subscriptionCancelled || notificationCancelled
              ? 'LATEST_PAYMENT_FAILED'
              : planType === PriceType.PREMIUM
              ? 'CREATE_LOCATION'
              : locationsCount < 1
              ? 'CREATE_LOCATION'
              : 'PREMIUM_UPGRADE_REQUIRED'
          }
          initialValues={{ planType, planId, isAlertLoading, locationPage: true }}
          fetchData={api.fetchLocations}
          onPositionUpdate={async (id: string, newPosition: number) =>
            await api.changeLocationPosition('', { id, newPosition })
          }
          infoPlaceholder={
            <InfoPlaceholder
              imgName="locations"
              titleLabel="state.locations.title"
              descriptionLabel="state.locations.description"
            />
          }
        />
      </Spin>
    </NoInternetConnection>
  );
}
