import * as api from 'api';
import { useEffect, useState } from 'react';

export interface IPaymentType {
  id: string;
  paymentType: string;
  isActive: boolean;
  accountDescription?: string;
  isOnboard: boolean;
  paymentEnable: boolean;
}

export enum PaymentTypeEnum {
  ON_SITE = 'on_site',
  STRIPE_PAYMENT = 'stripe_payment',
}

function PaymentTypesController() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [paymentTypes, setPaymentTypes] = useState({
    [PaymentTypeEnum.ON_SITE]: {},
    [PaymentTypeEnum.STRIPE_PAYMENT]: {},
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      let response = await api.getAllPaymentTypes();
      setLoading(false);

      if (response?.data) {
        setPaymentTypes({
          [PaymentTypeEnum.ON_SITE]: response.data.filter(
            (item: any) => item.paymentType === PaymentTypeEnum.ON_SITE
          )[0],
          [PaymentTypeEnum.STRIPE_PAYMENT]: response.data.filter(
            (item: any) => item.paymentType === PaymentTypeEnum.STRIPE_PAYMENT
          )[0],
        });
      }
    })();
    return () => {};
  }, [refresh]);

  const changeStatus = async (paymentType: PaymentTypeEnum, status: boolean) => {
    setError(undefined);
    setLoading(true);
    let response = await api.updatePaymentStatus(paymentType, { status });
    setLoading(false);

    if (response?.error) {
      setError(response.error?.message);

      return;
    }

    let value = paymentTypes[paymentType] as IPaymentType;
    value.isActive = status;

    setPaymentTypes((prev) => ({ ...prev, paymentType: value }));
  };

  return {
    error,
    paymentTypes,
    changeStatus,
    loading,
    refresh: () => setRefresh((p) => p + 1),
  };
}

export default PaymentTypesController;
