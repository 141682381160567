import { withCurrencySign } from '@plandok/i18n';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import React, { Component, ComponentType } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { IoC } from '../../../../di.config';
import SelectInput from '../../input/SelectInput';
import CustomFilterSelectInput from '../../input/CustomFilterSelectInput';
import { extractOptions, filterOptions } from '../../../../helpers/form/helpers';

export interface AsyncSelectInputProps {
  input: Partial<FieldRenderProps<any, any>['input']>;
  asyncType: string;
  selectComponent?: ComponentType<any>;
  selectFirstAsDefault?: any;
  onInitialLoad?: any;
  showSearch?: boolean;
  predefinedOptions?: any[];
  filterParams?: any;
  className?: any;
  // hide component if there is only one option
  hideForOne?: boolean;
  // add currency sign to add it in services
  sign: string;
  isCustomSearchOption?: boolean;
  addBtn?: string;
  modalType?: string;
  isWorkingTime?: boolean;
  showModal?: () => any;
  hideModal?: () => any;
}

class AsyncSelectInput extends Component<AsyncSelectInputProps, any> {
  constructor(props: any) {
    super(props);
    const config = IoC.getService().getAsyncSelectConfig(props.asyncType);
    this.fetchData = config.fetchData;
    this.mapping = config.mapping || {};
    this.isGroup = !!config.groupValueExtractor;
    this.groupValueExtractor = config.groupValueExtractor;
  }

  componentDidUpdate(prevProps: Readonly<AsyncSelectInputProps>, prevState: Readonly<any>, snapshot?: any): void {
    if (!isEqual(this.props.filterParams, prevProps.filterParams)) {
      this.getOptions();
    }
  }

  state = {
    options: [],
    loading: true,
    filter: '',
  };

  getSupportInfo = (): { sign: string } => {
    return {
      sign: this.props.sign,
    };
  };

  componentDidMount(): void {
    this.getOptions(this.props.selectFirstAsDefault);
  }

  getOptions = (selectFirstAsDefault?: boolean) => {
    this.setState({ loading: true });
    this.fetchData(this.props.filterParams).then((data: any) => {
      const options = this.isGroup
        ? this.groupValueExtractor(data, this.getSupportInfo())
        : extractOptions(data, this.mapping);
      this.setState(
        {
          options,
          loading: false,
        },
        () => {
          if (selectFirstAsDefault && this.props.input && !this.props.input.value) {
            const initValue: any = get(options, '[0].code');
            // @ts-ignore
            this.props.input.onChange(this.props.mode === 'multi' ? [initValue] : initValue);
          }

          if (this.props.onInitialLoad) {
            this.props.onInitialLoad(this.props.input.onChange, options, this.props.input.value);
          }
        }
      );
    });
  };

  private fetchData: (a?: any) => any;
  private mapping: any;
  private isGroup: boolean;
  private groupValueExtractor: (a: any, supportInfo: any) => any;

  onSearch = (filter: any) => {
    if (this.props.showSearch) {
      this.setState({ filter });
    }
  };

  onFilterMenu = (filter: string) => {
    this.setState({ filter });
  };

  onGetOptions = () => {
    this.getOptions();
  };

  preventDefaultFilterOptionFn = () => true;

  render() {
    const {
      input,
      predefinedOptions,
      selectComponent: SelectComponent = SelectInput,
      isCustomSearchOption,
      addBtn,
      section,
      modalType,
      showModal,
      hideModal,
      isWorkingTime,
      ...rest
    }: any = this.props;
    const { options, loading, filter } = this.state;

    const filteredOptions = filterOptions(options, this.isGroup, filter, predefinedOptions);

    const isHidden = this.props.hideForOne && !loading && (!options?.length || options?.length < 2);

    if (isHidden) {
      return null;
    }
    return (
      <>
        {!addBtn && !isCustomSearchOption ? (
          <SelectComponent
            {...input}
            {...rest}
            onSearch={this.onSearch}
            options={!this.isGroup && filteredOptions}
            optionGroups={this.isGroup && filteredOptions}
            loading={loading}
            filterOption={this.preventDefaultFilterOptionFn}
          />
        ) : (
          <CustomFilterSelectInput
            {...input}
            {...rest}
            filterTxt={filter}
            onSearch={this.onSearch}
            onSubFilter={this.onFilterMenu}
            options={!this.isGroup && filteredOptions}
            optionGroups={this.isGroup && filteredOptions}
            loading={loading}
            filterOption={this.preventDefaultFilterOptionFn}
            modalType={modalType}
            addBtn={addBtn}
            section={section}
            isCustomSearchOption={isCustomSearchOption}
            onGetOptions={this.onGetOptions}
            showModal={showModal}
            hideModal={hideModal}
            isWorkingTime={isWorkingTime}
          />
        )}
      </>
    );
  }
}

export default withCurrencySign(AsyncSelectInput);
