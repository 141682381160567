import styled, { css } from 'styled-components';
import { mediaMobileDown } from '@plandok/core';
import { FC, ComponentProps } from 'react';

type Type = FC<Omit<ComponentProps<'div'>, 'stickyColumnCount'>>;

const Table = styled<any>('div').attrs({
  className: 'sticky-table-table',
})`
  white-space: nowrap;
  display: table;
  box-sizing: border-box;
  width: 100%;
`;

Table.displayName = 'Table';

const Cell = styled<any>('div').attrs({
  className: 'sticky-table-cell',
})`
  display: table-cell;
  box-sizing: border-box;
  padding: 15px 30px 15px 15px;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  background-color: #fff;
`;

Cell.displayName = 'Cell';

const Row = styled<Type>('div' as unknown as Type).attrs({
  className: 'sticky-table-row',
})<any>`
  display: table-row;
  & ${Cell}:first-child {
    //border-right: 2px solid #e5e5e5;
  }
`;

Row.displayName = 'Row';

const Wrapper = styled<Type>('div' as unknown as Type).attrs({
  className: 'sticky-table',
})<any>`
  position: relative;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: ${(props) => (props.disableShadow ? 'none' : '0 3px 25px rgba(19, 49, 109, 0.08)')};
  border-radius: ${(props) => props.theme.borderRadiusBase};

  ${Cell} {
    &:first-of-type {
      padding-left: 30px;
    }
  }
  ${Row}:first-of-type ${Cell} {
    font-weight: 500;
    color: ${(props) => props.theme.primaryColor};
    padding: 20px 30px 20px 15px;
    &:first-of-type {
      padding-left: 30px;
    }
  }
  & ${Row}:nth-child(${(props) => `-n+${props.stickyHeaderCount}`}) ${Cell} {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  & ${Row} ${Cell}:nth-child(-n+${(props) => props.stickyColumnCount}) {
    position: sticky;
    left: 0;
  }
  &
    ${Row}:nth-child(-n+${(props) => props.stickyHeaderCount})
    ${Cell}:nth-child(-n+${(props) => props.stickyColumnCount}) {
    position: sticky;
    top: 0;
    left: 0;
  }
`;

const Container = styled.div<any>`
  height: calc(100vh - 300px);
  ${mediaMobileDown(css`
    height: ${({ mobileHeight }: any) => mobileHeight || 'calc(100vh - 245px)'};

    ${Wrapper} {
      border-radius: 0;
    }
  `)}
` as any;

Wrapper.displayName = 'Wrapper';

/* eslint-disable import/no-anonymous-default-export */
export default {
  Container,
  Table,
  Cell,
  Row,
  Wrapper,
};
