import React from 'react';

import * as RSH from 'react-sortable-hoc';
import { formatDuration } from 'helpers/date';
import { IntlMoney } from '@plandok/i18n';
import SmallDragIcon from './SmallDragIcon';
import * as SC from '../style';

const DragHandle = RSH.SortableHandle(() => (
  <div data-service-item-drag>
    <SmallDragIcon />
  </div>
));
function ServiceItem({ item = {}, editService, groupId }: any) {
  const editItem = () => {
    editService(item.id, groupId);
  };

  return (
    <SC.ServiceItem onClick={editItem}>
      <DragHandle />
      <div data-service-item-content>
        <div>{item.name}</div>
        <div>{formatDuration(item.duration)}</div>
        <div>
          <IntlMoney value={item.price} />
        </div>
      </div>
    </SC.ServiceItem>
  );
}

export default RSH.SortableElement(ServiceItem);
