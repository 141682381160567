import { isMobile } from 'react-device-detect';
import React from 'react';
import { Text, CustomIcon } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import MobileMarketingMassMessage from './MobileMarketingMassMessage';
import { queryCall, query } from '../../../../graphql';
import { AppTable, NotReleased } from 'components';
import { RoutePath } from 'constants/routes';
import { messageLogColumns } from './config';
import { useLocation } from 'hooks/router';
import * as SC from './styles';

export default function MarketingMassmessages() {
  const fetchMessageLog = queryCall(query.MASS_MARKETING_CAMPAIGN);
  // const fetchMessageLog = useMassMarketingCampaignQuery();
  const { navigate } = useLocation();
  const smsCreateMassage = () => navigate(RoutePath.MESSAGE_CREATE);
  const emailCreateMassage = () => navigate(RoutePath.EMAIL_MESSAGE_CREATE);

  const massageLogData = true;

  return (
    <NotReleased>
      <>
        {isMobile ? (
          <MobileMarketingMassMessage />
        ) : (
          <div>
            {!massageLogData ? (
              <SC.Container>
                <SC.SPlaceholderTitle>
                  <IntlLabel label="Setup new message" />
                </SC.SPlaceholderTitle>
                <SC.SPlaceholderDescription>
                  <IntlLabel label="Send custom marketing messages to your clients via SMS or Email with few simple clicks" />
                </SC.SPlaceholderDescription>
                <SC.MassWrapper>
                  <SC.MassBtn onClick={smsCreateMassage}>
                    <CustomIcon type="SMS" />
                    <Text label="dropdown.notificationBy.sms" weight="bold" size="large" mb="none" />
                  </SC.MassBtn>
                  <SC.MassBtn onClick={emailCreateMassage}>
                    <CustomIcon type="Email" />
                    <Text label="dropdown.notificationBy.email" weight="bold" size="large" mb="none" />
                  </SC.MassBtn>
                </SC.MassWrapper>
              </SC.Container>
            ) : (
              <div>
                <SC.MessageContainer>
                  <div>
                    <SC.SPlaceholderTitle>
                      <IntlLabel label="Setup new message" />
                    </SC.SPlaceholderTitle>
                    <SC.SPlaceholderDescription>
                      <IntlLabel label="Send custom marketing messages to your clients via SMS or Email with few simple clicks" />
                    </SC.SPlaceholderDescription>
                  </div>
                  <SC.MassWrapper>
                    <SC.MassBtn onClick={smsCreateMassage}>
                      <CustomIcon type="SMS" />
                      <Text label="dropdown.notificationBy.sms" weight="bold" size="large" mb="none" />
                    </SC.MassBtn>
                    <SC.MassBtn onClick={emailCreateMassage}>
                      <CustomIcon type="Email" />
                      <Text label="dropdown.notificationBy.email" weight="bold" size="large" mb="none" />
                    </SC.MassBtn>
                  </SC.MassWrapper>
                </SC.MessageContainer>
                <Text size="xlarge" weight="semiBold">
                  <IntlLabel label="Message log" />
                </Text>
                <SC.MessageLog>
                  <AppTable
                    type="messageLog"
                    columns={messageLogColumns}
                    mobileClassName="app_clients-list--mobile"
                    apiConfig={{
                      fetchData: fetchMessageLog,
                      mapping: {
                        pageKey: 'page[offset]',
                        maxCountKey: 'page[limit]',
                        dataKey: 'massMarketingCampaign',
                        totalCountKey: 'messageLog.totalCount',
                        searchKey: 'query',
                        emptyStateKey: 'messageLog.emptyState',
                      },
                    }}
                  />
                </SC.MessageLog>
              </div>
            )}
          </div>
        )}
      </>
    </NotReleased>
  );
}
