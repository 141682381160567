import styled, { css } from 'styled-components';
import { mediaMdDown, Card } from '@plandok/core';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 10px;

  ${mediaMdDown(css`
    flex-direction: column;
  `)}
`;

export const AnalyticCard = styled(Card)`
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  width: 100%;
  max-width: 392px;
  padding: 16px 24px 72px 48px;
  text-align: center;
  border-radius: 20px !important;
  margin: 0 24px;

  ${mediaMdDown(css`
    max-width: 343px;
    padding: 16px 24px 64px 32px;
    margin: 0 auto 16px;
  `)}
`;

export const StatusTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 27px;
  div {
    color: ${({ theme }) => theme.color};
  }
`;

export const CardContainer = styled.div`
  padding-right: 24px;

  img {
    max-width: 110px;
    max-height: 110px;
    align-self: center;
    margin-bottom: 32px;
  }

  button {
    margin-top: 4px;
    min-width: 176px;
  }

  ${mediaMdDown(css`
    padding-right: 8px;
  `)}
`;

export const BookingAnalyticsInfo = styled.div`
  padding: 19px 24px 40px;
  line-height: 26px;

  .ant-form-item-label {
    font-weight: 600;
    line-height: inherit;
    margin-top: 5px;
    font-size: 14px;
    padding-bottom: 5px;
  }

  button {
    margin-bottom: 24px;
    background: #136ef6;
    border: none;
  }

  a {
    text-decoration: none;
    margin-left: 5px;
    div {
      color: #136ef6;
    }
  }
`;

export const AnalyticsImg = styled.img`
  display: flex;
  width: 255px;
  height: 187px;
  margin: 46px auto 24px;
`;
