import React from 'react';
import { CustomIcon } from '@plandok/core';
import * as SC from './styles';

interface Call {
  phoneNumber: string;
}

export default function CallAction(props: Call) {
  return (
    <a href={`tel:${props.phoneNumber}`}>
      <SC.PhoneCallButton>
        <CustomIcon type="call" />
      </SC.PhoneCallButton>
    </a>
  );
}
