import create from 'zustand';

type State = {
  allowSaveButton: boolean;
  locationId: string;
  employeeId: string;
  page: number;
  startDate: Date | null;
  endDate: Date | null;
  isTimeRangeSelected: boolean;
  frequency: string;
  repeatingValue: string;
  specificDate: string;
  rescheduleItemId: string;
  rescheduleItemDate: string | Date | undefined;
  rescheduleItemTime: number | undefined;
  rescheduleItemStaff: string;
  setStartDate: (startDate: Date | null) => void;
  setEndDate: (endDate: Date | null) => void;
  setIsTimeRangeSelected: (isTimeRangeSelected: boolean) => void;
  setAllowSaveButton: (allowSaveButton: boolean) => void;
  setEmployeeId: (employeeId: string) => void;
  setLocationId: (locationId: string) => void;
  setPickerCleared: (pickerCleared: boolean) => void;
  setPage: (page: number) => void;
  setFrequency: (frequency: string) => void;
  setRepeatingValue: (repeatingValue: string) => void;
  setSpecificDate: (repeatingValue: string) => void;
  setRescheduleItemId: (rescheduleItemId: string) => void;
  setRescheduleItemDate: (rescheduleItemDate: string | Date | undefined) => void;
  setRescheduleItemTime: (rescheduleItemTime: number | undefined) => void;
  setRescheduleItemStaff: (rescheduleItemStaff: string) => void;
};

export const useStore = create<State>((set) => ({
  allowSaveButton: false,
  locationId: '',
  employeeId: '',
  pickerCleared: false,
  page: 1,
  startDate: null,
  endDate: null,
  isTimeRangeSelected: false,
  frequency: '',
  repeatingValue: '',
  specificDate: '',
  rescheduleItemId: '',
  rescheduleItemDate: undefined,
  rescheduleItemTime: undefined,
  rescheduleItemStaff: '',
  setStartDate: (startDate) =>
    set((state) => ({
      ...state,
      startDate,
    })),
  setEndDate: (endDate) =>
    set((state) => ({
      ...state,
      endDate,
    })),
  setIsTimeRangeSelected: (isTimeRangeSelected) =>
    set((state) => ({
      ...state,
      isTimeRangeSelected,
    })),
  setAllowSaveButton: (allowSaveButton) =>
    set((state) => ({
      ...state,
      allowSaveButton,
    })),
  setEmployeeId: (employeeId) =>
    set((state) => ({
      ...state,
      employeeId,
    })),
  setLocationId: (locationId) =>
    set((state) => ({
      ...state,
      locationId,
    })),
  setPickerCleared: (pickerCleared) =>
    set((state) => ({
      ...state,
      pickerCleared,
    })),
  setPage: (page) =>
    set((state) => ({
      ...state,
      page,
    })),
  setFrequency: (frequency) =>
    set((state) => ({
      ...state,
      frequency,
    })),
  setRepeatingValue: (repeatingValue) =>
    set((state) => ({
      ...state,
      repeatingValue,
    })),
  setSpecificDate: (specificDate) =>
    set((state) => ({
      ...state,
      specificDate,
    })),
  setRescheduleItemId: (rescheduleItemId) =>
    set((state) => ({
      ...state,
      rescheduleItemId,
    })),
  setRescheduleItemDate: (rescheduleItemDate) =>
    set((state) => ({
      ...state,
      rescheduleItemDate,
    })),
  setRescheduleItemTime: (rescheduleItemTime) =>
    set((state) => ({
      ...state,
      rescheduleItemTime,
    })),
  setRescheduleItemStaff: (rescheduleItemStaff) =>
    set((state) => ({
      ...state,
      rescheduleItemStaff,
    })),
}));
