import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Col, Row, Spin } from 'antd';
import sumBy from 'lodash/sumBy';
import { getIsMobile, Text, useHistory, AuthBlock, useModal, formatTime, Card, CustomIcon } from '@plandok/core';
import { IntlDate, IntlLabel, IntlTemplate, IntlMoney } from '@plandok/i18n';
import { ModifyLayout, AppointmentDate, ClientItem, TextLong, NoInternetConnection } from 'components';
import { queryAppointmentDetails, QueryType } from 'api/query';
import { repeatFrequencyIntervals } from 'constants/data';
import { parseDateField } from 'helpers/date/field';
import { Client, EmployeeForSelect } from 'types';
import { PermissionType } from 'constants/auth';
import { formatDuration } from 'helpers/date';
import { getUrlParams } from 'helpers/router';
import { RoutePath } from 'constants/routes';
import { useLocation } from 'hooks/router';
import { useStore } from 'store/store';
import AppointmentFooterTotal from '../ModifyAppointmentPage/components/AppointmentFooterTotal';
import calendarState from '../CalendarPage/store/calendar.state';
import NotificationLog from './components/NotificationLog';
import RescheduleBtn from './components/RescheduleBtn';
import HistoryLog from './components/HistoryLog';
import CancelBtn from './components/CancelBtn';
import StatusBtn from './components/StatusBtn';
import EditBtn from './components/EditBtn';
import { Status } from './helpers';
import * as SC from './styles';
import { Log } from './types';

type AppointmentDetailsData = {
  bookings: {
    id: string;
    duration: number;
    employee: EmployeeForSelect;
    price: string;
    resource: null | { name: string };
    serviceId: string;
    serviceName: string;
    startTime: number;
  }[];
  client: Client | null;
  date: string;
  id: string;
  locationId: string;
  logs: Log[];
  notes: string | null;
  notificationLogs: Log[];
  options: {
    repeating_value: string;
    frequency: string;
    last_appointment_date: string;
  };
  recurrenceId: string | null;
  reminderAt: string | null;
  reminderSendAt: string | null;
  status: string;
  paymentType?: string;
  paymentStatus?: string;
};

export default function AppointmentDetailsPage() {
  const [timeFormat, setTimeFormat] = useState('');
  const [, { showModal }]: any = useModal();
  const { params }: any = useLocation();
  const history = useHistory();

  const backPath = getUrlParams()?.backPath;
  const isMobile = getIsMobile();

  useEffect(() => {
    calendarState.initialFetchData().then(() => {
      const { timeFormat } = calendarState.data ?? {};

      setTimeFormat(timeFormat);
    });
  }, []);

  const { data, isFetching: isLoading }: { data: AppointmentDetailsData; isFetching: boolean } = useQuery(
    [QueryType.APPOINTMENT_DETAILS, params.id],
    queryAppointmentDetails
  );

  const { repeating_value, frequency } = data?.options ?? {};
  const findFrequency = repeatFrequencyIntervals.find((interval) => interval.code === frequency)?.name;

  // Means that we come from page that we want to come back after create
  const onCancelAppointment = () =>
    backPath
      ? history.push(backPath, { id: params.id, status: data?.status })
      : history.push(RoutePath.CALENDAR, { id: params.id, status: data?.status });

  const setRescheduleItemId = useStore((state) => state.setRescheduleItemId);

  const openAppointmentCancel = () =>
    showModal({
      type: 'CANCEL_APPOINTMENT',
      modalProps: {
        id: params.id,
        recurrenceId: data?.recurrenceId ?? '',
        onSuccess: onCancelAppointment,
      },
    });

  const editItem = () =>
    history.push(`${RoutePath.APPOINTMENT_UPDATE}/${params.id || ''}`, {
      ...data?.options,
      recurrenceId: data?.recurrenceId,
    });

  const rescheduleItem = () => {
    setRescheduleItemId(params.id);

    history.push(RoutePath.CALENDAR);
  };

  const getLogLabel = (log: Log) => ` ${log?.employee?.firstName || ''} ${log?.employee?.lastName || ''}, `;
  const until = new Date(data?.options?.last_appointment_date || 0);
  const dateFormat = timeFormat === '12h' ? 'EEE, dd MMM, yyyy h:mm a' : 'EEE, dd MMM, yyyy HH:mm';
  const loading = isLoading || !timeFormat;

  return (
    <NoInternetConnection>
      <ModifyLayout title="appointment.view.title">
        <Card noPadding>
          <Spin spinning={loading}>
            <Row>
              <Col span={24} md={16}>
                <Row>
                  <SC.Header>
                    <AppointmentDate hideArrow input={{ value: parseDateField(data?.date) }} disabled />
                    {data?.recurrenceId && !!data?.options?.last_appointment_date && (
                      <Text size="small" colorType="base" weight="normal" mb="none">
                        <IntlLabel label="rp.repeats.title" />
                        &nbsp;
                        <IntlLabel label={findFrequency} />
                        &nbsp;
                        <IntlLabel label="rp.repeatsUntil.title" />
                        &nbsp;
                        <IntlDate date={until} dateFormat="EEE, dd MMM yyyy" />
                        &nbsp;
                        {parseInt(repeating_value) <= 30 && (
                          <IntlTemplate label="rp.repeatingTimes.title" params={[repeating_value]} />
                        )}
                      </Text>
                    )}
                    {isMobile ? (
                      <>
                        <SC.StatusTitle>
                          <IntlLabel label="appointment.status.btn" />:
                        </SC.StatusTitle>
                        <SC.StatusButton>
                          <StatusBtn status={data?.status} id={params.id} />
                        </SC.StatusButton>
                      </>
                    ) : (
                      <StatusBtn status={data?.status} id={params.id} />
                    )}
                  </SC.Header>
                  <SC.MobileBlock>
                    <SC.ClientHeader>
                      <div data-client-info-header>
                        <ClientItem client={data?.client} redirectOnClick />
                      </div>
                      <TextLong text={data?.client?.notes} />
                    </SC.ClientHeader>
                  </SC.MobileBlock>
                  <SC.Body>
                    {data?.bookings?.map(({ id, employee, price, startTime, resource, serviceName, duration }) => {
                      const displayName = employee?.firstName?.concat(` ${employee?.lastName || ''}`);

                      return (
                        <SC.AppointmentItem key={id}>
                          <div data-appointment-item-date>{timeFormat && formatTime(startTime, timeFormat)}</div>
                          <div data-appointment-item-info>
                            <div>
                              {serviceName} <Status status={data?.status} />
                            </div>
                            <div>
                              {formatDuration(duration)} {displayName ? ` with ${displayName},` : ''}
                              {resource?.name}
                            </div>
                          </div>
                          <div data-appointment-item-amount>
                            <IntlMoney value={price} />
                          </div>
                        </SC.AppointmentItem>
                      );
                    })}
                    <SC.AppointmentItem summary>
                      <div data-appointment-item-date />
                      <div data-appointment-item-info>
                        <div />
                        <div>{formatDuration(sumBy(data?.bookings, 'duration'))}</div>
                      </div>
                      <div data-appointment-item-amount>
                        <IntlMoney
                          value={data?.bookings?.reduce((acc, booking) => acc + parseFloat(booking.price), 0)}
                        />
                      </div>
                    </SC.AppointmentItem>
                    {data?.notes && (
                      <SC.History>
                        <div>
                          <IntlLabel label="appointment.notes.title" />
                        </div>
                        <div>{data?.notes}</div>
                      </SC.History>
                    )}
                    <br />
                    {data?.logs && (
                      <SC.History>
                        <div>
                          <IntlLabel label="appointment.history.title" />
                        </div>
                        <div>
                          {data?.logs?.map((log) => (
                            <HistoryLog getLogLabel={getLogLabel} log={log} key={log.id} dateFormat={dateFormat} />
                          ))}
                          {data?.notificationLogs?.map((log) => (
                            <NotificationLog getLogLabel={getLogLabel} log={log} key={log.id} dateFormat={dateFormat} />
                          ))}
                        </div>
                      </SC.History>
                    )}
                  </SC.Body>
                </Row>
              </Col>
              <SC.ClientMenu>
                <SC.ClientHeader>
                  <div data-client-info-header>
                    <ClientItem client={data?.client} redirectOnClick />
                  </div>
                  <TextLong text={data?.client?.notes} />
                </SC.ClientHeader>
                <SC.StatusTitle>
                  <IntlLabel label="appointment.status.btn" />:
                </SC.StatusTitle>
                <SC.StatusButton>
                  <StatusBtn status={data?.status} id={params.id} />
                </SC.StatusButton>
                {data?.paymentType === 'stripe_payment' && (
                  <SC.PaymentMethodSection>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                      <CustomIcon type="paidByCard" />
                      <Text size="base" weight="medium" colorType="base" ml="xsmall" mb="none">
                        <IntlLabel label="paid.by.card" />
                      </Text>
                    </div>
                  </SC.PaymentMethodSection>
                )}
              </SC.ClientMenu>
            </Row>
          </Spin>
        </Card>
        <ModifyLayout.Footer info={<AppointmentFooterTotal value={data} style={{ display: 'block' }} />}>
          <AuthBlock section={PermissionType.CAN_BOOK_APPOINTMENTS}>
            <SC.ClientFooter>
              <CancelBtn openAppointmentCancel={openAppointmentCancel} />
              <RescheduleBtn rescheduleItem={rescheduleItem} />
              <EditBtn editItem={editItem} />
            </SC.ClientFooter>
            <SC.MobileFooter>
              <SC.ButtonContainer>
                <RescheduleBtn rescheduleItem={rescheduleItem} />
                <EditBtn editItem={editItem} />
              </SC.ButtonContainer>
              <CancelBtn openAppointmentCancel={openAppointmentCancel} />
            </SC.MobileFooter>
          </AuthBlock>
        </ModifyLayout.Footer>
      </ModifyLayout>
    </NoInternetConnection>
  );
}
