import React, { ReactNode } from 'react';
import { Typography } from 'antd';
import { IntlLabel, LabelKey } from '@plandok/i18n';
import { Text } from '@plandok/core';
import RegistrationLangSelect from 'pages/auth/RegistrationPage/components/RegistrationLangSelect';
import * as SC from './styles';

interface AppLayoutProps {
  children: ReactNode;
  title: LabelKey;
  isRegistration?: boolean;
  mobileDescription?: LabelKey;
  regLangSelect?: ReactNode;
  page?: any;
}

function AuthLayout({ children, title, isRegistration, mobileDescription, page }: AppLayoutProps) {
  return (
    <SC.Layout isRegistration={isRegistration}>
      <div data-base-container>
        <div data-form-container>
          <div data-title-section>
            <Typography.Title level={2}>Plandok</Typography.Title>
            <RegistrationLangSelect page={page} />
            <div className="mb-1">
              <IntlLabel label={title} />
            </div>
            <Text className="visible-md" label={mobileDescription} size="small" data-auth-layout-mobile-text />
          </div>
          {children}
          <div data-copyright>
            © {new Date().getFullYear()} Plandok
            <a href="https://plandok.com/contact-us" target="_blank" rel="noopener noreferrer">
              <IntlLabel label="footer.support.center" />
            </a>
          </div>
        </div>
      </div>
    </SC.Layout>
  );
}

AuthLayout.Card = SC.Card;

export default AuthLayout;
