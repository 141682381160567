import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'antd';
import cn from 'classnames';
import { IntlLabel } from '@plandok/i18n';
import { AuthBlock } from '@plandok/core';
import SidebarIcons from '../../Sidebar/components/SidebarIcons';
import { useLocation } from 'hooks/router';
import { NotReleased } from 'components';
import * as SC from '../styles';

export default function MobileSidebarItem({ item }: any) {
  const { location } = useLocation();
  const [visible, setVisible] = useState(location.pathname === item.path);

  useEffect(() => {
    if (visible) {
      setTimeout(() => setVisible(false), 800);
    }
  }, [visible]);

  const active = location.pathname === item.path;
  return (
    <AuthBlock section={item.permission}>
      {item.notReleased && (
        <NotReleased>
          <Tooltip placement="top" title={<IntlLabel label={item.title} />} visible={visible}>
            <SC.LinkItem as={NavLink} to={item.mobilePath ?? item.path} key={item.path} className={cn({ active })}>
              <SC.ItemIcon>{(SidebarIcons as any)[item.iconType]}</SC.ItemIcon>
            </SC.LinkItem>
          </Tooltip>
        </NotReleased>
      )}
      {!item.notReleased && (
        <Tooltip placement="top" title={<IntlLabel label={item.title} />} visible={visible}>
          <SC.LinkItem as={NavLink} to={item.mobilePath ?? item.path} key={item.path} className={cn({ active })}>
            <SC.ItemIcon>{(SidebarIcons as any)[item.iconType]}</SC.ItemIcon>
          </SC.LinkItem>
        </Tooltip>
      )}
    </AuthBlock>
  );
}
