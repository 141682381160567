import { isMobile } from 'react-device-detect';
import React, { useState } from 'react';
import { Steps } from 'antd';
import { Button, withModal, Text } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { exportDataToCSV } from 'helpers/async';
import * as SC from '../styles';

const { Step } = Steps;

const EMAIL = 'info@plandok.com';

function ImportClientsModal() {
  const [currentStep, setCurrentStep] = useState(0);

  const onChange = (current: number) => setCurrentStep(current);

  const exportClients = () => exportDataToCSV('client/export', 'clients');

  return (
    <SC.PricingModal>
      <SC.PricingModalContainer>
        <Text size={isMobile ? 'small' : 'medium'} weight="normal" isCenter colorType="base">
          <IntlLabel label="client.import.instructions.description" />
        </Text>

        <Steps current={currentStep} onChange={onChange} direction="vertical">
          <Step
            description={
              <Text size="medium" weight="normal" colorType="base">
                <IntlLabel label="client.import.instructions.download" />
              </Text>
            }
          />
          <Step
            description={
              <Text size="medium" weight="normal" colorType="base">
                <IntlLabel label="client.import.instructions.copy" />
              </Text>
            }
          />
          <Step
            description={
              <Text size="medium" weight="normal" colorType="base">
                <IntlLabel label="client.import.instructions.email" />{' '}
                <a href={`mailto:${EMAIL}`} target="_blank" rel="noopener noreferrer">
                  <Text as="span" colorType="link" mb="none" size="medium" weight="normal" isCursor>
                    {EMAIL}
                  </Text>
                </a>
                <IntlLabel label="client.import.instructions.account" />
              </Text>
            }
          />
        </Steps>

        <SC.ImportClientsModalFooter>
          <Button type="primary" label="btn.downloadImportFile" upperCase={false} onClick={exportClients} />
        </SC.ImportClientsModalFooter>
      </SC.PricingModalContainer>
    </SC.PricingModal>
  );
}

export default withModal('client.options.import.title', {
  wide: false,
  icon: 'importClients',
  contentClassName: 'import-clients-modal-content',
})(ImportClientsModal);
