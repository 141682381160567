/* eslint-disable consistent-return,no-nested-ternary */
import React, { Component } from 'react';

import { getFirstKey } from '@plandok/core';
import isEqual from 'lodash/isEqual';

export default function withApiData(fetchData?: any, { dataKey, exactData, skipMountFetch, isSortable }: any = {}) {
  return (WrappedComponent: any) => {
    return class extends Component<any> {
      state = {
        data: null,
        isLoading: true,
        emptyState: undefined,
      };

      sortData = (data?: any[]) => {
        return data?.sort?.((a: any, b: any) => a?.position - b?.position) || [];
      };

      componentDidMount(): void {
        if (!skipMountFetch) {
          this.fetchData();
        }
      }

      fetchData = () => {
        if (!(fetchData || this.props.fetchData)) {
          return;
        }
        return (fetchData || this.props.fetchData)(this.props.filterParams).then((e: any) => {
          let result = exactData ? e : dataKey ? e[dataKey] : getFirstKey(e);

          if (isSortable) {
            result = this.sortData(result);
          }
          return this.setState({ data: result, isLoading: false, emptyState: e?.meta?.emptyState });
        });
      };

      componentDidUpdate(prevProps: Readonly<any>): void {
        if (!isEqual(this.props.filterParams, prevProps.filterParams)) {
          this.fetchData();
        }
      }

      getIsPlaceholderVisible = () => {
        // @ts-ignore
        return !this.state.isLoading && !this.state.data?.length;
      };

      changeData = (data?: any[]) => {
        this.setState({ data });
      };

      render() {
        return (
          <WrappedComponent
            {...this.props}
            {...this.state}
            fetchData={this.fetchData}
            changeData={this.changeData}
            showPlaceholder={this.getIsPlaceholderVisible()}
          />
        );
      }
    };
  };
}
