import React from 'react';
// @ts-ignore
import useGlobalHook from 'use-global-hook';

const initialState = {
  modals: [],
};

const MODAL_ANIMATION_DELAY = 300;

const actions = {
  showModal: (store: any, { type, modalProps }: any) => {
    store.setState({ modals: [...store.state.modals, { type, modalProps, visible: true }] });
  },
  hideModal: (store: any, modalType: string) => {
    store.setState({
      modals: store.state.modals.map((e: any) => (e.type === modalType ? { ...e, visible: false } : e)),
    });
    setTimeout(() => {
      store.setState({ modals: store.state.modals.filter((e: any) => e.type !== modalType) });
    }, MODAL_ANIMATION_DELAY);
  },
  hideAllModals: (store: any) => {
    store.setState({ modals: store.state.modals.map((e: any) => ({ ...e, visible: false })) });
    setTimeout(() => {
      store.setState(initialState);
    }, MODAL_ANIMATION_DELAY);
  },
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useModal = useGlobalHook(React, initialState, actions);
