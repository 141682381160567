import { Avatar, Dropdown, Icon } from 'antd';
import React from 'react';
import { IClientAppointmentDetails } from 'types/api';

import * as SC from '../styles';
import ClientDetailsMenu from './ClientDetailsMenu';

export default function ClientDetailsMain(props: {
  removeClient(): any;
  data?: IClientAppointmentDetails;
  clientId: any;
  openCreateClient: any;
}) {
  const editClient = props.openCreateClient;
  const profile: IClientAppointmentDetails['profile'] = (props?.data?.profile || {}) as any;
  return (
    <SC.ItemMainInfo>
      <SC.ClientItem>
        <div data-client-item-avatar>
          <Avatar size="large">
            {profile.firstName?.[0]?.toUpperCase?.() ?? ' '}
            {profile.lastName?.[0]?.toUpperCase?.() ?? ''}
          </Avatar>
        </div>
        <div data-client-item-info>
          <div>
            {profile.firstName} {profile.lastName}
          </div>
          <div>{profile.email}</div>
          <div>{profile.phoneNumber}</div>
        </div>
      </SC.ClientItem>
      <Dropdown
        overlay={() => <ClientDetailsMenu edit={editClient} remove={props.removeClient} />}
        trigger={['click']}
        placement="bottomRight"
      >
        <Icon type="setting" />
      </Dropdown>
    </SC.ItemMainInfo>
  );
}
