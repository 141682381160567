import { mediaSmDown } from '@plandok/core';
import styled, { css } from 'styled-components';

type ContainerProps = {
  color?: string;
  isSpacing?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: ${({ isSpacing }) => (isSpacing ? 'repeat(2, 1fr)' : '1fr')};
  border-left: ${({ color }) => `6px solid ${color}` || 'inherit'};
  margin-top: ${({ isSpacing }) => (isSpacing ? '2rem' : '0')};
  padding-left: 0.625rem;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  margin-bottom: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #e2e6ee;

  [data-service-option-first-column] {
    margin: 0.5rem 0;
  }

  [data-service-option-money] {
    color: #61749d;
    font-size: 18px;
    ${mediaSmDown(css`
      color: #13316d;
      font-weight: 500;
      font-size: 16px;
    `)}
  }

  [data-service-option-name] {
    color: #61749d;
    ${mediaSmDown(css`
      color: #13316d;
      font-size: 14px;
    `)}
  }

  [data-service-option-time] {
    color: #adb6cc;
  }
`;
