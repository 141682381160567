import * as api from 'api';
import React from 'react';
import { ModifyLayout, NoInternetConnection } from 'components';
import { useLocation, useParams } from 'hooks/router';
import withFormApi from 'hocs/form/withFormApi';
import { RoutePath } from 'constants/routes';
import { useStore } from 'store/store';
import ClientForm from './components/ClientForm';

const ClientFormWithApi = withFormApi({
  detailsAction: api.fetchClientDetails,
  createAction: api.createClient,
  editAction: api.editClient,
  successMessage: 'Client Created',
})(ClientForm);

export default function ModifyClientPage(props: any) {
  const params: any = useParams();
  const { navigate, goBack }: any = useLocation();
  const setAllowSaveButton = useStore((state) => state.setAllowSaveButton);

  const onSuccess = (res: any) => {
    setAllowSaveButton(true);
    return props.onSuccess ? props.onSuccess(res) : navigate(RoutePath.CLIENTS);
  };

  const onClose = props.onClose || goBack;

  const id = props.customId || params?.id;

  return (
    <NoInternetConnection>
      <ModifyLayout style={props.style} title={id ? 'title.edit.client' : 'client.create.title'} onClose={onClose}>
        <ClientFormWithApi
          customId={id}
          skipDetails={props.skipDetails}
          onBack={onClose}
          initialValues={{ notificationChannel: 'sms_and_email', gender: 'unknown' }}
          onSuccess={onSuccess}
        />
      </ModifyLayout>
    </NoInternetConnection>
  );
}
