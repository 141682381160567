import React from 'react';

import { IntlLabel } from '@plandok/i18n';

export default function ServicesMobileHeader() {
  return (
    <>
      <div />
      <div>
        <IntlLabel label="sidebar.service" />
      </div>
      <div />
    </>
  );
}
