import { FieldRenderProps } from 'react-final-form';
import { Switch } from 'antd';
import { IntlLabel, LabelKey } from '@plandok/i18n';
import * as SC from './styles';
import withFormBlock from '../FormBlock/withFormBlock';
import React from 'react';

interface FormSwitchProps extends FieldRenderProps<any, any> {
  submitting?: boolean;
  placeholder?: string;
  options?: any[];
  textKey?: LabelKey;
}

const FormSwitch = ({ input, submitting, textKey }: FormSwitchProps) => {
  const onChange = () => input.onChange(!input.value);

  return (
    <>
      <Switch checked={!!input.value} {...input} size="small" disabled={submitting} />
      <SC.SwitchLabel htmlFor={input.name} onClick={onChange}>
        <IntlLabel label={textKey} />
      </SC.SwitchLabel>
    </>
  );
};

export default withFormBlock<FormSwitchProps>(FormSwitch);
