import * as RSH from 'react-sortable-hoc';
import React from 'react';
import { CustomIcon } from '@plandok/core';
import * as SC from '../styles';

const DragHandle = RSH.SortableHandle(() => (
  <td className="drag-cell">
    <CustomIcon type="smallDragHandle" />
  </td>
));

function TableRow({ value = {}, columns, index, onClick }: any) {
  return (
    <SC.Row className={`ant-table-row ant-table-row-level-${index}`} data-row-key={index} onClick={onClick}>
      <DragHandle />
      {columns.map(({ render: RenderComponent, key, style }: any) => (
        <td key={`${key}`} style={style}>
          {RenderComponent ? <RenderComponent value={value} /> : value[key]}
        </td>
      ))}
    </SC.Row>
  );
}

export default RSH.SortableElement(TableRow);
