import React, { ComponentType, ReactNode } from 'react';
import { Layout } from 'antd';
import cn from 'classnames';
import { getIsMobile, AuthBlock } from '@plandok/core';
import { LabelKey } from '@plandok/i18n';
import { PermissionType } from 'constants/auth';
import AddButton from './components/AddButton';
import AppHeader from './components/AppHeader';
import Sidebar from './components/Sidebar';
import * as SC from './styles';

interface AppLayoutProps {
  children: ReactNode;
  mobileHeader: ComponentType;
  headerTitle: LabelKey;
  mobileBackground?: string;
  headerIconTitle?: string;
  customButton?: any;
  addButtonPath?: string;
  mobileWhiteBg?: boolean;
  addBtnClassName?: string;
  disableSidebarShade?: boolean;
  noPaddingBottom?: boolean;
  addButtonModal?: string;
  contentClassName?: string;
  hideSideBar?: boolean;
  addButtonPermission?: PermissionType;
  className?: string;
  modalProps?: any;
  planType: string;
  isSubscriptionLoading: boolean;
  isVisibleUpdateBtn?: boolean;
  setIsVisibleUpdateBtn?: (value: boolean) => void;
  setIsVisibleUpdateNotification?: (value: boolean) => void;
}

function AppLayout(props: AppLayoutProps) {
  const showAddBtn = props.addButtonPath || props.addButtonModal;
  const isMobile = getIsMobile();

  return (
    <>
      <Layout id="main-layout" className={props.className}>
        {!props.hideSideBar && (
          <Sidebar
            disableShade={props.disableSidebarShade}
            isVisibleUpdateBtn={props.isVisibleUpdateBtn}
            setIsVisibleUpdateBtn={props.setIsVisibleUpdateBtn}
            setIsVisibleUpdateNotification={props.setIsVisibleUpdateNotification}
          />
        )}
        <Layout>
          <AppHeader
            mobileHeader={props.mobileHeader}
            mobileBackground={props.mobileBackground}
            headerTitle={props.headerTitle}
            headerIconTitle={props.headerIconTitle}
            planType={props.planType}
            isSubscriptionLoading={props.isSubscriptionLoading}
          />
          <Layout.Content
            className={cn(
              { 'mobile-layout-content-white': props.mobileWhiteBg },
              {
                'pb-0': props.noPaddingBottom,
              },
              {
                'is-mobile': isMobile,
              },
              props.contentClassName
            )}
          >
            {props.children}
          </Layout.Content>
        </Layout>
      </Layout>
      {showAddBtn && (
        <AuthBlock section={props.addButtonPermission}>
          <AddButton
            className={props.addBtnClassName}
            path={props.addButtonPath}
            addButtonModal={props.addButtonModal}
            modalProps={props.modalProps}
          />
        </AuthBlock>
      )}
      {props.customButton}
    </>
  );
}

AppLayout.Container = SC.Container;

AppLayout.defaultProps = {
  disableSidebarShade: true,
};

export default AppLayout;
