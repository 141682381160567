import styled, { css } from 'styled-components';
import { mediaMobileDown, mediaXlUp } from '@plandok/core';

export const Header = styled.div<any>`
  padding: 25px;
  border-bottom: 1px solid #e9ecf3;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e9ecf3;
  div {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  button {
    display: none;
  }

  ${mediaMobileDown(css`
    justify-content: center;
    padding: 15px;
    button {
      text-align: center;
      display: block;
      width: 100%;
      max-width: 320px;
    }
  `)}
`;

export const MobileBlock = styled.div`
  display: none;
  ${mediaMobileDown(css`
    display: flex;
    flex-direction: column;
  `)}
`;

export const Body = styled.div<any>`
  padding-left: 50px;
  padding-bottom: 50px;
  border-right: 1px solid #e9ecf3;
  display: flex;
  flex-direction: column;
  min-height: 380px;

  ${mediaMobileDown(css`
    padding: 0 15px;
  `)}
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const PaidByCardContainer = styled.div`
  border-top: 1px solid #e9ecf3;
  padding: 23px;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const PaidByCardContainerMobile = styled.div`
  margin: 16px 0 22px;
`;

export const ClientMenu = styled.div<any>`
  flex: 0 0 auto;
  float: left;
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
  ${mediaMobileDown(css`
    display: none;
  `)}
  position: relative;
  min-height: 461px;
`;

export const BtnContainer = styled.div`
  display: flex;
  z-index: 100;
  button[data-appointment-btn] {
    color: #61749d !important;
    margin: 20px auto;
    padding: 0 20px;
    font-weight: 500;
    border: 2px solid #13316d;
  }

  span {
    margin-left: 24px;
  }
`;

export const ClientHeader = styled.div`
  padding: 50px 25px 25px 25px;
  border-bottom: 1px solid #e9ecf3;
  [data-client-info-header] {
    display: flex;
    justify-content: center;
    margin: 0 auto 20px auto;
  }

  ${mediaMobileDown(css`
    padding: 15px;
  `)}
`;

export const StatusTitle = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 12px;
`;

export const StatusButton = styled.div`
  button {
    margin: 5px auto !important;
  }
`;

export const PaymentMethodSection = styled.div`
  padding: 20px;
  border-top: 1px solid #e9ecf3;
  margin-top: 45%;
  height: 50px;

  ${mediaMobileDown(css`
    padding: 15px;
    border-top: none;
  `)}
`;

export const AppointmentItem = styled.div<any>`
  color: ${(props) => props.theme.primaryColor};
  display: flex;
  padding: 20px 80px 20px 20px;
  border-bottom: ${(props) => (props.summary ? 'none' : '1px solid #e9ecf3')};

  [data-appointment-item-date] {
    min-height: 1px;
    width: 100px;
    font-size: 18px;
    font-weight: 600;
  }

  [data-appointment-item-info] {
    div:first-of-type {
      font-size: 18px;
      font-weight: 600;
    }
    div:nth-of-type(2) {
      font-size: 14px;
      font-weight: 400;
    }
  }

  [data-appointment-item-amount] {
    display: flex;
    margin-left: auto;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
  }

  ${mediaMobileDown(css`
    padding: 15px 0;
  `)}
`;

export const History = styled.div<any>`
  color: ${(props) => props.theme.primaryColor};
  display: flex;
  flex-direction: column;
  div:first-of-type {
    font-size: 18px;
  }
  div:nth-of-type(2) {
    font-size: 14px;
  }
`;

export const ClientFooter = styled.div`
  display: none;

  ${mediaXlUp(css`
    display: block;
    min-width: 620px;
  `)}
`;

export const MobileFooter = styled.div`
  display: block;

  ${mediaXlUp(css`
    display: none;
  `)}
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  align-self: stretch;
  margin: 20px 0px;
`;
