import React from 'react';
import { Text, Button } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import * as SC from '../../../styles';

type NoClientsPlaceholderProps = {
  createNewClient: () => void;
};

export default function NoClientsPlaceholder({ createNewClient }: NoClientsPlaceholderProps) {
  return (
    <SC.EmptyContainer>
      <img src={`/img/illustrations/user.svg`} alt="placeholder" />
      <Text size="base" colorType="base" mb="medium">
        <IntlLabel label="appointment.clients.empty.list" />
      </Text>
      <Button block type="primary" onClick={createNewClient}>
        <Text size="small" weight="bold" colorType="white" mb="none" upperCase>
          <IntlLabel label="appointment.clients.addClient" />
        </Text>
      </Button>
    </SC.EmptyContainer>
  );
}
