import { useEffect, useState } from 'react';
import * as api from 'api';
import { Employee } from 'types';

export default function useGetCurrentStaff() {
  const [isStaffLoading, setIsStaffLoading] = useState<boolean>(false);
  const [staffCount, setStaffCount] = useState<number>(1);
  const [staff, setStaff] = useState<Employee[]>();

  useEffect(() => {
    setIsStaffLoading(true);

    (async () => {
      try {
        const data = await api.fetchEmployees();

        setStaffCount(data?.totalCount);
        setStaff(data?.employees);
      } catch (e) {
        console.error('Error when getting staff');
      } finally {
        setIsStaffLoading(false);
      }
    })();
  }, []);

  return {
    isStaffLoading,
    staffCount,
    staff,
  };
}
