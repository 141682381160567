import styled from 'styled-components';
import { AnalyticsCard } from '../../styles';

export const TopServices = styled(AnalyticsCard)`
  grid-area: top-services;
  background: #fff;
`;

export const ServiceName = styled.div``;

export const ThisMonthTitle = styled.div`
  text-align: right;
  width: 100%;
`;

export const GridContent = styled.div`
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-template-rows: 1fr auto;
`;

export const TextColumn = styled.div`
  grid-column: 1/2;

  div {
    width: 12rem;
    white-space: pre-line;
    overflow: hidden;
  }
`;

export const ThisMonthColumn = styled.div`
  justify-self: end;
`;

export const Divider = styled.div`
  margin: 5px 0 !important;
`;
