import styled, { css } from 'styled-components';
import { mediaMobileDown, Card } from '@plandok/core';

type PropsModel = {
  border: boolean;
};

export const CardContainer = styled(Card)`
  max-width: 980px;
  width: 670px;
  margin: 60px auto;
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  border-radius: 20px;
  padding: 0 80px;
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${mediaMobileDown(css`
    width: 355px;
    padding: 0 10px;
    margin: 160px auto 0 auto;
  `)}
`;

export const HeaderTitle = styled.div`
  align-self: center;
  text-align: center;
  padding: 40px 40px 0 40px;
  max-width: 290px;
  ${mediaMobileDown(css`
    padding: 20px 15px 0 15px;
  `)}
`;

export const CheckboxListProps = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  ${mediaMobileDown(css`
    padding: 0 15px;
    margin-bottom: 0px;
  `)}
`;

export const CheckboxList = styled.div<PropsModel>`
  border: ${({ border }) => `2px solid ${border ? '#13316D' : '#DFE6F1'}`};
  box-sizing: border-box;
  border-radius: 30px;
  width: 470px;
  padding: 8px 10px;
  margin-bottom: 14px;
  label {
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
      font-weight: 500;
      color: #13316d;
      ${mediaMobileDown(css`
        font-size: 14px;
      `)}
    }
    > span:last-child {
      display: flex;
      flex: 1;
      span:last-child {
        margin-left: auto;
      }
    }
  }
  .ant-checkbox-inner {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #fff;
    &:after {
      top: 50%;
      left: 30%;
    }
  }
  .ant-checkbox-checked {
    &:after {
      border: none;
    }
  }
  .ant-checkbox {
    top: 0;
  }
  ${mediaMobileDown(css`
    width: 320px;
    padding: 5px;
    text-align: center;
  `)}
`;

export const SelectionCustomDateBtn = styled.div`
  display: flex;
  max-width: 330px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 3px 2px 11px rgba(38, 60, 122, 0.2);
  border-radius: 31px;
  padding: 10px 20px;
  margin-bottom: 60px;
  align-self: center;
  cursor: pointer;
  div {
    margin-right: 10px;
    color: #2472e8 !important;
  }
  ${mediaMobileDown(css`
    padding: 5px 30px;
  `)}
`;
