import { notification } from 'antd';
import React from 'react';
import { CustomIcon, LocalStorage, Text, useLocation, withModal, Mutation } from '@plandok/core';
import { RoutePath } from 'constants/routes';
import * as SC from '../styles';
import '../styles/styles.less';
import * as api from 'api';

type DeleteAccountModalProps = {
  onClose: () => void;
};

function DeleteAccountModal({ onClose }: DeleteAccountModalProps) {
  const { navigate } = useLocation();

  const closeSuccessMessage = () =>
    notification.open({
      message: <Text label="modal.deleteAccount.successMessage" mb="none" size="small" colorType="base" />,
      closeIcon: <span />,
      className: 'delete-account-success-message',
    });

  const onSuccess = () => {
    onClose();
    navigate(RoutePath.LOGOUT);
    LocalStorage.clearAll();
    closeSuccessMessage();
  };

  return (
    <SC.DeleteAccountContainer>
      <CustomIcon type="warning" />
      <Text label="modal.deleteAccount.title" weight="medium" />
      <Text label="modal.deleteAccount.description" size="medium" />
      <Mutation action={api.deleteEmployeesAccount} onSuccess={onSuccess}>
        {({ loading, action }: any) => (
          <>
            <SC.DeleteAccountButton
              disabled={loading}
              label="btn.cancel"
              onClick={onClose}
              upperCase={false}
              whiteBtn
              semiBold
            />
            <SC.DeleteAccountButton
              loading={loading}
              label="btn.delete"
              type="danger"
              onClick={action}
              upperCase={false}
              semiBold
            />
          </>
        )}
      </Mutation>
    </SC.DeleteAccountContainer>
  );
}

export default withModal(null, { contentClassName: 'delete-account-modal' })(DeleteAccountModal);
