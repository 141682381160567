import styled, { css } from 'styled-components';
import { mediaMobileDown } from '@plandok/core';

export const Container = styled.div<any>`
  min-height: 100vh;
  background: #f8f9fc;
  display: flex;
  flex-direction: column;
  position: relative;
  ${mediaMobileDown(css`
    background: ${(props) => ((props as any).mdWhite ? 'white' : 'initial')};
    padding-bottom: 50px;
  `)}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -1px 10px rgba(38, 60, 122, 0.2);
  padding: 18px 0;
  margin-bottom: 85px;
  position: fixed;
  width: 100%;
  z-index: 10;
  ${mediaMobileDown(css`
    margin-bottom: 20px;
  `)}
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const CloseIcon = styled.div`
  font-size: 36px;
  color: #13316d;
  position: absolute;
  right: 20px;
  cursor: pointer;
  z-index: 2;
`;

export const Content = styled.div`
  margin: 100px auto 0 auto;
  max-width: 1160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-form {
    align-items: center;
  }
  ${mediaMobileDown(css`
    margin-top: 20px;
  `)}
`;

export const Title = styled.h2<any>`
  text-align: center;
  font-size: 34px;
  line-height: 35px;
  color: ${(props) => props.theme.normalColor};
  margin: 0px;
  ${mediaMobileDown(css`
    font-size: 25px;
  `)}
` as any;
