import styled, { css } from 'styled-components';
import { mediaMobileDown } from '@plandok/core';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: calc(100vh - 180px);
  ${mediaMobileDown(css`
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 220px);
  `)};
`;

export const SPlaceholderTitle = styled.h2`
  line-height: '1.1';
  font-size: 42px;
  color: #13316d;
  font-weight: 600;
  margin: 0px;
  ${mediaMobileDown(css`
    font-size: 28px;
  `)};
`;

export const SPlaceholderDescription = styled.div`
  line-height: '1.1';
  font-size: 18px;
  color: #13316d;
  font-weight: 400;
  width: 440px;
  padding-bottom: 50px;
  ${mediaMobileDown(css`
    font-size: 14px;
    width: 260px;
  `)};
`;

export const MassWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 500px;
  ${mediaMobileDown(css`
    flex-direction: column;
  `)};
`;

export const MassBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 220px;
  height: 100px;
  background: #ffffff;
  box-shadow: 4px 5px 11px rgba(38, 60, 122, 0.2);
  border-radius: 10px;
  border: none;
  padding: 0 45px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  ${mediaMobileDown(css`
    margin-bottom: 20px;
  `)};
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
`;

export const MessageLog = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  border-radius: 20px;
  margin: 2px;
  ${mediaMobileDown(css`
    margin: 50px 0;
  `)};
`;

export const MobileBtn = styled.div`
  border: 1px solid #dce1ed;
  box-sizing: border-box;
  border-radius: 23px;
  margin-top: 20px;
  button {
    min-width: 176.5px;
    border: none;
    box-shadow: none;
  }
`;
