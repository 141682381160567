import { Col, Divider } from 'antd';
import React from 'react';
import { Text } from '@plandok/core';
import { ClientItem } from 'components';
import * as SC from '../styles';

export default function SearchClientColumn(props: any) {
  const data = props.searchClientList || props.recentlyAdded || [];
  const isRecentlyAddedMod = !props.searchClientList;
  const count = (props.searchClientList || []).length;

  return (
    <Col md={10} span={24}>
      {isRecentlyAddedMod ? (
        <Text label="title.recentlyAddedClients" bold />
      ) : (
        <Text label="title.clients" bold>
          {` (${count})`}
        </Text>
      )}
      <Divider className="mb-0" />
      {data.map((e: any) => (
        <SC.ClientItem key={e.id}>
          <ClientItem client={e} redirectOnClick />
        </SC.ClientItem>
      ))}
    </Col>
  );
}
