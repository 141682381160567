import React from 'react';
import { App_Client } from 'graphql/generatedTypes';
import { ClientsForMerge } from '../../types/types';
import * as SC from '../../styles';

type GroupClientsCheckboxProps = {
  сlients: App_Client[];
  сlientsForMerge: ClientsForMerge[];
  setClientsForMerge: (сlientsForMerge: ClientsForMerge[]) => void;
};

export default function GroupClientsCheckbox({
  сlients,
  сlientsForMerge,
  setClientsForMerge,
}: GroupClientsCheckboxProps) {
  const isSelectAll = сlientsForMerge.map(({ root: rootId }) => rootId).includes(сlients[0].id);

  const toggleSelectAll = (clients: App_Client[]) => {
    const clearedClients = сlientsForMerge.filter(({ root: rootId }) => rootId !== сlients[0].id);

    const clientGroup = {
      root: clients[0].id,
      rest: clients.slice(1).map(({ id }) => id),
    };

    setClientsForMerge(isSelectAll ? clearedClients : [...сlientsForMerge, clientGroup]);
  };

  return <SC.StyledCheckbox checked={isSelectAll} onChange={() => toggleSelectAll(сlients)} />;
}
