import { Icon, Row } from 'antd';
import React from 'react';
import { ClientItem } from 'components';

export default function MobileClientRow({ value }: any) {
  return (
    <Row type="flex" justify="space-between" align="middle" className="w-100">
      <ClientItem client={value} />
      <Icon type="right" />
    </Row>
  );
}
