import React from 'react';
import { AuthLayout, NoInternetConnection } from 'components';
import { RoutePath } from 'constants/routes';
import { useRouter } from 'hooks/router';
import SetNewPasswordFooter from './SetNewPasswordFooter';
import SetNewPasswordForm from './SetNewPasswordForm';

export default function SetNewPasswordPage() {
  const { match }: any = useRouter();

  const email = match?.params?.email;
  const passwordResetToken = match?.params?.token;

  return (
    <NoInternetConnection>
      <AuthLayout
        title="title.newPassword"
        mobileDescription="title.newPassword.description"
        page={RoutePath.RESET_PASSWORD}
      >
        <AuthLayout.Card>
          <SetNewPasswordForm
            initialValues={{
              email,
              passwordResetToken,
            }}
          />
        </AuthLayout.Card>
        <br />
        <SetNewPasswordFooter />
      </AuthLayout>
    </NoInternetConnection>
  );
}
