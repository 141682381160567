import { isSameDay, addDays, isToday } from 'date-fns';
import cn from 'classnames';
import React from 'react';
import { DatePicker, getIsMobile, startOfWeekIntl, Button } from '@plandok/core';
import { getCalendarRangeStart } from 'helpers/date/calendar';
import { DatePickerContainer } from './components/DatePickerContainer';
import DateFilterDisplay from './components/DateFilterDisplay';
import * as SC from './styles';

type DateFilterItemProps = {
  date: Date;
  changeDate: (date: Date) => void;
  viewType?: any;
};

export default function DateFilterItem({ date = new Date(), changeDate, viewType }: DateFilterItemProps) {
  const isWeekMode = viewType === 'week';
  const rangeDate = getCalendarRangeStart(date, isWeekMode);

  const isCurrentDate = isWeekMode ? isSameDay(startOfWeekIntl(new Date()), startOfWeekIntl(date)) : isToday(rangeDate);

  const daysDelta = isWeekMode ? 7 : 1;

  return (
    <SC.DateFilterContainer className="date-picker-right-position filter-datepicker-container">
      <Button icon="left" ghost onClick={() => changeDate(addDays(date, -daysDelta))} />
      {!getIsMobile() && (
        <Button
          ghost={!isCurrentDate}
          onClick={() => changeDate(new Date())}
          label="calendar.today"
          minorBtn={isCurrentDate}
        />
      )}
      <DatePicker
        value={rangeDate as any}
        customInput={
          <Button ghost>
            <DateFilterDisplay isWeekMode={isWeekMode} date={date} />
          </Button>
        }
        calendarContainer={(props) => <DatePickerContainer {...props} date={date} changeDate={changeDate} />}
        withPortal={getIsMobile()}
        onChange={changeDate}
        className="filter-datepicker"
        calendarClassName={cn({ 'week-mode-react-datepicker': isWeekMode })}
      />
      <Button icon="right" ghost onClick={() => changeDate(addDays(date, daysDelta))} />
    </SC.DateFilterContainer>
  );
}
