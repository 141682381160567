import React from 'react';
import { AppSetupList, NoInternetConnection } from 'components';
import { fakeData, columnsConfig } from './config';

export default function DiscountsPage() {
  return (
    <NoInternetConnection>
      <AppSetupList
        btnLabel="btn.new.discount"
        data={fakeData}
        columns={columnsConfig}
        title="discount.list.title"
        modalType="CREATE_DISCOUNT"
      />
    </NoInternetConnection>
  );
}
