import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import React from 'react';
import { IntlLabel, IntlDate } from '@plandok/i18n';
import { Text } from '@plandok/core';
import { parseDateFieldWithTime } from '../../../helpers/date/field';
import { PermissionType } from 'constants/auth';
import { RoutePath } from 'constants/routes';

export const settingTabs: any = [
  {
    path: RoutePath.CLIENT_MESSAGES,
    title: 'tabs.messagesLog',
    description: 'tabs.messagesLog.description',
    permission: PermissionType.MESSAGES,
    icon: 'notification',
  },
  {
    path: RoutePath.SETUP_NOTIFICATIONS,
    title: 'tabs.messagesSettings',
    description: 'tabs.messagesSettings.description',
    permission: PermissionType.CLIENT_SETTINGS,
    hasBasePath: true,
    icon: 'settings',
  },
];

export const getMessagesColumns = (showMessage: any) => [
  {
    title: <IntlLabel label="table.header.timeSent" />,
    width: '190px',
    key: 'timeSent',
    render: ({ createdAt }: { createdAt: string }) => {
      const date = parseDateFieldWithTime(createdAt);
      return <span>{date && <IntlDate date={date} dateFormat="dd MMM yyyy, HH:mm" />}</span>;
    },
  },
  {
    title: <IntlLabel label="table.header.client" />,
    width: '180px',
    key: 'client',
    render: ({ clientId, clientName }: { clientId?: string; clientName: string }) =>
      clientId ? (
        <Link to={`${RoutePath.CLIENT_DETAILS}/${clientId}`}>{clientName}</Link>
      ) : (
        <Text mb="none" size="base" colorType="link">
          {clientName}
        </Text>
      ),
  },
  {
    title: <IntlLabel label="table.header.appointment" />,
    width: '150px',
    key: 'appointment',
    render: ({ appointmentId }: { appointmentId?: string }) =>
      appointmentId ? (
        <Link to={`${RoutePath.APPOINTMENT_DETAILS}/${appointmentId}`}>
          <IntlLabel label="title.appointment" />
        </Link>
      ) : (
        <Text mb="none" size="base" colorType="link" label="title.appointment" />
      ),
  },
  {
    title: <IntlLabel label="table.header.destination" />,
    width: '200px',
    dataIndex: 'contact',
    key: 'contact',
  },
  {
    title: <IntlLabel label="table.header.type" />,
    width: '150px',
    dataIndex: 'type',
    key: 'type',
    render: (value: any) => <IntlLabel label={`notification.type.${value.type || value}`} />,
  },
  {
    title: <IntlLabel label="page.marketing.massMessages.channel" />,
    width: '120px',
    dataIndex: 'channel',
    key: 'channel',
    render: (value: any) => <IntlLabel label={`notification.channel.${value.channel || value}`} />,
  },
  {
    title: <IntlLabel label="table.header.message" />,
    width: '370px',
    key: 'message',
    render: ({ message }: { message: string }) => (
      <span className="cursor-pointer text-underline color-link" onClick={() => showMessage(message)}>
        {message?.substring?.(0, 40)}
        {message.length > 40 ? '...' : ''}
      </span>
    ),
  },
  {
    title: <IntlLabel label="table.header.status" />,
    width: '150px',
    key: 'status',
    render: ({ status }: { status: string }) => {
      switch (status) {
        case 'sent':
          return (
            <span>
              <IntlLabel label="notification.status.sent" /> <Icon type="check" />
            </span>
          );
        case 'pending':
          return (
            <span>
              <IntlLabel label="notification.status.pending" /> <Icon type="clock-circle-o" />
            </span>
          );
        default:
          return (
            <span>
              <IntlLabel label="notification.status.failed" /> <Icon type="exclamation" />
            </span>
          );
      }
    },
  },
  {
    title: <IntlLabel label="table.header.cost" />,
    width: '100px',
    key: 'charge',
    render: ({ charge }: { charge: number }) => <span>{charge} €</span>,
  },
];
