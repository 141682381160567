import React, { ComponentType, ReactNode, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { getIsMobile } from '@plandok/core';
import { Placeholder, PlaceholderProps } from 'components';
import tableStoreFactory, { TableStoreInitializeProps, TableStoreTypes } from './store';
import DesktopTable from '../components/DesktopTable';
import MobileTable from '../components/MobileTable';

interface AppTableProps {
  type: TableStoreTypes;
  columns: any[];
  children?: ReactNode;
  selectedPage?: number;
  page?: number;
  placeholder?: PlaceholderProps;
  showPlaceholder?: boolean;
  viewDetails?: Function;
  mobileRowComponent?: ComponentType;
  mobileClassName?: string;
  className?: string;
  apiConfig: TableStoreInitializeProps;
  searchStr?: any;
  paginationSpacing?: string;
  isCustomPagination?: boolean;
  loading?: boolean;
  loadMore?: (page: number) => void;
  mobileColumns?: any;
  hidePageSwitcher?: boolean;
}

export default observer(function AppTable(props: AppTableProps) {
  const tableStore = useMemo(
    () => tableStoreFactory.getTableStoreInstance(props.type),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    tableStore.initialize(props.apiConfig);
    tableStore.fetchData();
    return tableStore.onUnmount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (tableStore.initialized) {
        tableStore.fetchDataDebounced(props.searchStr);
        tableStore.debouncedQuery = props.searchStr ?? '';
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [props.searchStr, tableStore]);

  const isMobile = getIsMobile();
  const isHidePageSwitcher =
    typeof props.hidePageSwitcher === 'boolean' ? props.hidePageSwitcher : tableStore.page === 1 && isMobile;

  if (props.placeholder && tableStore.showPlaceholder) {
    return (
      <div style={{ marginTop: '100px' }}>
        <Placeholder {...props.placeholder} position="relative" />
      </div>
    );
  }

  return (
    <>
      {props.children}
      {!isMobile || !props.mobileRowComponent ? (
        <DesktopTable
          columns={props.columns}
          viewDetails={props.viewDetails}
          page={tableStore.page}
          selectedPage={props.selectedPage}
          count={tableStore.count}
          totalCount={tableStore.totalCount}
          hidePageSwitcher={isHidePageSwitcher}
          isLoading={props?.loading ? props?.loading : tableStore.isLoading}
          fetchData={tableStore.fetchData}
          className={props.className}
          isCustomPagination={props.isCustomPagination}
          paginationSpacing={props.paginationSpacing}
          data={tableStore.currentData}
          loadMore={props.loadMore}
        />
      ) : (
        <MobileTable
          rowComponent={props.mobileRowComponent}
          className={props.mobileClassName}
          viewDetails={props.viewDetails}
          data={tableStore.allData}
          hasMore={tableStore.hasMore}
          isLoading={props.loading || tableStore.isLoading}
          fetchData={tableStore.fetchData}
          mobileColumns={props.mobileColumns}
        />
      )}
    </>
  );
});
