import { Upload } from 'antd';
import React from 'react';
import { withField, CustomIcon } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { getBase64 } from 'helpers/common';
import { NotReleased } from 'components';
import './upload.less';

interface ChangeUploadProps {
  input?: {
    value: string[];
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
}

const ChangeUpload = (props: ChangeUploadProps) => {
  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };
  const handleChange = ({ fileList }: any) => {
    props.input?.onChange(fileList);
  };
  const uploadButton = (
    <div className="ant-upload-button">
      <p className="ant-upload-text">
        <IntlLabel label="Change photo" />
      </p>
      <p className="ant-upload-drag-icon">
        <CustomIcon type="vector" />
      </p>
    </div>
  );

  const tempImage =
    props.input?.value?.map?.((e: any, i: number) => {
      return {
        ...e,
        uid: e.uid || e.name || i,
        status: e.status || 'done',
      };
    }) || [];

  const images = tempImage.length > 0 ? tempImage.slice(tempImage.length - 1) : [];

  return (
    <NotReleased>
      <div className="clearfix app-photo-change-upload">
        <Upload
          beforeUpload={() => false}
          listType="picture-card"
          fileList={images}
          onPreview={handlePreview}
          onChange={handleChange}
          accept=".jpg,.jpeg,.png"
        >
          {(props.input?.value || []).length <= 8 && uploadButton}
        </Upload>
      </div>
    </NotReleased>
  );
};

export default withField(ChangeUpload);
