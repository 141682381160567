import React from 'react';
import { Link } from 'react-router-dom';
import { CustomIcon } from '@plandok/core';

export default function SocialMediaIcon({ href, type }: any) {
  return (
    <Link target="_blank" to={href}>
      <CustomIcon type={type} />
    </Link>
  );
}
