import React from 'react';

export default {
  close: (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L21 21M21 1L1 21" stroke="#14316D" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  dots: (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.865" height="3.717" viewBox="0 0 14.865 3.717">
      <g id="Group_212" data-name="Group 212" transform="translate(4022.999 -19199.283) rotate(-90)">
        <circle
          id="Ellipse_74"
          data-name="Ellipse 74"
          cx="1.858"
          cy="1.858"
          r="1.858"
          transform="translate(-19203 -4011.85)"
          fill="#13316d"
        />
        <circle
          id="Ellipse_75"
          data-name="Ellipse 75"
          cx="1.858"
          cy="1.858"
          r="1.858"
          transform="translate(-19203 -4017.425)"
          fill="#13316d"
        />
        <circle
          id="Ellipse_76"
          data-name="Ellipse 76"
          cx="1.858"
          cy="1.858"
          r="1.858"
          transform="translate(-19203 -4023)"
          fill="#13316d"
        />
      </g>
    </svg>
  ),
  tools: (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.953" viewBox="0 0 20 19.953">
      <path
        id="sliders-v-alt"
        d="M20,8.18V3a1,1,0,0,0-2,0V8.18a3,3,0,0,0,0,5.64V21a1,1,0,0,0,2,0V13.82a3,3,0,0,0,0-5.64ZM19,12a1,1,0,1,1,1-1A1,1,0,0,1,19,12Zm-6,2.18V3a1,1,0,0,0-2,0V14.18a3,3,0,0,0,0,5.64V21a1,1,0,0,0,2,0V19.82a3,3,0,0,0,0-5.64ZM12,18a1,1,0,1,1,1-1A1,1,0,0,1,12,18ZM6,6.18V3A1,1,0,0,0,4,3V6.18a3,3,0,0,0,0,5.64V21a1,1,0,0,0,2,0V11.82A3,3,0,0,0,6,6.18ZM5,10A1,1,0,1,1,6,9,1,1,0,0,1,5,10Z"
        transform="translate(22 -2.024) rotate(90)"
        fill="#13316d"
      />
    </svg>
  ),
  left: (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="14.212" height="11.804" viewBox="0 0 14.212 11.804">
      <g id="left-arrow_2_" data-name="left-arrow (2)" transform="translate(0 -41.674)">
        <g id="Group_161" data-name="Group 161" transform="translate(0 41.674)">
          <path
            id="Path_105"
            data-name="Path 105"
            d="M13.413,46.462l.022,0H3.925l2.99-3a.785.785,0,0,0,0-1.1L6.45,41.9a.778.778,0,0,0-1.1,0L.226,47.025a.783.783,0,0,0,0,1.1l5.125,5.125a.779.779,0,0,0,1.1,0l.465-.465a.77.77,0,0,0,.227-.549.747.747,0,0,0-.227-.539L3.891,48.686h9.532a.805.805,0,0,0,.788-.8V47.23A.79.79,0,0,0,13.413,46.462Z"
            transform="translate(0 -41.674)"
            fill="#13316d"
          />
        </g>
      </g>
    </svg>
  ),
  bigDots: (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.734" height="5.656" viewBox="0 0 21.734 5.656">
      <g id="Group_70" data-name="Group 70" transform="translate(-378 433.656) rotate(-90)">
        <circle
          id="Ellipse_27"
          data-name="Ellipse 27"
          cx="2.828"
          cy="2.828"
          r="2.828"
          transform="translate(428 378)"
          fill="#adb6cc"
        />
        <circle
          id="Ellipse_28"
          data-name="Ellipse 28"
          cx="2.828"
          cy="2.828"
          r="2.828"
          transform="translate(428 386.039)"
          fill="#adb6cc"
        />
        <circle
          id="Ellipse_29"
          data-name="Ellipse 29"
          cx="2.828"
          cy="2.828"
          r="2.828"
          transform="translate(428 394.078)"
          fill="#adb6cc"
        />
      </g>
    </svg>
  ),
  arrowSlider: (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 7.72747H16.5M1.5 7.72747L7.5498 1.70312M1.5 7.72747L7.5498 13.7521"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  arrow: (
    <svg viewBox="0 0 24 24" width="24" height="24">
      <path
        d="M14.481 12l-7.14 6.247a1 1 0 001.318 1.506l8-7a1 1 0 000-1.506l-8-7a1 1 0 10-1.317 1.506L14.482 12z"
        fill="rgb(255, 255, 255)"
        fillRule="evenodd"
      ></path>
    </svg>
  ),
  search: (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7309 18.3109L16.0209 14.6309C17.461 12.8353 18.1584 10.5562 17.9697 8.2622C17.781 5.9682 16.7206 3.83368 15.0064 2.29754C13.2923 0.761407 11.0547 -0.0595894 8.75382 0.00337096C6.45294 0.0663314 4.26362 1.00846 2.63604 2.63604C1.00846 4.26362 0.0663314 6.45294 0.00337096 8.75382C-0.0595894 11.0547 0.761407 13.2923 2.29754 15.0064C3.83368 16.7206 5.9682 17.781 8.2622 17.9697C10.5562 18.1584 12.8353 17.461 14.6309 16.0209L18.3109 19.7009C18.4011 19.7988 18.51 19.8776 18.6311 19.9326C18.7523 19.9876 18.8833 20.0177 19.0163 20.0211C19.1493 20.0246 19.2817 20.0013 19.4056 19.9526C19.5294 19.9039 19.6422 19.8309 19.7373 19.7378C19.8324 19.6447 19.9079 19.5335 19.9592 19.4107C20.0105 19.2879 20.0366 19.1561 20.036 19.023C20.0354 18.8899 20.0081 18.7583 19.9557 18.636C19.9033 18.5137 19.8268 18.4031 19.7309 18.3109ZM9.0209 16.0209C7.63643 16.0209 6.28305 15.6104 5.13191 14.8412C3.98076 14.072 3.08356 12.9788 2.55374 11.6997C2.02393 10.4206 1.88531 9.01314 2.1554 7.65527C2.4255 6.2974 3.09219 5.05012 4.07115 4.07115C5.05012 3.09219 6.2974 2.4255 7.65527 2.1554C9.01314 1.88531 10.4206 2.02393 11.6997 2.55374C12.9788 3.08356 14.072 3.98076 14.8412 5.13191C15.6104 6.28305 16.0209 7.63643 16.0209 9.0209C16.0209 10.8774 15.2834 12.6579 13.9706 13.9706C12.6579 15.2834 10.8774 16.0209 9.0209 16.0209Z"
        fill="#13316D"
      />
    </svg>
  ),
  cardDots: (
    <svg width="5" height="21" viewBox="0 0 5 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2.5" cy="2.5" r="2.5" transform="rotate(90 2.5 2.5)" fill="#13316D" />
      <circle cx="2.5" cy="10.5" r="2.5" transform="rotate(90 2.5 10.5)" fill="#13316D" />
      <circle cx="2.5" cy="18.5" r="2.5" transform="rotate(90 2.5 18.5)" fill="#13316D" />
    </svg>
  ),
  print: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 9.6C5.76266 9.6 5.53065 9.67038 5.33332 9.80224C5.13598 9.93409 4.98217 10.1215 4.89134 10.3408C4.80052 10.5601 4.77676 10.8013 4.82306 11.0341C4.86936 11.2669 4.98365 11.4807 5.15147 11.6485C5.3193 11.8164 5.53311 11.9306 5.76589 11.9769C5.99867 12.0232 6.23995 11.9995 6.45922 11.9087C6.67849 11.8178 6.86591 11.664 6.99776 11.4667C7.12962 11.2693 7.2 11.0373 7.2 10.8C7.2 10.4817 7.07357 10.1765 6.84853 9.95147C6.62348 9.72643 6.31826 9.6 6 9.6ZM20.4 4.8H19.2V1.2C19.2 0.88174 19.0736 0.576515 18.8485 0.351472C18.6235 0.126428 18.3183 0 18 0H6C5.68174 0 5.37652 0.126428 5.15147 0.351472C4.92643 0.576515 4.8 0.88174 4.8 1.2V4.8H3.6C2.64522 4.8 1.72955 5.17929 1.05442 5.85442C0.379285 6.52955 0 7.44522 0 8.4V15.6C0 16.5548 0.379285 17.4705 1.05442 18.1456C1.72955 18.8207 2.64522 19.2 3.6 19.2H4.8V22.8C4.8 23.1183 4.92643 23.4235 5.15147 23.6485C5.37652 23.8736 5.68174 24 6 24H18C18.3183 24 18.6235 23.8736 18.8485 23.6485C19.0736 23.4235 19.2 23.1183 19.2 22.8V19.2H20.4C21.3548 19.2 22.2705 18.8207 22.9456 18.1456C23.6207 17.4705 24 16.5548 24 15.6V8.4C24 7.44522 23.6207 6.52955 22.9456 5.85442C22.2705 5.17929 21.3548 4.8 20.4 4.8ZM7.2 2.4H16.8V4.8H7.2V2.4ZM16.8 21.6H7.2V16.8H16.8V21.6ZM21.6 15.6C21.6 15.9183 21.4736 16.2235 21.2485 16.4485C21.0235 16.6736 20.7183 16.8 20.4 16.8H19.2V15.6C19.2 15.2817 19.0736 14.9765 18.8485 14.7515C18.6235 14.5264 18.3183 14.4 18 14.4H6C5.68174 14.4 5.37652 14.5264 5.15147 14.7515C4.92643 14.9765 4.8 15.2817 4.8 15.6V16.8H3.6C3.28174 16.8 2.97652 16.6736 2.75147 16.4485C2.52643 16.2235 2.4 15.9183 2.4 15.6V8.4C2.4 8.08174 2.52643 7.77652 2.75147 7.55147C2.97652 7.32643 3.28174 7.2 3.6 7.2H20.4C20.7183 7.2 21.0235 7.32643 21.2485 7.55147C21.4736 7.77652 21.6 8.08174 21.6 8.4V15.6Z"
        fill="#13316D"
      />
    </svg>
  ),
  checked: (
    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.40388 11C5.24721 11.0006 5.09199 10.9695 4.94718 10.9085C4.80237 10.8475 4.67086 10.7578 4.56024 10.6446L0.353827 6.3555C0.242204 6.24311 0.153477 6.10932 0.0927536 5.96182C0.0320304 5.81432 0.000509819 5.65603 6.1335e-06 5.49606C-0.000497552 5.33609 0.0300256 5.17759 0.0898188 5.0297C0.149612 4.88181 0.237494 4.74744 0.348408 4.63432C0.459321 4.5212 0.591075 4.43157 0.736087 4.37059C0.881099 4.30961 1.0365 4.27848 1.19336 4.27899C1.35021 4.27951 1.50542 4.31165 1.65004 4.37358C1.79467 4.43551 1.92586 4.526 2.03605 4.63985L5.40388 8.07116L12.9747 0.34984C13.1983 0.124739 13.5001 -0.0010226 13.8143 6.26355e-06C14.1284 0.00103513 14.4294 0.12877 14.6516 0.355331C14.8737 0.581891 14.999 0.888878 15 1.20928C15.001 1.52968 14.8777 1.83749 14.657 2.0655L6.24162 10.6446C6.01958 10.8716 5.71827 10.9994 5.40388 11Z"
        fill="white"
      />
    </svg>
  ),
};
