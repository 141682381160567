import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { SearchInput, Text } from '@plandok/core';
import { ModifyLayout, NoInternetConnection } from 'components';
import useQuery from 'hooks/data/useQuery';
import * as api from 'api';
import SearchAppointmentColumn from './components/SearchAppointmentColumn';
import calendarState from '../CalendarPage/store/calendar.state';
import SearchClientColumn from './components/SearchClientColumn';
import * as helpers from './helpers';
import * as SC from './styles';

export default function SearchPage() {
  const [timeFormat, setTimeFormat] = useState('');
  const [value, setValue] = useState();

  const { data } = useQuery({
    fetchData: api.fetchSearch,
    filterParams: { search_phrase: value },
    extractDataFunc: helpers.extractSearchData,
  }) as any;

  const { clients = [], bookings = [] } = data || {};
  const onChange = ({ target }: any) => setValue(target.value || '');

  useEffect(() => {
    calendarState.initialFetchData().then(() => {
      const { timeFormat } = calendarState.data ?? {};

      setTimeFormat(timeFormat);
    });
  }, []);

  return (
    <NoInternetConnection>
      <ModifyLayout mdWhite>
        <SC.Container>
          <Col span={24}>
            <SearchInput placeholder="input.globalSearch.placeholder" size="large" value={value} onChange={onChange} />
            <Text label="input.globalSearch.description" className="mt-3 mb-1" size="base" />
            <Row justify="space-between" type="flex" className="w-100 mt-3">
              {bookings.length > 0 && <SearchAppointmentColumn data={bookings} timeFormat={timeFormat} />}
              <SearchClientColumn searchClientList={clients} />
              {!bookings.length && <SearchAppointmentColumn data={bookings} timeFormat={timeFormat} />}
            </Row>
          </Col>
        </SC.Container>
      </ModifyLayout>
    </NoInternetConnection>
  );
}
