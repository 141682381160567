import { FieldRenderProps } from 'react-final-form';
import withFormBlock from './FormBlock/withFormBlock';
import TextInput from '../input/TextInput';
import React from 'react';

interface FormInputProps {
  submitting?: boolean;
  limit?: number;
}

const FormInput = ({ input, submitting, limit, meta, ...rest }: FormInputProps & FieldRenderProps<any, any>) => {
  return <TextInput id={input.name} {...input} {...rest} limit={limit} />;
};

export default withFormBlock<FormInputProps>(FormInput);
