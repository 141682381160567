import { Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { IntlLabel } from '@plandok/i18n';
import { Button } from '@plandok/core';

const CalendarAddItem = (props: any) => (
  <Menu className="app-common-dropdown" {...props}>
    <Menu.Item onClick={props.createAppointment}>
      <IntlLabel label="appointment.create.menu" />
    </Menu.Item>
    <Menu.Item onClick={props.createBlockTime}>
      <IntlLabel label="blocktime.create.menu" />
    </Menu.Item>
  </Menu>
);

function CalendarAddItemBtn({ openSetBlockTime, createAppointment }: any) {
  return (
    <Dropdown
      overlay={<CalendarAddItem createBlockTime={openSetBlockTime} createAppointment={createAppointment} />}
      trigger={['click']}
      placement="bottomRight"
    >
      <Button label="btn.add.new" minorBtn data-calendar-add-dropdown />
    </Dropdown>
  );
}

export default observer(CalendarAddItemBtn);
