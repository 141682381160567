import {
  countryOptions,
  currencyOptions,
  timeZoneOptions,
  phonePrefixOptions,
  LocalStorage,
  callAction,
} from '@plandok/core';
import axios from './axios';
import { LANGUAGE_HEADER } from 'constants/api';
import * as ApiType from './api.d';

export const login = (props: any) => callAction<ApiType.Login>('/login', 'post', true)(props);

export const getLocationInfo = async () => {
  try {
    const res: any = await axios.get(
      `https://ip-api.io/json?api_key=${process.env.REACT_APP_IP_API_KEY || 'feae14ac-26cb-4524-9d3b-87530d5a6fa1'}`
    );

    const data = res?.data;
    return {
      companyCountryIsoCode: countryOptions.find((e: any) => e.code === data?.country_code)?.code,
      companyTimeZone: timeZoneOptions.find((e: any) => e.code === data?.time_zone)?.code,
      companyCurrencyIsoCode: currencyOptions.find((e: any) => e.code === data?.currency)?.code,
      callingCode: phonePrefixOptions.find(
        (option) => option.value === `+${data?.callingCode}` && option.country === data?.country_name
      )?.value,
    };
  } catch (e) {
    return {};
  }
};

export const register = callAction<ApiType.Registration>('/register', 'post', true);

export const authSuccess = (props: any) => {
  LocalStorage.setRefreshToken(props.refreshToken);
  LocalStorage.setAuthToken(props.token);
  if (props.context?.languageIsoCode) {
    axios.defaults.headers[LANGUAGE_HEADER] = props.context.languageIsoCode;
  }
  axios.defaults.headers.Authorization = `Bearer ${props.token}`;
};

export const logout = () => {
  delete axios.defaults.headers.Authorization;
  delete axios.defaults.headers[LANGUAGE_HEADER];
};

export const requestPasswordChange = callAction<ApiType.Login>('/request-password-reset', 'post', true);

export const fetchSubscriptionInfo = callAction('/subscription-billing/subscription', 'get', true);

export const setNewPassword = callAction('/reset-password', 'post', true);
