import styled, { css } from 'styled-components';
import { mediaMobileDown, mediaMdUp } from '@plandok/core';

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  width: 140px;
  justify-content: center;
  border-right: 1px solid #e9ecf3;
  div[data-total-item-main] {
    font-size: 35px;
  }
  div[data-total-item-minor] {
    font-size: 16px;
  }

  ${mediaMobileDown(css`
    display: none;
  `)}
`;

export const TotalNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  [data-total-numbers-row] {
    display: flex;
    justify-content: space-around;
    padding: 25px;
    &:not(:last-of-type) {
      border-bottom: 1px solid #e9ecf3;
      ${mediaMobileDown(css`
        border-bottom: 0;
      `)}
    }
    ${mediaMobileDown(css`
      padding: 0 15px;
    `)}
  }
`;

export const Container = styled.div<any>`
  display: flex;
  flex-direction: row;
  width: 100%;
  [data-total-item-main] {
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    color: #61749d;
  }
  [data-total-item-minor] {
    text-align: center;
    font-size: 14px;
    color: #adb6cc;
  }
  ${mediaMobileDown(css`
    padding: 15px 0;
    flex-direction: column;
  `)}
`;

export const Divider = styled.div`
  height: 1px;
  background: #e9ecf3;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  ${mediaMdUp(css`
    display: none;
  `)}
`;
