import styled, { css } from 'styled-components';
import { mediaMobileDown, Card } from '@plandok/core';

export const HeaderText = styled.div`
  padding-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 430px;
  svg {
    margin-bottom: 15px;
  }
  ${mediaMobileDown(css`
    padding-top: 110px;
    max-width: 300px;
  `)}
`;

export const CardContainer = styled(Card)`
  max-width: 540px;
  width: 535px;
  margin: 5px auto 6px auto;
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  padding: 35px;
  border-radius: 20px;
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .ant-row-flex-space-between {
    justify-content: flex-start;
    align-items: center;
  }
  ${mediaMobileDown(css`
    max-width: 355px;
    padding: 15px;
    .ant-select-selection--single {
      width: 170px;
    }
  `)}
`;

export const Text = styled.div`
  div {
    margin-bottom: 10px;
    font-size: 22px;
  }
`;

export const CheckedBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .ant-checkbox-wrapper {
    color: #14316d;
    margin: 0;
    padding: 5px 0;
    ${mediaMobileDown(css`
      &:first-child {
        padding-right: 50px;
      }
    `)}
  }
  .ant-checkbox-inner {
    background: #fff;
  }
  ${mediaMobileDown(css`
    flex-direction: row;
    justify-content: flex-start;
  `)}
`;

export const CampaignsCardBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2472e8 !important;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  svg: first-child {
    margin-right: 10px;
    margin-top: 0px;
  }
  svg {
    margin-left: 10px;
    margin-top: 0px;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export const CampaignsServiceGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DayItem = styled.div`
  ${mediaMobileDown(css`
    margin-top: -88px;
    margin-left: 185px;
  `)}
`;
