import React, { useState, useEffect } from 'react';
import flow from 'lodash/flow';
import { Icon } from 'antd';
import { getScreenHeight, Text, getIsMobile, AuthBlock, SearchInput, Portal } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import calendarState from 'pages/dashboard/CalendarPage/store/calendar.state';
import { PermissionType } from 'constants/auth';
import { useStore } from 'store/store';
import * as api from 'api';
import ClientSearchList from './components/ClientSearchList';
import EmptyListHolder from './components/EmptyListHolder';
import ModifyClientPage from '../../../ModifyClientPage';
import ClientDetails from './components/ClientDetails';
import * as SC from './styles';

export default function ClientSelectMenu({ input }: any) {
  const [value, setValue] = useState('');
  const [version, setVersion] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [isOpenClient, setIsOpenClient] = useState(false);
  const [clients, setClients] = useState([]);
  const [isMobileOpen, setMobileOpen] = useState(false);
  const setAllowSaveButton = useStore((state) => state.setAllowSaveButton);
  const [timeFormat, setTimeFormat] = useState('');
  const isMobile = getIsMobile();

  useEffect(() => {
    calendarState.initialFetchData().then(() => {
      const { timeFormat } = calendarState.data ?? {};

      setTimeFormat(timeFormat);
    });

    if (!isOpenClient) {
      api.fetchClientOptions(value ? { query: value } : value).then((e) => setClients((e || {}).clients || []));
    }
  }, [value, isOpenClient]);

  const openCreateClient = () => setIsOpenClient(true);
  const closeClientCreate = () => setIsOpenClient(false);
  const scrollHeight = getScreenHeight();

  const clientEdit = (
    <Portal>
      {isOpenClient && (
        <SC.ClientContainer style={{ minHeight: scrollHeight || '100vh' }}>
          <ModifyClientPage
            onClose={closeClientCreate}
            onSuccess={(res: any) => {
              if (!input.value) {
                input.onChange(res.clientId);
              } else {
                setVersion(version + 1);
              }
              closeClientCreate();
            }}
            style={{ background: 'white' }}
            customId={input.value}
            skipDetails={!input.value}
          />
        </SC.ClientContainer>
      )}
    </Portal>
  );

  if (input.value) {
    const removeClient = () => {
      input.onChange(null);
      setValue('');
      setAllowSaveButton(true);
    };

    return (
      <>
        <ClientDetails
          removeClient={removeClient}
          clientId={input.value}
          openCreateClient={openCreateClient}
          key={version}
          timeFormat={timeFormat}
        />
        {clientEdit}
      </>
    );
  }

  const onOpen = !isOpen ? () => setOpen(true) : undefined;

  const toggleMobile = () => {
    setMobileOpen(!isMobileOpen);
    setAllowSaveButton(isMobileOpen);
  };

  const setMobileClient = flow(input.onChange, toggleMobile);

  const filteredClients = clients;

  return (
    <>
      <SC.Container onClick={onOpen}>
        <SearchInput value={value} setValue={setValue} />
        {!isOpen ? (
          <EmptyListHolder />
        ) : (
          <ClientSearchList
            clients={filteredClients}
            selectClient={input.onChange}
            createNewClient={openCreateClient}
          />
        )}
      </SC.Container>
      {!isMobile && (
        <AuthBlock section={PermissionType.CLIENTS}>
          <SC.AddItem onClick={openCreateClient}>
            <Icon type="plus-circle" theme="filled" />
            <Text size="small" colorType="base" weight="semiBold" mb="none" upperCase>
              <IntlLabel label="client.create.new" />
            </Text>
          </SC.AddItem>
        </AuthBlock>
      )}
      <SC.SelectClientMobile onClick={toggleMobile}>
        <Icon type="user" /> <IntlLabel label="client.choose" /> <Icon type="down" />
      </SC.SelectClientMobile>
      {isMobileOpen ? (
        <SC.MobileContainer>
          <SC.CloseIcon onClick={toggleMobile}>
            <Icon type="close-circle" />
          </SC.CloseIcon>
          <SearchInput value={value} setValue={setValue} />
          <ClientSearchList
            clients={filteredClients}
            selectClient={setMobileClient}
            createNewClient={openCreateClient}
          />
        </SC.MobileContainer>
      ) : null}
      {clientEdit}
    </>
  );
}
