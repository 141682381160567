import React from 'react';

import { IntlLabel } from '@plandok/i18n';

export const resourcesColumn = [
  {
    title: <IntlLabel label="table.header.resourceName" />,
    dataIndex: 'name',
    key: 'name',
  },
];

export const fakeResources = [...Array(7)].map((_, i) => ({
  id: i,
  name: `Resource ${i}`,
}));
