import React from 'react';
import { validateRequired, Field } from '@plandok/core';
import * as api from 'api';
import withModifyModal from '../withModifyModal';

function CreateCategoryModal() {
  return (
    <>
      <Field.Input name="name" label="input.newCategoryName.label" validate={validateRequired} />
      <Field.TextArea
        name="description"
        label="input.newCategoryDescription.label"
        placeholder="input.newCategoryDescription.placeholder"
        rows="4"
      />
      <Field.ColorSelect name="color" label="input.chooseCategory.label" validate={validateRequired} />
    </>
  );
}

export default withModifyModal({
  label: 'modal.newCategory.title',
  editLabel: 'modal.editCategory.title',
  entityName: 'category',
  entityNameField: 'name',
  deleteAction: api.deleteCategory,
  detailsAction: api.fetchCategoryDetails,
  createAction: api.createCategory,
  editAction: api.editCategory,
  contentClassName: 'campaign-activation-modal',
})(CreateCategoryModal);
