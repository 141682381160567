import React, { useContext } from 'react';
import { CustomIcon, Text, useLocation, withModal } from '@plandok/core';
import { getLabel, IntlLabel, ReactIntl } from '@plandok/i18n';
import { RoutePath } from 'constants/routes';
import * as SC from '../styles';

type CancelSubscriptionErrorModalProps = {
  onClose: () => void;
};

function CancelSubscriptionErrorModal({ onClose }: CancelSubscriptionErrorModalProps) {
  const { lang } = useContext(ReactIntl);
  const { navigate } = useLocation();

  const goToAnotherPage = (path: string) => {
    onClose();
    navigate(path);
  };

  const goToLocationsPage = () => goToAnotherPage(RoutePath.LOCATIONS);
  const goToStaffPage = () => goToAnotherPage(RoutePath.STAFF);

  const ErrorDescription = () => {
    const getErrorDescription = (numberText: string) =>
      getLabel(`modal.cancelSubscriptionError.text.${numberText}`, lang);

    return (
      <SC.ErrorDescription>
        <Text colorType="base" mb="none" size="medium">
          <span>{getErrorDescription('1')} </span>
          <SC.Link onClick={goToStaffPage}>{getErrorDescription('2')}</SC.Link>
          <span> {getErrorDescription('3')} </span>
          <SC.Link onClick={goToLocationsPage}>{getLabel('modal.entity.location', lang)}</SC.Link>
          <span>. {getErrorDescription('4')}</span>
        </Text>
      </SC.ErrorDescription>
    );
  };

  return (
    <>
      <SC.CancelSubscriptionErrorTitle>
        <CustomIcon type="error" />
        <Text
          colorType="base"
          mb="none"
          label="modal.cancelSubscriptionError.title"
          weight="semiBold"
          size="llarge"
          lh="xxxlarge"
        />
      </SC.CancelSubscriptionErrorTitle>
      <ErrorDescription />
      <div style={{ display: 'flex', maxWidth: '324px', justifyContent: 'space-between', margin: '0 auto' }}>
        <SC.BtnLink whiteBtn upperCase={false} onClick={goToLocationsPage}>
          <IntlLabel label="title.locations" />
          <CustomIcon type="arrowRight" />
        </SC.BtnLink>

        <SC.BtnLink whiteBtn upperCase={false} onClick={goToStaffPage}>
          <IntlLabel label="sidebar.staff" />
          <CustomIcon type="arrowRight" />
        </SC.BtnLink>
      </div>
    </>
  );
}

export default withModal(null, { contentClassName: 'cancel-subscription-error-modal' })(CancelSubscriptionErrorModal);
