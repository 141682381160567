import { getIsMobile, AuthBlock } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { Icon } from 'antd';
import React from 'react';

import { PermissionType } from '../../../../../../../constants/auth';
import * as SC from '../../styles';
import ClientSearchItem from './components/ClientSearchItem';
import NoClientsPlaceholder from './components/NoClientsPlaceholder';

export default function ClientSearchList({ clients = [], selectClient, createNewClient }: any) {
  return (
    <SC.ClientListContainer>
      <SC.ClientList>
        {clients.map((e: any, index: number) => (
          <ClientSearchItem key={index} client={e} selectClient={selectClient} />
        ))}
        {!clients.length && <NoClientsPlaceholder createNewClient={createNewClient} />}
      </SC.ClientList>
      {getIsMobile() && (
        <SC.MobileAddItemContainer>
          <AuthBlock section={PermissionType.CLIENTS}>
            <SC.AddItem onClick={createNewClient}>
              <Icon type="plus-circle" theme="filled" /> <IntlLabel label="client.create.new" />
            </SC.AddItem>
          </AuthBlock>
        </SC.MobileAddItemContainer>
      )}
    </SC.ClientListContainer>
  );
}
