// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from '../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportBookingsByEmployeeQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  from: Types.Scalars['Date'];
  to: Types.Scalars['Date'];
  locationId?: Types.InputMaybe<Types.Scalars['Uuid']>;
  employeeId?: Types.InputMaybe<Types.Scalars['Uuid']>;
}>;

export type ReportBookingsByEmployeeQuery = {
  __typename?: 'AppQuery';
  reports?: {
    __typename?: 'App_Reports';
    bookingsByEmployee?: {
      __typename?: 'App_BookingsByEmployeeReportConnection';
      totalCount?: number | null;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor?: string | null;
        endCursor?: string | null;
      };
      edges?: Array<{
        __typename?: 'App_BookingsByEmployeeReportEdge';
        cursor: string;
        node?: {
          __typename?: 'App_BookingsByEmployeeReport';
          firstName: string;
          lastName?: string | null;
          totalCount: number;
          totalPrice: { __typename?: 'Money'; amount: string; currency: string };
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export const ReportBookingsByEmployeeDocument = gql`
  query ReportBookingsByEmployee(
    $offset: Int
    $limit: Int
    $from: Date!
    $to: Date!
    $locationId: Uuid
    $employeeId: Uuid
  ) {
    reports(from: $from, to: $to, offset: $offset, limit: $limit) {
      bookingsByEmployee(locationId: $locationId, employeeId: $employeeId) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            firstName
            lastName
            totalCount
            totalPrice {
              amount
              currency
            }
          }
        }
      }
    }
  }
`;

/**
 * __useReportBookingsByEmployeeQuery__
 *
 * To run a query within a React component, call `useReportBookingsByEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportBookingsByEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportBookingsByEmployeeQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      locationId: // value for 'locationId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useReportBookingsByEmployeeQuery(
  baseOptions: Apollo.QueryHookOptions<ReportBookingsByEmployeeQuery, ReportBookingsByEmployeeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportBookingsByEmployeeQuery, ReportBookingsByEmployeeQueryVariables>(
    ReportBookingsByEmployeeDocument,
    options
  );
}
export function useReportBookingsByEmployeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportBookingsByEmployeeQuery, ReportBookingsByEmployeeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportBookingsByEmployeeQuery, ReportBookingsByEmployeeQueryVariables>(
    ReportBookingsByEmployeeDocument,
    options
  );
}
export type ReportBookingsByEmployeeQueryHookResult = ReturnType<typeof useReportBookingsByEmployeeQuery>;
export type ReportBookingsByEmployeeLazyQueryHookResult = ReturnType<typeof useReportBookingsByEmployeeLazyQuery>;
export type ReportBookingsByEmployeeQueryResult = Apollo.QueryResult<
  ReportBookingsByEmployeeQuery,
  ReportBookingsByEmployeeQueryVariables
>;
