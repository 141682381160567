import styled, { css } from 'styled-components';
import cn from 'classnames';
import { mediaMdUp } from '@plandok/core';

export const Container = styled.aside<any>`
  z-index: 110;
  background: #14316d;
  height: 60px;
  position: fixed;
  padding: 0 30px;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 3px 25px rgba(19, 49, 109, 0.16);
  justify-content: space-around;
  ${(props) =>
    !props.isMobile &&
    mediaMdUp(css`
      display: none !important;
    `)}
`;

export const LinkItem = styled.a.attrs((props: any) => ({
  className: cn('ant-tooltip-open', props.className),
}))<any>`
  cursor: pointer;
  width: 45px;
  display: flex;
`;

export const ItemIcon = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
`;
