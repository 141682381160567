import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { message } from 'antd';
import * as api from 'api';

export default function useStripeElement() {
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [stripePublicKey, setStripePublicKey] = useState<string | undefined>();
  const [stripePromise, setStripePromise] = useState<any | undefined>();

  useEffect(() => {
    setIsStripeLoading(true);

    (async () => {
      try {
        const { data } = await api.getClientToken();
        setStripePublicKey(data);
        setStripePromise(loadStripe(data || ''));
      } catch (error) {
        message.error(`Error: ${error}. Initialize payment systems.`);
      } finally {
        setIsStripeLoading(false);
      }
    })();
  }, []);

  return {
    isStripeLoading,
    stripePublicKey,
    stripePromise,
  };
}
