import { AnalyticsHash } from 'constants/routes';

export const analyticsTabsConfig: any = [
  {
    icon: 'chartVector',
    path: AnalyticsHash.DASHBOARD,
    title: 'analytics.dashboard.tab',
    description: 'analytics.menu.dashboard.description',
  },
  {
    icon: 'dialogVector',
    path: AnalyticsHash.REPORTS,
    title: 'analytics.reports.tab',
    description: 'analytics.menu.reports.description',
  },
];
