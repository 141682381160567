export enum LogType {
  CREATED = 'created',
  UPDATED = 'updated',
  RESCHEDULED = 'rescheduled',
  CANCELLED = 'cancelled',
  CHANGED_STATUS = 'changed_status',
}

export interface Log {
  id: string;
  type: LogType;
  createdAt: string;
  employee: {
    firstName: string;
    lastName: string;
  };
}
