import React, { useState } from 'react';
import addDays from 'date-fns/addDays';
import { observer } from 'mobx-react';
import format from 'date-fns/format';
import cn from 'classnames';
import { CustomIcon, DatePicker, IconButton, getIsMobile } from '@plandok/core';
import { ICalendarActions, ICustomComponents } from 'epic-calendar';
import { getCalendarRangeStart } from 'helpers/date/calendar';
import { DateFilterDisplay } from 'components';
import CalendarMobileFilter from '../CalendarMobileFilter';
import calendarState from '../../store/calendar.state';
import * as SC from './styles';

interface CalendarMobileHeaderProps extends ICalendarActions, ICustomComponents {
  isWeekMode: boolean;
  date: Date;
  isSelectSlotMode: boolean;
  staffFromFilterParams?: string;
  timeFormat: string;
  changeDate: (d: Date) => void;
  scrollToCurrentTime: () => void;
}

type DatePickerRangeDate = string & Date;

function CalendarMobileHeader({
  isWeekMode,
  date = new Date(),
  changeDate,
  scrollToCurrentTime,
}: CalendarMobileHeaderProps) {
  const [visibleFilter, setVisibleFilter] = useState(false);
  const toggleFilter = () => setVisibleFilter(!visibleFilter);
  const onClose = () => setVisibleFilter(false);

  const rangeDate = getCalendarRangeStart(calendarState.filterParams.date, isWeekMode);
  const setToday = () => {
    calendarState.changeFilter('date')(new Date());
    scrollToCurrentTime();
  };
  const daysDelta = isWeekMode ? 7 : 1;

  return (
    <SC.Container>
      <SC.CalendarIcon onClick={setToday}>
        <CustomIcon type="calendar" />
        <div data-today-date>{format(new Date(), 'dd')}</div>
      </SC.CalendarIcon>

      <SC.DateFilterContainer className="date-picker-right-position filter-datepicker-container">
        <SC.LeftIconButton icon="left" ghost onClick={() => changeDate(addDays(date, -daysDelta))} />
        <DatePicker
          value={rangeDate as DatePickerRangeDate}
          customInput={
            <SC.DatePickerButton>
              <DateFilterDisplay isWeekMode={isWeekMode} date={date} />
            </SC.DatePickerButton>
          }
          onChange={calendarState.changeFilter('date')}
          className="filter-datepicker"
          calendarClassName={cn({ 'week-mode-react-datepicker': isWeekMode })}
          withPortal={getIsMobile()}
        />
        <SC.RightIconButton icon="right" ghost onClick={() => changeDate(addDays(date, daysDelta))} />
      </SC.DateFilterContainer>

      <div tabIndex={0} role="button" onClick={toggleFilter}>
        <IconButton icon="tools" />
      </div>
      <CalendarMobileFilter visible={visibleFilter} onClose={onClose} />
    </SC.Container>
  );
}

export default observer(CalendarMobileHeader);
