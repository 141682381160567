import React, { ReactNode } from 'react';
import addWeeks from 'date-fns/addWeeks';
import { Button } from '@plandok/core';
import * as SC from '../styles';

type DatePickerContainerProps = {
  date: Date;
  children: ReactNode[];
  changeDate(date: Date): void;
  className?: string;
};

export const DatePickerContainer = ({
  className,
  children,
  date,
  changeDate,
}: DatePickerContainerProps): JSX.Element => {
  const weekOptions = [
    { label: 'datepicker.next.week', nextWeek: 1 },
    { label: 'datepicker.in.two.weeks', nextWeek: 2 },
    { label: 'datepicker.in.three.weeks', nextWeek: 3 },
    { label: 'datepicker.in.four.weeks', nextWeek: 4 },
    { label: 'datepicker.in.five.weeks', nextWeek: 5 },
    { label: 'datepicker.in.six.weeks', nextWeek: 6 },
  ];

  const renderBtn = weekOptions.map((option) => (
    <Button
      key={option.label}
      upperCase={false}
      onClick={() => changeDate(addWeeks(date, option.nextWeek))}
      label={option.label}
    />
  ));

  return (
    <div className={className}>
      <SC.DatePickerContainer>{children}</SC.DatePickerContainer>
      <SC.DatePickerBtnContainer>{renderBtn}</SC.DatePickerBtnContainer>
    </div>
  );
};

export default DatePickerContainer;
