import styled, { css } from 'styled-components';
import { mediaMobileDown, Card } from '@plandok/core';

export const CardContainer = styled(Card)`
  max-width: 570px;
  width: 568px;
  margin: 30px auto 6px auto;
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  padding: 45px 45px 30px;
  border-radius: 20px;
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${mediaMobileDown(css`
    max-width: 355px;
    padding: 15px 15px 15px 30px;
    margin-top: 110px;
  `)}
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  svg {
    margin-bottom: 15px;
  }
  ${mediaMobileDown(css`
    padding-top: 10px;
  `)}
`;

export const Text = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    fill: #14316d;
  }
`;

export const PricingContainer = styled.div`
  position: relative;
  margin-bottom: 15px;
  .ant-collapse {
    border: none;
  }
  .ant-collapse-item-active > .ant-collapse-header > .ant-collapse-arrow {
    svg {
      transform: rotate(-90deg) !important;
    }
  }
  .ant-collapse-content {
    border-top: 1px solid #e9ecf3;
  }
  .ant-collapse > .ant-collapse-item {
    border-radius: initial;
    border-bottom: 1px solid #e9ecf3;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 20px;
    font-weight: 600;
    color: #13316d;
    padding: 5px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: auto;
    right: 10px !important;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 5px;
  }
`;

export const PricingIcon = styled.div`
  i {
    position: absolute;
    left: -25px;
    top: 60px;
  }
`;

export const PricingTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PricingText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${mediaMobileDown(css`
    &:first-child {
      margin-right: 20px;
    }
  `)}
`;

export const ApproximatePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  strong {
    font-size: 32px;
  }
  ${mediaMobileDown(css`
    display: block;
    div:last-child {
      float: right;
    }
  `)}
`;

export const Pricing = styled.div`
  color: #13316d;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  ${mediaMobileDown(css`
    margin: 0;
  `)};
`;
