import React from 'react';
import EmailMessageForm from './components/EmailMessageForm';
import { RoutePath, MarketingHash } from 'constants/routes';
import { ModifyMassLayout } from 'components';
import withFormApi from 'hocs/form/withFormApi';
import { NotReleased } from 'components';
import * as api from 'api';

export const EmailMassageFormWithApi = withFormApi({
  createAction: api.createService,
  backPath: `${RoutePath.MARKETING}#${MarketingHash.MASSMESSAGES}`,
  successMessage: 'Message',
})(EmailMessageForm);

export default function ModifyMessagePage() {
  return (
    <NotReleased>
      <ModifyMassLayout title="Message" step="Email setup. Step 1 of 4">
        <EmailMassageFormWithApi />
      </ModifyMassLayout>
    </NotReleased>
  );
}
