import { Icon, List } from 'antd';
import React from 'react';
import { IntlLabel } from '@plandok/i18n';
import { AppLayout, MobileBackButton, NoInternetConnection } from 'components';
import { RoutePath, StaffTabHash } from 'constants/routes';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { useLocation } from 'hooks/router';

export default function MobileStaffPage() {
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const { navigate } = useLocation();

  return (
    <NoInternetConnection>
      <AppLayout
        headerTitle="sidebar.staff"
        mobileHeader={() => (
          <>
            <MobileBackButton />
            <div>
              <IntlLabel label="sidebar.staff" />
            </div>
            <div />
          </>
        )}
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <List
          itemLayout="horizontal"
          className="staff-menu__list"
          dataSource={[
            {
              icon: 'user',
              name: 'staff.menu.members',
              description: 'staff.menu.members.description',
              path: `${RoutePath.STAFF}#${StaffTabHash.STAFF_MEMBERS}`,
            },
            {
              icon: 'clock-circle',
              name: 'staff.menu.working',
              description: 'staff.menu.working.description',
              path: `${RoutePath.STAFF}#${StaffTabHash.WORKING_HOURS}`,
            },
            {
              icon: 'calendar',
              name: 'staff.menu.dates',
              description: 'staff.menu.dates.description',
              path: `${RoutePath.STAFF}#${StaffTabHash.CLOSED_DATES}`,
            },
            {
              icon: 'lock',
              name: 'staff.menu.permissions',
              description: 'staff.menu.permissions.description',
              path: `${RoutePath.STAFF}#${StaffTabHash.USER_PERMISSIONS}`,
            },
          ]}
          renderItem={(item: any) => (
            <List.Item onClick={() => navigate(item.path)}>
              <List.Item.Meta
                avatar={<Icon type={item.icon} />}
                title={<IntlLabel label={item.name} />}
                description={<IntlLabel label={item.description} />}
              />
              <div>
                <Icon type="right" />
              </div>
            </List.Item>
          )}
        />
      </AppLayout>
    </NoInternetConnection>
  );
}
