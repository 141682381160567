import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import React from 'react';
import { IntlLabel } from '@plandok/i18n';
import { RoutePath } from 'constants/routes';

type HeaderUserMenuProps = {
  openSelectLanguage: () => void;
  logout: () => void;
};

export default function HeaderUserMenu({ openSelectLanguage, logout, ...props }: HeaderUserMenuProps) {
  return (
    <Menu className="app-common-dropdown" {...props}>
      <Menu.Item>
        <Link to={RoutePath.PERSONAL_SETTINGS}>
          <IntlLabel label="user.menu.settings" />
        </Link>
      </Menu.Item>
      <Menu.Item onClick={openSelectLanguage}>
        <IntlLabel label="user.menu.language" />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={logout}>
        <IntlLabel label="user.menu.logout" />
      </Menu.Item>
    </Menu>
  );
}
