/* eslint-disable no-param-reassign */
import getAllInputs from './helpers/getAllInputs';
import defaultFindInput from './helpers/findInput';

const createDecorator = (getInputs = getAllInputs, findInput = defaultFindInput) => form => {
  const focusOnFirstError = errors => {
    const firstInput = findInput(getInputs(), errors);
    if (firstInput) {
      firstInput.focus();
      if (form.getState) {
        /**
         * Initializing form with values to make them touched=false and pristine=true,
         * to prevent showing error when field is modified.
         */
        form.initialize(form.getState().values);
      }
    }
  };
  // Save original submit function
  const originalSubmit = form.submit;

  // Subscribe to errors, and keep a local copy of them
  let state = {};
  const unsubscribe = form.subscribe(
    nextState => {
      state = nextState;
    },
    { errors: true, submitErrors: true }
  );

  // What to do after submit
  const afterSubmit = () => {
    const { errors, submitErrors } = state;
    if (errors && Object.keys(errors).length) {
      focusOnFirstError(errors);
    } else if (submitErrors && Object.keys(submitErrors).length) {
      focusOnFirstError(submitErrors);
    }
  };

  // Rewrite submit function
  form.submit = () => {
    const result = originalSubmit.call(form);
    if (result && typeof result.then === 'function') {
      // async
      result.then(afterSubmit);
    } else {
      // sync
      afterSubmit();
    }
    return result;
  };

  return () => {
    unsubscribe();
    form.submit = originalSubmit;
  };
};

export default createDecorator;
