import { Icon, Row, Spin } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import * as SC from './styles';

interface MobileTableProps {
  rowComponent: any;
  viewDetails?: any;
  className?: string;
  isLoading: boolean;
  fetchData: any;
  hasMore: boolean;
  data: any[];
  mobileColumns?: any[];
}

export default function MobileTable({ rowComponent: RowComponent, ...props }: MobileTableProps) {
  return (
    <Spin spinning={props.isLoading}>
      <SC.Container className={props.className}>
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          loadMore={(ind: any) => props.fetchData(ind)}
          hasMore={props.hasMore}
          loader={
            <SC.SpinnerContainer key="table-spinner">
              <Icon type="loading" />
            </SC.SpinnerContainer>
          }
        >
          <Row type="flex" justify="space-between" align="middle" className="w-100">
            <SC.StyledRowContainer>
              {props.mobileColumns?.map(({ key, title, width }) => (
                <div key={key} style={{ padding: '15px', maxWidth: width, minWidth: width }}>
                  {title}
                </div>
              ))}
            </SC.StyledRowContainer>
          </Row>

          {props.data?.map((e: any) => (
            <SC.Item onClick={() => props?.viewDetails?.(e.id, e)} key={`l-${e.id}`}>
              <RowComponent value={e} />
            </SC.Item>
          ))}
        </InfiniteScroll>
      </SC.Container>
    </Spin>
  );
}
