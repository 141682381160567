import { useEffect, useState } from 'react';
import * as api from 'api';

export default function useGetCurrentLocationsCount() {
  const [isLocationsLoading, setIsLocationsLoading] = useState(false);
  const [locationsCount, setLocationsCount] = useState(1);

  useEffect(() => {
    setIsLocationsLoading(true);

    (async () => {
      try {
        const { meta } = await api.fetchLocations();

        setLocationsCount(meta?.pagination?.total);
      } catch (e) {
        console.error('Error when getting locations');
      } finally {
        setIsLocationsLoading(false);
      }
    })();
  }, []);

  return {
    isLocationsLoading,
    locationsCount,
  };
}
