import cn from 'classnames';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import React from 'react';

interface TextInputProps extends InputProps {
  search?: boolean;
  limit?: number;
}

const TextInput = (props: TextInputProps) => {
  const Component = props.type === 'password' ? Input.Password : Input;
  return (
    <Component maxLength={props.limit} className={cn({ 'search-input': props.search }, props.className)} {...props} />
  );
};

export default TextInput;
