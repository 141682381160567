import React, { ComponentType, ReactNode, useState } from 'react';
import { FieldInputProps } from 'react-final-form';
import { Select } from 'antd';
import cn from 'classnames';
import { IntlLabel } from '@plandok/i18n';
import { Text } from '@plandok/core';
import { OptionGroupType, OptionType } from '../../../../constants/select';
import styled from 'styled-components';
import * as helpers from './helpers';

interface SelectInputProps extends Partial<FieldInputProps<any, any>> {
  submitting?: boolean;
  filter?: boolean;
  hideDropdown?: boolean;
  options?: OptionType[];
  optionGroups?: OptionGroupType[];
  className?: string;
  defaultValue?: string;
  optionRender?: ComponentType<any>;
  onChange: (...arg: any) => void;
  onCustomChange?: (...arg: any) => void;
  onDefaultChange?: () => void;
  dropdownClassName?: string;
  showMenuOnOptionClick?: boolean;
}

const StyledDefaultOption = styled.div`
  position: relative;
  display: block;
  padding: 5px 0;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
  &:hover,
  &:focus {
    color: #14316d;
    background: #f8f9fc;
  }
`;

const SelectInput = ({
  submitting,
  options,
  filter,
  hideDropdown = false,
  defaultValue,
  optionGroups,
  optionRender: OptionRender,
  onChange: onInputChange,
  ...rest
}: SelectInputProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onChange = (value: any, ...arg: any) => {
    if (rest.showMenuOnOptionClick) {
      setDropdownOpen(true);
    }
    if (!rest.showMenuOnOptionClick) {
      onInputChange(value, ...arg);
    }
    if (rest.onCustomChange) {
      rest.onCustomChange(value, helpers.extractValue(value, options, optionGroups));
    }
  };

  const onDefaultValueClick = () => {
    rest.onDefaultChange?.();
    setDropdownOpen(false);
  };

  return (
    <Select
      {...rest}
      onChange={onChange}
      showSearch={options && options?.length >= 10}
      open={dropdownOpen}
      onDropdownVisibleChange={(open) => setDropdownOpen(open)}
      filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      value={rest.value === null || rest.value === '' ? undefined : rest.value}
      className={cn({ 'ant-select__filter': filter }, rest.className)}
      data-field-name={rest.name}
      dropdownClassName={cn(`dropdown_field-${rest.name?.replace(/(\[|\]|\.)/g, '_')}`, rest.dropdownClassName)}
      dropdownRender={
        defaultValue
          ? (menu: ReactNode) => (
              <>
                <StyledDefaultOption onMouseDown={(e) => e.preventDefault()} onClick={onDefaultValueClick}>
                  <Text size="base" weight="normal" colorType="base" ml="small" mb="none">
                    <IntlLabel label={defaultValue} />
                  </Text>
                </StyledDefaultOption>
                {menu}
              </>
            )
          : undefined
      }
    >
      {(options || []).map((val, i) => (
        <Select.Option value={val.code} title={val.name} key={`${val.code}-${i}`}>
          {OptionRender ? <OptionRender value={val.value} /> : val.name}
        </Select.Option>
      ))}
      {(optionGroups || []).map((val, i: number) => (
        <Select.OptGroup label={val.label} key={val.label as any}>
          {(val.options || []).map((v, idx) => (
            <Select.Option value={v.code} title={`${v.name}`} key={`${v.code}-${idx}`}>
              {OptionRender ? <OptionRender value={v.value} /> : v.name}
            </Select.Option>
          ))}
        </Select.OptGroup>
      ))}
    </Select>
  );
};

export default SelectInput;
