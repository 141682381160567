import React from 'react';

import { IntlLabel } from '@plandok/i18n';

export const clientColumns = [
  {
    title: <IntlLabel label="table.clients.name" />,
    dataIndex: 'name',
    key: 'name',
    render: (_: any, value: any) => {
      return (
        <div>
          {value.firstName} {value.lastName}
        </div>
      );
    },
  },
  {
    title: <IntlLabel label="table.clients.mobile" />,
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: <IntlLabel label="table.clients.email" />,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: <IntlLabel label="table.clients.gender" />,
    key: 'gender',
    dataIndex: 'gender',
  },
];
