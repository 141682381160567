import React from 'react';
import { Button } from '@plandok/core';
import { DraggableTable, InfoPlaceholder, PageControlContainer } from 'components';
import withApiData from 'hocs/form/withApiData';
import * as api from 'api';
import { columnsConfig } from './config';

type StaffMembersPageProps = {
  goToCreateStaff: () => void;
  goToUpdateStaff: (id: string) => void;
  isLoading: boolean;
};

const StaffTableWithApi = withApiData(api.fetchEmployees, { isSortable: true })(DraggableTable);

export default function StaffMembersPage({ goToCreateStaff, goToUpdateStaff, isLoading }: StaffMembersPageProps) {
  return (
    <>
      <PageControlContainer mobileHidden>
        <PageControlContainer.Item />
        <PageControlContainer.Item>
          <InfoPlaceholder imgName="staff" titleLabel="state.staff.title" descriptionLabel="state.staff.description" />
        </PageControlContainer.Item>
        <PageControlContainer.Item mobileHidden>
          <Button
            label="btn.new.staff"
            type="primary"
            onClick={goToCreateStaff}
            bold={false}
            upperCase={false}
            data-button-create-staff
            minorBtn
            loading={isLoading}
          />
        </PageControlContainer.Item>
      </PageControlContainer>
      <StaffTableWithApi
        columns={columnsConfig}
        onItemClick={goToUpdateStaff}
        onPositionUpdate={async (id: string, newPosition: number) =>
          await api.changeEmployeePosition('', { id, newPosition })
        }
      />
    </>
  );
}
