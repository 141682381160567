import React, { useState } from 'react';
import { Checkbox, Icon } from 'antd';
import { flow, noop } from 'lodash';
import {
  getIsMobile,
  useLocation,
  CustomIcon,
  sendToClientOption,
  validateRequired,
  Form,
  Text,
  Field,
  Button,
} from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { ModifyMassLayout } from 'components';
import { RoutePath } from 'constants/routes';
import * as SC from '../styles';

export default function BirthdayCampaignForm(props: any) {
  const isMobile = getIsMobile();
  const [stateSMS, setStateSMS] = useState(true);
  const [stateEmail, setStateEmail] = useState(true);
  const { navigate } = useLocation();

  const editMessagePage = () => navigate(RoutePath.CAMPAIGN_SETUP);

  const handleSubmit = (form: any) =>
    props.onSubmit({
      ...form,
      price: form.price || 0,
    });

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        onSuccess={flow(props.onSuccess, props.modalProps?.onSuccess || noop)}
        initialValues={props.initialValues}
      >
        {({ formError, submitting }: any) => {
          return (
            <>
              <SC.HeaderText>
                <CustomIcon type="present" />
                <Text size={isMobile ? 'medium' : 'mlarge'} weight="semiBold" mb="small">
                  <IntlLabel label="Birthday Special" />
                </Text>
                <Text size={isMobile ? 'small' : 'base'}>
                  <IntlLabel label="This campaign automatically sends to clients close to their birthday." />
                </Text>
              </SC.HeaderText>
              <SC.CardContainer>
                <SC.Text>
                  <Text label="Audience" size="mlarge" weight="semiBold" />
                </SC.Text>
                <Field.Select
                  name="birthday"
                  label="Send to Client: "
                  options={sendToClientOption}
                  defaultValue="On the day of Birthday"
                  validate={validateRequired}
                />
                <Text size="mlarge" weight="semiBold" mb="none">
                  <IntlLabel label="Channel" />
                </Text>
                <Text size={isMobile ? 'small' : 'base'} mb="small">
                  <IntlLabel label="Choose what channels campaign will be sent" />
                </Text>
                <SC.CheckedBtn>
                  <Checkbox checked={stateSMS} onClick={() => setStateSMS(!stateSMS)}>
                    <span>
                      <IntlLabel label="SMS" />
                    </span>
                  </Checkbox>
                  <Checkbox checked={stateEmail} onClick={() => setStateEmail(!stateEmail)}>
                    <span>
                      <IntlLabel label="Email" />
                    </span>
                  </Checkbox>
                </SC.CheckedBtn>
              </SC.CardContainer>
              <Form.Alert text={formError} />
              <ModifyMassLayout.Footer>
                <Button
                  block
                  type="primary"
                  label="Edit message"
                  htmlType="submit"
                  loading={submitting}
                  upperCase={false}
                  onClick={editMessagePage}
                >
                  <Icon type="arrow-right" style={{ marginLeft: '10px' }} />
                </Button>
              </ModifyMassLayout.Footer>
            </>
          );
        }}
      </Form>
    </>
  );
}
