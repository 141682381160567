import styled, { css } from 'styled-components';
import { mediaMobileDown, Button, mediaMdDown } from '@plandok/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  max-width: max-content;
  margin: 0 auto;

  ${mediaMobileDown(css`
    padding-top: 20px;
  `)}
`;

export const AddButton = styled(Button)`
  display: flex;
  max-width: max-content;
  align-items: center;
  justify-content: center;
  padding: 0 26px;
  margin-bottom: 36px;
  margin-left: inherit;

  ${mediaMdDown(css`
    margin-bottom: 24px;
    min-width: 100%;
  `)}
`;
