import React from 'react';
import CampaignSetupForm from './components/CampaignSetupForm';
import { NotReleased, ModifyMassLayout } from 'components';
import withFormApi from 'hocs/form/withFormApi';
import { RoutePath } from 'constants/routes';

export const CampaignSetFormWithApi = withFormApi({
  backPath: RoutePath.MARKETING,
  successMessage: 'Message',
})(CampaignSetupForm);

export default function CampaignSetup() {
  return (
    <NotReleased>
      <ModifyMassLayout title="Edit Message" step="Campaign setup. Step 2 of 3">
        <CampaignSetFormWithApi />
      </ModifyMassLayout>
    </NotReleased>
  );
}
