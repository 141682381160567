import React from 'react';
import { NoInternetConnection } from 'components';
import { useLocation } from 'hooks/router';
import SetupCancellationForm from './components/SetupCancellationForm';
import SetupConfirmationForm from './components/SetupConfirmationForm';
import SetupRescheduleForm from './components/SetupRescheduleForm';
import SetupLastVisitForm from './components/SetupLastVisitForm';
import SetupRemindersForm from './components/SetupRemindersForm';
import SetupThankYouForm from './components/SetupThankYouForm';
import SetupPageLayout from './components/SetupPageLayout';
import { getSetupPageLabel } from './helpers';

export default function SetupPage() {
  const { location } = useLocation();

  return (
    <NoInternetConnection>
      <SetupPageLayout title={getSetupPageLabel(location.hash)}>
        <SetupPageLayout.Body>
          <SetupRemindersForm />
        </SetupPageLayout.Body>

        <SetupPageLayout.Body>
          <SetupConfirmationForm />
        </SetupPageLayout.Body>

        <SetupPageLayout.Body>
          <SetupRescheduleForm />
        </SetupPageLayout.Body>

        <SetupPageLayout.Body>
          <SetupCancellationForm />
        </SetupPageLayout.Body>

        <SetupPageLayout.Body>
          <SetupThankYouForm />
        </SetupPageLayout.Body>

        <SetupPageLayout.Body>
          <SetupLastVisitForm />
        </SetupPageLayout.Body>
      </SetupPageLayout>
    </NoInternetConnection>
  );
}
