import React from 'react';
import { IntlLabel } from '@plandok/i18n';

export const locationColumns = [
  {
    title: <IntlLabel label="table.header.locationName" />,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: <IntlLabel label="table.header.locatonAddress" />,
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: <IntlLabel label="table.header.location.Phone" />,
    dataIndex: 'contactPhoneNumber',
    key: 'contactPhoneNumber',
  },
];
