import React from 'react';
import { FACEBOOK_PIXEL_URL } from 'constants/external-links';
import facebookPixel from '../images/facebook-pixel.svg';
import Analytics from '../components/Analytics';

export default function FacebookPixel() {
  const facebookPixelProps = {
    altKey: 'ob.analytics.facebook.title',
    FIELD_PLACEHOLDER: '1234567891011121',
    imgSrc: facebookPixel,
    description1: 'ob.analytics.facebook.description.1',
    description2: 'ob.analytics.facebook.description.2',
    fieldName: 'facebookTrackerId',
    fieldLabel: 'ob.analytics.facebook.pixelId',
    fieldTooltip: 'ob.analytics.facebook.tooltip',
    href: FACEBOOK_PIXEL_URL,
    mobile1: 'ob.analytics.facebook.account.mobile.1',
    mobile2: 'ob.analytics.facebook.account.mobile.2',
    mobile3: 'ob.analytics.facebook.account.mobile.3',
    mobile4: 'ob.analytics.facebook.account.mobile.4',
  };

  return <Analytics {...facebookPixelProps} />;
}
