import { FORM_ERROR } from 'final-form';
import { mutate, mutation } from '../../../graphql';
import { uploadFileToS3 } from '../../../helpers/common';

export const uploadFiles = async (id: string, photos: any[]) => {
  try {
    const alreadyUploaded = photos.filter((e) => e._alreadyUploaded).map((e) => e.name);
    const notUploaded = photos.filter((e) => !e._alreadyUploaded);

    const result = notUploaded.length
      ? await mutate(mutation.UPLOAD_LOCATION_IMAGES)({ count: notUploaded?.length })
      : null;

    const configs = (result as any)?.data?.generateLocationUploads?.uploads;
    if (configs) {
      await Promise.all(configs.map((e: any, i: number) => uploadFileToS3(e, notUploaded?.[i]?.originFileObj)));
    }
    const resultImages = [...(alreadyUploaded || []), ...(configs?.map((e: any) => e.name) || [])];
    await mutate(mutation.UPDATE_LOCATION_IMAGES)({ id, photos: resultImages });

    await mutate(mutation.PUBLISH_LOCATION_BOOKING)({ id, publish: true });
    return {};
  } catch (e) {
    return { [FORM_ERROR]: 'Failed to load files.' };
  }
};

// 0: {name: "40bd30ba-2d0c-11eb-af8a-36e80f166e3b.jpg",…}
// 1: {name: "40bd96ea-2d0c-11eb-93df-36e80f166e3b.jpg",…}
export const convertData = async (data: any) => {
  const result = { ...data.location };

  const photos = [...(result.photos || [])];
  result.photos = [];

  for (const photo of photos) {
    const url = photo?.url;
    result.photos.push({
      ...photo,
      url,
      _alreadyUploaded: true,
      uid: photo?.name || new Date().getTime(),
    });
  }

  return result;
};
