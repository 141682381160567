import React from 'react';
import BirthdayCampaignForm from './component/BirthdayCampaignForm';
import { ModifyMassLayout, NotReleased } from 'components';
import withFormApi from 'hocs/form/withFormApi';
import { RoutePath } from 'constants/routes';

export const MassageFormWithApi = withFormApi({
  backPath: RoutePath.MARKETING,
  successMessage: 'Message',
})(BirthdayCampaignForm);

export default function BirthdayCampaignSetting() {
  return (
    <NotReleased>
      <ModifyMassLayout title="Campaign Setting" step="Campaign setup. Step 1 of 3">
        <MassageFormWithApi />
      </ModifyMassLayout>
    </NotReleased>
  );
}
