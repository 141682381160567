import { useContext } from 'react';
import { __RouterContext as RouterContext } from 'react-router';

export function useRouter() {
  return useContext(RouterContext);
}

export function useParams() {
  const { match } = useRouter();
  return match.params;
}

export function useQueryParams() {
  const { location } = useRouter();
  return new URLSearchParams(location?.search);
}

export function useHistory() {
  const { history } = useRouter();
  return history;
}

export function useLocation<T = any>() {
  const { location, history, match } = useRouter();

  function navigate(to: string, id?: string, { replace = false, backPath }: any = {}) {
    let nextPath = id ? `${to}/${id}` : to;

    if (backPath) {
      nextPath = `${nextPath}?backPath=${backPath}`;
    }
    if (replace) {
      history.replace(nextPath);
    } else {
      history.push(nextPath);
    }
  }

  function goBack() {
    history.goBack();
  }
  return {
    params: match.params as T,
    location,
    navigate,
    goBack,
  };
}
