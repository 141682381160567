import * as React from 'react';
import MenuContext from 'antd/lib/menu/MenuContext';
import { Tooltip } from 'antd';
// @ts-ignore
import { Item } from 'rc-menu';
import { TooltipProps } from 'antd/lib/tooltip';

/**
 * This component is copy of ant.design component
 * since ant.design does not provide api to override tooltip behavior
 */
export default class MenuItem extends React.Component<any> {
  static isMenuItem = true;

  private menuItem: any;

  onKeyDown = (e: React.MouseEvent<HTMLElement>) => {
    this.menuItem.onKeyDown(e);
  };

  saveMenuItem = (menuItem: this) => {
    this.menuItem = menuItem;
  };

  render() {
    const { level, children, rootPrefixCls } = this.props;
    const { title, siderCollapsed, ...rest } = this.props;

    return (
      <MenuContext.Consumer>
        {({ inlineCollapsed }: any) => {
          const tooltipProps: TooltipProps = {
            title: title || (level === 1 ? children : ''),
            mouseLeaveDelay: 0,
            mouseEnterDelay: 0,
          };

          if (!siderCollapsed && !inlineCollapsed) {
            tooltipProps.title = null;
            // Reset `visible` to fix control mode tooltip display not correct
            // ref: https://github.com/ant-design/ant-design/issues/16742
            tooltipProps.visible = false;
          }

          return (
            <Tooltip {...tooltipProps} placement="right" overlayClassName={`${rootPrefixCls}-inline-collapsed-tooltip`}>
              <Item {...rest} ref={this.saveMenuItem} />
            </Tooltip>
          );
        }}
      </MenuContext.Consumer>
    );
  }
}
