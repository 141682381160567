import React from 'react';
import { Button } from '@plandok/core';

type EditBtnProps = {
  editItem: () => void;
};

export default function EditBtn({ editItem }: EditBtnProps) {
  return <Button data-footer-minor-btn type="primary" label="btn.edit" onClick={editItem} smallRadius bold />;
}
