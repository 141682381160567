import { Row, Col } from 'antd';
import React from 'react';
import { AppLayout, NoInternetConnection } from 'components';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import SettingsMobileHeader from './components/SettingsMobileHeader';
import SettingCard from './components/SettingCard';
import { cardSettings } from './config';

export default function SettingsPage() {
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();

  return (
    <NoInternetConnection>
      <AppLayout
        mobileHeader={SettingsMobileHeader}
        headerTitle="sidebar.settings"
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <AppLayout.Container>
          <Row gutter={20}>
            <Col md={12} span={24}>
              <SettingCard {...cardSettings.ACCOUNT} account />
            </Col>
            <Col md={12} span={24}>
              <SettingCard {...cardSettings.CLIENT} />
              <SettingCard {...cardSettings.BOOKING} />
            </Col>
          </Row>
        </AppLayout.Container>
      </AppLayout>
    </NoInternetConnection>
  );
}
