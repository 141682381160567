import React from 'react';
import { Button, Mutation, Text, withModal } from '@plandok/core';
import * as SC from '../styles';
import * as api from 'api';

type ModalProps = {
  paymentMethod: string;
  fetchData: () => void;
};

type DeletePaymentMethodModalProps = {
  modalProps: ModalProps;
  onClose: () => void;
};

function DeletePaymentMethodModal({ modalProps, onClose }: DeletePaymentMethodModalProps) {
  const onSuccess = () => {
    onClose();
    modalProps.fetchData();
  };

  return (
    <SC.DeletePaymentMethodContainer>
      <Text weight="normal" label="modal.deletePaymentMethod.description" mb="xmedium" size="medium" />
      <Mutation action={api.deletePaymentMethod} id={modalProps.paymentMethod} onSuccess={onSuccess}>
        {({ loading, action }: any) => (
          <>
            <Button disabled={loading} label="btn.cancel" onClick={onClose} upperCase={false} whiteBtn semiBold />
            <Button
              loading={loading}
              label="modal.deleteReminder.confirm"
              type="primary"
              onClick={action}
              upperCase={false}
              semiBold
            />
          </>
        )}
      </Mutation>
    </SC.DeletePaymentMethodContainer>
  );
}

export default withModal('modal.deletePaymentMethod.title', { contentClassName: 'delete-payment-method-modal' })(
  DeletePaymentMethodModal
);
