import { IntlLabel, LabelKey } from '@plandok/i18n';
import { Tabs } from 'antd';
import React from 'react';
import * as SC from './styles';
import { Text } from '@plandok/core';

interface ReportsMobileTabs {
  tabs: {
    title: LabelKey;
    path: string;
  }[];
  className?: string;
  defaultTab?: string;
  children: any;
}

export const ReportsMobileTabs = ({ tabs, className, children }: ReportsMobileTabs) => (
  <SC.TabsWrapper className={className} defaultActiveKey="1" animated={false}>
    {tabs.map((tabConfig, i) => {
      const rend = children[i];
      return (
        <Tabs.TabPane
          tab={
            <Text colorType="inherit" size="base" weight="medium" mb="none">
              <IntlLabel label={tabConfig.title} />
            </Text>
          }
          key={tabConfig.path}
        >
          {rend || null}
        </Tabs.TabPane>
      );
    })}
  </SC.TabsWrapper>
);
