import styled, { css } from 'styled-components';
import { mediaMobileDown } from '@plandok/core';

export const PlaceholderContainer = styled.div`
  text-align: center;
  width: 100%;
  max-width: 1200px;
  margin: 20px 0 100px 0;
  display: flex;
  flex-direction: row-reverse;
  [data-booking-placeholder-mobile-title] {
    display: none;
  }

  ${mediaMobileDown(css`
    flex-direction: column;
    text-align: center;
    padding: 0 40px;
    [data-booking-placeholder-mobile-title] {
      display: block;
    }
    [data-placeholder-column-img] img {
      height: 100px;
      width: 170px;
      margin-bottom: 20px;
    }

    [data-placeholder-column-info] {
      div {
        text-align: center;
        font-size: 14px;
        line-height: 1.5;
      }
      h2 {
        display: none;
      }
    }
  `)};
  [data-placeholder-column] {
    width: 100%;
  }

  h2 {
    color: #283140;
    font-size: 50px;
    text-align: left;
  }
  p {
    color: #61749d;
    margin-bottom: 30px;
    text-align: left;
  }
`;

export const Container = styled.div`
  margin-top: 50px;

  ${mediaMobileDown(css`
    margin-left: auto;
    margin-right: auto;
  `)};
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  button {
    min-width: 180px;
  }
`;
