import styled, { css } from 'styled-components';
import { mediaMdDown, mediaMobileDown } from '@plandok/core';

type CardProps = {
  padding?: string;
};

export const Container = styled.div`
  min-height: 100vh;
  background: #f8f9fc;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const LocationUrl = styled.span`
  display: flex;
  flex-direction: column;
`;

export const BodyContent = styled.div<any>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${(p) => (p.isSmall ? '690px' : '1200px')};
  margin: 50px auto 60px auto;
  ${mediaMdDown(
    css`
      margin-top: 0;
      margin-bottom: 0;
    `
  )}
`;

export const Card = styled.div<CardProps>`
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 0.16);
  margin-bottom: 50px;
  padding: ${({ padding }) => (padding ? padding : '30px 30px 20px 30px')};
  width: 100%;

  ${mediaMdDown(
    css`
      background: #f8f9fc;
      box-shadow: none;
    `
  )}
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    min-width: 180px;
  }

  ${mediaMdDown(css`
    box-shadow: 0 3px 25px rgba(19, 49, 109, 0.16);
    button {
      min-width: 130px;
    }

    position: fixed;
    background: white;
    padding: 6px 30px;
    height: 54px;
    bottom: 0;
    left: 0;
    right: 0;
  `)}
`;

export const HeaderContainer = styled.div`
  height: 140px;
  background: white;
  position: relative;

  [data-booking-flow-close] {
    position: absolute;
    right: 50px;
    top: 50px;
  }

  [data-bf-progress] {
    margin-right: 50px;
  }

  [data-bf-top-title] {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 1050px;

    h2 {
      font-weight: bold;
      color: #13316d;
      margin-bottom: 20px;
    }
    [data-bf-top-next] {
      position: absolute;
      color: #13316d;
      right: 0;
    }
  }

  p[data-bf-top-description] {
    color: #13316d;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
  }

  ${mediaMobileDown(css`
    font-size: 16px;
    height: 100px;
    [data-bf-progress] {
      margin-right: 20px;
    }
    [data-bf-top-title] {
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      h2 {
        font-size: 16px;
        margin-bottom: 0;
      }
      [data-bf-top-next] {
        position: static;
      }
    }

    [data-bf-top-description] {
      display: none;
    }

    [data-booking-flow-close] {
      top: 10px;
      right: 10px;
    }
  `)}
`;

export const HeaderContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 20px 0;
  height: inherit;
  ${mediaMobileDown(css`
    padding: 10px 0 10px 30px;
  `)}
`;
export const FormAlertContainer = styled.div`
  margin-bottom: 1rem;
`;
