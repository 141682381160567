export enum PermissionLevel {
  BASIC = 'basic',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  OWNER = 'owner',
}

export enum PermissionType {
  ACCESS_ALL_LOCATIONS = 'access_all_locations',
  ACCESS_OWN_CALENDAR = 'access_own_calendar',
  ACCESS_OTHER_CALENDARS = 'access_other_employee_calendars',
  CAN_BOOK_APPOINTMENTS = 'can_book_appointments',
  CAN_DRAG_APPOINTMENTS = 'can_drag_appointments',
  CAN_SEE_CLIENT_INFO = 'can_see_clients_contact_info',
  CLIENTS = 'clients',
  MESSAGES = 'messages',
  SERVICES = 'services',
  EMPLOYEES = 'employees',
  WORKING_HOURS = 'working_hours',
  CLOSED_DATES = 'closed_dates',
  PERMISSION_LEVELS = 'permission_levels',
  ACCOUNT_SETTINGS = 'account_settings',
  CLIENT_SETTINGS = 'client_settings',
  ONLINE_BOOKINGS = 'online_bookings',
  ANALYTICS = 'analytics',
  MARKETING = 'marketing',
}

export const NOT_RELEASED_TOKEN = '4cy2aev9p8K5Xl1E';
