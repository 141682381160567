export {
  App_AppointmentChangeTimeSetting as AppAppointmentChangeTimeSetting,
  App_EarliestAppointmentSetting as AppEarliestAppointmentSetting,
  App_LatestAppointmentSetting as AppLatestAppointmentSetting,
  App_TimeSlotIntervalSetting as AppTimeSlotIntervalSetting,
  App_LocationOnlineStatus as AppLocationOnlineStatus,
} from './generatedTypes';

enum AppRepeatFrequencyIntervals {
  DOESNT_REPEAT = 'DOESNT_REPEAT',
  DAILY = 'DAILY',
  TWO_DAYS = 'TWO_DAYS',
  THREE_DAYS = 'THREE_DAYS',
  FOUR_DAYS = 'FOUR_DAYS',
  FIVE_DAYS = 'FIVE_DAYS',
  SIX_DAYS = 'SIX_DAYS',
  WEEK = 'WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  THREE_WEEKS = 'THREE_WEEKS',
  FOUR_WEEKS = 'FOUR_WEEKS',
  FIVE_WEEKS = 'FIVE_WEEKS',
  SIX_WEEKS = 'SIX_WEEKS',
  SEVEN_WEEKS = 'SEVEN_WEEKS',
  EIGHT_WEEKS = 'EIGHT_WEEKS',
  NINE_WEEKS = 'NINE_WEEKS',
  TEN_WEEKS = 'TEN_WEEKS',
  MONTH = 'MONTH',
  TWO_MONTHS = 'TWO_MONTHS',
  THREE_MONTHS = 'THREE_MONTHS',
  FOUR_MONTHS = 'FOUR_MONTHS',
  FIVE_MONTHS = 'FIVE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  SEVEN_MONTHS = 'SEVEN_MONTHS',
  EIGHT_MONTHS = 'EIGHT_MONTHS',
  NINE_MONTHS = 'NINE_MONTHS',
  TEN_MONTHS = 'TEN_MONTHS',
  ELEVEN_MONTHS = 'ELEVEN_MONTHS',
}

enum AppRepeatEndsAfterIntervals {
  AFTER_2_TIMES = '2',
  AFTER_3_TIMES = '3',
  AFTER_4_TIMES = '4',
  AFTER_5_TIMES = '5',
  AFTER_6_TIMES = '6',
  AFTER_7_TIMES = '7',
  AFTER_8_TIMES = '8',
  AFTER_9_TIMES = '9',
  AFTER_10_TIMES = '10',
  AFTER_11_TIMES = '11',
  AFTER_12_TIMES = '12',
  AFTER_13_TIMES = '13',
  AFTER_14_TIMES = '14',
  AFTER_15_TIMES = '15',
  AFTER_20_TIMES = '20',
  AFTER_25_TIMES = '25',
  AFTER_30_TIMES = '30',
  UNTIL_DATE = 'UNTIL_DATE',
}

export { AppRepeatFrequencyIntervals, AppRepeatEndsAfterIntervals };
