export const passwordTooltip: any = [
  { key: 'tooltip.password.title', style: { fontWeight: 'bold' } },
  {
    key: 'tooltip.password.characters',
  },
  {
    key: 'tooltip.password.number',
  },
  {
    key: 'tooltip.password.letter',
  },
];
