import React from 'react';
import { Field } from '@plandok/core';
import withModifyModal from '../withModifyModal';
import * as api from 'api';

function CreateResourceModal() {
  return (
    <>
      <Field.Input name="name" label="input.resourceName.label" placeholder="input.resourceName.placeholder" />
      <Field.TextArea
        name="description"
        label="input.description.label"
        placeholder="input.description.placeholder"
        row="4"
      />
      <Field.AsyncSelect
        name="locationId"
        label="input.location.label"
        placeholder="input.location.placeholder"
        tooltip="tooltip.location"
        asyncType="location"
        onInitialLoad={(onChange: any, options: any[], value: any) => {
          if (!value) {
            onChange(options[0]?.code);
          }
        }}
      />
    </>
  );
}

export default withModifyModal({
  label: 'modal.newResource.title',
  editLabel: 'modal.editResource.title',
  entityName: 'resource',
  entityNameField: 'name',
  deleteAction: api.deleteResource,
  detailsAction: api.fetchResourceDetails,
  createAction: api.createResource,
  editAction: api.editResource,
})(CreateResourceModal);
