import { Button, CustomIcon, Text, useModal } from '@plandok/core';
import React from 'react';
import * as SC from './styles';

type MergeDuplicateClientsProps = {
  checkAction: () => void;
};

export default function MergeDuplicateClients({ checkAction }: MergeDuplicateClientsProps) {
  const [, { showModal }] = useModal();

  return (
    <SC.Container>
      <CustomIcon type="duplicateContacts" />
      <Text weight="semiBold" mb="none" label="clients.duplicate.title" />
      <Text colorType="base" weight="normal" mb="none" label="clients.duplicate.description" />
      <Button
        style={{ marginLeft: '1rem', width: '96px', height: '36px', boxShadow: '0px 2px 4px #E2D5BD' }}
        label="clients.merge.btn.title"
        whiteBtn
        onClick={() => showModal({ type: 'MERGE_DUPLICATED_CLIENTS', modalProps: { checkAction } })}
      />
    </SC.Container>
  );
}
