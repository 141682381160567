import React from 'react';
import { withModal, Button, Text } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { RoutePath } from 'constants/routes';
import { useLocation } from 'hooks/router';
import styled from 'styled-components';
import * as SC from '../styles';

const StyledButton = styled(Button)`
  box-shadow: none;
`;

function UpgradeToPremiumModal(props: any) {
  const { navigate } = useLocation();

  const goToPricingPage = () => {
    props.modalProps.onExit();
    props.modalProps.onCloseRepeatModal();
    navigate(RoutePath.BILLING);
  };

  return (
    <SC.UpgradeContainer>
      <Text colorType="base" weight="semiBold" style={{ fontSize: '28px' }} mb="large">
        <IntlLabel label="rp.upgrade.title" />
      </Text>
      <Text colorType="base" weight="normal" size="base" mb="large" isCenter>
        <IntlLabel label="rp.upgrade.description.title" />
      </Text>
      <Button type="primary" style={{ background: '#349447', borderRadius: '5px' }} onClick={goToPricingPage}>
        <Text colorType="white" weight="semiBold" size="base" mb="none" upperCase>
          <IntlLabel label="rp.upgrade.button.title" />
        </Text>
      </Button>
      <StyledButton type="ghost" upperCase={false} onClick={props.modalProps.onExit}>
        <Text colorType="base" weight="semiBold" size="base" mb="none">
          <IntlLabel label="btn.close" />
        </Text>
      </StyledButton>
    </SC.UpgradeContainer>
  );
}

export default withModal(null, { contentClassName: 'upgrade-to-premium-modal' })(UpgradeToPremiumModal);
