import React, { memo } from 'react';
import cn from 'classnames';
import { CalendarItem } from 'pages/dashboard/CalendarPage/types';
import { getHeight, getHourCellCount, getTopByViewType } from '../../support/helpers';
import { ICalendarActions } from '../../EpicCalendar';
import TimeCardList from '../TimeCard/TimeCardList';
import ColumnTimeBlocks from './ColumnTimeBlocks';
import { ModeType } from '../../support/types';
import ColumnHeader from './ColumnHeader';
import * as SC from '../../styles';

interface ICalendarList {
  timeInterval: number;
  data: CalendarItem[];
  hourHeight: number;
  columnWidth: number;
  getContainerRef(): any;
  actions: ICalendarActions;
  parentFrameId?: string;
  headerComponent?: any;
  tooltipComponent: any;
  timeCardComponent?: any;
  isSelectSlotMode?: boolean;
  modeType: ModeType;
  customViewType: string;
  customViewTime: { startTime: number; endTime: number };
  workingHoursStartTime: number;
  workingHoursEndTime: number;
  timeFormat: string;
}

export default memo(function CalendarList({ actions, ...props }: ICalendarList) {
  const hourCellCount = getHourCellCount(props.timeInterval);
  const columnsCount = props.data?.length;

  return (
    <>
      {(props.data || []).map((item: CalendarItem, columnIndex: number) => {
        const onTimeSlotClick = (time: number) => actions.onTimeSlotClick(time, item.info);

        return (
          <SC.CalendarColumn
            key={columnIndex}
            id={`calendar-column-${columnIndex}`}
            className={cn('calendar-column', { 'calendar-column__select-slot-mode': props.isSelectSlotMode })}
          >
            <TimeCardList
              bookings={item.bookings}
              hourHeight={props.hourHeight}
              columnWidth={props.columnWidth}
              getContainerRef={props.getContainerRef}
              columnIndex={columnIndex}
              columnsCount={columnsCount}
              onMove={actions.onMove}
              onResize={actions.onResize}
              onCardClick={actions.onCardClick}
              parentFrameId={props.parentFrameId}
              tooltipComponent={props.tooltipComponent}
              timeCardComponent={props.timeCardComponent}
              isSelectSlotMode={props.isSelectSlotMode}
              customViewTime={props.customViewTime}
              customViewType={props.customViewType}
              workingHoursStartTime={props.workingHoursStartTime}
              timeFormat={props.timeFormat}
            />
            <ColumnHeader headerComponent={props.headerComponent} info={item.info} modeType={props.modeType} />
            {item.workingHours?.map((hourItem) => {
              const top = getTopByViewType(
                props.customViewTime.startTime,
                props.customViewType,
                hourItem.startTime,
                props.hourHeight,
                props.workingHoursStartTime
              );

              const height = getHeight(
                props.customViewType,
                hourItem.endTime,
                hourItem.startTime,
                props.customViewTime.endTime,
                props.hourHeight
              );

              return <SC.WorkingTime top={top} height={height} key={hourItem.startTime} />;
            })}
            <ColumnTimeBlocks
              hourCellCount={hourCellCount}
              timeInterval={props.timeInterval}
              onTimeSlotClick={onTimeSlotClick}
              customViewType={props.customViewType}
              customViewTime={props.customViewTime}
              workingHoursStartTime={props.workingHoursStartTime}
              workingHoursEndTime={props.workingHoursEndTime}
              timeFormat={props.timeFormat}
            />
          </SC.CalendarColumn>
        );
      })}
    </>
  );
});
