import { Pie } from '@ant-design/charts';
import { getIsMobile, Text } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import React from 'react';
import { useEffect, useState } from 'react';
import { App_ClientsAnalytics } from '../../../../../../graphql/generatedTypes';
import { NoData } from '../NoData/NoData';
import * as SC from './styles';
import { ChartsData, CustomTooltip } from './types';

type GenderPieChartProps = {
  data: App_ClientsAnalytics;
};

export const GenderPieChart = ({ data }: GenderPieChartProps) => {
  const [mappedGenderData, setMappedGenderData] = useState<ChartsData[]>([]);

  const dataLength = data?.byGender?.length;
  const colors = ['#61749D', '#899CC5', '#D0DAF1'];

  useEffect(() => {
    const genderDataByGender: ChartsData[] = [];
    data?.byGender?.map((item: any) => {
      if (item.gender) {
        const genderType = item.gender.toLowerCase().charAt(0).toUpperCase() + item.gender.toLowerCase().slice(1) || '';
        return genderDataByGender.push({
          type: genderType,
          value: item.percent || 0,
        });
      }
      return [];
    });
    setMappedGenderData(genderDataByGender);
  }, [data]);

  const tooltipContent = (title: string, data: any[]): JSX.Element => (
    <SC.Tooltip>
      {title}
      <div>
        ({data[0]?.value}% <IntlLabel label="analytics.clients.title" />)
      </div>
    </SC.Tooltip>
  );

  const genderPieChartConfig = {
    data: mappedGenderData,
    angleField: 'value',
    colorField: 'type',
    color: colors,
    radius: 0.9,
    legend: {},
    label: {
      type: 'inner',
      offset: '-50%',
      content: (_ref: any) => `${(_ref.percent.toFixed(2) * 100).toFixed().toString()}%`,
      style: {
        fontSize: 16,
        fontWeight: 500,
        textAlign: 'center',
      },
    },
    tooltip: {
      customContent: (title: string, data: any[]) => tooltipContent(title, data),
    } as CustomTooltip,
    interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
  };

  return (
    <SC.ColumnGraph>
      <Text size="medium" weight={getIsMobile() ? 'semiBold' : 'normal'} colorType="base" mb="none">
        <IntlLabel label="analytics.clientsByGender.title" />
      </Text>
      {!!dataLength && (
        <SC.PieChart>
          <Pie {...genderPieChartConfig} />
          <SC.LegendContainer>
            {mappedGenderData?.map((item, index) => (
              <div key={item.type} className="align-center">
                <SC.LegendDot color={colors[index]} />
                <SC.LegendValue>{item.type}</SC.LegendValue>
              </div>
            ))}
          </SC.LegendContainer>
        </SC.PieChart>
      )}
      {!dataLength && <NoData />}
    </SC.ColumnGraph>
  );
};

export default GenderPieChart;
