import React from 'react';
import { composeValidators, validatePassword, validateRequired, Field, Form, Button } from '@plandok/core';
import { passwordTooltip } from 'constants/form/general';
import * as api from 'api';
import { useOnLogin } from '../RegistrationPage/useOnLogin';

export default function SetNewPasswordForm(props: { initialValues: any }) {
  const onRegisterSuccess = useOnLogin('message.successNew.password');

  const onSetNewPassword = async (form: any) =>
    await api.setNewPassword(form).then((res) => {
      if (res?.errors) {
        return res;
      }
      return api.login({ email: form.email, password: form.password });
    });

  return (
    <Form
      onSubmit={onSetNewPassword}
      initialValues={props.initialValues}
      excludeList={['repeatPassword']}
      onSuccess={onRegisterSuccess}
      validate={(values: any) => {
        if (values.password !== values.repeatPassword) {
          return { repeatPassword: 'validation.password.match' };
        }
      }}
    >
      {({ submitting, formError }: any) => (
        <>
          <Field.Input
            name="password"
            label="input.newPassword.label"
            placeholder="input.password.placeholder"
            submitting={submitting}
            tooltip={passwordTooltip}
            validate={composeValidators(validateRequired, validatePassword)}
            type="password"
          />
          <Field.Input
            name="repeatPassword"
            label="input.newPasswordRepeat.label"
            placeholder="input.password.label"
            validate={validateRequired}
            submitting={submitting}
            type="password"
          />
          <Form.Alert text={formError} />
          <Button type="primary" block htmlType="submit" loading={submitting} label="btn.change.password" />
        </>
      )}
    </Form>
  );
}
