import React, { useState, useEffect, ReactNode, memo } from 'react';
import { message } from 'antd';
import { useLocation } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import useCheckUpdateApp from 'hooks/use-check-update-app';
import { replaceHistoryState } from '../store/calendar-state.helpers';
import UpdateNotification from '../components/UpdateNotification';
import A2HSNotification from '../components/A2HSNotification';
import BtnShowAllTimes from './BtnShowAllTimes';
import BtnUndo from '../components/BtnUndo';

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

type A2HSProviderProps = {
  isVisibleUpdateNotification: boolean;
  setIsVisibleUpdateNotification: (value: boolean) => void;
  setIsVisibleUpdateBtn: (value: boolean) => void;
  setCustomViewType: (viewType: string) => void;
  isDisplayBtnShowAllTimes: boolean;
  setIsDisplayBtnShowAllTimes: (value: boolean) => void;
  children: ReactNode;
};

export default memo(function A2HSProvider({
  children,
  isVisibleUpdateNotification,
  setIsVisibleUpdateNotification,
  setIsVisibleUpdateBtn,
  isDisplayBtnShowAllTimes,
  setCustomViewType,
  setIsDisplayBtnShowAllTimes,
}: A2HSProviderProps) {
  const [deferredPrompt, setDeferredPrompt] = useState<IBeforeInstallPromptEvent | null>(null);
  const [isVisibleUndo, setIsVisibleUndo] = useState(false);
  const [isVisibleA2HS, setIsVisibleA2HS] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { isUpdated, isHiddenNewVersionNotification } = useCheckUpdateApp();
  const { location } = useLocation();

  const isOpenedFromApp = document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;

  const onCancel = () => setIsVisibleA2HS(false);

  const onNotNow = () => {
    setIsVisibleUpdateNotification(false);
    setIsVisibleUpdateBtn(true);
    localStorage.setItem('isHiddenNewVersionNotification1', 'true');
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsVisibleA2HS(true);
    });

    const deleteAppointment = () => {
      setIsVisibleUndo(false);
      replaceHistoryState();
    };

    if (location.state?.id) {
      setIsVisibleUndo(true);
      setTimeout(deleteAppointment, 10000);
    }
  }, [location]);

  const onOk = () => {
    deferredPrompt?.prompt();
    deferredPrompt?.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        setIsSuccess(true);
        onCancel();
      }
    });
  };

  const onUpdate = () => {
    if ('caches' in window && !isUpdated) {
      caches.keys().then((names) => names.forEach((name) => caches.delete(name)));
      localStorage.setItem('isHiddenNewVersionNotification1', 'true');
      localStorage.setItem('isUpdated1', 'true');
      setIsVisibleUpdateNotification(false);
      setIsVisibleUpdateBtn(false);
      window.location.reload();
    }
  };

  return (
    <>
      {isSuccess && message.success(<IntlLabel label="app.install.success.title" />)}
      {isVisibleA2HS && !isOpenedFromApp && <A2HSNotification onOk={onOk} onCancel={onCancel} />}
      {isVisibleUpdateNotification && !isUpdated && !isHiddenNewVersionNotification && (
        <UpdateNotification onOk={onUpdate} onCancel={onNotNow} />
      )}
      <BtnUndo isVisibleUndo={isVisibleUndo} setIsVisibleUndo={setIsVisibleUndo} deletedAppointment={location.state} />
      {isDisplayBtnShowAllTimes && (
        <BtnShowAllTimes
          setCustomViewType={setCustomViewType}
          setIsDisplayBtnShowAllTimes={setIsDisplayBtnShowAllTimes}
        />
      )}
      {children}
    </>
  );
});
