import { ApolloError } from '@apollo/client';
import { Result } from 'antd';
import React from 'react';

export const AnalyticsErrorState = (errors: ApolloError) => (
  <>
    {!!errors &&
      errors?.graphQLErrors.map(({ message }, index) => <Result key={index} status="error" title={message} />)}
  </>
);
