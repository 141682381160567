import { useAppContext } from '@plandok/core';
import { message } from 'antd';
import * as api from 'api';

export function useGetProfile() {
  const [, { setDisplayName }] = useAppContext();

  const onGetProfile = async () => {
    try {
      const data = await api.fetchProfileDetails();
      setDisplayName(`${data?.firstName ?? ''} ${data?.lastName ?? ''}`);
    } catch (error) {
      message.error(`Error: ${error}.Failed to get data, please contact support.`);
      return;
    }
  };

  return onGetProfile;
}

export function useProfile() {
  const [state] = useAppContext();
  return state?.displayName ?? '';
}
