import React from 'react';

import { Tabs } from 'antd';
import { IntlLabel, LabelKey } from '@plandok/i18n';
import { CustomIconsPack, getIsMobile } from '@plandok/core';
import useGetHasPermission from 'hooks/permission/useGetHasPermission';
import { useLocation } from 'hooks/router';

interface HashTabsProps {
  tabs: {
    icon?: Extract<keyof typeof CustomIconsPack, string>;
    title: LabelKey;
    path: string;
    permission?: string;
    description?: string;
  }[];
  className?: string;
  defaultTab?: string;
  isChildrenObserver?: boolean;
  rootPath?: string;
  children: any;
}

export default function HashTabs({
  tabs,
  className,
  defaultTab,
  rootPath,
  isChildrenObserver,
  children,
}: HashTabsProps) {
  const { navigate, location } = useLocation();
  const isMobile = getIsMobile();
  const getHasPermission = useGetHasPermission();
  const resultTabs = tabs.filter(({ permission }) => getHasPermission(permission));
  const handleTabClick = (key: string) => navigate(rootPath ? `${rootPath}#${key}` : `#${key}`);
  const activeKey = (location.hash || '').replace('#', '') || defaultTab;

  const getActiveTab = () => {
    const activeIndex = resultTabs.findIndex((tabConfig) => tabConfig.path === activeKey);
    return children[activeIndex].props.children || children[activeIndex];
  };

  return isMobile && isChildrenObserver ? (
    getActiveTab()
  ) : (
    <Tabs
      className={className}
      defaultActiveKey={activeKey}
      activeKey={activeKey}
      onChange={handleTabClick}
      animated={false}
    >
      {resultTabs.map((tabConfig, i) => {
        const renderTabPane = children[i];

        return (
          <Tabs.TabPane tab={<IntlLabel label={tabConfig.title} />} key={tabConfig.path}>
            {renderTabPane || null}
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
}
