export enum RoutePath {
  LOGIN = '/login',
  LOGOUT = '/logout',
  RESET_PASSWORD = '/reset-pass',
  RESET_PASSWORD_CONFIRMATION = '/reset-password',
  CREATE_PASSWORD = '/create-password',
  REGISTRATION = '/registration',

  CALENDAR = '/calendar',
  CALENDAR_SETTINGS = '/calendar/settings',
  CLIENTS = '/clients',
  CLIENT_DETAILS = '/client/details',
  APPOINTMENT_CREATE = '/appointment/create',
  APPOINTMENT_UPDATE = '/appointment/update',
  APPOINTMENT_DETAILS = '/appointment/details',
  CLIENT_CREATE = '/client/create',
  CLIENT_EDIT = '/client/edit',
  COMPANY_DETAILS = '/company/details',
  LOCATIONS = '/setup/locations',
  RESOURCES = '/setup/resources',
  DISCOUNTS = '/setup/discounts',
  REFERRAL_SOURCES = '/setup/referral-sources',
  CANCEL_REASONS = '/setup/cancel-reasons',
  SERVICES = '/services',
  SERVICE_CREATE = '/service/create',
  SERVICE_EDIT = '/service/edit',
  SETTINGS = '/settings',
  SETUP_NOTIFICATIONS = '/notifications',
  STAFF = '/staff',
  STAFF_SELECT = '/staff/select',
  STAFF_CREATE = '/staff/create',
  STAFF_UPDATE = '/staff/update',
  CLIENT_MESSAGES = '/notifications/messages',
  PERSONAL_SETTINGS = '/personal/settings',
  SEARCH = '/search',
  MORE = '/more',
  BILLING = '/billing',
  BILLING_SELECT = '/billing/select',
  BOOKING = '/online-bookings',
  BOOKING_MOBILE = '/online-bookings/select',
  ANALYTICS = '/analytics',
  ANALYTICS_MOBILE = '/analytics/select',
  MESSAGES_MOBILE = '/messages/select',
  BOOKING_CREATE = '/booking-create',
  GOOGLE_ANALYTICS = '/google-analytics',
  FACEBOOK_PIXEL = '/facebook-pixel',
  MARKETING = '/marketing',
  MARKETING_MOBILE = '/marketing',
  HELP_MOBILE = '/help',
  MESSAGE_CREATE = '/marketing/massmessage/message-create',
  CLIENT_SELECTION = '/marketing/massmessage/cleint-selection',
  SCHEDULE_SEND = '/marketing/massmessage/schedule-send',
  SCHEDULE_SEND_CUSTOM_TIME = '/marketing/massmessage/schedule-send/custom-time',
  PAYMENT_DETILES = '/marketing/massmessage/payment-detiles',
  EMAIL_MESSAGE_CREATE = '/marketing/massmessage/email-message-create',

  BIRTHDAY_CAMPAIGN_SETTING = '/campaign/birthday-campaign',
  WIN_BACK_CLIENTS_SETTING = '/campaign/win-back-clients',
  WELCOME_NEW_CLIENTS_SETTING = '/campaign/welcome-new-clients',
  REWARD_REGULAR_CLIENTS_SETTING = '/campaign/reward-regular-clients',

  REPORTS_APPOINTMENT_LIST = '/reports/appointment-list',
  REPORTS_APPOINTMENT_SUMMARY = '/reports/appointments-summary',
  REPORTS_APPOINTMENT_CANCELLATIONS = '/reports/appointments-cancellations',
  REPORTS_CLIENTS = '/reports/clients',

  CAMPAIGN_SETUP = '/campaign/campaign-setup',
  START_CAMPAIGN_PAGE = '/campaign/start-campaign',

  UPDATE_SUBSCRIPTION = '/payments/update-subscriptions',
  NOTIFICATION_PAYMENT = '/payments/notification-payments',
  PAYMENT_CONFIRMATION = '/payment/confirmation',
  NEW_PRICING = '/new-pricing',
  PLANS = '/plans',
}

export enum SetupTabHash {
  REMINDERS = 'reminders',
  CONFIRMATIONS = 'confirmations',
  CANCELLATIONS = 'cancelations',
  RESCHEDULE = 'reschedule',
  THANK_YOU = 'thank-you',
  LAST_VISIT = 'last-visit',
}

export enum StaffTabHash {
  WORKING_HOURS = 'hours',
  CLOSED_DATES = 'dates',
  STAFF_MEMBERS = 'members',
  USER_PERMISSIONS = 'permissions',
}

export enum OnlineBookingHash {
  OVERVIEW = 'overview',
  PROFILE = 'profile',
  LINKS = 'links',
  ANALYTICS_TRACKING = 'analytics-tracking',
  SETTINGS = 'settings',
  PAYMENTS = 'payments',
}

export enum AnalyticsHash {
  DASHBOARD = 'dashboard',
  REPORTS = 'reports',
}

export enum AppointmentsSummaryHash {
  STAFF = 'staff',
  SERVICE = 'service',
}

export enum AppointmentCancellationsHash {
  SUMMARY = 'summary',
  CANCELLATIONS = 'cancellations',
}

export enum BillingTabHash {
  PLAN = 'plan',
  COSTS = 'costs',
  INFO = 'info',
  INVOICES = 'invoices',
  PAYMENT_METHOD = 'payment-method',
}

export enum MarketingHash {
  OVERVIEW = 'overview',
  MASSMESSAGES = 'massMassages',
  CAMPAIGNS = 'campaigns',
}

export enum PaymentTabHash {
  DETAILS = 'details',
  INFORMATION = 'information',
}
