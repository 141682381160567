import React from 'react';
import Notification from '../Notification';

export type A2HSNotificationProps = {
  onOk: () => void;
  onCancel: () => void;
};

export default function A2HSNotification({ onOk, onCancel }: A2HSNotificationProps) {
  const a2HSNotificationProps = {
    title: 'app.install.title',
    description: 'app.install.description.title',
    onOkBtnTitle: 'app.install.button.title',
    onCancelBtnTitle: 'app.skip.button.title',
    onOk,
    onCancel,
    zIndex: 5,
  };

  return <Notification {...a2HSNotificationProps} />;
}
