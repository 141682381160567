import { format, startOfWeek, addDays, isSameDay, isWithinInterval } from 'date-fns';
import { parseDateField } from 'helpers/date/field';
import isEmpty from 'lodash/isEmpty';
import { CalendarItem } from '../types';

const addMissingFields = (data: any, isBlocked?: boolean) => {
  if (isBlocked) {
    return (
      data?.map((b: {} & any) => ({
        ...b,
        duration: b.endTime - b.startTime,
        isBlocked: true,
      })) || []
    );
  }

  return (
    data?.map((b: {} & any) => ({
      ...b,
      endTime: b.startTime + b.duration,
    })) || []
  );
};

const findCurrentDateBookings = (bookings: any[], date: Date) =>
  bookings?.filter((e: any) => {
    const bookingDate = parseDateField(e.date || e.appointmentDate);

    return bookingDate && isSameDay(bookingDate, date);
  }) || [];

const hasClosedDates = (dates: any, check: string) => {
  if (!dates?.length) {
    return false;
  }

  return dates.some(({ start_date, end_date }: any) =>
    isWithinInterval(new Date(check), { start: new Date(start_date), end: new Date(end_date) })
  );
};

export const getWeekCalendarWorkingHours = (staffId: any, date: any, scheduledRanges: any, closedDates: any) =>
  scheduledRanges?.find(
    (e: any) =>
      !hasClosedDates(closedDates, e.date) &&
      e.employeeId === staffId &&
      parseDateField(e.date)?.getDay?.() === date?.getDay?.()
  )?.shifts || [];

export const extractCalendarData = (viewType: any, date: Date, staffId: any, data: any): CalendarItem[] => {
  if (!data || isEmpty(data)) {
    return [];
  }

  if (viewType === 'week') {
    const startWeek = startOfWeek(date, { weekStartsOn: 1 });
    return [...Array(7)].map((_: any, i) => {
      const currentDate = addDays(startWeek, i);

      const bookings = addMissingFields(findCurrentDateBookings(data.bookings, currentDate));
      const blockedTimes = addMissingFields(findCurrentDateBookings(data.blockedTimes, currentDate), true);

      return {
        info: currentDate,
        bookings: [...(bookings || []), ...(blockedTimes || [])].filter((e) => e.employeeId === staffId),
        workingHours: getWeekCalendarWorkingHours(staffId, currentDate, data?.scheduledRanges, data?.closedDates),
      } as any;
    });
  }

  return (data.employees || []).map((e: any) => {
    const bookings = addMissingFields((data.bookings || []).filter((el: any) => el.employeeId === e.id));

    const blockedTimes = addMissingFields(
      (data.blockedTimes || []).filter((el: any) => el.employeeId === e.id),
      true
    );

    return {
      info: {
        id: e.id,
        displayName: `${e.firstName || ''} ${e.lastName || ''}`,
        photo: e.photo,
      },
      bookings: [...(bookings || []), ...(blockedTimes || [])],
      workingHours:
        data.scheduledRanges?.find((el: any) => !hasClosedDates(data?.closedDates, el.date) && el.employeeId === e.id)
          ?.shifts || [],
    };
  });
};

// https://gitlab.com/plandok/plandok-app/-/issues/517
export const getCalendarFilterParams = (state: any, employees: any[]): any => {
  const selectFirstEmployee = employees?.length === 1;
  const firstEmployeeId = employees?.[0]?.id;

  const result: any = {
    location_id: state.location,
    view_range: state.viewType,
    date: format(state.viewType === 'week' ? startOfWeek(state.date, { weekStartsOn: 1 }) : state.date, 'yyyy-MM-dd'),
  };

  if (state.viewType === 'week') {
    result.view_mode = 'specific_employee';
    if (selectFirstEmployee) {
      result.view_mode = 'specific_employee';
      result.employee_id = firstEmployeeId;
    } else if (['all_employees', 'working_employees'].includes(state.staff as any)) {
      result.employee_id = firstEmployeeId;
    } else {
      result.employee_id = state.staff || firstEmployeeId;
    }
  } else {
    if (selectFirstEmployee) {
      result.view_mode = 'specific_employee';
      result.employee_id = firstEmployeeId;
    } else if (['all_employees', 'working_employees'].includes(state.staff as any)) {
      result.view_mode = state.staff;
    } else {
      result.view_mode = 'specific_employee';
      result.employee_id = state.staff;
    }
  }

  return result;
};

export const replaceHistoryState = (stateObj = {}, title = '', url = '') =>
  window.history.replaceState(stateObj, title, url);
