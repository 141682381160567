import React from 'react';
import { useLocation, Button, Text, getIsMobile, withModal } from '@plandok/core';
import { IntlTemplate } from '@plandok/i18n';
import { RoutePath } from 'constants/routes';
import * as SC from '../styles';

type PaymentErrorModalProps = {
  modalProps: {
    planType: string;
    planId: string;
    warningDate: string;
    type: string;
  };
  hideAllModals: () => void;
  onClose: () => void;
};

function PaymentErrorModal({ modalProps, hideAllModals, onClose }: PaymentErrorModalProps) {
  const { navigate } = useLocation();
  const isMobile = getIsMobile();

  const dismiss = () => {
    sessionStorage.setItem('isPaymentErrorModalDismiss', 'true');
    onClose();
  };

  const goToPlanCreate = () => {
    hideAllModals();
    navigate(`${RoutePath.UPDATE_SUBSCRIPTION}/${modalProps.planType}/${modalProps.planId}`);
  };

  return (
    <>
      <Text size="base" mb={isMobile ? 'xmedium' : 'large'} lh="xlarge">
        <IntlTemplate
          label={
            modalProps.type === 'subscription_payment_failed'
              ? 'subscriptionPaymentFailed.title'
              : 'notificationPaymentFailed.title'
          }
          params={[modalProps.warningDate]}
        />
      </Text>

      <SC.BtnsWrapper>
        <SC.BtnDismiss label="payment.dismiss.title" onClick={dismiss} upperCase={false} whiteBtn semiBold />
        <Button label="payment.payNow.title" onClick={goToPlanCreate} upperCase={false} lightBtn semiBold />
      </SC.BtnsWrapper>
    </>
  );
}

export default withModal('payment.error.title', { icon: 'clear', contentClassName: 'payment-error-modal' })(
  PaymentErrorModal
);
