import { mediaSmDown } from '@plandok/core';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #fef7ea;
  border: 1px solid #ee9500;
  border-radius: 10px;
  height: 3.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.625rem;

  & > div:nth-child(2) {
    color: #ee9500;
  }

  & > div:nth-child(n + 2) {
    margin-left: 10px;
    font-size: 16px;
    line-height: 19px;
  }

  ${mediaSmDown(css`
    height: auto;
    display: grid;
    margin-top: 5rem;
    grid-template-columns: 2rem 1fr 7rem;
    padding: 1rem;

    & > div:nth-child(2) {
      margin-bottom: 6px;
    }

    & > div:nth-child(n + 2) {
      text-align: start;
      font-size: 14px;
      line-height: 16px;
    }

    & > div:nth-child(3) {
      grid-column: 2/3;
    }

    button {
      grid-row: 1/3;
      grid-column: 3/4;
    }
  `)}
`;
