import { message } from 'antd';
import * as api from 'api';
import { action, observable } from 'mobx';

export class StaffDatesState {
  @observable locations: any[] = [];
  @observable initialized = false;
  @observable isLoading = false;

  @action
  initialFetchData = async () => {
    if (!this.initialized) {
      try {
        this.isLoading = true;
        const locations = (await api.fetchLocations())?.locations || [];
        this.locations = locations;
        this.isLoading = false;
        this.initialized = true;
      } catch (error) {
        message.error(`Error: ${error}.Failed to get data, please contact support.`);
        return;
      }
    } else {
      try {
        const locations = (await api.fetchLocations())?.locations || [];
        this.locations = locations;
      } catch (error) {
        message.error(`Error: ${error}.Failed to get data, please contact support.`);
        return Promise.reject('Something went wrong, please try again or contact support.');
      }
    }
  };
}

export default new StaffDatesState();
