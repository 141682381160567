import React from 'react';
import { IntlLabel } from '@plandok/i18n';
import { MobileBackButton } from 'components';

export default function MessagesMobileHeader() {
  return (
    <>
      <MobileBackButton />
      <IntlLabel label="Auto-sending Campaigns" />
      <div />
    </>
  );
}
