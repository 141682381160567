import styled, { css } from 'styled-components';
import { Collapse } from 'antd';
import { Card, mediaMdDown } from '@plandok/core';

export const EstimatedCostsCard = styled(Card)`
  max-width: 938px;
  box-shadow: 0 2px 10px rgba(38, 60, 122, 0.2);
  padding: 40px 60px;
  margin: 60px auto 32px;
  .costs-data {
    max-width: 591px;
  }
  .title {
    margin-bottom: 12px;
    line-height: 28px;
  }
  .description {
    font-size: 16px;
  }
  .cost {
    font-size: 42px;
    line-height: 49px;
    margin-top: 1px;
  }
  ${mediaMdDown(css`
    padding: 32px 14px 16px;
    margin: 16px auto 24px;
    .title {
      text-align: center;
    }
    .description {
      margin-bottom: 11px;
      font-size: 14px;
    }
    .cost {
      text-align: center;
    }
  `)};
`;

export const EstimatedCostsData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  ${mediaMdDown(css`
    flex-direction: column;
  `)};
`;

export const EstimatedCostsAdditionalData = styled.div`
  padding: 12px 16px;
  border: 1px solid #95d2d2;
  background: #e0f3fd;
  border-radius: 5px;

  div {
    font-size: 16px;
    line-height: 22px;
    ${mediaMdDown(css`
      font-size: 14px;
      line-height: 19px;
    `)};
  }
`;

export const SummaryCard = styled(Card)`
  max-width: 938px;
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  padding: 40px 67px 40px 60px;
  margin: 0 auto 30px;
  .title {
    margin-bottom: 12px;
    line-height: 28px;
  }
  .description {
    font-size: 16px;
    margin-bottom: 40px;
  }
  .charges {
    margin-bottom: 24px;
  }
  ${mediaMdDown(css`
    padding: 30px 14px 32px 14px;
    margin-bottom: 20px;
    .description {
      font-size: 14px;
    }
    .charges {
      margin-bottom: 16px;
    }
  `)};
`;

export const DetailedChargesHeader = styled.div`
  display: flex;
  padding-bottom: 12px;
  justify-content: space-between;
`;

export const DetailedChargesLineContainer = styled.div`
  padding: 4px 0 12px;
  border-bottom: 1px solid #dfe6f1;
  border-top: 1px solid #dfe6f1;
`;

export const SubscriptionLineContainer = styled.div`
  border-bottom: 1px solid #dfe6f1;
  padding: 12px 0;
  margin-bottom: 20px;
  div {
    div {
      div:last-child {
        margin-left: 5px;
      }
      ${mediaMdDown(css`
        flex-direction: column;
        div:last-child {
          margin-left: 0;
        }
      `)};
    }
  }
`;

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-item {
    border: none;
    .ant-collapse-header {
      padding: 8px 0px;
      display: -webkit-box;
      svg {
        margin-right: 8px;
      }
    }
  }
`;

export const StyledPanel = styled(Collapse.Panel)`
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 2px 0px 22px 15px !important;
    }
  }
  ${mediaMdDown(css`
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding-bottom: 32px !important;
      }
    }
  `)};
`;

export const PanelWhatsAppContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;

  div:nth-child(3) {
    width: 30%;
    text-align: end;
  }
`;

export const PanelSmsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  div:nth-child(3) {
    width: 30%;
    text-align: end;
  }
`;

export const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
`;

export const HeaderPanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 17px);
  div {
    :nth-child(1) {
      width: 40%;
      text-transform: capitalize;
    }
    :nth-child(2) {
      margin-left: -70px;
    }
    :nth-child(3) {
      width: 30%;
      text-align: end;
    }
  }
`;

export const SummaryContainer = styled.div`
  min-width: 263px;
  margin-left: auto;
  ${mediaMdDown(css`
    min-width: 224px;
  `)};
`;

export const CostItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => `${theme.mb}px`};
`;
