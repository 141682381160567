import styled from 'styled-components';
import { AnalyticsCard } from '../../styles';

export const Occupancy = styled(AnalyticsCard)`
  grid-area: occupancy;
  background: #fff;
`;

export const TotalStatus = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const TotalCount = styled.div`
  display: flex;
  align-items: baseline;
  height: 4.2rem;

  div:first-child {
    padding-right: 1rem;
  }
`;

export const Inliner = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NumbersColumn = styled.div`
  justify-content: center;
`;

export const Divider = styled.div`
  margin: 5px 0 !important;
`;
