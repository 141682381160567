import cn from 'classnames';
import React from 'react';
import { getIsMobile } from '@plandok/core';
import { sidebarConfig } from 'config/sidebar-config';
import { RoutePath } from 'constants/routes';
import MobileSidebarItem from './components/MobileSidebarItem';
import * as SC from './styles';

export default function MobileSidebar() {
  const isMobile = getIsMobile();
  const ITEMS_LIMIT = 4;

  return (
    <SC.Container className={cn('sidebar-mobile', { 'is-mobile': isMobile })} isMobile={isMobile}>
      {sidebarConfig.slice(0, ITEMS_LIMIT).map((item: any) => (
        <MobileSidebarItem item={item} key={item.title} />
      ))}
      <MobileSidebarItem item={{ path: RoutePath.MORE, iconType: 'menu', title: 'sidebar.menu' }} />
    </SC.Container>
  );
}
