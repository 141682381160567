import styled, { css } from 'styled-components';
import { Divider } from 'antd';
import { mediaMobileDown, Card } from '@plandok/core';

type PropsModel = {
  border: boolean;
};

type PropsColor = {
  background: boolean;
};

export const CardContainer = styled(Card)`
  max-width: 980px;
  width: 670px;
  margin: 90px auto;
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  border-radius: 20px;
  padding: 0;
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${mediaMobileDown(css`
    width: 355px;
    margin: 100px auto 0 auto;
    padding: 0;
  `)}
`;

export const HeaderTitle = styled.div`
  padding: 40px 40px 0 40px;
  ${mediaMobileDown(css`
    padding: 20px 15px 0 15px;
  `)}
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    min-width: 250px;
    margin-bottom: 10px;
  }
`;

export const CheckboxListProps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0 40px;
  ${mediaMobileDown(css`
    padding: 0 15px;
  `)}
`;

export const CheckboxList = styled.div<PropsModel>`
  border: ${({ border }) => `1px solid ${border ? '#13316D' : '#DFE6F1'}`};
  box-sizing: border-box;
  border-radius: 15px;
  width: 260px;
  height: 110px;
  padding: 10px 20px;
  span {
    font-size: 20px;
    font-weight: 600;
    color: #13316d;
    ${mediaMobileDown(css`
      font-size: 16px;
    `)}
  }
  .ant-checkbox-inner {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #fff;
    &:after {
      top: 50%;
      left: 30%;
    }
  }
  .ant-checkbox-checked {
    &:after {
      border: none;
    }
  }
  .ant-checkbox-wrapper {
    ${mediaMobileDown(css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `)}
  }
  ${mediaMobileDown(css`
    width: 152px;
    height: 135px;
    padding: 10px 0;
    text-align: center;
  `)}
`;

export const TotalClientGroup = styled.div`
  padding: 0 40px;
  ${mediaMobileDown(css`
    padding: 0 15px;
  `)}
`;

export const DividerWithMargin = styled(Divider)`
  margin-bottom: 10px;
`;

export const TotalClient = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const EditSelection = styled.div`
  color: #2472e8;
  font-size: 18px;
  cursor: pointer;
  svg {
    margin-right: 7px;
  }
  ${mediaMobileDown(css`
    margin-left: auto;
  `)}
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dfe6f1;
  padding: 8px 0;
`;

export const Price = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TopPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
`;

export const FooterDivider = styled.div`
  position: absolute;
  width: 100%;
  height: 0px;
  left: 0px;
  bottom: 10px;
  border: 1px solid #dfe6f1;
  top: 10px;
`;

export const ClientGroups = styled.div<PropsColor>`
  background-color: ${({ background }) => `${background ? '#F0F3F9' : '#fff'}`};
  padding: 10px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .ant-radio-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  span {
    color: #13316d;
    font-size: 18px;
    font-weight: 600;
  }
  .ant-radio-checked {
    &:after {
      animation: none !important;
      border: none !important;
    }
  }
  .ant-radio-inner {
    width: 20px;
    height: 20px;
    background-color: #fff;
    margin-right: 10px;
    &:after {
      top: 4px;
      left: 4px;
      width: 10px;
      height: 10px;
    }
  }
  ${mediaMobileDown(css`
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
  `)}
`;
