import { getDaysInMonth, getDaysInYear, endOfMonth, startOfMonth, addDays } from 'date-fns';
import axios from 'axios';

export const exportTableDataToCSV = async (type: string, from: any, to: any) => {
  const response = await axios.get(`/reports/export`, {
    responseType: 'arraybuffer',
    params: {
      reporter_type: type,
      from,
      to,
    },
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${type}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body?.removeChild?.(link);
};

const getMonthRange = (month: number) => {
  const startDate = startOfMonth(month);
  const endDate = endOfMonth(month);

  return { startDate, endDate };
};

const getLastYearRange = () => {
  const lastYear = new Date().getFullYear() - 1;
  const startDate = new Date(lastYear, 0, 1);
  const endDate = new Date(lastYear, 11, 31);

  return { startDate, endDate };
};

const getThisYearRange = () => {
  const startDate = new Date(new Date().getFullYear(), 0, 1);
  const endDate = new Date();

  return { startDate, endDate };
};

const getTomorrowRange = () => {
  const tomorrow = addDays(new Date(), 1);
  const startDate = tomorrow;
  const endDate = tomorrow;

  return { startDate, endDate };
};

const getThisWeekRange = () => {
  const getFirstDayOfWeek = () => {
    const day = new Date().getDay();
    const diff = new Date().getDate() - day + (day === 0 ? -6 : 1);

    return new Date(new Date().setDate(diff));
  };

  const startDate = getFirstDayOfWeek();
  const endDate = addDays(startDate, 6);

  return { startDate, endDate };
};

const getNextWeekRange = () => {
  const startDate = new Date();
  const endDate = addDays(new Date(), 7);

  return { startDate, endDate };
};

const getNextMonthRange = () => {
  const startDate = new Date();
  const endDate = addDays(new Date(), 30);

  return { startDate, endDate };
};

const getTimeRange = () => {
  const lastMonth = new Date().setMonth(new Date().getMonth() - 1);
  const thisMonth = new Date().setMonth(new Date().getMonth());

  const numberOfDaysInLastMonth = getDaysInMonth(lastMonth);
  const lastMonthRange = getMonthRange(lastMonth);

  const numberOfDaysInLastYear = getDaysInYear(new Date().setFullYear(new Date().getFullYear() - 1));
  const lastYearRange = getLastYearRange();

  const numberOfDaysInThisYear = Math.ceil(
    Math.abs(new Date(new Date().getFullYear(), 0, 1).getTime() - new Date().getTime()) / (1000 * 3600 * 24)
  );

  const numberOfDaysInThisMonth = getDaysInMonth(thisMonth);
  const thisMonthRange = getMonthRange(thisMonth);

  const thisYearRange = getThisYearRange();
  const tomorrowRange = getTomorrowRange();
  const thisWeekRange = getThisWeekRange();
  const nextWeekRange = getNextWeekRange();
  const nextMonthRange = getNextMonthRange();

  return {
    numberOfDaysInLastMonth,
    lastMonthRange,
    numberOfDaysInLastYear,
    lastYearRange,
    numberOfDaysInThisYear,
    thisYearRange,
    numberOfDaysInThisMonth,
    thisMonthRange,
    tomorrowRange,
    thisWeekRange,
    nextWeekRange,
    nextMonthRange,
  };
};

export const timeRangeOptions = (isPremium: boolean) => [
  { label: 'analytics.timeRange.today', days: 0, isStepByDay: true, isEnable: true },
  { label: 'analytics.timeRange.yesterday', days: 1, isStepByDay: true, isEnable: true },
  { label: 'analytics.timeRange.last7days', days: 7, isStepByDay: true, isEnable: true },
  { label: 'analytics.timeRange.last30days', days: 30, isStepByDay: true, isEnable: isPremium },
  { label: 'analytics.timeRange.last90days', days: 91, isStepByDay: false, isEnable: isPremium },
  {
    label: 'analytics.timeRange.lastMonth',
    days: getTimeRange().numberOfDaysInLastMonth,
    isStepByDay: false,
    isEnable: isPremium,
    monthRange: getTimeRange().lastMonthRange,
  },
  { label: 'analytics.timeRange.last6Months', days: 183, isStepByDay: false, isEnable: isPremium },
  {
    label: 'analytics.timeRange.lastYear',
    days: getTimeRange().numberOfDaysInLastYear,
    isStepByDay: false,
    isEnable: isPremium,
    monthRange: getTimeRange().lastYearRange,
  },
  {
    label: 'analytics.timeRange.thisWeek',
    days: 7,
    isStepByDay: false,
    isEnable: isPremium,
    monthRange: getTimeRange().thisWeekRange,
  },
  {
    label: 'analytics.timeRange.thisMonth',
    days: getTimeRange().numberOfDaysInThisMonth,
    isStepByDay: false,
    isEnable: isPremium,
    monthRange: getTimeRange().thisMonthRange,
  },
  {
    label: 'analytics.timeRange.thisYear',
    days: getTimeRange().numberOfDaysInThisYear,
    isStepByDay: false,
    isEnable: isPremium,
    monthRange: getTimeRange().thisYearRange,
  },
  {
    label: 'analytics.timeRange.tomorrow',
    days: 1,
    isStepByDay: false,
    isEnable: isPremium,
    monthRange: getTimeRange().tomorrowRange,
  },
  {
    label: 'analytics.timeRange.next7days',
    days: 7,
    isStepByDay: false,
    isEnable: isPremium,
    monthRange: getTimeRange().nextWeekRange,
  },
  {
    label: 'analytics.timeRange.next30days',
    days: 30,
    isStepByDay: false,
    isEnable: isPremium,
    monthRange: getTimeRange().nextMonthRange,
  },
];
