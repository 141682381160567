import { message } from 'antd';
import React from 'react';
import { validateEmail, validateRequired, composeValidators, Form, Field, Button } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import * as api from 'api';

export default function ResetPasswordForm() {
  const onSuccess = () => message.success(<IntlLabel label="message.successReset.password" />);

  return (
    <Form onSubmit={api.requestPasswordChange} onSuccess={onSuccess}>
      {({ submitting, formError }: any) => (
        <>
          <Field.Input
            name="email"
            label="input.email.label"
            placeholder="input.email.placeholder"
            validate={composeValidators(validateRequired, validateEmail)}
            submitting={submitting}
          />
          <Form.Alert text={formError} />
          <Button type="primary" block htmlType="submit" loading={submitting} label="reset.btn.text" />
        </>
      )}
    </Form>
  );
}
