import styled from 'styled-components';

export const Preview = styled.div`
  background: #f0f3f9;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  font-size: 14px;
  margin: -40px 30px 0 20px;
  line-height: 20px;
  color: #13316d;
  min-height: 140px;
  max-height: 250px;
  word-break: break-all;
  overflow: hidden;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: #e9ecf3;
  }
  &::-webkit-scrollbar-thumb {
    background: #adb6cc;
    border-radius: 10px;
  }
`;
