import React from 'react';
import { ModifyMassLayout, NotReleased } from 'components';
import SheduleSendForm from './components/SheduleSendForm';
import withFormApi from 'hocs/form/withFormApi';
import { RoutePath } from 'constants/routes';

export const SheduleSendFormWithApi = withFormApi({
  backPath: RoutePath.CLIENT_SELECTION,
  successMessage: 'Massage',
})(SheduleSendForm);

export default function ModifyScheduleSendPage() {
  return (
    <NotReleased>
      <ModifyMassLayout title="Schedule send" step="SMS setup. Step 3 of 4">
        <SheduleSendFormWithApi />
      </ModifyMassLayout>
    </NotReleased>
  );
}
