/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
  'login.title': 'Войдите в свой аккаунт',
  'label.email': 'Email',
  'placeholder.email': 'например andrey@gmail.com',
  'input.password.label': 'Пароль',
  'input.password.placeholder': 'Введите пароль',
  'login.btn': 'Вход',
  'link.sign.in': 'Войдите',
  'label.password': 'Введите пароль',
  'login.forgot.password': 'Забыли пароль?',
  'login.no.account': 'Еще нет аккаунта?',
  'login.description': 'Если у вас возникли проблемы при входе в систему попробуйте сбросить пароль',
  'register.title': 'Создать аккаунт',
  'register.description': 'Создайте бесплатный аккаунт в Plandok для планирования визитов и бронирований',
  'register.btn': 'Зарегистрироваться',
  'register.agreement.agree': 'Я согласен с',
  'register.agreement.terms': 'Условия использования сайта',
  'register.agreement.policy': 'политикой конфиденциальности',
  'register.agreement.and': 'и',
  'register.agreement.partner': 'Условия сотрудничества с партнерами',
  'register.have.account': 'Уже зарегистрированы?',
  'register.validation.confirm': 'Прежде чем продолжить просмотрите и примите условия выше.',
  'link.sign.up': 'Регистрация',
  'text.alreadyHaveAccount': 'Уже зарегистрированы?',
  'input.company.label': 'Компания',
  'input.company.placeholder': 'Компания',
  'input.country.label': 'Страна',
  'input.country.placeholder': 'Название страны',
  'input.timeZone.label': 'Часовой пояс',
  'input.timeZone.placeholder': 'GMT +02.00',
  'input.currency.label': 'Валюта',
  'input.language.label': 'Язык (Language)',
  'input.language.placeholder': 'Русский',
  'input.currency.placeholder': 'Русский рубль',
  'reset.title': 'Восстановление пароля',
  'reset.btn.text': 'Выслать новый пароль',
  'reset.pwd.btn': 'Восстановить пароль',
  'reset.no.password': 'Вспомнили пароль?',
  'reset.description':
    'Введите адрес электронной почты для входа чтобы получить безопасную ссылку установки нового пароля',
  'sidebar.calendar': 'Календарь',
  'sidebar.client': 'Клиенты',
  'sidebar.staff': 'Сотрудники',
  'sidebar.service': 'Услуги',
  'sidebar.notification': 'Сообщения',
  'sidebar.settings': 'Настройки',
  'sidebar.menu': 'Меню',
  'sidebar.analytics': 'Аналитика',
  'sidebar.language': 'Язык (Language)',
  'sidebar.logout': 'Выйти',
  'user.menu.settings': 'Мои настройки',
  'user.menu.language': 'Изменить язык',
  'user.menu.logout': 'Выйти',
  'input.globalSearch.placeholder': 'Что вы ищете ?',
  'input.globalSearch.description':
    'Поиск по имени клиента, мобильному телефону, электронной почте или номеру бронирования',
  'title.upcomingAppointments': 'Предстоящие Записи',
  'calendar.day': 'День',
  'calendar.week': 'Неделя',
  'calendar.monday': 'Понедельник',
  'calendar.tuesday': 'Вторник',
  'calendar.wednesday': 'Среда',
  'calendar.thursday': 'Четверг',
  'calendar.friday': 'Пятница',
  'calendar.saturday': 'Суббота',
  'calendar.sunday': 'Воскресенье',
  'calendar.mon': 'Пнд',
  'calendar.tue': 'Втр',
  'calendar.wed': 'Срд',
  'calendar.thu': 'Чтв',
  'calendar.fri': 'Птн',
  'calendar.sat': 'Суб',
  'calendar.sun': 'Воск',
  'calendar.today': 'Сегодня',
  'calendar.january': 'Январь',
  'calendar.february': 'Февраль',
  'calendar.march': 'Март',
  'calendar.april': 'Апрель',
  'calendar.may': 'Май',
  'calendar.june': 'Июнь',
  'calendar.july': 'Июль',
  'calendar.august': 'Август',
  'calendar.september': 'Сентябрь',
  'calendar.october': 'Октябрь',
  'calendar.november': 'Ноябрь',
  'calendar.december': 'Декабрь',
  'calendar.jan': 'Янв',
  'calendar.feb': 'Фев',
  'calendar.mar': 'Мар',
  'calendar.apr': 'Апр',
  'calendar.may.short': 'Май',
  'calendar.jun': 'Июн',
  'calendar.jul': 'Июл',
  'calendar.aug': 'Авг',
  'calendar.sept': 'Сен',
  'calendar.oct': 'Окт',
  'calendar.nov': 'Ноя',
  'calendar.dec': 'Дек',
  'calendar.allStaff': 'Все ',
  'calendar.workingStaff': 'Работающие ',
  'calendar.tooltip.time': 'Время',
  'calendar.tooltip.category': 'Категория',
  'calendar.tooltip.service': 'Услига',
  'calendar.tooltip.staff': 'Сотрудник',
  'calendar.tooltip.price': 'Цена',
  'calendar.tooltip.repeating': 'Повтор',
  'btn.viewDetails': 'Посмотреть детали',
  'calendar.tooltip.emptyCustomer': 'C улицы',
  'calendar.tooltip.bookedOnline': 'Онлайн запись',
  'calendar.print.description':
    'Для того, чтобы использовать функцию печати, ваш план должен быть активирован. Активировать',
  'calendar.print.appointmentsBetween.title': 'Встречи между',
  'calendar.print.appointmentsAnd.title': 'и',
  'calendar.billing.title': 'План и выставление счетов',
  'calendar.btn.print.title': 'Распечатать',
  'datepicker.next.week': 'Следующея неделя',
  'datepicker.in.two.weeks': 'Через 2 недели',
  'datepicker.in.three.weeks': 'Через 3 недели',
  'datepicker.in.four.weeks': 'Через 4 недели',
  'datepicker.in.five.weeks': 'Через 5 недель',
  'datepicker.in.six.weeks': 'Через 6 недель',
  'modal.splitAppointment.title': 'Разделить запись',
  'modal.splitAppointment.text':
    'Эта запись является частью группы, перемещение его на другой день разделит его на отдельные записи. Вы уверены?',
  'blocktime.create.modal.title': 'Блок времени',
  'blocktime.edit.modal.title': 'Изменить блок времени',
  'appointment.create.menu': 'Запись',
  'blocktime.create.menu': 'Блок времени',
  'appointment.new.title': 'Новая Запись',
  'input.startTime.label': 'Время начала',
  'input.endTime.label': 'Время конца',
  'input.startDate.label': 'Дата начала',
  'input.endDate.label': 'Дата окончания',
  'input.startDate.description.label': 'Выбор запуска Дата',
  'input.endDate.description.label': 'Выберите дату окончания',
  'input.closedDatedDescription.placeholder': 'Например каникулы',
  'input.service.label': 'Услуга',
  'input.services.label': 'услуги',
  'input.service.placeholder': 'Выберите услугу',
  'input.staff.label': 'Сотрудник',
  'input.staff.placeholder': 'Выберите сотрудника',
  'input.pickDateAndTime.title': 'Выберите дату и время',
  'input.pickDate.title': 'Выберите дату',
  'service.add.another': 'Добавить еще услугу',
  'input.appointmentNotes.label': 'Заметки о записи',
  'input.emailSubject.placeholder': 'Напоминание о вашей записи',
  'input.appointmentNotes.placeholder': 'Добавить заметку о записи (видна только сотрудникам)',
  'input.searchClients.placeholder': 'Поиск Клиента',
  'input.searchService.placeholder': 'Поиск',
  'appointment.empty.list':
    'Используйте поиск, чтобы добавить клиента, или оставьте пустым, чтобы сохранить его как с улицы.',
  'appointment.clients.empty.list': 'Список клиентов пуст.',
  'appointment.clients.addClient': 'Создать клиента',
  'client.create.new': 'Создать нового клиента',
  'client.choose': 'Выберите клиента',
  'client.menu.edit': 'Изменить данные клиента',
  'client.menu.remove': 'Удалить из записи',
  'appointment.btn.save': 'Сохранить',
  'appointment.vat': 'НДС ({0}%)',
  'appointment.total': 'Всего',
  'appointment.view.title': 'Информация о Записи',
  'appointment.status.btn': 'Статус записи',
  'appointment.btn.cancel': 'Удалить',
  'btn.cancelAppointment': 'Удалить',
  'modal.cancelAppointment.title': 'Удалить',
  'title.clients': 'Клиенты',
  'client.create.title': 'Новый клиент',
  'client.options.export.title': 'Экспортные клиенты',
  'client.options.export.description': 'Скачать CSV с данными клиента',
  'client.options.import.title': 'Импортировать клиентов',
  'client.export.premium.description': 'Чтобы использовать функцию экспорта, ваш план должен быть обновлен.',
  'client.export.premium.visit': 'Для обновления посетите ',
  'client.import.instructions.description':
    'Следуйте инструкциям ниже, чтобы импортировать клиентов в свою учетную запись Plandok.',
  'client.import.instructions.download': 'Загрузите файл импорта, используя кнопку ниже',
  'client.import.instructions.copy': 'Скопируйте и вставьте данные вашего клиента в файл.',
  'client.import.instructions.email': 'Отправьте его нам по адресу info@plandok.com.',
  'client.import.instructions.account': 'и мы импортируем в ваш аккаунт',
  'table.clients.name': 'Имя',
  'table.clients.phone': 'Телефон',
  'table.clients.mobile': 'Телефон',
  'table.clients.email': 'Email',
  'table.clients.gender': 'Пол',
  'client.page.title': 'Профиль клиента',
  'btn.more.options': 'Oпций',
  'btn.showAll': 'Показать все',
  'btn.collapse': 'Закрыть',
  'btn.reschedule': 'Перенести',
  'title.edit.client': 'Редактировать клиента',
  'menu.edit.client': 'Редактировать клиента',
  'menu.delete.client': 'Удалить клиента',
  'title.mobilePhone': 'Мобильный телефон',
  'title.telephoneNumber': 'Мобильный телефон',
  'title.email': 'Email',
  'title.address': 'Адрес',
  'title.dateOfBirth': 'Дата рождения',
  'title.gender': 'Пол',
  'client.statistics': 'Статистика',
  'client.totalSales': 'Всего продаж',
  'client.totalBookings': 'Всего записей',
  'client.outstanding': 'Задолженность',
  'client.allBooking': 'Записи',
  'client.completed': 'Завершено',
  'client.cancelled': 'Отменена',
  'client.noShow': 'Прогул',
  'appointment.list.title': 'Записи',
  'upcoming.list.title': 'Предстоящий',
  'past.list.title': 'Прошедшее',
  'modal.confirmClientDelete.text.0': 'Вы уверены, что хотите навсегда удалить клиента',
  'modal.confirmClientDelete.text.1': 'из базы данных ?',
  'input.date.label': 'До определённого дня',
  'input.date.placeholder': 'Выберите дату',
  'input.firstName.label': 'Имя',
  'input.firstName.placeholder': 'e.g. Андрей',
  'input.lastName.label': 'Фамилия',
  'input.lastName.placeholder': 'e.g. Попов',
  'input.mobile.label': 'Номер мобильного',
  'input.mobile.placeholder': 'Введите номер',
  'input.email.label': 'Email',
  'input.email.placeholder': 'e.g. andrey@gmail.com',
  'input.title.label': 'Заголовок',
  'input.title.placeholder': 'например Дантист',
  'input.notificationType.label': 'Отправить уведомление по',
  'tab.personalInfo': 'Личная информация',
  'input.gender.label': 'Пол',
  'input.referal.label': 'Источник рефералов',
  'input.referal.placeholder': 'Выберите источник',
  'input.birthDay.label': 'День рождения',
  'input.month.placeholder': 'Месяц',
  'input.day.placeholder': 'День',
  'input.year.placeholder': 'Год',
  'input.clientsNotes.label': 'Заметки  клиента',
  'input.clientsNotes.placeholder': 'Введите заметки клиента',
  'input.bookingDisplay.label': 'Показать на записях',
  'input.serviceDescription.label': 'Описание услуги',
  'input.serviceDescription.placeholder':
    'Добавить краткое описание, которое будет видно для клиентов в онлайн-бронирований',
  'input.isBookableOnline.label': 'Доступно для онлайн записи',
  'service.onlineBookings.title': 'Онлайн-запись',
  'tab.address': 'Адрес',
  'input.address.label': 'Адрес',
  'input.address.placeholder': 'Введите свой адрес',
  'input.city.label': 'Город',
  'input.city.placeholder': 'Введите город',
  'input.zip.label': 'Почтовый индекс',
  'input.zip.placeholder': 'Введите индекс',
  'btn.edit': 'Редактировать',
  'btn.cancel': 'Отмена',
  'btn.save': 'Сохранить',
  'btn.save.changes': 'Сохранить',
  'tabs.staffWorking.title': 'Время работы',
  'tabs.staffMembers.title': 'Сотрудники',
  'tabs.userPermissions.title': 'Уровень доступа',
  'tabs.closedDates.title': 'Нерабочие дни',
  'btn.new.staff': 'Новый сотрудник',
  'table.staff.name': 'Имя',
  'table.staff.mobileNumber': 'Телефон',
  'table.staff.email': 'Email',
  'table.staff.userPermission': 'Уровень доступа',
  'table.staff.dataRange': 'Диапазон дат',
  'table.staff.numberOfDays': 'Количество дней',
  'table.staff.location': 'Локация',
  'table.staff.description': 'Описание',
  'table.appointmentsList.appointment': 'Запись',
  'table.appointmentsList.client': 'Клиент',
  'table.appointmentsList.service': 'Услуга',
  'table.appointmentsList.date': 'Дата',
  'table.appointmentsList.time': 'Время',
  'table.appointmentsList.duration': 'Продолжительность',
  'table.appointmentsList.location': 'Филиал',
  'table.appointmentsList.staff': 'Сотрудник',
  'table.appointmentsList.price': 'Цена',
  'table.appointmentsList.status': 'Статус записи',
  'table.appointmentsCancellations.ref': 'Реф',
  'table.appointmentsCancellations.client': 'Клиент',
  'table.appointmentsCancellations.service': 'Услуга',
  'table.appointmentsCancellations.scheduledDate': 'Запланированная дата',
  'table.appointmentsCancellations.cancelledDate': 'Отмененная дата',
  'table.appointmentsCancellations.cancelledBy': 'Отменено',
  'table.appointmentsCancellations.reason': 'Причина',
  'table.appointmentsCancellations.price': 'Цена',
  'table.appointmentsCancellations.totalCount': 'Сумма',
  'table.clients.appointments': 'Записи',
  'table.clients.noShows': 'Непришол',
  'table.clients.totalSales': 'Всего продаж',
  'table.clients.added': 'Добавлен',
  'table.clients.daysAbsent': 'Дней отсутствует',
  'table.clients.lastAppointment': 'Последнея запись',
  'table.clients.lastLocation': 'Последнйи филиал',
  'table.appointmentsByService.serviceName': 'наименование услуги',
  'table.appointmentsByService.appointments': 'Записи',
  'table.appointmentsByService.totalValue': 'Общая стоимость',
  'table.appointmentsByStaff.staffMember': 'Штатный сотрудник',
  'table.appointmentsByStaff.appointments': 'Запись',
  'table.appointmentsByStaff.totalValue': 'Общая стоимость',
  'table.pagination.showing.title': 'Показевает',
  'table.pagination.results.title': 'Результаты',
  'clients.duplicate.title': 'Найдено дубликаты клиентов.',
  'clients.duplicate.description': 'Есть похожие клиенты, которые можно объединить.',
  'clients.merge.btn.title': 'Объединить',
  'clients.merge.modal.title': 'Объединить клиентов',
  'clients.merge.selectAll.title': 'Выбрать все дубликаты',
  'clients.merge.duplicatesNotFound.title': 'Дубликаты не найдены',
  'clients.merge.duplicatesNotFound.description': 'Нет доступных клиентов для объединения',
  'clients.mergeConfirm.modal.title': 'Подтвердить объединение',
  'clients.mergeConfirm.modal.description':
    'Вы уверены, что хотите объединить {0} дубликатов? Это действие является постоянным и не может быть отменено.',
  'clients.mergeConfirm.checkbox.title': 'Я понимаю, что объединение клиентов нельзя отменить.',
  'clients.mergeConfirm.btn.title': 'Подтвердить и объединить',
  'clients.mergeConfirm.cancel.btn.title': 'Отмена',
  'clients.byNumber.btn.title': 'По телефону {0}',
  'clients.byEmail.btn.title': 'По электронной почте {0}',
  'clients.byName.btn.title': 'По имени {0}',
  'title.newStaff': 'Новый сотрудник',
  'title.editStaff': 'Изменить сотрудника',
  'description.extraCharge':
    'При добавлении новых сотрудников к вашей ежемесячной подписке будет добавлена дополнительная плата',
  'tabs.details': 'Детали',
  'input.staffPicture.label': 'Фото',
  'input.cropImage.title': 'Обрезать фото',
  'input.cropImage.btn.apply.title': 'Сохранить',
  'input.stafffirstName.label': 'Имя',
  'input.stafffirstName.placeholder': 'e.g. Андрей',
  'input.stafflastName.label': 'Фамилия',
  'input.stafflastName.placeholder': 'e.g. Попов',
  'input.stafmobile.label': 'Номер телефона',
  'input.stafemail.label': 'Email',
  'input.stafemail.placeholder': 'e.g. andrey@gmail.com',
  'input.permission.label': 'Уровень доступа',
  'input.uploadProfile.label': 'Загрузить изображение профиля',
  'input.changeProfile.label': 'Изменить изображение профиля',
  'input.notes.label': 'Заметки',
  'input.staffNotes.placeholder':
    'Добавить личные заметки, которые можно просмотреть только в настройках персонала (необязательно)',
  'input.blockTime.placeholder': 'например встреча',
  'tabs.locations': 'Локаций',
  'tabs.integrations': 'Интеграции',
  'input.staffLocations.description': 'Назначьте локации, где можно забронировать этого сотрудника.',
  'input.multiLocation.description': 'Назначьте локации, где можно забронировать этого сотрудника.',
  'tabs.services': 'Услуги',
  'input.staffServices.description': 'Назначьте услуги, которые может выполнять этот сотрудник',
  'input.multiServices.description': 'Назначьте услуги, которые может выполнять этот сотрудник',
  'input.shiftStart.label': 'Начало смены',
  'input.shiftEnd.label': 'Конец смены',
  'edit.staff.title.resendPassword': 'Повторная отправка пароля',
  'edit.staff.info.resendPassword':
    'Сотрудник не получил письмо с паролем? Нажмите кнопку ниже, чтобы отправить эл.почту с инструкциями по настройке пароля.',
  'edit.staff.button.resendPassword': 'Повторно отправить пароль ',
  'btn.add.shift': 'Добавить еще одну смену',
  'break.time': '{0} перерыва',
  'input.repeat.label': 'Повторить',
  'input.repeatWeekly.label': 'Еженедельно',
  'input.repeatDoNot.label': 'Не повторять',
  'input.endRepeat.label': 'Конец повтора',
  'input.endRepeatOngoing.label': 'Постоянно',
  'input.endRepeatSpecificDate.label': 'Конкретная дата',
  'input.endRepeatDate.placeholder': 'Выберите дату',
  'btn.deleteUpcomingShift': 'Удалить предстоящие смены',
  'btn.deleteThisShift': 'Удалить только эту смену',
  'btn.updateUpcomingShift': 'Обновленить предстоящие смены',
  'btn.updateThisShift': 'Обновленить только эту смену',
  'modal.repeatShift.title': 'Повторяющаяся Смена',
  'form.error.uniqShifts': 'Смены должны быть уникальными',
  'btn.add.new': 'Добавить',
  'btn.addNewService': 'Добавить Услугу',
  'btn.newCategory': 'Новая категория',
  'btn.newServiceGroup': 'Новая категория',
  'btn.editCategory': 'Редактировать категорию',
  'btn.deleteCategory': 'Удалить категорию',
  'btn.downloadImportFile': 'Скачать файл импорта',
  'serviceGroup.delete.success': 'Категория успешно удалена!',
  'service.new.title': 'Новая Услуга',
  'service.edit.title': 'Редактировать услугу',
  'input.serviceName.label': 'Название услуги',
  'input.serviceName.placeholder': 'e.g. Массаж',
  'input.serviceCategory.label': 'Категория Услуг',
  'input.serviceCategory.placeholder': 'Выберите категорию услуг',
  'input.price.label': 'Цена',
  'input.price.placeholder': '0',
  'input.duration.label': 'Длительность',
  'input.duration.placeholder': 'Длительность',
  'input.staffSelect.description': 'Выберите сотрудника, который выполняет эту услугу',
  'input.newCategoryName.label': 'Название категории',
  'input.newCategoryDescription.label': 'Описание категории',
  'input.newCategoryDescription.placeholder': 'Опишите эту категорию, она будет показана при онлайн-бронировании.',
  'modal.newCategory.title': 'Новая категория',
  'modal.editCategory.title': 'Редактировать категорию',
  'input.chooseCategory.label': 'Выберите цвет категории',
  'client.messages.title': 'Клиентские сообщения',
  'tabs.messagesLog': 'Журнал сообщений',
  'tabs.messagesLog.description': 'Список отправленных сообщений вашим клиентам',
  'tabs.messagesSettings': 'Настройки',
  'tabs.messagesSettings.description': 'Настройте параметры сообщений в соответствии с потребностями вашего бизнеса.',
  'table.header.timeSent': 'Отправлено',
  'table.header.client': 'Клиент',
  'table.header.appointment': 'Запись',
  'table.header.destination': 'Адрес',
  'table.header.type': 'Тип',
  'table.header.message': 'Сообщение',
  'table.header.status': 'Статус',
  'table.header.cost': 'Стоимость',
  'modal.message.title': 'Просмотр',
  'setup.page.title': 'Настройки',
  'title.accountSettings': 'Настройки Аккаунта',
  'title.accountSetup': 'Настройки Компании',
  'title.accountSetup.description': 'Управление настройками, такими как название вашей компании и часовой пояс',
  'resources.list.title': 'Ресурсы',
  'title.resources': 'Ресурсы',
  'title.resources.description':
    'Настройте доступные для бронирования ресурсы, такие как комнаты, стулья или оборудование',
  'title.locations': 'Локаций',
  'title.locations.description': 'Управляйте локациями торговых точек для вашего бизнеса',
  'calendar.settings.title': 'Настройки Календаря',
  'calendar.btn.selectFromCalendar': 'Выберите из календаря',
  'title.calendarSettings': 'Настройки Календаря',
  'title.calendarSettings.description': 'Настройте цветовую схему и вид вашего календаря',
  'title.clientSettings': 'Настройки Клиента',
  'title.onlineBookingSettings': 'Настройки онлайн-бронирований',
  'title.salesSettings': 'Настройки продаж',
  'title.receiptSequencing': 'Последовательность выдачи квитанций',
  'title.receiptSequencing.description': 'Настройте детали, отображаемые в товарных чеках, выдаваемых клиентам',
  'title.receiptTemplate': 'Шаблон квитанции',
  'title.taxes.description': 'Управление ставками налогов, применяемыми к товарам, продаваемым при оформлении заказа',
  'title.paymentTypes': 'Типы платежей',
  'title.paymentTypes.description': 'Настройка типов оплаты вручную для использования при оформлении заказа',
  'title.discounts.description': 'Настройка скидок',
  'title.receiptPrefix': 'Квитанция №. Префикс',
  'title.receiptNumber': 'Номер следующего чека',
  'title.change': 'Изменить',
  'title.saleReceiptTemplate': 'Шаблон товарного чека',
  'description.saleReceiptTemplate': 'Настройте содержание, отображаемое в квитанциях, выдаваемых клиентам',
  'setting.automaticallyPrint': 'Автоматическая печать чеков по завершении продажи',
  'setting.showMobile': 'Показывать мобильный и электронный адрес клиента в товарном чеке',
  'setting.showAddress': 'Показывать адрес клиента в товарном чеке',
  'title.receiptTitle': 'Заголовок квитанции',
  'title.referrals': 'Реферальные Источники',
  'title.referrals.description': 'Настройка источников, чтобы отслеживать, как клиент нашел ваш бизнес',
  'title.clientNotifications': 'Настройки Уведомлений Клиента',
  'title.clientNotifications.description': 'Управляйте сообщениями, которые отправляются клиентам',
  'cancelReason.list.title': 'Причины Отмены',
  'title.cancellationReasons': 'Причины Отмены',
  'title.cancellationReasons.description': 'Отслеживайте, почему клиенты не пришли на свой визиты',
  'title.onlineBooking.description': 'Управление доступностью и настройками онлайн-бронирований туров',
  'title.pointOfSale': 'Торговая точка',
  'discount.list.title': 'Скидоки',
  'title.discountTypes': 'Типы Скидок',
  'title.discountTypes.description': 'Установите типы скидок для использования во время оформления заказа',
  'title.premiumFeature': 'Премиум-функция',
  'company.details.title': 'Данные компании',
  'input.businessName.label': 'Название фирмы',
  'input.businessName.placeholder': 'e.g. Plandok',
  'input.description.label': 'Описание',
  'input.description.placeholder': 'Введите описание',
  'input.businessAddress.label': 'Адрес',
  'input.businessAddress.placeholder': 'Введите адрес',
  'input.website.label': 'Веб-сайт',
  'input.website.placeholder': 'Введите название сайта',
  'input.contactNumber.label': 'Контактный номер',
  'input.contactNumber.placeholder': 'Введите номер',
  'input.businessTimeZone.label': 'Часовой пояс',
  'input.businessTimeZone.placeholder': 'GMT +02.00',
  'input.businessCountry.label': 'Страна',
  'input.businessCountry.placeholder': 'Выберите страну',
  'input.timeFormat.label': 'Формат времени',
  'input.timeFormat.placeholder': '24 часа',
  'table.header.resourceName': 'Ресурс',
  'btn.new.resource': 'Новый ресурс',
  'input.resourceName.label': 'Название ресурса',
  'input.resourceName.placeholder': 'Введите название',
  'input.resourceDescription.label': 'Описание',
  'input.resourceDescription.placeholder': 'Открыто',
  'input.resourceLocation.placeholder': 'Название Локаций',
  'resourcesTooltip.location': 'Location tooltip for resource',
  'modal.newResource.title': 'Новый Ресурс',
  'modal.editResource.title': 'Редактировать Ресурс',
  'table.header.locationName': 'Локация',
  'table.header.locatonAddress': 'Адрес',
  'table.header.location.Phone': 'Телефон',
  'modal.newLocation.title': 'Новая Локация',
  'modal.editLocation.title': 'Редактировать Локацию',
  'btn.new.location': 'Новая Локация',
  'btn.addExtraLocation': 'Добавить дополнительную локацию',
  'input.locationName.label': 'Название',
  'input.locationName.placeholder': 'Введите Название Локаций',
  'input.profile.locationName.placeholder': 'Например. Парикмахерская салон',
  'input.slug.label': 'Окончание ссылки',
  'input.locationTips.label': 'Советы по расположению',
  'input.slug.explanation': 'Будет виден в вашем регионе URL',
  'input.contactEmail.label': 'Контактный Email',
  'input.locationAddress.label': 'Адрес',
  'input.locationAddress.placeholder': 'Введите Адрес Локаций',
  'tooltip.contactEmail': 'Ответы на записи от клиентов будут отправлены на этот email.',
  'input.appointmentColors.label': 'Цвет записи',
  'input.colorBy.service': 'По цвету категории услуг',
  'input.colorBy.employee': 'По цвету сатрудника',
  'input.colorBy.appointment': 'По статусу записи',
  'input.timeSlot.label': 'Интервал времени в календаре',
  'input.defaultViewType.label': 'Вид календаря по умолчанию',
  'input.calendarTimeRange.label': 'Диапазон календарного времени',
  'input.calendarTimeRange.allTimes.option': 'Все время (00:00 - 24:00)',
  'input.calendarTimeRange.onlyWorkingHours.option': 'Только рабочее время',
  'input.calendarTimeRange.customRange.option': 'Пользовательский диапазон',
  'input.weekStart.label': 'День начала недели',
  'table.header.refSourcetitle': 'Источник Рефералов',
  'table.header.refSourceAddDate': 'Дата добавления',
  'btn.new.referral': 'Новый Источник Рефералов',
  'input.nameRefSource.label': 'Название Источника',
  'input.nameRefSource.placeholder': 'e.g. Facebook реклама',
  'modal.newRefSource.title': 'Новый Источник Рефералов',
  'modal.editRefSource.title': 'Изменить Источник Рефералов',
  'input.sendBy.label': 'Отправить уведомление по',
  'input.emailSubject.label': 'Тема письма',
  'input.emailTemplate.label': 'Шаблон электронного письма',
  'input.emailTemplate.placeholder': 'Введите шаблон электронного письма',
  'input.subject.label': 'Тема письма',
  'input.subject.placeholder': 'Введите Тему письма',
  'title.messagingSettings.label': 'Настройки сообщений',
  'title.messagingSettings.description':
    'Установленные здесь типы сообщений будут автоматически отправляться клиентам. Все созданные сообщения могут быть легко доступны на странице сообщений (в главном меню). Настройте параметры того, как и когда отправляются сообщения, и отредактируйте шаблоны, чтобы персонализировать текст. Используйте теги ниже, чтобы включить детали встречи в сообщения:',
  'client.first.name': 'CLIENT_FIRST_NAME',
  'client.last.name': 'CLIENT_LAST_NAME',
  'staff.first.name': 'STAFF_FIRST_NAME',
  'staff.last.name': 'STAFF_LAST_NAME',
  'booking.date.time': 'BOOKING_DATE_TIME',
  'booking.date': 'BOOKING_DATE',
  'booking.time': 'BOOKING_TIME',
  'service.name': 'SERVICE_NAME',
  'business.name': 'BUSINESS_NAME',
  'business.phone': 'BUSINESS_PHONE',
  'business.address': 'BUSINESS_ADDRESS',
  'business.email': 'BUSINESS_EMAIL',
  'location.name': 'LOCATION_NAME',
  'location.phone': 'LOCATION_PHONE',
  'location.address': 'LOCATION_ADDRESS',
  'location.email': 'LOCATION_EMAIL',
  'tabs.reminders.title': 'Напоминания',
  'input.enableReminder.label': 'Включить напоминания',
  'input.enableReminder.description': 'Aвтоматически отправляет клиентам раньше их предстоящей встречи',
  'input.reminder.title': 'Напоминание {0}',
  'input.message.title': 'Сообщение {0}',
  'input.reminder.description': 'Каналы и время отправки',
  'input.reminder.channels': 'Выберите каналы, по которым будет отправлено это сообщение',
  'input.reminderBefore.label': 'Время уведомления до записи',
  'input.smsSender.label': 'Имя отпровителя сообщения',
  'input.messageSender.label': 'Идентификатор отправителя сообщения',
  'input.smsSender.placeholder': 'Введите имя отпровителя сообщения',
  'input.smsTemplate.label': 'Шаблон сообщения',
  'input.messageTemplate.label': 'Шаблон сообщения',
  'input.smsTemplate.placeholder': 'Введите шаблон сообщения',
  'input.whatsAppSender.placeholder': 'Plandok',
  'reminder.whatsApp.notificate':
    'В настоящее время шаблон сообщения WhatsApp не редактируется из-за ограничений. Однако мы активно работаем над тем, чтобы сделать его полностью редактируемым.',
  'reminder.charactersCount': 'Количество символов:',
  'reminder.pricing': 'Цены',
  'modal.pricing.description':
    'В разных странах действуют разные тарифы. Мы предоставляем наилучшую возможную цену от наших партнеров. Они могут варьироваться.',
  'modal.pricing.subDescription': 'Плата будет взиматься после отправки сообщений и добавляться к предстоящему счету.',
  'modal.pricing.counterDescription':
    'Стандартное текстовое SMS ограничено 160 символами и 70 символами для сообщений со специальными символами.',
  'modal.pricing.learnMore': 'Узнать больше',
  'modal.pricing.priceRates': 'Расценки',
  'modal.pricing.messagePrice': 'Стоимость 1 сообщения:',
  'modal.sendTestMessageByNumber.title': 'Отправить тестовое сообщение на этот номер:',
  'modal.sendTestMessageByNumber.description': 'Взимается плата, как за обычное сообщение.',
  'modal.sendTestMessageByEmail.title': 'Отправить тестовое сообщение на этот адрес электронной почты:',
  'modal.sendTestMessageByEmail.placeholder': 'Пожалуйста, введите адрес электронной почты',
  'btn.send': 'Отправить',
  'reminder.sendTestMessage': 'Отправить текстовое сообщение',
  'button.reminder.addTag': 'Добавить метку',
  'button.reminder.addAnotherReminder': 'ДОБАВИТЬ ДРУГОЕ НАПОМИНАНИЕ',
  'button.message.addAnotherMessage': 'ДОБАВИТЬ ДРУГОЕ СООБЩЕНИЕ',
  'button.reminder.upgrade': 'Обновите, чтобы получить доступ',
  'label.emailSettings': 'Настройки Email',
  'label.SMSSettings': 'Настройки SMS',
  'label.whatsAppSettings': 'Настройки WhatsApp',
  'tooltip.sender.0': 'Будет отображаться как имя отправителя SMS',
  'tooltip.sender.1': 'Максимум 11 символов',
  'tooltip.sender.2': 'Может не работать в некоторых странах',
  'tooltip.sender.3': 'Только латинские буквы или цифры',
  'tabs.confirmations.title': 'Подтверждения',
  'btn.appBookings': 'Бронирование в приложении',
  'btn.onlineBookings': 'Онлайн бронирование',
  'input.enableAppConfirmation.label': 'Включить подтверждение бронирований в приложении',
  'input.enableOnlineConfirmation.label': 'Включить подтверждение бронирований онлайн',
  'input.enableConfirmationApp.description': 'Автоматически отправляется клиентам, когда для них забронирована встреча',
  'input.enableConfirmationOnline.description':
    'Автоматически отправляется клиентам, когда они бронируют назначенную встречу',
  'messageSetup.header': 'Настройка сообщений',
  'channels.subHeader': 'Каналы',
  'tabs.reschedules.title': 'Перепланирования',
  'input.enableReschedule.label': 'Включить Перепланирования',
  'input.enableReschedule.description': 'Автоматически отправляет клиентам при изменении времени записи',
  'tabs.cancellations.title': 'Отмены',
  'input.enableCancellation.label': 'Включить Отмены',
  'input.enableCancellations.description': 'Автоматически отправляет клиентам, когда их запись отменена',
  'tabs.thanks.title': 'Благодарности',
  'input.enableThank.label': 'Включить Благодарности',
  'input.enableThankyou.description': 'Сообщение отправляется клиенту, когда статус встречи помечается как завершенный',
  'tabs.lastVisit.title': 'Последнее посещение',
  'input.enableLastVisit.label': 'Включить Последнее посещение',
  'input.enableLastVisit.description':
    'Автоматически отправляет клиентам, когда они не посетили ваш бизнес в течение указанного количества дней',
  'table.cancelReason.name': 'Причины отмены',
  'table.cancelReason.addDate': 'Дата добавления',
  'btn.new.cancelReason': 'Новая Причина Отмены',
  'modal.newCancelReason.title': 'Новая Причина Отмены',
  'modal.editCancelReason.title': 'Редактировать Причину Отмены',
  'input.cancelReason.title': 'Название Причины отмены',
  'input.cancelReason.placeholder': 'e.g. Клиент недоступен',
  'table.discounts.name': 'Скидка',
  'table.discounts.value': 'Стоимость',
  'table.discounts.addDate': 'Дата добавления',
  'input.discountName.label': 'Название скидки',
  'input.discountName.placeholder': 'e.g. Пенсионер',
  'input.discountValue.label': 'Значение скидки',
  'btn.new.discount': 'Новая скидка',
  'input.discountPercentage.label': '% Процент',
  'input.discountSum.label': 'Сумма',
  'modal.newDiscount.title': 'Новая скидка',
  'modal.editDiscount.title': 'Редактировать скидку',
  'modal.deleteAccount.title': 'Удалить аккаунт',
  'modal.deleteAccount.description':
    'Удаление аккаунта - это необратимый процесс, и все ваши данные будут удалены навсегда.',
  'modal.deleteAccount.successMessage': 'Ваш аккаунт был успешно удален.',
  'title.mySettings': 'Мои настройки',
  'title.personalDetails': 'Личные данные',
  'title.personalDetails.description':
    'Сохраните свое имя и контактную информацию, введенный здесь емаил используется для входа в систему.',
  'btn.continueWithGoogle.title': 'Продолжить с Google',
  'btn.continueWithFacebook.title': 'Продолжить с Facebook',
  'notification.googleAccountUnlinked': 'Аккаунт Google не привязан',
  'notification.googleAccountLinked': 'Связанный аккаунт Google',
  'notification.facebookAccountUnlinked': 'Аккаунт Facebook не привязан',
  'notification.facebookAccountLinked': 'Связанный аккаунт Facebook',
  'btn.linkAccount': 'Связать аккаунт',
  'btn.unlinkAccount': 'Отвязать аккаунт',
  'btn.deleteYourAccount': 'Удалить аккаунт',
  'input.myName.label': 'Имя',
  'input.myName.placeholder': 'e.g. Андрей',
  'input.mylastName.label': 'Фамилия',
  'input.mylastName.placeholder': 'e.g. Попов',
  'title.changePassword': 'Измени пароль',
  'title.changePassword.description':
    'Чтобы сделать обновление, введите свой существующий пароль, а затем новый. Если вы не знаете свой существующий пароль, вы можете выйти из системы и воспользоваться ссылкой «Сбросить пароль» на странице входа.',
  'input.currentPassword.label': 'Текущий пароль',
  'label.currentPassword.placeholder': 'Введите Текущий пароль',
  'input.newPassword.label': 'Новый пароль',
  'input.newPassword.placeholder': 'Введите Новый пароль',
  'input.verifyPassword.label': 'Повторите пароль',
  'input.verifyPassword.placeholder': 'Повторите Новый пароль',
  'btn.confirmDelete': 'Да, Удалить сейчас',
  'btn.confirm': 'Подтвердить',
  'btn.confirmBook': 'Подтвердить и забронировать',
  'btn.confirmPay': 'Подтвердить и оплатить',
  'btn.delete': 'Удалить',
  'btn.close': 'Закрыть',
  'validation.required': 'Пожалуйста, заполните это поле',
  'validation.email.format': 'Неверный формат электронной почты',
  'validation.url.format': 'Неверный формат url, должен начинаться с https:// или http://',
  'validation.password.length': 'Пароль должен содержать не менее 7 символов',
  'validation.password.letters': 'Пароль должен содержать цифру и букву',
  'validation.startTime': 'Время начала не может быть позже или совпадать с временем окончания',
  'validation.endTime': 'Время окончания не может быть раньше или совпадать с временем начала',
  'tooltip.password.title': 'Пароль должен:',
  'tooltip.password.characters': 'Иметь не менее 7 символов',
  'tooltip.password.number': 'Содержать цифру',
  'tooltip.password.letter': 'Содержать букву',
  'tooltip.location': 'Location tooltip',
  'footer.support.center': 'Нужна помощь? ',
  'not.found': 'Не найдено',
  'input.cancelReason.label': 'Причина отмены',
  'input.location.label': 'Локация',
  'input.location.placeholder': 'Название локации',
  'input.name.label': 'Название',
  'title.recentlyAddedClients': 'Клиенты (недавно добавленные)',
  'title.calendarView': 'Вид Календаря',
  'text.manageProfile': 'Управление профиля пользователя',
  'modal.language.title': 'Выберите язык',
  'modal.language.placeholder': 'Поиск языка',
  'dropdown.access.medium': 'Средний',
  'dropdown.access.high': 'Высокий',
  'dropdown.access.low': 'Низкий',
  'dropdown.access.basic': 'Базовый',
  'dropdown.access.no': 'Нет доступа',
  'dropdown.access.owner': 'Владелец',
  'button.select.all': 'Выбрать все',
  'button.selected': 'Выбранно',
  'dropdown.gender.unknown': 'Неизвестно',
  'dropdown.gender.male': 'Мужской',
  'dropdown.gender.female': 'Женский',
  'dropdown.notificationBy.none': 'Не отправлять',
  'dropdown.notificationBy.sms': 'SMS',
  'dropdown.notificationBy.email': 'Эл.почте',
  'dropdown.notificationBy.combined': 'SMS и Эл.почте',
  'notification.success.login': 'Успешно авторизован!',
  'notification.success.register': 'Успешно зарегистрирован!',
  'dropdown.time.minutes': 'минуты',
  'dropdown.repeat.no': 'Не повторят',
  'dropdown.repeat.weekly': 'Еженедельно',
  'dropdown.endRepeat.ongoing': 'Постоянно',
  'dropdown.endRepeat.weekly': 'До определенной даты',
  'dropdown.time.appointment.immediately': 'Немедленно',
  'dropdown.time.appointment.upTo1Hour': '1 час до записи',
  'dropdown.time.appointment.upTo2Hours': '2 часа до записи',
  'dropdown.time.appointment.upTo3Hours': '3 часа до записи',
  'dropdown.time.appointment.upTo4Hours': '4 часа до записи',
  'dropdown.time.appointment.upTo5Hours': '5 часов до записи',
  'dropdown.time.appointment.upTo6Hours': '6 часов до записи',
  'dropdown.time.appointment.upTo7Hours': '7 часов до записи',
  'dropdown.time.appointment.upTo8Hours': '8 часов до записи',
  'dropdown.time.appointment.upTo9Hours': '9 часов до записи',
  'dropdown.time.appointment.upTo10Hours': '10 часов до записи',
  'dropdown.time.appointment.upTo11Hours': '11 часов до записи',
  'dropdown.time.appointment.upTo12Hours': '12 часов до записи',
  'dropdown.time.appointment.upTo13Hours': '13 часов до записи',
  'dropdown.time.appointment.upTo14Hours': '14 часов до записи',
  'dropdown.time.appointment.upTo15Hours': '15 часов до записи',
  'dropdown.time.appointment.upTo16Hours': '16 часов до записи',
  'dropdown.time.appointment.upTo17Hours': '17 часов до записи',
  'dropdown.time.appointment.upTo18Hours': '18 часов до записи',
  'dropdown.time.appointment.upTo19Hours': '19 часов до записи',
  'dropdown.time.appointment.upTo20Hours': '20 часов до записи',
  'dropdown.time.appointment.upTo21Hours': '21 часов до записи',
  'dropdown.time.appointment.upTo22Hours': '22 часов до записи',
  'dropdown.time.appointment.upTo23Hours': '23 часов до записи',
  'dropdown.time.appointment.upTo24Hours': 'До 1 дня',
  'dropdown.time.appointment.upTo48Hours': 'До 2 дней',
  'dropdown.time.appointment.upTo3days': 'До 3 дней',
  'dropdown.time.appointment.upTo4days': 'До 4 дней',
  'dropdown.time.appointment.upTo5days': 'До 5 дней',
  'dropdown.time.appointment.upTo6days': 'До 6 дней',
  'dropdown.time.appointment.upTo1week': 'До 1 недели',
  'dropdown.time.appointment.upTo2weeks': 'До 2 недель',
  'dropdown.time.appointment.upTo15Min': '15 минут до записи',
  'dropdown.time.appointment.upTo30Min': '30 минут до записи',
  'dropdown.time.appointment.upTo1Month': 'До 1 месяца',
  'dropdown.time.appointment.upTo2Months': 'До 2 месяцев',
  'dropdown.time.appointment.upTo3Months': 'До 3 месяцев',
  'dropdown.time.appointment.upTo4Months': 'До 4 месяцев',
  'dropdown.time.appointment.upTo5Months': 'До 5 месяцев',
  'dropdown.time.appointment.upTo6Months': 'До 6 месяцев',
  'dropdown.time.appointment.interval5Min': '5 минут',
  'dropdown.time.appointment.interval10Min': '10 минут',
  'dropdown.time.appointment.interval15Min': '15 минут',
  'dropdown.time.appointment.interval20Min': '20 минут',
  'dropdown.time.appointment.interval30Min': '30 минут',
  'dropdown.time.appointment.interval40Min': '40 минут',
  'dropdown.time.appointment.interval45Min': '45 минут',
  'dropdown.time.appointment.interval50Min': '50 минут',
  'dropdown.time.appointment.interval60Min': '60 минут',
  'dropdown.time.appointment.interval75Min': '75 минут',
  'dropdown.time.appointment.interval90Min': '90 минут',
  'dropdown.time.appointment.interval120Min': '120 минут',
  'dropdown.time.appointment.interval150Min': '150 минут',
  'dropdown.time.appointment.interval180Min': '180 минут',
  'dropdown.time.appointment.interval240Min': '240 минут',
  'dropdown.time.appointment.anyTime': 'В любое время',
  'dropdown.time.appointment.disabled': 'Отключено',
  'dropdown.time.appointment.doesntRepeat': 'Не повторяется',
  'dropdown.time.appointment.daily': 'Ежедневно',
  'dropdown.time.appointment.every2Days': 'Каждые 2 дня',
  'dropdown.time.appointment.every3Days': 'Каждые 3 дня',
  'dropdown.time.appointment.every4Days': 'Каждые 4 дня',
  'dropdown.time.appointment.every5Days': 'Каждые 5 дней',
  'dropdown.time.appointment.every6Days': 'Каждые 6 дней',
  'dropdown.time.appointment.every7Days': 'Каждые 7 дней',
  'dropdown.time.appointment.weekly': 'Еженедельно',
  'dropdown.time.appointment.every2weeks': 'Каждые 2 недели',
  'dropdown.time.appointment.every3weeks': 'Каждые 3 недели',
  'dropdown.time.appointment.every4weeks': 'Каждые 4 недели',
  'dropdown.time.appointment.every5weeks': 'Каждые 5 недель',
  'dropdown.time.appointment.every6weeks': 'Каждые 6 недель',
  'dropdown.time.appointment.every7weeks': 'Каждые 7 недель',
  'dropdown.time.appointment.every8weeks': 'Каждые 8 ​​недель',
  'dropdown.time.appointment.every9weeks': 'Каждые 9 недель',
  'dropdown.time.appointment.every10weeks': 'Каждые 10 недель',
  'dropdown.time.appointment.monthly': 'Ежемесячно',
  'dropdown.time.appointment.every2months': 'Каждые 2 месяца',
  'dropdown.time.appointment.every3months': 'Каждые 3 месяца',
  'dropdown.time.appointment.every4months': 'Каждые 4 месяца',
  'dropdown.time.appointment.every5months': 'Каждые 5 месяцев',
  'dropdown.time.appointment.every6months': 'Каждые 6 месяцев',
  'dropdown.time.appointment.every7months': 'Каждые 7 месяцев',
  'dropdown.time.appointment.every8months': 'Каждые 8 ​​месяцев',
  'dropdown.time.appointment.every9months': 'Каждые 9 месяцев',
  'dropdown.time.appointment.every10months': 'Каждые 10 месяцев',
  'dropdown.time.appointment.every11months': 'Каждые 11 месяцев',
  'dropdown.time.appointment.after2times': 'Через 2 раза',
  'dropdown.time.appointment.after3times': 'Через 3 раза',
  'dropdown.time.appointment.after4times': 'Через 4 раза',
  'dropdown.time.appointment.after5times': 'Через 5 раз',
  'dropdown.time.appointment.after6times': 'Через 6 раз',
  'dropdown.time.appointment.after7times': 'Через 7 раз',
  'dropdown.time.appointment.after8times': 'Через 8 раз',
  'dropdown.time.appointment.after9times': 'Через 9 раз',
  'dropdown.time.appointment.after10times': 'После 10 раз',
  'dropdown.time.appointment.after11times': 'Через 11 раз',
  'dropdown.time.appointment.after12times': 'Через 12 раз',
  'dropdown.time.appointment.after13times': 'Через 13 раз',
  'dropdown.time.appointment.after14times': 'Через 14 раз',
  'dropdown.time.appointment.after15times': 'Через 15 раз',
  'dropdown.time.appointment.after20times': 'Через 20 раз',
  'dropdown.time.appointment.after25times': 'Через 25 раз',
  'dropdown.time.appointment.after30times': 'Через 30 раз',
  'dropdown.time.appointment.specificDate': 'Конкретная дата',
  'modal.createHours.title': 'Добавить {0} рабочее время',
  'modal.editHours.title': 'Редактировать {0} рабочее время',
  'modal.deleteItem.confirm.0': 'Вы уверены, что хотите навсегда ',
  'modal.deleteItem.confirm.1': 'удалить',
  'modal.deleteItem.confirm.2': 'из базы данных ?',
  'modal.entity.category': 'Категория',
  'modal.entity.referal': 'Источник рефералов',
  'modal.entity.resource': 'Ресурс',
  'modal.entity.location': 'Локация',
  'modal.deleteReminder.title': 'Удалить напоминание',
  'modal.deleteMessage.title': 'Удалить сообщение',
  'modal.deleteReminder.description': 'Вы уверены, что хотите удалить это напоминание ?',
  'modal.deleteMessage.description': 'Вы уверены, что хотите удалить это сообщение ?',
  'modal.deleteReminder.confirm': 'Да, удалить',
  'modal.deletePaymentMethod.title': 'Удалить метод оплаты',
  'modal.deletePaymentMethod.description': 'Данный метод оплаты будет удален навсегда',
  'closedDate.create.modal.title': 'Добавить нерабочие дни ',
  'closedDate.edit.modal.title': 'Редактировать Нерабочие дни',
  'staff.access.owner': 'Владелец',
  'staff.access.high': 'Высокий',
  'staff.access.medium': 'Средний',
  'staff.access.low': 'Низкий',
  'staff.access.basic': 'Базовый',
  'staff.access.no_access': 'Нет доступа',
  'staff.create.title': 'Добавить нерабочие дни ',
  'staff.emptyState.title': 'Нет штатных сотрудников по расписанию.',
  'staff.emptyState.description': 'См. раздел «Сотрудники», чтобы добавить рабочее время.\n',
  'staff.viewStaffMembers.button': 'Посмотреть сотрудников',
  'staff.picture.changePhoto': 'Измени фотографию',
  'staff.picture.removePhoto': 'Удалить фото',
  'input.clientSearch.placeholder': 'Поиск по имени, почте и телефону',
  'input.blockedDate.label': 'Дата',
  'appointment.notes.title': 'Заметки ',
  'appointment.history.title': 'История',
  'appointment.history.by.title': ' на {0}',
  'appointment.log.created.label': 'Созданнo',
  'appointment.log.updated.label': 'Обновлено',
  'appointment.log.rescheduled.label': 'Редактированнщ',
  'appointment.log.cancelled.label': 'Отменено',
  'appointment.log.changed.label': 'Статус изменен',
  'appointment.notificationLog.rescheduling': 'Уведомление о переносе отправлено',
  'appointment.notificationLog.cancellation': 'Уведомление об отмене отправлено',
  'appointment.notificationLog.confirmation': 'Уведомление о подтверждении отправлено',
  'appointment.notificationLog.reminder': 'Напоминание отправлено',
  'appointment.notificationLog.thankYou': 'Уведомление с благодарностью отправлено',
  'appointment.notificationLog.lastVisit': 'Уведомление о последнем посещении отправлено',
  'state.cancelReason.title': 'Установить причины отмены',
  'state.cancelReason.description': 'Причины отмены помогают вам отследить, почему ваши клиенты не явились на прием.',
  'state.cancelReason.button': 'Добавить причину отмены',
  'state.resources.title': 'Управляйте своими ресурсами',
  'state.resources.description':
    'Бизнес становится более успешным, когда вы знаете о доступных ресурсах. Следите за комнатами или любой другой бытовой техникой, чтобы обеспечить бесперебойное обслуживание.',
  'state.resources.button': 'Добавить ресурс',
  'state.referralSource.title': 'Управляйте источники рефералов',
  'state.referralSource.description':
    'Источник указывает, как клиенты находят ваш бизнес. Следите за ними и используйте эту информацию, чтобы увеличить видимость вашего бизнеса.',
  'state.referralSource.button': 'Добавить источник рефералов',
  'state.locations.title': 'Установите ваши локаций',
  'state.locations.description':
    'Здесь вы можете управлять несколькими точками своего бизнеса, устанавливать разное рабочее время для своих сотрудников для каждой локаций и  индивидуальные календари для каждого.',
  'state.staff.title': 'Управляйте своим персоналом',
  'state.staff.description':
    'Создайте профили для своих сотрудников, чтобы вы могли добавлять и редактировать их расписания, а также отслеживать их записи и рабочее время.',
  'state.messageLog.title': 'Следите за сообщениями',
  'state.messageLog.description':
    'Сообщения напоминают вашим клиентам о предстоящих записях или информируют их об изменениях. Здесь вы сможете отслеживать сообщения и их статус. Чтобы настроить автоматические сообщения, перейдите на',
  'state.messageLog.link': 'Настройки уведомлений.',
  'state.clients.title': 'Администрирование ваших клиентов',
  'state.clients.description':
    'Забота о ваших клиентах начинается с того, что в их профилях содержится вся необходимая информация для бесперебойной работы и возможности связаться с ними.',
  'state.clients.button': 'добавить клиента',
  'state.services.title': 'Установить группы и услуги',
  'state.services.description':
    'Группы необходимы, поскольку они позволяют группировать аналогичные услуги, которые вы предоставляете. Кроме того, они делают ваш календарь чистым и легко доступным!',
  'state.services.button': 'Добавить группу услуг',
  'state.calendar.title': 'Нет работающего персонала',
  'state.calendar.description': 'Чтобы установить рабочее время персонала, см.',
  'state.calendar.button': 'Отдел персонала',
  'state.closedDates.title': 'Нерабочие дни',
  'state.closedDates.description':
    'Добавте дни когда ваш бизнес закрыт, по причине отдыха, обслуживания или по любой другой причине. Клиенты не смогут в эти дни делат онлайн записи. ',
  'state.closedDates.button': 'Добавить Нерабочие дни',
  'title.billingPlan': 'План и счета',
  'btn.upgrade': 'Поменять план ',
  'header.usage': 'ИСПОЛЬЗОВАНИЕ',
  'header.dropdown.subscription.plan': '{0} План',
  'header.dropdown.subscription.staff': '({0} Сотрудников)',
  'header.dropdown.notifications.description': '{0} сообщений',
  'header.paymentDetails': 'Посмотреть детали оплаты',
  'title.billingPlan.description':
    'Управляйте своими учетными записями. Детали плана и счетов. Просморт переводов и фактур. ',
  'plan.free.title': 'Бесплатный',
  'plan.individual.title': 'Индивидуальный',
  'plan.business.title': 'Бизнес',
  'btn.upgradeNow': 'Выбирать',
  'btn.getStarted': 'Начало работы',
  'btn.currentPlan': 'Текущий план',
  'plan.free.option.0': '1 Локация',
  'plan.free.option.1': 'До 5 сотрудников',
  'plan.free.option.2': 'Неограниченные назначения',
  'plan.free.option.3': 'Неограниченные клиенты',
  'plan.free.option.4': 'Неограниченные онлайн -бронирования',
  'plan.free.option.5': 'Уведомления по электронной почте',
  'plan.premium.option.0': 'Неограниченные Локаций',
  'plan.premium.option.1': 'Неограниченные назначения',
  'plan.premium.option.2': 'Неограниченные клиенты',
  'plan.premium.option.3': 'Неограниченные онлайн -бронирования',
  'plan.premium.option.4': 'Повторяющиеся назначения',
  'plan.premium.option.5': 'Уведомления по эл. почте',
  'plan.premium.option.6': 'SMS уведомления*',
  'plan.premium.option.7': 'WhatsApp Уведомления*',
  'plan.premium.option.8': 'Неограниченная аналитика',
  'plan.premium.option.9': 'Неограниченные отчеты',
  'plan.premium.option.10': 'Печать расписаний',
  'plan.premium.option.11': 'Експорт клиентов',
  'plan.premium.option.12': 'Приоритетная поддержка',
  'plan.premium.option.13':
    'Из электронных писем удален брендинг Plandok (бесплатные электронные письма на базе Plandok).',
  'plan.premium.costsInfo': '*Расходы на SMS/WhatsApp не включены в план, взимается дополнительная плата.',
  'plan.premium.activeUntilDate': 'Активен до {0}',
  'period.perMonth': 'в месяц (без НДС)',
  'plan.premium.description': 'Цена будет увеличиваться при добавлении большего количества сотрудников.',
  'plan.premium.viewPricing': 'Посмотреть цены',
  'plan.premium.numberStuff': 'Ваше текущее количество сотрудников: {0}',
  'premium.membership': 'Стоимость премиум-членства может варьироваться в зависимости от количества ваших сотрудников.',
  'premium.price.perMonth': 'Премиум цена в месяц',
  'pricing.calculated': 'Цена рассчитывается следующим образом:',
  'pricing.calculator': 'Калькулятор цен',
  'pricing.numberStuff': 'Количество сотрудников',
  'pricing.extra.member': '{0} за каждого дополнительного члена',
  'modal.title.pricing': 'Цены',
  'plan.foreverFree': 'Бесплатно навсегда',
  'plan.individual.0': 'Все из бесплатного плана, плюс',
  'plan.individual.1': 'Индивидуальные уведомления',
  'plan.individual.2': 'Отсутствие брендинга Plandok',
  'plan.individual.option.3': 'Неограниченное кол-во смс уведомлений',
  'plan.individual.4': 'Приоритетная поддержка',
  'plan.business.0': 'Все из Индивидуального, плюс ',
  'plan.business.1': 'Неограниченное кол-во лакаций',
  'plan.business.2': 'Неограниченное кол-во персонала',
  'block.billingInfo.title': 'Информация о счетах',
  'block.carInfo.title': 'Информация о карте',
  'input.cardNumber.label': 'Номер карты',
  'input.cardNumber.placeholder': '1235 5678 3456 2456',
  'input.expireDate.label': 'Срок годности',
  'input.expireDate.placeholder': '05 / 21',
  'input.cvv.label': 'CVV',
  'input.cvv.placeholder': '123',
  'modal.confirmCancel.subscription': 'Вы уверены, что хотите отменить абонимент? ',
  'modal.confirmCancel.subscription.description':
    'Как только вы это сделаете, вы вернетесь к использованию бесплатной версии.',
  'btn.cancelSubscription.confirm': 'Да, отменить мой абонимент ',
  'loading.processingSubscribtion': 'Ваш платеж обрабатывается. Пожалуйста, подождите...',
  'state.serviceGroup.description': 'Нет услуг в группе',
  'state.serviceGroup.btn': 'Добавить Услугу',
  'appointment.status.new': 'Новая запись',
  'appointment.status.confirmed': 'Подтвержденно',
  'appointment.status.notShow': 'Не явился',
  'appointment.status.completed': 'Завершенно',
  'appointment.status.cancelled': 'отменен',
  'billing.tab.plan': 'План',
  'billing.tab.costs': 'Предполагаемые затраты',
  'billing.tab.billing': 'Платежная информация',
  'billing.tab.invoice': 'Счета',
  'plan.free': 'Бесплатно',
  'plan.deactivated.title': 'Премиум был деактивирован',
  'plan.active.title': 'Активировать',
  'payment.step.title': 'Платежи',
  'payment.error.title': 'Обновить оплату',
  'payment.payNow.title': 'Платить',
  'payment.dismiss.title': 'Увольнять',
  'payment.tab.title.sub': 'Подписка',
  'payment.tab.subTitle': 'Премиум план',
  'payment.per.members': 'на сотрудника',
  'payment.tab.title.notification': 'Уведомления',
  'payment.card.details': 'Детали карты',
  'payment.success.message': 'Платеж прошел успешно',
  'payment.payWithCard.title': 'Оплатить картой',
  'payment.cardNumber.title': 'Номер карты',
  'payment.expirationDate.title': 'Дата истечения срока действия',
  'payment.digits.title': '(3 цифры)',
  'payment.amount': 'Сумма к оплате:',
  'payment.btn.pay': 'Оплатить',
  'invoice.table.date': 'Дата',
  'invoice.table.invoice': 'Счет',
  'invoice.table.download': 'Скачать',
  'invoice.table.status': 'Статус',
  'costs.billingPeriod.title': 'Предполагаемые расходы за этот расчетный период',
  'costs.billingPeriod.description':
    'Это текущие расходы на использование в этот расчетный период. Разбивка ваших расходов доступна ниже.',
  'costs.billingPeriod.additionalInfo':
    'Как только ваши расходы на уведомления достигнут 50 евро, они будут списаны автоматически. Но не беспокойтесь, если сумма меньше - она будет удобно включена в вашу ежемесячную подписку.',
  'costs.summary.title': 'Сводка за месяц',
  'costs.summary.description': 'Эти сборы учитываются в балансе вашего счета',
  'costs.summary.detailedCharges': 'Подробные расходы',
  'costs.summary.noData': 'Нету данных',
  'costs.summary.quantity': 'Количество',
  'costs.summary.subtotal': 'Промежуточный итог',
  'costs.summary.taxes': 'Налоги',
  'dropdown.time.hours': 'часов',
  'message.planUpgrade': 'Вы успешно обновили свой план!',
  'costs.subscription': 'Подписка',
  'btn.cancelSubscription': 'Отмена плана',
  'btn.no': 'Нет',
  'notification.success.update': 'Успешно обновлено',
  'validation.password.match': 'Пароли не совпадают',
  'blocked.time': 'Блок времени',
  'message.successReset.password':
    'Письмо для сброса пароля было отправлено, пожалуйста, проверьте свой почтовый ящик.',
  'title.newPassword': 'Введите новый пароль',
  'title.newPassword.description': 'Безопасный доступ к вашей учетной записи, создав новый пароль',
  'input.newPasswordRepeat.label': 'Подтвердите ваш новый пароль',
  'btn.change.password': 'Изменить свой пароль',
  'text.havingTrouble': 'Возникли проблемы?',
  'link.resetPassword.again': 'Сбросить пароль еще раз',
  'message.successNew.password': 'Пароль успешно изменен.',
  'title.repeatShifts': 'Повторяющаяся Смена',
  'text.confirmShift.update':
    'Вы редактировали смену, которая повторяется еженедельно. Удаление предстоящих смен будет перезаписывать {0} текущего {0} графика',
  'text.confirmShift.delete':
    'Вы удаляете смену, которая повторяется еженедельно. Удаление предстоящих смен будет перезаписывать {0} текущего {0} графика',
  'btn.deleteUpcoming.shifts': 'Удалить предстоящие смены',
  'btn.deleteThis.shift': 'Удалить только эту смену',
  'btn.updateUpcoming.shifts': 'Обновить предстоящие смены',
  'btn.updateThis.shift': 'Обновить только эту смену',
  'text.permisionTab':
    'Укажите, какие разделы доступны для каждого уровня пользователя. Все вошедшие в систему сотрудники могут получить доступ к календарю, а учетные записи владельцев имеют полный доступ к системе.',
  'title.permission.locations': 'Локаций',
  'title.permission.booking': 'Записи и Клиенты',
  'title.permission.staff': 'Сотрудники',
  'title.permission.setup': 'Настроики',
  'title.permission.basic': 'Базовый',
  'title.permission.low': 'Низкий',
  'title.permission.med': 'Средний',
  'title.permission.high': 'Высокий',
  'title.permission.owner': 'Владелец',
  'title.allLocations.permission': 'Доступ ко всем локациям',
  'title.accessCalendar.permission': 'Доступ к собственному календарю',
  'title.accessOtherCalendar.permission': 'Доступ к календарям персонала',
  'title.canBook.permission': 'Бронировать записи',
  'title.contactInfo.permission': 'Видеть контактную информацию клиента',
  'title.clients.permission': 'Клиенты',
  'title.messages.permission': 'Сообщения',
  'title.services.permission': 'Услуги',
  'title.workingHours.permission': 'Время работы',
  'title.staffMembers.permission': 'Сотрудники',
  'title.permissionLevels.permission': 'Уровни разрешений',
  'title.accountSetup.permission': 'Настройки Аккаунта',
  'title.clientSettings.permission': 'Настройки Клиента',
  'title.onlineBookings.permission': 'Онлайн-бронирование',
  'title.analytics.permission': 'Аналитика',
  'title.marketing.permission': 'Маркетинг',
  'notification.type.reminder': 'Напоминания',
  'notification.type.confirmation': 'Подтверждения',
  'notification.type.online_booking_confirmation': 'Подтверждение бронирования онлайн',
  'notification.type.rescheduling': 'Перепланирования',
  'notification.type.cancellation': 'Отмены',
  'notification.type.thank_you': 'Благодарности',
  'notification.type.last_visit': 'Последнее посещение',
  'notification.channel.sms': 'SMS',
  'notification.channel.email': 'Email',
  'notification.channel.whatsapp': 'WhatsApp',
  'notification.status.sent': 'Отправлено',
  'notification.status.pending': 'Ожидается',
  'notification.status.failed': 'Не удалось',
  'dropdown.time.days': 'дней',
  'title.appointment': 'запись',
  'input.reminderBeforeLastVisit.label': 'Уведомление будет отправлено после указанного количествa дней',
  'txt.with': 'с',
  'short.hours': 'час',
  'short.minutes': 'мин',
  'input.registrationNumber.label': 'Номер компании',
  'input.vatNumber.label': 'Номер НДС',
  'invoice.table.priceTaxIncl': 'Сумма с НДС',
  'error.wrongSenderId':
    'Идентификатор отправителя должен содержать цифры или символы, длина должна быть больше 3 и меньше 11 символов',
  'sidebar.help': 'Помощь',
  'modal.help.title': 'Как мы можем помочь?',
  'modal.help.liveChat.title': 'Живой чат',
  'modal.help.liveChat.description': 'Есть вопросы? Просто задайте их нам прямо сейчас',
  'modal.help.suggestFeature.title': 'Предложите функцию',
  'modal.help.suggestFeature.description':
    'Поделитесь предложениями, посмотрите предложения других и проголосуйте за нужные вам функции',
  'modal.help.viewUpdates.title': 'Просмотр обновлений',
  'modal.help.viewUpdates.description': 'Ознакомьтесь с нашими последними обновлениями и улучшениями',
  'warning.title.paidFeature': 'Платная функция',
  'warning.smsFeatureMissing': 'Чтобы получить доступ к SMS, необходимо обновить подписку. Пожалуйста, посетите ',
  'warning.link.billingAndPricing': 'страницу плана',
  'warning.newPage.0': 'обновлен!',
  'warning.newPage.1': 'Если вы использовали старую систему Plandok, ваш пароль больше не будет работать, пожалуйста',
  'warning.newPage.2': 'смените пароль',
  'warning.newPage.3':
    'чтобы вы могли ползоватся новой системой. Пожалуйста, свяжитесь с нами для дополнительных вопросов',
  'warning.newPage.4': 'или',
  'title.blockTime.select': 'Выберите время',
  'calendar.allResources': 'Все ресурсы',
  'appointment.edit.title': 'Редактировать Запись',
  'appointment.create.title': 'Запись успешно создана.',
  'appointment.update.title': 'Запись успешно обновлена.',
  'select.notify.title': '{0} не работает в выбранном интервале, но вы можете бронировать.',
  'booking.notify.title': 'Выбранное время забронировано, но вы можете создать встречу.',
  'textArea.characters.left': 'осталось символов',
  'textArea.characters.exceeded': 'предел превышен',
  'validation.characters.exceeded': 'Превышен лимит символов',
  'staff.menu.members': 'Сотрудники',
  'staff.menu.dates': 'Нерабочие дни',
  'staff.menu.working': 'Время работы',
  'staff.menu.permissions': 'Уровень доступа',
  'staff.menu.members.description': 'Добавите или удалите сотрудников, контролируйте уровень доступа',
  'staff.menu.dates.description': 'Спланируйте нерабочие дни и выходные',
  'staff.menu.working.description': 'Управляйте и контролируите графики работы персонала',
  'staff.menu.permissions.description': 'Укажите, какие разделы доступны для каждого уровня пользователя',
  'message.successUpdateLocation': 'Готово! Профиль местоположения успешно обновлен.',
  'ob.title': 'Онлайн-запись',
  'ob.overview.tab': 'Обзор',
  'ob.massMessages.tab': 'Массовые сообщения',
  'ob.campaigns.tab': 'Кампании',
  'ob.profile.tab': 'Онлайн профиль',
  'ob.links.tab': 'Ссылки',
  'ob.settings.tab': 'Настройки',
  'ob.payments.tab': 'Платежи',
  'ob.status.online': 'Онлайн',
  'ob.status.offline': 'Выключено',
  'ob.status.title': 'Статус',
  'ob.profile.step': '{0} из 4',
  'ob.profile.step.title': 'Профиль и местоположение',
  'ob.profile.step.description':
    'Раскажите своим клиентам кратко о своем бизнесе и услугах, не забудьте оставить номер телефона, по которому клиенты могут связаться с вами для получения дополнительной информации! Введите адрес вашего бизнеса, этот адрес будет отображон в вашем онлайн профиле и виден клиентам при записи онлайн.',
  'ob.overview.placheholder.title': 'Онлайн-запись',
  'ob.overview.placheholder.description':
    'Создайте свой профиль и позвольте клиентам самим бронировать записи онлайн, добавте ссылку на свой собственный сайт и расличные соц. сети. ',
  'ob.overview.start.now': 'Создать онлайн профиль',
  'ob.links.direct.title': 'Ссылка',
  'ob.links.facebook.title': 'Facebook Ссылка',
  'ob.links.instagram.title': 'Instagram Ссылка',
  'ob.links.direct.description':
    'Все очень просто, просто воспользуйтесь этой ссылкой, где вам удобно. Будь то ваш сайт или фасебоок, клиенты будут переходить к вашему онлайн-профилю по этой ссылке и иметь возможность зарегистрироваться онлайн.',
  'ob.links.facebook.description':
    'Здесь вы найдете инструкции как установить ссылку на свой Facebook профиль, чтобы клиенты могли зарегистрироваться онлайн',
  'ob.links.instagram.description':
    'Все очень просто, используйте эту ссылку, где вам угодно. Клиенты по сылке смогут зарегистрироватся онлайн ',
  'ob.analytics.tab.description':
    'Добавьте Google Analytics и Facebook Pixel на вашу страницу онлайн-бронирования для анализа трафика и поведения пользователей.',
  'ob.analytics.google.title': 'Google Analytics',
  'ob.analytics.facebook.title': 'Facebook Pixel',
  'ob.analytics.google.description':
    'С помощью Google Analytics вы можете отслеживать и анализировать посещаемость сайта и поведение пользователей, чтобы получить представление о том, как улучшить ваш сайт.',
  'ob.analytics.active': 'Активный',
  'ob.analytics.inactive': 'Неактивный',
  'ob.analytics.setUp.btn': 'Установить',
  'ob.analytics.google.description.1':
    'Google Analytics - это мощный инструмент, который поможет вам отслеживать посещаемость сайта и поведение пользователей. С помощью Google Analytics вы можете отслеживать количество посетителей вашего сайта, откуда они приходят, какие страницы посещают и как долго остаются на сайте.',
  'ob.analytics.google.description.2':
    'Кроме того, Google Analytics позволяет устанавливать цели и отслеживать беседы, что дает вам лучшее понимание того, насколько хорошо работает ваш сайт и как его можно улучшить.',
  'ob.analytics.google.trackingId': 'Идентификатор отслеживания',
  'ob.analytics.google.tooltip':
    'Чтобы найти свой идентификатор отслеживания, сначала войдите в свой аккаунт Google Analytics, выберите сайт или приложение, которое вы хотите отслеживать, а затем перейдите на вкладку "Администратор". Там нажмите на "Код отслеживания". Ваш идентификатор отслеживания будет отображаться в формате "UA-XXXX-Y".',
  'ob.analytics.google.account': 'У вас еще нет аккаунта Google Analytics?',
  'ob.analytics.click': 'Нажмите',
  'ob.analytics.here': 'здесь',
  'ob.analytics.learn': 'чтобы узнать, как его создать.',
  'ob.analytics.facebook.description.1':
    'Facebook Pixel - это инструмент, позволяющий отслеживать поведение пользователей на вашем сайте, включая конверсии, просмотры страниц и другие события.',
  'ob.analytics.facebook.description.2':
    'Facebook Pixel может предоставить вам ценные сведения о вашей аудитории, такие как демографические данные, интересы, тип устройства, просмотры страниц и совершенные действия, которые собираются, что может помочь вам лучше понять и оптимизировать работу пользователей на вашем сайте.',
  'ob.analytics.facebook.pixelId': 'Идентификатор пикселя',
  'ob.analytics.facebook.tooltip':
    "Чтобы найти свой Facebook Pixel ID, выполните следующие действия: Зайдите в свой аккаунт Facebook Ads Manager. Нажмите на кнопку 'Меню' в левом верхнем углу экрана. В разделе 'Менеджер событий' выберите 'Пиксели'. Здесь вы увидите список всех пикселей, связанных с вашим аккаунтом. Нажмите на название пикселя, для которого вам нужен идентификатор. Ваш Pixel ID будет отображаться в левом верхнем углу экрана, рядом с названием пикселя.",
  'ob.analytics.facebook.account': 'У вас еще нет бизнес-страницы Facebook?',
  'ob.analytics.google.account.mobile.1': 'У вас еще нет Google Analytics',
  'ob.analytics.google.account.mobile.2': 'аккаунта? Нажмите',
  'ob.analytics.google.account.mobile.3': 'чтобы узнать, как',
  'ob.analytics.google.account.mobile.4': 'создать его.',
  'ob.analytics.facebook.account.mobile.1': 'У вас еще нет Facebook',
  'ob.analytics.facebook.account.mobile.2': 'бизнес-страницы? Нажмите',
  'ob.analytics.facebook.account.mobile.3': 'чтобы узнать, как',
  'ob.analytics.facebook.account.mobile.4': 'её создать.',
  'ob.analytics.successfullySaved.message': 'Успешно сохранено',
  'ob.settings.booking.title': 'Доступность онлайн бронирования',
  'ob.settings.booking.description':
    'Установите время, когда клиенты могут зарегистрироваться онлайн в кратчайшие сроки и не позднее чем. Организация интервалов времени, предоставка важноий  информаций клиентам, доступ к выбору сотрдуников. ',
  'ob.settings.cancellation.title': 'Отмена и Редагирование',
  'ob.settings.cancellation.description': 'Установите время, когда клиенты могут отменить или изменить визит',
  'ob.settings.locationsUrl.title': 'Сылка URL ваших филиалов',
  'ob.settings.locationsUrl.description': 'Общая URL ссылка для бизнеса с 2 и более филиалов',
  'ob.settings.businessName.explanation': 'URL: book.plandok.com/business-name/Location2556',
  'ob.settings.notifications.title': 'Уведомления по эл.почте',
  'ob.settings.notifications.description':
    'Выбор получателей, которые будут уведомлены по электронной почте, когда клиент забронирует визит онлаийн',
  'ob.settings.required.title': 'Обязательные и необязательные поля',
  'ob.settings.required.description': 'Выберите, какие поля клиент должен будет указать при онлайн-бронировании.',
  'ob.settings.requiredField.title': 'Необходимый',
  'ob.settings.optionalField.title': 'Необязательный',
  'ob.settings.requiredField.notification.title': 'Возникла проблема с настройками',
  'ob.settings.requiredField.notification.description':
    'Извините, но хоть одно поле должно быть обязательным, кроме "заметок".',
  'ob.settings.nameField.title': 'Имя',
  'ob.settings.lastNameField.title': 'Фамилия',
  'ob.settings.phoneField.title': 'Номер телефона',
  'ob.settings.emailField.title': 'Электронная почта',
  'ob.settings.notes.label': 'Текст-заполнитель для заметок',
  'ob.settings.notes.placeholder': 'Например, Введите свои примечания',
  'ob.bookingLimit.title': 'Лимит онлайн-бронирований',
  'ob.bookingLimit.description': 'Укажите максимальное количество бронирований услуг, которое вы хотите установить.',
  'ob.bookingLimit.label': 'Ограничение бронирования услуг',
  'ob.bookingLimit.unlimited.title': 'Неограниченно',
  'ob.bookingLimit.1service.title': 'макс. 1 услуга',
  'ob.bookingLimit.2services.title': 'макс. 2 yслуги',
  'ob.bookingLimit.3services.title': 'макс. 3 yслуги',
  'ob.menu.edit.profile': 'Редактировать',
  'ob.menu.turnOff.profile': 'Выключить',
  'ob.profile.status': 'Статус',
  'ob.overview.tab.description': 'Позволте клиентам зарегистрироваться онлайн через ваш сайт, или соц.сети.',
  'ob.profile.tab.description': 'Управление онлайн-профиля, измените фотографии, описание, адрес и другую информацию.',
  'ob.links.tab.description': 'Ссылку на ваш онлаийн профиль, которыми можете делиться со своими клиентами.',
  'ob.settings.tab.description':
    'Настройки онлайн регистрации, чтобы ваши клиенты регистровались по вашим правилам и когда вам удобно.',
  'ob.payments.tab.description': 'Выберите способы оплаты, которые ваши клиенты смогут использовать при оплате услуг.',
  'ob.payments.page.description':
    'Здесь вы можете выбрать способы оплаты, которые ваши клиенты смогут использовать при оплате услуг.',
  'ob.payOnSite.title': 'Оплата на месте',
  'ob.payOnSite.description': 'Ваши клиенты смогут оплатить услуги наличными на месте.',
  'ob.payWithCard.title': 'Оплатить картой',
  'ob.payWithCard.description': 'Ваши клиенты смогут оплачивать услуги картой через приложение Stripe.',
  'ob.payWithCard.info.1': 'Вы используете бесплатный тарифный план Plandok, поэтому вам придется заплатить',
  'ob.payWithCard.info.2': 'комиссию Plandok и комиссию Stripe, используя этот способ оплаты.',
  'ob.payWithCard.info.3': ' чтобы иметь',
  'ob.payWithCard.info.4': 'Комиссионные сборы Plandok.',
  'ob.payWithCard.info.5': 'Премиум-план не освобождает от уплаты комиссионных сборов Stripe. ',
  'ob.payWithCard.info.6': 'Проверьте цены Stripe здесь',
  'ob.stripeConnected.info': '(Stripe подключен)',
  'ob.requiresSetup.info': '(Требуется настроить учетную запись Stripe)',
  'ob.connectToStripe.btn': 'Подключитесь к Stripe',
  'ob.connectToStripe.successMessage.title': 'Успешное подключение к Stripe',
  'ob.waitingAuthorization.title': 'Ожидание авторизации в Stripe',
  'ob.stripeDashboard.title': 'Все журналы платежей и возвратов доступны в ',
  'ob.stripeDashboard.url': 'Приборная панель Stripe',
  'ob.yourStripeAccount.title': 'Ваша учетная запись Stripe',
  'ob.visitStripe.option': 'Посетите Stripe',
  'ob.changeAccount.option': 'Изменить учетную запись Stripe',
  'input.earliestAppointment.label': 'Самая ранняя регистрация',
  'input.earliestAppointment.placeholder': 'Сразу перед визитом',
  'input.latestAppointment.label': 'Самая поздняя регистрация',
  'input.latestAppointment.placeholder': 'Не более чем на 6 месяцев вперед',
  'input.timeSlots.label': 'Временные интервалы',
  'input.timeSlots.placeholder': '15 минут',
  'checkbox.allowCustomers': 'Разрешить клиентам выбрать сотрудника',
  'input.importantInfo.label': 'Важная информация',
  'input.importantInfo.placeholder': 'Информация, которая будет отображаться клиенты при онлайн бронировании',
  'input.changeAppointment.label': 'Изменения в записи могут быть сделаны',
  'input.changeAppointment.placeholder': 'Всегда',
  'checkbox.sendToBooked': 'Отправить заказанному сотруднику',
  'checkbox.sendSpecificEmail': 'Отправить на конкретный адрес эл.почты',
  'input.specificEmail.label': 'Конкретный адрес электронной почты',
  'input.specificEmail.placeholder': 'Введите адрес электронной почты',
  'input.locationsUrl.label': 'Locations URL',
  'input.locationsUrl.placeholder': 'Например. Барбершоп - Иван',
  'ob.btn.save': 'Сохранить',
  'ob.btn.cancel': 'Отменить',
  'ob.btn.showLink': 'Показать ссылку',
  'ob.btn.active': 'Активно',
  'ob.btn.inactive': 'Неактивно',
  'ob.linkModal.title': 'Ссылка для онлаийн записи',
  'ob.linkModal.description': 'Вот ссылка, где ваши клиенты могут записатся онлаийн. ',
  'ob.linkModal.afterDescription':
    'Эта ссылка направляет пользователей прямо к вам. Просто одним щелчком мыши,и клиенты могут достичь ваш бизнес профиль и сделать онлаийн запись.',
  'ob.fbLinkModal.title': 'Инструкция для Facebook',
  'ob.fbLinkModal.description':
    'Добавте кнопку регистраций на свою страчку в Facebook, и клиенты буду имет возможность легко зарегистрироватся.',
  'ob.fbLinkModal.step1': 'Войдите на свою страницу в Facebook',
  'ob.fbLinkModal.step2': 'Нажмите добавить кнопку, находится недалеко к картине',
  'ob.fbLinkModal.step3': 'Выберите «Регистрация» и вставите эту ссылку',
  'ob.linkModalCopySuccess.title': 'Ссылка скопирована!',
  'ob.btn.copyLink': 'Скопировать ссылку',
  'title.appointmentSlot.select': 'Выберите время',
  'btn.back': 'Назад',
  'btn.continue': 'Дальше',
  'ob.hours.open': 'Начало',
  'ob.hours.close': 'Конец',
  'ob.hours.step.title': 'Время работы',
  'ob.hours.step.description':
    'Пусть ваши клиенты знают когда работает ваш бизнес, введите время работы. Ето всего лиш для информаций, чтобы клиенты моглы записатся онлайн, убедитесь что кажмоду сотрудники добавили время работы лично в разделе сотрудники.',
  'ob.staffHours.step.title': 'Рабочее время персонала',
  'ob.staffHours.step.description':
    'Важный шаг, пожалуйста, добавьте рабочее время для системы, чтобы узнать, когда клиенты могут забронировать онлайн.',
  'ob.location.step.title': 'Адрес',
  'ob.photo.step.title': 'Фото',
  'ob.photo.step.description':
    'Покажыте клиентам как выгладит ваш бизнес, ваши услуги. Красивие фото всегда привлекают внимание.',
  'input.file.dnd.photos': 'Загрузить фото',
  'btn.publish': 'Включать',
  'text.next': 'Дальше',
  'input.phone.number.label': 'Номер телефона',
  'input.state.label': 'Округ',
  'input.state.placeholder': 'Введите округ',
  'input.addressDetails.label': 'Дополнительная информация ',
  'input.addressDetails.placeholder': 'Введите адресные данные, например, код двери или информация для парковки и т.д.',
  'placeholder.no.photos': 'Фотографии не доступны',
  'btn.options': 'Параметры',
  'btn.list.profile': 'Включить',
  'btn.preview': 'Просмотр',
  'btn.next': 'Дальше',
  'market.ob.pleaseWait.title': 'Пожалуйста, подождите ...',
  'market.ob.location.step.title': 'Выберите место',
  'market.ob.services.step.title': 'Выберите услуги',
  'market.ob.employee.step.title': 'Выберите дату и сотрудника',
  'market.ob.timeSlot.step.title': 'Выберите дату и время',
  'market.ob.personalInfo.step.title': 'Оплата',
  'market.ob.appointmentInfo.step.title': 'Информация о записи',
  'market.ob.informationAboutYou.title': 'Информация о вас',
  'market.ob.paymentMethod.title': 'Способ оплаты',
  'market.ob.securePayment.title': 'Безопасный платеж',
  'market.ob.payWithCard.title': 'Оплатить картой',
  'market.ob.payOnSite.title': 'Оплата на месте',
  'market.ob.payOnSite.firstDescription': 'Вам нужно будет заплатить в салоне после процедуры.',
  'market.ob.payOnSite.secondDescription':
    'Небольшая просьба от нас: салоны часто являются небольшими независимыми предприятиями, поэтому если вы не можете прийти на назначенную встречу, перенесите или отмените ее.',
  'market.ob.validThru.title': 'Срок действия (месяц/год)',
  'market.ob.information.title': 'Информация',
  'market.ob.additionalInformation.title': 'Дополнительная информация ',
  'market.ob.reschedulingPolicy.title': 'Правила отмены/переноса приема',
  'market.ob.reschedulingPolicy.description':
    'Изменения в записи на прием могут быть внесены за {0} до назначенного времени.',
  'market.ob.fieldsAreRequired.title': '* Поля обязательны',
  'market.ob.step.title': 'Шаг {0} из 3',
  'market.ob.confirm.step.title': 'Просмотр и подтверждение',
  'market.ob.noServices.placeholder': 'Выберите услугу',
  'market.ob.input.firstName.label': '{0} Имя',
  'market.ob.input.firstName.placeholder': 'e.g. Иван',
  'market.ob.input.lastName.label': '{0} Фамилия',
  'market.ob.input.lastName.placeholder': 'e.g. Смит',
  'market.ob.input.phone.label': '{0} Номер мобильного телефона',
  'market.ob.input.phone.placeholder': '670 70 822',
  'market.ob.input.email.label': '{0} Адрес электронной почты',
  'market.ob.input.email.placeholder': 'ivan.smit@gmail.com',
  'market.ob.input.description.label': 'Примечания',
  'market.ob.staff.assignedSpecialist.label': 'Сотрудник',
  'market.ob.staff.noPreference.label': 'Выбрать случайным образом',
  'market.ob.timeSlots.fullyBooked.title': 'Извините, на этот день у нас нет свободного времени.',
  'market.ob.timeSlots.choseAnotherDay.title': 'Пожалуйста, выберите другой день.',
  'market.ob.timeSlots.closedSlot.title': 'Ближайший доступный день',
  'market.ob.timeSlots.selectClosest.title': 'Выберить свободный день',
  'market.ob.timeSlots.note.title': 'Важная информация',
  'market.ob.service.duration.title': 'мин',
  'market.location.workingHours.title': 'Время работы',
  'market.location.monday.title': 'Понедельник',
  'market.location.tuesday.title': 'Вторник',
  'market.location.wednesday.title': 'Среда',
  'market.location.thursday.title': 'Четверг',
  'market.location.friday.title': 'Пятница',
  'market.location.saturday.title': 'Суббота',
  'market.location.sunday.title': 'Воскресенье',
  'market.location.meetOurTeam.title': 'Познакомьтесь с нашей командой',
  'market.location.title': 'Свяжитесь с нами',
  'market.location.services.title': 'Услуги',
  'market.location.about.title': 'О сервисе',
  'market.location.aboutUs.title': 'О нас',
  'market.confirmation.appointmentCanceled.title': 'Эта встреча была отменена',
  'market.confirmation.appointmentConfirmed.title': 'Назначение подтверждено',
  'market.confirmation.appointmentConfirmed.description': 'Ваш визит подтвержден, вот подробности:',
  'market.confirmation.appointmentDate.title': 'Дата визита',
  'market.confirmation.scheduledAppointment.title': 'Отмена визита онлайн невозможна.',
  'market.confirmation.scheduledAppointment.description':
    'Если вы не можете прийти на назначенный визит, пожалуйста, перенесите его на другой день или отмените.',
  'market.modal.cancelAppointment.title': 'Вы уверены, что хотите отменить этот визит ?',
  'market.modal.cancelledAppointment.title': 'Визит был отменен',
  'market.language.changer.title': 'Язык (Language)',
  'market.btn.bookNow': 'Записаться',
  'market.servicesAvailable.title': 'услуги',
  'market.poweredByPlandok.title': 'Plandok',
  'market.poweredByPlandok.description': 'Хотите похожий сайт ? Посетите',
  'market.btn.readMore': 'Читать больше',
  'market.btn.showLess': 'Показать меньше',
  'market.btn.reviews': 'Отзывы',
  'market.btn.viewMore': 'Смотреть больше',
  'market.btn.cancelAppointment': 'Отменить визит',
  'market.btn.cancel': 'Да, отменить',
  'market.wh.closed.title': 'Закрыто',
  'market.ob.success.message': 'Регистрация успешна',
  'market.ob.cancelAppointment.success.message': 'Регистрация отменена',
  'market.ob.cancelAppointment.error.message': 'Не удалось, попробуйте еще раз',
  'market.ob.cancelAppointment.confirm.title': 'Вы уверены, что хотите отменить запись?',
  'market.location.getDirections.title': 'Получить направление',
  'market.notFound.title': 'Страница не найдена',
  'market.notFound.btn.goBack': 'Вернуться назад',
  'market.ob.quantitativeLimit.title': 'Количественное ограничение',
  'market.ob.quantitativeLimit.description':
    'Извините, но владелец ввел ограничение на количество услуг бронирования, позволяя выбрать только {0} услуг{0}',
  'market.ob.quantitativeLimit.three': 'три',
  'market.ob.quantitativeLimit.two': 'две',
  'market.ob.quantitativeLimit.one': 'одну',
  'market.ob.quantitativeLimit.serviceSingular': 'у',
  'market.ob.quantitativeLimit.servicesPlural': 'и',
  'validation.characters.minLength': 'Должно быть 3 или более символов.',
  'not.found.btn.label': 'Обратно',
  'not.found.subTitle.label': 'Извините, страница, которую вы посетили, не существует.',
  'analytics.title': 'Аналитика',
  'analytics.dashboard.tab': 'Обзор',
  'analytics.reports.tab': 'Отчеты',
  'analytics.premium.title': 'Премиум',
  'analytics.totalAppointments.title': 'Всего записей',
  'analytics.cancellationReasons.title': 'Топ причины отмены',
  'analytics.occupancy.title': 'Занятость',
  'analytics.topServices.title': 'Топ услуги',
  'analytics.clients.title': 'Клиенты',
  'analytics.completed.title': 'Завершенно',
  'analytics.notCompleted.title': 'Не завершенно',
  'analytics.canceled.title': 'Отменено',
  'analytics.noShowAppointments.title': 'Не явился',
  'analytics.reasonName.title': 'Название причины',
  'analytics.reasonCount.title': 'Счет',
  'analytics.lastPeriod.title': 'Пред. период',
  'analytics.workingHours.title': 'Рабочее время',
  'analytics.bookedHours.title': 'Забронированные время',
  'analytics.blockedHours.title': 'Заблокированные время',
  'analytics.unbookedHours.title': 'Не забронированные  время',
  'analytics.service.title': 'Услуга',
  'analytics.thisMonth.title': 'Этот период',
  'analytics.lastMonth.title': 'Пред. период',
  'analytics.totalClients.title': 'Всего клиентов',
  'analytics.averageVisits.title': 'Средние посещения клиента',
  'analytics.newClients.title': 'Новые клиенты',
  'analytics.returningClients.title': 'Повторные клиенты',
  'analytics.returnRate.title': 'Возврат клиентов',
  'analytics.clientsByGender.title': 'Клиенты по полу',
  'analytics.clientsBySource.title': 'Клиенты по источнику',
  'analytics.clientsByAge.title': 'Клиенты по возрасту',
  'analytics.timeRange.custom': 'Обычай',
  'analytics.timeRange.today': 'Сегодня',
  'analytics.timeRange.yesterday': 'Вчера',
  'analytics.timeRange.last1day': 'Последний 1 день',
  'analytics.timeRange.last7days': 'Последние 7 дней',
  'analytics.timeRange.last30days': 'Последние 30 дней',
  'analytics.timeRange.last90days': 'Последние 90 дней',
  'analytics.timeRange.last3months': 'Последние 3 месяца',
  'analytics.timeRange.lastMonth': 'Прошлый месяц',
  'analytics.timeRange.last6Months': 'Последние 6 месяцев',
  'analytics.timeRange.lastYear': 'Прошлый  1 год',
  'analytics.timeRange.last2year': 'Прошлый 2 года',
  'analytics.timeRange.thisWeek': 'На этой неделе',
  'analytics.timeRange.thisMonth': 'Этот месяц',
  'analytics.timeRange.thisYear': 'В этом году',
  'analytics.timeRange.tomorrow': 'Завтро',
  'analytics.timeRange.next7days': 'Следующие 7 дней',
  'analytics.timeRange.next30days': 'Следующие 30 дней',
  'analytics.menu.dashboard.description': 'Изучите свои бизнес-данные в диаграммах и статистике.',
  'analytics.menu.reports.description': 'Изучите свои бизнес-данные в отчетах',
  'analytics.timeRange.allTime': 'Все время',
  'analytics.timeRange.label': 'Временной интервал',
  'analytics.services.lastPeriod.label': 'Пред. период',
  'analytics.totalAppointments.appointment.label': 'Записи',
  'analytics.totalAppointments.onlineBook.label': 'Онлайн-запись',
  'analytics.gender.male': 'Мужчина',
  'analytics.gender.female': 'Женщина',
  'analytics.gender.unknown': 'Неизвестный',
  'analytics.noData': 'В выбранном периоде нет данных',
  'analytics.premium.info.title': 'Чтобы просматривать аналитику дольше, чем 7 дней активируйте премиум-версию. ',
  'analytics.premium.upgradeButton.title': 'ВКЛЮЧИТЬ',
  'analytics.premium.skipButton.title': 'Пропустить',
  'reports.appointments.title': 'Записи',
  'reports.appointments.description': 'Обзор записей, прогнозируемых доходов от предстоящих записеи и причины отмен.',
  'reports.appointmentsList.title': 'Список записей',
  'reports.appointmentsList.description': 'Отображает все записи с соответствующей информацией.',
  'reports.appointmentsSummary.title': 'Резюме записей',
  'reports.appointmentsSummary.description': 'Отображает количество записеи с персоналом и услугами.',
  'reports.appointmentsCancellations.title': 'Причины отмены',
  'reports.appointmentCancellations.title': 'Причины отмены',
  'reports.appointmentsCancellations.description':
    'Отображает все причины отмены встреч и их количество за выбранный период.',
  'reports.summary.title': 'Обзор',
  'reports.clients.title': 'Клиенты',
  'reports.clients.description':
    'Получите представление о том, как клиенты взаимодействуют с вашим бизнесом и кто тратит больше всего средств.',
  'reports.clientsList.title': 'Клиенты',
  'reports.clientsList.description': 'Список клиентов с их данными',
  'reports.exportCsv.title': 'Экспортировать CSV',
  'reports.appointmentsByStaff.title': 'Назначения сотрудников',
  'reports.appointmentsByService.title': 'Назначения по службе',
  'reports.clearFilters.button.title': 'Очистить фильтры',
  'reports.filterStaff.placeholder': 'Весь персонал',
  'reports.filterLocations.placeholder': 'Все локации',
  'reports.filters.title': 'Фильтры',
  'reports.locations.mobile.title': 'Местоположение:',
  'reports.staff.mobile.title': 'Персонал:',
  'reports.filters.apply.title': 'Применять',
  'reports.search.placeholder': 'Поиск по ссылке или клиенту',
  'reports.subscription.description': '{0} план (только {0} сотрудников)',
  'reports.notifications.description': '{0} сообщений ({0})',
  'serviceGroup.addCategory.description': 'Группы группировать подобные услуги, которые вы предоставляете.',
  'serviceGroup.addCategory.title': 'Добавить категорию',
  'app.install.title': 'Установить Пландок',
  'app.install.description.title':
    'При установке практически не используется память и можно быстро вернуться к этому приложению.',
  'app.install.button.title': 'Установить',
  'app.skip.button.title': 'Отменить',
  'app.actionUndone.button.title': 'Действие отменено',
  'app.appointmentCancelled.button.title': 'Назначение удалено',
  'app.undo.button.title': 'Отменить',
  'app.showAllTimes.button.title': 'Показать все время',
  'app.install.success.title': 'Приложение успешно установлено!',
  'rp.upgradeToPremiumLink.title': 'Обновите план',
  'rp.upgradeToPremium.title': 'использовать эту функцию',
  'rp.repeatAppointment.title': 'Повторить',
  'rp.input.frequency.title': 'Как часто',
  'rp.input.endsAfter.title': 'Конец',
  'rp.input.selectDate.title': 'Выберите дату',
  'rp.upgrade.title': 'Обновите свой план',
  'rp.upgrade.description.title':
    'Премиум-версия PLANDOK не активирована. Обновите свой план чтобы получить полный доступа к всем функциям.',
  'rp.upgrade.button.title': 'Перейти на премиум-версию',
  'rp.repeating.title': 'Повторение',
  'rp.repeats.title': 'Повторы',
  'rp.repeatsUntil.title': 'до',
  'rp.repeatingTimes.title': '({0} раз)',
  'rp.cancel.upcoming.title': 'Удалить все предстоящие повторные встречи',
  'rp.update.upcoming.title': 'Обновить все предстоящие повторные встречи',
  'rp.edit.appointment.title': 'Обновить встречу',
  'rp.edit.appointment.description.title':
    'Вы редактируете повторяющуюся встречу. Выберите, какие встречи следует обновить:',
  'rp.edit.thisAppointment.title': 'Обновить только эту встречу',
  'rp.edit.allAppointments.title': 'Обновить все предстоящие встречи',
  'rp.edit.saveAppointment.title': 'Сохранять',
  'modal.cancelSubscriptionError.title': 'Ошибка при отмене подписки',
  'modal.cancelSubscriptionError.text.1': 'Бесплатный план доступен только при наличии не более 5',
  'modal.cancelSubscriptionError.text.2': 'сотрудников',
  'modal.cancelSubscriptionError.text.3': 'и 1',
  'modal.cancelSubscriptionError.text.4':
    'Чтобы использовать бесплатную версию, удалите местоположения / сотрудников для соответствия требованиям.',
  'modal.latestPaymentFailed.title': 'Последний платеж не прошел',
  'modal.latestPaymentFailed.text.1':
    'Чтобы получить доступ ко всем функциям, пожалуйста, заплатите или для использования бесплатной версии удалите',
  'modal.latestPaymentFailed.text.2':
    'чтобы соответствовать требованиям. Бесплатный план доступен только при наличии не более 5 сотрудников и 1 местоположения.',
  'modal.extraCostsForNewMember.title': 'Дополнительные расходы для нового члена',
  'modal.extraCostsForNewMember.description':
    'Увеличение числа сотрудников влечет за собой дополнительные расходы на ежемесячную подписку.',
  'modal.premiumUpgradeRequired.title': 'Требуется обновление до Премиума',
  'modal.premiumUpgradeRequired.description':
    'Для добавления дополнительных {0} ваш тарифный план должен быть обновлен до Премиума',
  'modal.premiumUpgradeRequired.location': 'местоположений',
  'modal.premiumUpgradeRequired.staffMembers': 'сотрудников',
  'modal.changeAccount.title': 'Сменить аккаунт Stripe?',
  'modal.changeAccount.description':
    'Пока вы меняете аккаунт, вы не сможете принимать платежи. Смена займет около минуты.',
  'btn.agree': 'Согласиться',
  'btn.decline': 'Отказаться',
  'paymentMethod.paymentMethod.title': 'Способ оплаты',
  'paymentMethod.currentPaymentMethod.title': 'Текущий способ оплаты',
  'paymentMethod.expirationDate': 'Дата истечения срока действия {0}',
  'paymentMethod.otherPaymentMethods.title': 'Другие способы оплаты',
  'paymentMethod.addAnotherPaymentMethod.btn': 'Добавить другой метод оплаты',
  'paymentMethod.addPaymentMethod.title': 'Добавить метод оплаты',
  'paymentMethod.noPaymentMethodAdded.title': 'Метод оплаты не добавлен',
  'paymentMethod.noPaymentMethodAdded.description': 'Способ оплаты будет добавлен при выборе плана Premium',
  'paymentMethod.goToPlanPage.btn': 'Перейдите на страницу тарифного плана',
  'paymentMethod.add.btn': 'Добавить',
  'paymentMethod.makeDefault.btn': 'Сделать по умолчанию',
  'paymentMethod.cardHasBeenAdded.message': 'Карта была добавлена',
  'paymentMethod.cardHasBeenAssign.message': 'Карта была назначена',
  'page.marketing.overview.messages.description':
    'Отправляйте пользовательские маркетинговые сообщения своим клиентам по SMS или электронной почте несколькими простыми щелчками мыши',
  'page.marketing.overview.btn.sendMessage': 'Отправить сообщение',
  'page.marketing.overview.campaigns.title': 'Кампании с автоматической отправкой',
  'page.marketing.overview.campaigns.description':
    'Создавайте персонализированные кампании на основе таких событий, как дни рождения клиентов и т.д. \nОтправляйте кампании по нескольким каналам, доставляя их по электронной почте и текстовым сообщениям.',
  'page.marketing.overview.btn.newCampaign': 'Новая кампания',
  'page.marketing.massMessages.title': 'Настройка нового сообщения',
  'page.marketing.massMessages.messageLog': 'Журнал сообщений',
  'page.marketing.massMessages.sendOn': 'Отправить',
  'page.marketing.massMessages.sendTo': 'Отправить на',
  'page.marketing.massMessages.channel': 'Канал',
  'page.marketing.campaigns.description':
    'Отправляйте автоматизированные маркетинговые сообщения своим клиентам по SMS или электронной почте несколькими простыми щелчками мыши',
  'page.marketing.campaigns.birthdaySpecial.title': 'Специально к Дню рождения',
  'page.marketing.campaigns.winBackClients.title': 'Вернуть клиентов',
  'page.marketing.campaigns.welcomeNewClients.title': 'Приветствовать новых клиентов',
  'page.marketing.campaigns.rewardRegularClients.title': 'Наградить постоянных клиентов',
  'page.marketing.campaigns.birthdaySpecial.description':
    'Удивите клиентов в их особый день, эта кампания автоматически отправляется клиентам в день их рождения.',
  'page.marketing.campaigns.winBackClients.description':
    'Нацельте отвязавшихся клиентов на возвращение с помощью специального предложения. Эта кампания отправляется клиентам, которые не вернулись после определенного периода времени',
  'page.marketing.campaigns.welcomeNewClients.description':
    'Превратите новичков в постоянных клиентов, побуждая клиентов, впервые обратившихся к вам, снова забронировать номер с помощью специальной скидки. Эта кампания автоматически отправляется клиентам через день после их первой продажи',
  'page.marketing.campaigns.rewardRegularClients.description':
    'Удивите лучших клиентов специальным предложением - это определенный способ привлечь лучших клиентов еще больше. Эта кампания автоматически отправляется клиентам на основе недавней активности при назначении встреч',
  'page.marketing.campaigns.btn.active': 'Активно',
  'page.marketing.campaigns.btn.inactive': 'Неактивно',
  'page.marketing.campaigns.btn.startCampaign': 'Начать кампанию',
  'page.marketing.campaigns.btn.editCampaign': 'Редактировать кампанию',
  'page.marketing.campaigns.btn.resetCampaign': 'Сбросить кампанию',
  'modal.resetCampaign.description': 'Вы уверены, что хотите сбросить эту кампанию ?',
  'modal.resetCampaign.btn.yesReset': 'Да, сбросить',
  'page.marketing.smsCreate.step.1': 'Настройка SMS. Шаг 1 из 4',
  'page.marketing.smsCreate.step.2': 'Настройка SMS. Шаг 2 из 4',
  'page.marketing.smsCreate.step.3': 'Настройка SMS. Шаг 3 из 4',
  'page.marketing.smsCreate.step.4': 'Настройка SMS. Шаг 4 из 4',
  'page.marketing.emailCreate.step.1': 'Настройка электронной почты. Шаг 1 из 4',
  'page.marketing.emailCreate.step.2': 'Настройка электронной почты. Шаг 2 из 4',
  'page.marketing.emailCreate.step.3': 'Настройка электронной почты. Шаг 3 из 4',
  'page.marketing.emailCreate.step.4': 'Настройка электронной почты. Шаг 4 из 4',
  'page.marketing.campaignCreate.step.1': 'Настройка кампании. Шаг 1 из 3',
  'page.marketing.campaignCreate.step.2': 'Настройка кампании. Шаг 2 из 3',
  'page.marketing.campaignCreate.step.3': 'Настройка кампании. Шаг 3 из 3',
  'page.marketing.smsCreate.step.1.title': 'Сообщение и идентификатор отправителя',
  'page.marketing.smsCreate.step.1.defaultTitleForSms': 'Введите идентификатор отправителя SMS',
  'page.marketing.smsCreate.step.1.defaultMessageForSms': 'Введите ваше сообщение',
  'page.marketing.emailCreate.step.1.defaultTitleForEmail': 'Введите заголовок сообщения',
  'page.marketing.emailCreate.step.1.defaultMessageForSms': 'Введите ваше сообщение',
  'page.marketing.emailCreate.step.1.title': 'Настройки электронной почты',
  'page.marketing.emailCreate.step.1.messageTitle': 'Заголовок сообщения',
  'page.marketing.emailCreate.step.1.replyToEmail': 'Ответить на электронное письмо',
  'page.marketing.emailCreate.step.1.clientReplies': 'Ответы клиентов будут отправляться на эту электронную почту',
  'page.marketing.emailCreate.step.1.photo.title': 'Фото',
  'page.marketing.emailCreate.step.1.enablePhoto': 'Включить фото',
  'page.marketing.emailCreate.step.1.changePhoto': 'Изменить фото',
  'page.marketing.emailCreate.step.1.button.title': 'Кнопка',
  'page.marketing.emailCreate.step.1.enableButton': 'Включить кнопку',
  'page.marketing.emailCreate.step.1.buttonName': 'Название кнопки',
  'page.marketing.emailCreate.step.1.buttonURL': 'URL кнопки',
  'page.marketing.emailCreate.step.1.socialMedia.title': 'Социальные сети',
  'page.marketing.emailCreate.step.1.enableSocialMedia': 'Включить ссылки на социальные сети',
  'page.marketing.emailCreate.step.1.facebookPage': 'Страница Facebook',
  'page.marketing.emailCreate.step.1.instagramPage': 'Страница Instagram',
  'page.marketing.emailCreate.step.1.yourPage': 'Ваша страница',
  'page.marketing.smsCreate.yourMessage': 'Ваше сообщение',
  'page.marketing.btn.sendMeTestMessage': 'Отправить тестовое сообщение',
  'page.marketing.smsCreate.step.1.freeTests': 'На сегодня осталось {0} бесплатных теста',
  'page.marketing.smsCreate.messagePreview': 'Предварительный просмотр сообщения',
  'page.marketing.smsCreate.btn.clientSelection': 'Выбор клиента',
  'page.marketing.audience.title': 'Аудитория',
  'page.marketing.smsCreate.step.2.description': 'Выберите, какие клиенты получат ваше сообщение',
  'page.marketing.smsCreate.step.2.allClient.title': 'Все клиенты',
  'page.marketing.smsCreate.step.2.allClient.description': 'Отправить сообщение всем вашим клиентам',
  'page.marketing.smsCreate.step.2.clientGroups.title': 'Группы клиентов',
  'page.marketing.smsCreate.step.2.clientGroups.description': 'Отправить сообщение выбранным группам клиентов',
  'page.marketing.smsCreate.step.2.totalClients': 'Всего клиентов',
  'page.marketing.smsCreate.step.2.totalPrice': 'Общая стоимость',
  'page.marketing.smsCreate.step.3.description': 'Выберите время, когда вы хотите отправить сообщение',
  'page.marketing.smsCreate.step.3.btn.selectDateTime': 'Выберите пользовательскую дату и время',
  'page.marketing.smsCreate.step.4.title': 'Оплата за массовое сообщение',
  'page.marketing.smsCreate.step.4.description': 'Введите данные вашей кредитной карты и оплатите',
  'page.marketing.smsCreate.step.4.paymentAmount': 'Сумма платежа:',
  'btn.scheduleSend': 'Расписание отправки',
  'btn.previous': 'Предыдущая',
  'btn.payment': 'Оплата',
  'btn.payAndFinish': 'Оплатить и завершить',
  'btn.backToDashboard': 'Вернуться на главную',
  'btn.saveAndClose': 'Сохранить и закрыть',
  'modal.editClientSelection.title': 'Редактирование выбора клиента',
  'modal.newClient.description': 'Клиенты, добавленные за последнее время:',
  'modal.recentClient.title': 'Последний клиент',
  'modal.recentClient.description': 'Клиенты, которые посетили вас за последнее время:',
  'modal.clientByAge.title': 'Клиенты по возрасту',
  'modal.clientsByGender.title': 'Клиенты по полу',
  'modal.paymentConfirmation.title': 'Оплата прошла успешно !',
  'modal.paymentConfirmation.messageSend': 'Ваше сообщение будет отправлено',
  'modal.previewMessage.title': 'Предварительный просмотр сообщения',
  'modal.previewSms.tab': 'Предварительный просмотр SMS',
  'modal.previewEmail.tab': 'Предварительный просмотр электронной почты',
  'modal.editServiceSelection.title': 'Редактировать выбор услуги',
  'modal.editServiceSelection.allServices': 'Все услуги',
  'modal.campaignActivated.title': 'Кампания активирована !',
  'modal.campaignActivated.description': 'Вы можете приостановить или изменить эту кампанию в любое время',
  'btn.all': 'Все {0}',
  'btn.included': 'Включено {0}',
  'btn.excluded': 'Исключено {0}',
  'checkbox.allClients': 'Все клиенты {0}',
  'scheduleSend.time': 'время',
  'scheduleSend.timeSetTo': 'Время, установленное на:',
  'scheduleSend.setTime': 'Установить время',
  'campaignSetup.step.1.header.title': 'Настройка кампании',
  'campaignSetup.step.2.header.title': 'Редактировать сообщение',
  'campaignSetup.step.3.header.title': 'Просмотр',
  'campaignSetup.channel.description': 'Выберите, по каким каналам будет отправляться кампания',
  'campaignSetup.smsSetup.tab': 'Настройка SMS',
  'campaignSetup.emailSetup.tab': 'Настройка электронной почты',
  'campaignSetup.almostDone.title': 'Почти готово !',
  'campaignSetup.almostDone.description':
    'Ваша умная кампания готова к работе, после включения она будет автоматически отправлять сообщения клиентам на постоянной основе. Вы можете легко изменить или приостановить эту кампанию в любое время',
  'campaignSetup.approximatePrice.header': 'Приблизительная цена',
  'campaignSetup.approximatePrice.description': 'За предстоящие 30 дней',
  'campaignSetup.approximatePrice.approx': 'Приблизительно',
  'campaign.birthdaySpecial.description': 'Эта кампания автоматически отправляется клиентам в их день рождения',
  'campaign.birthdaySpecial.sendToClient': 'Отправить клиенту:',
  'campaign.birthdaySpecial.selectOption.1': 'В день рождения',
  'campaign.birthdaySpecial.selectOption.2': 'За три дня до дня рождения',
  'campaign.birthdaySpecial.selectOption.3': 'За неделю до дня рождения',
  'campaign.birthdaySpecial.selectOption.4': 'За две недели до дня рождения',
  'campaign.winBackClients.description':
    'Эта кампания отправляется клиентам, которые не вернулись через определенный период времени',
  'campaign.winBackClients.clientsWhoDidntReturn': 'Клиенты, которые не вернулись в течение последних',
  'campaign.winBackClients.appliedTo': 'Применяется к',
  'campaign.winBackClients.all': ' всем ',
  'campaign.winBackClients.services': 'услугам',
  'campaign.winBackClients.editSelection': 'Редактировать выбор',
  'campaign.welcomeNewClients.description':
    'Превратите новичков в постоянных клиентов, поощряя первых клиентов к повторному бронированию с помощью специальной скидки',
  'campaign.welcomeNewClients.smartCampaignNewClients':
    'Эта умная кампания автоматически отправляется новым клиентам через день после их первой продажи',
  'campaign.rewardRegularClients.description':
    'Эта кампания автоматически отправляется клиентам на основе недавней активности назначений',
  'campaign.rewardRegularClients.clientsWithAtLeast': 'Клиентам, у которых по крайней мере',
  'campaign.rewardRegularClients.appointmentsInThe': 'встреч в',
  'campaign.rewardRegularClients.last': 'последние',
  'campaign.rewardRegularClients.appointments': 'встреч',
  'campaign.rewardRegularClients.inTheLast': 'в последние',
  'campaign.rewardRegularClients.months': 'месяцев',
  'subscriptionPaymentFailed.title':
    'Пожалуйста, обновите свой способ оплаты до {0}, в противном случае все премиальные функции будут прекращены, спасибо.',
  'subscriptionCancelled.title':
    'Извините, но ваш Премиум-план был отключен из-за неуплаты. Если вы хотите продолжить использовать функции Премиум, активируйте план Премиум.',
  'notificationPaymentFailed.title':
    'Не удалось оплатить сообщения. Пожалуйста, произведите оплату до {0}, иначе уведомления будут отключены, спасибо.',
  'notificationCancelled.title':
    'Извините, но ваши сообщения были отключены из-за неуплаты. Если вы хотите продолжить отправку сообщений, пожалуйста, заплатите, спасибо.',
  'btn.hideOptions': 'Скрыть опции',
  'btn.showOptions': 'Показать опции',
  'notification.googleDisconnected': 'Календарь Google отключен',
  'notification.googleConnected': 'Календарь Google подключен',
  'notification.appleDisconnected': 'Calendar Apple отключен',
  'notification.appleConnected': 'Календарь Apple подключен',
  'notification.microsoft365Disconnected': 'Календарь Microsoft365 отключен',
  'notification.microsoft365Connected': 'Календарь Microsoft365 подключен',
  'notification.linkCopied': 'Ссылка скопирована',
  'calendarSync.title': 'Синхронизация календаря',
  'calendarSync.description':
    'Синхронизация встреч Plandok с вашим любимым календарем позволяет легко организовать процесс и никогда не пропустить важное событие.',
  'googleCalendar.title': 'Календарь Google',
  'appleCalendar.title': 'Календарь Apple',
  'microsoft365Calendar.title': 'Календарь Microsoft365',
  'linkYourCalendar.title': 'Связать календари',
  'connect.btn': 'Подключить',
  'disconnect.rtn': 'Отключить',
  'connected.label': 'Подключено',
  'modal.workingHours.standartMethod.btn': 'Стандартный метод',
  'modal.workingHours.customMethod.btn': 'Нестандартный метод',
  'modal.workingHours.workingDates.input': 'Рабочие даты',
  'modal.workingHours.selectDates.placeholder': 'Выберите даты',
  'noInternetConnection.notification.title': 'Отсутствие подключения к Интернету',
  'app.update.title': 'Доступна новая версия',
  'app.update.description.title':
    'Мы добавили новые функции и исправили некоторые ошибки, чтобы сделать работу с Plandok максимально удобной.',
  'app.update.button.title': 'Обновить',
  'app.notNow.button.title': 'Не сейчас',
  'app.updateAvailable.button.title': 'Обновление доступно',
  'upgradePlan.success.message': 'Подписка успешно обновлена',
  'upgradePlan.error.message':
    'Ошибка обновления подписки, обратитесь в службу поддержки, если с вашей карты сняты деньги, а подписка не обновляется',
  'sidebar.inventory': 'Инвентаризация',
  'products.tab': 'Продукция',
  'suppliers.tab': 'Поставщики',
  'stockOrders.tab': 'Заказы на складе',
  'stockTracking.tab': 'Отслеживание запасов',
  'input.productsSearch.placeholder': 'Поиск по названию товара или штрих-коду',
  'product.create.btn.title': 'Добавить новый продукт',
  'table.products.product': 'Товар',
  'table.products.barcode': 'Штрих-код',
  'table.products.brand': 'Бренд',
  'table.products.sale': 'Продажа',
  'table.products.saleOpt': 'Опц. продажи',
  'saleOptions.tooltip.title': 'Опции продажи',
  'saleOptions.tooltip.description': 'Вы можете выбрать включение/отключение продажи во время оформления заказа.',
  'seeMore.title': 'см. подробнее',
  'option.products.downloadCSV': 'Скачать CSV',
  'option.products.downloadExcel': 'Скачать Excel',
  'option.products.manageBrands': 'Управление марками',
  'option.products.manageCategories': 'Управление категориями',
  'option.products.manageStock': 'Управление запасами',
  'option.products.importProducts': 'Импорт товаров',
  'modal.filter.categories.title': 'Категории',
  'modal.filter.brands.title': 'Бренды',
  'modal.filter.stock.title': 'Запасы',
  'modal.filter.allCategories.selectItem': 'Все категории',
  'modal.filter.allBrands.selectItem': 'Все бренды',
  'modal.filter.allSuppliers.selectItem': 'Все поставщики',
  'modal.filter.allProducts.selectItem': 'Все товары',
  'input.suppliersSearch.placeholder': 'Поиск по названию поставщика',
  'supplier.create.btn.title': 'Добавить нового поставщика',
  'table.suppliers.supplierName': 'Название поставщика',
  'table.suppliers.phoneNumber': 'Номер телефона',
  'table.suppliers.physicalAddress': 'Физический адрес',
  'validation.notification.reminderTime': 'Нельзя отправлять уведомления с тем же временем напоминанием.',
  'validation.paymentType.switches': 'Должен быть разрешен хотя бы один способ оплаты.',
  'plan.premium.option.14':
    '0% комиссия Plandok за встречи, забронированные через онлайн-бронирование с использованием кредитной карты.',
  'title.dragAndExtend.appointment.permission': 'Можно перетаскивать и продлевать встречи',
  'button.lastVisit.addAnotherLastvisit': 'ДОБАВИТЬ ЕЩЕ ПОСЛЕДНИЙ ВИЗИТ',
  'notifications.template.badge.validate': 'Подтвердить',
  'notifications.template.badge.refused': 'Отказался',
  'paid.by.card': 'Оплачено картой',
  'costs.manual.paid.link': 'Или вы можете оплатить уведомления вручную с помощью карты, нажав на эту ссылку',
  'modal.remove.account.title': 'Удалить учетную запись Stripe',
  'modal.remove.account.description': 'Ваша учетная запись будет удалена, и вы не сможете принимать онлайн-платежи. ',
  'ob.remove.account.option': 'Удалить учетную запись Stripe',
  'btn.remove.account': 'Удалить',
};

export default labels;
