import React from 'react';

import { IntlLabel, IntlDate } from '@plandok/i18n';

export const columnsConfig = [
  {
    title: <IntlLabel label="table.cancelReason.name" />,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: <IntlLabel label="table.cancelReason.addDate" />,
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: ({ value }: any) => (
      <IntlDate date={value.createdAt && new Date(value.createdAt)} dateFormat="dd MMM yyyy" />
    ),
  },
];
