import styled, { css } from 'styled-components';
import { Menu } from 'antd';
import { mediaMobileDown } from '@plandok/core';

type ContainerProps = {
  maxWidth?: string;
  noMargin?: boolean;
  noWhiteBG?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${({ maxWidth }) => maxWidth || '1200px'};
  width: 100%;

  ${mediaMobileDown(css<ContainerProps>`
    margin: ${({ noMargin }) => (noMargin ? '0' : '-15px')};
    width: ${({ noMargin }) => (noMargin ? '100%' : 'calc(100% + 30px)')};
    max-width: ${({ maxWidth }) => maxWidth || '1200px'};
    padding-top: ${({ noMargin }) => (noMargin ? '0' : '30px')};
    background: ${({ noWhiteBG }) => (noWhiteBG ? 'inherit' : '#fff')};
  `)}
`;

export const StyledMenu = styled(Menu)`
  min-height: 90vh;
  height: 90vh;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  padding-bottom: 1rem;

  &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: #e9ecf3;
  }
  &::-webkit-scrollbar-thumb {
    background: #adb6cc;
    border-radius: 10px;
  }
`;
