import styled, { css } from 'styled-components';
import { mediaMdUpMobily } from '@plandok/core';

export const ButtonContainer = styled.div<any>`
  position: fixed;
  bottom: 75px;
  right: 12px;
  z-index: 3;

  button {
    height: 35px;
    background: #13316d;
    border-color: #13316d;
    i {
      transition: all 1s;
      transform: ${(props) => (props.open ? 'rotate(45deg)' : 'rotate(0deg)')};
    }
  }

  ${mediaMdUpMobily(
    css`
      display: none;
    `
  )}
`;
