import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, Spin, Row, Col } from 'antd';
import { CustomIcon } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import CheckboxFieldList from './components/CheckboxFieldList';
import { extractServiceGroupOptions } from 'helpers/app/lookups';
import CustomCheckbox from './components/CustomCheckbox';
import * as SC from './styles';

export default function MultiCheckboxField({
  title,
  customLabel,
  isDescription = true,
  description,
  options = [],
  loading,
  value,
  onChange,
  mode,
}: any) {
  const [servicesGroup, setServicesGroup] = useState<any[]>([]);

  const isSelectAll = (value || []).length === options.length;
  const toggleSelectAll = () => onChange(isSelectAll ? [] : options.map((e: any) => e.code));
  const toggleSelect = (isSelected: boolean) => (id: any) => () =>
    onChange(isSelected ? (value || []).filter((e: any) => e !== id) : [...value, id]);
  const isMultiColumns = mode === 'multi';
  const isThreeColumns = mode === 'three';

  useEffect(() => {
    if (isThreeColumns && options.length) {
      setServicesGroup(extractServiceGroupOptions({ services: options }, undefined));
    }
  }, [options, isThreeColumns]);

  return (
    <Spin spinning={loading}>
      <SC.Container>
        <SC.Title>{title && <IntlLabel label={title} />}</SC.Title>
        {!isMultiColumns && !isThreeColumns && <SC.Divider as={Divider} />}
        <SC.Description>{isDescription && <IntlLabel label={description} />}</SC.Description>
        {!isThreeColumns && (
          <SC.SelectItem bold>
            <Checkbox
              checked={isSelectAll}
              indeterminate={!isSelectAll && (value || []).length}
              onChange={toggleSelectAll}
            >
              <IntlLabel label={customLabel || 'button.select.all'} />
            </Checkbox>
          </SC.SelectItem>
        )}

        {isMultiColumns ? (
          <Row gutter={40}>
            <Col md={12}>
              <SC.Divider as={Divider} />
              <CheckboxFieldList
                options={options.filter((_: any, i: number) => (i + 1) % 2)}
                value={value}
                toggleSelect={toggleSelect}
              />
            </Col>
            <Col md={12}>
              <SC.Divider as={Divider} />
              <CheckboxFieldList
                options={options.filter((_: any, i: number) => i % 2)}
                value={value}
                toggleSelect={toggleSelect}
              />
            </Col>
          </Row>
        ) : isThreeColumns && servicesGroup.length ? (
          <SC.StyledCollapse
            defaultActiveKey={servicesGroup.map((panel, index) => index)}
            bordered={false}
            expandIcon={({ isActive }) => <CustomIcon type={isActive ? 'arrowUp' : 'arrowDown'} />}
          >
            {servicesGroup.map(({ label, options }, index) => (
              <SC.StyledPanel header={label} key={index} theme={options[0].value.serviceGroup.color}>
                <SC.WrapperCheckbox>
                  <CustomCheckbox options={options} value={value} onChange={onChange} />
                </SC.WrapperCheckbox>
                <Row gutter={40} style={{ marginLeft: '-10px' }}>
                  <Col md={8}>
                    <CheckboxFieldList
                      options={options.filter((_: any, i: number) => i % 3 === 0)}
                      value={value}
                      toggleSelect={toggleSelect}
                      three={true}
                    />
                  </Col>
                  <Col md={8}>
                    <CheckboxFieldList
                      options={options.filter((_: any, i: number) => i % 3 === 1)}
                      value={value}
                      toggleSelect={toggleSelect}
                      three={true}
                    />
                  </Col>
                  <Col md={8}>
                    <CheckboxFieldList
                      options={options.filter((_: any, i: number) => i % 3 === 2)}
                      value={value}
                      toggleSelect={toggleSelect}
                      three={true}
                    />
                  </Col>
                </Row>
              </SC.StyledPanel>
            ))}
          </SC.StyledCollapse>
        ) : (
          <SC.ItemList>
            <CheckboxFieldList options={options} value={value} toggleSelect={toggleSelect} />
          </SC.ItemList>
        )}
      </SC.Container>
    </Spin>
  );
}
