import { differenceInCalendarDays, isValid, subDays, format } from 'date-fns';
import React, { useState } from 'react';
import { Spin } from 'antd';
import cn from 'classnames';
import { App_AnalyticsDateInterval, App_AppointmentsGraphStep } from 'graphql/generatedTypes';
import { useAnalyticsQuery } from 'graphql/queries/analytics.generated';
import { useUserQuery } from 'graphql/queries/userQuery.generated';
import { AppLayout, NoInternetConnection } from 'components';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { AnalyticsHash } from 'constants/routes';
import { AnalyticsDashboard } from './AnalyticsDashboard/AnalyticsDashboard';
import { AnalyticsMobileHeader } from './components/AnalyticsMobileHeader';
import { AnalyticsErrorState } from './components/AnalyticsErrorsState';
import Reports from './AnalyticsReports/components/Reports';
import { analyticsTabsConfig } from './config';
import * as SC from './styles';
import './style.less';

type FromDate = Date | string | null;
type TimeRangeData = { timeRange: Date; startDate: Date | null; endDate: Date | null };

export default function AnalyticsPage() {
  const [isStepByDay, setIsStepByDay] = useState(false);
  const [timeRangeData, setTimeRangeData] = useState<TimeRangeData>({} as TimeRangeData);

  const { data: userData, loading: userLoading, error: userErrors } = useUserQuery();
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();

  const isPremium = userData?.user?.partner?.subscription.premium ?? false;

  const toDate = isValid(timeRangeData.endDate)
    ? format(timeRangeData.endDate ?? 0, 'yyyy-MM-dd')
    : format(new Date(), 'yyyy-MM-dd');

  const getFromDate = (): FromDate => {
    const isTimeRangeSelected = isValid(timeRangeData.timeRange);
    const isNoDaysSelected = !isValid(timeRangeData.timeRange) && !isValid(timeRangeData.endDate);
    const isCustomRangeSelected = isValid(timeRangeData.startDate) && isValid(timeRangeData.endDate);

    if (isTimeRangeSelected) {
      return formattedTimeRange;
    }

    if (isNoDaysSelected) {
      return defaultFormatLast7Days;
    }

    if (isCustomRangeSelected) {
      return format(timeRangeData.startDate ?? 0, 'yyyy-MM-dd');
    }

    return '';
  };

  const differenceInDays = differenceInCalendarDays(new Date(), new Date(getFromDate() || 0));
  const fromDatePrevious = format(subDays(new Date(getFromDate() ?? 0), differenceInDays), 'yyyy-MM-dd');

  const customRangeDifferenceInDays = differenceInCalendarDays(
    timeRangeData.endDate || 0,
    timeRangeData.startDate || 0
  );
  const isCustomRangeInDays = customRangeDifferenceInDays <= 31;
  const isSelectedRangeByDay = isStepByDay || (!isValid(timeRangeData.timeRange) && isCustomRangeInDays);

  const defaultFormatLast7Days = format(subDays(new Date(), 7), 'yyyy-MM-dd');
  const formattedTimeRange = isValid(timeRangeData.timeRange) ? format(timeRangeData.timeRange, 'yyyy-MM-dd') : '';

  const analyticsVariables = {
    fromDate: getFromDate(),
    fromDatePrevious,
    toDate,
    toDatePrevious: getFromDate(),
    range: App_AnalyticsDateInterval.Month,
    step: isSelectedRangeByDay ? App_AppointmentsGraphStep.Day1 : App_AppointmentsGraphStep.Month1,
  };

  const {
    data: analyticsData,
    loading: analyticsLoading,
    error: analyticsErrors,
  } = useAnalyticsQuery({
    variables: analyticsVariables,
  });

  const analyticsDashboardErrors = analyticsErrors || userErrors;
  const analyticsDashboardLoading = analyticsLoading || userLoading;

  return (
    <NoInternetConnection>
      <AppLayout
        headerTitle="analytics.title"
        headerIconTitle="analytics.premium.title"
        mobileHeader={() => AnalyticsMobileHeader('analytics.dashboard.tab')}
        mobileBackground="#fff"
        mobileWhiteBg
        className={cn('analytics-page')}
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <SC.HashTabsWrapper className="analytics-tabs" tabs={analyticsTabsConfig} defaultTab={AnalyticsHash.DASHBOARD}>
          <Spin spinning={analyticsDashboardLoading}>
            {analyticsDashboardErrors && AnalyticsErrorState(analyticsDashboardErrors)}
            {!analyticsDashboardErrors && analyticsData && (
              <AnalyticsDashboard
                analyticsData={analyticsData}
                isPremium={isPremium}
                isStepByDay={isStepByDay}
                setTimeRangeData={setTimeRangeData}
                setIsStepByDay={setIsStepByDay}
              />
            )}
          </Spin>
          <AppLayout.Container maxWidth={true ? 'inherit' : ''} noMargin noWhiteBG>
            <Reports />
          </AppLayout.Container>
        </SC.HashTabsWrapper>
      </AppLayout>
    </NoInternetConnection>
  );
}
