import styled, { css } from 'styled-components';

export const IconCircle = styled.div`
  height: 44px;
  width: 44px;
  margin: 0 3px 5px 3px;
  border-radius: 50%;
  transition: background-color 0.2s, border-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dee3e7;
  [data-calendar-custom-icon] {
    padding-top: 7px;
    svg {
      height: 20px;
    }
  }
`;

export const AvatarContainer = styled.div`
  margin: 0 3px 5px 3px;
`;

export const IconItem = styled.div<any>`
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  ${IconCircle} {
    background: ${(props) => (props.active ? props.theme.primaryColor : 'initial')};
    color: ${(props) => (props.active ? 'white' : 'initial')};
    svg {
      fill: ${(props) => (props.active ? 'white' : 'initial')};
    }
  }
`;

export const Item = styled.div<any>`
  background-color: ${(props) => (props.active ? 'rgba(0, 147, 239, 0.1)' : 'white')};
  padding: 10px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dee3e7;
  cursor: pointer;

  ${(props) =>
    props.active
      ? css`
          margin: 0 -25px;
          padding: 10px 25px;
        `
      : ''}
  &:last-of-type {
    border: none;
  }
  ${IconCircle} {
    margin-right: 10px;
    background: ${(props) => (props.active ? props.theme.primaryColor : 'initial')};
    color: ${(props) => (props.active ? 'white' : 'initial')};
  }
`;

export const CloseMenu = styled.div`
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  top: 20px;
  right: 20px;
  z-index: 10;
`;
