import { IntlLabel } from '@plandok/i18n';
import { Icon } from 'antd';
import { useState } from 'react';
import React from 'react';

import * as SC from './styles';

export default function TextLong({ text, ...props }: any) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  if (!text) {
    return null;
  }

  const showCollapse = text.length > 110;
  return (
    <>
      <SC.Container open={open}>
        {text}
        {showCollapse && !open && <SC.FadeContainer />}
      </SC.Container>
      {showCollapse && (
        <SC.Toggle onClick={toggleOpen}>
          {open ? (
            <>
              <IntlLabel label="btn.collapse" /> <Icon type="up" />
            </>
          ) : (
            <>
              <IntlLabel label="btn.showAll" /> <Icon type="down" />
            </>
          )}
        </SC.Toggle>
      )}
      {props.children}
    </>
  );
}
