import React from 'react';
import { MobileBackButton } from 'components';
import * as SC from '../styles';

export default function MobileHeader() {
  return (
    <>
      <MobileBackButton />
      <SC.Block />
    </>
  );
}
