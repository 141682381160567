// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from '../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnlineBookingLinkQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OnlineBookingLinkQuery = { __typename?: 'AppQuery'; onlineBookingLink?: string | null };

export const OnlineBookingLinkDocument = gql`
  query OnlineBookingLink {
    onlineBookingLink
  }
`;

/**
 * __useOnlineBookingLinkQuery__
 *
 * To run a query within a React component, call `useOnlineBookingLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineBookingLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineBookingLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnlineBookingLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<OnlineBookingLinkQuery, OnlineBookingLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OnlineBookingLinkQuery, OnlineBookingLinkQueryVariables>(OnlineBookingLinkDocument, options);
}
export function useOnlineBookingLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OnlineBookingLinkQuery, OnlineBookingLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OnlineBookingLinkQuery, OnlineBookingLinkQueryVariables>(
    OnlineBookingLinkDocument,
    options
  );
}
export type OnlineBookingLinkQueryHookResult = ReturnType<typeof useOnlineBookingLinkQuery>;
export type OnlineBookingLinkLazyQueryHookResult = ReturnType<typeof useOnlineBookingLinkLazyQuery>;
export type OnlineBookingLinkQueryResult = Apollo.QueryResult<OnlineBookingLinkQuery, OnlineBookingLinkQueryVariables>;
