import { mediaMdDown, mediaMobileDown, mediaSmDown, mediaXlDown } from '@plandok/core';
import styled, { css } from 'styled-components';

type ContainerProps = {
  isMobile: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  min-height: ${({ isMobile }) => (isMobile ? 'calc(100vh - 120px)' : 'calc(100vh - 150px)')};
  margin: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px -30px 0 -30px')};
  ${mediaMobileDown(css`
    min-height: calc(100vh - 120px);
    margin: 20px 0 0 0;
  `)}
`;

export const WhatsNewContainer = styled.div`
  background: #136ef6;
  border-radius: 10px;
  padding: 9px 20px;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: calc(100vw - 20px);

  div:nth-child(2) {
    div {
      margin-bottom: 0;
    }
    button {
      max-height: 36px;
      font-size: 14px;
      box-shadow: none;
      margin-left: 24px;
    }
  }

  button {
    svg {
      width: 14px;
      height: 14px;
      path {
        stroke: #ffffff;
      }
    }
    :hover,
    :focus {
      background: none;
    }
  }

  ${mediaXlDown(css`
    padding: 16px 24px 20px;
    align-items: start;

    div:nth-child(2) {
      flex-direction: column;
      div {
        margin-bottom: 16px;
      }
      button {
        margin-left: auto;
      }
    }
  `)}

  ${mediaMdDown(css`
    margin-top: 20px;
  `)}

  ${mediaSmDown(css`
    div:nth-child(2) {
      div {
        max-width: 267px;
      }
    }
  `)}
`;
