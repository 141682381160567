import React from 'react';
import { Button } from '@plandok/core';
import { RoutePath } from 'constants/routes';
import { useLocation } from 'hooks/router';

export default function HeaderSearchBtn() {
  const { navigate } = useLocation();

  const navigateToSearch = () => navigate(RoutePath.SEARCH);

  return (
    <Button shape="circle" size="small" type="ghost" onClick={navigateToSearch}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20.012" height="19.997" viewBox="0 0 20.012 19.997">
        <path
          id="search_1_"
          data-name="search (1)"
          d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a.994.994,0,1,0,1.42-1.39ZM11,18a7,7,0,1,1,7-7,7,7,0,0,1-7,7Z"
          transform="translate(-1.979 -1.979)"
          fill="#adb6cc"
        />
      </svg>
    </Button>
  );
}
