import React from 'react';
import { CustomIcon } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { LogType } from 'types';

export const getLogType = (type: LogType) => {
  if (type === LogType.CREATED) return `appointment.log.created.label`;
  if (type === LogType.UPDATED) return `appointment.log.updated.label`;
  if (type === LogType.RESCHEDULED) return `appointment.log.rescheduled.label`;
  if (type === LogType.CANCELLED) return `appointment.log.cancelled.label`;
  if (type === LogType.CHANGED_STATUS) return `appointment.log.changed.label`;
};

export const Status = ({ status }: { status: 'new' | 'cancelled' | 'confirmed' | 'no_show' | 'completed' }) => {
  switch (status) {
    case 'new':
      return (
        <span style={{ color: '#4A9CFC' }}>
          <IntlLabel label="appointment.status.new" />
        </span>
      );
    case 'cancelled':
      return (
        <span style={{ color: '#ADB6CC' }}>
          <IntlLabel label="appointment.status.cancelled" />
        </span>
      );
    case 'confirmed':
      return (
        <>
          <span style={{ color: '#29C0CC' }}>
            <IntlLabel label="appointment.status.confirmed" />
          </span>
          <span style={{ marginLeft: '6px' }}>
            <CustomIcon type="confirmed" />
          </span>
        </>
      );
    case 'completed':
      return (
        <>
          <span style={{ color: '#97D561' }}>
            <IntlLabel label="appointment.status.completed" />
          </span>
          <span style={{ marginLeft: '6px' }}>
            <CustomIcon type="completed" />
          </span>
        </>
      );
    case 'no_show':
      return (
        <>
          <span style={{ color: '#FC6793' }}>
            <IntlLabel label="appointment.status.notShow" />
          </span>
          <span style={{ marginLeft: '6px' }}>
            <CustomIcon type="noShow" />
          </span>
        </>
      );
    default:
      return <IntlLabel label="appointment.status.btn" />;
  }
};
