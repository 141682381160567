import React from 'react';
import { CustomIcon, Text, Button } from '@plandok/core';
import * as SC from '../styles';

type BookingAnalyticCardProps = {
  title: string;
  description: string;
  icon: string;
  link?: string | null;
  onClick: () => void;
};

export const BookingAnalyticCard = ({ title, description, icon, link, onClick }: BookingAnalyticCardProps) => (
  <SC.AnalyticCard>
    <SC.StatusTitle theme={{ color: link ? '#6bce3c' : '#c2c2c2' }}>
      <CustomIcon type={link ? 'activeCardCircle' : 'inactiveCardCircle'} />
      <Text
        label={link ? 'ob.analytics.active' : 'ob.analytics.inactive'}
        mb="none"
        weight="medium"
        size="medium"
        ml="xxsmall"
      />
    </SC.StatusTitle>
    <SC.CardContainer>
      <img src={icon} alt={title} />
      <Text label={title} weight="semiBold" size="mlarge" mb="small" />
      <Text label={description} size="base" mb="medium" />
      <Button type="primary" label="ob.analytics.setUp.btn" onClick={onClick} upperCase={false} semiBold />
    </SC.CardContainer>
  </SC.AnalyticCard>
);
