import { callAction, callActionWithId } from '@plandok/core';

export const getClientToken = callAction('/subscription-billing/client-token', 'post');

export const upgradePlan = callAction('/subscription-billing/upgrade', 'post', true);
export const cancelPlan = callAction('/subscription-billing/cancel', 'post', true);

export const fetchPlans = callAction('/subscription-billing/plans', 'get');
export const fetchPaymentStatus = callAction('/alerts', 'get');

export const fetchNotificationEstimatedCosts = callAction('/subscription-billing/notification/estimated/costs', 'get');
export const payNotification = callAction('/subscription-billing/notification/paid', 'post', true);

export const fetchEstimatedCosts = callAction('/subscription-billing/estimated/costs', 'get');
export const fetchEstimatedCostsWithStaffCount = callActionWithId(
  '/subscription-billing/estimated/costs',
  'get',
  false,
  {
    customUrlFunc: (id: string, staffCount: number) => `/subscription-billing/estimated/costs?staffCount=${staffCount}`,
  }
);

export const fetchPaymentInformation = callActionWithId(
  '/payment/validation/{id}/{paymentMethod}/details',
  'get',
  false,
  {
    customUrlFunc: (id: string, paymentMethod: string) => `/payment/validation/${id}/${paymentMethod}/details`,
  }
);
export const cancelPaymentIntent = callActionWithId('/payment/validation/{id}/cancelled', 'post');

export const fetchPaymentMethods = callAction('/subscription-billing/payment/methods', 'get');
export const deletePaymentMethod = callActionWithId(
  '/subscription-billing/payment/methods/delete/{paymentMethod}',
  'delete'
);
export const assignPaymentMethods = callAction('/subscription-billing/payment/methods/assign', 'post', true);
export const fetchClientSecret = callAction('/subscription-billing/payment/methods/setup', 'get');
