import React from 'react';

import { Menu } from 'antd';
import { IntlLabel } from '@plandok/i18n';
import useHasPermission from 'hooks/permission/useHasPermission';
import { PermissionType } from '../../../../../../../../constants/auth';

export default function ClientDetailsMenu({ remove, edit, ...props }: any) {
  const hasPermission = useHasPermission(PermissionType.CAN_SEE_CLIENT_INFO);
  return (
    <Menu className="app-common-dropdown" {...props}>
      {hasPermission ? (
        <Menu.Item key="0" onClick={edit}>
          <IntlLabel label="client.menu.edit" />
        </Menu.Item>
      ) : null}
      <Menu.Item key="1" className="color-red" onClick={remove}>
        <IntlLabel label="client.menu.remove" />
      </Menu.Item>
    </Menu>
  );
}
