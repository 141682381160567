import { Col, Icon, Popover, Row } from 'antd';
import React, { useState } from 'react';
import cn from 'classnames';
import { CustomIcon, getIsMobile, Text, TextInput, useLocation } from '@plandok/core';
import { IntlLabel, withIntlLabel } from '@plandok/i18n';
import { AppLayout, AppTable, BackButton, NoInternetConnection } from 'components';
import { exportTableDataToCSV, timeRangeOptions } from 'helpers/reports';
import { useEmployeesQuery } from 'graphql/queries/employees.generated';
import { useLocationsQuery } from 'graphql/queries/locations.generated';
import { useUserQuery } from 'graphql/queries/userQuery.generated';
import { AnalyticsHash, RoutePath } from 'constants/routes';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import ReportsMobileFilter from '../AnalyticsPage/AnalyticsReports/components/ReportsMobileFilter';
import DatePicker from '../AnalyticsPage/AnalyticsDashboard/components/DatePicker/DatePicker';
import { AnalyticsMobileHeader } from '../AnalyticsPage/components/AnalyticsMobileHeader';
import { AnalyticsErrorState } from '../AnalyticsPage/components/AnalyticsErrorsState';
import { useQueryReports } from '../common/hooks/useQueryReports';
import { analyticsTabsConfig } from '../AnalyticsPage/config';
import { useFilters } from '../common/hooks/useFilters';
import * as SC from '../../../styles/reports/index';
import { appointmentsListColumns } from './config';

const SearchInput = withIntlLabel({ propKey: 'placeholder' })(TextInput);

export default function ReportsAppointmentListPage() {
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isStepByDay, setIsStepByDay] = useState(false);
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [query, setQuery] = useState('');
  const isMobile = getIsMobile();
  const { goBack } = useLocation();

  const { data: employeesData, error: employeesErrors } = useEmployeesQuery();
  const { data: locationsData, error: locationsErrors } = useLocationsQuery();
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const { data: userData, error: userErrors } = useUserQuery();
  const { data, loading, refetchData, loadMore, error, setTimeRangeData, from, to, setPage } =
    useQueryReports('appointmentsReport');

  const {
    mappedLocations,
    mappedEmployees,
    clearFilters,
    selectAllStaffValues,
    selectAllLocationValues,
    selectedStaffValue,
    selectedLocationValue,
    onStaffChange,
    onLocationChange,
    setSelectedStaffValue,
    setSelectedLocationValue,
  } = useFilters({
    locations: locationsData?.locations,
    employees: employeesData?.employees,
    setTimeRange: setTimeRangeData,
  });

  const errors = employeesErrors || locationsErrors || userErrors || error;
  if (errors) {
    return AnalyticsErrorState(errors);
  }

  const isPremium = userData?.user?.partner?.subscription.premium ?? false;

  const closeFilter = () => setIsVisibleFilter(false);
  const handleVisibleMobileSearch = () => setIsMobileSearchVisible((isVisible) => !isVisible);

  const handlePopoverVisibleChange = (isVisible: boolean) => setIsPopoverVisible(isVisible);

  const toggleFilter = () => setIsVisibleFilter(!isVisibleFilter);

  const exportCSVByClosingPopover = (type: string, from: any, to: any) => {
    exportTableDataToCSV(type, from, to);
    setIsPopoverVisible(false);
  };

  return (
    <NoInternetConnection>
      <AppLayout
        headerTitle="analytics.title"
        headerIconTitle="analytics.premium.title"
        mobileHeader={() => AnalyticsMobileHeader('reports.appointments.title')}
        mobileBackground="#fff"
        mobileWhiteBg={true}
        className={cn('analytics-page')}
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <SC.HashTabsWrapper
          className="analytics-tabs"
          tabs={analyticsTabsConfig}
          defaultTab={AnalyticsHash.REPORTS}
          rootPath={RoutePath.ANALYTICS}
        >
          <div />
          <AppLayout.Container maxWidth={'inherit'} noMargin noWhiteBG>
            {!isMobile && (
              <>
                <div style={{ maxWidth: '20rem' }}>
                  <BackButton onClick={goBack} />
                </div>

                <Text size="mlarge" label="reports.appointmentsList.title" Type="base" bold />
              </>
            )}
            <Row align="middle" className="w-auto mb-2 justify-between" gutter={16}>
              {!isMobile && (
                <Col span={6}>
                  <SearchInput
                    suffix={<Icon type="search" style={{ color: '#61749D' }} />}
                    width="100%"
                    className="search-input-reports"
                    placeholder="reports.search.placeholder"
                    value={query}
                    onChange={({ target: { value } }: any) => setQuery(value)}
                  />
                </Col>
              )}
              <Col span={isMobile ? 18 : 6}>
                <DatePicker
                  hasCustomPlacement
                  isPremium={isPremium}
                  timeRangeOptions={timeRangeOptions(isPremium)}
                  isStepByDay={isStepByDay}
                  refetchData={refetchData}
                  className="custom-react-datepicker-wrapper"
                  setTimeRangeData={setTimeRangeData}
                  setIsStepByDay={setIsStepByDay}
                  setPage={setPage}
                />
              </Col>
              {!isMobile && (
                <>
                  <Col span={3}>
                    <SC.SelectFilterButton
                      options={mappedEmployees}
                      placeholder={<IntlLabel label="reports.filterStaff.placeholder" />}
                      defaultValue="reports.filterStaff.placeholder"
                      value={selectedStaffValue}
                      onChange={onStaffChange}
                      onDefaultChange={selectAllStaffValues}
                      dropdownStyle={{ minWidth: 'max-content' }}
                    />
                  </Col>
                  <Col span={3}>
                    <SC.SelectFilterButton
                      options={mappedLocations}
                      value={selectedLocationValue}
                      placeholder={<IntlLabel label="reports.filterLocations.placeholder" />}
                      defaultValue="reports.filterLocations.placeholder"
                      onChange={onLocationChange}
                      onDefaultChange={selectAllLocationValues}
                      dropdownStyle={{ minWidth: 'max-content' }}
                    />
                  </Col>
                  <Col span={2}>
                    <SC.ClearFilterButton upperCase={false} type="link">
                      <Text size="base" color="link" weight="normal" mb="none" onClick={clearFilters}>
                        <IntlLabel label="reports.clearFilters.button.title" />
                      </Text>
                    </SC.ClearFilterButton>
                  </Col>
                </>
              )}
              {isMobile && (
                <>
                  <Col span={3}>
                    <SC.ToolsButton icon="search" onClick={handleVisibleMobileSearch} />
                  </Col>
                  <Col span={3}>
                    <SC.ToolsButton icon="tools" onClick={toggleFilter} />
                  </Col>
                </>
              )}
              <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Popover
                  content={
                    <SC.PopoverContentRow>
                      <Text
                        size="base"
                        colorType="base"
                        mb="none"
                        onClick={() => exportCSVByClosingPopover('appointments', from, to)}
                      >
                        <IntlLabel label="reports.appointmentsList.title" />
                      </Text>
                    </SC.PopoverContentRow>
                  }
                  placement="bottomLeft"
                  trigger="click"
                  visible={isPopoverVisible}
                  onVisibleChange={handlePopoverVisibleChange}
                  overlayClassName={isMobile ? 'custom-mobile-popover' : 'custom-desktop-popover'}
                >
                  <SC.ExportCSVButton ghost={isMobile ? false : true} upperCase={false} minorBtn>
                    {!isMobile && <Text size="base" colorType="base" label="reports.exportCsv.title" mb="none" />}
                    <CustomIcon type="csv" />
                  </SC.ExportCSVButton>
                </Popover>
              </Col>
            </Row>
            <AppTable
              type="appointmentsReport"
              columns={appointmentsListColumns}
              className="reports-table"
              paginationSpacing="15px 0"
              isCustomPagination
              loading={loading}
              loadMore={loadMore}
              searchStr={query}
              hidePageSwitcher={false}
              apiConfig={{
                fetchData: () => data?.reports,
                mapping: {
                  pageKey: 'page',
                  dataKey: 'appointmentsReport',
                  totalCountKey: 'appointmentsReport.totalCount',
                  searchKey: 'query',
                  emptyStateKey: 'appointmentsReport.emptyState',
                },
              }}
            />
            {isMobileSearchVisible && (
              <Row className="w-auto mb-2" gutter={16}>
                <Col span={24}>
                  <SearchInput
                    suffix={<Icon type="search" style={{ color: '#61749D' }} />}
                    width="100%"
                    className="search-input-reports"
                    placeholder="reports.search.placeholder"
                    value={query}
                    onChange={({ target: { value } }: any) => setQuery(value)}
                  />
                </Col>
              </Row>
            )}
            <ReportsMobileFilter
              isVisible={isVisibleFilter}
              onClose={closeFilter}
              staffOptions={mappedEmployees}
              locationOptions={mappedLocations}
              selectedStaffValue={selectedStaffValue}
              selectedLocationValue={selectedLocationValue}
              clearFilters={clearFilters}
              setSelectedStaffValue={setSelectedStaffValue}
              setSelectedLocationValue={setSelectedLocationValue}
              selectAllStaffValues={selectAllStaffValues}
              selectAllLocationValues={selectAllLocationValues}
            />
          </AppLayout.Container>
        </SC.HashTabsWrapper>
      </AppLayout>
    </NoInternetConnection>
  );
}
