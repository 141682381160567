import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import * as api from 'api';
import { useAppContext } from '@plandok/core';
import { PermissionLevel } from 'constants/auth';

type Alert = {
  type: string;
  warningDate: string;
};

export default function useAlert() {
  const [isAlertLoading, setIsAlertLoading] = useState<boolean>(false);
  const [alerts, setAlerts] = useState<Alert[]>();

  const [context] = useAppContext();

  const isOwner = context.access.permissionLevel === PermissionLevel.OWNER;

  useEffect(() => {
    if (isOwner) {
      (async () => {
        try {
          setIsAlertLoading(true);
          const { data } = await api.fetchPaymentStatus();

          if (data.length) {
            const alertsObj: Alert[] = [];

            data.forEach((alert: Alert) => {
              if (typeof alert.warningDate === 'string') {
                alert.warningDate = format(new Date(alert.warningDate), 'dd/LL/yyyy');
              }

              alertsObj.push({ type: alert.type, warningDate: alert.warningDate });
            });
            setAlerts(alertsObj);
          }
        } catch (e) {
          console.error('Error when getting payment status');
        } finally {
          setIsAlertLoading(false);
        }
      })();
    }
  }, [isOwner]);

  return {
    isAlertLoading,
    subscriptionCancelled: alerts?.find((alert: any) => alert.type === 'subscription_cancelled'),
    notificationCancelled: alerts?.find((alert: any) => alert.type === 'notification_cancelled'),
    subscriptionPaymentFailed: alerts?.find((alert: any) => alert.type === 'subscription_payment_failed'),
    notificationPaymentFailed: alerts?.find((alert: any) => alert.type === 'notification_payment_failed'),
  };
}
