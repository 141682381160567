import startOfWeek from 'date-fns/startOfWeek';
import addDays from 'date-fns/addDays';
import React from 'react';
import { IntlDate } from '@plandok/i18n';
import { StickyTable } from 'components';

export default function StaffWorkHeader({ date }: { date: Date }) {
  const fromDate = startOfWeek(date, { weekStartsOn: 1 });
  return (
    <>
      {[...Array(7)].map((_, index) => (
        <StickyTable.Cell key={index} data-staff-hour-cell>
          <IntlDate date={addDays(fromDate, index)} dateFormat="EEE d MMM" />
        </StickyTable.Cell>
      ))}{' '}
    </>
  );
}
