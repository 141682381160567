import theme from '../../styles/theme';

export const roundCalendarTime = (time: number) => {
  const delta = time % 5;
  return delta > 2 ? Math.round(time + 5 - delta) : Math.round(time - delta);
};

export const calculateDynamicTime = (timeCard: any, deltaTime: number): any => {
  const dynamicStartTime = roundCalendarTime(timeCard.startTime + deltaTime);
  const dynamicEndTime = roundCalendarTime(timeCard.endTime + deltaTime);

  if (dynamicStartTime < 0) {
    return {
      dynamicStartTime: 0,
      dynamicEndTime: 0 + timeCard.duration,
    };
  } else if (dynamicEndTime > 1440) {
    return {
      dynamicStartTime: 1440 - timeCard.duration,
      dynamicEndTime: 1440,
    };
  }
  return {
    dynamicStartTime,
    dynamicEndTime,
  };
};

export const convertDistanceToTime = (distance: number, rowHeight: number) => {
  return (distance * 60) / rowHeight;
};

export const getNextColumnDelta = ({
  screenX,
  columnDelta,
  columnWidth,
  leftContainerOffset,
  columnIndex,
  columnsCount,
  scrollLeft,
}: any) => {
  const leftColumnsCount = columnIndex - 1;
  const rightColumnsCount = columnsCount - columnIndex;
  const startPoint =
    theme.timeColumnWidth + leftContainerOffset + columnDelta * columnWidth + columnIndex * columnWidth - scrollLeft;

  if (screenX < startPoint && columnDelta >= -leftColumnsCount) {
    return columnDelta - 1;
  }

  if (screenX > startPoint + columnWidth && columnDelta < rightColumnsCount - 1) {
    return columnDelta + 1;
  }

  return columnDelta;
};

export const getNextColumnDeltaMobile = ({ x, columnWidth, columnIndex, columnsCount }: any) => {
  const nextDelta = Math.round(x / columnWidth);

  const nextInd = nextDelta + columnIndex;

  if (nextInd < 0) {
    return -columnIndex;
  } else if (nextInd > columnsCount) {
    return columnsCount - columnIndex;
  }

  return nextDelta;
};
