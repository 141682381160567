import styled, { css } from 'styled-components';
import { mediaMobileDown } from '@plandok/core';

export const CalendarTitle = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 30px;
    color: ${(props) => props.theme.primaryColor};
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: 500;
    color: #adb6cc;
  }

  ${mediaMobileDown(css`
    justify-content: left;
    i {
      display: none;
    }
    span {
      color: ${(props) => props.theme.primaryColor};
    }
    border-bottom: 0;
  `)}
`;

export const Container = styled.div`
  margin-top: 40px;
  ${mediaMobileDown(css`
    margin-top: 0;
    padding-bottom: 40px;
  `)}
`;

export const PrintHeader = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  @media print {
    display: flex;
  }
`;

export const PrintHeaderUsername = styled.div`
  font-size: 28px;
  color: ${(props) => props.theme.primaryColor};
`;

export const PrintHeaderContactRow = styled.div`
  font-size: 14px;
  color: #000;
`;
