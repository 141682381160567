import styled from 'styled-components';
import { Avatar, Badge } from 'antd';
import React from 'react';
import { IntlLabel } from '@plandok/i18n';

const accessMapping = {
  owner: 'staff.access.owner',
  high: 'staff.access.high',
  medium: 'staff.access.medium',
  low: 'staff.access.low',
  basic: 'staff.access.basic',
  no_access: 'staff.access.no_access',
};

const Photo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 5px;
  :hover {
    cursor: pointer;
  }
`;

export const columnsConfig = [
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
    render: ({ value: { appointmentColor } }: any) => (
      <Badge color={appointmentColor} status="processing" className="status-badge staff-members-table__status-badge" />
    ),
  },
  {
    title: <IntlLabel label="table.staff.name" />,
    dataIndex: 'firstName',
    style: { minWidth: '200px' },
    render: ({ value }: any) =>
      value.photo ? (
        <div>
          <Photo src={value.photo} alt={value.firstName} />
          {value.firstName} {value.lastName}
        </div>
      ) : (
        <div>
          <Avatar className="mr-sm">{(value.firstName || '').substring(0, 2).toUpperCase()}</Avatar>
          {value.firstName} {value.lastName}
        </div>
      ),
  },
  {
    title: <IntlLabel label="table.staff.mobileNumber" />,
    dataIndex: 'phoneNumber',
    style: { minWidth: '200px' },
    key: 'phoneNumber',
  },
  {
    title: <IntlLabel label="table.staff.email" />,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: <IntlLabel label="table.staff.userPermission" />,
    dataIndex: 'permissionLevel',
    key: 'permissionLevel',
    style: { minWidth: '150px' },
    render: ({ value }: any) => <IntlLabel label={(accessMapping as any)[value.permissionLevel]} />,
  },
];
