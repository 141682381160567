// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from '../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AnalyticsQueryVariables = Types.Exact<{
  fromDate: Types.Scalars['Date'];
  fromDatePrevious: Types.Scalars['Date'];
  toDate: Types.Scalars['Date'];
  toDatePrevious: Types.Scalars['Date'];
  range?: Types.InputMaybe<Types.App_AnalyticsDateInterval>;
  step: Types.App_AppointmentsGraphStep;
}>;

export type AnalyticsQuery = {
  __typename?: 'AppQuery';
  currentPeriodAnalytics?: {
    __typename?: 'App_Analytics';
    appointments?: {
      __typename?: 'App_AppointmentsAnalytics';
      totalAppointments?: number | null;
      completedCount?: number | null;
      completedPercent?: number | null;
      notCompletedCount?: number | null;
      notCompletedPercent?: number | null;
      cancelledCount?: number | null;
      cancelledPercent?: number | null;
      noShowCount?: number | null;
      noShowPercent?: number | null;
      points?: Array<{
        __typename?: 'App_AppointmentPoint';
        point?: string | null;
        totalCount?: number | null;
        totalOnlineCount?: number | null;
      }> | null;
    } | null;
    graph?: {
      __typename?: 'App_AppointmentsAnalyticsGraph';
      points?: Array<{
        __typename?: 'App_AppointmentsAnalyticsGraphPoint';
        xValue: string;
        yAppointmentsCount: number;
        yOnlineBookingsCount?: number | null;
      }> | null;
    } | null;
    clients?: {
      __typename?: 'App_ClientsAnalytics';
      bookedCount?: number | null;
      averageVisitsCount?: number | null;
      newCount?: number | null;
      returningCount?: number | null;
      returningPercent?: number | null;
      byGender?: Array<{
        __typename?: 'App_ClientsByGenderAnalytics';
        gender?: Types.Gender | null;
        percent?: number | null;
      }> | null;
      byReferralSource?: Array<{
        __typename?: 'App_ClientsByReferralSourceAnalytics';
        name?: string | null;
        percent?: number | null;
      }> | null;
      byAge?: Array<{ __typename?: 'App_ClientsByAgeAnalytics'; name?: string | null; count?: number | null }> | null;
    } | null;
    occupancy?: {
      __typename?: 'App_OccupancyAnalytics';
      percent?: number | null;
      workingHoursCount?: number | null;
      bookedHoursCount?: number | null;
      bookedHoursPercent?: number | null;
      unbookedHoursCount?: number | null;
      unbookedHoursPercent?: number | null;
      blockedHoursCount?: number | null;
      blockedHoursPercent?: number | null;
    } | null;
    topCancellationReasons?: Array<{
      __typename?: 'App_TopCancellationReason';
      id?: any | null;
      name?: string | null;
      appointmentCount?: number | null;
      percent?: number | null;
    }> | null;
    topServices?: Array<{
      __typename?: 'App_TopService';
      id?: any | null;
      name?: string | null;
      bookingCount?: number | null;
    }> | null;
  } | null;
  previousPeriodAnalytics?: {
    __typename?: 'App_Analytics';
    appointments?: { __typename?: 'App_AppointmentsAnalytics'; totalAppointments?: number | null } | null;
    occupancy?: { __typename?: 'App_OccupancyAnalytics'; percent?: number | null } | null;
  } | null;
};

export const AnalyticsDocument = gql`
  query Analytics(
    $fromDate: Date!
    $fromDatePrevious: Date!
    $toDate: Date!
    $toDatePrevious: Date!
    $range: App_AnalyticsDateInterval
    $step: App_AppointmentsGraphStep!
  ) {
    currentPeriodAnalytics: analytics(fromDate: $fromDate, toDate: $toDate) {
      appointments(range: $range) {
        totalAppointments
        completedCount
        completedPercent
        notCompletedCount
        notCompletedPercent
        cancelledCount
        cancelledPercent
        noShowCount
        noShowPercent
        points {
          point
          totalCount
          totalOnlineCount
        }
      }
      graph(step: $step) {
        points {
          xValue
          yAppointmentsCount
          yOnlineBookingsCount
        }
      }
      clients {
        bookedCount
        averageVisitsCount
        newCount
        returningCount
        returningPercent
        byGender {
          gender
          percent
        }
        byReferralSource {
          name
          percent
        }
        byAge {
          name
          count
        }
      }
      occupancy {
        percent
        workingHoursCount
        bookedHoursCount
        bookedHoursPercent
        unbookedHoursCount
        unbookedHoursPercent
        blockedHoursCount
        blockedHoursPercent
      }
      topCancellationReasons {
        id
        name
        appointmentCount
        percent
      }
      topServices {
        id
        name
        bookingCount
      }
    }
    previousPeriodAnalytics: analytics(fromDate: $fromDatePrevious, toDate: $toDatePrevious) {
      appointments(range: $range) {
        totalAppointments
      }
      occupancy {
        percent
      }
    }
  }
`;

/**
 * __useAnalyticsQuery__
 *
 * To run a query within a React component, call `useAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      fromDatePrevious: // value for 'fromDatePrevious'
 *      toDate: // value for 'toDate'
 *      toDatePrevious: // value for 'toDatePrevious'
 *      range: // value for 'range'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<AnalyticsQuery, AnalyticsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AnalyticsQuery, AnalyticsQueryVariables>(AnalyticsDocument, options);
}
export function useAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsQuery, AnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AnalyticsQuery, AnalyticsQueryVariables>(AnalyticsDocument, options);
}
export type AnalyticsQueryHookResult = ReturnType<typeof useAnalyticsQuery>;
export type AnalyticsLazyQueryHookResult = ReturnType<typeof useAnalyticsLazyQuery>;
export type AnalyticsQueryResult = Apollo.QueryResult<AnalyticsQuery, AnalyticsQueryVariables>;
