import useClipboard from 'react-use-clipboard';
import { message } from 'antd';
import React from 'react';
import { withModal, Button } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { useOnlineBookingLinkQuery } from 'graphql/queries/onlineBookingLink.generated';
import * as SC from '../styles';

function DirectLinkForBookingsModal() {
  const { data: linkData } = useOnlineBookingLinkQuery();

  const copyUrl = linkData?.onlineBookingLink ? linkData?.onlineBookingLink : '';
  const [isCopied, setCopied] = useClipboard(copyUrl, {
    successDuration: 1000,
  });

  return (
    <SC.BookingLinksContainer>
      <h2>
        <IntlLabel label="ob.linkModal.title" />
      </h2>
      <h3>
        <IntlLabel label="ob.linkModal.description" />
      </h3>
      <div className="link-box">{copyUrl}</div>
      <div className="after-description">
        <IntlLabel label="ob.linkModal.afterDescription" />
      </div>
      <SC.ModalFooter>
        <span>
          <Button label="ob.btn.copyLink" type="primary" bold upperCase={false} onClick={setCopied} />
          {isCopied && message.success(<IntlLabel label="ob.linkModalCopySuccess.title" />)}
        </span>
      </SC.ModalFooter>
    </SC.BookingLinksContainer>
  );
}

export default withModal(null, { wide: true })(DirectLinkForBookingsModal);
