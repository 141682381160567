import React from 'react';
import { AppSetupList, NoInternetConnection } from 'components';
import * as api from 'api';
import { columnsConfig } from './config';

export default function CancellationReasonsPage() {
  return (
    <NoInternetConnection>
      <AppSetupList
        btnLabel="btn.new.cancelReason"
        fetchData={api.fetchCancelReasons}
        columns={columnsConfig}
        title="cancelReason.list.title"
        modalType="CREATE_CANCEL_REASON"
        placeholder={{
          imgName: 'cancelReason',
          titleLabel: 'state.cancelReason.title',
          descriptionLabel: 'state.cancelReason.description',
          btnLabel: 'state.cancelReason.button',
        }}
      />
    </NoInternetConnection>
  );
}
