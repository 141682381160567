import React from 'react';
import { useHistory } from '@plandok/core';
import { getUrlParams } from 'helpers/router';
import { RoutePath } from 'constants/routes';
import AppointmentStatusBtn from './AppointmentStatusBtn';

type StatusBtnProps = {
  status: string;
  id: string;
};

export default function StatusBtn({ status, id }: StatusBtnProps) {
  const backPath = getUrlParams()?.backPath;
  const history = useHistory();

  const onUpdateStatusAppointment = () => (backPath ? history.push(backPath) : history.push(RoutePath.CALENDAR));

  return <AppointmentStatusBtn status={status} id={id} onSuccess={onUpdateStatusAppointment} />;
}
