import styled, { css } from 'styled-components';
import { mediaMobileDown, mediaMdUp } from '@plandok/core';

export const ClientItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.primaryColor};

  [data-client-item-avatar] {
    margin-right: 15px;
  }

  [data-client-item-info] {
    line-height: 16px;
    div:first-of-type {
      font-size: 18px;
      font-weight: 500;
    }
    div:not(:first-of-type) {
      font-weight: 300;
      font-size: 14px;
    }
  }
`;

export const Container = styled.div<any>`
  height: 520px;
  overflow: auto;
  background: white;
  box-shadow: 0 3px 50px rgba(53, 38, 122, 0.15);
  border-radius: ${(props) => props.theme.borderRadiusBase};
  ${mediaMobileDown(css`
    display: none;
  `)}
` as any;

export const MobileContainer = styled.div`
  display: block;
  ${mediaMdUp(css`
    display: none;
  `)}
`;

export const ItemMainInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;

  i {
    font-size: 20px;
    color: #adb6cc;
  }

  ${mediaMobileDown(
    css`
      padding: 0 15px;
      i {
        margin-left: auto;
      }
    `
  )}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e9ecf3;
  padding: 25px 15px 15px 15px;

  ${ClientItem} {
    margin: 0 auto;
  }
`;

export const Summary = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 15px 0;
  [data-client-summary-major] {
    color: ${(props) => props.theme.primaryColor};
    font-weight: 600;
    text-align: center;
    font-size: 18px;
  }

  [data-client-summary-minor] {
    color: #adb6cc;
  }
`;

export const AppointmentList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AppointmentTitle = styled.div`
  margin-left: 15px;
  margin-bottom: 10px;
  font-weight: 500;
  color: ${(props) => props.theme.primaryColor};
`;
