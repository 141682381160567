import { Spin } from 'antd';
import React from 'react';
import { IntlLabel } from '@plandok/i18n';
import { useModal } from '@plandok/core';
import { AppLayout, MobileBackButton, NoInternetConnection } from 'components';
import { PriceType } from 'pages/plans/BillingPage/PricingPage/constants';
import useGetCurrentStaff from 'hooks/use-get-current-staff';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { RoutePath, StaffTabHash } from 'constants/routes';
import { useGetEstimatedCosts } from 'hooks/costs';
import { PermissionType } from 'constants/auth';
import { useLocation } from 'hooks/router';
import useQuery from 'hooks/data/useQuery';
import useAlert from 'hooks/use-alert';
import * as api from 'api';
import StaffPermissionsPage from './StaffPermissionsPage';
import StaffWorkingPage from './StaffWorkingPage';
import StaffMembersPage from './StaffMembersPage';
import StaffDatesPage from './StaffDatesPage';
import StaffTabs from './StaffTabs';
import './style.less';

export default function StaffPage() {
  const { isAlertLoading, subscriptionCancelled, notificationCancelled } = useAlert();
  const { estimatedCostsData, isLoading } = useGetEstimatedCosts();
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const { isStaffLoading, staffCount } = useGetCurrentStaff();
  const { location, navigate } = useLocation();
  const [, { showModal }] = useModal();

  const { data }: any = useQuery({
    fetchData: api.fetchPlans,
    extractDataFunc: (_) => _,
  });

  const planId = data?.meta.currentPlanId;

  const goToUpdateStaff = (id: string) => navigate(`${RoutePath.STAFF_UPDATE}/${id}`);

  const goToCreateStaff = () =>
    subscriptionCancelled || notificationCancelled
      ? showModal({
          type: 'LATEST_PAYMENT_FAILED',
          modalProps: { initialValues: { planType, planId } },
        })
      : planType === PriceType.PREMIUM
      ? staffCount === 5
        ? showModal({
            type: 'EXTRA_COSTS_FOR_NEW_MEMBER',
            modalProps: { subscription: estimatedCostsData?.subscription, isLoading },
          })
        : navigate(RoutePath.STAFF_CREATE)
      : staffCount < 5
      ? navigate(RoutePath.STAFF_CREATE)
      : showModal({ type: 'PREMIUM_UPGRADE_REQUIRED', modalProps: { staffPage: true } });

  const getSidebarTitle = () => {
    switch (true) {
      case location?.hash?.includes?.(StaffTabHash.WORKING_HOURS):
        return 'staff.menu.working';
      case location?.hash?.includes?.(StaffTabHash.USER_PERMISSIONS):
        return 'staff.menu.permissions';
      case location?.hash?.includes?.(StaffTabHash.CLOSED_DATES):
        return 'staff.menu.dates';
      default:
        return 'sidebar.staff';
    }
  };

  return (
    <NoInternetConnection>
      <Spin spinning={isSubscriptionLoading}>
        <AppLayout
          headerTitle="sidebar.staff"
          mobileHeader={() => (
            <>
              <MobileBackButton />
              <div>
                <IntlLabel label={getSidebarTitle()} />
              </div>
              <div />
            </>
          )}
          addButtonModal={'LATEST_PAYMENT_FAILED'}
          modalProps={{ initialValues: { planType, planId } }}
          isSubscriptionLoading={isSubscriptionLoading}
          planType={planType}
          addButtonPath={location?.hash?.includes?.(StaffTabHash.STAFF_MEMBERS) ? RoutePath.STAFF_CREATE : undefined}
        >
          <StaffTabs>
            <StaffMembersPage
              key={PermissionType.EMPLOYEES}
              goToCreateStaff={goToCreateStaff}
              goToUpdateStaff={goToUpdateStaff}
              isLoading={isStaffLoading || isAlertLoading}
            />
            <StaffWorkingPage key={PermissionType.WORKING_HOURS} />
            <StaffDatesPage key={PermissionType.CLOSED_DATES} />
            <StaffPermissionsPage key={PermissionType.PERMISSION_LEVELS} />
          </StaffTabs>
        </AppLayout>
      </Spin>
    </NoInternetConnection>
  );
}
