import React, { ReactNode, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { Popover } from 'antd';
import { CustomIcon, IconButton, Text, useLocation, Button } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import * as SC from './styles';

interface PrintButtonProps {
  isPremium: boolean;
  children: ReactNode;
  label?: string;
}

export default function PrintButton({ isPremium, label, children }: PrintButtonProps) {
  const [visible, setVisible] = useState(false);
  const { navigate } = useLocation();
  const componentRef = useRef<HTMLDivElement>(null);

  const toggleVisibleChange = (visible: boolean) => setVisible(visible);
  const goToPricingPage = () => navigate('/billing');

  return (
    <>
      {isPremium ? (
        <div className="d-flex">
          <ReactToPrint
            trigger={() => (
              <SC.PrintButton label={label}>
                {label && (
                  <Button block ghost upperCase={false}>
                    <CustomIcon type="printGrey" />
                    <Text as="span" colorType="base" mb="none" size="small" weight="normal" ml="xsmall" isCursor>
                      <IntlLabel label={label} />
                    </Text>
                  </Button>
                )}
                {!label && <IconButton icon="print" />}
              </SC.PrintButton>
            )}
            content={() => componentRef.current}
          />
          <SC.PrintContent ref={componentRef} className="print-source">
            <div className="print-source">{children}</div>
          </SC.PrintContent>
        </div>
      ) : (
        <Popover
          content={
            <div>
              <SC.PopoverTitle>
                <CustomIcon type="premium" />
                <Text colorType="yellow" mb="none" size="medium" weight="medium">
                  <IntlLabel label="title.premiumFeature" />
                </Text>
              </SC.PopoverTitle>
              <SC.PopoverDescription>
                <Text colorType="base" mb="none" size="small" weight="normal">
                  <IntlLabel label="calendar.print.description" />
                  <Text
                    as="span"
                    colorType="base"
                    mb="none"
                    size="small"
                    weight="medium"
                    ml="xsmall"
                    onClick={goToPricingPage}
                    isCursor
                  >
                    <IntlLabel label="calendar.billing.title" />
                  </Text>
                </Text>
              </SC.PopoverDescription>
            </div>
          }
          trigger="click"
          placement="bottom"
          visible={visible}
          onVisibleChange={toggleVisibleChange}
        >
          <SC.PrintButton label={label}>
            {label && (
              <Button block ghost upperCase={false}>
                <CustomIcon type="printGrey" />
                <Text as="span" colorType="base" mb="none" size="small" weight="normal" ml="xsmall" isCursor>
                  <IntlLabel label={label} />
                </Text>
              </Button>
            )}
            {!label && <IconButton icon="print" />}
          </SC.PrintButton>
        </Popover>
      )}
    </>
  );
}
