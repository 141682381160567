import React, { useEffect, useState } from 'react';
import startOfWeek from 'date-fns/startOfWeek';
import { useQuery } from 'react-query';
import { SelectInput } from '@plandok/core';
import { queryLocationOptions, queryEmployeesHours, QueryType } from 'api/query';
import calendarState from 'pages/dashboard/CalendarPage/store/calendar.state';
import { PageControlContainer, DateFilterItem } from 'components';
import StaffWorkTable from './components/StaffWorkTable';
import * as helpers from './helpers';

export default function StaffWorkingPage() {
  const [date, setDate] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [timeFormat, setTimeFormat] = useState('');

  const { data: locations, isFetching: isLoadingLocations } = useQuery(
    QueryType.LOCATION_OPTIONS,
    queryLocationOptions,
    {
      onSuccess: (data) => {
        setLocation(data?.[0]?.code);
      },
    }
  );

  const [location, setLocation] = useState(locations?.[0]?.code);

  const {
    data,
    isFetching: isLoading,
    refetch,
  } = useQuery(
    location && [QueryType.EMPLOYEE_HOURS, helpers.getFilterParams(new Date(date ?? 0), location)],
    queryEmployeesHours
  );

  const fetchData = () => refetch({ force: true });

  useEffect(() => {
    calendarState.initialFetchData().then(() => {
      const { timeFormat } = calendarState.data ?? {};

      setTimeFormat(timeFormat);
    });
  }, []);

  const loading = isLoading || !timeFormat;

  return (
    <>
      <PageControlContainer mobileColumn>
        <PageControlContainer.Item>
          {locations?.length > 1 ? (
            <SelectInput
              filter
              className="mr-sm"
              onChange={setLocation}
              value={location}
              options={locations}
              loading={isLoadingLocations}
              style={{ minWidth: '150px' }}
            />
          ) : null}
        </PageControlContainer.Item>
        <PageControlContainer.Item>
          <DateFilterItem date={date} changeDate={setDate} viewType="week" />
        </PageControlContainer.Item>
        <PageControlContainer.Space />
      </PageControlContainer>
      <StaffWorkTable
        data={data || []}
        date={date}
        isLoading={loading}
        locationId={location}
        fetchData={fetchData}
        timeFormat={timeFormat}
      />
    </>
  );
}
