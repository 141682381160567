import { observable, action, computed } from 'mobx';
import { message } from 'antd';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { queryCall } from '../../../../graphql';

export interface TableStoreInitializeProps {
  // for some api we need to send page - 1 instead page
  gqlQuery?: any;
  apiType?: 'rest' | 'gql';
  magicMinus?: boolean;
  fetchData?: (...a: any[]) => any;
  // Mean that we need to send instead of offset -> offset * limit
  // so if you will send 1 it will skip only 1 record instead of 10 if actualOffset is false
  actualOffset?: boolean;
  mapping?: {
    pageKey: string;
    limitKey?: string;
    dataKey: string;
    totalCountKey: string;
    searchKey?: string;
    maxCountKey?: string;
    emptyStateKey?: string;
  };
}

class TableStore {
  // for some api we need to send page - 1 instead page
  @observable magicMinus = false; // TODO remove it in future

  @observable page = 1;
  @observable totalCount = 0;
  @observable maxCount = 10;
  @observable isLoading = false;
  @observable initialized = false;
  @observable error = '';
  @observable searchStr = '';
  @observable debouncedQuery = '';
  @observable actualOffset = false;
  @observable isNodeData = false;
  @observable mapping: any = { pageKey: 'page', dataKey: 'data' };
  @observable data: { [key: string]: any[] } = {};
  @observable showPlaceholder: boolean = false;
  @observable apiCallType: 'rest' | 'gql' | undefined = 'rest';
  @observable gqlQuery: any = '';
  @observable gqlParams: any = {};
  @observable fetchDataAction: any = () => {
    try {
    } catch (error) {
      console.log('%cerror', 'color: green; font-size: 1.5rem;', error);
      throw new Error('Set this method for correct work');
    }
  };

  @observable fetchGqlDataAction: any = () => {
    try {
    } catch (error) {
      console.log('%cerror', 'color: green; font-size: 1.5rem;', error);
      throw new Error('Set this method for correct work');
    }
  };

  @computed get count() {
    return this.data[this.getCacheKey(this.page)]?.length ?? 0;
  }

  @computed get hasMore() {
    return this.totalCount > this.allData?.length; // TODO
  }

  @computed get currentData() {
    if (this.data[this.getCacheKey(this.page)]) {
      let index = 0;
      return this.data[this.getCacheKey(this.page)].map((v) => ({ ...v, key: index++ }));
    }
    return [];
  }

  @computed get allData() {
    if (this.searchStr) {
      return Object.keys(this.data)
        .filter((e) => e.includes(this.getSearchStrCachePart()))
        .sort(
          (a, b) =>
            parseInt(a.replace(this.getSearchStrCachePart(), ''), 10) -
            parseInt(b.replace(this.getSearchStrCachePart(), ''), 10)
        )
        .reduce((acc, k) => [...acc, ...(this.data[k] || [])] as any, []);
    }
    return Object.values(this.data).reduce((acc, a) => [...(acc || []), ...(a || [])], []) || [];
  }

  extractGqlParams = (resp: any) => {
    if (resp.pageInfo) {
      this.gqlParams.after = resp.pageInfo.endCursor;
      // this.gqlParams.before = resp.pageInfo.startCursor;
    }
  };
  extractData = (resp: any) => {
    const getResData = get(resp, this.mapping.dataKey);
    if (Boolean(getResData?.edges)) {
      this.extractGqlParams(getResData);
      return get(resp, this.mapping.dataKey)?.edges?.map(({ node }: any) => {
        this.isNodeData = true;
        if (Boolean(node.firstName)) {
          return { ...node, user: `${node.firstName} ${node.lastName || ''}` };
        }
        if (Boolean(node.clientFirstName) || Boolean(node.cancelledByFirstName) || Boolean(node.employeeFirstName)) {
          return {
            ...node,
            client: `${node.clientFirstName || ''} ${node.clientLastName || ''}`,
            employee: `${node.employeeFirstName || ''} ${node.employeeLastName ?? ''}`,
            cancelledBy: `${node.cancelledByFirstName || ''} ${node.cancelledByLastName ?? ''}`,
          };
        }

        return node;
      });
    }
    return getResData;
  };

  extractTotalCount = (resp: any) => {
    return get(resp, this.mapping.totalCountKey) ?? 0;
  };

  getFilterParams = (page: number) => {
    const result: any = { [this.mapping.pageKey]: page };

    if (this.magicMinus) {
      result[this.mapping.pageKey] -= 1;
    }
    if (this.actualOffset) {
      result[this.mapping.pageKey] = result[this.mapping.pageKey] * this.maxCount;
    }
    if (this.mapping.searchKey && this.searchStr) {
      result[this.mapping.searchKey] = this.searchStr;
    }
    if (this.mapping.limitKey && this.maxCount) {
      result[this.mapping.limitKey] = this.maxCount;
    }
    if (this.mapping.maxCountKey) {
      result[this.mapping.maxCountKey] = this.maxCount;
    }

    return result;
  };

  filterGqlDataBySearchParams = (result: any, query?: string) => {
    if (query) {
      const filteredResult =
        result?.[this.mapping.dataKey]?.edges?.filter((item: any) =>
          Boolean(
            item?.node?.clientFirstName?.toLowerCase().includes(query.toLowerCase()) ||
              item?.node?.clientLastName?.toLowerCase().includes(query.toLowerCase())
          )
        ) ?? [];

      const mergedResult = {
        ...result,
        [this.mapping.dataKey]: { edges: filteredResult, totalCount: filteredResult.length },
      };
      return mergedResult;
    } else {
      return result;
    }
  };

  getSearchStrCachePart = () => {
    return this.searchStr ? `:query${this.searchStr}` : '';
  };

  getCacheKey = (page: number) => {
    return `${page}${this.getSearchStrCachePart()}`;
  };

  @action fetchData = async (page: number = 1, freshData?: any) => {
    if (this.isLoading) {
      return;
    }
    if (this.data[this.getCacheKey(page)]) {
      this.page = page;
      return;
    }
    this.isLoading = true;

    try {
      if (this.apiCallType === 'gql') {
        this.fetchDataAction = queryCall(this.gqlQuery, { ...this.gqlParams });
      }

      let resp =
        freshData && typeof freshData !== 'number'
          ? this.filterGqlDataBySearchParams(freshData, this.debouncedQuery)
          : await this.fetchDataAction(this.getFilterParams(page));

      const data = this.extractData(resp);

      this.totalCount = this.extractTotalCount(resp);

      if (this.mapping?.emptyStateKey) {
        this.showPlaceholder = !!get(resp, this.mapping?.emptyStateKey);
      } else {
        if (!this.totalCount && !data?.length) {
          this.showPlaceholder = true;
        }
      }

      this.data[this.getCacheKey(page)] = data;
      this.page = page;
      this.isLoading = false;

      if (!this.initialized) {
        this.initialized = true;
      }
    } catch (e) {
      message.error('Failed to load data, please contact support');
      this.isLoading = false;
    }
  };

  @action fetchDataDebounced = debounce(async (query?: string) => {
    this.page = 1;
    this.searchStr = query || '';

    if (!this.isNodeData) {
      this.clearCache();
    }
    await this.fetchData();
  }, 50);

  @action fetchFreshData = async (data?: any) => {
    this.clearCache();
    this.gqlParams.after = '';
    await this.fetchData(this.page, data);
  };

  @action pageChange = async (page: any) => {
    await this.fetchData(page);
  };

  @action initialize = ({
    fetchData,
    mapping,
    magicMinus,
    actualOffset,
    apiType,
    gqlQuery,
  }: TableStoreInitializeProps) => {
    this.mapping = mapping;
    this.actualOffset = !!actualOffset;
    this.magicMinus = !!magicMinus;
    this.apiCallType = apiType ?? 'rest';
    this.gqlQuery = gqlQuery;
    this.fetchDataAction = fetchData ?? queryCall(gqlQuery);
  };

  @action clearCache = () => {
    this.data = {};
  };

  @action resetFilter = () => {
    this.searchStr = '';
    this.page = 0;
    this.totalCount = 0;
    this.maxCount = 10;
  };

  @action onUnmount = () => {
    this.showPlaceholder = false;
    this.initialized = false;
    this.resetFilter();
    this.clearCache();
  };
}

export type TableStoreTypes =
  | 'messages'
  | 'clients'
  | 'closedDates'
  | 'bookingsByEmployee'
  | 'bookingsByService'
  | 'clientsReport'
  | 'appointmentCancellations'
  | 'appointmentsByCancellationReason'
  | 'appointmentsReport'
  | 'invoices'
  | 'message'
  | 'messageLog';

class TableStoreFactory {
  tableStores: { [key: string]: TableStore } = {
    key: new TableStore(),
  };

  getTableStoreInstance = (type: TableStoreTypes) => {
    let tableStore = this.tableStores[type];

    if (!tableStore) {
      tableStore = new TableStore();
      this.tableStores[type] = tableStore;
    }

    return tableStore;
  };
}

export default new TableStoreFactory();
