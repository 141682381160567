import React, { useState } from 'react';
import { Switch, Collapse } from 'antd';
import { Text, CustomIcon } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import MoreButtonExpended from './MoreButtonExpended';
import { useLocation } from 'hooks/router';
import * as SC from '../styles';

const MobileCampaignCard = ({ indexNumber, headerText, icon, description, perView, resetCampaign, url }: any) => {
  const [activeSwitch, setActiveSwitch] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const { navigate } = useLocation();
  const { Panel } = Collapse;

  const startCampaigns = () => navigate(url);
  const editCampaigns = () => navigate(url);

  function callback(key: any) {
    key.length ? setShowActive(false) : setShowActive(true);
  }
  return (
    <SC.MobileCampaignsCard>
      <Collapse onChange={callback}>
        <SC.ActiveText>
          {showActive && (
            <Text
              style={{ color: activeSwitch ? '#009329' : 'inherit' }}
              label={activeSwitch ? 'ob.analytics.active' : 'ob.analytics.inactive'}
              size="base"
              weight="normal"
              mb="none"
              ml="xsmall"
            />
          )}
        </SC.ActiveText>
        <Panel header={headerText} key={indexNumber} extra={<CustomIcon type={icon as any} />}>
          <SC.DescriptionContainer>
            <Text size="small" weight="normal" mb="xsmall" style={{ width: '255px' }}>
              <IntlLabel label={description} />
            </Text>
            <MoreButtonExpended perView={perView} resetCampaign={resetCampaign} />
          </SC.DescriptionContainer>
          <SC.MobileCampaignsCardFooter>
            <SC.SwitchBtn>
              <Switch defaultChecked checked={activeSwitch} onChange={setActiveSwitch} />
              <Text
                style={{ color: activeSwitch ? '#009329' : 'inherit' }}
                label={activeSwitch ? 'ob.analytics.active' : 'ob.analytics.inactive'}
                size="base"
                weight="normal"
                mb="none"
                ml="xsmall"
              />
            </SC.SwitchBtn>
            {activeSwitch ? (
              <SC.CampaignsCardBtn onClick={editCampaigns}>
                <CustomIcon type="edit" />
                <IntlLabel label="Edit Campaign" />
              </SC.CampaignsCardBtn>
            ) : (
              <SC.CampaignsCardBtn onClick={startCampaigns}>
                <IntlLabel label="Start Campaign" />
                <CustomIcon type="arrow" />
              </SC.CampaignsCardBtn>
            )}
          </SC.MobileCampaignsCardFooter>
        </Panel>
      </Collapse>
    </SC.MobileCampaignsCard>
  );
};

export default MobileCampaignCard;
