import React from 'react';

import PageControlContainer from '../../../../components/app/PageControlContainer';
import PermissionForm from './components/PermissionForm';

export default function StaffPermissionsPage() {
  return (
    <>
      <PageControlContainer mobileHidden>
        <PageControlContainer.Item />
        <PageControlContainer.Space />
        <PageControlContainer.Item mobileHidden></PageControlContainer.Item>
      </PageControlContainer>
      <PermissionForm />
    </>
  );
}
