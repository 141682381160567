/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
  'login.title': 'Login to your account',
  'label.email': 'Email address',
  'placeholder.email': 'e.g johndoe@gmail.com',
  'input.password.label': 'Password',
  'input.password.placeholder': 'Enter password',
  'login.btn': 'Login to Plandok',
  'link.sign.in': 'Login',
  'label.password': 'Enter password',
  'login.forgot.password': 'Forgot password?',
  'login.no.account': 'Do not have an account?',
  'login.description': 'If you are having trouble logging in you can try resetting your password',
  'register.title': 'Create an account',
  'register.description': 'Create a free account in the Plandok meeting planning and booking app',
  'register.btn': 'Create my account',
  'register.agreement.agree': 'I agree to the',
  'register.agreement.terms': 'Website Terms',
  'register.agreement.policy': 'Privacy Policy',
  'register.agreement.and': 'and',
  'register.agreement.partner': 'Partner Terms of Business',
  'register.have.account': 'Already have an account ?',
  'register.validation.confirm': 'Before proceeding, please review and accept the terms above.',
  'link.sign.up': 'Register',
  'text.alreadyHaveAccount': 'Already have an account ?',
  'input.company.label': 'Company',
  'input.company.placeholder': 'Company',
  'input.country.label': 'Country',
  'input.country.placeholder': 'Country name',
  'input.timeZone.label': 'Time zone',
  'input.timeZone.placeholder': 'GMT +02.00',
  'input.currency.label': 'Currency',
  'input.language.label': 'Language',
  'input.language.placeholder': 'English',
  'input.currency.placeholder': 'Euro - EUR',
  'reset.title': 'Reset password',
  'reset.btn.text': 'Send Password Reset',
  'reset.pwd.btn': 'Reset password',
  'reset.no.password': 'Do not want to reset password?',
  'reset.description':
    "Please provide your email address and we'll send you instructions on how to change your password.",
  'sidebar.calendar': 'Calendar',
  'sidebar.client': 'Clients',
  'sidebar.staff': 'Staff',
  'sidebar.service': 'Services',
  'sidebar.notification': 'Messages',
  'sidebar.settings': 'Settings',
  'sidebar.menu': 'Menu',
  'sidebar.analytics': 'Analytics',
  'sidebar.language': 'Language',
  'sidebar.logout': 'Logout',
  'user.menu.settings': 'My Settings',
  'user.menu.language': 'Change Language',
  'user.menu.logout': 'Log out',
  'input.globalSearch.placeholder': 'What are you looking for ?',
  'input.globalSearch.description': 'Search by client name, mobile, email or booking reference',
  'title.upcomingAppointments': 'Upcoming Appointments',
  'calendar.day': 'Day',
  'calendar.week': 'Week',
  'calendar.monday': 'Monday',
  'calendar.tuesday': 'Tuesday',
  'calendar.wednesday': 'Wednesday',
  'calendar.thursday': 'Thursday',
  'calendar.friday': 'Friday',
  'calendar.saturday': 'Saturday',
  'calendar.sunday': 'Sunday',
  'calendar.mon': 'Mon',
  'calendar.tue': 'Tue',
  'calendar.wed': 'Wed',
  'calendar.thu': 'Thu',
  'calendar.fri': 'Fri',
  'calendar.sat': 'Sat',
  'calendar.sun': 'Sun',
  'calendar.today': 'Today',
  'calendar.january': 'January',
  'calendar.february': 'February',
  'calendar.march': 'March',
  'calendar.april': 'April',
  'calendar.may': 'May',
  'calendar.june': 'June',
  'calendar.july': 'July',
  'calendar.august': 'August',
  'calendar.september': 'September',
  'calendar.october': 'October',
  'calendar.november': 'November',
  'calendar.december': 'December',
  'calendar.jan': 'Jan',
  'calendar.feb': 'Feb',
  'calendar.mar': 'Mar',
  'calendar.apr': 'Apr',
  'calendar.may.short': 'May',
  'calendar.jun': 'Jun',
  'calendar.jul': 'July',
  'calendar.aug': 'Aug',
  'calendar.sept': 'Sept',
  'calendar.oct': 'Oct',
  'calendar.nov': 'Nov',
  'calendar.dec': 'Dec',
  'calendar.allStaff': 'All',
  'calendar.workingStaff': 'Working',
  'calendar.tooltip.time': 'Time',
  'calendar.tooltip.category': 'Category',
  'calendar.tooltip.service': 'Service',
  'calendar.tooltip.staff': 'Staff',
  'calendar.tooltip.price': 'Price',
  'calendar.tooltip.repeating': 'Repeating',
  'btn.viewDetails': 'View Details',
  'calendar.tooltip.emptyCustomer': 'Walk-in',
  'calendar.tooltip.bookedOnline': 'Online Booking',
  'calendar.print.description': 'In order to use print function your plan must be upgraded. To upgrade visit',
  'calendar.print.appointmentsBetween.title': 'Appointments between',
  'calendar.print.appointmentsAnd.title': 'and',
  'calendar.billing.title': 'Plan and Billing',
  'calendar.btn.print.title': 'Print',
  'datepicker.next.week': 'Next week',
  'datepicker.in.two.weeks': 'In 2 weeks',
  'datepicker.in.three.weeks': 'In 3 weeks',
  'datepicker.in.four.weeks': 'In 4 weeks',
  'datepicker.in.five.weeks': 'In 5 weeks',
  'datepicker.in.six.weeks': 'In 6 weeks',
  'modal.splitAppointment.title': 'Split Appointment',
  'modal.splitAppointment.text':
    'This appointment is part of a group, moving it to another day will split it to become a separate booking. Are you sure?',
  'blocktime.create.modal.title': 'Block Time',
  'blocktime.edit.modal.title': 'Edit Block Time',
  'appointment.create.menu': 'Appointment',
  'blocktime.create.menu': 'Block Time',
  'appointment.new.title': 'New Appointment',
  'input.startTime.label': 'Start time',
  'input.endTime.label': 'End time',
  'input.startDate.label': 'Start Date',
  'input.endDate.label': 'End Date',
  'input.startDate.description.label': 'Select start Date',
  'input.endDate.description.label': 'Select end Date',
  'input.closedDatedDescription.placeholder': 'e.g. holidays',
  'input.service.label': 'Service',
  'input.services.label': 'services',
  'input.service.placeholder': 'Choose a service',
  'input.staff.label': 'Staff',
  'input.staff.placeholder': 'Select staff',
  'input.pickDateAndTime.title': 'Pick date and time',
  'input.pickDate.title': 'Pick a date',
  'service.add.another': 'Add another service',
  'input.appointmentNotes.label': 'Appointments notes',
  'input.emailSubject.placeholder': 'Note about your appointment',
  'input.appointmentNotes.placeholder': 'Add appointment note (visible to staff only)',
  'input.searchClients.placeholder': 'Search Client',
  'input.searchService.placeholder': 'Search',
  'appointment.empty.list': 'Use the search to add a client, or keep empty to save as walk-in.',
  'appointment.clients.empty.list': 'Client list is empty.',
  'appointment.clients.addClient': 'Create client',
  'client.create.new': 'Create new client',
  'client.choose': 'Choose client',
  'client.menu.edit': 'Edit client details',
  'client.menu.remove': 'Remove from appointment',
  'appointment.btn.save': 'Save',
  'appointment.vat': 'VAT ({0}%)',
  'appointment.total': 'Total',
  'appointment.view.title': 'View Appointment',
  'appointment.status.btn': 'Appointment status',
  'appointment.btn.cancel': 'Delete',
  'btn.cancelAppointment': 'Delete',
  'modal.cancelAppointment.title': 'Delete',
  'title.clients': 'Clients',
  'client.create.title': 'New Client',
  'client.options.export.title': 'Export clients',
  'client.options.export.description': 'Download CSV with client data',
  'client.options.import.title': 'Import clients',
  'client.export.premium.description': 'In order to use Export function your plan must be upgraded.',
  'client.export.premium.visit': 'To upgrade visit ',
  'client.import.instructions.description': 'Follow instructions below to import clients to your Plandok account',
  'client.import.instructions.download': 'Download your import file using the button below',
  'client.import.instructions.copy': 'Copy and paste your client details into the file',
  'client.import.instructions.email': 'Email it to us at info@plandok.com',
  'client.import.instructions.account': 'and we will import to your account',
  'table.clients.name': 'Name',
  'table.clients.phone': 'Phone',
  'table.clients.mobile': 'Phone',
  'table.clients.email': 'Email',
  'table.clients.gender': 'Gender',
  'client.page.title': 'Client profile',
  'btn.more.options': 'More Options',
  'btn.showAll': 'Show all',
  'btn.collapse': 'Collapse',
  'btn.reschedule': 'Reschedule',
  'title.edit.client': 'Edit Client',
  'menu.edit.client': 'Edit client',
  'menu.delete.client': 'Delete client',
  'title.mobilePhone': 'Mobile Phone',
  'title.telephoneNumber': 'Telephone number',
  'title.email': 'Email',
  'title.address': 'Address',
  'title.dateOfBirth': 'Date of Birth',
  'title.gender': 'Gender',
  'client.statistics': 'Statistics',
  'client.totalSales': 'Total sales',
  'client.totalBookings': 'Total Bookings',
  'client.outstanding': 'Outstanding',
  'client.allBooking': 'All booking',
  'client.completed': 'Completed',
  'client.cancelled': 'Canceled',
  'client.noShow': 'No Show',
  'appointment.list.title': 'Appointments',
  'upcoming.list.title': 'Upcoming',
  'past.list.title': 'Past',
  'modal.confirmClientDelete.text.0': 'Are you sure want to permanently delete client',
  'modal.confirmClientDelete.text.1': 'from database ?',
  'input.date.label': 'Specific Date',
  'input.date.placeholder': 'Select Date',
  'input.firstName.label': 'First Name',
  'input.firstName.placeholder': 'e.g. John',
  'input.lastName.label': 'Last Name',
  'input.lastName.placeholder': 'e.g. Doe',
  'input.mobile.label': 'Mobile number',
  'input.mobile.placeholder': 'Enter number',
  'input.email.label': 'Email address',
  'input.email.placeholder': 'e.g. johndoe@gmail.com',
  'input.title.label': 'Title',
  'input.title.placeholder': 'e.g. Dentist',
  'input.notificationType.label': 'Send notification by',
  'tab.personalInfo': 'Personal Info',
  'input.gender.label': 'Gender',
  'input.referal.label': 'Referal Source',
  'input.referal.placeholder': 'Select Source',
  'input.birthDay.label': 'Birthday',
  'input.month.placeholder': 'Month',
  'input.day.placeholder': 'Day',
  'input.year.placeholder': 'Year',
  'input.clientsNotes.label': 'Client notes',
  'input.clientsNotes.placeholder': 'Enter client notes',
  'input.bookingDisplay.label': 'Display on all bookings',
  'input.serviceDescription.label': 'Service description',
  'input.serviceDescription.placeholder':
    'Add a short description which will be visible for customers in online bookings',
  'input.isBookableOnline.label': 'Available for online bookings',
  'service.onlineBookings.title': 'Online bookings',
  'tab.address': 'Address',
  'input.address.label': 'Address',
  'input.address.placeholder': 'Enter your address',
  'input.city.label': 'City',
  'input.city.placeholder': 'Enter city',
  'input.zip.label': 'Zip / Post Code',
  'input.zip.placeholder': 'Enter Post Code',
  'btn.edit': 'Edit',
  'btn.cancel': 'Cancel',
  'btn.save': 'Save',
  'btn.save.changes': 'Save',
  'tabs.staffWorking.title': 'Working Hours',
  'tabs.staffMembers.title': 'Staff Members',
  'tabs.userPermissions.title': 'User Permissions',
  'tabs.closedDates.title': 'Closed Dates',
  'btn.new.staff': 'New Staff',
  'table.staff.name': 'Name',
  'table.staff.mobileNumber': 'Phone',
  'table.staff.email': 'Email',
  'table.staff.userPermission': 'User permission',
  'table.staff.dataRange': 'Date Range',
  'table.staff.numberOfDays': 'Number of Days',
  'table.staff.location': 'Location',
  'table.staff.description': 'Description',
  'table.appointmentsList.appointment': 'Appointment',
  'table.appointmentsList.client': 'Client',
  'table.appointmentsList.service': 'Service',
  'table.appointmentsList.date': 'Date',
  'table.appointmentsList.time': 'Time',
  'table.appointmentsList.duration': 'Duration',
  'table.appointmentsList.location': 'Location',
  'table.appointmentsList.staff': 'Staff',
  'table.appointmentsList.price': 'Price',
  'table.appointmentsList.status': 'Status',
  'table.appointmentsCancellations.ref': 'Ref',
  'table.appointmentsCancellations.client': 'Client',
  'table.appointmentsCancellations.service': 'Service',
  'table.appointmentsCancellations.scheduledDate': 'Scheduled Date',
  'table.appointmentsCancellations.cancelledDate': 'Cancelled Date',
  'table.appointmentsCancellations.cancelledBy': 'Cancelled by',
  'table.appointmentsCancellations.reason': 'Reason',
  'table.appointmentsCancellations.price': 'Price',
  'table.appointmentsCancellations.totalCount': 'Total Count',
  'table.clients.appointments': 'Appointments',
  'table.clients.noShows': 'No Shows',
  'table.clients.totalSales': 'Total Sales',
  'table.clients.added': 'Added',
  'table.clients.daysAbsent': 'Days Absent',
  'table.clients.lastAppointment': 'Last Appointment',
  'table.clients.lastLocation': 'Last Location',
  'table.appointmentsByService.serviceName': 'Service Name',
  'table.appointmentsByService.appointments': 'Appointments',
  'table.appointmentsByService.totalValue': 'Total Value',
  'table.appointmentsByStaff.staffMember': 'Staff Member',
  'table.appointmentsByStaff.appointments': 'Appointments',
  'table.appointmentsByStaff.totalValue': 'Total Value',
  'table.pagination.showing.title': 'Showing',
  'table.pagination.results.title': 'results',
  'clients.duplicate.title': 'Duplicate clients found.',
  'clients.duplicate.description': 'There are similar clients that can be merged.',
  'clients.merge.btn.title': 'Merge',
  'clients.merge.modal.title': 'Merge clients',
  'clients.merge.selectAll.title': 'Select all duplicates',
  'clients.merge.duplicatesNotFound.title': 'Duplicates not found',
  'clients.merge.duplicatesNotFound.description': 'There are no clients available to merge',
  'clients.mergeConfirm.modal.title': 'Confirm Merge',
  'clients.mergeConfirm.modal.description':
    'Are you sure you want to merge {0} duplicates? This action is permanent and cannot be undone.',
  'clients.mergeConfirm.checkbox.title': 'I understand client merging cannot be undone',
  'clients.mergeConfirm.btn.title': 'Confirm & Merge',
  'clients.mergeConfirm.cancel.btn.title': 'Cancel',
  'clients.byNumber.btn.title': 'By Phone {0}',
  'clients.byEmail.btn.title': 'By Email {0}',
  'clients.byName.btn.title': 'By Name {0}',
  'title.newStaff': 'New staff',
  'title.editStaff': 'Edit staff',
  'description.extraCharge': 'By adding new staff, extra charge will be added to your monthly subscription',
  'tabs.details': 'Details',
  'input.staffPicture.label': 'Picture',
  'input.cropImage.title': 'Crop Image',
  'input.cropImage.btn.apply.title': 'Apply',
  'input.stafffirstName.label': 'First Name',
  'input.stafffirstName.placeholder': 'e.g. John',
  'input.stafflastName.label': 'Last name',
  'input.stafflastName.placeholder': 'e.g. Doe',
  'input.stafmobile.label': 'Phone',
  'input.stafemail.label': 'Email',
  'input.stafemail.placeholder': 'e.g. johndoe@plandok.com',
  'input.permission.label': 'User permissions',
  'input.uploadProfile.label': 'Upload profile picture',
  'input.changeProfile.label': 'Change profile picture',
  'input.notes.label': 'Notes',
  'input.staffNotes.placeholder': 'Add private notes viewable in staff settings only (optional)',
  'input.blockTime.placeholder': 'e.g. lunch meeting',
  'tabs.locations': 'Locations',
  'tabs.integrations': 'Integrations',
  'input.staffLocations.description': 'Assign locations where this staff member can be booked.',
  'input.multiLocation.description': 'Assign locations where this staff member can be booked.',
  'tabs.services': 'Services',
  'input.staffServices.description': 'Assign services this staff member can perform.',
  'input.multiServices.description': 'Assign services this staff member can perform.',
  'input.shiftStart.label': 'Shift start',
  'input.shiftEnd.label': 'Shift end',
  'edit.staff.title.resendPassword': 'Password resend',
  'edit.staff.info.resendPassword':
    "Staff member didn't receive an email with password? Click a button bellow to resend email with password setup instructions.",
  'edit.staff.button.resendPassword': 'Resend password email',
  'btn.add.shift': 'Add another shift',
  'break.time': '{0} break time',
  'input.repeat.label': 'Repeat',
  'input.repeatWeekly.label': 'Weekly',
  'input.repeatDoNot.label': 'Dont repeat',
  'input.endRepeat.label': 'Repeat Ends',
  'input.endRepeatOngoing.label': 'Ongoing',
  'input.endRepeatSpecificDate.label': 'Specific Date',
  'input.endRepeatDate.placeholder': 'Choose Date',
  'btn.deleteUpcomingShift': 'Delete upcoming shifts',
  'btn.deleteThisShift': 'Delete this shift only',
  'btn.updateUpcomingShift': 'Update upcoming shifts',
  'btn.updateThisShift': 'Update this shift only',
  'modal.repeatShift.title': 'Repeating Shift',
  'form.error.uniqShifts': 'Shifts should be unique',
  'btn.add.new': 'Add new',
  'btn.addNewService': 'Create new service',
  'btn.newCategory': 'New Category',
  'btn.newServiceGroup': 'New Category',
  'btn.editCategory': 'Edit category',
  'btn.deleteCategory': 'Delete category',
  'btn.downloadImportFile': 'Download import file',
  'serviceGroup.delete.success': 'Category deleted sucessfully!',
  'service.new.title': 'New Service',
  'service.edit.title': 'Edit Service',
  'input.serviceName.label': 'Service name',
  'input.serviceName.placeholder': 'e.g. Massage',
  'input.serviceCategory.label': 'Service Category',
  'input.serviceCategory.placeholder': 'Select service category',
  'input.price.label': 'Price',
  'input.price.placeholder': '0',
  'input.duration.label': 'Duration',
  'input.duration.placeholder': 'Duration',
  'input.staffSelect.description': 'Select staff who perform this service',
  'input.newCategoryName.label': 'Category name',
  'input.newCategoryDescription.label': 'Category description',
  'input.newCategoryDescription.placeholder': 'Desribe this category, it will be shown in Online bookings',
  'modal.newCategory.title': 'New Category',
  'modal.editCategory.title': 'Edit Category',
  'input.chooseCategory.label': 'Choose Category color:',
  'client.messages.title': 'Client Messages',
  'tabs.messagesLog': 'Messages log',
  'tabs.messagesLog.description': 'List of sent messages to your clients',
  'tabs.messagesSettings': 'Settings',
  'tabs.messagesSettings.description': 'Adjust your message settings to fit your business needs',
  'table.header.timeSent': 'Sent',
  'table.header.client': 'Client',
  'table.header.appointment': 'Appointment',
  'table.header.destination': 'Address',
  'table.header.type': 'Type',
  'table.header.message': 'Message',
  'table.header.status': 'Status',
  'table.header.cost': 'Cost',
  'modal.message.title': 'View Message',
  'setup.page.title': 'Setup',
  'title.accountSettings': 'Account settings',
  'title.accountSetup': 'Account setup',
  'title.accountSetup.description': 'Manage settings such as your business name and time zone',
  'resources.list.title': 'Resources',
  'title.resources': 'Resources',
  'title.resources.description': 'Set up bookable resources such as rooms, chairs or equipment',
  'title.locations': 'Locations',
  'title.locations.description': 'Manage multiple outlets for your business',
  'calendar.settings.title': 'Calendar Settings',
  'calendar.btn.selectFromCalendar': 'Select from calendar',
  'title.calendarSettings': 'Calendar Settings',
  'title.calendarSettings.description': 'Adjust the color scheme and layout of your calendar',
  'title.clientSettings': 'Client settings',
  'title.onlineBookingSettings': 'Online booking settings',
  'title.salesSettings': 'Sales settings',
  'title.receiptSequencing': 'Receipt sequencing',
  'title.receiptSequencing.description': 'Configure details displayed on sales receipts issued to your clients',
  'title.receiptTemplate': 'Receipt template',
  'title.taxes.description': 'Manage tax rates that apply to items sold at checkout',
  'title.paymentTypes': 'Payment types',
  'title.paymentTypes.description': 'Set up manual payment types for use during checkout',
  'title.discounts.description': 'Setup your discounts',
  'title.receiptPrefix': 'Receipt №. Prefix',
  'title.receiptNumber': 'Next receipt number',
  'title.change': 'Change',
  'title.saleReceiptTemplate': 'Sale receipt template',
  'description.saleReceiptTemplate': 'Customize the content displayed on sales receipts issued to your clients',
  'setting.automaticallyPrint': 'Automatically print receipts upon sale completion',
  'setting.showMobile': 'Show client mobile and email on sale receipt',
  'setting.showAddress': 'Show client address on sale receipt',
  'title.receiptTitle': 'Receipt title',
  'title.referrals': 'Referral Sources',
  'title.referrals.description': 'Set up custom sources to track how client found your business',
  'title.clientNotifications': 'Client Notifications Settings',
  'title.clientNotifications.description': 'Manage messages which send to clients about their appointments',
  'cancelReason.list.title': 'Cancellation Reasons',
  'title.cancellationReasons': 'Cancellation Reasons',
  'title.cancellationReasons.description': 'Track why clients did not arrive for their appointments',
  'title.onlineBooking.description': 'Manage tour online booking availability and settings',
  'title.pointOfSale': 'Point of sale',
  'discount.list.title': 'Discounts',
  'title.discountTypes': 'Discount Types',
  'title.discountTypes.description': 'Set up manual discount types for use during checkout',
  'title.premiumFeature': 'Premium Feature',
  'company.details.title': 'Company details',
  'input.businessName.label': 'Business name',
  'input.businessName.placeholder': 'e.g. Eaglewings',
  'input.description.label': 'Description',
  'input.description.placeholder': 'Enter description',
  'input.businessAddress.label': 'Address',
  'input.businessAddress.placeholder': 'Enter address',
  'input.website.label': 'Website',
  'input.website.placeholder': 'Enter website name',
  'input.contactNumber.label': 'Contact number',
  'input.contactNumber.placeholder': 'Enter number',
  'input.businessTimeZone.label': 'Time zone',
  'input.businessTimeZone.placeholder': 'GMT +02.00',
  'input.businessCountry.label': 'Country',
  'input.businessCountry.placeholder': 'Choose Country',
  'input.timeFormat.label': 'Time format',
  'input.timeFormat.placeholder': '24-hours',
  'table.header.resourceName': 'Resource',
  'btn.new.resource': 'New Resource',
  'input.resourceName.label': 'Resource Name',
  'input.resourceName.placeholder': 'Enter Name',
  'input.resourceDescription.label': 'Description',
  'input.resourceDescription.placeholder': 'Enter description',
  'input.resourceLocation.placeholder': 'Location name',
  'resourcesTooltip.location': 'Location tooltip for resource',
  'modal.newResource.title': 'Create new Resource',
  'modal.editResource.title': 'Edit Resource',
  'table.header.locationName': 'Location',
  'table.header.locatonAddress': 'Address',
  'table.header.location.Phone': 'Phone',
  'modal.newLocation.title': 'New Location',
  'modal.editLocation.title': 'Edit Location',
  'btn.new.location': 'New Location',
  'btn.addExtraLocation': 'Add extra location',
  'input.locationName.label': 'Location name',
  'input.locationName.placeholder': 'Enter Location Name',
  'input.profile.locationName.placeholder': 'E.g. Barber Salon',
  'input.slug.label': 'Slug',
  'input.locationTips.label': 'Location tips',
  'input.slug.explanation': 'Will be visible in your location URL',
  'input.contactEmail.label': 'Contact Email',
  'input.locationAddress.label': 'Address',
  'input.locationAddress.placeholder': 'Enter Location Address',
  'tooltip.contactEmail': 'Replies to client appointment notifications will go to this email.',
  'input.appointmentColors.label': 'Appointment Color',
  'input.colorBy.service': 'By Service Group',
  'input.colorBy.employee': 'By Employee',
  'input.colorBy.appointment': 'By Appointment Status',
  'input.timeSlot.label': 'Time slot interval',
  'input.defaultViewType.label': 'Default view',
  'input.calendarTimeRange.label': 'Calendar time range',
  'input.calendarTimeRange.allTimes.option': 'All times (00:00 - 24:00)',
  'input.calendarTimeRange.onlyWorkingHours.option': 'Only working hours',
  'input.calendarTimeRange.customRange.option': 'Custom range',
  'input.weekStart.label': 'Week start day',
  'table.header.refSourcetitle': 'Referral Source',
  'table.header.refSourceAddDate': 'Date Added',
  'btn.new.referral': 'New Referral Source',
  'input.nameRefSource.label': 'Referral Source name',
  'input.nameRefSource.placeholder': 'e.g. Facebook ads',
  'modal.newRefSource.title': 'New Referral Source',
  'modal.editRefSource.title': 'Edit Referral Source',
  'input.sendBy.label': 'Send by',
  'input.emailSubject.label': 'Email subject',
  'input.emailTemplate.label': 'Email template',
  'input.emailTemplate.placeholder': 'Enter email template',
  'input.subject.label': 'Email subject',
  'input.subject.placeholder': 'Enter email subject',
  'title.messagingSettings.label': 'Messaging Settings',
  'title.messagingSettings.description':
    'Message types setup here will be automatically sent to clients. All messages generated can be easily accessed on the messages page (in your main menu). Adjust the settings for how and when messages are sent, and edit the templates to personalise the text. Use the below tags to include appointment details inside messages:',
  'client.first.name': 'CLIENT_FIRST_NAME',
  'client.last.name': 'CLIENT_LAST_NAME',
  'staff.first.name': 'STAFF_FIRST_NAME',
  'staff.last.name': 'STAFF_LAST_NAME',
  'booking.date.time': 'BOOKING_DATE_TIME',
  'booking.date': 'BOOKING_DATE',
  'booking.time': 'BOOKING_TIME',
  'service.name': 'SERVICE_NAME',
  'business.name': 'BUSINESS_NAME',
  'business.phone': 'BUSINESS_PHONE',
  'business.address': 'BUSINESS_ADDRESS',
  'business.email': 'BUSINESS_EMAIL',
  'location.name': 'LOCATION_NAME',
  'location.phone': 'LOCATION_PHONE',
  'location.address': 'LOCATION_ADDRESS',
  'location.email': 'LOCATION_EMAIL',
  'tabs.reminders.title': 'Reminders',
  'input.enableReminder.label': 'Enable Reminder Messages',
  'input.enableReminder.description': 'Automatically sends to clients ahead of their upcoming appointment',
  'input.reminder.title': 'Reminder {0}',
  'input.message.title': 'Message {0}',
  'input.reminder.description': 'Channels and sending time',
  'input.reminder.channels': 'Select the channels on which this message will be sent',
  'input.reminderBefore.label': 'Reminders will be sent before',
  'input.smsSender.label': 'SMS Sender ID',
  'input.messageSender.label': 'Message Sender ID',
  'input.smsSender.placeholder': 'Enter SMS Sender ID',
  'input.smsTemplate.label': 'SMS Template',
  'input.messageTemplate.label': 'Message template',
  'input.smsTemplate.placeholder': 'Enter SMS template',
  'input.whatsAppSender.placeholder': 'Plandok',
  'reminder.whatsApp.notificate':
    "WhatsApp message template is currently non-editable due to limitations. However, we're actively working to make it fully editable.",
  'reminder.charactersCount': 'Characters count:',
  'reminder.pricing': 'Pricing',
  'modal.pricing.description':
    'Different countries have different price rates. We provide the best possible rate from our partners. It may vary.',
  'modal.pricing.subDescription': 'Charges will be applied once messages are sent and added to upcoming invoice.',
  'modal.pricing.counterDescription':
    'A standard text SMS is limited to 160 characters and 70 characters for messages with special characters.',
  'modal.pricing.learnMore': 'Learn more',
  'modal.pricing.priceRates': 'Price rates',
  'modal.pricing.messagePrice': '1 message price:',
  'modal.sendTestMessageByNumber.title': 'Send test Message to this number:',
  'modal.sendTestMessageByNumber.description': 'Charges apply as per regular message.',
  'modal.sendTestMessageByEmail.title': 'Send test Message to this email:',
  'modal.sendTestMessageByEmail.placeholder': 'Please enter the email address',
  'btn.send': 'Send',
  'reminder.sendTestMessage': 'Send test message',
  'button.reminder.addTag': 'Add tag',
  'button.reminder.addAnotherReminder': 'ADD ANOTHER REMINDER',
  'button.message.addAnotherMessage': 'ADD ANOTHER MESSAGE',
  'button.reminder.upgrade': 'Upgrade to access',
  'label.emailSettings': 'Email Settings',
  'label.SMSSettings': 'SMS Settings',
  'label.whatsAppSettings': 'WhatsApp Settings',
  'tooltip.sender.0': 'It will be displayed as SMS sender name',
  'tooltip.sender.1': '11 characters maximum',
  'tooltip.sender.2': 'May not work in some countries',
  'tooltip.sender.3': 'Latin letters or number only',
  'tabs.confirmations.title': 'Confirmation',
  'btn.appBookings': 'In App Bookings',
  'btn.onlineBookings': 'Online Bookings',
  'input.enableAppConfirmation.label': 'Enable in App Bookings confirmations',
  'input.enableOnlineConfirmation.label': 'Enable Online Bookings confirmations',
  'input.enableConfirmationApp.description': 'Automatically sends to clients when an appointment is booked for them',
  'input.enableConfirmationOnline.description': 'Automatically sends to clients when they book an appointment',
  'messageSetup.header': 'Message setup',
  'channels.subHeader': 'Channels',
  'tabs.reschedules.title': 'Reschedule',
  'input.enableReschedule.label': 'Enable Reschedule Messages',
  'input.enableReschedule.description': 'Automatically sends to clients when their appointment start time is changed',
  'tabs.cancellations.title': 'Cancellation',
  'input.enableCancellation.label': 'Enable Cancellation Messages',
  'input.enableCancellations.description': 'Automatically sends to clients when their appointment is cancelled',
  'tabs.thanks.title': 'Thank you',
  'input.enableThank.label': 'Enable Thank you Messages',
  'input.enableThankyou.description': 'Message is sent to client when appointment status is marked as completed',
  'tabs.lastVisit.title': 'Last visit',
  'input.enableLastVisit.label': 'Enable Last visit Messages',
  'input.enableLastVisit.description':
    'Automatically sends to clients when they have not visited your business for indicated number of days',
  'table.cancelReason.name': 'Cancellation Reasons',
  'table.cancelReason.addDate': 'Date Added',
  'btn.new.cancelReason': 'New Cancellation Reason',
  'modal.newCancelReason.title': 'New Cancellation Reason',
  'modal.editCancelReason.title': 'Edit Cancellation Reason',
  'input.cancelReason.title': 'Cancellation Reason name',
  'input.cancelReason.placeholder': 'e.g. Client unavailable',
  'table.discounts.name': 'Discounts',
  'table.discounts.value': 'Value',
  'table.discounts.addDate': 'Creation Date',
  'input.discountName.label': 'Discount name',
  'input.discountName.placeholder': 'e.g. Senior Citizens',
  'input.discountValue.label': 'Discount Value',
  'btn.new.discount': 'New Discount',
  'input.discountPercentage.label': '% Percentage',
  'input.discountSum.label': 'Amount',
  'modal.newDiscount.title': 'New Discount Type',
  'modal.editDiscount.title': 'Edit Discount',
  'modal.deleteAccount.title': 'Delete Account',
  'modal.deleteAccount.description':
    'Deleting your account is an irreversible process and all your data will be removed forever.',
  'modal.deleteAccount.successMessage': 'Your account was succesfully deleted.',
  'title.mySettings': 'My Settings',
  'title.personalDetails': 'Personal Details',
  'title.personalDetails.description':
    'Set your name and contact information, the email address entered here is used for your login access',
  'btn.continueWithGoogle.title': 'Continue with Google',
  'btn.continueWithFacebook.title': 'Continue with Facebook',
  'notification.googleAccountUnlinked': 'Google account unlinked',
  'notification.googleAccountLinked': 'Google account linked',
  'notification.facebookAccountUnlinked': 'Facebook account unlinked',
  'notification.facebookAccountLinked': 'Facebook account linked',
  'btn.linkAccount': 'Link account',
  'btn.unlinkAccount': 'Unlink account',
  'btn.deleteYourAccount': 'Delete your account',
  'input.myName.label': 'First Name',
  'input.myName.placeholder': 'e.g. John',
  'input.mylastName.label': 'Last Name',
  'input.mylastName.placeholder': 'e.g. Doe',
  'title.changePassword': 'Change Password',
  'title.changePassword.description':
    "To make an update, enter your existing password followed by a new one. If you don't know your existing password, you can logout and use the Reset Password link on the Sign In page.",
  'input.currentPassword.label': 'Current Password',
  'label.currentPassword.placeholder': 'Enter Current Password',
  'input.newPassword.label': 'New password',
  'input.newPassword.placeholder': 'Enter New Password',
  'input.verifyPassword.label': 'Repeat Password',
  'input.verifyPassword.placeholder': 'Repeat New Password',
  'btn.confirmDelete': 'Yes, Delete now',
  'btn.confirm': 'Confirm',
  'btn.confirmBook': 'Confirm and book',
  'btn.confirmPay': 'Confirm and pay',
  'btn.delete': 'Delete',
  'btn.close': 'Close',
  'validation.required': 'Field is required',
  'validation.email.format': 'Incorrect email format',
  'validation.url.format': 'Incorrect url format, must start with https:// or http://',
  'validation.password.length': 'Password must be at least 7 characters',
  'validation.password.letters': 'Password should contain number and letter',
  'validation.startTime': 'Start time cannot be later or the same with End time',
  'validation.endTime': 'End time cannot be earlier or the same with Start time',
  'tooltip.password.title': 'Password must:',
  'tooltip.password.characters': 'Have at least 7 characters',
  'tooltip.password.number': 'Contain a number',
  'tooltip.password.letter': 'Contain a letter',
  'tooltip.location': 'Location tooltip',
  'footer.support.center': 'Need help? Contact us',
  'not.found': 'Not Found',
  'input.cancelReason.label': 'Cancellation Reason',
  'input.location.label': 'Location',
  'input.location.placeholder': 'Location name',
  'input.name.label': 'Name',
  'title.recentlyAddedClients': 'Clients (Recently Added)',
  'title.calendarView': 'Calendar View',
  'text.manageProfile': 'Manage your user profile',
  'modal.language.title': 'Choose language',
  'modal.language.placeholder': 'Search language',
  'dropdown.access.medium': 'Medium',
  'dropdown.access.high': 'High',
  'dropdown.access.low': 'Low',
  'dropdown.access.basic': 'Basic',
  'dropdown.access.no': 'No Access',
  'dropdown.access.owner': 'Owner',
  'button.select.all': 'Select All',
  'button.selected': 'Selected',
  'dropdown.gender.unknown': 'Unknown',
  'dropdown.gender.male': 'Male',
  'dropdown.gender.female': 'Female',
  'dropdown.notificationBy.none': "Don't send",
  'dropdown.notificationBy.sms': 'SMS',
  'dropdown.notificationBy.email': 'Email',
  'dropdown.notificationBy.combined': 'SMS and Email',
  'notification.success.login': 'Successfully logged in!',
  'notification.success.register': 'Successfully registered!',
  'dropdown.time.minutes': 'minutes',
  'dropdown.repeat.no': "Don't repeat",
  'dropdown.repeat.weekly': 'Weekly',
  'dropdown.endRepeat.ongoing': 'Ongoing',
  'dropdown.endRepeat.weekly': 'Specific Date',
  'dropdown.time.appointment.immediately': 'Immediately',
  'dropdown.time.appointment.upTo1Hour': 'Up to 1 hour',
  'dropdown.time.appointment.upTo2Hours': 'Up to 2 hours',
  'dropdown.time.appointment.upTo3Hours': 'Up to 3 hours',
  'dropdown.time.appointment.upTo4Hours': 'Up to 4 hours',
  'dropdown.time.appointment.upTo5Hours': 'Up to 5 hours',
  'dropdown.time.appointment.upTo6Hours': 'Up to 6 hours',
  'dropdown.time.appointment.upTo7Hours': 'Up to 7 hours',
  'dropdown.time.appointment.upTo8Hours': 'Up to 8 hours',
  'dropdown.time.appointment.upTo9Hours': 'Up to 9 hours',
  'dropdown.time.appointment.upTo10Hours': 'Up to 10 hours',
  'dropdown.time.appointment.upTo11Hours': 'Up to 11 hours',
  'dropdown.time.appointment.upTo12Hours': 'Up to 12 hours',
  'dropdown.time.appointment.upTo13Hours': 'Up to 13 hours',
  'dropdown.time.appointment.upTo14Hours': 'Up to 14 hours',
  'dropdown.time.appointment.upTo15Hours': 'Up to 15 hours',
  'dropdown.time.appointment.upTo16Hours': 'Up to 16 hours',
  'dropdown.time.appointment.upTo17Hours': 'Up to 17 hours',
  'dropdown.time.appointment.upTo18Hours': 'Up to 18 hours',
  'dropdown.time.appointment.upTo19Hours': 'Up to 19 hours',
  'dropdown.time.appointment.upTo20Hours': 'Up to 20 hours',
  'dropdown.time.appointment.upTo21Hours': 'Up to 21 hours',
  'dropdown.time.appointment.upTo22Hours': 'Up to 22 hours',
  'dropdown.time.appointment.upTo23Hours': 'Up to 23 hours',
  'dropdown.time.appointment.upTo24Hours': 'Up to 1 day',
  'dropdown.time.appointment.upTo48Hours': 'Up to 2 days',
  'dropdown.time.appointment.upTo3days': 'Up to 3 days',
  'dropdown.time.appointment.upTo4days': 'Up to 4 days',
  'dropdown.time.appointment.upTo5days': 'Up to 5 days',
  'dropdown.time.appointment.upTo6days': 'Up to 6 days',
  'dropdown.time.appointment.upTo1week': 'Up to 1 week',
  'dropdown.time.appointment.upTo2weeks': 'Up to 2 weeks',
  'dropdown.time.appointment.upTo15Min': 'Up to 15 minutes',
  'dropdown.time.appointment.upTo30Min': 'Up to 30 minutes',
  'dropdown.time.appointment.upTo1Month': 'Up to 1 month',
  'dropdown.time.appointment.upTo2Months': 'Up to 2 months',
  'dropdown.time.appointment.upTo3Months': 'Up to 3 months',
  'dropdown.time.appointment.upTo4Months': 'Up to 4 months',
  'dropdown.time.appointment.upTo5Months': 'Up to 5 months',
  'dropdown.time.appointment.upTo6Months': 'Up to 6 months',
  'dropdown.time.appointment.interval5Min': '5 minutes',
  'dropdown.time.appointment.interval10Min': '10 minutes',
  'dropdown.time.appointment.interval15Min': '15 minutes',
  'dropdown.time.appointment.interval20Min': '20 minutes',
  'dropdown.time.appointment.interval30Min': '30 minutes',
  'dropdown.time.appointment.interval40Min': '40 minutes',
  'dropdown.time.appointment.interval45Min': '45 minutes',
  'dropdown.time.appointment.interval50Min': '50 minutes',
  'dropdown.time.appointment.interval60Min': '60 minutes',
  'dropdown.time.appointment.interval75Min': '75 minutes',
  'dropdown.time.appointment.interval90Min': '90 minutes',
  'dropdown.time.appointment.interval120Min': '120 minutes',
  'dropdown.time.appointment.interval150Min': '150 minutes',
  'dropdown.time.appointment.interval180Min': '180 minutes',
  'dropdown.time.appointment.interval240Min': '240 minutes',
  'dropdown.time.appointment.anyTime': 'Any time',
  'dropdown.time.appointment.disabled': 'Disabled',
  'dropdown.time.appointment.doesntRepeat': "Doesn't repeat",
  'dropdown.time.appointment.daily': 'Daily',
  'dropdown.time.appointment.every2Days': 'Every 2 days',
  'dropdown.time.appointment.every3Days': 'Every 3 days',
  'dropdown.time.appointment.every4Days': 'Every 4 days',
  'dropdown.time.appointment.every5Days': 'Every 5 days',
  'dropdown.time.appointment.every6Days': 'Every 6 days',
  'dropdown.time.appointment.every7Days': 'Every 7 days',
  'dropdown.time.appointment.weekly': 'Weekly',
  'dropdown.time.appointment.every2weeks': 'Every 2 weeks',
  'dropdown.time.appointment.every3weeks': 'Every 3 weeks',
  'dropdown.time.appointment.every4weeks': 'Every 4 weeks',
  'dropdown.time.appointment.every5weeks': 'Every 5 weeks',
  'dropdown.time.appointment.every6weeks': 'Every 6 weeks',
  'dropdown.time.appointment.every7weeks': 'Every 7 weeks',
  'dropdown.time.appointment.every8weeks': 'Every 8 weeks',
  'dropdown.time.appointment.every9weeks': 'Every 9 weeks',
  'dropdown.time.appointment.every10weeks': 'Every 10 weeks',
  'dropdown.time.appointment.monthly': 'Monthly',
  'dropdown.time.appointment.every2months': 'Every 2 months',
  'dropdown.time.appointment.every3months': 'Every 3 months',
  'dropdown.time.appointment.every4months': 'Every 4 months',
  'dropdown.time.appointment.every5months': 'Every 5 months',
  'dropdown.time.appointment.every6months': 'Every 6 months',
  'dropdown.time.appointment.every7months': 'Every 7 months',
  'dropdown.time.appointment.every8months': 'Every 8 months',
  'dropdown.time.appointment.every9months': 'Every 9 months',
  'dropdown.time.appointment.every10months': 'Every 10 months',
  'dropdown.time.appointment.every11months': 'Every 11 months',
  'dropdown.time.appointment.after2times': 'After 2 times',
  'dropdown.time.appointment.after3times': 'After 3 times',
  'dropdown.time.appointment.after4times': 'After 4 times',
  'dropdown.time.appointment.after5times': 'After 5 times',
  'dropdown.time.appointment.after6times': 'After 6 times',
  'dropdown.time.appointment.after7times': 'After 7 times',
  'dropdown.time.appointment.after8times': 'After 8 times',
  'dropdown.time.appointment.after9times': 'After 9 times',
  'dropdown.time.appointment.after10times': 'After 10 times',
  'dropdown.time.appointment.after11times': 'After 11 times',
  'dropdown.time.appointment.after12times': 'After 12 times',
  'dropdown.time.appointment.after13times': 'After 13 times',
  'dropdown.time.appointment.after14times': 'After 14 times',
  'dropdown.time.appointment.after15times': 'After 15 times',
  'dropdown.time.appointment.after20times': 'After 20 times',
  'dropdown.time.appointment.after25times': 'After 25 times',
  'dropdown.time.appointment.after30times': 'After 30 times',
  'dropdown.time.appointment.specificDate': 'Specific date',
  'modal.createHours.title': '{0} work hours',
  'modal.editHours.title': 'Edit {0} hours',
  'modal.deleteItem.confirm.0': 'Are you sure want to permanently',
  'modal.deleteItem.confirm.1': 'delete',
  'modal.deleteItem.confirm.2': 'from database ?',
  'modal.entity.category': 'Category',
  'modal.entity.referal': 'Referal source',
  'modal.entity.resource': 'Resource',
  'modal.entity.location': 'Location',
  'modal.deleteReminder.title': 'Delete Reminder',
  'modal.deleteMessage.title': 'Delete Message',
  'modal.deleteReminder.description': 'Are you sure want to delete this Reminder ?',
  'modal.deleteMessage.description': 'Are you sure want to delete this Message ?',
  'modal.deleteReminder.confirm': 'Yes, delete',
  'modal.deletePaymentMethod.title': 'Delete payment method',
  'modal.deletePaymentMethod.description': 'This payment method will be permanently deleted',
  'closedDate.create.modal.title': 'Add Closed Date',
  'closedDate.edit.modal.title': 'Edit Closed Date',
  'staff.access.owner': 'Owner',
  'staff.access.high': 'High',
  'staff.access.medium': 'Medium',
  'staff.access.low': 'Low',
  'staff.access.basic': 'Basic',
  'staff.access.no_access': 'No Access',
  'staff.create.title': 'Add Closed Date',
  'staff.emptyState.title': 'No scheduled staff members.',
  'staff.emptyState.description': 'See the staff members section to add workings hours.\n',
  'staff.viewStaffMembers.button': 'View staff members',
  'staff.picture.changePhoto': 'Change photo',
  'staff.picture.removePhoto': 'Remove photo',
  'input.clientSearch.placeholder': 'Search by name, e-mail or mobile',
  'input.blockedDate.label': 'Date',
  'appointment.notes.title': 'Appointment Notes',
  'appointment.history.title': 'Appointment history',
  'appointment.history.by.title': ' by {0}',
  'appointment.log.created.label': 'Created by',
  'appointment.log.updated.label': 'Updated by',
  'appointment.log.rescheduled.label': 'Rescheduled by',
  'appointment.log.cancelled.label': 'Cancelled by',
  'appointment.log.changed.label': 'Changed Status by',
  'appointment.notificationLog.rescheduling': 'Reschedule notification sent on',
  'appointment.notificationLog.cancellation': 'Cancellation notification sent on',
  'appointment.notificationLog.confirmation': 'Confirmation notification sent on',
  'appointment.notificationLog.reminder': 'Reminder notification sent on',
  'appointment.notificationLog.thankYou': 'Thank you notification sent on',
  'appointment.notificationLog.lastVisit': 'Last visit notification sent on',
  'state.cancelReason.title': 'Set up cancelation reasons',
  'state.cancelReason.description':
    'Cancelation reasons help you track why your clients did not show up to their appointments.',
  'state.cancelReason.button': 'Add cancelation reason',
  'state.resources.title': 'Manage your resources',
  'state.resources.description':
    'Business is more successful when you are aware of the resources at hand. Keep track of rooms, furniture or any other appliances to ensure smooth services and less bumps along the way.',
  'state.resources.button': 'Add resource',
  'state.referralSource.title': 'Handle Referral Sources',
  'state.referralSource.description':
    'A referral source indicates where the clients come from. Keep track of them and use this information to possibly increase your business visibility and conversion rate.',
  'state.referralSource.button': 'Add referral source',
  'state.locations.title': 'Set your locations',
  'state.locations.description':
    'Here you can manage multiple outlets of your business, set different working hours for your staff per location and have individual calendars for each one.',
  'state.staff.title': 'Manage your staff',
  'state.staff.description':
    'Create profiles for your employees so you could add and edit their schedules also keeping track of their appointments and working hours.',
  'state.messageLog.title': 'Keep track of messages',
  'state.messageLog.description':
    'Messages remind your clients of upcoming appointments or keeps them informed. Here, you will be able to track the messages and their status.\u000bTo configure automated messages, go to ',
  'state.messageLog.link': 'Notification Settings.',
  'state.clients.title': 'Administrate your clients',
  'state.clients.description':
    'Taking care of your clients starts with making sure their profiles contain all needed information for a smooth experience and the ability to contact them.',
  'state.clients.button': 'add client',
  'state.services.title': 'Set groups and services',
  'state.services.description':
    'Groups are essential, as they let you group similar services you provide. Plus, they make your calendar look clean and easily accessible!',
  'state.services.button': 'Add service group',
  'state.calendar.title': 'No Scheduled Staff',
  'state.calendar.description': 'To set staff working hours, see the ',
  'state.calendar.button': 'Staff Section.',
  'state.closedDates.title': 'Closed Dates',
  'state.closedDates.description':
    'List the dates your business is closed for public holidays, maintenance or any other reason. Clients will not be able to place online bookings during these days.',
  'state.closedDates.button': 'Add Closed Date',
  'title.billingPlan': 'Plan and Billing',
  'btn.upgrade': 'Upgrade',
  'header.usage': 'USAGE',
  'header.dropdown.subscription.plan': '{0} Plan',
  'header.dropdown.subscription.staff': '({0} Staff members)',
  'header.dropdown.notifications.description': '{0} messages',
  'header.paymentDetails': 'View Payment details',
  'title.billingPlan.description':
    'Manage your accounts. Plan and billing details, view transactions and download invoices.',
  'plan.free.title': 'Starter',
  'plan.individual.title': 'Individual (1 Staff only)',
  'plan.business.title': 'Business',
  'btn.upgradeNow': 'Upgrade',
  'btn.getStarted': 'Get Started',
  'btn.currentPlan': 'Current Plan',
  'plan.free.option.0': '1 Location',
  'plan.free.option.1': 'Up to 5 Employees',
  'plan.free.option.2': 'Unlimited Appointments',
  'plan.free.option.3': 'Unlimited Clients',
  'plan.free.option.4': 'Unlimited Online bookings',
  'plan.free.option.5': 'Email Notifications',
  'plan.premium.option.0': 'Unlimited Locations',
  'plan.premium.option.1': 'Unlimited Appointments',
  'plan.premium.option.2': 'Unlimited Clients',
  'plan.premium.option.3': 'Unlimited Online bookings',
  'plan.premium.option.4': 'Recurring Appointments',
  'plan.premium.option.5': 'Email Notifications',
  'plan.premium.option.6': 'SMS Notifications* ',
  'plan.premium.option.7': 'Whatsapp notifications*',
  'plan.premium.option.8': 'Unlimited Analytics',
  'plan.premium.option.9': 'Unlimited Reports',
  'plan.premium.option.10': 'Print schedules',
  'plan.premium.option.11': 'Client export',
  'plan.premium.option.12': 'Priority support',
  'plan.premium.option.13': 'Removed Plandok branding From emails (free emails Powered by Plandok)',
  'plan.premium.costsInfo': '*SMS/WhatsApp costs not included in the plan, extra charges apply.',
  'plan.premium.activeUntilDate': 'Active until {0}',
  'period.perMonth': 'per month (excl. VAT)',
  'plan.premium.description': 'Price will increase adding more staff members.',
  'plan.premium.viewPricing': 'View pricing',
  'plan.premium.numberStuff': 'Your current number of staff: {0}',
  'premium.membership': 'Premium membership price may vary depending on the number of staff you have.',
  'premium.price.perMonth': 'Premium price per month',
  'pricing.calculated': 'The price is calculated as follows:',
  'pricing.calculator': 'Price calculator',
  'pricing.numberStuff': 'Number of staff',
  'pricing.extra.member': '{0} for each extra member',
  'modal.title.pricing': 'Pricing',
  'plan.foreverFree': 'Forever Free',
  'plan.individual.0': 'Everything is Starter, plus:',
  'plan.individual.1': 'Customized notifications',
  'plan.individual.2': 'Remove plandok branding',
  'plan.individual.option.3': 'Unlimited SMS reminders',
  'plan.individual.4': 'Priority support',
  'plan.business.0': 'Everything in individual, plus:',
  'plan.business.1': 'Unlimited locations',
  'plan.business.2': 'Unlimited staff',
  'block.billingInfo.title': 'Billing Information',
  'block.carInfo.title': 'Credit Card Information',
  'input.cardNumber.label': 'Card number',
  'input.cardNumber.placeholder': '1234 5678 3456 2456',
  'input.expireDate.label': 'Expire date',
  'input.expireDate.placeholder': '05 / 21',
  'input.cvv.label': 'CVV',
  'input.cvv.placeholder': '123',
  'modal.confirmCancel.subscription': 'Are you sure want to cancel the subscription?',
  'modal.confirmCancel.subscription.description': 'Once you do you will be back to using the free version.',
  'btn.cancelSubscription.confirm': 'Yes, cancel my subscription',
  'loading.processingSubscribtion': 'Your payment is processing. Please wait...',
  'state.serviceGroup.description': 'No services in the group',
  'state.serviceGroup.btn': 'Add service',
  'appointment.status.new': 'New Appointment',
  'appointment.status.confirmed': 'Confirmed',
  'appointment.status.notShow': 'No show',
  'appointment.status.completed': 'Completed',
  'appointment.status.cancelled': 'Cancelled',
  'billing.tab.plan': 'Your plan',
  'billing.tab.costs': 'Estimated costs',
  'billing.tab.billing': 'Billing information',
  'billing.tab.invoice': 'Invoices',
  'plan.free': 'Free',
  'plan.deactivated.title': 'Premium has been deactivated',
  'plan.active.title': 'Activate',
  'payment.step.title': 'Payments',
  'payment.error.title': 'Update payment',
  'payment.payNow.title': 'Pay',
  'payment.dismiss.title': 'Dismiss',
  'payment.tab.title.sub': 'Subscription',
  'payment.tab.subTitle': 'Premium plan',
  'payment.per.members': 'per employee',
  'payment.tab.title.notification': 'Notifications',
  'payment.card.details': 'Card details',
  'payment.success.message': 'Payment was successful',
  'payment.payWithCard.title': 'Pay with card',
  'payment.cardNumber.title': 'Card Number',
  'payment.expirationDate.title': 'Expiration date',
  'payment.digits.title': '(3 digits)',
  'payment.amount': 'Payment amount:',
  'payment.btn.pay': 'Pay',
  'invoice.table.date': 'Date',
  'invoice.table.invoice': 'Invoice',
  'invoice.table.download': 'Download',
  'invoice.table.status': 'Status',
  'costs.billingPeriod.title': 'Estimated costs for this billing period',
  'costs.billingPeriod.description':
    'This is a current costs for your usage this billing period. A breakdown of your costs is available below.',
  'costs.billingPeriod.additionalInfo':
    "Once your notification expenses reach 50 €, they will be charged automatically. But don't worry if the amount is smaller; it will be conveniently included in your monthly subscription.",
  'costs.summary.title': 'Month-to-date summary',
  'costs.summary.description': 'These charges are factored into your account balance',
  'costs.summary.detailedCharges': 'Detailed charges',
  'costs.summary.noData': 'No data',
  'costs.summary.quantity': 'Quantity',
  'costs.summary.subtotal': 'Subtotal',
  'costs.summary.taxes': 'Taxes',
  'dropdown.time.hours': 'hours',
  'message.planUpgrade': 'You have successfully upgraded your plan!',
  'costs.subscription': 'Subscription',
  'btn.cancelSubscription': 'Cancel subscription',
  'btn.no': 'No',
  'notification.success.update': 'Updated successfully',
  'validation.password.match': 'Password and confirm password does not match',
  'blocked.time': 'Blocked Time',
  'message.successReset.password': 'Done! The password reset email has been sent, please check your inbox.',
  'title.newPassword': 'Enter new password',
  'title.newPassword.description': 'Securely access your account by creating a new log in password',
  'input.newPasswordRepeat.label': 'Confirm your new password',
  'btn.change.password': 'Change My password',
  'text.havingTrouble': 'Having trouble?',
  'link.resetPassword.again': 'Reset your password again',
  'message.successNew.password': 'Successfully changed password.',
  'title.repeatShifts': 'Repeating Shift',
  'text.confirmShift.update':
    'You have edited a shift that repeats weekly. Deleting upcoming shifts will overwrite {0} ongoing {0} schedule',
  'text.confirmShift.delete':
    'You are deleting a shift that repeats weekly. Deleting upcoming shifts will overwrite  {0} ongoing {0} schedule',
  'btn.deleteUpcoming.shifts': 'Delete upcoming shifts',
  'btn.deleteThis.shift': 'Delete this shift only',
  'btn.updateUpcoming.shifts': 'Update upcoming shifts',
  'btn.updateThis.shift': 'Update this shift only',
  'text.permisionTab':
    'Setup which sections are accessible to each user permission level. All logged in staff can access the calendar, and owner accounts have full system access.',
  'title.permission.locations': 'Locations',
  'title.permission.booking': 'Booking & Clients',
  'title.permission.staff': 'Staff',
  'title.permission.setup': 'Setup',
  'title.permission.basic': 'Basic',
  'title.permission.low': 'Low',
  'title.permission.med': 'Med',
  'title.permission.high': 'High',
  'title.permission.owner': 'Owner',
  'title.allLocations.permission': 'Access All Locations',
  'title.accessCalendar.permission': 'Access Own Calendar',
  'title.accessOtherCalendar.permission': 'Access Other Staff Calendars',
  'title.canBook.permission': 'Can Book Appointments',
  'title.contactInfo.permission': 'Can see client contact info',
  'title.clients.permission': 'Clients',
  'title.messages.permission': 'Messages',
  'title.services.permission': 'Services',
  'title.workingHours.permission': 'Working Hours',
  'title.staffMembers.permission': 'Staff Members',
  'title.permissionLevels.permission': 'Permission Levels',
  'title.accountSetup.permission': 'Account Settings',
  'title.clientSettings.permission': 'Client Settings',
  'title.onlineBookings.permission': 'Online Bookings',
  'title.analytics.permission': 'Analytics',
  'title.marketing.permission': 'Marketing',
  'notification.type.reminder': 'Reminder',
  'notification.type.confirmation': 'Confirmation',
  'notification.type.online_booking_confirmation': 'Confirmation - Online booking ',
  'notification.type.rescheduling': 'Reschedule',
  'notification.type.cancellation': 'Cancellation',
  'notification.type.thank_you': 'Thank you',
  'notification.type.last_visit': 'Last visit',
  'notification.channel.sms': 'SMS',
  'notification.channel.email': 'Email',
  'notification.channel.whatsapp': 'WhatsApp',
  'notification.status.sent': 'Sent',
  'notification.status.pending': 'Pending',
  'notification.status.failed': 'Failed',
  'dropdown.time.days': 'days',
  'title.appointment': 'appointment',
  'input.reminderBeforeLastVisit.label': 'Notification will be sent after the indicated number of days',
  'txt.with': 'with',
  'short.hours': 'h',
  'short.minutes': 'min',
  'input.registrationNumber.label': 'Company number',
  'input.vatNumber.label': 'Vat number',
  'invoice.table.priceTaxIncl': 'Amount',
  'error.wrongSenderId':
    'Sender ID must include digits or characters, length must be more then 3 and less then 11 symbols',
  'sidebar.help': 'Help',
  'modal.help.title': 'How can we help?',
  'modal.help.liveChat.title': 'Live chat',
  'modal.help.liveChat.description': 'Have any questions? Just ask us right away',
  'modal.help.suggestFeature.title': 'Suggest a feature',
  'modal.help.suggestFeature.description':
    'Share suggestions and see others suggestions and vote for the features you want',
  'modal.help.viewUpdates.title': 'View updates',
  'modal.help.viewUpdates.description': 'View our newest updates and improvements',
  'warning.title.paidFeature': 'Paid Feature',
  'warning.smsFeatureMissing': 'In order to access SMS settings your plan must be upgraded. To upgrade visit',
  'warning.link.billingAndPricing': 'Plan and Billing',
  'warning.newPage.0': 'was upgraded!',
  'warning.newPage.1': 'If you used old version of Plandok, your password will not work anymore, please',
  'warning.newPage.2': 'reset password',
  'warning.newPage.3': 'to enjoy new system. For more information, contact us',
  'warning.newPage.4': 'or',
  'title.blockTime.select': 'Select a time',
  'calendar.allResources': 'All Resources',
  'appointment.edit.title': 'Edit Appointment',
  'appointment.create.title': 'Appointment Created Successfully.',
  'appointment.update.title': 'Appointment Updated Successfully.',
  'select.notify.title': '{0} isn’t working at selected interval, but you can still book appointments.',
  'booking.notify.title': 'Selected time is booked, but you can create appointment.',
  'textArea.characters.left': 'characters left',
  'textArea.characters.exceeded': 'limit is exceeded by',
  'validation.characters.exceeded': 'Characters limit is exceeded',
  'staff.menu.members': 'Staff Members',
  'staff.menu.dates': 'Closed Date',
  'staff.menu.working': 'Staff Working Hours',
  'staff.menu.permissions': 'User Permissions',
  'staff.menu.members.description': 'Add or remove team members and control their user access level',
  'staff.menu.dates.description': 'Schedule upcoming business closures or public holidays',
  'staff.menu.working.description': 'Manage calendar availability by adjusting staff work schedules',
  'staff.menu.permissions.description': 'Set access permissions for sensitive features and information',
  'message.successUpdateLocation': 'Done! Location profile successfully updated.',
  'ob.title': 'Online Booking',
  'ob.overview.tab': 'Overview',
  'ob.massMessages.tab': 'Mass messages',
  'ob.campaigns.tab': 'Campaigns',
  'ob.profile.tab': 'Online profile',
  'ob.links.tab': 'Links',
  'ob.settings.tab': 'Settings',
  'ob.payments.tab': 'Payments',
  'ob.status.online': 'Online',
  'ob.status.offline': 'Offline',
  'ob.status.title': 'Status',
  'ob.profile.step': '{0} of 4',
  'ob.profile.step.title': 'Profile & Location',
  'ob.profile.step.description':
    'Present your branch and introduce the services you provide to your customers. The "About" part is one of the first sections your customers will be looking at and if that information is not enough, make sure to leave a phone number where customers can contact you for more information! Enter address where you provide your services, so your clients can be informed and view the address.',
  'ob.overview.placheholder.title': 'Let Your Clients Book Appointments Online!',
  'ob.overview.placheholder.description':
    "Create an online bookings profile and let your clients book appointments online. Simply insert a link to your website or social media and that's it.",
  'ob.overview.start.now': 'Start Now',
  'ob.links.direct.title': 'Direct Link',
  'ob.links.facebook.title': 'Facebook Link',
  'ob.links.instagram.title': 'Instagram Link',
  'ob.links.direct.description':
    "URL Link to your online bookings profile, you can use it on your website or on social media accounts.  If you're not sure how to use the link get in touch with us.",
  'ob.links.facebook.description':
    'Share this link on Facebook to attract more customers and make it easier for them to find your business and book for an appointment.',
  'ob.links.instagram.description':
    "Make it easier for you and link your online booking directly!\nYou can use it on your website and anywhere else where you feel like it.\nIf you're not sure how to use the link, don't worry, we got you covered!",
  'ob.analytics.tab.description':
    'Add Google Analytics and Facebook Pixel to your online bookings page to analyze traffic and user behavior.',
  'ob.analytics.google.title': 'Google Analytics',
  'ob.analytics.facebook.title': 'Facebook Pixel',
  'ob.analytics.google.description':
    'With Google Analytics, you can track and analyze website traffic and user behavior to gain insights how to improve your website.',
  'ob.analytics.active': 'Active',
  'ob.analytics.inactive': 'Inactive',
  'ob.analytics.setUp.btn': 'Set up',
  'ob.analytics.google.description.1':
    'Google Analytics is a powerful tool, that can help you monitor website traffic and user behavior. With Google Analytics, you can track the number of visitors to your website, where they come from, what pages they visit and how long they stay on your site.',
  'ob.analytics.google.description.2':
    'Additionally Google Analytics allows you to set up goals and track conversations, giving you a better understanding of how well your website is performing and how you can improve it.',
  'ob.analytics.google.trackingId': 'Tracking ID',
  'ob.analytics.google.tooltip':
    'To find your Tracking ID, you must first log in to your Google Analytics account, select the website or app you want to track, and then navigate to the “Admin” tab. From there, click on the “Tracking Code”. Your tracking ID will be displayed in the format “UA-XXXX-Y”.',
  'ob.analytics.google.account': "Don't already have a Google Analytics account?",
  'ob.analytics.click': 'Click',
  'ob.analytics.here': 'here',
  'ob.analytics.learn': 'to learn how to create one.',
  'ob.analytics.facebook.description.1':
    'Facebook Pixel is a tool that allows you to track user behavior on your website, including conversions, page views, and other events.',
  'ob.analytics.facebook.description.2':
    'Facebook Pixel can provide you with valuable insights about your audience such as demographics, interests, device type, pages views and actions taken is being collected, which can help you better understand and optimize user experience on your site.',
  'ob.analytics.facebook.pixelId': 'Pixel ID',
  'ob.analytics.facebook.tooltip':
    "To find your Facebook Pixel ID, follow these steps: Go to your Facebook Ads Manager account. Click on the 'Menu' button on the top left corner of the screen. Under the 'Events Manager' section, select 'Pixels'. Here, you should see a list of all the pixels associated with your account. Click on the name of the pixel for which you need the ID. Your Pixel ID will be displayed in the top left corner of the screen, next to the pixel name.",
  'ob.analytics.facebook.account': "Don't already have a Facebook business page?",
  'ob.analytics.google.account.mobile.1': "Don't already have a Google Analytics",
  'ob.analytics.google.account.mobile.2': 'account? Click',
  'ob.analytics.google.account.mobile.3': 'to learn how to',
  'ob.analytics.google.account.mobile.4': 'create one.',
  'ob.analytics.facebook.account.mobile.1': "Don't already have a Facebook",
  'ob.analytics.facebook.account.mobile.2': 'business page? Click',
  'ob.analytics.facebook.account.mobile.3': 'to learn how',
  'ob.analytics.facebook.account.mobile.4': 'to create one.',
  'ob.analytics.successfullySaved.message': 'Successfully saved',
  'ob.settings.booking.title': 'Online booking availability',
  'ob.settings.booking.description':
    'Set the time when the earliest and latest appointments can be scheduled. Manage the time intervals of appointments and provide important information to the customers, alongside the option to choose a staff member for their service.',
  'ob.settings.cancellation.title': 'Cancellation and Rescheduling',
  'ob.settings.cancellation.description': 'Set the time frame when clients can cancel or reschedule their appointment.',
  'ob.settings.locationsUrl.title': 'Locations URL',
  'ob.settings.locationsUrl.description':
    'Configure and share single URL for all online bookable locations. This is useful to businesses with multiple locations only.',
  'ob.settings.businessName.explanation': 'Your URL: book.plandok.com/business-name/Location2556',
  'ob.settings.notifications.title': 'Notification Emails',
  'ob.settings.notifications.description':
    'Specify the recipient of notification emails regarding new appointments or changes made to them.',
  'ob.settings.required.title': 'Required and optional fields',
  'ob.settings.required.description':
    'Select which fields client will be required to provide when making online bookings.',
  'ob.settings.requiredField.title': 'Required',
  'ob.settings.optionalField.title': 'Optional',
  'ob.settings.requiredField.notification.title': 'There was a problem with settings',
  'ob.settings.requiredField.notification.description': 'Sorry, but one field needs to be required, except “notes”.',
  'ob.settings.nameField.title': 'First Name',
  'ob.settings.lastNameField.title': 'Last Name',
  'ob.settings.phoneField.title': 'Phone Number',
  'ob.settings.emailField.title': 'Email',
  'ob.settings.notes.label': 'Placeholder text for notes',
  'ob.settings.notes.placeholder': 'E.g. Enter your notes',
  'ob.bookingLimit.title': 'Online booking limit',
  'ob.bookingLimit.description': 'Please specify the maximum number of service bookings you would like to set.',
  'ob.bookingLimit.label': 'Limiting service bookings',
  'ob.bookingLimit.unlimited.title': 'Unlimited',
  'ob.bookingLimit.1service.title': 'max. 1 Service',
  'ob.bookingLimit.2services.title': 'max. 2 Services',
  'ob.bookingLimit.3services.title': 'max. 3 Services',
  'ob.menu.edit.profile': 'Edit Profile',
  'ob.menu.turnOff.profile': 'Turn Off Profile',
  'ob.profile.status': 'Status',
  'ob.overview.tab.description': 'Grow your business by letting your customers book appointments online.',
  'ob.profile.tab.description':
    'Manage online profiles for your locations. Adjust images, descriptions, addresses, and more.',
  'ob.links.tab.description': 'Here you will find an online bookings link, which you can share with your clients.',
  'ob.settings.tab.description':
    'Adjust online booking settings here, so that your clients can book only when it is convenient for you.',
  'ob.payments.tab.description':
    'Select which payment methods your customers will be able to use when paying for services.',
  'ob.payments.page.description':
    'Here you can select which payment methods your customers will be able to use when paying for services.',
  'ob.payOnSite.title': 'Pay on site',
  'ob.payOnSite.description': 'Your clients will be able to pay on site with cash.',
  'ob.payWithCard.title': 'Pay with Card',
  'ob.payWithCard.description': 'Your clients will be able to pay with credit card via Stripe app.',
  'ob.payWithCard.info.1': 'You are using free Plandok plan so you will have to pay',
  'ob.payWithCard.info.2': 'Plandok commission fee and Stripe commission fee using this payment method.',
  'ob.payWithCard.info.3': ' to have',
  'ob.payWithCard.info.4': 'Plandok commission fees.',
  'ob.payWithCard.info.5': 'Premium plan does not exempt from paying Stripe commission fees. ',
  'ob.payWithCard.info.6': 'Check Stripe prices here',
  'ob.stripeConnected.info': '(Stripe connected)',
  'ob.requiresSetup.info': '(Requires to setup your Stripe account)',
  'ob.connectToStripe.btn': 'Connect to Stripe',
  'ob.connectToStripe.successMessage.title': 'Succesfully connected to Stripe',
  'ob.waitingAuthorization.title': 'Waiting for your authorization in Stripe',
  'ob.stripeDashboard.title': 'All payment logs and refunds are available in ',
  'ob.stripeDashboard.url': 'Stripe dashboard',
  'ob.yourStripeAccount.title': 'Your Stripe account',
  'ob.visitStripe.option': 'Visit Stripe',
  'ob.changeAccount.option': 'Change Stripe account',
  'input.earliestAppointment.label': 'Earliest appointment',
  'input.earliestAppointment.placeholder': 'Right before the appointment',
  'input.latestAppointment.label': 'Latest appointment',
  'input.latestAppointment.placeholder': 'Not more than 6 months in advance',
  'input.timeSlots.label': 'Time slot intervals',
  'input.timeSlots.placeholder': '15 minutes',
  'checkbox.allowCustomers': 'Allow customers to choose staff members for their appointment',
  'input.importantInfo.label': 'Important information',
  'input.importantInfo.placeholder': 'Information that will show to the customer while confirming their appointment',
  'input.changeAppointment.label': 'Changes to appointments can be made',
  'input.changeAppointment.placeholder': 'Anytime',
  'checkbox.sendToBooked': 'Send to the booked staff member',
  'checkbox.sendSpecificEmail': 'Send to a specific email address',
  'input.specificEmail.label': 'Specific Email address',
  'input.specificEmail.placeholder': 'Enter Email address',
  'input.locationsUrl.label': 'Locations URL',
  'input.locationsUrl.placeholder': 'E.g. Johns-Barber-Shop',
  'ob.btn.save': 'Save',
  'ob.btn.cancel': 'Cancel',
  'ob.btn.showLink': 'Show Link',
  'ob.btn.active': 'Active',
  'ob.btn.inactive': 'Inactive',
  'ob.linkModal.title': 'Direct Link for bookings',
  'ob.linkModal.description': "Here's the link where your online booking services can be reached directly!",
  'ob.linkModal.afterDescription':
    'This link directs users straight to your services. Just with one click the customers can reach your business profiles and book appointments.\nThis link is easy to use and friendly to all devices either it be a computer, a phone or a tablet.',
  'ob.fbLinkModal.title': 'Facebook Page Book Now',
  'ob.fbLinkModal.description':
    'Setup an integrated Book Now button for your Facebook business page to effortlessly receive new bookings directly from Facebook.\n',
  'ob.fbLinkModal.step1': 'Login to your business page on Facebook',
  'ob.fbLinkModal.step2': 'Click on "Add a button" located near the cover photo',
  'ob.fbLinkModal.step3': 'Select "Sign Up" option and enter the below booking link',
  'ob.linkModalCopySuccess.title': 'Link copied!',
  'ob.btn.copyLink': 'Copy Link',
  'title.appointmentSlot.select': 'Select time',
  'btn.back': 'Back',
  'btn.continue': 'Continue',
  'ob.hours.open': 'Open',
  'ob.hours.close': 'Closed',
  'ob.hours.step.title': 'Working Hours',
  'ob.hours.step.description': 'Let your customers know when your business opens up and closes.',
  'ob.staffHours.step.title': 'Staff Working Hours',
  'ob.staffHours.step.description':
    'An important step, please add working hours for the system to know when clients can book online.',
  'ob.location.step.title': 'Location Information',
  'ob.photo.step.title': 'Photos',
  'ob.photo.step.description': 'Let your customers know how your business place looks.',
  'input.file.dnd.photos': 'Drag and drop photos',
  'btn.publish': 'Turn on',
  'text.next': 'Next',
  'input.phone.number.label': 'Phone Number',
  'input.state.label': 'State',
  'input.state.placeholder': 'Enter a state (municipality)',
  'input.addressDetails.label': 'Address details',
  'input.addressDetails.placeholder':
    'Enter address details such as door code, other entrances, parking information, etc.',
  'placeholder.no.photos': 'No photos',
  'btn.options': 'Options',
  'btn.list.profile': 'Enable',
  'btn.preview': 'Preview',
  'btn.next': 'Next',
  'market.ob.pleaseWait.title': 'Please wait ...',
  'market.ob.location.step.title': 'Select location',
  'market.ob.services.step.title': 'Select services',
  'market.ob.employee.step.title': 'Select date and staff',
  'market.ob.timeSlot.step.title': 'Select date and time',
  'market.ob.personalInfo.step.title': 'Payment',
  'market.ob.appointmentInfo.step.title': 'Appointment information',
  'market.ob.informationAboutYou.title': 'Information about you',
  'market.ob.paymentMethod.title': 'Payment method',
  'market.ob.securePayment.title': 'Secure Payment',
  'market.ob.payWithCard.title': 'Pay with card',
  'market.ob.payOnSite.title': 'Pay on site',
  'market.ob.payOnSite.firstDescription': 'You will need to pay in the salon after the procedure.',
  'market.ob.payOnSite.secondDescription':
    "A small request from us: businesses are often small independent businesses, so if you can't make it to your scheduled appointment, reschedule or cancel it.",
  'market.ob.validThru.title': 'Valid thru (Month/year)',
  'market.ob.information.title': 'Information',
  'market.ob.additionalInformation.title': 'Additional information',
  'market.ob.reschedulingPolicy.title': 'Cancellation/Rescheduling policy',
  'market.ob.reschedulingPolicy.description': 'Changes to appointments can be made {0} up to before appointment.',
  'market.ob.fieldsAreRequired.title': '* Fields are required',
  'market.ob.step.title': 'Step {0} out of 3',
  'market.ob.confirm.step.title': 'View and confirm',
  'market.ob.noServices.placeholder': 'No services selected yet',
  'market.ob.input.firstName.label': '{0} First Name',
  'market.ob.input.firstName.placeholder': 'e.g. John',
  'market.ob.input.lastName.label': '{0} Last Name',
  'market.ob.input.lastName.placeholder': 'e.g. Doe',
  'market.ob.input.phone.label': '{0} Mobile number',
  'market.ob.input.phone.placeholder': '670 70 822',
  'market.ob.input.email.label': '{0} Email address',
  'market.ob.input.email.placeholder': 'john.doe@gmail.com',
  'market.ob.input.description.label': 'Notes',
  'market.ob.staff.assignedSpecialist.label': 'with staff',
  'market.ob.staff.noPreference.label': 'No preference',
  'market.ob.timeSlots.fullyBooked.title': "Sorry, we don't have any available times for this day.",
  'market.ob.timeSlots.choseAnotherDay.title': 'Please choose another day.',
  'market.ob.timeSlots.closedSlot.title': 'The closest available time is ',
  'market.ob.timeSlots.selectClosest.title': 'Select closest date',
  'market.ob.timeSlots.note.title': 'Important information',
  'market.ob.service.duration.title': 'min',
  'market.location.workingHours.title': 'Working Hours',
  'market.location.monday.title': 'Monday',
  'market.location.tuesday.title': 'Tuesday',
  'market.location.wednesday.title': 'Wednesday',
  'market.location.thursday.title': 'Thursday',
  'market.location.friday.title': 'Friday',
  'market.location.saturday.title': 'Saturday',
  'market.location.sunday.title': 'Sunday',
  'market.location.meetOurTeam.title': 'Meet Our Team',
  'market.location.title': 'Get in touch',
  'market.location.services.title': 'Services',
  'market.location.about.title': 'About',
  'market.location.aboutUs.title': 'About us',
  'market.confirmation.appointmentCanceled.title': 'This appointment was cancelled',
  'market.confirmation.appointmentConfirmed.title': 'Appointment confirmed',
  'market.confirmation.appointmentConfirmed.description': 'Your appointment is confirmed, here are the details:',
  'market.confirmation.appointmentDate.title': 'Appointment date',
  'market.confirmation.scheduledAppointment.title': 'Cancellation is not possible online.',
  'market.confirmation.scheduledAppointment.description':
    "If you can't make it to your scheduled appointment, please reschedule or cancel it.",
  'market.modal.cancelAppointment.title': 'Are you sure want to cancel this appointment ?',
  'market.modal.cancelledAppointment.title': 'Appointment was cancelled',
  'market.language.changer.title': 'Language',
  'market.btn.bookNow': 'Book now',
  'market.servicesAvailable.title': 'services',
  'market.poweredByPlandok.title': 'Powered by Plandok',
  'market.poweredByPlandok.description': 'Want similar website ? Visit',
  'market.btn.readMore': 'Read more',
  'market.btn.showLess': 'Show less',
  'market.btn.reviews': 'Reviews',
  'market.btn.viewMore': 'View more',
  'market.btn.cancelAppointment': 'Cancel appointment',
  'market.btn.cancel': 'Yes, cancel',
  'market.wh.closed.title': 'Closed',
  'market.ob.success.message': 'Done! Booking has been successfully created',
  'market.ob.cancelAppointment.success.message': 'Appointment cancelled successfully',
  'market.ob.cancelAppointment.error.message': 'Appointment cancelation failed',
  'market.ob.cancelAppointment.confirm.title': 'Are you sure you want to cancel appointment?',
  'market.location.getDirections.title': 'Get Directions',
  'market.notFound.title': 'Page not found',
  'market.notFound.btn.goBack': 'Go back',
  'market.ob.quantitativeLimit.title': 'Quantitative limit',
  'market.ob.quantitativeLimit.description':
    'Sorry, the owner has imposed a limit on the number of booking services, allowing for a selection of only {0} servic{0}',
  'market.ob.quantitativeLimit.three': 'three',
  'market.ob.quantitativeLimit.two': 'two',
  'market.ob.quantitativeLimit.one': 'one',
  'market.ob.quantitativeLimit.serviceSingular': 'e',
  'market.ob.quantitativeLimit.servicesPlural': 'es',
  'validation.characters.minLength': 'This value is too short. It should have 3 characters or more.',
  'not.found.btn.label': 'Back',
  'not.found.subTitle.label': 'Sorry, the page you visited does not exist.',
  'analytics.title': 'Analytics',
  'analytics.dashboard.tab': 'Dashboard',
  'analytics.reports.tab': 'Reports',
  'analytics.premium.title': 'Premium',
  'analytics.totalAppointments.title': 'Total appointments',
  'analytics.cancellationReasons.title': 'Top cancel reasons',
  'analytics.occupancy.title': 'Occupancy',
  'analytics.topServices.title': 'Top services',
  'analytics.clients.title': 'Clients',
  'analytics.completed.title': 'Completed',
  'analytics.notCompleted.title': 'Not completed',
  'analytics.canceled.title': 'Canceled',
  'analytics.noShowAppointments.title': 'No show appointments',
  'analytics.reasonName.title': 'Reason name',
  'analytics.reasonCount.title': 'Count',
  'analytics.lastPeriod.title': 'last period',
  'analytics.workingHours.title': 'Working Hours',
  'analytics.bookedHours.title': 'Booked Hours',
  'analytics.blockedHours.title': 'Blocked Hours',
  'analytics.unbookedHours.title': 'Unbooked Hours',
  'analytics.service.title': 'Service',
  'analytics.thisMonth.title': 'This Period',
  'analytics.lastMonth.title': 'Last Period',
  'analytics.totalClients.title': 'Total clients',
  'analytics.averageVisits.title': 'Average visits per client ',
  'analytics.newClients.title': 'New clients',
  'analytics.returningClients.title': 'Returning clients',
  'analytics.returnRate.title': 'Client return rate',
  'analytics.clientsByGender.title': 'Clients by gender',
  'analytics.clientsBySource.title': 'Clients by Reference source',
  'analytics.clientsByAge.title': 'Clients by age',
  'analytics.timeRange.custom': 'Custom',
  'analytics.timeRange.today': 'Today',
  'analytics.timeRange.yesterday': 'Yesterday',
  'analytics.timeRange.last1day': 'Last 1 day',
  'analytics.timeRange.last7days': 'Last 7 days',
  'analytics.timeRange.last30days': 'Last 30 days',
  'analytics.timeRange.last90days': 'Last 90 days',
  'analytics.timeRange.last3months': 'Last 3 months',
  'analytics.timeRange.lastMonth': 'Last month',
  'analytics.timeRange.last6Months': 'Last 6 months',
  'analytics.timeRange.lastYear': 'Last year',
  'analytics.timeRange.last2year': 'Last 2 year',
  'analytics.timeRange.thisWeek': 'This week',
  'analytics.timeRange.thisMonth': 'This month',
  'analytics.timeRange.thisYear': 'This year',
  'analytics.timeRange.tomorrow': 'Tommorow',
  'analytics.timeRange.next7days': 'Next 7 days',
  'analytics.timeRange.next30days': 'Next 30 days',
  'analytics.menu.dashboard.description': 'Explore your business data in charts and statistics',
  'analytics.menu.reports.description': 'Explore your business data in reports',
  'analytics.timeRange.allTime': 'All time',
  'analytics.timeRange.label': 'Time Range',
  'analytics.services.lastPeriod.label': 'Last Period',
  'analytics.totalAppointments.appointment.label': 'Appointments',
  'analytics.totalAppointments.onlineBook.label': 'Online bookings',
  'analytics.gender.male': 'Male',
  'analytics.gender.female': 'Female',
  'analytics.gender.unknown': 'Unknown',
  'analytics.noData': 'There is no data in selected period',
  'analytics.premium.info.title': 'Upgrade to premium version to view analytics for longer than 7 days.',
  'analytics.premium.upgradeButton.title': 'Upgrade',
  'analytics.premium.skipButton.title': 'Skip',
  'reports.appointments.title': 'Appointments',
  'reports.appointments.description':
    'View projected revenues of upcoming appointments, track cancellation rates and reasons.',
  'reports.appointmentsList.title': 'Appointment list',
  'reports.appointmentsList.description': 'Displays all appointments with related information.',
  'reports.appointmentsSummary.title': 'Appointments summary',
  'reports.appointmentsSummary.description': 'Displays appoinments by Staff and Service.',
  'reports.appointmentsCancellations.title': 'Appointments cancellations',
  'reports.appointmentCancellations.title': 'Appointment Cancellations',
  'reports.appointmentsCancellations.description':
    'Displays all cencelled appointment reasons and quantity per your selected period.',
  'reports.summary.title': 'Summary',
  'reports.clients.title': 'Clients',
  'reports.clients.description':
    'Gain insights into how clients interact with your business and who your top spenders are',
  'reports.clientsList.title': 'Clients',
  'reports.clientsList.description': 'List of Clients with their data',
  'reports.exportCsv.title': 'Export CSV',
  'reports.appointmentsByStaff.title': 'Appointments by Staff',
  'reports.appointmentsByService.title': 'Appointments by Service',
  'reports.clearFilters.button.title': 'Clear filters',
  'reports.filterStaff.placeholder': 'All Staff',
  'reports.filterLocations.placeholder': 'All Locations',
  'reports.filters.title': 'Filters',
  'reports.locations.mobile.title': 'Locations:',
  'reports.staff.mobile.title': 'Staff:',
  'reports.filters.apply.title': 'Apply',
  'reports.search.placeholder': 'Search by reference or client',
  'reports.subscription.description': '{0} plan ({0} staff only)',
  'reports.notifications.description': '{0} messages ({0})',
  'serviceGroup.addCategory.description': 'Categories are essential, as they let you group similar services.',
  'serviceGroup.addCategory.title': 'Add Category',
  'app.install.title': 'Install Plandok',
  'app.install.description.title': 'Installing uses almost no storage and provides a quick way to return to this app',
  'app.install.button.title': 'Install',
  'app.skip.button.title': 'Skip',
  'app.actionUndone.button.title': 'Action undone',
  'app.appointmentCancelled.button.title': 'Appointment deleted',
  'app.undo.button.title': 'Undo',
  'app.showAllTimes.button.title': 'Show all times',
  'app.install.success.title': 'App installed successfully!',
  'rp.upgradeToPremiumLink.title': 'Upgrade to Premium',
  'rp.upgradeToPremium.title': 'to use this feature',
  'rp.repeatAppointment.title': 'Repeat',
  'rp.input.frequency.title': 'Frequency',
  'rp.input.endsAfter.title': 'Ending',
  'rp.input.selectDate.title': 'Select date',
  'rp.upgrade.title': 'Upgrade your plan',
  'rp.upgrade.description.title':
    'Premium version of Plandok is not activated. Upgrade your plan to fully access all features provided by Plandok.',
  'rp.upgrade.button.title': 'Upgrade to premium',
  'rp.repeating.title': 'Repeating',
  'rp.repeats.title': 'Repeats',
  'rp.repeatsUntil.title': 'until',
  'rp.repeatingTimes.title': '({0} times)',
  'rp.cancel.upcoming.title': 'Delete all upcoming repeat appointments',
  'rp.update.upcoming.title': 'Update all upcoming repeat appointments',
  'rp.edit.appointment.title': 'Update Appointment',
  'rp.edit.appointment.description.title':
    'You are editing a repeating appointment. Select which appointments should be updated:',
  'rp.edit.thisAppointment.title': 'Update this appointment only',
  'rp.edit.allAppointments.title': 'Update all upcoming appointments',
  'rp.edit.saveAppointment.title': 'Save',
  'modal.cancelSubscriptionError.title': 'Cancel subscription error',
  'modal.cancelSubscriptionError.text.1': 'Free plan is only available with maximum of 5',
  'modal.cancelSubscriptionError.text.2': 'employees',
  'modal.cancelSubscriptionError.text.3': 'and 1',
  'modal.cancelSubscriptionError.text.4': 'To use free version, remove locations / staff to meet the requirements.',
  'modal.latestPaymentFailed.title': 'Latest payment failed',
  'modal.latestPaymentFailed.text.1': 'To gain access to all features, please pay or to use free version remove',
  'modal.latestPaymentFailed.text.2':
    'to meet the requirements. Free plan is only available with max of 5 employees and 1 location.',
  'modal.extraCostsForNewMember.title': 'Extra costs for new member',
  'modal.extraCostsForNewMember.description':
    'By increasing number of staff there will be extra costs to your monthly subscription.',
  'modal.premiumUpgradeRequired.title': 'Premium upgrade required',
  'modal.premiumUpgradeRequired.description': 'In order to add additional {0} your plan must be upgraded to Premium',
  'modal.premiumUpgradeRequired.location': 'location',
  'modal.premiumUpgradeRequired.staffMembers': 'staff members',
  'modal.changeAccount.title': 'Change Stripe account?',
  'modal.changeAccount.description':
    "You won't be able to accept payments while you change accounts. It'll take about a minute to make the change.",
  'btn.agree': 'Agree',
  'btn.decline': 'Decline',
  'paymentMethod.paymentMethod.title': 'Payment method',
  'paymentMethod.currentPaymentMethod.title': 'Current payment method',
  'paymentMethod.expirationDate': 'Expiration date {0}',
  'paymentMethod.otherPaymentMethods.title': 'Other payment methods',
  'paymentMethod.addAnotherPaymentMethod.btn': 'Add another payment method',
  'paymentMethod.addPaymentMethod.title': 'Add payment method',
  'paymentMethod.noPaymentMethodAdded.title': 'No payment method added',
  'paymentMethod.noPaymentMethodAdded.description': 'Payment method will be added when selecting Premium plan',
  'paymentMethod.goToPlanPage.btn': 'Go to plan page',
  'paymentMethod.add.btn': 'Add',
  'paymentMethod.makeDefault.btn': 'Make default',
  'paymentMethod.cardHasBeenAdded.message': 'Card has been added',
  'paymentMethod.cardHasBeenAssign.message': 'Card has been assign',
  'page.marketing.overview.messages.description':
    'Send custom marketing messages to your clients via SMS or Email with few simple clicks',
  'page.marketing.overview.btn.sendMessage': 'Send message',
  'page.marketing.overview.campaigns.title': 'Auto-sending campaigns',
  'page.marketing.overview.campaigns.description':
    "Create personalised campaigns based on events like clients' birthdays and more. Send cross multiple channels to deliver campaigns by email and text message.",
  'page.marketing.overview.btn.newCampaign': 'New Campaign',
  'page.marketing.massMessages.title': 'Setup new message',
  'page.marketing.massMessages.messageLog': 'Message log',
  'page.marketing.massMessages.sendOn': 'Send on',
  'page.marketing.massMessages.sendTo': 'Send to',
  'page.marketing.massMessages.channel': 'Channel',
  'page.marketing.campaigns.description':
    'Send automated marketing messages to your clients via SMS or Email with few simple clicks',
  'page.marketing.campaigns.birthdaySpecial.title': 'Birthday Special',
  'page.marketing.campaigns.winBackClients.title': 'Win Back Clients',
  'page.marketing.campaigns.welcomeNewClients.title': 'Welcome New Clients',
  'page.marketing.campaigns.rewardRegularClients.title': 'Reward Regular Clients',
  'page.marketing.campaigns.birthdaySpecial.description':
    'Target disengaged clients to return with a special offer. This campaign sends to clients who did not return after a certain period of time',
  'page.marketing.campaigns.winBackClients.description':
    'Turn newcomers into regulars by encouraging first-time clients to book again with a special discount. This campaign automatically sends to clients one day after their very first sale',
  'page.marketing.campaigns.welcomeNewClients.description':
    'Surprise top clients with a special offer, a certain way to get your best clients even more engaged. This campaign automatically sends to clients based on recent appointment activity',
  'page.marketing.campaigns.rewardRegularClients.description':
    'Surprise top clients with a special offer, a certain way to get your best clients even more engaged. This campaign automatically sends to clients based on recent appointment activity',
  'page.marketing.campaigns.btn.active': 'Active',
  'page.marketing.campaigns.btn.inactive': 'Inactive',
  'page.marketing.campaigns.btn.startCampaign': 'Start Campaign',
  'page.marketing.campaigns.btn.editCampaign': 'Edit Campaign',
  'page.marketing.campaigns.btn.resetCampaign': 'Reset Campaign',
  'modal.resetCampaign.description': 'Are you sure want to reset this Campaign ?',
  'modal.resetCampaign.btn.yesReset': 'Yes, reset',
  'page.marketing.smsCreate.step.1': 'SMS setup. Step 1 of 4',
  'page.marketing.smsCreate.step.2': 'SMS setup. Step 2 of 4',
  'page.marketing.smsCreate.step.3': 'SMS setup. Step 3 of 4',
  'page.marketing.smsCreate.step.4': 'SMS setup. Step 4 of 4',
  'page.marketing.emailCreate.step.1': 'Email setup. Step 1 of 4',
  'page.marketing.emailCreate.step.2': 'Email setup. Step 2 of 4',
  'page.marketing.emailCreate.step.3': 'Email setup. Step 3 of 4',
  'page.marketing.emailCreate.step.4': 'Email setup. Step 4 of 4',
  'page.marketing.campaignCreate.step.1': 'Campaign setup. Step 1 of 3',
  'page.marketing.campaignCreate.step.2': 'Campaign setup. Step 2 of 3',
  'page.marketing.campaignCreate.step.3': 'Campaign setup. Step 3 of 3',
  'page.marketing.smsCreate.step.1.title': 'Message and sender ID',
  'page.marketing.smsCreate.step.1.defaultTitleForSms': 'Enter SMS Sender ID',
  'page.marketing.smsCreate.step.1.defaultMessageForSms': 'Enter your message',
  'page.marketing.emailCreate.step.1.defaultTitleForEmail': 'Enter message title',
  'page.marketing.emailCreate.step.1.defaultMessageForSms': 'Enter your message',
  'page.marketing.emailCreate.step.1.title': 'Email settings',
  'page.marketing.emailCreate.step.1.messageTitle': 'Message title',
  'page.marketing.emailCreate.step.1.replyToEmail': 'Reply to email',
  'page.marketing.emailCreate.step.1.clientReplies': 'Client replies will be send to this email',
  'page.marketing.emailCreate.step.1.photo.title': 'Photo',
  'page.marketing.emailCreate.step.1.enablePhoto': 'Enable photo',
  'page.marketing.emailCreate.step.1.changePhoto': 'Change photo',
  'page.marketing.emailCreate.step.1.button.title': 'Button',
  'page.marketing.emailCreate.step.1.enableButton': 'Enable button',
  'page.marketing.emailCreate.step.1.buttonName': 'Button name',
  'page.marketing.emailCreate.step.1.buttonURL': 'Button URL',
  'page.marketing.emailCreate.step.1.socialMedia.title': 'Social media',
  'page.marketing.emailCreate.step.1.enableSocialMedia': 'Enable social media links',
  'page.marketing.emailCreate.step.1.facebookPage': 'Facebook page',
  'page.marketing.emailCreate.step.1.instagramPage': 'Instagram page',
  'page.marketing.emailCreate.step.1.yourPage': 'Your page',
  'page.marketing.smsCreate.yourMessage': 'Your message',
  'page.marketing.btn.sendMeTestMessage': 'Send me test message',
  'page.marketing.smsCreate.step.1.freeTests': '{0} free tests left for today',
  'page.marketing.smsCreate.messagePreview': 'Message preview',
  'page.marketing.smsCreate.btn.clientSelection': 'Client selection',
  'page.marketing.audience.title': 'Audience',
  'page.marketing.smsCreate.step.2.description': 'Choose which clients will receive your message',
  'page.marketing.smsCreate.step.2.allClient.title': 'All Client',
  'page.marketing.smsCreate.step.2.allClient.description': 'Send message to all your clients',
  'page.marketing.smsCreate.step.2.clientGroups.title': 'Client groups',
  'page.marketing.smsCreate.step.2.clientGroups.description': 'Send message to selected client groups',
  'page.marketing.smsCreate.step.2.totalClients': 'Total Clients',
  'page.marketing.smsCreate.step.2.totalPrice': 'Total price',
  'page.marketing.smsCreate.step.3.description': 'Pick time you want your message to be send',
  'page.marketing.smsCreate.step.3.btn.selectDateTime': 'Select custom date and time',
  'page.marketing.smsCreate.step.4.title': 'Payment for mass message',
  'page.marketing.smsCreate.step.4.description': 'Type down your credit card details and pay',
  'page.marketing.smsCreate.step.4.paymentAmount': 'Payment amount:',
  'btn.scheduleSend': 'Schedule send',
  'btn.previous': 'Previous',
  'btn.payment': 'Payment',
  'btn.payAndFinish': 'Pay and finish',
  'btn.backToDashboard': 'Back to dashboard',
  'btn.saveAndClose': 'Save and close',
  'modal.editClientSelection.title': 'Edit client selection',
  'modal.newClient.description': 'Clients added in the last:',
  'modal.recentClient.title': 'Recent Client',
  'modal.recentClient.description': 'Clients who visited in the last:',
  'modal.clientByAge.title': 'Client by Age',
  'modal.clientsByGender.title': 'Clients by Gender',
  'modal.paymentConfirmation.title': 'Payment successful !',
  'modal.paymentConfirmation.messageSend': 'Your message will be send',
  'modal.previewMessage.title': 'Preview Message',
  'modal.previewSms.tab': 'Preview SMS',
  'modal.previewEmail.tab': 'Preview Email',
  'modal.editServiceSelection.title': 'Edit service selection',
  'modal.editServiceSelection.allServices': 'All services',
  'modal.campaignActivated.title': 'Campaign activated !',
  'modal.campaignActivated.description': 'Your can pause or edit this Campaign at any time',
  'btn.all': 'All {0}',
  'btn.included': 'Included {0}',
  'btn.excluded': 'Excluded {0}',
  'checkbox.allClients': 'All clients {0}',
  'scheduleSend.time': 'time',
  'scheduleSend.timeSetTo': 'Time set to:',
  'scheduleSend.setTime': 'Set time',
  'campaignSetup.step.1.header.title': 'Campaign setting',
  'campaignSetup.step.2.header.title': 'Edit message',
  'campaignSetup.step.3.header.title': 'Review',
  'campaignSetup.channel.description': 'Choose what channels campaign will be sent',
  'campaignSetup.smsSetup.tab': 'SMS setup',
  'campaignSetup.emailSetup.tab': 'Email setup',
  'campaignSetup.almostDone.title': 'Almost done !',
  'campaignSetup.almostDone.description':
    'Your smart campaign is ready to go, once enabled it will automatially send messages to clients on an ongoing basis. You can easily modify or pause this campaign at any time',
  'campaignSetup.approximatePrice.header': 'Approximate price',
  'campaignSetup.approximatePrice.description': 'For upcoming 30 days',
  'campaignSetup.approximatePrice.approx': 'Approx',
  'campaign.birthdaySpecial.description': 'This campaign automatically sends to clients close to their birthday',
  'campaign.birthdaySpecial.sendToClient': 'Send to Client:',
  'campaign.birthdaySpecial.selectOption.1': 'On the day of birthday',
  'campaign.birthdaySpecial.selectOption.2': 'Three days before birthday',
  'campaign.birthdaySpecial.selectOption.3': 'One week before birthday',
  'campaign.birthdaySpecial.selectOption.4': 'Two weeks before birthday',
  'campaign.winBackClients.description':
    'This campaign sends to clients who did not return after a certain period of time',
  'campaign.winBackClients.clientsWhoDidntReturn': 'Clients who didn’t return in the last',
  'campaign.winBackClients.appliedTo': 'Applied to',
  'campaign.winBackClients.all': ' all ',
  'campaign.winBackClients.services': 'services',
  'campaign.winBackClients.editSelection': 'Edit Selection',
  'campaign.welcomeNewClients.description':
    'Turn newcomers into regulars by encouraging first-time clients to book again with a special discount',
  'campaign.welcomeNewClients.smartCampaignNewClients':
    'This smart campaign automatically sends to new clients one day after their first-ever sale',
  'campaign.rewardRegularClients.description':
    'This campaign automatically sends to clients based on recent appointment activity',
  'campaign.rewardRegularClients.clientsWithAtLeast': 'Clients with at least',
  'campaign.rewardRegularClients.appointmentsInThe': 'appointments in the',
  'campaign.rewardRegularClients.last': 'last',
  'campaign.rewardRegularClients.appointments': 'appointments',
  'campaign.rewardRegularClients.inTheLast': 'in the last',
  'campaign.rewardRegularClients.months': 'months',
  'subscriptionPaymentFailed.title':
    'Please update your payment method until {0}, otherwise all premium features will be discontinued, thank you.',
  'subscriptionCancelled.title':
    'We apologize but your Premium has been deactivated due to non-payment. If you wish to continue using premium features, please activate Premium Plan, thank you.',
  'notificationPaymentFailed.title':
    'Your payment for notifications has failed. Please complete the payment until {0}, otherwise notifications will be disabled, thank you.',
  'notificationCancelled.title':
    'We apologize but your notifications has been deactivated due to non-payment. If you wish to continue sending notifications, please make payment, thank you.',
  'btn.hideOptions': 'Hide options',
  'btn.showOptions': 'Show options',
  'notification.googleDisconnected': 'Google Calendar disconnected',
  'notification.googleConnected': 'Google Calendar connected',
  'notification.appleDisconnected': 'Apple Calendar disconnected',
  'notification.appleConnected': 'Apple Calendar connected',
  'notification.microsoft365Disconnected': 'Microsoft365 Calendar disconnected',
  'notification.microsoft365Connected': 'Microsoft365 Calendar connected',
  'notification.linkCopied': 'Link copied',
  'calendarSync.title': 'Calendar sync',
  'calendarSync.description':
    'One way sync Plandok appointments with your favorite calendar for effortless organization and never miss an important event.',
  'googleCalendar.title': 'Google Calendar',
  'appleCalendar.title': 'Apple Calendar',
  'microsoft365Calendar.title': 'Microsoft365 Calendar',
  'linkYourCalendar.title': 'Link your Calendars',
  'connect.btn': 'Connect',
  'disconnect.rtn': 'Disconnect',
  'connected.label': 'Connected',
  'modal.workingHours.standartMethod.btn': 'Standart method',
  'modal.workingHours.customMethod.btn': 'Custom method',
  'modal.workingHours.workingDates.input': 'Working dates',
  'modal.workingHours.selectDates.placeholder': 'Select dates',
  'noInternetConnection.notification.title': 'No internet connection',
  'app.update.title': 'New version available',
  'app.update.description.title':
    'We added new features and fix some bugs to make your experience with Plandok as smooth as possible.',
  'app.update.button.title': 'Update',
  'app.notNow.button.title': 'Not now',
  'app.updateAvailable.button.title': 'Update available',
  'upgradePlan.success.message': 'Subscription has been successfully updated',
  'upgradePlan.error.message':
    'Subscription update error, please contact support if your card is charged and the subscription is not upgraded',
  'sidebar.inventory': 'Inventory',
  'products.tab': 'Products',
  'suppliers.tab': 'Suppliers',
  'stockOrders.tab': 'Stock orders',
  'stockTracking.tab': 'Stock tracking',
  'input.productsSearch.placeholder': 'Search by product name or barcode',
  'product.create.btn.title': 'Add new product',
  'table.products.product': 'Product',
  'table.products.barcode': 'Barcode',
  'table.products.brand': 'Brand',
  'table.products.sale': 'Sale',
  'table.products.saleOpt': 'Sale Opt.',
  'saleOptions.tooltip.title': 'Sale options',
  'saleOptions.tooltip.description': 'You can choose to enable/disable selling during checkout.',
  'seeMore.title': 'see more',
  'option.products.downloadCSV': 'Download CSV',
  'option.products.downloadExcel': 'Download Excel',
  'option.products.manageBrands': 'Manage Brands',
  'option.products.manageCategories': 'Manage Categories',
  'option.products.manageStock': 'Manage Stock',
  'option.products.importProducts': 'Import Products',
  'modal.filter.categories.title': 'Categories',
  'modal.filter.brands.title': 'Brands',
  'modal.filter.stock.title': 'Stock',
  'modal.filter.allCategories.selectItem': 'All categories',
  'modal.filter.allBrands.selectItem': 'All brands',
  'modal.filter.allSuppliers.selectItem': 'All suppliers',
  'modal.filter.allProducts.selectItem': 'All products',
  'input.suppliersSearch.placeholder': 'Search by supplier name',
  'supplier.create.btn.title': 'Add new supplier',
  'table.suppliers.supplierName': 'Supplier name',
  'table.suppliers.phoneNumber': 'Phone number',
  'table.suppliers.physicalAddress': 'Physical address',
  'validation.notification.reminderTime': 'Can`t send notifications with the same reminder time.',
  'validation.paymentType.switches': 'At least one payment method must be allowed',
  'plan.premium.option.14':
    '0% Plandok commission fee for appointments booked through Online Bookings using credit card.',
  'title.dragAndExtend.appointment.permission': 'Can drag and extend appointments',
  'button.lastVisit.addAnotherLastvisit': 'ADD ANOTHER LAST VISIT',
  'notifications.template.badge.validate': 'Validate',
  'notifications.template.badge.refused': 'Refused',
  'paid.by.card': 'Paid by card',
  'costs.manual.paid.link': 'Or you can pay for your notifications manually with your card, by clicking on this link',
  'modal.remove.account.title': 'Remove Stripe account',
  'modal.remove.account.description': "Your account will be removed and you won't be able to accept online payments. ",
  'ob.remove.account.option': 'Remove Stripe account',
  'btn.remove.account': 'Remove',
};

export default labels;
