import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { getIsMobile, useHistory, useModal } from '@plandok/core';
import { RoutePath } from 'constants/routes';
import { BookingAnalyticCard } from './components/BookingAnalyticCard';
import getAnalyticsServices from './helpers';
import facebook from './icons/facebook.svg';
import google from './icons/google.svg';
import * as SC from './styles';

export type AnalyticsServices = {
  googleTrackerId: string | null;
  facebookTrackerId: string | null;
};

export default function BookingAnalytics() {
  const [analyticsServices, setAnalyticsServices] = useState<AnalyticsServices>();
  const [isLoading, setIsLoading] = useState(false);
  const [, { showModal }] = useModal();
  const isMobile = getIsMobile();
  const history = useHistory();

  useEffect(() => {
    getAnalyticsServices(setIsLoading, setAnalyticsServices);
  }, []);

  const viewAnalytics = (type: string) => () =>
    isMobile
      ? history.push(
          type === 'GOOGLE_ANALYTICS' ? RoutePath.GOOGLE_ANALYTICS : RoutePath.FACEBOOK_PIXEL,
          analyticsServices
        )
      : showModal({
          type: `VIEW_${type}_MODAL`,
          modalProps: { analyticsServices, onSuccess: () => getAnalyticsServices(setIsLoading, setAnalyticsServices) },
        });

  const viewGoogleAnalytics = viewAnalytics('GOOGLE_ANALYTICS');
  const viewFacebookPixel = viewAnalytics('FACEBOOK_PIXEL');

  return (
    <Spin spinning={isLoading}>
      <SC.Container>
        <BookingAnalyticCard
          title="ob.analytics.google.title"
          description="ob.analytics.google.description"
          icon={google}
          link={analyticsServices?.googleTrackerId}
          onClick={viewGoogleAnalytics}
        />
        <BookingAnalyticCard
          title="ob.analytics.facebook.title"
          description="ob.analytics.facebook.description.1"
          icon={facebook}
          link={analyticsServices?.facebookTrackerId}
          onClick={viewFacebookPixel}
        />
      </SC.Container>
    </Spin>
  );
}
