import styled, { css } from 'styled-components';
import { mediaMobileDown, mediaMdUp } from '@plandok/core';
import SBox from 'components/styled-components/SBox';

type EmptyContainerProps = {
  isPadding?: boolean;
};

export const EmptyContainer = styled.div<EmptyContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #61749d;
  padding-top: ${({ isPadding }) => (isPadding ? '40px' : 'none')};

  button {
    width: 10rem;
  }
  img {
    margin-bottom: 1rem;
  }
`;

export const AddItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 13px auto;
  color: #adb6cc;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  i {
    font-size: 25px;
    color: #13316d;
    margin-right: 5px;
  }

  ${mediaMobileDown(css`
    flex-direction: row-reverse;
    width: 100%;
    color: ${(props) => props.theme.primaryColor};
    margin-right: 0;
    i {
      margin-left: 20px;
    }
  `)}
`;

export const ClientListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 380px;
  ${mediaMobileDown(css`
    height: calc(100% - 10rem);
  `)}
`;

export const MobileAddItemContainer = styled.div`
  display: flex;

  ${mediaMobileDown(css`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  `)}
`;

export const ClientList = styled(SBox)<any>`
  display: flex;
  background: white;
  flex-direction: column;
  overflow: auto;
  border: none;
` as any;

export const ClientItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  color: ${(props) => props.theme.primaryColor};

  &:not(:last-of-type) {
    border-bottom: 1px solid #e9ecf3;
  }
  &:hover {
    background: #f8f9fc;
  }

  [data-client-item-avatar] {
    margin-right: 15px;
  }

  [data-client-item-info] {
    line-height: 16px;
    div:first-of-type {
      font-weight: 500;
    }
    div:not(:first-of-type) {
      font-weight: 300;
      font-size: 14px;
    }
  }
`;

export const SelectClientMobile = styled.div<any>`
  display: flex;
  text-align: center;
  align-items: center;
  color: ${(props) => props.theme.primaryColor};
  font-weight: 500;
  padding: 0 15px;
  margin-bottom: 15px;
  cursor: pointer;

  i:first-of-type {
    color: #adb6cc;
    font-size: 24px;
    margin-right: 10px;
  }

  i:last-of-type {
    margin-left: auto;
    font-size: 12px;
  }

  ${mediaMdUp(css`
    display: none;
  `)}
` as any;

export const Card = styled.div`
  box-shadow: 0px 2px 10px #263c7a33;
`;

export const Container = styled.div`
  box-shadow: 0px 2px 10px #263c7a33;
  border-radius: 20px;
  padding: 1rem;
  background-color: #fff;

  ${mediaMobileDown(
    css`
      display: none;
      box-shadow: none;
      border-radius: 0;
    `
  )}
`;

export const ClientContainer = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  background: white;
  padding-bottom: 30px;
`;

export const CloseIcon = styled.div`
  font-size: 20px;
  color: #adb6cc;
  position: absolute;
  right: 20px;
  top: 16px;
  cursor: pointer;
`;

export const MobileContainer = styled.div`
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 50px 20px 20px 20px;
`;
