import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu, Dropdown } from 'antd';
import flow from 'lodash/flow';
import { Button, CustomIcon } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import * as SC from './styles';

const FadeMask = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 109;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
`;

const MenuItem = styled(Menu.Item)`
  display: flex !important;
  align-items: center;
  &:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  &:last-of-type {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  svg {
    margin-right: 10px;
  }
`;

const MenuHok = (props: any) => (
  <Menu className="app-common-dropdown br-default">
    {props.actions?.map((e: any) => (
      <MenuItem onClick={flow(props.toggleOpen, e.action)}>
        {e.icon && <CustomIcon type={e.icon} />}
        {e.title && <IntlLabel label={e.title} />}
      </MenuItem>
    ))}
  </Menu>
);

export default function MultipleAddButton({ actions, className }: any) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  return (
    <>
      {open && <FadeMask />}
      <Dropdown
        overlay={<MenuHok actions={actions} toggleOpen={toggleOpen} />}
        trigger={['click']}
        onVisibleChange={toggleOpen}
        placement="topRight"
        visible={open}
      >
        <SC.ButtonContainer className={className} open={open}>
          <Button type="primary" icon="plus" minorBtn />
        </SC.ButtonContainer>
      </Dropdown>
    </>
  );
}
