export enum LogType {
  CREATED = 'created',
  UPDATED = 'updated',
  RESCHEDULED = 'rescheduled',
  CANCELLED = 'cancelled',
  CHANGED_STATUS = 'changed_status',
}

export enum NotificationType {
  REMINDER = 'reminder',
  CONFIRMATION = 'confirmation',
  RESCHEDULING = 'rescheduling',
  CANCELLATION = 'cancellation',
  THANK_YOU = 'thank_you',
  LAST_VISIT = 'last_visit',
}

export type Log = {
  id: string;
  type: LogType | NotificationType;
  createdAt: string;
  employee?: {
    firstName: string;
    lastName: string;
  };
  channel?: string;
  status?: string;
};
