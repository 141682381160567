import { getPath, callAction, callActionWithId } from '@plandok/core';

export const fetchRefSources = callAction('/referral-sources', 'get');
export const fetchRefSourcesOptions = callAction('/referral-sources/for-select', 'get');
export const createRefSource = callAction('/referral-sources', 'post', true);
export const editRefSource = callActionWithId('/referral-sources/{referralSourceId}', 'put', true);
export const deleteRefSource = callActionWithId('/referral-sources/{referralSourceId}', 'delete');
export const fetchRefSourceDetails = callActionWithId('/referral-sources/{referralSourceId}', 'get');

export const fetchCategoriesOptions = callAction('/services/for-select', 'get');
export const fetchCategories = callAction('/service-groups', 'get');
export const fetchCategoryOptions = callAction('/service-groups/for-select', 'get');

export const createCategory = callAction('/service-groups', 'post');
export const editCategory = callActionWithId('/service-groups/{serviceGroupId}', 'put');
export const deleteCategory = callActionWithId('/service-groups/{serviceGroupId}', 'delete', true);
export const fetchCategoryDetails = callActionWithId('/service-groups/{serviceGroupId}', 'get');
export const changeServiceGroupPosition = callActionWithId(
  '/service-groups/{serviceGroupId}/change-position/{newPosition}',
  'post',
  false,
  {
    customUrlFunc: (id: string, params: { id: string; newPosition: number }) =>
      `/service-groups/${params.id}/change-position/${params.newPosition}`,
  }
);

export const changeServicePosition = callActionWithId(
  '/services/{serviceId}/change-position/{newPosition}',
  'post',
  false,
  {
    customUrlFunc: (id: string, params: { id: string; newPosition: number }) =>
      `/services/${params.id}/change-position/${params.newPosition}`,
  }
);

export const fetchLocationsOptions = callAction('/locations/for-select', 'get');
export const fetchLocationsCalendarOptions = callAction('/locations/for-select?for-calendar', 'get');
export const fetchLocations = callAction('/locations', 'get');
export const createLocation = callAction('/locations', 'post', true);
export const changeLocationPosition = callActionWithId(
  '/locations/{locationId}/change-position/{newPosition}',
  'post',
  false,
  {
    customUrlFunc: (id: string, params: { id: string; newPosition: number }) =>
      `/locations/${params.id}/change-position/${params.newPosition}`,
  }
);

export const editLocation = callActionWithId('/locations/{locationId}', 'put', true);
export const deleteLocation = callActionWithId('/locations/{locationId}', 'delete');
export const fetchLocationDetails = callActionWithId('/locations/{locationId}', 'get');

export const fetchCalendarConfig = callAction('/calendar-settings', 'get');
export const updateCalendarConfig = callAction('/calendar-settings', 'post', true);

export const fetchCompanyDetails = callAction('/company-details', 'get');
export const updateCompanyDetails = callAction('/company-details', 'post', true);

export const fetchResourcesOptions = callAction('/resources/for-select', 'get');
export const fetchResources = callAction('/resources', 'get');
export const createResource = callAction('/resources', 'post');
export const editResource = callActionWithId('/resources/{resourceId}', 'put');
export const deleteResource = callActionWithId('/resources/{resourceId}', 'delete');
export const fetchResourceDetails = callActionWithId('/resources/{resourceId}', 'get');

export const fetchServiceOptions = callAction('/services/for-select', 'get');
export const createService = callAction('/services', 'post', true);
export const editService = callActionWithId('/services/{serviceId}', 'put', true);
export const fetchServiceDetails = callActionWithId('/services/{serviceId}', 'get');
export const deleteService = callActionWithId('/services/{serviceId}', 'delete');

export const fetchEmployeesOptions = callAction('/employees/for-select', 'get');
export const fetchEmployeesCalendarOptions = callAction('/employees/for-calendar-select', 'get');
export const fetchEmployees = callAction('/employees', 'get');
export const changeEmployeePosition = callActionWithId(
  '/locations/{locationId}/change-position/{newPosition}',
  'post',
  false,
  {
    customUrlFunc: (id: string, params: { id: string; newPosition: number }) =>
      `/employees/${params.id}/change-position/${params.newPosition}`,
  }
);
export const fetchEmployeesHours = callAction('/schedule-ranges/for-employees', 'get');
export const createStaff = callAction('/employees', 'post', true);
export const updateStaff = callActionWithId('/employees/{employeeId}', 'put', true);
export const fetchStaffDetails = callActionWithId('/employees/{employeeId}', 'get');
export const fetchIsWorkingStaff = callActionWithId('/employees/{employeeId}/is-working', 'get', true, {
  customUrlFunc: (
    id: string,
    params: { employeeId: string; date: string | Date; locationId: string; time: number; duration: number }
  ) =>
    `/employees/${params.employeeId}/is-working?locationId=${params.locationId}&date=${params.date}&time=${params.time}`,
});
export const deleteStaff = callActionWithId('/employees/{employeeId}', 'delete');
export const deleteEmployeesAccount = callAction('/employees/delete/account', 'delete');

export const fetchCalendar = callAction('/calendar-view', 'get');
export const createAppointment = callAction('/appointments', 'post', true);
export const updateAppointment = callActionWithId('/appointments/{appointmentId}', 'post', true);
export const checkIsBookedAppointment = callActionWithId('/appointment/{employeeId}/is-booked', 'get', true, {
  customUrlFunc: (
    id: string,
    params: { employeeId: string; locationId: string; date: Date; startTime: number; duration: number }
  ) =>
    `/appointment/${params.employeeId}/is-booked?locationId=${params.locationId}&date=${params.date}&startTime=${params.startTime}&duration=${params.duration}`,
});

export const fetchAppointmentDetails = callActionWithId('/appointments/{appointmentId}', 'get');
export const changeAppointmentStatus = callActionWithId('/appointments/{appointmentId}/change-status', 'post');

export const updateUserLanguage = callActionWithId('/me/language/{languageIsoCode}', 'post');

export const createBlockTime = callAction('/blocked-times', 'post', true);
export const editBlockTime = callActionWithId('/blocked-times/{blockedTimeId}', 'put', true);
export const deleteBlockTime = callActionWithId('/blocked-times/{blockedTimeId}', 'delete', true);

export const fetchCancelReasons = callAction('/cancellation-reasons', 'get');
export const createCancelReason = callAction('/cancellation-reasons', 'post', true);
export const editCancelReason = callActionWithId('/cancellation-reasons/{cancellationReasonId}', 'put', true);
export const deleteCancelReason = callActionWithId('/cancellation-reasons/{cancellationReasonId}', 'delete');
export const fetchCancelReasonDetails = callActionWithId('/cancellation-reasons/{cancellationReasonId}', 'get');

export const fetchSearch = callAction('/search', 'get');
export const closeAppointment = callActionWithId('/appointments/{appointmentId}/cancel', 'post', true);

export const createNotificationConfig = callActionWithId('/notification-templates/{notificationType}', 'post');
export const deleteNotificationConfig = callActionWithId('/notification-template/{templateId}', 'delete');
export const updateNotificationConfig = callActionWithId(
  '/notification-template/{templateId}/{notificationType}',
  'patch',
  true,
  {
    customUrlFunc: (id: { templateId: string; notificationType: string }) => {
      return `/notification-template/${id.templateId}/${id.notificationType}`;
    },
  }
);
export const changeNotificationStatus = callActionWithId(
  '/notification-template/change-status/{notificationType}',
  'patch',
  true
);
export const fetchNotificationConfig = callActionWithId('/notification-templates/{notificationType}', 'get');
export const sendTestNotification = callAction('/notification/send/test', 'post', true);
// >>>>>>> master

export const fetchProfileDetails = callAction('/me/profile', 'get');
export const editProfileDetails = callAction('/me/profile', 'post', true);

export const fetchUserPermissions = callAction('/permissions', 'get');
export const updateUserPermissions = callAction('/permissions', 'post');

export const createStaffHours = callActionWithId('/employees/{employeeId}/schedule-ranges', 'post', true);
export const deleteStaffRanges = callActionWithId('/employees/{employeeId}/schedule-ranges', 'delete', true, {
  customUrlFunc: (id: string, params: any) => getPath(`/employees/${id}/schedule-ranges`, 'delete', params),
});

export const fetchNotificationsLogs = callAction('/notification-logs', 'get');

export const fetchBillingInfo = callAction('/subscription-billing/customer', 'get');
export const fetchInvoices = callAction('/subscription-billing/payments', 'get');
export const updateBillingInfo = callAction('/subscription-billing/customer', 'post', true);

export const fetchAnalyticsServices = callAction('/online/booking/tracker', 'get');
export const updateAnalyticService = callAction('/update/online/tracker', 'patch');

export const fetchPricing = callAction('/subscription-billing/notification/pricing', 'get');

export const getAllPaymentTypes = callAction('/payment-types', 'get');
export const updatePaymentStatus = callActionWithId('/payment-type/{id}/update', 'post');
export const onboardPaymentType = callActionWithId('/payment-type/{id}/onboard', 'post');
export const removePaymentType = callActionWithId('/payment-type/{id}/remove', 'delete');

export const loginPaymentType = callActionWithId('/payment-type/{id}/login', 'get');
