import { FieldRenderProps } from 'react-final-form';
import withFormBlock from './FormBlock/withFormBlock';
import SelectInput from '../input/SelectInput';
import SelectWithTranslate from '../input/SelectInput/SelectWithTranslate';
import React from 'react';

interface FormSelectProps extends FieldRenderProps<any, any> {
  submitting?: boolean;
  translate?: boolean;
  options?: any[];
}

const FormSelect = ({ input, translate, ...rest }: FormSelectProps) => {
  return translate ? <SelectWithTranslate {...input} {...rest} /> : <SelectInput {...input} {...rest} />;
};

export default withFormBlock<FormSelectProps>(FormSelect);
