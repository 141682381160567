import { Divider } from 'antd';
import React from 'react';
import { App_OccupancyAnalytics } from '../../../../../../graphql/generatedTypes';
import * as StyledCommon from '../../styles';
import { NoData } from '../NoData/NoData';
import { OccupancyHeader } from './OccupancyHeader';
import OccupancyStatus from './OccupancyStatus';
import * as SC from './styles';

type OccupancyProps = {
  occupancy: App_OccupancyAnalytics;
  occupancyPercentDiff: number;
};

export const Occupancy = ({ occupancy, occupancyPercentDiff }: OccupancyProps) => {
  const occupancyLength = Object.keys(occupancy)?.length;
  return (
    <SC.Occupancy>
      <OccupancyHeader totalPercent={occupancy?.percent} percentDiff={occupancyPercentDiff} />
      <SC.Divider as={Divider} />
      <StyledCommon.ScrollableContent>
        {!!occupancyLength && <OccupancyStatus occupancy={occupancy} />}
      </StyledCommon.ScrollableContent>
      {!occupancyLength && <NoData />}
    </SC.Occupancy>
  );
};

export default Occupancy;
