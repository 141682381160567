import styled, { css } from 'styled-components';
import { Card, mediaLgDown, mediaSmDown, mediaXlDown } from '@plandok/core';

export const BillingInformation = styled(Card)`
  width: 850px;
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  padding: 20px 40px 16px;
  margin: 9px auto 40px;
  text-align: start;
  .billing-information {
    font-size: 24px;
    line-height: 28px;
  }
  .ant-select-selection-selected-value,
  .ant-input {
    color: #13316d;
  }
  .ant-form-item-no-colon {
    font-size: 14px;
  }
  .ant-form-explain {
    min-height: 24px;
  }
  ${mediaXlDown(css`
    border-radius: 5px;
  `)}
  ${mediaLgDown(css`
    width: calc(100vw - 20px);
    padding: 17px 14px 16px;
    margin: 4px 0 30px;
    .billing-information {
      font-size: 28px;
      line-height: 33px;
    }
  `)}
`;

export const BottomBtnContainer = styled.div`
  margin: 0 auto;
  max-width: 436px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  button {
    box-shadow: none;
    min-width: 203px;
    font-size: 18px;
    line-height: 21px;
  }
  button:nth-child(1) {
    border: 1px solid #adb6cc;
  }
  ${mediaSmDown(css`
    padding-bottom: 18px;
    max-width: 343px;
    button {
      min-width: 163px;
      font-size: 14px;
      line-height: 16px;
    }
  `)}
`;

export const ContainerTabs = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 870px;
  ${mediaLgDown(css`
    width: calc(100vw - 34px);
  `)}
`;

export const CardDetails = styled(Card)`
  width: 593px;
  box-shadow: 0 2px 10px rgba(38, 60, 122, 0.2);
  padding: 20px 24px 40px 40px;
  margin: 9px auto 40px;
  text-align: start;

  ${mediaXlDown(css`
    border-radius: 5px;
  `)}
  ${mediaSmDown(css`
    width: calc(100vw - 20px);
    padding: 17px 14px 30px;
    margin: 4px auto 30px;
  `)}
`;

export const PayWithCardBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 9px;
  ${mediaSmDown(css`
    padding-bottom: 16px;
  `)}
`;

export const InputsCardBlock = styled.div`
  max-width: 512px;
  border-top: 1px solid #dfe6f1;
  padding-top: 16px;
  line-height: 16px;
  .StripeElement {
    padding: 11px 24px;
    border: 1px solid #adb6cc;
    border-radius: 26px;
    min-width: 241px;
  }
  .StripeElement--focus,
  .StripeElement--hover {
    border-color: grey;
  }

  ${mediaSmDown(css`
    .StripeElement {
      min-width: 157px;
    }
  `)}
`;

export const CardElementsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
`;

export const ContainerPaymentAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
  div:nth-child(1) {
    line-height: 21px;
  }
  div:nth-child(2) {
    line-height: 33px;
  }
  ${mediaSmDown(css`
    margin-bottom: 16px;
  `)}
`;
