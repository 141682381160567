import { Icon, Checkbox, Radio } from 'antd';
import React, { useState } from 'react';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import { Button, CustomIcon, Form, Text, getIsMobile, useLocation, useModal } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { ModifyMassLayout } from 'components';
import { RoutePath } from 'constants/routes';
import * as SC from '../styles';

const ClientGroupData = [
  {
    clientGroupName: 'New Client',
    number: '(8)',
    text: 'Client added in the',
    date: 'last 30 days',
    dateFront: false,
  },
  {
    clientGroupName: 'Recent Clients',
    number: '(18)',
    text: 'Clients who visited in the',
    date: 'last 30 days',
    dateFront: false,
  },
  {
    clientGroupName: 'Client by Age',
    number: '(48)',
    text: 'years old',
    date: '25~35',
    dateFront: true,
  },
  {
    clientGroupName: 'Client by Gender',
    number: '(15)',
    text: 'selected',
    date: 'Females only',
    dateFront: true,
  },
];

export default function ClientSelectionForm(props: any) {
  const [clientType, setClientType] = useState('All Client');
  const [clientGroupType, setClientGroupType] = useState(0);
  const { navigate, goBack } = useLocation();
  const [, { showModal }] = useModal();
  const [ClientGroupDataList, setClientGroupDataList] = useState(ClientGroupData);

  const modals = [
    'CREATE_NEW_CLIENT_MODAL',
    'CREATE_RECENT_CLIENT_MODAL',
    'CREATE_CLIENT_BY_AGE_MODAL',
    'CREATE_CLIENT_BY_GENDER_MODAL',
  ];

  const scheduleSend = () => navigate(RoutePath.SCHEDULE_SEND);

  const isMobile = getIsMobile();

  const editSelection = () => {
    showModal({
      type: 'EDIT_CLIENT_SELECTION',
      modalProps: { String },
    });
  };

  const PriceData = [
    {
      characterData: '150 messages x 0.08 EUR Lietuva',
      count: '12.00',
      unit: '€',
    },
    {
      characterData: '50 messages x 0.15 EUR Latvia ',
      count: '7.50',
      unit: '€',
    },
    {
      characterData: 'VAT 21%',
      count: '4.10',
      unit: '€',
    },
  ];
  const ClientGroupPriceData = [
    {
      characterData: '8 messages x 0.08 EUR Lietuva',
      count: '0.64',
      unit: '€',
    },
    {
      characterData: 'VAT 21%',
      count: '0.10',
      unit: '€',
    },
  ];
  const handleSubmit = (form: any) =>
    props.onSubmit({
      ...form,
      price: form.price || 0,
    });

  const updateGender = (groupName: string, dateChange: string) => {
    let newClientGroupData = [...ClientGroupDataList];
    const index = newClientGroupData.findIndex((item) => item.clientGroupName === groupName);
    newClientGroupData[index] = { ...newClientGroupData[index], date: dateChange };
    setClientGroupDataList(newClientGroupData);
  };
  const editTime = (groupName: string) => {
    showModal({
      type: modals[clientGroupType],
      modalProps: { groupName, updateGender },
    });
  };
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        onSuccess={flow(props.onSuccess, props.modalProps?.onSuccess || noop)}
        initialValues={props.initialValues}
      >
        {({ formError, submitting }: any) => (
          <>
            <SC.CardContainer>
              <SC.HeaderTitle>
                <Text weight="semiBold" size="mlarge" mb="none">
                  <IntlLabel label="Audience" />
                </Text>
                <Text size="base" weight="normal">
                  <IntlLabel label="Choose which clients will receive your message" />
                </Text>
              </SC.HeaderTitle>
              <SC.CheckboxListProps>
                <SC.CheckboxList border={clientType === 'All Client'}>
                  <Checkbox checked={clientType === 'All Client'} onClick={() => setClientType('All Client')}>
                    <IntlLabel label="All Client" />
                    <Text size={isMobile ? 'small' : 'base'} weight="normal" mb="none" ml={isMobile ? 'none' : 'large'}>
                      <IntlLabel label="Send message to all your clients" />
                    </Text>
                  </Checkbox>
                </SC.CheckboxList>
                <SC.CheckboxList border={clientType === 'Client groups'}>
                  <Checkbox checked={clientType === 'Client groups'} onClick={() => setClientType('Client groups')}>
                    <IntlLabel label="Client groups" />
                    <Text size={isMobile ? 'small' : 'base'} weight="normal" mb="none" ml={isMobile ? 'none' : 'large'}>
                      <IntlLabel label="Send message to selected client groups" />
                    </Text>
                  </Checkbox>
                </SC.CheckboxList>
              </SC.CheckboxListProps>
              {clientType === 'Client groups' && (
                <>
                  {ClientGroupDataList &&
                    ClientGroupDataList.map((item, index) => (
                      <SC.ClientGroups key={index} background={clientGroupType === index}>
                        <Radio onClick={() => setClientGroupType(index)} checked={clientGroupType === index}>
                          <IntlLabel label={item.clientGroupName} />
                          <Text weight="normal" mb="none" ml="xxsmall" style={{ display: 'inline' }}>
                            <IntlLabel label={item.number} />
                          </Text>
                          <div className="d-flex">
                            {item.dateFront && (
                              <Text size={isMobile ? 'small' : 'base'} weight="semiBold" mb="none">
                                <IntlLabel label={item.date} />
                              </Text>
                            )}
                            <Text
                              size={isMobile ? 'small' : 'base'}
                              weight="normal"
                              mb="none"
                              ml={item.dateFront ? 'xxsmall' : 'none'}
                            >
                              <IntlLabel label={item.text} />
                            </Text>
                            {!item.dateFront && (
                              <Text size={isMobile ? 'small' : 'base'} weight="semiBold" mb="none" ml="xxsmall">
                                <IntlLabel label={item.date} />
                              </Text>
                            )}
                          </div>
                        </Radio>
                        {clientGroupType === index && (
                          <SC.EditSelection onClick={() => editTime(item.clientGroupName)}>
                            <CustomIcon type="edit" />
                            <IntlLabel label="Edit time" />
                          </SC.EditSelection>
                        )}
                      </SC.ClientGroups>
                    ))}
                  <SC.TotalClientGroup>
                    <SC.DividerWithMargin />
                    <Text weight="semiBold" size="mlarge" mb="none">
                      <IntlLabel label="Total Clients" />
                    </Text>
                    <SC.TotalClient>
                      <Text size="base" weight="normal" mb="none">
                        <IntlLabel label="8 Clients" />
                      </Text>
                      <SC.EditSelection onClick={editSelection}>
                        <CustomIcon type="edit" />
                        <IntlLabel label="Edit Selection" />
                      </SC.EditSelection>
                    </SC.TotalClient>
                    <SC.DividerWithMargin />
                    <Text weight="semiBold" size="mlarge">
                      <IntlLabel label="Price" />
                    </Text>
                    {ClientGroupPriceData &&
                      ClientGroupPriceData.map((item, i) => (
                        <SC.PriceWrapper key={i}>
                          <Text size={isMobile ? 'small' : 'base'} mb="none">
                            <IntlLabel label={item.characterData} />
                          </Text>
                          <SC.Price>
                            <Text size={isMobile ? 'small' : 'base'} mb="none">
                              <IntlLabel label={item.count} />
                            </Text>
                            <Text size={isMobile ? 'small' : 'base'} ml="xsmall" mb="none">
                              <IntlLabel label={item.unit} />
                            </Text>
                          </SC.Price>
                        </SC.PriceWrapper>
                      ))}
                    <SC.TopPrice>
                      <Text weight="semiBold" size="medium">
                        <IntlLabel label="Total" />
                      </Text>
                      <SC.Price>
                        <Text weight="semiBold" size="medium" mb="none">
                          <IntlLabel label="0.74" />
                        </Text>
                        <Text weight="semiBold" size="medium" ml="xsmall" mb="none">
                          <IntlLabel label="€" />
                        </Text>
                      </SC.Price>
                    </SC.TopPrice>
                  </SC.TotalClientGroup>
                </>
              )}
              {clientType === 'All Client' && (
                <SC.TotalClientGroup>
                  <Text weight="semiBold" size="mlarge" mb="none">
                    <IntlLabel label="Total Clients" />
                  </Text>
                  <SC.TotalClient>
                    <Text size="base" weight="normal" mb="none">
                      <IntlLabel label="200 Clients" />
                    </Text>
                    <SC.EditSelection onClick={editSelection}>
                      <CustomIcon type="edit" />
                      <IntlLabel label="Edit Selection" />
                    </SC.EditSelection>
                  </SC.TotalClient>
                  <SC.DividerWithMargin />
                  <Text weight="semiBold" size="mlarge">
                    <IntlLabel label="Price" />
                  </Text>
                  {PriceData &&
                    PriceData.map((item, i) => (
                      <SC.PriceWrapper key={i}>
                        <Text size={isMobile ? 'small' : 'base'} mb="none">
                          <IntlLabel label={item.characterData} />
                        </Text>
                        <SC.Price>
                          <Text size={isMobile ? 'small' : 'base'} mb="none">
                            <IntlLabel label={item.count} />
                          </Text>
                          <Text size={isMobile ? 'small' : 'base'} ml="xsmall" mb="none">
                            <IntlLabel label={item.unit} />
                          </Text>
                        </SC.Price>
                      </SC.PriceWrapper>
                    ))}
                  <SC.TopPrice>
                    <Text weight="semiBold" size="medium">
                      <IntlLabel label="Total price" />
                    </Text>
                    <SC.Price>
                      <Text weight="semiBold" size="medium" mb="none">
                        <IntlLabel label="23.60" />
                      </Text>
                      <Text weight="semiBold" size="medium" ml="xsmall" mb="none">
                        <IntlLabel label="€" />
                      </Text>
                    </SC.Price>
                  </SC.TopPrice>
                </SC.TotalClientGroup>
              )}
            </SC.CardContainer>
            <Form.Alert text={formError} />
            <ModifyMassLayout.Footer>
              <Button block type="primary" ghost upperCase={false} onClick={goBack}>
                <Icon type="arrow-left" style={{ marginRight: '10px' }} />
                <IntlLabel label={isMobile ? 'Provious' : 'Message'} />
              </Button>
              <Button
                block
                label={isMobile ? 'Next' : 'Schedule send'}
                type="primary"
                upperCase={false}
                loading={submitting}
                onClick={scheduleSend}
              >
                <Icon type="arrow-right" style={{ marginLeft: '10px' }} />
              </Button>
            </ModifyMassLayout.Footer>
          </>
        )}
      </Form>
    </>
  );
}
