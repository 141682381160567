import React, { useEffect } from 'react';
import { Spin } from 'antd';
import useGetCurrentStaff from 'hooks/use-get-current-staff';
import useQuery from 'hooks/data/useQuery';
import { useLocation } from 'hooks/router';
import * as api from 'api';
import { PricingItemGroup } from './PricingItemGroup';
import { extractPlans } from './helpers';
import { PriceType } from './constants';
import * as SC from './styles';

type PricingPageProps = {
  fetchCurrentPlan: () => void;
};

export default function PricingPage({ fetchCurrentPlan }: PricingPageProps) {
  const { isStaffLoading, staffCount } = useGetCurrentStaff();
  const { location }: any = useLocation();

  const { data, isLoading, fetchData }: any = useQuery({
    fetchData: api.fetchPlans,
    extractDataFunc: (_) => _,
  });

  useEffect(() => {
    (async () => {
      if (location.state?.eventUrl) {
        const url = new URL(`${process.env.REACT_APP_SEE_URL}`);

        url.searchParams.append('topic', `${location.state.eventUrl}`);
        url.searchParams.append('authorization', `${process.env.REACT_APP_SSE_TOKEN}`);

        const eventSource = new EventSource(url.toString());

        eventSource.onmessage = async ({ data }: { data: string }) => {
          if (JSON.parse(data)?.planUpgrade) {
            await fetchData();
            fetchCurrentPlan();
            location.state = {};
          }
        };
        eventSource.onerror = (e: Event) => console.error('Error when updating the tariff plan', e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { freePlan, premiumPlan } = extractPlans(data?.data || []);

  const currentPlanId = data?.meta?.currentPlanId;
  const endDate = data?.meta?.endDate?.replaceAll('-', '.') || '';

  return (
    <Spin spinning={isLoading || isStaffLoading}>
      <SC.PricingList>
        <PricingItemGroup
          active={currentPlanId && freePlan?.id === currentPlanId}
          plan={freePlan}
          planType={PriceType.FREE}
          fetchData={fetchData}
          planId={freePlan?.id}
          currentStaffCount={staffCount}
        />
        <PricingItemGroup
          active={currentPlanId && premiumPlan?.id === currentPlanId}
          plan={premiumPlan}
          planType={PriceType.PREMIUM}
          fetchData={fetchData}
          planId={premiumPlan?.id}
          currentStaffCount={staffCount}
          endDate={endDate}
          fetchCurrentPlan={fetchCurrentPlan}
        />
      </SC.PricingList>
    </Spin>
  );
}
