import './addon.less';
import AntdImgCrop from 'antd-img-crop';
import { withField } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { Icon, Modal, Upload } from 'antd';
import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';

type CustomRequestPayload = { file: File; onSuccess: (response: object, file: File) => void };

const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const FileUpload = ({ input }: FieldRenderProps<UploadFile[]>) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const { value } = input;

  const images =
    value?.map?.((e: any, i: number) => ({ ...e, uid: e.uid || e.name || i, status: e.status || 'done' })) || [];

  const cancelUpload = () => {
    setPreviewVisible(false);
  };

  const previewUpload = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);
  };

  const changeUpload: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    input.onChange(newFileList);
  };

  const customRequest = ({ file, onSuccess }: CustomRequestPayload) => {
    setTimeout(() => {
      onSuccess({ message: 'ok' }, file);
    }, 100);
  };

  const uploadButton = (
    <>
      <p className="ant-upload-drag-icon">
        <Icon type="upload" style={{ fontSize: '30px', color: '#0A1C43' }} />
      </p>

      <p className="ant-upload-text">
        <IntlLabel label="input.file.dnd.photos" />
      </p>
    </>
  );

  return (
    <div className="clearfix app-photo-upload">
      <AntdImgCrop
        modalTitle={(<IntlLabel label="input.cropImage.title" />) as any}
        modalOk={(<IntlLabel label="input.cropImage.btn.apply.title" />) as any}
        fillColor="white"
        grid
      >
        <Upload
          listType="picture-card"
          fileList={images}
          onPreview={previewUpload}
          onChange={changeUpload}
          customRequest={customRequest}
        >
          {(value || []).length >= 8 ? null : uploadButton}
        </Upload>
      </AntdImgCrop>

      <Modal visible={previewVisible} footer={null} onCancel={cancelUpload}>
        <br />

        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default withField(FileUpload);
