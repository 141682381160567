import cn from 'classnames';
import React from 'react';
import { getIsMobile } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { AppLayout, MobileBackButton, HashTabs, NoInternetConnection } from 'components';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { OnlineBookingHash } from 'constants/routes';
import BookingAnalytics from './BookingAnalytics';
import BookingOverview from './BookingOverview';
import BookingSettings from './BookingSettings';
import { bookingsTabsConfig } from './config';
import BookingProfile from './BookingProfile';
import BookingLinks from './BookingLinks';
import './style.less';
import BookingPayments from './BookingPayments/BookingPayments';

const MobileHeader = () => (
  <>
    <MobileBackButton />
    <div>
      <IntlLabel label="ob.title" />
    </div>
    <div />
  </>
);

export default function BookingsPage() {
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const isMobile = getIsMobile();

  return (
    <NoInternetConnection>
      <AppLayout
        headerTitle="ob.title"
        mobileHeader={MobileHeader}
        className={cn('booking-page', { 'booking-page__is-mobile': isMobile })}
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <HashTabs tabs={bookingsTabsConfig} defaultTab={OnlineBookingHash.OVERVIEW}>
          <BookingOverview />
          <BookingProfile />
          <BookingLinks />
          <BookingAnalytics />
          <BookingSettings />
          <BookingPayments />
        </HashTabs>
      </AppLayout>
    </NoInternetConnection>
  );
}
