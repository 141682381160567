import { Text } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import React from 'react';
import { App_OccupancyAnalytics } from '../../../../../../graphql/generatedTypes';
import * as SC from '../TotalAppointments/styles';

type OccupancyStatusProps = {
  occupancy: App_OccupancyAnalytics;
};

const OccupancyStatus = ({ occupancy }: OccupancyStatusProps) => (
  <SC.TotalStatus>
    <div>
      <Text size="base" weight="normal" colorType="base" mb="small">
        <IntlLabel label="analytics.workingHours.title" />
      </Text>
      <Text size="base" weight="normal" colorType="base" mb="small">
        <IntlLabel label="analytics.bookedHours.title" />
      </Text>
      <Text size="base" weight="normal" colorType="base" mb="small">
        <IntlLabel label="analytics.blockedHours.title" />
      </Text>
      <Text size="base" weight="normal" colorType="base" mb="small">
        <IntlLabel label="analytics.unbookedHours.title" />
      </Text>
    </div>
    <SC.NumbersColumn>
      <SC.Inliner>
        <Text size="base" weight="semiBold" colorType="base" mb="small">
          {occupancy.workingHoursCount}h
        </Text>
      </SC.Inliner>
      <SC.Inliner>
        <Text size="base" weight="semiBold" colorType="base" mb="small">
          {occupancy.bookedHoursCount}h
        </Text>
        <Text size="base" weight="normal" colorType="base" mb="small" ml="xxsmall">
          ({occupancy?.bookedHoursPercent}%)
        </Text>
      </SC.Inliner>
      <SC.Inliner>
        <Text size="base" weight="semiBold" colorType="base" mb="small">
          {occupancy.blockedHoursCount}h
        </Text>
        <Text size="base" weight="normal" colorType="base" mb="small" ml="xxsmall">
          ({occupancy?.blockedHoursPercent}%)
        </Text>
      </SC.Inliner>
      <SC.Inliner>
        <Text size="base" weight="semiBold" colorType="base" mb="small">
          {occupancy.unbookedHoursCount}h
        </Text>
        <Text size="base" weight="normal" colorType="base" mb="small" ml="xxsmall">
          ({occupancy?.unbookedHoursPercent}%)
        </Text>
      </SC.Inliner>
    </SC.NumbersColumn>
  </SC.TotalStatus>
);

export default OccupancyStatus;
