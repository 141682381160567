import { Divider } from 'antd';
import React from 'react';
import { IntlLabel } from '@plandok/i18n';
import { colorsPalette } from './config';
import * as SC from './style';

type SelectColorProps = {
  value: string;
  label: string;
  onChange: (value: string | null) => void;
};

export default function SelectColor({ value, label, onChange }: SelectColorProps) {
  return (
    <SC.Container>
      <SC.Label>
        <div data-item-label-text>
          <IntlLabel label={label} />
        </div>
        {value && (
          <div data-item-label-selected>
            <SC.Circle isSmall color={value} />
            <IntlLabel label="button.selected" />
          </div>
        )}
      </SC.Label>
      <Divider className="mt-1 mb-sm" />
      <SC.Body>
        {colorsPalette.map((color) => {
          const isSelected = color === value;
          const selectItem = () => onChange(isSelected ? null : color);

          return (
            <SC.SelectCircle color={color} isSelected={isSelected} onClick={selectItem} key={color}>
              <div data-select-circle-container>
                <SC.Circle color={color} />
              </div>
            </SC.SelectCircle>
          );
        })}
      </SC.Body>
    </SC.Container>
  );
}
