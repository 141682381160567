import { FieldRenderProps } from 'react-final-form';
import * as SC from './styles';
import withFormBlock from '../FormBlock/withFormBlock';
import React from 'react';

interface FormInputProps extends FieldRenderProps<any, any> {
  submitting?: boolean;
}

const PreviewArea = ({ input }: FormInputProps) => {
  return <>{input.value && <SC.Preview>{`${input.value}`}</SC.Preview>}</>;
};

export default withFormBlock<FormInputProps>(PreviewArea);
