import getISODay from 'date-fns/getISODay';
import { StateCalendar } from 'pages/dashboard/CalendarPage/constants';
import theme from '../../styles/theme';
import { getDayTime } from './date';
import { getWholeHourOptions } from '../../../pages/dashboard/ModifyAppointmentPage/config/options';

export const getCurrentLineTopOffsetByViewType = (
  date: Date,
  hourHeight: number,
  customViewType: string,
  calendarStartTime: number,
  workingHoursStartTime: number,
  timeFormat: string
) => {
  const time = getDayTime(date);
  const fullCalendar = theme.calendarHeaderHeight + (time / 60) * hourHeight;

  switch (customViewType) {
    case StateCalendar.CUSTOM_RANGE:
      const skipCustomHours = getWholeHourOptions(timeFormat).findIndex(({ code }) => code === calendarStartTime);

      return fullCalendar - skipCustomHours * hourHeight;
    case StateCalendar.WORKING_HOUR:
      const skipWorkingHours = getWholeHourOptions(timeFormat).findIndex(({ code }) => code === workingHoursStartTime);

      return fullCalendar - skipWorkingHours * hourHeight;
    case StateCalendar.FULL_CALENDAR:
      return fullCalendar;
    default:
      return fullCalendar;
  }
};

export const isDisplayTimeIndicatorByViewType = (
  endTime: number,
  startTime: number,
  hourHeight: number,
  customViewType: string,
  top: number,
  workingHoursStartTime: number,
  workingHoursEndTime: number
) => {
  switch (customViewType) {
    case StateCalendar.CUSTOM_RANGE:
      return top <= ((endTime - startTime) / 60) * hourHeight;
    case StateCalendar.WORKING_HOUR:
      return top <= ((workingHoursEndTime - workingHoursStartTime) / 60) * hourHeight;
    case StateCalendar.FULL_CALENDAR:
      return true;
    default:
      return true;
  }
};

export const getLeftOffset = (isWeek: boolean) => (isWeek ? getISODay(new Date()) - 1 : 0);
