import { mediaSmDown } from '../../../../../helpers/styles';
import styled, { css } from 'styled-components';

export const SearchDiv = styled.div`
  margin-bottom: 20px;
  input {
    border-radius: 0px !important;
    border: none;
    background-color: whitesmoke;
    top: 10px;
    left: 10px;
    padding-left: 5px;
    height: 35px;
  }
  .ant-input-suffix {
    top: 80% !important;
    right: 0px !important;
  }
  ${mediaSmDown(css`
    input {
      border-bottom: 1px solid #adb6cc;
      background-color: white;
      &:hover {
        border-bottom: 1px solid #6de1f7 !important;
      }
    }
  `)}
`;
