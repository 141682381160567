import React from 'react';
import { CustomIcon } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { LogType, NotificationType } from '../types';

export const getLogType = (type: LogType) => {
  switch (type) {
    case LogType.CREATED:
      return 'appointment.log.created.label';
    case LogType.UPDATED:
      return 'appointment.log.updated.label';
    case LogType.RESCHEDULED:
      return 'appointment.log.rescheduled.label';
    case LogType.CANCELLED:
      return 'appointment.log.cancelled.label';
    case LogType.CHANGED_STATUS:
      return 'appointment.log.changed.label';
  }
};

export const getNotificationType = (type: NotificationType) => {
  switch (type) {
    case NotificationType.REMINDER:
      return 'appointment.notificationLog.reminder';
    case NotificationType.CONFIRMATION:
      return 'appointment.notificationLog.confirmation';
    case NotificationType.RESCHEDULING:
      return 'appointment.notificationLog.rescheduling';
    case NotificationType.CANCELLATION:
      return 'appointment.notificationLog.cancellation';
    case NotificationType.THANK_YOU:
      return 'appointment.notificationLog.thankYou';
    case NotificationType.LAST_VISIT:
      return 'appointment.notificationLog.lastVisit';
  }
};

export const Status = ({ status }: { status: string }) => {
  switch (status) {
    case 'new':
      return (
        <span style={{ color: '#4A9CFC' }}>
          <IntlLabel label="appointment.status.new" />
        </span>
      );
    case 'cancelled':
      return (
        <span style={{ color: '#ADB6CC' }}>
          <IntlLabel label="appointment.status.cancelled" />
        </span>
      );
    case 'confirmed':
      return (
        <>
          <span style={{ color: '#29C0CC' }}>
            <IntlLabel label="appointment.status.confirmed" />
          </span>
          <span style={{ marginLeft: '6px' }}>
            <CustomIcon type="confirmed" />
          </span>
        </>
      );
    case 'completed':
      return (
        <>
          <span style={{ color: '#97D561' }}>
            <IntlLabel label="appointment.status.completed" />
          </span>
          <span style={{ marginLeft: '6px' }}>
            <CustomIcon type="completed" />
          </span>
        </>
      );
    case 'no_show':
      return (
        <>
          <span style={{ color: '#FC6793' }}>
            <IntlLabel label="appointment.status.notShow" />
          </span>
          <span style={{ marginLeft: '6px' }}>
            <CustomIcon type="noShow" />
          </span>
        </>
      );
    default:
      return <IntlLabel label="appointment.status.btn" />;
  }
};
