import React, { useContext } from 'react';
import { CustomIcon, Text, useLocation, withModal } from '@plandok/core';
import { getLabel, IntlLabel, ReactIntl } from '@plandok/i18n';
import { RoutePath } from 'constants/routes';
import * as SC from '../styles';

type LatestPaymentFailedModalProps = {
  modalProps: {
    initialValues: {
      planId: string;
      planType: string;
    };
  };
  onClose: () => void;
};

function LatestPaymentFailedModal({ modalProps, onClose }: LatestPaymentFailedModalProps) {
  const { lang } = useContext(ReactIntl);
  const { navigate } = useLocation();

  const goToAnotherPage = (path: string, id?: string) => {
    onClose();
    navigate(path, id);
  };

  const goToAddPlanPage = () =>
    goToAnotherPage(
      RoutePath.UPDATE_SUBSCRIPTION,
      `${modalProps.initialValues.planType}/${modalProps.initialValues.planId}`
    );
  const goToLocationsPage = () => goToAnotherPage(RoutePath.LOCATIONS);
  const goToStaffPage = () => goToAnotherPage(RoutePath.STAFF);

  return (
    <>
      <SC.LatestPaymentFailedTitle>
        <CustomIcon type="paymentError" />
        <Text
          colorType="base"
          mb="none"
          label="modal.latestPaymentFailed.title"
          weight="semiBold"
          size="llarge"
          lh="xxxlarge"
        />
      </SC.LatestPaymentFailedTitle>
      <SC.ErrorDescription>
        <Text colorType="base" mb="none" size="medium">
          <span>{getLabel('modal.latestPaymentFailed.text.1', lang)} </span>
          <SC.Link onClick={goToLocationsPage}>{getLabel('modal.entity.location', lang)}</SC.Link>
          <span> / </span>
          <SC.Link onClick={goToStaffPage}>{getLabel('sidebar.staff', lang)}</SC.Link>
          <span> {getLabel('modal.latestPaymentFailed.text.2', lang)}</span>
        </Text>
      </SC.ErrorDescription>
      <SC.BtnsContainer>
        <SC.BtnLink whiteBtn upperCase={false} onClick={goToLocationsPage}>
          <IntlLabel label="title.locations" />
          <CustomIcon type="arrowRight" />
        </SC.BtnLink>

        <SC.BtnLink whiteBtn upperCase={false} onClick={goToStaffPage}>
          <IntlLabel label="sidebar.staff" />
          <CustomIcon type="arrowRight" />
        </SC.BtnLink>

        <SC.BtnUpgrade label="btn.upgradeNow" upperCase={false} onClick={goToAddPlanPage} />
      </SC.BtnsContainer>
    </>
  );
}

export default withModal(null, { contentClassName: 'latest-payment-failed-modal' })(LatestPaymentFailedModal);
