import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Circle = styled.div<any>`
  background: ${(props) => props.color};
  height: ${(props) => (props.isSmall ? '20px' : '36px')};
  width: ${(props) => (props.isSmall ? '20px' : '36px')};
  border-radius: 50%;
`;

export const SelectCircle = styled.div<any>`
  position: relative;
  margin-bottom: 5px;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
  [data-select-circle-container] {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: ${(props) => (props.isSelected ? `2px solid ${props.color}` : 'none')};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${Circle} {
    background: ${(props) => props.color};
  }
`;

export const Label = styled.div<any>`
  display: flex;
  font-size: 14px;
  [data-item-label-text] {
    margin-right: 13px;
    color: ${(props) => props.theme.primaryColor};
  }
  [data-item-label-selected] {
    font-size: 14px;
    color: #adb6cc;
    display: flex;
    align-items: center;
    ${Circle} {
      margin-right: 10px;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${SelectCircle} {
    margin-right: 5px;
  }
`;
