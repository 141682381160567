import { CustomIcon } from '@plandok/core';
import { IntlDate, IntlLabel } from '@plandok/i18n';
import { subDays } from 'date-fns';
import React from 'react';

import * as SC from './styles';

type DatePickerCustomInputProps = {
  isTimeRangeSelected: boolean;
  isPremium: boolean;
  selectedRangeLabel: string;
  startDate: Date | null;
  endDate: Date | null;
  isRounded?: boolean;
};

export const DatePickerCustomInput = ({
  isTimeRangeSelected,
  isPremium,
  selectedRangeLabel,
  startDate,
  endDate,
  isRounded = false,
}: DatePickerCustomInputProps) => (
  <SC.DateRangeButton ghost isRounded={isRounded}>
    <SC.DateRange>
      {isTimeRangeSelected && !startDate && !endDate && <IntlLabel label={selectedRangeLabel} />}
      {!isTimeRangeSelected && !startDate && !endDate && <IntlLabel label="analytics.timeRange.last7days" />}
      {!isTimeRangeSelected && isPremium && startDate && !endDate && (
        <IntlDate date={subDays(new Date(), 60)} dateFormat="dd MMM, yyyy - " />
      )}
      {!isTimeRangeSelected && isPremium && startDate && !endDate && (
        <IntlDate date={new Date()} dateFormat="dd MMM, yyyy" />
      )}
      {startDate && <IntlDate date={startDate} dateFormat="dd MMM, yyyy - " />}
      {endDate && <IntlDate date={endDate} dateFormat="dd MMM, yyyy" />}
    </SC.DateRange>
    <CustomIcon type="datePickerCalendar" />
  </SC.DateRangeButton>
);

export default DatePickerCustomInput;
