import React from 'react';

import { useState } from 'react';
import styled from 'styled-components';
import { IntlLabel } from '@plandok/i18n';
import { Icon } from 'antd';
import { LabelKey } from '@plandok/i18n';

const SPlaceholderItem = styled.div`
  width: 532px;
  display: flex;
  padding: 20px 10px 20px 20px;
  align-items: center;
  box-shadow: 0 3px 6px rgba(173, 182, 204, 0.35);
  background: white;
  border-radius: 20px;
  position: relative;
`;

const SPlaceholderContainer = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 2;
  padding-top: 20px;
  margin-right: 20px;
  h2 {
    color: #283140;
    font-size: 18px;
  }
  p {
    color: #61749d;
    margin-bottom: 14px;
  }
`;

const SPlaceholderImage = styled.div`
  max-width: 262px;
  text-align: center;
  width: 100%;

  img {
    max-width: 262px;
    max-height: 160px;
  }
`;

export const CloseIcon = styled.div`
  font-size: 17px;
  color: #adb6cc;
  position: absolute;
  right: 20px;
  top: 16px;
  cursor: pointer;
`;

export default function InfoPlaceholder(props: { imgName: string; titleLabel: LabelKey; descriptionLabel: LabelKey }) {
  const [val, refresh] = useState(0);
  const onRefresh = () => refresh(val + 1);
  const storageKeyName = `info_item#${props.imgName}`;
  if (localStorage?.getItem(storageKeyName)) {
    return null;
  }

  const onClose = () => {
    localStorage.setItem(storageKeyName, 'true');
    onRefresh();
  };

  return (
    <SPlaceholderItem>
      <CloseIcon onClick={onClose}>
        <Icon type="close-circle" />
      </CloseIcon>
      <SPlaceholderContainer>
        <h2>
          <IntlLabel label={props.titleLabel} />
        </h2>
        <p>
          <IntlLabel label={props.descriptionLabel} />
        </p>
      </SPlaceholderContainer>
      <SPlaceholderImage>
        <img src={`/img/illustrations/${props.imgName}.svg`} alt="placeholder" />
      </SPlaceholderImage>
    </SPlaceholderItem>
  );
}
