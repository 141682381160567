import { useEffect, useRef, useState } from 'react';
import { withIntlLabel } from '@plandok/i18n';
import * as SC from './styles';
import React from 'react';

function SearchInput({ value, setValue, size = 'default', placeholder, isSpacing, ...props }: any) {
  const [width, setWidth] = useState(0);
  const containerRef = useRef(null);
  useEffect(() => {
    setWidth((containerRef.current || { offsetWidth: 0 }).offsetWidth);
  }, [value]);

  const onChange = ({ target: { value: val } }: any) => setValue(val);

  return (
    <>
      <SC.Container size={size} isSpacing={isSpacing}>
        <SC.Input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...props}
          data-search-clients-input
        />
        <SC.Line style={{ width: `${width}px` }} />
      </SC.Container>
      <SC.HiddenText ref={containerRef}>{value}</SC.HiddenText>
    </>
  );
}

export default withIntlLabel({ propKey: 'placeholder', defaultProp: 'input.searchClients.placeholder' })(SearchInput);
