import React from 'react';
import { IntlLabel } from '@plandok/i18n';
import { MenuItem } from 'components';
import { ButtonProps } from './SupportButton';
import SidebarIcons from './SidebarIcons';

export default function UpdateButton({
  collapsed,
  isFixed,
  setIsVisibleUpdateBtn,
  setIsVisibleUpdateNotification,
  ...props
}: ButtonProps) {
  const showUpdateNotification = () => {
    setIsVisibleUpdateBtn?.(false);
    setIsVisibleUpdateNotification?.(true);
    localStorage.setItem('isHiddenNewVersionNotification1', 'false');
  };

  return (
    <MenuItem
      {...props}
      style={{
        position: isFixed ? 'fixed' : 'inherit',
        width: isFixed ? '111px' : '108px',
        bottom: '80px',
      }}
      title={<IntlLabel label="app.update.button.title" />}
      siderCollapsed={collapsed}
      onClick={showUpdateNotification}
    >
      <div data-sidebar-update-icon>
        <i>{SidebarIcons.update}</i>
        <span>
          <IntlLabel label="app.update.button.title" />
        </span>
      </div>
    </MenuItem>
  );
}
