import { StateCalendar } from 'pages/dashboard/CalendarPage/constants';

import {
  AppEarliestAppointmentSetting,
  AppLatestAppointmentSetting,
  AppTimeSlotIntervalSetting,
  AppAppointmentChangeTimeSetting,
  AppRepeatFrequencyIntervals,
  AppRepeatEndsAfterIntervals,
} from '../../graphql';
import { PermissionType } from '../auth';

export const notificationType = [
  { code: 'none', name: 'dropdown.notificationBy.none' },
  { code: 'sms', name: 'dropdown.notificationBy.sms' },
  { code: 'email', name: 'dropdown.notificationBy.email' },
  { code: 'sms_and_email', name: 'dropdown.notificationBy.combined' },
];

export const notificationTypeWithoutSms = notificationType.filter(({ code }) => code !== 'sms');

export const calendarTimeOffset = [
  { code: 5, name: '5 {dropdown.time.minutes}' },
  { code: 10, name: '10 {dropdown.time.minutes}' },
  { code: 15, name: '15 {dropdown.time.minutes}' },
  { code: 30, name: '30 {dropdown.time.minutes}' },
];

export const reminderTimeOffset = [
  { code: 2, name: '2 {dropdown.time.hours}' },
  { code: 3, name: '3 {dropdown.time.hours}' },
  { code: 6, name: '6 {dropdown.time.hours}' },
  { code: 12, name: '12 {dropdown.time.hours}' },
  { code: 24, name: '24 {dropdown.time.hours}' },
  { code: 48, name: '48 {dropdown.time.hours}' },
  { code: 72, name: '72 {dropdown.time.hours}' },
  { code: 96, name: '96 {dropdown.time.hours}' },
  { code: 120, name: '120 {dropdown.time.hours}' },
  { code: 144, name: '144 {dropdown.time.hours}' },
  { code: 168, name: '168 {dropdown.time.hours}' },
  { code: 336, name: '336 {dropdown.time.hours}' },
];

export const reminderLastVisitTimeOffset = [
  { code: 45 * 24, name: '45 {dropdown.time.days}' },
  { code: 60 * 24, name: '60 {dropdown.time.days}' },
  { code: 90 * 24, name: '90 {dropdown.time.days}' },
  { code: 180 * 24, name: '180 {dropdown.time.days}' },
  { code: 360 * 24, name: '360 {dropdown.time.days}' },
];

export const viewTypeOptions = [
  {
    code: 'day',
    name: 'calendar.day',
    icon: 'day',
  },
  {
    code: 'week',
    name: 'calendar.week',
    icon: 'week',
  },
];

export const calendarTimeRangeOptions = [
  {
    code: StateCalendar.FULL_CALENDAR,
    name: 'input.calendarTimeRange.allTimes.option',
  },
  {
    code: StateCalendar.WORKING_HOUR,
    name: 'input.calendarTimeRange.onlyWorkingHours.option',
  },
  {
    code: StateCalendar.CUSTOM_RANGE,
    name: 'input.calendarTimeRange.customRange.option',
  },
];

export const clientOptions = [
  {
    code: 'export',
    name: 'client.options.export.title',
    value: {
      title: 'client.options.export.title',
      description: 'client.options.export.description',
      icon: 'star',
      hasPopover: true,
    },
    permission: PermissionType.CAN_SEE_CLIENT_INFO,
  },
  {
    code: 'import',
    name: 'client.options.import.title',
    value: {
      title: 'client.options.import.title',
    },
  },
];

export const addedClientViewTypeOptions = [
  {
    code: 'days',
    name: 'days',
    icon: 'day',
  },
  {
    code: 'days',
    name: 'days',
    icon: 'day',
  },
];

export const permissionOptions = [
  { code: 'owner', name: 'dropdown.access.owner' },
  { code: 'no_access', name: 'dropdown.access.no' },
  { code: 'high', name: 'dropdown.access.high' },
  { code: 'medium', name: 'dropdown.access.medium' },
  { code: 'low', name: 'dropdown.access.low' },
  { code: 'basic', name: 'dropdown.access.basic' },
];

export const appointmentColorOptions = [
  { code: 'by_employee', name: 'input.colorBy.employee' },
  { code: 'by_service_group', name: 'input.colorBy.service' },
  { code: 'by_appointment_status', name: 'input.colorBy.appointment' },
];

export const earliestAppointmentSettingOptions = [
  { code: AppEarliestAppointmentSetting.Immediately, name: 'dropdown.time.appointment.immediately' },
  { code: AppEarliestAppointmentSetting.UpTo_15Minutes, name: 'dropdown.time.appointment.upTo15Min' },
  { code: AppEarliestAppointmentSetting.UpTo_30Minutes, name: 'dropdown.time.appointment.upTo30Min' },
  { code: AppEarliestAppointmentSetting.UpTo_1Hour, name: 'dropdown.time.appointment.upTo1Hour' },
  { code: AppEarliestAppointmentSetting.UpTo_2Hours, name: 'dropdown.time.appointment.upTo2Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_3Hours, name: 'dropdown.time.appointment.upTo3Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_4Hours, name: 'dropdown.time.appointment.upTo4Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_5Hours, name: 'dropdown.time.appointment.upTo5Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_6Hours, name: 'dropdown.time.appointment.upTo6Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_7Hours, name: 'dropdown.time.appointment.upTo7Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_8Hours, name: 'dropdown.time.appointment.upTo8Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_9Hours, name: 'dropdown.time.appointment.upTo9Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_10Hours, name: 'dropdown.time.appointment.upTo10Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_11Hours, name: 'dropdown.time.appointment.upTo11Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_12Hours, name: 'dropdown.time.appointment.upTo12Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_13Hours, name: 'dropdown.time.appointment.upTo13Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_14Hours, name: 'dropdown.time.appointment.upTo14Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_15Hours, name: 'dropdown.time.appointment.upTo15Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_16Hours, name: 'dropdown.time.appointment.upTo16Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_17Hours, name: 'dropdown.time.appointment.upTo17Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_18Hours, name: 'dropdown.time.appointment.upTo18Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_19Hours, name: 'dropdown.time.appointment.upTo19Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_20Hours, name: 'dropdown.time.appointment.upTo20Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_21Hours, name: 'dropdown.time.appointment.upTo21Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_22Hours, name: 'dropdown.time.appointment.upTo22Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_23Hours, name: 'dropdown.time.appointment.upTo23Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_24Hours, name: 'dropdown.time.appointment.upTo24Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_2Days, name: 'dropdown.time.appointment.upTo48Hours' },
  { code: AppEarliestAppointmentSetting.UpTo_3Days, name: 'dropdown.time.appointment.upTo3days' },
  { code: AppEarliestAppointmentSetting.UpTo_4Days, name: 'dropdown.time.appointment.upTo4days' },
  { code: AppEarliestAppointmentSetting.UpTo_5Days, name: 'dropdown.time.appointment.upTo5days' },
  { code: AppEarliestAppointmentSetting.UpTo_6Days, name: 'dropdown.time.appointment.upTo6days' },
  { code: AppEarliestAppointmentSetting.UpTo_1Week, name: 'dropdown.time.appointment.upTo1week' },
  { code: AppEarliestAppointmentSetting.UpTo_2Week, name: 'dropdown.time.appointment.upTo2weeks' },
];

export const latestAppointmentSettingOptions = [
  { code: AppLatestAppointmentSetting.UpTo_1Month, name: 'dropdown.time.appointment.upTo1Month' },
  { code: AppLatestAppointmentSetting.UpTo_2Months, name: 'dropdown.time.appointment.upTo2Months' },
  { code: AppLatestAppointmentSetting.UpTo_3Months, name: 'dropdown.time.appointment.upTo3Months' },
  { code: AppLatestAppointmentSetting.UpTo_4Months, name: 'dropdown.time.appointment.upTo4Months' },
  { code: AppLatestAppointmentSetting.UpTo_5Months, name: 'dropdown.time.appointment.upTo5Months' },
  { code: AppLatestAppointmentSetting.UpTo_6Months, name: 'dropdown.time.appointment.upTo6Months' },
];

export const timeSlotIntervalSettingOptions = [
  { code: AppTimeSlotIntervalSetting.Interval_5Minutes, name: 'dropdown.time.appointment.interval5Min' },
  { code: AppTimeSlotIntervalSetting.Interval_10Minutes, name: 'dropdown.time.appointment.interval10Min' },
  { code: AppTimeSlotIntervalSetting.Interval_15Minutes, name: 'dropdown.time.appointment.interval15Min' },
  { code: AppTimeSlotIntervalSetting.Interval_20Minutes, name: 'dropdown.time.appointment.interval20Min' },
  { code: AppTimeSlotIntervalSetting.Interval_30Minutes, name: 'dropdown.time.appointment.interval30Min' },
  { code: AppTimeSlotIntervalSetting.Interval_40Minutes, name: 'dropdown.time.appointment.interval40Min' },
  { code: AppTimeSlotIntervalSetting.Interval_45Minutes, name: 'dropdown.time.appointment.interval45Min' },
  { code: AppTimeSlotIntervalSetting.Interval_50Minutes, name: 'dropdown.time.appointment.interval50Min' },
  { code: AppTimeSlotIntervalSetting.Interval_60Minutes, name: 'dropdown.time.appointment.interval60Min' },
  { code: AppTimeSlotIntervalSetting.Interval_75Minutes, name: 'dropdown.time.appointment.interval75Min' },
  { code: AppTimeSlotIntervalSetting.Interval_90Minutes, name: 'dropdown.time.appointment.interval90Min' },
  { code: AppTimeSlotIntervalSetting.Interval_120Minutes, name: 'dropdown.time.appointment.interval120Min' },
  { code: AppTimeSlotIntervalSetting.Interval_150Minutes, name: 'dropdown.time.appointment.interval150Min' },
  { code: AppTimeSlotIntervalSetting.Interval_180Minutes, name: 'dropdown.time.appointment.interval180Min' },
  { code: AppTimeSlotIntervalSetting.Interval_240Minutes, name: 'dropdown.time.appointment.interval240Min' },
];

export const appointmentChangeTimeSettingOptions = [
  { code: AppAppointmentChangeTimeSetting.AnyTime, name: 'dropdown.time.appointment.anyTime' },
  { code: AppAppointmentChangeTimeSetting.Disabled, name: 'dropdown.time.appointment.disabled' },
  { code: AppAppointmentChangeTimeSetting.UpTo_30Minutes, name: 'dropdown.time.appointment.upTo30Min' },
  { code: AppAppointmentChangeTimeSetting.UpTo_1Hour, name: 'dropdown.time.appointment.upTo1Hour' },
  { code: AppAppointmentChangeTimeSetting.UpTo_2Hours, name: 'dropdown.time.appointment.upTo2Hours' },
  { code: AppAppointmentChangeTimeSetting.UpTo_3Hours, name: 'dropdown.time.appointment.upTo3Hours' },
  { code: AppAppointmentChangeTimeSetting.UpTo_4Hours, name: 'dropdown.time.appointment.upTo4Hours' },
  { code: AppAppointmentChangeTimeSetting.UpTo_5Hours, name: 'dropdown.time.appointment.upTo5Hours' },
  { code: AppAppointmentChangeTimeSetting.UpTo_6Hours, name: 'dropdown.time.appointment.upTo6Hours' },
  { code: AppAppointmentChangeTimeSetting.UpTo_12Hours, name: 'dropdown.time.appointment.upTo12Hours' },
  { code: AppAppointmentChangeTimeSetting.UpTo_24Hours, name: 'dropdown.time.appointment.upTo24Hours' },
  { code: AppAppointmentChangeTimeSetting.UpTo_48Hours, name: 'dropdown.time.appointment.upTo48Hours' },
];

export const appointmentBookingsLimitOptions = [
  { code: 0, name: 'ob.bookingLimit.unlimited.title' },
  { code: 1, name: 'ob.bookingLimit.1service.title' },
  { code: 2, name: 'ob.bookingLimit.2services.title' },
  { code: 3, name: 'ob.bookingLimit.3services.title' },
];

export const repeatFrequencyIntervals = [
  { code: AppRepeatFrequencyIntervals.DOESNT_REPEAT, name: 'dropdown.time.appointment.doesntRepeat' },
  { code: AppRepeatFrequencyIntervals.DAILY, name: 'dropdown.time.appointment.daily' },
  { code: AppRepeatFrequencyIntervals.TWO_DAYS, name: 'dropdown.time.appointment.every2Days' },
  { code: AppRepeatFrequencyIntervals.THREE_DAYS, name: 'dropdown.time.appointment.every3Days' },
  { code: AppRepeatFrequencyIntervals.FOUR_DAYS, name: 'dropdown.time.appointment.every4Days' },
  { code: AppRepeatFrequencyIntervals.FIVE_DAYS, name: 'dropdown.time.appointment.every5Days' },
  { code: AppRepeatFrequencyIntervals.SIX_DAYS, name: 'dropdown.time.appointment.every6Days' },
  { code: AppRepeatFrequencyIntervals.WEEK, name: 'dropdown.time.appointment.every7Days' },
  { code: AppRepeatFrequencyIntervals.TWO_WEEKS, name: 'dropdown.time.appointment.every2weeks' },
  { code: AppRepeatFrequencyIntervals.THREE_WEEKS, name: 'dropdown.time.appointment.every3weeks' },
  { code: AppRepeatFrequencyIntervals.FOUR_WEEKS, name: 'dropdown.time.appointment.every4weeks' },
  { code: AppRepeatFrequencyIntervals.FIVE_WEEKS, name: 'dropdown.time.appointment.every5weeks' },
  { code: AppRepeatFrequencyIntervals.SIX_WEEKS, name: 'dropdown.time.appointment.every6weeks' },
  { code: AppRepeatFrequencyIntervals.SEVEN_WEEKS, name: 'dropdown.time.appointment.every7weeks' },
  { code: AppRepeatFrequencyIntervals.EIGHT_WEEKS, name: 'dropdown.time.appointment.every8weeks' },
  { code: AppRepeatFrequencyIntervals.NINE_WEEKS, name: 'dropdown.time.appointment.every9weeks' },
  { code: AppRepeatFrequencyIntervals.TEN_WEEKS, name: 'dropdown.time.appointment.every10weeks' },
  { code: AppRepeatFrequencyIntervals.MONTH, name: 'dropdown.time.appointment.monthly' },
  { code: AppRepeatFrequencyIntervals.TWO_MONTHS, name: 'dropdown.time.appointment.every2months' },
  { code: AppRepeatFrequencyIntervals.THREE_MONTHS, name: 'dropdown.time.appointment.every3months' },
  { code: AppRepeatFrequencyIntervals.FOUR_MONTHS, name: 'dropdown.time.appointment.every4months' },
  { code: AppRepeatFrequencyIntervals.FIVE_MONTHS, name: 'dropdown.time.appointment.every5months' },
  { code: AppRepeatFrequencyIntervals.SIX_MONTHS, name: 'dropdown.time.appointment.every6months' },
  { code: AppRepeatFrequencyIntervals.SEVEN_MONTHS, name: 'dropdown.time.appointment.every7months' },
  { code: AppRepeatFrequencyIntervals.EIGHT_MONTHS, name: 'dropdown.time.appointment.every8months' },
  { code: AppRepeatFrequencyIntervals.NINE_MONTHS, name: 'dropdown.time.appointment.every9months' },
  { code: AppRepeatFrequencyIntervals.TEN_MONTHS, name: 'dropdown.time.appointment.every10months' },
  { code: AppRepeatFrequencyIntervals.ELEVEN_MONTHS, name: 'dropdown.time.appointment.every11months' },
];

export const repeatEndsAfterIntervals = [
  { code: AppRepeatEndsAfterIntervals.AFTER_2_TIMES, name: 'dropdown.time.appointment.after2times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_3_TIMES, name: 'dropdown.time.appointment.after3times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_4_TIMES, name: 'dropdown.time.appointment.after4times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_5_TIMES, name: 'dropdown.time.appointment.after5times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_6_TIMES, name: 'dropdown.time.appointment.after6times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_7_TIMES, name: 'dropdown.time.appointment.after7times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_8_TIMES, name: 'dropdown.time.appointment.after8times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_9_TIMES, name: 'dropdown.time.appointment.after9times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_10_TIMES, name: 'dropdown.time.appointment.after10times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_11_TIMES, name: 'dropdown.time.appointment.after11times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_12_TIMES, name: 'dropdown.time.appointment.after12times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_13_TIMES, name: 'dropdown.time.appointment.after13times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_14_TIMES, name: 'dropdown.time.appointment.after14times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_15_TIMES, name: 'dropdown.time.appointment.after15times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_20_TIMES, name: 'dropdown.time.appointment.after20times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_25_TIMES, name: 'dropdown.time.appointment.after25times' },
  { code: AppRepeatEndsAfterIntervals.AFTER_30_TIMES, name: 'dropdown.time.appointment.after30times' },
  { code: AppRepeatEndsAfterIntervals.UNTIL_DATE, name: 'dropdown.time.appointment.specificDate' },
];

export const repeatFrequencyNumbers = [
  { code: AppRepeatFrequencyIntervals.DOESNT_REPEAT, value: 0 },
  { code: AppRepeatFrequencyIntervals.DAILY, value: 1 },
  { code: AppRepeatFrequencyIntervals.TWO_DAYS, value: 2 },
  { code: AppRepeatFrequencyIntervals.THREE_DAYS, value: 3 },
  { code: AppRepeatFrequencyIntervals.FOUR_DAYS, value: 4 },
  { code: AppRepeatFrequencyIntervals.FIVE_DAYS, value: 5 },
  { code: AppRepeatFrequencyIntervals.SIX_DAYS, value: 6 },
  { code: AppRepeatFrequencyIntervals.WEEK, value: 7 },
  { code: AppRepeatFrequencyIntervals.TWO_WEEKS, value: 14 },
  { code: AppRepeatFrequencyIntervals.THREE_WEEKS, value: 21 },
  { code: AppRepeatFrequencyIntervals.FOUR_WEEKS, value: 28 },
  { code: AppRepeatFrequencyIntervals.FIVE_WEEKS, value: 35 },
  { code: AppRepeatFrequencyIntervals.SIX_WEEKS, value: 42 },
  { code: AppRepeatFrequencyIntervals.SEVEN_WEEKS, value: 49 },
  { code: AppRepeatFrequencyIntervals.EIGHT_WEEKS, value: 56 },
  { code: AppRepeatFrequencyIntervals.NINE_WEEKS, value: 63 },
  { code: AppRepeatFrequencyIntervals.TEN_WEEKS, value: 70 },
  { code: AppRepeatFrequencyIntervals.MONTH, value: 30 },
  { code: AppRepeatFrequencyIntervals.TWO_MONTHS, value: 61 },
  { code: AppRepeatFrequencyIntervals.THREE_MONTHS, value: 91 },
  { code: AppRepeatFrequencyIntervals.FOUR_MONTHS, value: 122 },
  { code: AppRepeatFrequencyIntervals.FIVE_MONTHS, value: 152 },
  { code: AppRepeatFrequencyIntervals.SIX_MONTHS, value: 182 },
  { code: AppRepeatFrequencyIntervals.SEVEN_MONTHS, value: 213 },
  { code: AppRepeatFrequencyIntervals.EIGHT_MONTHS, value: 243 },
  { code: AppRepeatFrequencyIntervals.NINE_MONTHS, value: 274 },
  { code: AppRepeatFrequencyIntervals.TEN_MONTHS, value: 304 },
  { code: AppRepeatFrequencyIntervals.ELEVEN_MONTHS, value: 335 },
];
