import React, { ReactNode } from 'react';
import { Button, CustomIcon, Text } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import * as SC from './styles';

type DatePickerContainerContentProps = {
  isMobile: boolean;
  isPremium: boolean;
  isOpenTimeRange: boolean;
  timeRangeOptions: JSX.Element[];
  children: ReactNode[];
  handleUpgradeAction: () => void;
  handleSkipAction: () => void;
  handleToggleAction: () => void;
};

export const DatePickerContainerContent = ({
  isMobile,
  isPremium,
  isOpenTimeRange,
  timeRangeOptions,
  children,
  handleUpgradeAction,
  handleSkipAction,
  handleToggleAction,
}: DatePickerContainerContentProps) => (
  <>
    <SC.TimeRangeBlock>
      {isMobile ? (
        <span onClick={handleToggleAction}>
          <CustomIcon type="hamburger" />
        </span>
      ) : (
        <IntlLabel label="analytics.timeRange.label" />
      )}
      {isMobile && isOpenTimeRange && <SC.DatePickerBtnContainer>{timeRangeOptions}</SC.DatePickerBtnContainer>}
      {!isMobile && <SC.DatePickerBtnContainer>{timeRangeOptions}</SC.DatePickerBtnContainer>}
    </SC.TimeRangeBlock>
    <SC.DatePickerContainer>
      {isPremium && children}
      {!isPremium && (
        <SC.UpgradeToPremium>
          <Text size="small" weight="semiBold" colorType="base" mb="small">
            <IntlLabel label="analytics.premium.info.title" />
          </Text>
          <SC.UpgradeButton
            type="default"
            label="analytics.premium.upgradeButton.title"
            onClick={handleUpgradeAction}
          />
          <Button ghost bold label="analytics.premium.skipButton.title" onClick={handleSkipAction} />
        </SC.UpgradeToPremium>
      )}
    </SC.DatePickerContainer>
  </>
);

export default DatePickerContainerContent;
