import React from 'react';
import {
  Button,
  Card,
  Field,
  Form,
  Text,
  countryOptions,
  currencyOptions,
  timeFormatOptions,
  timeZoneOptions,
  validateLength,
} from '@plandok/core';
import * as SC from '../styles';

export default function CompanyDetailsForm(props: any) {
  const INPUT_CHARS_LIMIT = 512;

  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={props.initialValues}
      className="w-100"
      onSuccess={props.onSuccess}
      combinedFields={['phoneNumber']}
    >
      {({ submitting, formError }: any) => (
        <>
          <Card>
            <Text label="company.details.title" bold />
            <Field.Input name="name" label="input.businessName.label" placeholder="input.businessName.placeholder" />
            <Field.TextArea
              name="description"
              label="input.description.label"
              placeholder="input.description.placeholder"
              rows="4"
              validate={validateLength(INPUT_CHARS_LIMIT)}
            />
            <Field.Input name="address" label="input.address.label" placeholder="input.address.placeholder" />
            <Field.Input name="website" label="input.website.label" placeholder="input.website.placeholder" />
            <Field.CombinedInput
              name="phoneNumber"
              label="input.contactNumber.label"
              placeholder="input.contactNumber.placeholder"
            />
            <Field.Row>
              <Field.Select
                name="timeZone"
                label="input.timeZone.label"
                placeholder="input.timeZone.placeholder"
                options={timeZoneOptions}
                md={12}
                span={24}
              />
              <Field.Select
                name="timeFormat"
                label="input.timeFormat.label"
                placeholder="input.timeFormat.placeholder"
                options={timeFormatOptions}
                md={11}
                span={24}
              />
            </Field.Row>
            <Field.Row>
              <Field.Select
                name="countryIsoCode"
                label="input.country.label"
                placeholder="input.country.placeholder"
                options={countryOptions}
                md={12}
                span={24}
              />
              <Field.Select
                name="currencyIsoCode"
                label="input.currency.label"
                placeholder="input.currency.placeholder"
                options={currencyOptions}
                md={11}
                span={24}
              />
            </Field.Row>
          </Card>
          <Form.Alert text={formError} />
          <SC.BtnContainer>
            <Button label="btn.save.changes" type="primary" block htmlType="submit" loading={submitting} />
          </SC.BtnContainer>
        </>
      )}
    </Form>
  );
}
