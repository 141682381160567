/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
  'login.title': 'Acesse sua conta',
  'label.email': 'E-mail',
  'placeholder.email': 'ex: joao@gmail.com',
  'input.password.label': 'Senha',
  'input.password.placeholder': 'Digite sua senha',
  'login.btn': 'Login em Plandock',
  'link.sign.in': 'Login',
  'label.password': 'Digite sua senha',
  'login.forgot.password': 'Esqueceu sua senha?',
  'login.no.account': 'Não possui conta?',
  'login.description': 'Se está com problema em acessar sua conta, tente redefinir sua senha',
  'register.title': 'Criar conta',
  'register.description': 'Crie sua conta gratuita no Plandok, software de agendamento e marcação de horários. ',
  'register.btn': 'Criar minha conta',
  'register.agreement.agree': 'Concordo com',
  'register.agreement.terms': 'Temos do site',
  'register.agreement.policy': 'Política de Privacidade',
  'register.agreement.and': 'e',
  'register.agreement.partner': 'Termos de Negócios de Parceiros',
  'register.have.account': 'Já possui uma conta?',
  'register.validation.confirm': 'Antes de continuar, por favor leia e aceite os termos acima.',
  'link.sign.up': 'Registrar',
  'text.alreadyHaveAccount': 'Já possui uma conta?',
  'input.company.label': 'Empresa',
  'input.company.placeholder': 'Empresa',
  'input.country.label': 'País',
  'input.country.placeholder': 'Nome do país',
  'input.timeZone.label': 'Fuso horário',
  'input.timeZone.placeholder': 'GMT +2.00',
  'input.currency.label': 'Moeda',
  'input.language.label': 'Idioma (Language)',
  'input.language.placeholder': 'Inglês',
  'input.currency.placeholder': 'Euro - EUR',
  'reset.title': 'Redefinir Senha',
  'reset.btn.text': 'Enviar redefinição de senha',
  'reset.pwd.btn': 'Redefinir Senha',
  'reset.no.password': 'Não quer redefinir sua senha?',
  'reset.description': 'Digite seu e-mail cadastrado para receber um link seguro e redefinir sua senha',
  'sidebar.calendar': 'Calendário',
  'sidebar.client': 'Clientes',
  'sidebar.staff': 'Funcionários',
  'sidebar.service': 'Serviços',
  'sidebar.notification': 'Mensagens',
  'sidebar.settings': 'Configurações',
  'sidebar.menu': 'Menu',
  'sidebar.analytics': 'Analítica',
  'sidebar.language': 'Idioma (Language)',
  'sidebar.logout': 'Sair',
  'user.menu.settings': 'Minhas configurações',
  'user.menu.language': 'Mudar idioma',
  'user.menu.logout': 'Sair',
  'input.globalSearch.placeholder': 'O que você está procurando?',
  'input.globalSearch.description': 'Procurar por nome do cliente, telefone, e-mail ou referência de agendamento',
  'title.upcomingAppointments': 'Compromissos Futuros',
  'calendar.day': 'Dia',
  'calendar.week': 'Semana',
  'calendar.monday': 'Segunda-feira',
  'calendar.tuesday': 'Terça-feira',
  'calendar.wednesday': 'Quarta-feira',
  'calendar.thursday': 'Quinta-feira',
  'calendar.friday': 'Sexta-feira',
  'calendar.saturday': 'Sábado',
  'calendar.sunday': 'Domingo',
  'calendar.mon': 'Seg',
  'calendar.tue': 'Ter',
  'calendar.wed': 'Quar',
  'calendar.thu': 'Quin',
  'calendar.fri': 'Sex',
  'calendar.sat': 'Sab',
  'calendar.sun': 'Dom',
  'calendar.today': 'Hoje',
  'calendar.january': 'Janeiro',
  'calendar.february': 'Fevereiro',
  'calendar.march': 'Março',
  'calendar.april': 'Abril',
  'calendar.may': 'Maio',
  'calendar.june': 'Junho',
  'calendar.july': 'Julho',
  'calendar.august': 'Agosto',
  'calendar.september': 'Setembro',
  'calendar.october': 'Outubro',
  'calendar.november': 'Novembro',
  'calendar.december': 'Dezembro',
  'calendar.jan': 'Jan',
  'calendar.feb': 'Fev',
  'calendar.mar': 'Mar',
  'calendar.apr': 'Abr',
  'calendar.may.short': 'Maio',
  'calendar.jun': 'Jun',
  'calendar.jul': 'Jul',
  'calendar.aug': 'Ago',
  'calendar.sept': 'Set',
  'calendar.oct': 'Out',
  'calendar.nov': 'Nov',
  'calendar.dec': 'Dez',
  'calendar.allStaff': 'Todos',
  'calendar.workingStaff': 'Disponível',
  'calendar.tooltip.time': 'Tempo',
  'calendar.tooltip.category': 'Categoria',
  'calendar.tooltip.service': 'Serviço',
  'calendar.tooltip.staff': 'Funcionários',
  'calendar.tooltip.price': 'Preço',
  'calendar.tooltip.repeating': 'Recorrente',
  'btn.viewDetails': 'Ver detalhes',
  'calendar.tooltip.emptyCustomer': 'Ordem de chegada',
  'calendar.tooltip.bookedOnline': 'Reservar on-line',
  'calendar.print.description': 'Para usar a função de impressão, seu plano deve ser atualizado. Para atualizar visite',
  'calendar.print.appointmentsBetween.title': 'Compromissos entre',
  'calendar.print.appointmentsAnd.title': 'e',
  'calendar.billing.title': 'Plano e faturamento',
  'calendar.btn.print.title': 'Imprimir',
  'datepicker.next.week': 'Semana que vem',
  'datepicker.in.two.weeks': 'Em 2 semanas',
  'datepicker.in.three.weeks': 'Em 3 semanas',
  'datepicker.in.four.weeks': 'Em 4 semanas',
  'datepicker.in.five.weeks': 'Em 5 semanas',
  'datepicker.in.six.weeks': 'Em 6 semanas',
  'modal.splitAppointment.title': 'Dividir Agendamento',
  'modal.splitAppointment.text':
    'Este agendamento é parte de um grupo, ao movê-lo para outro dia, o grupo será dividido e ele se tornará um agendamento separado. Tem certeza que desejar continuar?',
  'blocktime.create.modal.title': 'Bloquear Horário',
  'blocktime.edit.modal.title': 'Editar Bloqueio de Horário',
  'appointment.create.menu': 'Agendamentos',
  'blocktime.create.menu': 'Bloquear Horário',
  'appointment.new.title': 'Novo Agendamento',
  'input.startTime.label': 'Horário Inicial',
  'input.endTime.label': 'Horário FInal',
  'input.startDate.label': 'Data de início',
  'input.endDate.label': 'Data final',
  'input.startDate.description.label': 'Selecione a data de início',
  'input.endDate.description.label': 'Selecione a data final',
  'input.closedDatedDescription.placeholder': 'Por exemplo: Feriados',
  'input.service.label': 'Serviços',
  'input.services.label': 'serviços',
  'input.service.placeholder': 'Escolher um Serviço',
  'input.staff.label': 'Funcionários',
  'input.staff.placeholder': 'Selecionar funcionário',
  'input.pickDateAndTime.title': 'Escolher data e hora',
  'input.pickDate.title': 'Escolha uma data',
  'service.add.another': 'Adicionar outro serviço',
  'input.appointmentNotes.label': 'Observações dos agendamentos',
  'input.emailSubject.placeholder': 'Observações dos agendamentos',
  'input.appointmentNotes.placeholder': 'Adicionar observações (visível somente para funcionários)',
  'input.searchClients.placeholder': 'Procurar cliente',
  'input.searchService.placeholder': 'Procurar',
  'appointment.empty.list':
    'Use a pesquisa para adicionar um cliente ou mantenha vazio para salvar como ordem de chegada.',
  'appointment.clients.empty.list': 'A lista de clientes está vazia.',
  'appointment.clients.addClient': 'Criar cliente',
  'client.create.new': 'Criar novo cliente',
  'client.choose': 'Escolher cliente',
  'client.menu.edit': 'Editar informações do cliente',
  'client.menu.remove': 'Remover do agendamento',
  'appointment.btn.save': 'Salvar',
  'appointment.vat': 'IVA ({0}%)',
  'appointment.total': 'Total',
  'appointment.view.title': 'Ver agendamento',
  'appointment.status.btn': 'Status do agendamento',
  'appointment.btn.cancel': 'Excluir',
  'btn.cancelAppointment': 'Excluir',
  'modal.cancelAppointment.title': 'Excluir',
  'title.clients': 'Clientes',
  'client.create.title': 'Novo cliente',
  'client.options.export.title': 'Exportar clientes',
  'client.options.export.description': 'Baixe CSV com dados do cliente',
  'client.options.import.title': 'Importar clientes',
  'client.export.premium.description': 'Para usar a função Exportar, seu plano deve ser atualizado.',
  'client.export.premium.visit': 'Para atualizar visite ',
  'client.import.instructions.description': 'Siga as instruções abaixo para importar clientes para sua conta Plandok',
  'client.import.instructions.download': 'Baixe seu arquivo de importação usando o botão abaixo',
  'client.import.instructions.copy': 'Copie e cole os dados do seu cliente no arquivo',
  'client.import.instructions.email': 'Envie-nos por e-mail para info@plandok.com',
  'client.import.instructions.account': 'e importaremos para sua conta',
  'table.clients.name': 'Nome',
  'table.clients.phone': 'Telefone',
  'table.clients.mobile': 'Telefone',
  'table.clients.email': 'E-mail',
  'table.clients.gender': 'Gênero',
  'client.page.title': 'Perfil do cliente',
  'btn.more.options': 'Mais opções',
  'btn.showAll': 'Mostrar tudo',
  'btn.collapse': 'Colapso',
  'btn.reschedule': 'Reprogramar',
  'title.edit.client': 'Editar cliente',
  'menu.edit.client': 'Editar cliente',
  'menu.delete.client': 'Deletar cliente',
  'title.mobilePhone': 'Celular',
  'title.telephoneNumber': 'Telefone',
  'title.email': 'E-mail',
  'title.address': 'Endereço',
  'title.dateOfBirth': 'Data de nascimento',
  'title.gender': 'Sexo',
  'client.statistics': 'Estatística',
  'client.totalSales': 'Total de Vendas',
  'client.totalBookings': 'Total de Marcações',
  'client.outstanding': 'Excepcional',
  'client.allBooking': 'Marcações completas',
  'client.completed': 'Concluído',
  'client.cancelled': 'Cancelado',
  'client.noShow': 'Ausência',
  'appointment.list.title': 'Agendamentos',
  'upcoming.list.title': 'Próximos',
  'past.list.title': 'Anteriores',
  'modal.confirmClientDelete.text.0': 'Tem certeza que deseja deletar este cliente permanentemene?',
  'modal.confirmClientDelete.text.1': 'Do banco de dados?',
  'input.date.label': 'Data específica',
  'input.date.placeholder': 'Selecionar data',
  'input.firstName.label': 'Nome',
  'input.firstName.placeholder': 'ex. Joao',
  'input.lastName.label': 'Sobrenome',
  'input.lastName.placeholder': 'ex. Silva',
  'input.mobile.label': 'Celular',
  'input.mobile.placeholder': 'Digite o número',
  'input.email.label': 'E-mail',
  'input.email.placeholder': 'ex. joao@gmail.com',
  'input.title.label': 'Título',
  'input.title.placeholder': 'por exemplo. Dentista',
  'input.notificationType.label': 'Enviar notificação por',
  'tab.personalInfo': 'Informações pessoais',
  'input.gender.label': 'Sexo',
  'input.referal.label': 'Referência',
  'input.referal.placeholder': 'Selecione a fonte',
  'input.birthDay.label': 'Data de nascimento',
  'input.month.placeholder': 'Mês',
  'input.day.placeholder': 'Dia',
  'input.year.placeholder': 'Ano',
  'input.clientsNotes.label': 'Observações do cliente',
  'input.clientsNotes.placeholder': 'Digite as observações do cliente',
  'input.bookingDisplay.label': 'Exibir todos os agendamentos',
  'input.serviceDescription.label': 'Descrição do serviço',
  'input.serviceDescription.placeholder':
    'Adicionar uma breve descrição que ficará visível para os clientes com reservas online',
  'input.isBookableOnline.label': 'Disponível para reservas on-line',
  'service.onlineBookings.title': 'Reservas on-line',
  'tab.address': 'Endereço',
  'input.address.label': 'Endereço',
  'input.address.placeholder': 'Digite o endereço',
  'input.city.label': 'Cidade',
  'input.city.placeholder': 'Digite a cidade',
  'input.zip.label': 'CEP / Código Postal',
  'input.zip.placeholder': 'Digite o CEP',
  'btn.edit': 'Editar',
  'btn.cancel': 'Cancelar',
  'btn.save': 'Salvar',
  'btn.save.changes': 'Salvar',
  'tabs.staffWorking.title': 'Horas trabalhadas',
  'tabs.staffMembers.title': 'Equipe',
  'tabs.userPermissions.title': 'Permissão de Usuário',
  'tabs.closedDates.title': 'Datas fechadas',
  'btn.new.staff': 'Funionário Novo',
  'table.staff.name': 'Nome',
  'table.staff.mobileNumber': 'Telefone',
  'table.staff.email': 'E-mail',
  'table.staff.userPermission': 'Permissão de Usuário',
  'table.staff.dataRange': 'Intervalo de datas',
  'table.staff.numberOfDays': 'Número de dias',
  'table.staff.location': 'Localização',
  'table.staff.description': 'Descrição',
  'table.appointmentsList.appointment': 'Encontro',
  'table.appointmentsList.client': 'Cliente',
  'table.appointmentsList.service': 'Serviço',
  'table.appointmentsList.date': 'Data',
  'table.appointmentsList.time': 'Tempo',
  'table.appointmentsList.duration': 'Duração',
  'table.appointmentsList.location': 'Localização',
  'table.appointmentsList.staff': 'Funcionários',
  'table.appointmentsList.price': 'Preço',
  'table.appointmentsList.status': 'Status',
  'table.appointmentsCancellations.ref': 'Referência',
  'table.appointmentsCancellations.client': 'Cliente',
  'table.appointmentsCancellations.service': 'Serviço',
  'table.appointmentsCancellations.scheduledDate': 'Data marcada',
  'table.appointmentsCancellations.cancelledDate': 'Data de cancelamento',
  'table.appointmentsCancellations.cancelledBy': 'Cancelado por',
  'table.appointmentsCancellations.reason': 'Razão',
  'table.appointmentsCancellations.price': 'Preço',
  'table.appointmentsCancellations.totalCount': 'Contagem total',
  'table.clients.appointments': 'Compromissos',
  'table.clients.noShows': 'Sem apresentações',
  'table.clients.totalSales': 'Vendas totais',
  'table.clients.added': 'Adicionado',
  'table.clients.daysAbsent': 'Dias ausentes',
  'table.clients.lastAppointment': 'Última consulta',
  'table.clients.lastLocation': 'Última localização',
  'table.appointmentsByService.serviceName': 'Nome do Serviço',
  'table.appointmentsByService.appointments': 'Compromissos',
  'table.appointmentsByService.totalValue': 'Valor total',
  'table.appointmentsByStaff.staffMember': 'Membro da equipe',
  'table.appointmentsByStaff.appointments': 'Compromissos',
  'table.appointmentsByStaff.totalValue': 'Valor total',
  'table.pagination.showing.title': 'Mostrando',
  'table.pagination.results.title': 'resultados',
  'clients.duplicate.title': 'Clientes duplicados encontrados.',
  'clients.duplicate.description': 'Existem clientes semelhantes que podem ser mesclados.',
  'clients.merge.btn.title': 'Mesclar',
  'clients.merge.modal.title': 'Mesclar clientes',
  'clients.merge.selectAll.title': 'Selecione todas as duplicatas',
  'clients.merge.duplicatesNotFound.title': 'Duplicações não encontradas',
  'clients.merge.duplicatesNotFound.description': 'Não há clientes disponíveis para fundir',
  'clients.mergeConfirm.modal.title': 'Confirmar mesclagem',
  'clients.mergeConfirm.modal.description':
    'Tem certeza de que deseja mesclar {0} duplicatas? Esta ação é permanente e não pode ser desfeita.',
  'clients.mergeConfirm.checkbox.title': 'Entendo que a fusão do cliente não pode ser desfeita',
  'clients.mergeConfirm.btn.title': 'Confirmar e mesclar',
  'clients.mergeConfirm.cancel.btn.title': 'Cancelar',
  'clients.byNumber.btn.title': 'Por telefone {0}',
  'clients.byEmail.btn.title': 'Por e-mail {0}',
  'clients.byName.btn.title': 'Por nome {0}',
  'title.newStaff': 'Funionário Novo',
  'title.editStaff': 'Editar Funcionário',
  'description.extraCharge': 'A adição de novos funcionários implica um custo adicional à sua subscrição mensal',
  'tabs.details': 'Informações',
  'input.staffPicture.label': 'Foto',
  'input.cropImage.title': 'Cortar imagem',
  'input.cropImage.btn.apply.title': 'Aplicar',
  'input.stafffirstName.label': 'Nome',
  'input.stafffirstName.placeholder': 'ex: Joao',
  'input.stafflastName.label': 'Sobrenome',
  'input.stafflastName.placeholder': 'ex. Silva',
  'input.stafmobile.label': 'Telefone',
  'input.stafemail.label': 'E-mail',
  'input.stafemail.placeholder': 'ex. joao@gmail.com',
  'input.permission.label': 'Permissão de Usuário',
  'input.uploadProfile.label': 'Upload da foto do perfil',
  'input.changeProfile.label': 'Alterar foto do perfil',
  'input.notes.label': 'Obervações',
  'input.staffNotes.placeholder': 'Adicionar observações visíveis apenas para funcionários (opcional)',
  'input.blockTime.placeholder': 'ex. almoço de negócios',
  'tabs.locations': 'Locais',
  'tabs.integrations': 'Integrações',
  'input.staffLocations.description': 'Atribuir locais em que este membro da equipe pode ter agendamentos.',
  'input.multiLocation.description': 'Atribuir locais em que este membro da equipe pode ter agendamentos.',
  'tabs.services': 'Serviços',
  'input.staffServices.description': 'Atribua funções para os membro de sua equipe.',
  'input.multiServices.description': 'Atribua funções para os membro de sua equipe.',
  'input.shiftStart.label': 'Início de turno',
  'input.shiftEnd.label': 'Final de turno',
  'edit.staff.title.resendPassword': 'Reenvio de senha',
  'edit.staff.info.resendPassword':
    'O membro da equipe não recebeu um e-mail com senha? Clique no botão abaixo para reenviar e-mail com instruções de configuração de senha.',
  'edit.staff.button.resendPassword': 'Reenviar e-mail de senha',
  'btn.add.shift': 'Adicionar novo turno',
  'break.time': '{0} Intervalo',
  'input.repeat.label': 'Repetir',
  'input.repeatWeekly.label': 'Semanal',
  'input.repeatDoNot.label': 'Não repetir',
  'input.endRepeat.label': 'Repetição terminada',
  'input.endRepeatOngoing.label': 'Em andamento',
  'input.endRepeatSpecificDate.label': 'Data Específica',
  'input.endRepeatDate.placeholder': 'Escolha a Data',
  'btn.deleteUpcomingShift': 'Excluir próximos turnos',
  'btn.deleteThisShift': 'Excluir apenas este turno',
  'btn.updateUpcomingShift': 'Atualizar turnos futuros',
  'btn.updateThisShift': 'Atualizar apenas este turno',
  'modal.repeatShift.title': 'Repetir Turno ',
  'form.error.uniqShifts': 'Os turnos devem ser únicos',
  'btn.add.new': 'Adicionar novo',
  'btn.addNewService': 'Adicionar novo serviço',
  'btn.newCategory': 'Nova Categoria',
  'btn.newServiceGroup': 'Nova Categoria',
  'btn.editCategory': 'Editar categoria',
  'btn.deleteCategory': 'Deletar categoria',
  'btn.downloadImportFile': 'Baixar arquivo de importação',
  'serviceGroup.delete.success': 'Categoria excluída com sucesso!',
  'service.new.title': 'Novo Serviço',
  'service.edit.title': 'Editar Serviço',
  'input.serviceName.label': 'Nome do serviço',
  'input.serviceName.placeholder': 'ex. Massagem',
  'input.serviceCategory.label': 'Categoria de Serviços',
  'input.serviceCategory.placeholder': 'Selecionar categoria de serviço',
  'input.price.label': 'Preço',
  'input.price.placeholder': '0',
  'input.duration.label': 'Duração',
  'input.duration.placeholder': 'Duração',
  'input.staffSelect.description': 'Selecione os funcionários que realizam este serviço',
  'input.newCategoryName.label': 'Nome da Categoria',
  'input.newCategoryDescription.label': 'Descrição da categoria',
  'input.newCategoryDescription.placeholder': 'Descreva esta categoria, ela será mostrada em Reservas Online',
  'modal.newCategory.title': 'Nova Categoria',
  'modal.editCategory.title': 'Editar Categoria',
  'input.chooseCategory.label': 'Escolha a cor da Categoria',
  'client.messages.title': 'Mensagens do cliente',
  'tabs.messagesLog': 'Área de mensagem',
  'tabs.messagesLog.description': 'Lista de mensagens enviadas para seus clientes',
  'tabs.messagesSettings': 'Configurações',
  'tabs.messagesSettings.description':
    'Ajuste suas configurações de mensagem para atender às suas necessidades de negócios',
  'table.header.timeSent': 'Enviar',
  'table.header.client': 'Clientes',
  'table.header.appointment': 'Agendamento',
  'table.header.destination': 'Endereço',
  'table.header.type': 'Tipo',
  'table.header.message': 'Mensagem',
  'table.header.status': 'Status',
  'table.header.cost': 'Custo',
  'modal.message.title': 'Ver Mensagens',
  'setup.page.title': 'Configurações',
  'title.accountSettings': 'Configurações da conta',
  'title.accountSetup': 'Configurações da conta',
  'title.accountSetup.description': 'Gerenciar configurações como o nome da empresa e o fuso horário',
  'resources.list.title': 'Recursos',
  'title.resources': 'Recursos',
  'title.resources.description': 'Definir locais para agendamento, como salas, cadeiras ou equipamentos',
  'title.locations': 'Localizações',
  'title.locations.description': 'Gerenciar vários canais para sua empresa',
  'calendar.settings.title': 'Configuração do calendário',
  'calendar.btn.selectFromCalendar': 'Seleccione a partir do calendário',
  'title.calendarSettings': 'Configuração do calendário',
  'title.calendarSettings.description': 'Ajuste o esquema de cores e layout do seu calendário',
  'title.clientSettings': 'Configuração do cliente',
  'title.onlineBookingSettings': 'Definições de reservas online',
  'title.salesSettings': 'Opções de vendas',
  'title.receiptSequencing': 'Sequência de recibos',
  'title.receiptSequencing.description':
    'Configurar os detalhes apresentados nos recibos de venda emitidos aos seus clientes',
  'title.receiptTemplate': 'Modelo de recibo',
  'title.taxes.description': 'Gerir as taxas de imposto que se aplicam aos artigos vendidos no checkout',
  'title.paymentTypes': 'Tipos de pagamento',
  'title.paymentTypes.description': 'Configure tipos de pagamento manual para utilizar durante a finalização da compra',
  'title.discounts.description': 'Configurar os seus descontos',
  'title.receiptPrefix': 'Recibo №. Prefixo',
  'title.receiptNumber': 'Número do recibo seguinte',
  'title.change': 'Alterar',
  'title.saleReceiptTemplate': 'Modelo de recibo de venda',
  'description.saleReceiptTemplate':
    'Personalize o conteúdo apresentado nos recibos de venda emitidos aos seus clientes',
  'setting.automaticallyPrint': 'Imprimir automaticamente os recibos após a conclusão da venda',
  'setting.showMobile': 'Mostrar o telemóvel e o e-mail do cliente no recibo de venda',
  'setting.showAddress': 'Mostrar o endereço do cliente no recibo de venda',
  'title.receiptTitle': 'Título do recibo',
  'title.referrals': 'Fontes de referência',
  'title.referrals.description': 'Configurar fontes personalizadas para rastrear como o cliente descobriu seu negócio',
  'title.clientNotifications': 'Configurações de Notificação do Cliente',
  'title.clientNotifications.description': 'Gerenciar mensagens enviadas aos clientes sobre seus agendamentos',
  'cancelReason.list.title': 'Motivo do cancelamento',
  'title.cancellationReasons': 'Motivo do cancelamento',
  'title.cancellationReasons.description': 'Rastrear o motivo pelo qual o cliente não compareceu ',
  'title.onlineBooking.description': 'Gerir a disponibilidade e as definições das reservas online das excursões',
  'title.pointOfSale': 'Pontos de venda',
  'discount.list.title': 'Descontos',
  'title.discountTypes': 'Tipos de desconto',
  'title.discountTypes.description': 'Configurar descontos manuais para serem usados no checkout',
  'title.premiumFeature': 'Recurso Premium',
  'company.details.title': 'Detalhes da Empresa',
  'input.businessName.label': 'Nome da empresa',
  'input.businessName.placeholder': 'ex. Eaglewings',
  'input.description.label': 'Descrição',
  'input.description.placeholder': 'Digite as informações',
  'input.businessAddress.label': 'Endereço',
  'input.businessAddress.placeholder': 'Digite o endereço',
  'input.website.label': 'Site',
  'input.website.placeholder': 'Digite o nome do site',
  'input.contactNumber.label': 'Número de contato',
  'input.contactNumber.placeholder': 'Digite o número',
  'input.businessTimeZone.label': 'Fuso horário',
  'input.businessTimeZone.placeholder': 'GMT +02.00',
  'input.businessCountry.label': 'País',
  'input.businessCountry.placeholder': 'Escolha o país',
  'input.timeFormat.label': 'Formato da hora',
  'input.timeFormat.placeholder': '24 horas',
  'table.header.resourceName': 'Recursos',
  'btn.new.resource': 'Novos Recursos',
  'input.resourceName.label': 'Nome do Recurso',
  'input.resourceName.placeholder': 'Digite o nome',
  'input.resourceDescription.label': 'Descrições',
  'input.resourceDescription.placeholder': 'Digite as descrições',
  'input.resourceLocation.placeholder': 'Nome do local',
  'resourcesTooltip.location': 'Descrição do local para o recurso',
  'modal.newResource.title': 'Criar novo Recurso',
  'modal.editResource.title': 'Editar Recurso',
  'table.header.locationName': 'Local',
  'table.header.locatonAddress': 'Endereço',
  'table.header.location.Phone': 'Telefone',
  'modal.newLocation.title': 'Nova Localização',
  'modal.editLocation.title': 'Editar localização',
  'btn.new.location': 'Nova Localização',
  'btn.addExtraLocation': 'Adicionar localização extra',
  'input.locationName.label': 'Nome do Local',
  'input.locationName.placeholder': 'Digite o nome do Local',
  'input.profile.locationName.placeholder': 'Por exemplo: Salão de Cabeleireiro',
  'input.slug.label': 'Slug',
  'input.locationTips.label': 'Dicas de localização',
  'input.slug.explanation': 'Será visível no seu local de URL',
  'input.contactEmail.label': 'E-mail de Contato',
  'input.locationAddress.label': 'Endereço',
  'input.locationAddress.placeholder': 'Digite o endereço do local',
  'tooltip.contactEmail': 'Responda aos agendamentos de clientes as notificações serão enviadas para este e-mail.',
  'input.appointmentColors.label': 'Cor de Agendamento',
  'input.colorBy.service': 'Por tipos de serviços',
  'input.colorBy.employee': 'Por funcionário',
  'input.colorBy.appointment': 'Por status de agendamento',
  'input.timeSlot.label': 'Intervalo de tempo',
  'input.defaultViewType.label': 'Visualização padrão',
  'input.calendarTimeRange.label': 'Intervalo de tempo do calendário',
  'input.calendarTimeRange.allTimes.option': 'Todas as horas (00:00 - 24:00)',
  'input.calendarTimeRange.onlyWorkingHours.option': 'Apenas horas de trabalho',
  'input.calendarTimeRange.customRange.option': 'Intervalo personalizado',
  'input.weekStart.label': 'Dia que se inicia a semana',
  'table.header.refSourcetitle': 'Fonte de referência',
  'table.header.refSourceAddDate': 'Adicionar Data',
  'btn.new.referral': 'Nova Fonte de Referência',
  'input.nameRefSource.label': 'Nome da Fonte de Referência',
  'input.nameRefSource.placeholder': 'por exemplo. Anúncios no Facebook',
  'modal.newRefSource.title': 'Nova Fonte de Referência',
  'modal.editRefSource.title': 'Editar a Fonte de Referência',
  'input.sendBy.label': 'Enviado por',
  'input.emailSubject.label': 'Assunto do email',
  'input.emailTemplate.label': 'modelo de email',
  'input.emailTemplate.placeholder': 'Digite o modelo de email',
  'input.subject.label': 'Assunto do email',
  'input.subject.placeholder': 'Digite o assunto do email',
  'title.messagingSettings.label': 'Configurações  das mensagens',
  'title.messagingSettings.description':
    'Configure aqui os tipos de mensagens que serão enviadas automaticamente aos clientes. Todas as mensagens geradas podem ser acessadas na página de mensagens (no menu principal). Configure como e quando as mensagens são enviadas e edite os modelos para personalizar o texto. Use as tags abaixo para incluir nas mensagens os detalhes do agendamento:',
  'client.first.name': 'CLIENT_FIRST_NAME',
  'client.last.name': 'CLIENT_LAST_NAME',
  'staff.first.name': 'STAFF_FIRST_NAME',
  'staff.last.name': 'STAFF_LAST_NAME',
  'booking.date.time': 'BOOKING_DATE_TIME',
  'booking.date': 'BOOKING_DATE',
  'booking.time': 'BOOKING_TIME',
  'service.name': 'SERVICE_NAME',
  'business.name': 'BUSINESS_NAME',
  'business.phone': 'BUSINESS_PHONE',
  'business.address': 'BUSINESS_ADDRESS',
  'business.email': 'BUSINESS_EMAIL',
  'location.name': 'LOCATION_NAME',
  'location.phone': 'LOCATION_PHONE',
  'location.address': 'LOCATION_ADDRESS',
  'location.email': 'LOCATION_EMAIL',
  'tabs.reminders.title': 'Lembretes',
  'input.enableReminder.label': 'Ativar Lembretes',
  'input.enableReminder.description': 'Envio automático de aviso ao agendamento do cliente',
  'input.reminder.title': 'Lembrete {0}',
  'input.message.title': 'Mensagem {0}',
  'input.reminder.description': 'Canais e tempo de envio',
  'input.reminder.channels': 'Selecione os canais em que esta mensagem será enviada',
  'input.reminderBefore.label': 'Lembretes serão enviados antes de',
  'input.smsSender.label': 'ID Remetente do SMS',
  'input.messageSender.label': 'ID do remetente da mensagem',
  'input.smsSender.placeholder': 'Digite ID Remetente do SMS',
  'input.smsTemplate.label': 'Modelo de SMS',
  'input.messageTemplate.label': 'Modelo de mensagem',
  'input.smsTemplate.placeholder': 'Digite o modelo de SMS',
  'input.whatsAppSender.placeholder': 'Plandok',
  'reminder.whatsApp.notificate':
    'Atualmente, o modelo de mensagem do WhatsApp não é editável devido a limitações. No entanto, estamos a trabalhar ativamente para o tornar totalmente editável.',
  'reminder.charactersCount': 'Contagem de personagens:',
  'reminder.pricing': 'Preços',
  'modal.pricing.description':
    'Países diferentes têm taxas de preços diferentes. Fornecemos a melhor taxa possível dos nossos parceiros. Pode variar.',
  'modal.pricing.subDescription':
    'As taxas serão aplicadas assim que as mensagens forem enviadas e adicionadas à factura seguinte.',
  'modal.pricing.counterDescription':
    'Um SMS de texto normal está limitado a 160 caracteres e a 70 caracteres para mensagens com caracteres especiais.',
  'modal.pricing.learnMore': 'Saiba mais',
  'modal.pricing.priceRates': 'Taxas de preços',
  'modal.pricing.messagePrice': '1 preço de mensagem:',
  'modal.sendTestMessageByNumber.title': 'Enviar mensagem de teste para este número:',
  'modal.sendTestMessageByNumber.description': 'As taxas aplicam-se de acordo com a mensagem normal.',
  'modal.sendTestMessageByEmail.title': 'Enviar mensagem de teste para este email:',
  'modal.sendTestMessageByEmail.placeholder': 'Por favor introduza o endereço de correio electrónico',
  'btn.send': 'Enviar',
  'reminder.sendTestMessage': 'Enviar mensagem de teste',
  'button.reminder.addTag': 'Adicionar etiqueta',
  'button.reminder.addAnotherReminder': 'ADICIONAR OUTRO LEMBRETE',
  'button.message.addAnotherMessage': 'ADICIONAR OUTRA MENSAGEM',
  'button.reminder.upgrade': 'Atualize para acessar',
  'label.emailSettings': 'Configurações de Email',
  'label.SMSSettings': 'Configurações de SMS',
  'label.whatsAppSettings': 'Configurações de WhatsApp',
  'tooltip.sender.0': 'Será exibido como nome de remetente do SMS',
  'tooltip.sender.1': 'máximo de 11 caracteres',
  'tooltip.sender.2': 'Pode não funcionar em alguns países',
  'tooltip.sender.3': 'Apenas letras do alfabeto ou números',
  'tabs.confirmations.title': 'Confirmação',
  'btn.appBookings': 'Em App Bookings',
  'btn.onlineBookings': 'Reservas Online',
  'input.enableAppConfirmation.label': 'Habilitar em confirmações App Bookings',
  'input.enableOnlineConfirmation.label': 'Activar as confirmações de reservas online',
  'input.enableConfirmationApp.description':
    'Envia automaticamente aos clientes quando uma marcação é marcada para eles',
  'input.enableConfirmationOnline.description': 'Envia automaticamente aos clientes quando estes marcam uma consulta',
  'messageSetup.header': 'Configuração de mensagens',
  'channels.subHeader': 'Canais',
  'tabs.reschedules.title': 'Reagendar',
  'input.enableReschedule.label': 'Ativar Mensagens de reagendamento',
  'input.enableReschedule.description': 'Enviar automaticamente ao cliente quando um agendamento for feito para ele',
  'tabs.cancellations.title': 'Cancelamento',
  'input.enableCancellation.label': 'Ativar Mensagens de Cancelamento',
  'input.enableCancellations.description': 'Enviar automaticamente ao cliente quando seu agendamento for cancelado',
  'tabs.thanks.title': 'Obrigado',
  'input.enableThank.label': 'Ativar Mensagens de Agradecimento',
  'input.enableThankyou.description': 'Enviar mensagem ao cliente quando seu agendamento for marcado como completo',
  'tabs.lastVisit.title': 'Última visita',
  'input.enableLastVisit.label': 'Ativar Mensagens da Última Visita',
  'input.enableLastVisit.description':
    'Enviar automaticamente ao cliente quando ele não visitar seu negócio durante um número de dias indicado',
  'table.cancelReason.name': 'Motivos do Cancelamento',
  'table.cancelReason.addDate': 'Adicionar Data',
  'btn.new.cancelReason': 'Novo motivo de cancelamento',
  'modal.newCancelReason.title': 'Novo motivo de cancelamento',
  'modal.editCancelReason.title': 'Editar motivo do cancelamento',
  'input.cancelReason.title': 'Motivo do cancelamento',
  'input.cancelReason.placeholder': 'ex. Cliente indisponível',
  'table.discounts.name': 'Descontos',
  'table.discounts.value': 'Valor',
  'table.discounts.addDate': 'Data de Criação',
  'input.discountName.label': 'nome do Desconto',
  'input.discountName.placeholder': 'ex. Idosos',
  'input.discountValue.label': 'Valor do Desconto',
  'btn.new.discount': 'Novo Desconto',
  'input.discountPercentage.label': '% Porcentagem',
  'input.discountSum.label': 'Quantidade',
  'modal.newDiscount.title': 'Novo Tipo de Desconto',
  'modal.editDiscount.title': 'Editar Descontos',
  'modal.deleteAccount.title': 'Apagar conta',
  'modal.deleteAccount.description':
    'A eliminação da sua conta é um processo irreversível e todos os seus dados serão removidos para sempre.',
  'modal.deleteAccount.successMessage': 'A sua conta foi apagada com sucesso.',
  'title.mySettings': 'Minhas Configurações',
  'title.personalDetails': 'Detalhes pessoais',
  'title.personalDetails.description':
    'Definir seu nome e informações de contato, este e-mail será usado para acessar sua conta',
  'btn.continueWithGoogle.title': 'Continuar com o Google',
  'btn.continueWithFacebook.title': 'Continuar com o Facebook',
  'notification.googleAccountUnlinked': 'Conta Google desvinculada',
  'notification.googleAccountLinked': 'Conta Google associada',
  'notification.facebookAccountUnlinked': 'Conta do Facebook desvinculada',
  'notification.facebookAccountLinked': 'Conta do Facebook associada',
  'btn.linkAccount': 'Ligar conta',
  'btn.unlinkAccount': 'Desvincular conta',
  'btn.deleteYourAccount': 'Eliminar a sua conta',
  'input.myName.label': 'Nome',
  'input.myName.placeholder': 'ex. Joao',
  'input.mylastName.label': 'Sobrenome',
  'input.mylastName.placeholder': 'por exemplo. Doe',
  'title.changePassword': 'Trocar Senha',
  'title.changePassword.description':
    'Para fazer uma atualização, digite sua senha atual e, em seguida, sua nova senha. Se esqueceu sua senha, pode usar o link de redefinição de senha na página de Acesso.',
  'input.currentPassword.label': 'Senha Atual',
  'label.currentPassword.placeholder': 'Digitar Senha Atual',
  'input.newPassword.label': 'Nova senha',
  'input.newPassword.placeholder': 'Digite a Nova Senha',
  'input.verifyPassword.label': 'Repita a Senha',
  'input.verifyPassword.placeholder': 'Repita a Nova Senha',
  'btn.confirmDelete': 'Sim, Excluir agora',
  'btn.confirm': 'Confirme',
  'btn.confirmBook': 'Confirmar e reservar',
  'btn.confirmPay': 'Confirmar e pagar',
  'btn.delete': 'Excluir',
  'btn.close': 'Fechar',
  'validation.required': 'Campo obrigatório',
  'validation.email.format': 'Formato incorreto de email',
  'validation.url.format': 'Formato url incorrecto, deve começar com https:// ou http://',
  'validation.password.length': 'A senha deve conter pelo menos 7 caracteres',
  'validation.password.letters': 'A senha deve conter número e letra',
  'validation.startTime': 'A hora de início não pode ser posterior ou igual à hora de fim',
  'validation.endTime': 'A hora de fim não pode ser anterior ou igual à hora de início',
  'tooltip.password.title': 'Senha deve:',
  'tooltip.password.characters': 'Ter pelo menos 7 caracteres',
  'tooltip.password.number': 'Conter um número',
  'tooltip.password.letter': 'Conter uma letra',
  'tooltip.location': 'Descrição do local',
  'footer.support.center': 'Precisa de ajuda? Contate-nos',
  'not.found': 'Não encontrado',
  'input.cancelReason.label': 'Motivos do Cancelamento',
  'input.location.label': 'Local',
  'input.location.placeholder': 'Nome do local',
  'input.name.label': 'Nome',
  'title.recentlyAddedClients': 'Clientes (Adicionados Recentemente)',
  'title.calendarView': 'Ver Calendário',
  'text.manageProfile': 'Gerenciar seu perfil de usuário',
  'modal.language.title': 'Escolha seu idioma',
  'modal.language.placeholder': 'Pesquisa idioma',
  'dropdown.access.medium': 'Médio',
  'dropdown.access.high': 'Alto',
  'dropdown.access.low': 'Baixo',
  'dropdown.access.basic': 'Básico',
  'dropdown.access.no': 'Acesso Negado',
  'dropdown.access.owner': 'Proprietário',
  'button.select.all': 'Selecionar tudo',
  'button.selected': 'Selecionar',
  'dropdown.gender.unknown': 'Desconhecido',
  'dropdown.gender.male': 'Masculino',
  'dropdown.gender.female': 'Feminino ',
  'dropdown.notificationBy.none': 'Não enviar',
  'dropdown.notificationBy.sms': 'SMS',
  'dropdown.notificationBy.email': 'Email',
  'dropdown.notificationBy.combined': 'SMS e Email',
  'notification.success.login': 'Login efetuado com sucesso!',
  'notification.success.register': 'Registrado com sucesso',
  'dropdown.time.minutes': 'minutos',
  'dropdown.repeat.no': 'Não repetir',
  'dropdown.repeat.weekly': 'Semanal',
  'dropdown.endRepeat.ongoing': 'Em andamento',
  'dropdown.endRepeat.weekly': 'Data Específica',
  'dropdown.time.appointment.immediately': 'Imediatamente',
  'dropdown.time.appointment.upTo1Hour': 'Até 1 hora',
  'dropdown.time.appointment.upTo2Hours': 'Até 2 horas',
  'dropdown.time.appointment.upTo3Hours': 'Até 3 horas',
  'dropdown.time.appointment.upTo4Hours': 'Até 4 horas',
  'dropdown.time.appointment.upTo5Hours': 'Até 5 horas',
  'dropdown.time.appointment.upTo6Hours': 'Até 6 horas',
  'dropdown.time.appointment.upTo7Hours': 'Até 7 horas',
  'dropdown.time.appointment.upTo8Hours': 'Até 8 horas',
  'dropdown.time.appointment.upTo9Hours': 'Até 9 horas',
  'dropdown.time.appointment.upTo10Hours': 'Até 10 horas',
  'dropdown.time.appointment.upTo11Hours': 'Até 11 horas',
  'dropdown.time.appointment.upTo12Hours': 'Até 12 horas',
  'dropdown.time.appointment.upTo13Hours': 'Até 13 horas',
  'dropdown.time.appointment.upTo14Hours': 'Até 14 horas',
  'dropdown.time.appointment.upTo15Hours': 'Até 15 horas',
  'dropdown.time.appointment.upTo16Hours': 'Até 16 horas',
  'dropdown.time.appointment.upTo17Hours': 'Até 17 horas',
  'dropdown.time.appointment.upTo18Hours': 'Até 18 horas',
  'dropdown.time.appointment.upTo19Hours': 'Até 19 horas',
  'dropdown.time.appointment.upTo20Hours': 'Até 20 horas',
  'dropdown.time.appointment.upTo21Hours': 'Até 21 horas',
  'dropdown.time.appointment.upTo22Hours': 'Até 22 horas',
  'dropdown.time.appointment.upTo23Hours': 'Até 23 horas',
  'dropdown.time.appointment.upTo24Hours': 'Até 1 dia',
  'dropdown.time.appointment.upTo48Hours': 'Até 2 dias',
  'dropdown.time.appointment.upTo3days': 'Até 3 dias',
  'dropdown.time.appointment.upTo4days': 'Até 4 dias',
  'dropdown.time.appointment.upTo5days': 'Até 5 dias',
  'dropdown.time.appointment.upTo6days': 'Até 6 dias',
  'dropdown.time.appointment.upTo1week': 'Até 1 semana',
  'dropdown.time.appointment.upTo2weeks': 'Até 2 semanas',
  'dropdown.time.appointment.upTo15Min': 'Até 15 minutos',
  'dropdown.time.appointment.upTo30Min': 'Até 30 minutos',
  'dropdown.time.appointment.upTo1Month': 'Até 1 mês',
  'dropdown.time.appointment.upTo2Months': 'Até 2 meses',
  'dropdown.time.appointment.upTo3Months': 'Até 3 meses',
  'dropdown.time.appointment.upTo4Months': 'Até 4 meses',
  'dropdown.time.appointment.upTo5Months': 'Até 5 meses',
  'dropdown.time.appointment.upTo6Months': 'Até 6 meses',
  'dropdown.time.appointment.interval5Min': '5 minutos',
  'dropdown.time.appointment.interval10Min': '10 minutos',
  'dropdown.time.appointment.interval15Min': '15 minutos',
  'dropdown.time.appointment.interval20Min': '20 minutos',
  'dropdown.time.appointment.interval30Min': '30 minutos',
  'dropdown.time.appointment.interval40Min': '40 minutos',
  'dropdown.time.appointment.interval45Min': '45 minutos',
  'dropdown.time.appointment.interval50Min': '50 minutos',
  'dropdown.time.appointment.interval60Min': '60 minutos',
  'dropdown.time.appointment.interval75Min': '75 minutos',
  'dropdown.time.appointment.interval90Min': '90 minutos',
  'dropdown.time.appointment.interval120Min': '120 minutos',
  'dropdown.time.appointment.interval150Min': '150 minutos',
  'dropdown.time.appointment.interval180Min': '180 minutos',
  'dropdown.time.appointment.interval240Min': '240 minutos',
  'dropdown.time.appointment.anyTime': 'A qualquer momento',
  'dropdown.time.appointment.disabled': 'Desabilitado',
  'dropdown.time.appointment.doesntRepeat': 'Não se repete',
  'dropdown.time.appointment.daily': 'Diário',
  'dropdown.time.appointment.every2Days': 'A cada 2 dias',
  'dropdown.time.appointment.every3Days': 'A cada 3 dias',
  'dropdown.time.appointment.every4Days': 'A cada 4 dias',
  'dropdown.time.appointment.every5Days': 'A cada 5 dias',
  'dropdown.time.appointment.every6Days': 'A cada 6 dias',
  'dropdown.time.appointment.every7Days': 'A cada 7 dias',
  'dropdown.time.appointment.weekly': 'Semanalmente',
  'dropdown.time.appointment.every2weeks': 'A cada 2 semanas',
  'dropdown.time.appointment.every3weeks': 'A cada 3 semanas',
  'dropdown.time.appointment.every4weeks': 'A cada 4 semanas',
  'dropdown.time.appointment.every5weeks': 'A cada 5 semanas',
  'dropdown.time.appointment.every6weeks': 'A cada 6 semanas',
  'dropdown.time.appointment.every7weeks': 'A cada 7 semanas',
  'dropdown.time.appointment.every8weeks': 'A cada 8 semanas',
  'dropdown.time.appointment.every9weeks': 'A cada 9 semanas',
  'dropdown.time.appointment.every10weeks': 'A cada 10 semanas',
  'dropdown.time.appointment.monthly': 'Por mês',
  'dropdown.time.appointment.every2months': 'A cada 2 meses',
  'dropdown.time.appointment.every3months': 'A cada 3 meses',
  'dropdown.time.appointment.every4months': 'A cada 4 meses',
  'dropdown.time.appointment.every5months': 'A cada 5 meses',
  'dropdown.time.appointment.every6months': 'A cada 6 meses',
  'dropdown.time.appointment.every7months': 'A cada 7 meses',
  'dropdown.time.appointment.every8months': 'A cada 8 meses',
  'dropdown.time.appointment.every9months': 'A cada 9 meses',
  'dropdown.time.appointment.every10months': 'A cada 10 meses',
  'dropdown.time.appointment.every11months': 'A cada 11 meses',
  'dropdown.time.appointment.after2times': 'Depois de 2 vezes',
  'dropdown.time.appointment.after3times': 'Depois de 3 vezes',
  'dropdown.time.appointment.after4times': 'Depois de 4 vezes',
  'dropdown.time.appointment.after5times': 'Depois de 5 vezes',
  'dropdown.time.appointment.after6times': 'Depois de 6 vezes',
  'dropdown.time.appointment.after7times': 'Depois de 7 vezes',
  'dropdown.time.appointment.after8times': 'Depois de 8 vezes',
  'dropdown.time.appointment.after9times': 'Depois de 9 vezes',
  'dropdown.time.appointment.after10times': 'Depois de 10 vezes',
  'dropdown.time.appointment.after11times': 'Depois de 11 vezes',
  'dropdown.time.appointment.after12times': 'Depois de 12 vezes',
  'dropdown.time.appointment.after13times': 'Depois de 13 vezes',
  'dropdown.time.appointment.after14times': 'Depois de 14 vezes',
  'dropdown.time.appointment.after15times': 'Depois de 15 vezes',
  'dropdown.time.appointment.after20times': 'Depois de 20 vezes',
  'dropdown.time.appointment.after25times': 'Depois de 25 vezes',
  'dropdown.time.appointment.after30times': 'Depois de 30 vezes',
  'dropdown.time.appointment.specificDate': 'Data específica',
  'modal.createHours.title': 'Criar {0} horas',
  'modal.editHours.title': 'Editar {0} horas',
  'modal.deleteItem.confirm.0': 'Tem certeza que deseja permanentemente',
  'modal.deleteItem.confirm.1': 'excluir',
  'modal.deleteItem.confirm.2': 'do banco de dados?',
  'modal.entity.category': 'Categoria',
  'modal.entity.referal': 'Fonte referêncial',
  'modal.entity.resource': 'Recurso',
  'modal.entity.location': 'Local',
  'modal.deleteReminder.title': 'Eliminar Lembrete',
  'modal.deleteMessage.title': 'Eliminar mensagem',
  'modal.deleteReminder.description': 'Tem a certeza de querer apagar este Lembrete ?',
  'modal.deleteMessage.description': 'Tem a certeza de querer apagar esta Mensagem ?',
  'modal.deleteReminder.confirm': 'Sim, eliminar',
  'modal.deletePaymentMethod.title': 'Eliminar o método de pagamento',
  'modal.deletePaymentMethod.description': 'Este método de pagamento será eliminado de forma permanente',
  'closedDate.create.modal.title': 'Adicionar Data de Fechamento',
  'closedDate.edit.modal.title': 'Editar Data de Fechamento',
  'staff.access.owner': 'Proprietário',
  'staff.access.high': 'Alto',
  'staff.access.medium': 'Médio',
  'staff.access.low': 'Baixo',
  'staff.access.basic': 'Básico',
  'staff.access.no_access': 'Acesso Negado',
  'staff.create.title': 'Adicionar Data de Fechamento',
  'staff.emptyState.title': 'Nenhum funcionário agendado.',
  'staff.emptyState.description': 'Consulte a seção de membros da equipe para adicionar horários de trabalho.\n',
  'staff.viewStaffMembers.button': 'Ver membros da equipe',
  'staff.picture.changePhoto': 'Mudar foto',
  'staff.picture.removePhoto': 'Remover foto',
  'input.clientSearch.placeholder': 'Pesquisar por nome, e-mail ou telefone',
  'input.blockedDate.label': 'Data',
  'appointment.notes.title': 'Observações do agendamento',
  'appointment.history.title': 'Histórico de compromissos',
  'appointment.history.by.title': ' por {0}',
  'appointment.log.created.label': 'Criado por',
  'appointment.log.updated.label': 'Atualizado por',
  'appointment.log.rescheduled.label': 'Remarcado por',
  'appointment.log.cancelled.label': 'Cancelado pelo',
  'appointment.log.changed.label': 'Status alterado por',
  'appointment.notificationLog.rescheduling': 'Notificação de reagendamento enviada em',
  'appointment.notificationLog.cancellation': 'Notificação de cancelamento enviada em',
  'appointment.notificationLog.confirmation': 'Notificação de confirmação enviada em',
  'appointment.notificationLog.reminder': 'Notificação de lembrete enviada em',
  'appointment.notificationLog.thankYou': 'Obrigado notificação enviada em',
  'appointment.notificationLog.lastVisit': 'Notificação da última visita enviada em',
  'state.cancelReason.title': 'Configurar motivos de cancelamentos',
  'state.cancelReason.description':
    'Motivos de cancelamento ajudam a entender o motivo pelo qual seus clientes não compareceram ao agendamento.',
  'state.cancelReason.button': 'Adicionar motivo do cancelamento',
  'state.resources.title': 'Gerencie seus recursos',
  'state.resources.description':
    'O seu negócio alcançará ainda mais sucesso quando conhecer os recursos próximos de você. Mantenha o controle de salas, mobiliário ou quaisquer ferramentas para garantir serviços bem feitos e a minimização dos problemas ao longo do caminho.',
  'state.resources.button': 'Adicionar recurso',
  'state.referralSource.title': 'Lidar com fontes de recursos',
  'state.referralSource.description':
    'Uma fonte de referência indica de onde veio o cliente. Manter um rastreador desta informação pode ajudar a aumentar a visibilidade de seu negócio e taxa de conversão.',
  'state.referralSource.button': 'Adicionar fonte de referência',
  'state.locations.title': 'Definir seu local',
  'state.locations.description':
    'Aqui você pode gerenciar vários canais para sua empresa, definir turnos de trabalhos para seus funcionários por local e ter um calendário individual para cada um deles.',
  'state.staff.title': 'Gerenciar sua equipe',
  'state.staff.description':
    'Criar perfis para os seus funcionários poderem adicionar e editar seus turnos de trabalho e manter o controle de seus agendamentos e horas trabalhadas.',
  'state.messageLog.title': 'Tenha controle de suas mensagens',
  'state.messageLog.description':
    'Envie mensagens com lembrete de agendamentos para seus clientes ou informações. Aqui você poderá acompanhar as mensagens e seu status.\r\nPara configurar mensagens automáticas vá para',
  'state.messageLog.link': 'Configuração de notificações',
  'state.clients.title': 'Gerenciamento de clientes',
  'state.clients.description':
    'O cuidado com seus clientes começa na certificação de que o perfil do cliente possui todas as informações necessárias para uma experiência incrível e a capacidade de entrar em contato com eles.',
  'state.clients.button': 'adicionar cliente',
  'state.services.title': 'Configurar grupos e serviços',
  'state.services.description':
    'Grupos são essenciais pois torna possível juntar serviços similares oferecidos por você. Além disso, fazem com que seu calendário fique ainda mais organizado.',
  'state.services.button': 'Adicionar grupo de serviço',
  'state.calendar.title': 'Sem Funcionário Programado',
  'state.calendar.description': 'Para definir o horário de trabalho dos funcionários acesse',
  'state.calendar.button': 'Área do Funcionário.',
  'state.closedDates.title': 'Datas Fechadas',
  'state.closedDates.description':
    'Liste as datas em que sua empresa estará fechada devido a feriados, manutenção ou qualquer outro motivo. Os clientes não poderão fazer reservas on-line durante esses dias.',
  'state.closedDates.button': 'Adicionar Data de Fechamento',
  'title.billingPlan': 'Plano e Fatura',
  'btn.upgrade': 'Upgrade',
  'header.usage': 'UTILIZAÇÃO',
  'header.dropdown.subscription.plan': '{0} Plano',
  'header.dropdown.subscription.staff': '({0} Membros do pessoal)',
  'header.dropdown.notifications.description': '{0} mensagens',
  'header.paymentDetails': 'Ver detalhes de pagamento',
  'title.billingPlan.description':
    'Gerencie sua conta. Detalhes do plano e faturamento, ver transações e fazer download de recibos.',
  'plan.free.title': 'Iniciante',
  'plan.individual.title': 'Individual (apenas 1 funcionário)',
  'plan.business.title': 'Empresa',
  'btn.upgradeNow': 'Upgrade',
  'btn.getStarted': 'Começar a trabalhar',
  'btn.currentPlan': 'Plano Atual',
  'plan.free.option.0': '1 localização',
  'plan.free.option.1': 'Até 5 funcionários',
  'plan.free.option.2': 'Compromissos ilimitados',
  'plan.free.option.3': 'Clientes Ilimitados',
  'plan.free.option.4': 'Reservas on-line ilimitadas',
  'plan.free.option.5': 'Notificações por e-mail',
  'plan.premium.option.0': 'Locais Ilimitados',
  'plan.premium.option.1': 'Compromissos ilimitados',
  'plan.premium.option.2': 'Clientes Ilimitados',
  'plan.premium.option.3': 'Reservas on-line ilimitadas',
  'plan.premium.option.4': 'Compromissos recorrentes',
  'plan.premium.option.5': 'Notificações por e-mail',
  'plan.premium.option.6': 'Notificações por SMS* ',
  'plan.premium.option.7': 'Notificações do Whatsapp*',
  'plan.premium.option.8': 'Análise Ilimitada',
  'plan.premium.option.9': 'Relatórios Ilimitados',
  'plan.premium.option.10': 'Imprimir programações',
  'plan.premium.option.11': 'Exportação de cliente',
  'plan.premium.option.12': 'Suporte prioritário',
  'plan.premium.option.13': 'Marca Plandok removida dos e-mails (e-mails gratuitos desenvolvidos por Plandok)',
  'plan.premium.costsInfo': '*Custos de SMS/WhatsApp não incluídos no plano, aplicam-se custos adicionais.',
  'plan.premium.activeUntilDate': 'Activo até {0}',
  'period.perMonth': 'por mês (sem VAT)',
  'plan.premium.description': 'O preço aumentará adicionando mais membros do pessoal.',
  'plan.premium.viewPricing': 'Ver preços',
  'plan.premium.numberStuff': 'O seu actual número de funcionários: {0}',
  'premium.membership':
    'O preço da assinatura premium pode variar dependendo do número de funcionários que você possui.',
  'premium.price.perMonth': 'Preço premium por mês',
  'pricing.calculated': 'O preço é calculado da seguinte forma:',
  'pricing.calculator': 'Calculadora de preços',
  'pricing.numberStuff': 'Número de funcionários',
  'pricing.extra.member': '{0} para cada membro extra',
  'modal.title.pricing': 'Preços',
  'plan.foreverFree': 'Para sempre livr',
  'plan.individual.0': 'Tudo do Iniciante e mais:',
  'plan.individual.1': 'Notificações personalizadas',
  'plan.individual.2': 'Remova o logo Plandok',
  'plan.individual.option.3': 'Lembretes via SMS ilimitados',
  'plan.individual.4': 'Suporte prioritário',
  'plan.business.0': 'Tudo do Individual e mais:',
  'plan.business.1': 'Locais ilimitados',
  'plan.business.2': 'Funcionários ilimitados',
  'block.billingInfo.title': 'Informações de Pagamento',
  'block.carInfo.title': 'Informações do cartão de crédito',
  'input.cardNumber.label': 'Número do cartão',
  'input.cardNumber.placeholder': '1234 5678 3456 2456',
  'input.expireDate.label': 'Data de validade',
  'input.expireDate.placeholder': '05 / 21',
  'input.cvv.label': 'CVV',
  'input.cvv.placeholder': '123',
  'modal.confirmCancel.subscription': 'Tem certeza que deseja cancelar sua assinatura?',
  'modal.confirmCancel.subscription.description': 'Ao fazê-lo você voltará a ter acesso apenas ao plano grátis',
  'btn.cancelSubscription.confirm': 'Sim, quero cancelar minha assinatura',
  'loading.processingSubscribtion': 'Seu pagamento está sendo processado. Por favor, aguarde...',
  'state.serviceGroup.description': 'Não há serviços no grupo',
  'state.serviceGroup.btn': 'Adicionar serviço',
  'appointment.status.new': 'Novo Agendamento',
  'appointment.status.confirmed': 'Confirmado',
  'appointment.status.notShow': 'Não compareceu',
  'appointment.status.completed': 'Concluído',
  'appointment.status.cancelled': 'Cancelado',
  'billing.tab.plan': 'Seu plano',
  'billing.tab.costs': 'Custos estimados',
  'billing.tab.billing': 'Informações de pagamento',
  'billing.tab.invoice': 'Faturas',
  'plan.free': 'Grátis',
  'plan.deactivated.title': 'O prémio foi desactivado',
  'plan.active.title': 'Ativar',
  'payment.step.title': 'Pagamentos',
  'payment.error.title': 'Atualizar pagamento',
  'payment.payNow.title': 'Pagar',
  'payment.dismiss.title': 'Liberar',
  'payment.tab.title.sub': 'Inscrição',
  'payment.tab.subTitle': 'plano premium',
  'payment.per.members': 'por membro',
  'payment.tab.title.notification': 'Notificações',
  'payment.card.details': 'Detalhes do cartão',
  'payment.success.message': 'O pagamento foi bem sucedido',
  'payment.payWithCard.title': 'Pagar com cartão',
  'payment.cardNumber.title': 'Número do cartão',
  'payment.expirationDate.title': 'Data de expiração',
  'payment.digits.title': '(3 dígitos)',
  'payment.amount': 'Valor do pagamento:',
  'payment.btn.pay': 'Pagar',
  'invoice.table.date': 'Data',
  'invoice.table.invoice': 'Fatura',
  'invoice.table.download': 'Download',
  'invoice.table.status': 'Status',
  'costs.billingPeriod.title': 'Custos estimados para este período de faturamento',
  'costs.billingPeriod.description':
    'Este é um custo atual para seu uso neste período de cobrança. Um detalhamento de seus custos está disponível abaixo.',
  'costs.billingPeriod.additionalInfo':
    'Quando as suas despesas de notificação atingirem 50 €, serão cobradas automaticamente. Mas não se preocupe se o montante for inferior; será convenientemente incluído na sua subscrição mensal.',
  'costs.summary.title': 'Resumo do mês',
  'costs.summary.description': 'Essas cobranças são contabilizadas no saldo da sua conta',
  'costs.summary.detailedCharges': 'Cobranças detalhadas',
  'costs.summary.noData': 'Sem dados',
  'costs.summary.quantity': 'Quantidade',
  'costs.summary.subtotal': 'Subtotal',
  'costs.summary.taxes': 'Impostos',
  'dropdown.time.hours': 'horas',
  'message.planUpgrade': 'Seu plano foi atualizado com sucesso!',
  'costs.subscription': 'Inscrição',
  'btn.cancelSubscription': 'Cancelar assinatura',
  'btn.no': 'Não',
  'notification.success.update': 'Atualizado com sucesso',
  'validation.password.match': 'Senha e confirmação da senha não correspondem',
  'blocked.time': 'Horário Bloqueado',
  'message.successReset.password':
    'Concluído! O e-mail de redefinição de senha foi enviado. Verifique sua caixa de entrada.',
  'title.newPassword': 'Digite sua nova senha',
  'title.newPassword.description': 'Acesse sua conta com segurança ao criar um novo nome de usuário e senha.',
  'input.newPasswordRepeat.label': 'Confirmar sua nova senha',
  'btn.change.password': 'Alterar minha senha',
  'text.havingTrouble': 'Está com algum problema?',
  'link.resetPassword.again': 'Redefinir senha novamente',
  'message.successNew.password': 'Senha alterada com sucesso',
  'title.repeatShifts': 'Repetir turno',
  'text.confirmShift.update':
    'Você editou um turno com repetição semanal. Deletar turnos futuros substituirá {0} cronograma {0} em curso.',
  'text.confirmShift.delete':
    'Você está excluindo um turno com repetição semanal. Deletar turnos futuros substituirá {0} cronograma {0} em curso.',
  'btn.deleteUpcoming.shifts': 'Excluir turnos futuros',
  'btn.deleteThis.shift': 'Excluir apenas este turno',
  'btn.updateUpcoming.shifts': 'Atualizar turnos futuros',
  'btn.updateThis.shift': 'Atualizar apenas este turno',
  'text.permisionTab':
    'Configure as áreas acessíveis e o nível de permissão para cada usuário. Todos os funcionários logados podem acessar o calendário, e a conta do proprietário tem acesso total ao sistema.',
  'title.permission.locations': 'Locais',
  'title.permission.booking': 'Clientes e Agendamentos',
  'title.permission.staff': 'Equipe',
  'title.permission.setup': 'Configurações',
  'title.permission.basic': 'Básico',
  'title.permission.low': 'Baixo',
  'title.permission.med': 'Médio',
  'title.permission.high': 'Alto',
  'title.permission.owner': 'Proprietário',
  'title.allLocations.permission': 'Acessar Todos os Locais',
  'title.accessCalendar.permission': 'Acessar Seu Calendário',
  'title.accessOtherCalendar.permission': 'Acessar Calendário de Outros Funcionários',
  'title.canBook.permission': 'Pode fazer agendamento',
  'title.contactInfo.permission': 'Pode ver informações de contato do cliente',
  'title.clients.permission': 'Clientes',
  'title.messages.permission': 'Mensagens',
  'title.services.permission': 'Serviços',
  'title.workingHours.permission': 'Horas Trabalhadas',
  'title.staffMembers.permission': 'Membros da Equipe',
  'title.permissionLevels.permission': 'Nível de Permissão',
  'title.accountSetup.permission': 'Configurações da Conta',
  'title.clientSettings.permission': 'Configurações do Cliente',
  'title.onlineBookings.permission': 'Reservas on-line',
  'title.analytics.permission': 'Análise',
  'title.marketing.permission': 'Marketing',
  'notification.type.reminder': 'Lembrete',
  'notification.type.confirmation': 'Confirmação',
  'notification.type.online_booking_confirmation': 'Confirmação de reserva online',
  'notification.type.rescheduling': 'Reagendar',
  'notification.type.cancellation': 'Cancelamento',
  'notification.type.thank_you': 'Obrigado',
  'notification.type.last_visit': 'Última visita',
  'notification.channel.sms': 'SMS',
  'notification.channel.email': 'Email',
  'notification.channel.whatsapp': 'WhatsApp',
  'notification.status.sent': 'Enviar',
  'notification.status.pending': 'Pendente',
  'notification.status.failed': 'Falhou',
  'dropdown.time.days': 'dias',
  'title.appointment': 'Agendamento',
  'input.reminderBeforeLastVisit.label': 'Notificação será enviada após número de dias indicados',
  'txt.with': 'com',
  'short.hours': 'h',
  'short.minutes': 'min',
  'input.registrationNumber.label': 'Número da empresa',
  'input.vatNumber.label': 'Número do IVA',
  'invoice.table.priceTaxIncl': 'Montante',
  'error.wrongSenderId':
    'ID do remetente deve incluir dígitos ou caracteres, o tamanho deve ser maior do que 3 e menor do que 11 símbolos.',
  'sidebar.help': 'Ajuda',
  'modal.help.title': 'Como podemos ajudar?',
  'modal.help.liveChat.title': 'Bate-papo ao vivo',
  'modal.help.liveChat.description': 'Tem alguma pergunta? Basta perguntar-nos de imediato',
  'modal.help.suggestFeature.title': 'Sugerir uma característica',
  'modal.help.suggestFeature.description':
    'Partilhe sugestões e veja outras sugestões e vote nas características que deseja',
  'modal.help.viewUpdates.title': 'Ver actualizações',
  'modal.help.viewUpdates.description': 'Veja as nossas mais recentes actualizações e melhorias',
  'warning.title.paidFeature': 'Ferramenta Paga',
  'warning.smsFeatureMissing': 'Para acessar as configurações de SMS você deve melhorar seu plano. Para isso acesse',
  'warning.link.billingAndPricing': 'Plano e Faturamento',
  'warning.newPage.0': 'Upgraded realizado!',
  'warning.newPage.1': 'Se está usando uma versão antiga do Plandok sua senha não estará mais ativa, por favor,',
  'warning.newPage.2': 'Redefinir senha',
  'warning.newPage.3': 'para aproveitar o novo sistema. Para mais informações contacte-nos',
  'warning.newPage.4': 'ou',
  'title.blockTime.select': 'Selecione a hora do agendamento',
  'calendar.allResources': 'Todos os Recursos',
  'appointment.edit.title': 'Editar Agendamento',
  'appointment.create.title': 'Compromisso criado com sucesso.',
  'appointment.update.title': 'Compromisso atualizado com sucesso.',
  'select.notify.title': '{0} não está funcionando no intervalo selecionado, mas você ainda pode agendar compromissos.',
  'booking.notify.title': 'O tempo selecionado é reservado, mas você pode criar uma consulta.',
  'textArea.characters.left': 'caracteres restantes',
  'textArea.characters.exceeded': 'limite excedido por',
  'validation.characters.exceeded': 'O limite de caracteres foi excedido',
  'staff.menu.members': 'Membros da Equipe',
  'staff.menu.dates': 'Data de Fechamento',
  'staff.menu.working': 'Horas Trabalhadas pelos Funcionários',
  'staff.menu.permissions': 'Permissões de Usuário',
  'staff.menu.members.description': 'Adicionar mais membros na equipe e controlar o nível de acesso do usuário',
  'staff.menu.dates.description': 'Programe os próximos encerramentos do seus negócios ou feriados',
  'staff.menu.working.description': 'Gerenciar disponibilidade no calendário ajustando o cronograma dos funcionários',
  'staff.menu.permissions.description': 'Definir permissões para informações e recursos sensíveis',
  'message.successUpdateLocation': 'Feito! Perfil de localização actualizado com sucesso.',
  'ob.title': 'Marcação On-line',
  'ob.overview.tab': 'Visão Geral',
  'ob.massMessages.tab': 'Mensagens em massa',
  'ob.campaigns.tab': 'Campanhas',
  'ob.profile.tab': 'Perfil On-line',
  'ob.links.tab': 'Links',
  'ob.settings.tab': 'Configurações',
  'ob.payments.tab': 'Pagamentos',
  'ob.status.online': 'On-line',
  'ob.status.offline': 'Offline',
  'ob.status.title': 'Status',
  'ob.profile.step': '{0} de 4',
  'ob.profile.step.title': 'Perfil & Localização',
  'ob.profile.step.description':
    'Apresente a sua marca e mostre os seus serviços aos seus clientes. A parte "Sobre" é uma das primeiras seções que seus clientes verão e, se essa informação não for suficiente, deixe um número de telefone onde os clientes possam entrar em contato com você para obter mais informações! Insira o endereço onde você presta seus serviços, para que seus clientes possam ser informados e visualizar o endereço.',
  'ob.overview.placheholder.title': 'Deixe seus clientes marcarem consultas online!',
  'ob.overview.placheholder.description':
    'Crie um perfil de reservas online e permita que seus clientes reservem compromissos online. Basta inserir um link para o seu site ou mídia social e pronto.',
  'ob.overview.start.now': 'Comece agora',
  'ob.links.direct.title': 'Link direto',
  'ob.links.facebook.title': 'Link para o Facebook',
  'ob.links.instagram.title': 'Link para o Instagram',
  'ob.links.direct.description':
    'URL do Link para o seu perfil de reservas online, você pode usá-lo em seu site ou em suas contas de mídia social. Se você não tiver certeza de como usar o link, entre em contato conosco.',
  'ob.links.facebook.description':
    'Compartilhe este link no Facebook para atrair mais clientes e tornar mais fácil para eles encontrarem sua empresa e agendarem uma consulta.',
  'ob.links.instagram.description':
    'Torne isso mais fácil para você e vincule de forma direta sua reserva on-line!\nVocê pode usá-lo em seu site e em qualquer outro lugar que desejar.\nSe você não tiver certeza de como usar o link, não se preocupe, nós ajudamos você!',
  'ob.analytics.tab.description':
    'Adicione o Google Analytics e o Facebook Pixel à sua página de reservas online para analisar o tráfego e o comportamento dos utilizadores.',
  'ob.analytics.google.title': 'Google Analytics',
  'ob.analytics.facebook.title': 'Pixel do Facebook',
  'ob.analytics.google.description':
    'Com o Google Analytics, pode acompanhar e analisar o tráfego do sítio Web e o comportamento dos utilizadores para obter informações sobre como melhorar o seu sítio Web.',
  'ob.analytics.active': 'Activo',
  'ob.analytics.inactive': 'Inactivo',
  'ob.analytics.setUp.btn': 'Configurar',
  'ob.analytics.google.description.1':
    'O Google Analytics é uma ferramenta poderosa, que pode ajudá-lo a monitorizar o tráfego do sítio Web e o comportamento dos utilizadores. Com o Google Analytics, pode acompanhar o número de visitantes do seu sítio Web, de onde vêm, que páginas visitam e quanto tempo permanecem no seu sítio.',
  'ob.analytics.google.description.2':
    'Além disso, o Google Analytics permite-lhe definir objectivos e acompanhar conversas, dando-lhe uma melhor compreensão do desempenho do seu sítio Web e da forma como pode melhorá-lo.',
  'ob.analytics.google.trackingId': 'ID de rastreio',
  'ob.analytics.google.tooltip':
    'Para encontrar o seu ID de acompanhamento, deve começar por iniciar sessão na sua conta do Google Analytics, seleccionar o Web site ou a aplicação que pretende acompanhar e, em seguida, navegar para o separador "Admin". A partir daí, clique no "Código de rastreamento". O seu ID de seguimento será apresentado no formato "UA-XXXX-Y".',
  'ob.analytics.google.account': 'Ainda não tem uma conta do Google Analytics',
  'ob.analytics.click': 'Clique',
  'ob.analytics.here': 'aqui',
  'ob.analytics.learn': 'para saber como criar uma.',
  'ob.analytics.facebook.description.1':
    'O Facebook Pixel é uma ferramenta que permite acompanhar o comportamento do utilizador no seu Web site, incluindo conversões, visualizações de página e outros eventos.',
  'ob.analytics.facebook.description.2':
    'O Facebook Pixel pode fornecer-lhe informações valiosas sobre o seu público, tais como dados demográficos, interesses, tipo de dispositivo, visualizações de páginas e acções realizadas, o que pode ajudá-lo a compreender melhor e a optimizar a experiência do utilizador no seu site.',
  'ob.analytics.facebook.pixelId': 'ID do pixel',
  'ob.analytics.facebook.tooltip':
    'Para encontrar o seu Facebook Pixel ID, siga estes passos: Aceda à sua conta do Gestor de anúncios do Facebook. Clique no botão "Menu" no canto superior esquerdo do ecrã. Na secção "Gestor de eventos", seleccione "Pixels". Aqui, deve ver uma lista de todos os píxeis associados à sua conta. Clique no nome do pixel para o qual necessita do ID. O seu ID de pixel será apresentado no canto superior esquerdo do ecrã, junto ao nome do pixel.',
  'ob.analytics.facebook.account': 'Ainda não tem uma página empresarial do Facebook?',
  'ob.analytics.google.account.mobile.1': 'Ainda não tem uma conta do Google Analytics',
  'ob.analytics.google.account.mobile.2': 'conta? Clique em',
  'ob.analytics.google.account.mobile.3': 'para saber como',
  'ob.analytics.google.account.mobile.4': 'criar uma.',
  'ob.analytics.facebook.account.mobile.1': 'Ainda não tem uma página empresarial do Facebook',
  'ob.analytics.facebook.account.mobile.2': 'página comercial do Facebook? Clique em',
  'ob.analytics.facebook.account.mobile.3': 'para saber como',
  'ob.analytics.facebook.account.mobile.4': 'criar uma.',
  'ob.analytics.successfullySaved.message': 'Guardado com sucesso',
  'ob.settings.booking.title': 'Reservas online disponíveis ',
  'ob.settings.booking.description':
    'Defina o horário em que os primeiros e os últimos atendimentos podem ser agendados. Gerencie os intervalos de tempo dos atendimentos e forneça informações importantes aos clientes, além da opção de escolha de um funcionário para o atendimento.',
  'ob.settings.cancellation.title': 'Cancelamento e Reagendamento',
  'ob.settings.cancellation.description':
    'Defina o período de tempo em que os clientes podem cancelar ou reagendar seus atendimentos.',
  'ob.settings.locationsUrl.title': 'URL de Localização',
  'ob.settings.locationsUrl.description':
    'Configure e compartilhe um URL exclusivo para todos os locais que podem ser reservados online. Isso é útil para empresas com vários locais apenas.',
  'ob.settings.businessName.explanation': 'Sua URL: book.plandok.com/business-name/Location2556',
  'ob.settings.notifications.title': 'Emails de Notificação',
  'ob.settings.notifications.description':
    'Especifique o destinatário dos emails de notificação sobre novos agendamentos ou alterações feitas a eles.',
  'ob.settings.required.title': 'Campos obrigatórios e opcionais',
  'ob.settings.required.description': 'Selecione quais campos o cliente deverá fornecer ao fazer reservas online.',
  'ob.settings.requiredField.title': 'Obrigatório',
  'ob.settings.optionalField.title': 'Opcional',
  'ob.settings.requiredField.notification.title': 'Houve um problema com as definições',
  'ob.settings.requiredField.notification.description':
    'Lamento, mas um campo tem de ser obrigatório, exceto "obervações".',
  'ob.settings.nameField.title': 'Nome próprio',
  'ob.settings.lastNameField.title': 'Apelido',
  'ob.settings.phoneField.title': 'Número de telefone',
  'ob.settings.emailField.title': 'E-mail',
  'ob.settings.notes.label': 'Texto de marcador de posição para notas',
  'ob.settings.notes.placeholder': 'Por exemplo, introduza as suas notas',
  'ob.bookingLimit.title': 'Limite de reservas em linha',
  'ob.bookingLimit.description': 'Indique o número máximo de marcações de serviços que pretende definir.',
  'ob.bookingLimit.label': 'Limitar as marcações de serviços',
  'ob.bookingLimit.unlimited.title': 'Ilimitado',
  'ob.bookingLimit.1service.title': 'máx. 1 Serviço',
  'ob.bookingLimit.2services.title': 'máx. 2 Serviços',
  'ob.bookingLimit.3services.title': 'máx. 3 Serviços',
  'ob.menu.edit.profile': 'Editar Perfil',
  'ob.menu.turnOff.profile': 'Desativar Perfil',
  'ob.profile.status': 'Status',
  'ob.overview.tab.description': 'Expanda o seu negócio permitindo que os seus clientes façam reservas online.',
  'ob.profile.tab.description':
    'Gerencie perfis online para seus locais. Ajuste imagens, descrições, endereços e muito mais.',
  'ob.links.tab.description':
    'Aqui você encontrará um link de reservas online, que pode ser compartilhado com seus clientes.',
  'ob.settings.tab.description':
    'Ajuste suas configurações de reserva online aqui, para que seus clientes possam reservar apenas quando for conveniente para você.',
  'ob.payments.tab.description':
    'Seleccione os métodos de pagamento que os seus clientes poderão utilizar para pagar os serviços.',
  'ob.payments.page.description':
    'Aqui pode selecionar os métodos de pagamento que os seus clientes poderão utilizar para pagar os serviços.',
  'ob.payOnSite.title': 'Pagamento no local',
  'ob.payOnSite.description': 'Os seus clientes poderão pagar no local com dinheiro.',
  'ob.payWithCard.title': 'Pagar com cartão',
  'ob.payWithCard.description': 'Os seus clientes poderão pagar com cartão de crédito através da aplicação Stripe.',
  'ob.payWithCard.info.1': 'Você está usando o plano gratuito Plandok, então você terá que pagar',
  'ob.payWithCard.info.2': 'taxa de comissão Plandok e taxa de comissão Stripe usando este método de pagamento.',
  'ob.payWithCard.info.3': ' para ter',
  'ob.payWithCard.info.4': 'taxas de comissão do Plandok.',
  'ob.payWithCard.info.5': 'O plano Premium não está isento do pagamento das taxas de comissão do Stripe. ',
  'ob.payWithCard.info.6': 'Verificar os preços do Stripe aqui',
  'ob.stripeConnected.info': '(Stripe conectado)',
  'ob.requiresSetup.info': '(É necessário configurar a sua conta Stripe)',
  'ob.connectToStripe.btn': 'Conectar-se ao Stripe',
  'ob.connectToStripe.successMessage.title': 'Ligado com sucesso ao Stripe',
  'ob.waitingAuthorization.title': 'A aguardar a sua autorização no Stripe',
  'ob.stripeDashboard.title': 'Todos os registos de pagamento e reembolsos estão disponíveis no ',
  'ob.stripeDashboard.url': 'Painel de controlo do Stripe',
  'ob.yourStripeAccount.title': 'A sua conta Stripe',
  'ob.visitStripe.option': 'Visitar o Stripe',
  'ob.changeAccount.option': 'Alterar a conta Stripe',
  'input.earliestAppointment.label': 'Marcações anteriores',
  'input.earliestAppointment.placeholder': 'Um pouco antes do agendamento',
  'input.latestAppointment.label': 'Último agendamento',
  'input.latestAppointment.placeholder': 'Inferior a 6 meses de antecedência',
  'input.timeSlots.label': 'Intervalos de horário',
  'input.timeSlots.placeholder': '15 minutos',
  'checkbox.allowCustomers': 'Permitir que os clientes escolham os membros da equipe para os seus agendamentos',
  'input.importantInfo.label': 'Informação Importante',
  'input.importantInfo.placeholder':
    'Informações que serão mostradas ao cliente durante a confirmação de seu agendamento',
  'input.changeAppointment.label': 'Mudanças nos agendamentos podem ser realizadas',
  'input.changeAppointment.placeholder': 'A qualquer momento',
  'checkbox.sendToBooked': 'Envie de forma reservada para o membro da equipe ',
  'checkbox.sendSpecificEmail': 'Envie para um endereço de e-mail específico',
  'input.specificEmail.label': 'Endereço de email específico',
  'input.specificEmail.placeholder': 'Insira o endereço de email',
  'input.locationsUrl.label': 'Localizações de URL',
  'input.locationsUrl.placeholder': 'Ex: Johns-Barber-Shop',
  'ob.btn.save': 'Salvar',
  'ob.btn.cancel': 'Cancelar',
  'ob.btn.showLink': 'Mostrar Link',
  'ob.btn.active': 'Ativa',
  'ob.btn.inactive': 'Inativa',
  'ob.linkModal.title': 'Link direto para as reservas',
  'ob.linkModal.description': 'Aqui está o link onde seus serviços de reservas online podem ser acessados diretamente!',
  'ob.linkModal.afterDescription':
    'Este link direciona os usuários diretamente para seus serviços. Com apenas um clique, os clientes podem acessar seus perfis de negócios e agendar consultas.\nEste link é fácil de usar e compatível com todos os dispositivos, computador, smartfone ou um tablet.',
  'ob.fbLinkModal.title': 'Página do Facebook Reserve Agora',
  'ob.fbLinkModal.description':
    'Configure um botão Reserve Agora integrado na sua página de negócios do Facebook para receber facilmente novas reservas diretamente do Facebook.',
  'ob.fbLinkModal.step1': 'Faça login na sua página de negócios no Facebook',
  'ob.fbLinkModal.step2': 'Clique em "Adicionar um botão" localizado próximo à foto da capa',
  'ob.fbLinkModal.step3': 'Selecione a opção "Registrar-se" e insira o link de reserva abaixo',
  'ob.linkModalCopySuccess.title': 'Link copiado!',
  'ob.btn.copyLink': 'Copiar Link',
  'title.appointmentSlot.select': 'Selecione a hora do agendamento',
  'btn.back': 'Voltar',
  'btn.continue': 'Continuar',
  'ob.hours.open': 'Abrir',
  'ob.hours.close': 'Fechado',
  'ob.hours.step.title': 'Jornada de trabalho',
  'ob.hours.step.description': 'Avise seus clientes quando sua empresa abre e fecha.',
  'ob.staffHours.step.title': 'Horário de trabalho da equipe',
  'ob.staffHours.step.description':
    'Uma etapa importante, adicione o horário de trabalho para o sistema saber quando os clientes podem reservar on -line.',
  'ob.location.step.title': 'Informação de localização',
  'ob.photo.step.title': 'Fotos',
  'ob.photo.step.description': 'Deixe seus clientes vizualizarem a aparência de seu local de trabalho.',
  'input.file.dnd.photos': 'Arrastar e soltar fotos',
  'btn.publish': 'Ligar',
  'text.next': 'Próximo',
  'input.phone.number.label': 'Número de telefone',
  'input.state.label': 'Estado',
  'input.state.placeholder': 'Insira um estado (município) ',
  'input.addressDetails.label': 'Detalhes do endereço',
  'input.addressDetails.placeholder':
    'Insira os detalhes do endereço, como número, andar, outras entradas, informações de estacionamento, etc.',
  'placeholder.no.photos': 'Sem foto',
  'btn.options': 'Opções',
  'btn.list.profile': 'Habilitar',
  'btn.preview': 'Visualização',
  'btn.next': 'Próximo',
  'market.ob.pleaseWait.title': 'Por favor aguarde ...',
  'market.ob.location.step.title': 'Selecionar local',
  'market.ob.services.step.title': 'Selecionar serviços',
  'market.ob.employee.step.title': 'Seleccionar data e pessoal',
  'market.ob.timeSlot.step.title': 'Seleccionar data e hora',
  'market.ob.personalInfo.step.title': 'Pagamento',
  'market.ob.appointmentInfo.step.title': 'Informações de agendamento',
  'market.ob.informationAboutYou.title': 'Informação sobre si',
  'market.ob.paymentMethod.title': 'Forma de pagamento',
  'market.ob.securePayment.title': 'Pagamento Seguro',
  'market.ob.payWithCard.title': 'Pagar com cartão',
  'market.ob.payOnSite.title': 'Pagar no local',
  'market.ob.payOnSite.firstDescription': 'Terá de pagar no salão após o procedimento.',
  'market.ob.payOnSite.secondDescription':
    'Uma pequena solicitação de nós: as empresas geralmente são pequenas empresas independentes; portanto, se você não puder chegar ao seu compromisso programado, reagendar ou cancelá -lo.',
  'market.ob.validThru.title': 'Válido até (Mês/ano)',
  'market.ob.information.title': 'Informação',
  'market.ob.additionalInformation.title': 'Informação adicional',
  'market.ob.reschedulingPolicy.title': 'Política de cancelamento/reescalonamento',
  'market.ob.reschedulingPolicy.description': 'As alterações às marcações podem ser feitas {0} até à marcação de fogo.',
  'market.ob.fieldsAreRequired.title': '* Os campos são de preenchimento obrigatório',
  'market.ob.step.title': 'Passo {0} de 3',
  'market.ob.confirm.step.title': 'Ver e confirmar',
  'market.ob.noServices.placeholder': 'Ainda não foi selecionado nenhum serviço',
  'market.ob.input.firstName.label': '{0} Nome',
  'market.ob.input.firstName.placeholder': 'e.g. John',
  'market.ob.input.lastName.label': '{0} Sobrenome',
  'market.ob.input.lastName.placeholder': 'e.g. Doe',
  'market.ob.input.phone.label': '{0} Número de telemóvel',
  'market.ob.input.phone.placeholder': '670 70 822',
  'market.ob.input.email.label': '{0} Endereço de e-mail',
  'market.ob.input.email.placeholder': 'john.doe@gmail.com',
  'market.ob.input.description.label': 'Notas',
  'market.ob.staff.assignedSpecialist.label': 'Com a equipe',
  'market.ob.staff.noPreference.label': 'Sem preferência',
  'market.ob.timeSlots.fullyBooked.title': 'Lamentamos, mas não temos tempo disponível para este dia.',
  'market.ob.timeSlots.choseAnotherDay.title': 'Por favor, escolha outro dia.',
  'market.ob.timeSlots.closedSlot.title': 'A data disponível mais próxima é',
  'market.ob.timeSlots.selectClosest.title': 'Selecionar a data mais próxima',
  'market.ob.timeSlots.note.title': 'Informações importantes',
  'market.ob.service.duration.title': 'min',
  'market.location.workingHours.title': 'Horas de trabalho',
  'market.location.monday.title': 'Segunda-feira',
  'market.location.tuesday.title': 'Terça-feira',
  'market.location.wednesday.title': 'Quarta-feira',
  'market.location.thursday.title': 'Quinta-feira',
  'market.location.friday.title': 'Sexta-feira',
  'market.location.saturday.title': 'Sábado',
  'market.location.sunday.title': 'Domingo',
  'market.location.meetOurTeam.title': 'Conheça nossa equipe',
  'market.location.title': 'Entre em contacto',
  'market.location.services.title': 'Serviços',
  'market.location.about.title': 'Sobre',
  'market.location.aboutUs.title': 'Sobre nós',
  'market.confirmation.appointmentCanceled.title': 'Esta marcação foi cancelada',
  'market.confirmation.appointmentConfirmed.title': 'Nomeação confirmada',
  'market.confirmation.appointmentConfirmed.description': 'A sua marcação é confirmada, aqui estão os detalhes:',
  'market.confirmation.appointmentDate.title': 'Data da nomeação',
  'market.confirmation.scheduledAppointment.title': 'O cancelamento não é possível online.',
  'market.confirmation.scheduledAppointment.description':
    'Se não conseguir chegar à sua marcação agendada, por favor reprograme-a ou cancele-a.',
  'market.modal.cancelAppointment.title': 'Tem a certeza de querer cancelar esta marcação ?',
  'market.modal.cancelledAppointment.title': 'A marcação foi cancelada',
  'market.language.changer.title': 'Idioma (Language)',
  'market.btn.bookNow': 'Reserve',
  'market.servicesAvailable.title': 'Serviços',
  'market.poweredByPlandok.title': 'Plandok',
  'market.poweredByPlandok.description': 'Quer site semelhante ? Visite',
  'market.btn.readMore': 'Leia mais',
  'market.btn.showLess': 'Mostrar menos',
  'market.btn.reviews': 'Comentários',
  'market.btn.viewMore': 'Ver mais',
  'market.btn.cancelAppointment': 'Cancelar marcação',
  'market.btn.cancel': 'Sim, cancelar',
  'market.wh.closed.title': 'Fechado',
  'market.ob.success.message': 'Pronto! A reserva foi criada com sucesso',
  'market.ob.cancelAppointment.success.message': 'Agendamento cancelado com sucesso',
  'market.ob.cancelAppointment.error.message': 'Cancelamento do agendamento falhou',
  'market.ob.cancelAppointment.confirm.title': 'Tem certeza que deseja cancelar o agendamento',
  'market.location.getDirections.title': 'Obter direções',
  'market.notFound.title': 'Página não encontrada',
  'market.notFound.btn.goBack': 'Voltar atrás',
  'market.ob.quantitativeLimit.title': 'Limite quantitativo',
  'market.ob.quantitativeLimit.description':
    'Lamentamos, mas o proprietário impôs um limite ao número de serviços de reserva, permitindo a seleção de apenas {0} serviç{0}',
  'market.ob.quantitativeLimit.three': 'três',
  'market.ob.quantitativeLimit.two': 'dois',
  'market.ob.quantitativeLimit.one': 'um',
  'market.ob.quantitativeLimit.serviceSingular': 'o',
  'market.ob.quantitativeLimit.servicesPlural': 'os',
  'validation.characters.minLength': 'Este valor é muito curto. Ele deve ter pelo menos 3 caracteres.',
  'not.found.btn.label': 'Voltar',
  'not.found.subTitle.label': 'Desculpe, a página que você visitou não existe.',
  'analytics.title': 'Analítica',
  'analytics.dashboard.tab': 'Painel',
  'analytics.reports.tab': 'Relatórios',
  'analytics.premium.title': 'Prêmio',
  'analytics.totalAppointments.title': 'Total de consultas',
  'analytics.cancellationReasons.title': 'Top cancelar razões',
  'analytics.occupancy.title': 'Ocupação',
  'analytics.topServices.title': 'Principais serviços',
  'analytics.clients.title': 'Clientes',
  'analytics.completed.title': 'Concluído',
  'analytics.notCompleted.title': 'Não completo',
  'analytics.canceled.title': 'Cancelado',
  'analytics.noShowAppointments.title': 'No show nomeações',
  'analytics.reasonName.title': 'Nome razão',
  'analytics.reasonCount.title': 'Nome razão',
  'analytics.lastPeriod.title': 'último período',
  'analytics.workingHours.title': 'Jornada de trabalho',
  'analytics.bookedHours.title': 'Horas Reservas',
  'analytics.blockedHours.title': 'Horas bloqueados',
  'analytics.unbookedHours.title': 'Horas não reservadas',
  'analytics.service.title': 'Serviços',
  'analytics.thisMonth.title': 'Este período',
  'analytics.lastMonth.title': 'Último período',
  'analytics.totalClients.title': 'Total de clientes',
  'analytics.averageVisits.title': 'Média de visitas por cliente',
  'analytics.newClients.title': 'Novos clientes',
  'analytics.returningClients.title': 'Retornando clientes',
  'analytics.returnRate.title': 'Taxa de retorno cliente',
  'analytics.clientsByGender.title': 'Clientes por gênero',
  'analytics.clientsBySource.title': 'Clientes por fonte de referência',
  'analytics.clientsByAge.title': 'Clientes por idade',
  'analytics.timeRange.custom': 'Personalizado',
  'analytics.timeRange.today': 'Hoje',
  'analytics.timeRange.yesterday': 'Ontem',
  'analytics.timeRange.last1day': 'Último 1 dia',
  'analytics.timeRange.last7days': 'Últimos 7 dias',
  'analytics.timeRange.last30days': 'Últimos 30 dias',
  'analytics.timeRange.last90days': 'Últimos 90 dias',
  'analytics.timeRange.last3months': 'Últimos 3 meses',
  'analytics.timeRange.lastMonth': 'Mês passado',
  'analytics.timeRange.last6Months': 'Últimos 6 meses',
  'analytics.timeRange.lastYear': 'Ano passado',
  'analytics.timeRange.last2year': 'Última 2 anos',
  'analytics.timeRange.thisWeek': 'Essa semana',
  'analytics.timeRange.thisMonth': 'Este mês',
  'analytics.timeRange.thisYear': 'Este ano',
  'analytics.timeRange.tomorrow': 'Amanhã',
  'analytics.timeRange.next7days': 'Próximos 7 dias',
  'analytics.timeRange.next30days': 'Próximos 30 dias',
  'analytics.menu.dashboard.description': 'Explore os dados da sua empresa em gráficos e estatísticas',
  'analytics.menu.reports.description': 'Explore seus dados de negócios em relatórios',
  'analytics.timeRange.allTime': 'Tempo todo',
  'analytics.timeRange.label': 'Intervalo de tempo',
  'analytics.services.lastPeriod.label': 'Último período',
  'analytics.totalAppointments.appointment.label': 'Agendamentos',
  'analytics.totalAppointments.onlineBook.label': 'Reservas on-line',
  'analytics.gender.male': 'Macho',
  'analytics.gender.female': 'Fêmea',
  'analytics.gender.unknown': 'Desconhecido',
  'analytics.noData': 'Não há dados no período selecionado',
  'analytics.premium.info.title': 'Atualizar para a versão Premium para ver analytics por mais de 7 dias.',
  'analytics.premium.upgradeButton.title': 'ATIVAR',
  'analytics.premium.skipButton.title': 'Pular',
  'reports.appointments.title': 'Compromissos',
  'reports.appointments.description':
    'Visualize as receitas projetadas de compromissos futuros, acompanhe as taxas e motivos de cancelamento.',
  'reports.appointmentsList.title': 'Lista de compromissos',
  'reports.appointmentsList.description': 'Exibe todos os compromissos com informações relacionadas.',
  'reports.appointmentsSummary.title': 'Resumo de compromissos',
  'reports.appointmentsSummary.description': 'Exibe compromissos por equipe e serviço.',
  'reports.appointmentsCancellations.title': 'Cancelamentos de compromissos',
  'reports.appointmentCancellations.title': 'Cancelamentos de compromissos',
  'reports.appointmentsCancellations.description':
    'Exibe todos os motivos e quantidade de compromissos cancelados por período selecionado.',
  'reports.summary.title': 'Resumo',
  'reports.clients.title': 'Clientes',
  'reports.clients.description':
    'Obtenha insights sobre como os clientes interagem com sua empresa e quem são os maiores gastadores',
  'reports.clientsList.title': 'Clientes',
  'reports.clientsList.description': 'Lista de Clientes com seus dados',
  'reports.exportCsv.title': 'Exportar CSV',
  'reports.appointmentsByStaff.title': 'Nomeações por equipe',
  'reports.appointmentsByService.title': 'Agendamentos por Serviço',
  'reports.clearFilters.button.title': 'Limpar filtros',
  'reports.filterStaff.placeholder': 'Todos os funcionários',
  'reports.filterLocations.placeholder': 'Todos os locais',
  'reports.filters.title': 'Filtros',
  'reports.locations.mobile.title': 'Localizações:',
  'reports.staff.mobile.title': 'Funcionários:',
  'reports.filters.apply.title': 'Aplicar',
  'reports.search.placeholder': 'Pesquise por referência ou cliente',
  'reports.subscription.description': '{0} plano ({0} apenas pessoal)',
  'reports.notifications.description': '{0} mensagens ({0})',
  'serviceGroup.addCategory.description': 'As categorias são essenciais, pois permitem agrupar serviços semelhantes.',
  'serviceGroup.addCategory.title': 'Adicionar categoria',
  'app.install.title': 'Instale Plandok',
  'app.install.description.title':
    'A instalação quase não usa armazenamento e fornece uma maneira rápida de retornar a este aplicativo',
  'app.install.button.title': 'Instalar',
  'app.skip.button.title': 'Pular',
  'app.actionUndone.button.title': 'Acção anulada',
  'app.appointmentCancelled.button.title': 'Nomeação suprimida',
  'app.undo.button.title': 'Anular',
  'app.showAllTimes.button.title': 'Mostrar todos os horários',
  'app.install.success.title': 'Aplicativo instalado com sucesso!',
  'rp.upgradeToPremiumLink.title': 'Atualizar para Premium',
  'rp.upgradeToPremium.title': 'para usar esse recurso',
  'rp.repeatAppointment.title': 'Repita',
  'rp.input.frequency.title': 'Frequência',
  'rp.input.endsAfter.title': 'Final',
  'rp.input.selectDate.title': 'Selecione a data',
  'rp.upgrade.title': 'Atualize seu plano',
  'rp.upgrade.description.title':
    'A versão premium do Plandok não está ativada. Atualize seu plano para acessar totalmente todos os recursos fornecidos pelo Plandok.',
  'rp.upgrade.button.title': 'Atualizar para premium',
  'rp.repeating.title': 'recorrente',
  'rp.repeats.title': 'Repete',
  'rp.repeatsUntil.title': 'até',
  'rp.repeatingTimes.title': '({0} vezes)',
  'rp.cancel.upcoming.title': 'Exclua todos os próximos compromissos repetidos',
  'rp.update.upcoming.title': 'Atualizar todos os próximos compromissos repetidos',
  'rp.edit.appointment.title': 'Atualizar compromisso',
  'rp.edit.appointment.description.title':
    'Você está editando um compromisso repetido. Selecione quais compromissos devem ser atualizados:',
  'rp.edit.thisAppointment.title': 'Atualizar apenas este compromisso',
  'rp.edit.allAppointments.title': 'Atualizar todos os próximos compromissos',
  'rp.edit.saveAppointment.title': 'Salvar',
  'modal.cancelSubscriptionError.title': 'Cancelar erro de subscrição',
  'modal.cancelSubscriptionError.text.1': 'O plano gratuito só está disponível com um máximo de 5',
  'modal.cancelSubscriptionError.text.2': 'empregados',
  'modal.cancelSubscriptionError.text.3': 'e 1',
  'modal.cancelSubscriptionError.text.4':
    'Para utilizar a versão gratuita, remover locais / pessoal para satisfazer os requisitos.',
  'modal.latestPaymentFailed.title': 'O último pagamento falhou',
  'modal.latestPaymentFailed.text.1':
    'Para ter acesso a todas as funcionalidades, pagar ou utilizar a versão gratuita remover',
  'modal.latestPaymentFailed.text.2':
    'para cumprir os requisitos. O plano gratuito só está disponível com um máximo de 5 empregados e 1 local.',
  'modal.extraCostsForNewMember.title': 'Custos adicionais para o novo membro',
  'modal.extraCostsForNewMember.description':
    'Ao aumentar o número de funcionários, haverá custos adicionais à sua assinatura mensal.',
  'modal.premiumUpgradeRequired.title': 'Actualização Premium necessária',
  'modal.premiumUpgradeRequired.description':
    'A fim de adicionar {0} adicional o seu plano deve ser actualizado para Premium',
  'modal.premiumUpgradeRequired.location': 'uma localização',
  'modal.premiumUpgradeRequired.staffMembers': 'pessoal',
  'modal.changeAccount.title': 'Mudar de conta Stripe?',
  'modal.changeAccount.description':
    'Não será possível aceitar pagamentos enquanto estiver a mudar de conta. Demorará cerca de um minuto a efetuar a alteração.',
  'btn.agree': 'Concorda',
  'btn.decline': 'Declínio',
  'paymentMethod.paymentMethod.title': 'Método de pagamento',
  'paymentMethod.currentPaymentMethod.title': 'Método de pagamento actual',
  'paymentMethod.expirationDate': 'Data de expiração {0}',
  'paymentMethod.otherPaymentMethods.title': 'Outros métodos de pagamento',
  'paymentMethod.addAnotherPaymentMethod.btn': 'Adicionar outro método de pagamento',
  'paymentMethod.addPaymentMethod.title': 'Adicionar método de pagamento',
  'paymentMethod.noPaymentMethodAdded.title': 'Nenhum método de pagamento adicionado',
  'paymentMethod.noPaymentMethodAdded.description':
    'O método de pagamento será adicionado ao seleccionar o plano Premium',
  'paymentMethod.goToPlanPage.btn': 'Ir para a página do plano',
  'paymentMethod.add.btn': 'Adicionar',
  'paymentMethod.makeDefault.btn': 'Tornar predefinido',
  'paymentMethod.cardHasBeenAdded.message': 'O cartão foi adicionado',
  'paymentMethod.cardHasBeenAssign.message': 'O cartão foi atribuído',
  'page.marketing.overview.messages.description':
    'Envie mensagens de marketing personalizadas aos seus clientes via SMS ou Email com poucos cliques simples',
  'page.marketing.overview.btn.sendMessage': 'Enviar mensagem',
  'page.marketing.overview.campaigns.title': 'Campanhas de envio automático',
  'page.marketing.overview.campaigns.description':
    'Criar campanhas personalizadas com base em eventos como aniversários de clientes e muito mais. \nEnviar múltiplos canais para entregar campanhas por e-mail e mensagem de texto',
  'page.marketing.overview.btn.newCampaign': 'Nova Campanha',
  'page.marketing.massMessages.title': 'Configurar nova mensagem',
  'page.marketing.massMessages.messageLog': 'Registo de mensagens',
  'page.marketing.massMessages.sendOn': 'Enviar em',
  'page.marketing.massMessages.sendTo': 'Enviar para',
  'page.marketing.massMessages.channel': 'Canal',
  'page.marketing.campaigns.description':
    'Envie mensagens de marketing automáticas aos seus clientes via SMS ou Email com poucos cliques simples',
  'page.marketing.campaigns.birthdaySpecial.title': 'Especial de Aniversário',
  'page.marketing.campaigns.winBackClients.title': 'Ganhar Clientes de Volta',
  'page.marketing.campaigns.welcomeNewClients.title': 'Bem-vindo Novos Clientes',
  'page.marketing.campaigns.rewardRegularClients.title': 'Recompensar clientes regulares',
  'page.marketing.campaigns.birthdaySpecial.description':
    'Surpreender os clientes no seu dia especial, esta campanha envia automaticamente aos clientes próximos do seu aniversário',
  'page.marketing.campaigns.winBackClients.description':
    'Visar clientes desengajados para regressar com uma oferta especial. Esta campanha envia aos clientes que não regressaram após um determinado período de tempo',
  'page.marketing.campaigns.welcomeNewClients.description':
    'Transformar os recém-chegados em regulares, encorajando os clientes de primeira viagem a reservar novamente com um desconto especial. Esta campanha envia automaticamente aos clientes um dia após a sua primeira venda',
  'page.marketing.campaigns.rewardRegularClients.description':
    'Surpreenda os melhores clientes com uma oferta especial, uma certa forma de conseguir os seus melhores clientes ainda mais envolvidos. Esta campanha envia automaticamente aos clientes com base na actividade de marcação recente',
  'page.marketing.campaigns.btn.active': 'Activo',
  'page.marketing.campaigns.btn.inactive': 'Inactivo',
  'page.marketing.campaigns.btn.startCampaign': 'Iniciar Campanha',
  'page.marketing.campaigns.btn.editCampaign': 'Campanha de edição',
  'page.marketing.campaigns.btn.resetCampaign': 'Campanha de reinicialização',
  'modal.resetCampaign.description': 'Tem a certeza de querer reiniciar esta Campanha ?',
  'modal.resetCampaign.btn.yesReset': 'Sim, reiniciar',
  'page.marketing.smsCreate.step.1': 'Configuração de SMS. Passo 1 de 4',
  'page.marketing.smsCreate.step.2': 'Configuração de SMS. Passo 2 de 4',
  'page.marketing.smsCreate.step.3': 'Configuração de SMS. Passo 3 de 4',
  'page.marketing.smsCreate.step.4': 'Configuração de SMS. Passo 4 de 4',
  'page.marketing.emailCreate.step.1': 'Configuração do e-mail. Passo 1 de 4',
  'page.marketing.emailCreate.step.2': 'Configuração do e-mail. Passo 2 de 4',
  'page.marketing.emailCreate.step.3': 'Configuração do e-mail. Passo 3 de 4',
  'page.marketing.emailCreate.step.4': 'Configuração do e-mail. Passo 4 de 4',
  'page.marketing.campaignCreate.step.1': 'Configuração da campanha. Etapa 1 de 3',
  'page.marketing.campaignCreate.step.2': 'Configuração da campanha. Etapa 2 de 3',
  'page.marketing.campaignCreate.step.3': 'Configuração da campanha. Etapa 3 de 3',
  'page.marketing.smsCreate.step.1.title': 'Mensagem e identificação do remetente',
  'page.marketing.smsCreate.step.1.defaultTitleForSms': 'Introduza o ID do remetente do SMS',
  'page.marketing.smsCreate.step.1.defaultMessageForSms': 'Introduza a sua mensagem',
  'page.marketing.emailCreate.step.1.defaultTitleForEmail': 'Introduza o título da mensagem',
  'page.marketing.emailCreate.step.1.defaultMessageForSms': 'Introduza a sua mensagem',
  'page.marketing.emailCreate.step.1.title': 'Definições de e-mail',
  'page.marketing.emailCreate.step.1.messageTitle': 'Título da mensagem',
  'page.marketing.emailCreate.step.1.replyToEmail': 'Resposta ao e-mail',
  'page.marketing.emailCreate.step.1.clientReplies': 'As respostas dos clientes serão enviadas para este e-mail',
  'page.marketing.emailCreate.step.1.photo.title': 'Foto',
  'page.marketing.emailCreate.step.1.enablePhoto': 'Habilitar foto',
  'page.marketing.emailCreate.step.1.changePhoto': 'Mudar foto',
  'page.marketing.emailCreate.step.1.button.title': 'Botão',
  'page.marketing.emailCreate.step.1.enableButton': 'Activar botão',
  'page.marketing.emailCreate.step.1.buttonName': 'Nome do botão',
  'page.marketing.emailCreate.step.1.buttonURL': 'Botão URL',
  'page.marketing.emailCreate.step.1.socialMedia.title': 'Meios de comunicação social',
  'page.marketing.emailCreate.step.1.enableSocialMedia': 'Activar as ligações das redes sociais',
  'page.marketing.emailCreate.step.1.facebookPage': 'Página no Facebook',
  'page.marketing.emailCreate.step.1.instagramPage': 'Página Instagram',
  'page.marketing.emailCreate.step.1.yourPage': 'A sua página',
  'page.marketing.smsCreate.yourMessage': 'A sua mensagem',
  'page.marketing.btn.sendMeTestMessage': 'Envie-me uma mensagem de teste',
  'page.marketing.smsCreate.step.1.freeTests': '{0} testes grátis deixados para hoje',
  'page.marketing.smsCreate.messagePreview': 'Pré-visualização da mensagem',
  'page.marketing.smsCreate.btn.clientSelection': 'Selecção de clientes',
  'page.marketing.audience.title': 'Público',
  'page.marketing.smsCreate.step.2.description': 'Escolha que clientes irão receber a sua mensagem',
  'page.marketing.smsCreate.step.2.allClient.title': 'Todos os Clientes',
  'page.marketing.smsCreate.step.2.allClient.description': 'Envie mensagem a todos os seus clientes',
  'page.marketing.smsCreate.step.2.clientGroups.title': 'Grupos de clientes',
  'page.marketing.smsCreate.step.2.clientGroups.description': 'Enviar mensagem a grupos de clientes seleccionados',
  'page.marketing.smsCreate.step.2.totalClients': 'Total de Clientes',
  'page.marketing.smsCreate.step.2.totalPrice': 'Preço total',
  'page.marketing.smsCreate.step.3.description': 'Escolha a hora a que quer que a sua mensagem seja enviada',
  'page.marketing.smsCreate.step.3.btn.selectDateTime': 'Seleccionar data e hora personalizadas',
  'page.marketing.smsCreate.step.4.title': 'Pagamento por mensagem de massa',
  'page.marketing.smsCreate.step.4.description': 'Escreva os dados do seu cartão de crédito e pague',
  'page.marketing.smsCreate.step.4.paymentAmount': 'Montante do pagamento:',
  'btn.scheduleSend': 'Horário de envio',
  'btn.previous': 'Anterior',
  'btn.payment': 'Pagamento',
  'btn.payAndFinish': 'Pagamento e acabamento',
  'btn.backToDashboard': 'Voltar ao tablier',
  'btn.saveAndClose': 'Guardar e fechar',
  'modal.editClientSelection.title': 'Editar selecção de clientes',
  'modal.newClient.description': 'Os clientes acrescentaram no último:',
  'modal.recentClient.title': 'Cliente recente',
  'modal.recentClient.description': 'Clientes que visitaram no último:',
  'modal.clientByAge.title': 'Cliente por Idade',
  'modal.clientsByGender.title': 'Clientes por Género',
  'modal.paymentConfirmation.title': 'Pagamento bem sucedido !',
  'modal.paymentConfirmation.messageSend': 'A sua mensagem será enviada',
  'modal.previewMessage.title': 'Mensagem de pré-visualização',
  'modal.previewSms.tab': 'Pré-visualização de SMS',
  'modal.previewEmail.tab': 'Pré-visualizar e-mail',
  'modal.editServiceSelection.title': 'Selecção do serviço de edição',
  'modal.editServiceSelection.allServices': 'Todos os serviços',
  'modal.campaignActivated.title': 'Campanha activada !',
  'modal.campaignActivated.description': 'Pode interromper ou editar esta Campanha em qualquer altura',
  'btn.all': 'Todos {0}',
  'btn.included': 'Incluído {0}',
  'btn.excluded': 'Excluídos {0}',
  'checkbox.allClients': 'Todos os clientes {0}',
  'scheduleSend.time': 'hora',
  'scheduleSend.timeSetTo': 'Tempo ajustado para:',
  'scheduleSend.setTime': 'Hora definida',
  'campaignSetup.step.1.header.title': 'Definição da campanha',
  'campaignSetup.step.2.header.title': 'Editar mensagem',
  'campaignSetup.step.3.header.title': 'Revisão',
  'campaignSetup.channel.description': 'Escolha os canais de campanha que serão enviados',
  'campaignSetup.smsSetup.tab': 'Configuração de SMS',
  'campaignSetup.emailSetup.tab': 'Configuração do e-mail',
  'campaignSetup.almostDone.title': 'Quase pronto !',
  'campaignSetup.almostDone.description':
    'A sua campanha inteligente está pronta para ir, uma vez activada, enviará automaticamente mensagens aos clientes numa base contínua. Pode facilmente modificar ou interromper esta campanha em qualquer altura',
  'campaignSetup.approximatePrice.header': 'Preço aproximado',
  'campaignSetup.approximatePrice.description': 'Para os próximos 30 dias',
  'campaignSetup.approximatePrice.approx': 'Aproximadamente',
  'campaign.birthdaySpecial.description': 'Esta campanha envia automaticamente aos clientes perto do seu aniversário',
  'campaign.birthdaySpecial.sendToClient': 'Enviar ao Cliente:',
  'campaign.birthdaySpecial.selectOption.1': 'No dia do aniversário',
  'campaign.birthdaySpecial.selectOption.2': 'Três dias antes do aniversário',
  'campaign.birthdaySpecial.selectOption.3': 'Uma semana antes do aniversário',
  'campaign.birthdaySpecial.selectOption.4': 'Duas semanas antes do aniversário',
  'campaign.winBackClients.description':
    'Esta campanha envia aos clientes que não regressaram após um determinado período de tempo',
  'campaign.winBackClients.clientsWhoDidntReturn': 'Clientes que não regressaram no último',
  'campaign.winBackClients.appliedTo': 'Aplicado a',
  'campaign.winBackClients.all': ' todos os ',
  'campaign.winBackClients.services': 'serviços',
  'campaign.winBackClients.editSelection': 'Editar Selecção',
  'campaign.welcomeNewClients.description':
    'Transformar os recém-chegados em clientes regulares, encorajando os primeiros a reservar novamente com um desconto especial',
  'campaign.welcomeNewClients.smartCampaignNewClients':
    'Esta campanha inteligente envia automaticamente a novos clientes um dia após a sua primeira venda',
  'campaign.rewardRegularClients.description':
    'Esta campanha envia automaticamente aos clientes com base na actividade de marcação recente',
  'campaign.rewardRegularClients.clientsWithAtLeast': 'Clientes com pelo menos',
  'campaign.rewardRegularClients.appointmentsInThe': 'nomeações no',
  'campaign.rewardRegularClients.last': 'último',
  'campaign.rewardRegularClients.appointments': 'nomeações',
  'campaign.rewardRegularClients.inTheLast': 'no último',
  'campaign.rewardRegularClients.months': 'meses',
  'subscriptionPaymentFailed.title':
    'Atualize seu método de pagamento até {0}, caso contrário, todos os recursos premium serão descontinuados, obrigado.',
  'subscriptionCancelled.title':
    'Pedimos desculpas, mas seu Premium foi desativado por falta de pagamento. Se você deseja continuar usando os recursos premium, ative o Plano Premium, obrigado.',
  'notificationPaymentFailed.title':
    'O pagamento das notificações falhou. Conclua o pagamento até {0}, caso contrário as notificações serão desativadas, obrigado.',
  'notificationCancelled.title':
    'Pedimos desculpas, mas suas notificações foram desativadas por falta de pagamento. Se desejar continuar enviando notificações, efetue o pagamento, obrigado.',
  'btn.hideOptions': 'Ocultar opções',
  'btn.showOptions': 'Mostrar opções',
  'notification.googleDisconnected': 'Calendário Google desligado',
  'notification.googleConnected': 'Calendário Google ligado',
  'notification.appleDisconnected': 'Calendário da Apple desligado',
  'notification.appleConnected': 'Calendário da Apple ligado',
  'notification.microsoft365Disconnected': 'Calendário Microsoft365 desligado',
  'notification.microsoft365Connected': 'Calendário Microsoft365 ligado',
  'notification.linkCopied': 'Ligação copiada',
  'calendarSync.title': 'Sincronização de calendários',
  'calendarSync.description':
    'Sincronize os compromissos do Plandok com o seu calendário favorito para uma organização sem esforço e nunca perca um evento importante.',
  'googleCalendar.title': 'Calendário do Google',
  'appleCalendar.title': 'Calendário da Apple',
  'microsoft365Calendar.title': 'Calendário Microsoft365',
  'linkYourCalendar.title': 'Vincule seus calendários',
  'connect.btn': 'Conectar',
  'disconnect.rtn': 'Desconectar',
  'connected.label': 'Ligado',
  'modal.workingHours.standartMethod.btn': 'Método padrão',
  'modal.workingHours.customMethod.btn': 'Método personalizado',
  'modal.workingHours.workingDates.input': 'Datas de trabalho',
  'modal.workingHours.selectDates.placeholder': 'Selecionar datas',
  'noInternetConnection.notification.title': 'Sem ligação à Internet',
  'app.update.title': 'Nova versão disponível',
  'app.update.description.title':
    'Adicionámos novas funcionalidades e corrigimos alguns erros para tornar a sua experiência com o Plandok o mais agradável possível.',
  'app.update.button.title': 'Atualizar',
  'app.notNow.button.title': 'Agora não',
  'app.updateAvailable.button.title': 'Nova versão disponível',
  'upgradePlan.success.message': 'A subscrição foi actualizada com êxito',
  'upgradePlan.error.message':
    'Erro de atualização da subscrição, contacte o suporte se o seu cartão for debitado e a subscrição não for actualizada',
  'sidebar.inventory': 'Inventário',
  'products.tab': 'Produtos',
  'suppliers.tab': 'Fornecedores',
  'stockOrders.tab': 'Ordens de stock',
  'stockTracking.tab': 'Acompanhamento de stocks',
  'input.productsSearch.placeholder': 'Pesquisa por nome de produto ou código de barras',
  'product.create.btn.title': 'Adicionar novo produto',
  'table.products.product': 'Produtos',
  'table.products.barcode': 'Código de barras',
  'table.products.brand': 'Marca',
  'table.products.sale': 'Venda',
  'table.products.saleOpt': 'Opç. de venda',
  'saleOptions.tooltip.title': 'Opções de venda',
  'saleOptions.tooltip.description': 'Pode optar por ativar/desativar a venda durante o checkout.',
  'seeMore.title': 'ver mais',
  'option.products.downloadCSV': 'Descarregar CSV',
  'option.products.downloadExcel': 'Descarregar Excel',
  'option.products.manageBrands': 'Gerir marcas',
  'option.products.manageCategories': 'Gerir categorias',
  'option.products.manageStock': 'Gerir o stock',
  'option.products.importProducts': 'Importar produtos',
  'modal.filter.categories.title': 'Categorias',
  'modal.filter.brands.title': 'Marcas',
  'modal.filter.stock.title': 'Stock',
  'modal.filter.allCategories.selectItem': 'Todas as categorias',
  'modal.filter.allBrands.selectItem': 'Todas as marcas',
  'modal.filter.allSuppliers.selectItem': 'Todos os fornecedores',
  'modal.filter.allProducts.selectItem': 'Todos os produtos',
  'input.suppliersSearch.placeholder': 'Pesquisar por nome de fornecedor',
  'supplier.create.btn.title': 'Adicionar novo fornecedor',
  'table.suppliers.supplierName': 'Nome do fornecedor',
  'table.suppliers.phoneNumber': 'Número de telefone',
  'table.suppliers.physicalAddress': 'Endereço físico',
  'validation.notification.reminderTime': 'Não é possível enviar notificações com o mesmo horário do lembrete.',
  'validation.paymentType.switches': 'Pelo menos um método de pagamento deve ser permitido',
  'plan.premium.option.14':
    '0% de taxa de comissão Plandok para agendamentos agendados através de reservas on-line usando cartão de crédito.',
  'title.dragAndExtend.appointment.permission': 'Pode arrastar e estender compromissos',
  'button.lastVisit.addAnotherLastvisit': 'ADICIONAR OUTRA ÚLTIMA VISITA',
  'notifications.template.badge.validate': 'Validar',
  'notifications.template.badge.refused': 'Recusou',
  'paid.by.card': 'Pago com cartão',
  'costs.manual.paid.link': 'Ou você pode pagar suas notificações manualmente com seu cartão, clicando neste link',
  'modal.remove.account.title': 'Remover conta Stripe',
  'modal.remove.account.description': 'Sua conta será removida e você não poderá aceitar pagamentos online. ',
  'ob.remove.account.option': 'Remover conta Stripe',
  'btn.remove.account': 'Remover',
};

export default labels;
