import { Checkbox } from 'antd';
import React from 'react';
import * as SC from '../styles';

export default function CheckboxFieldList(props: any) {
  if (!props.options || !props.options.length) {
    return null;
  }

  return props.options.map((e: any) => {
    const isSelectedThree = (props.value || []).some((w: string) => w === e.value?.code);
    const isSelected = (props.value || []).some((w: string) => w === e.code);

    return (
      <div key={props.three ? e.value.code : e.code}>
        {props.three ? (
          <SC.WrapperCheckbox>
            <Checkbox checked={isSelectedThree} onChange={props.toggleSelect(isSelectedThree)(e.value.code)}>
              {e.value.name}
            </Checkbox>
          </SC.WrapperCheckbox>
        ) : (
          <SC.SelectItem>
            <Checkbox checked={isSelected} onChange={props.toggleSelect(isSelected)(e.code)}>
              {e.name}
            </Checkbox>
          </SC.SelectItem>
        )}
      </div>
    );
  });
}
