import React, { useEffect, useState } from 'react';
import { AppLayout, AppTable, NoInternetConnection } from 'components';
import useGetHasPermission from 'hooks/permission/useGetHasPermission';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { PermissionType } from 'constants/auth';
import { RoutePath } from 'constants/routes';
import { useLocation } from 'hooks/router';
import * as api from 'api';
import MergeDuplicateClients from './components/MergeDuplicateClients';
import ClientsMobileHeader from './components/ClientsMobileHeader';
import MobileClientRow from './components/MobileClientRow';
import ClientFilter from './components/ClientFilter';
import { clientColumns } from './config';

export default function ClientsPage() {
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [query, setQuery] = useState('');

  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const getHasPermission = useGetHasPermission();
  const { navigate } = useLocation();

  const createClient = () => navigate(RoutePath.CLIENT_CREATE);

  const checkDuplicated = async () => {
    try {
      const { status } = await api.checkDuplicatedClients();
      setHasDuplicates(status);
    } catch (e) {
      console.error('Error when getting duplicate clients');
      return;
    }
  };

  useEffect(() => {
    checkDuplicated();
  }, []);

  const viewClientDetails = (id: string) => {
    if (getHasPermission(PermissionType.CAN_SEE_CLIENT_INFO)) {
      return navigate(`${RoutePath.CLIENT_DETAILS}/${id}`);
    }
  };

  return (
    <NoInternetConnection>
      <AppLayout
        headerTitle="sidebar.client"
        mobileHeader={ClientsMobileHeader}
        addButtonPermission={PermissionType.CAN_SEE_CLIENT_INFO}
        addButtonPath={RoutePath.CLIENT_CREATE}
        addBtnClassName="client-page-add-btn"
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <AppTable
          type="clients"
          columns={clientColumns}
          searchStr={query}
          viewDetails={viewClientDetails}
          mobileRowComponent={MobileClientRow}
          mobileClassName="app_clients-list--mobile"
          apiConfig={{
            fetchData: api.fetchClients,
            mapping: {
              pageKey: 'page',
              dataKey: 'clients',
              totalCountKey: 'totalCount',
              searchKey: 'query',
              emptyStateKey: 'meta.emptyState',
            },
          }}
          placeholder={{
            imgName: 'clients',
            titleLabel: 'state.clients.title',
            descriptionLabel: 'state.clients.description',
            btnLabel: 'state.clients.button',
            onClick: createClient,
          }}
        >
          <ClientFilter setQuery={setQuery} query={query} />
          {hasDuplicates && <MergeDuplicateClients checkAction={checkDuplicated} />}
        </AppTable>
      </AppLayout>
    </NoInternetConnection>
  );
}
