import { Elements } from '@stripe/react-stripe-js';
import upperFirst from 'lodash/upperFirst';
import React, { useState } from 'react';
import { Spin } from 'antd';
import cn from 'classnames';
import { Text, Button, useLocation, CustomIcon, getIsMobile } from '@plandok/core';
import { IntlTemplate } from '@plandok/i18n';
import useStripeElement from 'hooks/use-stripe-element';
import { RoutePath } from 'constants/routes';
import useQuery from 'hooks/data/useQuery';
import * as api from 'api';
import PaymentMethodDropdown from './components/PaymentMethodDropdown';
import { getCardIconByBrand } from '../PricingPage/helpers';
import PaymentCardForm from './components/PaymentCardForm';
import * as SC from './styles';

type Card = {
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
};

export default function PaymentMethodPage() {
  const { data, isLoading, fetchData }: any = useQuery({
    fetchData: api.fetchPaymentMethods,
    extractDataFunc: (data: any) => data,
  });

  const [isOpenAddMethod, setIsOpenAddMethod] = useState(false);

  const { isStripeLoading, stripePromise } = useStripeElement();
  const { navigate } = useLocation();
  const isMobile = getIsMobile();

  const formatCardExpMonth = (expMonth: number) => (expMonth < 10 ? `0${expMonth}` : expMonth);
  const formatCardDate = (card: Card) => `${formatCardExpMonth(card.exp_month)}/${card.exp_year}`;
  const goToPlanPage = () => navigate(RoutePath.BILLING);

  const card = data?.meta.default?.card;
  const brand = card?.brand;

  return (
    <SC.PaymentMethodPageContainer>
      <Spin spinning={isStripeLoading || isLoading}>
        {data?.data.length || data?.meta.default ? (
          <SC.PaymentMethodCard>
            <Text
              label="paymentMethod.paymentMethod.title"
              mb={isMobile ? 'xmedium' : 'large'}
              size="mlarge"
              weight="semiBold"
            />
            {data?.meta?.default && (
              <>
                <Text
                  label="paymentMethod.currentPaymentMethod.title"
                  mb={isMobile ? 'small' : 'xmedium'}
                  size="medium"
                  weight="semiBold"
                  lh="mlarge"
                />
                <SC.CurrentPaymentMethodContainer>
                  {isMobile ? (
                    <div className="align-center">
                      <CustomIcon type={getCardIconByBrand(brand)} />
                      <div>
                        <Text mb="none" ml="xsmall" size="base" lh="small">
                          <IntlTemplate label="{0} •••• {0}" params={[upperFirst(brand), card.last4]} />
                        </Text>
                        <Text mb="none" size="small" lh="small" colorType="lightBlue" ml="xsmall">
                          <IntlTemplate label="paymentMethod.expirationDate" params={[formatCardDate(card)]} />
                        </Text>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="align-center">
                        <CustomIcon type={getCardIconByBrand(brand)} />
                        <Text mb="none" ml="xsmall" size="base" lh="small">
                          <IntlTemplate label="{0} •••• {0}" params={[upperFirst(brand), card.last4]} />
                        </Text>
                      </div>
                      <Text mb="none" size="base" lh="small" colorType="lightBlue">
                        <IntlTemplate label="paymentMethod.expirationDate" params={[formatCardDate(card)]} />
                      </Text>
                    </>
                  )}
                </SC.CurrentPaymentMethodContainer>
              </>
            )}

            {!!data.data.length && (
              <SC.OtherPaymentMethodsContainer>
                <Text
                  label="paymentMethod.otherPaymentMethods.title"
                  mb={isMobile ? 'small' : 'xmedium'}
                  size="medium"
                  weight="semiBold"
                  lh="mlarge"
                />
                {data.data.map((paymentMethod: any) => (
                  <SC.OtherPaymentMethodContainer key={paymentMethod.id}>
                    {isMobile ? (
                      <div className={cn('justify-align', 'w-100')}>
                        <div className="align-center">
                          <CustomIcon type={getCardIconByBrand(paymentMethod.card.brand)} />
                          <div>
                            <Text mb="none" ml="xsmall" size="base" lh="small">
                              <IntlTemplate
                                label="{0} •••• {0}"
                                params={[upperFirst(paymentMethod.card.brand), paymentMethod.card.last4]}
                              />
                            </Text>
                            <Text mb="none" size="small" lh="small" colorType="lightBlue" ml="xsmall">
                              <IntlTemplate
                                label="paymentMethod.expirationDate"
                                params={[formatCardDate(paymentMethod.card)]}
                              />
                            </Text>
                          </div>
                        </div>
                        <PaymentMethodDropdown paymentMethod={paymentMethod.id} fetchData={fetchData} />
                      </div>
                    ) : (
                      <>
                        <div className="align-center">
                          <CustomIcon type={getCardIconByBrand(paymentMethod.card.brand)} />
                          <Text mb="none" ml="xsmall" size="base" lh="small">
                            <IntlTemplate
                              label="{0} •••• {0}"
                              params={[upperFirst(paymentMethod.card.brand), paymentMethod.card.last4]}
                            />
                          </Text>
                        </div>

                        <div className="align-center">
                          <Text mb="none" size="base" lh="small" colorType="lightBlue">
                            <IntlTemplate
                              label="paymentMethod.expirationDate"
                              params={[formatCardDate(paymentMethod.card)]}
                            />
                          </Text>
                          <PaymentMethodDropdown paymentMethod={paymentMethod.id} fetchData={fetchData} />
                        </div>
                      </>
                    )}
                  </SC.OtherPaymentMethodContainer>
                ))}
              </SC.OtherPaymentMethodsContainer>
            )}

            <SC.AddPaymentMethod onClick={() => setIsOpenAddMethod((isOpenAddMethod) => !isOpenAddMethod)}>
              <CustomIcon type={isOpenAddMethod ? 'openPaymentMethod' : 'closePaymentMethod'} />
              <Text
                label="paymentMethod.addAnotherPaymentMethod.btn"
                mb="none"
                size="small"
                weight="medium"
                lh="base"
                ml="xsmall"
              />
            </SC.AddPaymentMethod>

            {isOpenAddMethod && (
              <div style={{ paddingTop: '24px' }}>
                <Text
                  label="paymentMethod.addPaymentMethod.title"
                  mb="medium"
                  size="medium"
                  weight="semiBold"
                  lh="mlarge"
                />
                {stripePromise && (
                  <Elements stripe={stripePromise}>
                    <PaymentCardForm fetchData={fetchData} setIsOpenAddMethod={setIsOpenAddMethod} />
                  </Elements>
                )}
              </div>
            )}
          </SC.PaymentMethodCard>
        ) : (
          <SC.NoPaymentMethodContainer>
            <CustomIcon type="noPayment" />
            <Text label="paymentMethod.noPaymentMethodAdded.title" weight="medium" size="mlarge" mb="xsmall" />
            <Text label="paymentMethod.noPaymentMethodAdded.description" size="base" lh="small" />
            <Button
              type="primary"
              label="paymentMethod.goToPlanPage.btn"
              onClick={goToPlanPage}
              upperCase={false}
              semiBold
            />
          </SC.NoPaymentMethodContainer>
        )}
      </Spin>
    </SC.PaymentMethodPageContainer>
  );
}
