import styled, { css } from 'styled-components';
import { mediaMdUp } from '@plandok/core';

export const Container = styled.div`
  background: white;
  margin: 60px -15px;
  overflow-x: scroll;
  padding: 0;
  box-shadow: 0 3px 25px rgba(19, 49, 109, 0.08);
  ${mediaMdUp(
    css`
      display: none;
    `
  )}
`;

export const StyledRowContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1rem;
  color: #13316d;
  font-size: 16px;
  font-weight: 500;
`;

export const SpinnerContainer = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 20px;
  color: ${({ theme }) => theme.primaryColor};
`;

export const Item = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  :not(:first-of-type) {
    border-top: 1px solid #e9ecf3;
  }
  :hover {
    background: #f8f9fc;
  }
`;
