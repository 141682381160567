import React from 'react';
import { AuthLayout, NoInternetConnection } from 'components';
import { RoutePath } from 'constants/routes';
import * as api from 'api';
import { useOnLogin } from '../RegistrationPage/useOnLogin';
import LoginFooter from './components/LoginFooter';
import LoginForm from './components/LoginForm';

function LoginPage() {
  const onLogin = useOnLogin('notification.success.login');

  return (
    <NoInternetConnection>
      <AuthLayout title="login.title" mobileDescription="login.description" page={RoutePath.LOGIN}>
        <AuthLayout.Card>
          <LoginForm onSubmit={api.login} onSuccess={onLogin} />
        </AuthLayout.Card>
        <br />
        <LoginFooter />
      </AuthLayout>
    </NoInternetConnection>
  );
}

export default LoginPage;
