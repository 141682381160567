import React from 'react';

import { IntlLabel, IntlDate } from '@plandok/i18n';
import { parseDateFieldWithTime } from '../../../../../helpers/date/field';
import { RoutePath } from '../../../../../constants/routes';

export const campaignsCardData = [
  {
    icon: 'present',
    headerText: 'Birthday Special',
    url: RoutePath.BIRTHDAY_CAMPAIGN_SETTING,
    description:
      'Surprise clients on their special day, this campaign automatically sends to clients close to their birthday.',
  },
  {
    icon: 'clientAttract',
    headerText: 'Win Back Clients',
    url: RoutePath.WIN_BACK_CLIENTS_SETTING,
    description:
      'Target disengaged clients to return with a special offer. This campaign sends to clients who did not return after a certain period of time.',
  },
  {
    icon: 'handshake',
    headerText: 'Welcome New Clients',
    url: RoutePath.WELCOME_NEW_CLIENTS_SETTING,
    description:
      'Turn newcomers into regulars by encouraging first-time clients to book again with a special discount. This campaign automatically sends to clients one day after their very first sale',
  },
  {
    icon: 'clientReward',
    headerText: 'Reward Regular Clients',
    url: RoutePath.REWARD_REGULAR_CLIENTS_SETTING,
    description:
      'Surprise top clients with a special offer, a certain way to get your best clients even more engaged. This campaign automatically sends to clients based on recent appointment activity.',
  },
];

export const mobileCampaignsCardData = [
  {
    icon: 'smallPresent',
    headerText: 'Birthday Special',
    url: RoutePath.BIRTHDAY_CAMPAIGN_SETTING,
    description:
      'Surprise clients on their special day, this campaign automatically sends to clients close to their birthday.',
  },
  {
    icon: 'smallClientAttract',
    headerText: 'Win Back Clients',
    url: RoutePath.WIN_BACK_CLIENTS_SETTING,
    description:
      'Target disengaged clients to return with a special offer. This campaign sends to clients who did not return after a certain period of time.',
  },
  {
    icon: 'smallHandshake',
    headerText: 'Welcome New Clients',
    url: RoutePath.WELCOME_NEW_CLIENTS_SETTING,
    description:
      'Turn newcomers into regulars by encouraging first-time clients to book again with a special discount. This campaign automatically sends to clients one day after their very first sale',
  },
  {
    icon: 'smallClientReward',
    headerText: 'Reward Regular Clients',
    url: RoutePath.REWARD_REGULAR_CLIENTS_SETTING,
    description:
      'Surprise top clients with a special offer, a certain way to get your best clients even more engaged. This campaign automatically sends to clients based on recent appointment activity.',
  },
];

export const messageLogColumns = [
  {
    title: <IntlLabel label="table.header.message" />,
    width: '200px',
    key: 'message',
    // render: (value: any) => {
    //   return (
    //     <span className="cursor-pointer text-underline color-link">
    //       {value.message?.substring?.(0, 40)}
    //       {value.message.length > 40 ? '...' : ''}
    //     </span>
    //   );
    // },
  },
  {
    title: <IntlLabel label="Send on" />,
    width: '180px',
    render: (value: any) => {
      const date = parseDateFieldWithTime(value.createdAt);
      return <span>{date && <IntlDate date={date} dateFormat="dd MMM yyyy, HH:mm" />}</span>;
    },
  },
  {
    title: <IntlLabel label="Sent to" />,
    dataIndex: 'sendTo',
    key: 'sendTo',
  },
  {
    title: <IntlLabel label="Channel" />,
    dataIndex: 'Channel',
    key: 'Channel',
  },
  {
    title: <IntlLabel label="Price" />,
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: <IntlLabel label="table.header.status" />,
    key: 'status',
    dataIndex: 'status',
  },
];
