import React, { useEffect, useState } from 'react';
import { Row, Spin } from 'antd';
import get from 'lodash/get';
import { getIsMobile } from '@plandok/core';
import calendarState from 'pages/dashboard/CalendarPage/store/calendar.state';
import { getTimeOptions } from '../../../ModifyAppointmentPage/config/options';
import { Field } from 'components/form';

export const weekDays = [
  { name: 'monday', shortLabel: 'calendar.mon', label: 'calendar.monday' },
  { name: 'tuesday', shortLabel: 'calendar.tue', label: 'calendar.tuesday' },
  { name: 'wednesday', shortLabel: 'calendar.wed', label: 'calendar.wednesday' },
  { name: 'thursday', shortLabel: 'calendar.thu', label: 'calendar.thursday' },
  { name: 'friday', shortLabel: 'calendar.fri', label: 'calendar.friday' },
  { name: 'saturday', shortLabel: 'calendar.sat', label: 'calendar.saturday' },
  { name: 'sunday', shortLabel: 'calendar.sun', label: 'calendar.sunday' },
];

export default function WorkingHoursStep({ values }: any) {
  const [timeFormat, setTimeFormat] = useState('');

  const isMobile = getIsMobile();

  useEffect(() => {
    calendarState.initialFetchData().then(() => {
      const { timeFormat } = calendarState.data ?? {};

      setTimeFormat(timeFormat);
    });
  }, []);

  const options = getTimeOptions(timeFormat);

  const SELECT_DEFAULT_VALUES = {
    opensAt: 480,
    closeAt: 1020,
  };

  return (
    <Spin
      spinning={false}
      // spinning={!timeFormat}
    >
      {weekDays.map(({ name, label, shortLabel }) => {
        const weekKey = isMobile ? shortLabel : label;
        const disabled = !get(values, `workingHours.${name}.open`);

        return (
          <Row key={name} type="flex" gutter={isMobile ? 20 : 60} align="middle">
            <Field.Checkbox
              name={`workingHours.${name}.open`}
              textKey={weekKey}
              type="checkbox"
              span={8}
              formClassName="booking-flow_checkbox__mobile"
            />
            <Field.Select
              name={`workingHours.${name}.opensAt`}
              label="ob.hours.open"
              span={8}
              options={options}
              disabled={disabled}
              defaultValue={SELECT_DEFAULT_VALUES.opensAt}
            />
            <Field.Select
              name={`workingHours.${name}.closesAt`}
              label="ob.hours.close"
              span={8}
              options={options}
              disabled={disabled}
              defaultValue={SELECT_DEFAULT_VALUES.closeAt}
            />
          </Row>
        );
      })}
    </Spin>
  );
}
