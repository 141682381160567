import FormBlock, { FormBlockProps } from './index';
import React from 'react';

export default function withFormBlock<T = any>(Component: any, itemProps?: any) {
  return (props: FormBlockProps & T & { noFormBlock?: boolean }) => {
    if (props.noFormBlock) {
      return <Component {...props} />;
    }

    return <FormBlock component={Component} itemProps={itemProps} {...props} />;
  };
}
