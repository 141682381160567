import React from 'react';
import { IconButton, useModal } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { MobileBackButton } from 'components';
import { RoutePath } from 'constants/routes';

export default function ClientDetailsMobileHeader({ id, phoneNumber }: any) {
  const [, { showModal }] = useModal();
  const openClientDetailsMenu = () => showModal({ type: 'CLIENT_DETAILS_MENU', modalProps: { id, phoneNumber } });

  return (
    <>
      <MobileBackButton path={RoutePath.CLIENTS} />
      <div>
        <IntlLabel label="client.page.title" />
      </div>
      <div>
        <IconButton icon="dots" onClick={openClientDetailsMenu} />
      </div>
    </>
  );
}
