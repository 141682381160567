import { flow, noop, pick } from 'lodash';
import React from 'react';
import {
  composeValidators,
  validateEmail,
  validateRequired,
  withModal,
  Button,
  Text,
  Form,
  Field,
} from '@plandok/core';
import * as api from 'api';
import * as SC from '../styles';

function SendTestMessageForEmailModal({ modalProps, onClose }: any) {
  return (
    <Form
      onSubmit={async (form: { email: string }) => {
        await modalProps?.onSubmit(modalProps?.values);
        return await api.sendTestNotification({ ...pick(modalProps, 'channel', 'notificationType'), ...form });
      }}
      onSuccess={flow(onClose, modalProps?.onSuccess || noop)}
    >
      {({ submitting }: any) => (
        <SC.ContainerSendTestMessage>
          <Text label="modal.sendTestMessageByEmail.title" size="medium" mb="xmedium" lh="mlarge" />
          <Field.Input
            name="email"
            placeholder="modal.sendTestMessageByEmail.placeholder"
            validate={composeValidators(validateRequired, validateEmail)}
          />
          <Button type="primary" label="btn.send" htmlType="submit" loading={submitting} upperCase={false} />
        </SC.ContainerSendTestMessage>
      )}
    </Form>
  );
}

export default withModal(null, { contentClassName: 'send-test-message-modal' })(SendTestMessageForEmailModal as any);
