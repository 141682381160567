import { Modal as AntModal } from 'antd';
import React, { ReactNode } from 'react';
import CustomIcon from '../CustomIcon';

interface ModalProps {
  title: ReactNode;
  children: ReactNode;
  visible: boolean;
  className?: string;
  noClose?: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function Modal(props: ModalProps & any) {
  return (
    <AntModal
      title={props.title}
      visible={props.visible}
      footer={null}
      closeIcon={<CustomIcon type="close" />}
      onCancel={props.onClose}
      onOk={props.onConfirm}
      className={props.className}
      closable={!props.noClose}
      {...props}
    >
      {props.children}
    </AntModal>
  );
}
