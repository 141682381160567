export const openCrisp = () => {
  if (!(window as any).$crisp) {
    setTimeout(() => {
      (window as any).$crisp?.push(['do', 'chat:open']);
      (window as any).$crisp?.push(['do', 'chat:show']);
    }, 5000);
  }
  (window as any).$crisp?.push(['do', 'chat:open']);
  (window as any).$crisp?.push(['do', 'chat:show']);
};
