import { TimeCardData } from '../types';

export const extractBlockTimeValues = (timeCard: TimeCardData) => ({
  employeeId: timeCard.employeeId,
  date: timeCard.date,
  startTime: timeCard.startTime,
  endTime: timeCard.endTime,
  description: timeCard.description,
});

export const concatString = (arr?: string[]): string => arr?.filter((item) => item).join(', ') ?? '';

export const roundDown = (number: number) => Math.floor(number / 60) * 60;
export const roundUp = (number: number) => Math.ceil(number / 60) * 60;
