import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import subDays from 'date-fns/subDays';
import { DatePicker as ReactDatePicker, getIsMobile } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { RoutePath } from 'constants/routes';
import { useLocation } from 'hooks/router';
import { useStore } from 'store/store';
import { DatePickerContainerContent } from './DatePickerContainer';
import { DatePickerCustomInput } from './DatePickerCustomInput';
import * as SC from './styles';

type TimeRangeData = {
  timeRange: Date;
  startDate: Date | null;
  endDate: Date | null;
};

type DatePickerContainerProps = {
  className: string;
  date: Date;
  children: ReactNode[];
  onChange(date: Date, label: string, isCustom: boolean): void;
};

type MonthRange = {
  startDate: Date;
  endDate: Date;
};

type TimeRangeOption = {
  label: string;
  days: number;
  isStepByDay: boolean;
  isEnable: boolean;
  monthRange?: MonthRange;
};

type DatePickerProps = {
  isPremium: boolean;
  isStepByDay: boolean;
  timeRangeOptions: TimeRangeOption[];
  isRounded?: boolean;
  isGrid?: boolean;
  hasCustomPlacement?: boolean;
  className?: string;
  refetchData?: () => void;
  setIsStepByDay: (value: boolean) => void;
  setTimeRangeData: (value: TimeRangeData) => void;
  setPage?: (page: number) => void;
};

const DatePicker = ({
  isPremium,
  isStepByDay,
  isRounded,
  isGrid,
  hasCustomPlacement,
  className,
  timeRangeOptions,
  refetchData,
  setTimeRangeData,
  setIsStepByDay,
  setPage,
}: DatePickerProps) => {
  const { navigate } = useLocation();

  const [selectedRangeLabel, setSelectedRangeLabel] = useState('');
  const [isOpenTimeRange, setIsOpenTimeRange] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(new Date());

  const setStartDate = useStore(useCallback((state) => state.setStartDate, []));
  const setEndDate = useStore(useCallback((state) => state.setEndDate, []));
  const startDate = useStore(useCallback((state) => state.startDate, []));
  const endDate = useStore(useCallback((state) => state.endDate, []));
  const setIsTimeRangeSelected = useStore(useCallback((state) => state.setIsTimeRangeSelected, []));
  const isTimeRangeSelected = useStore(useCallback((state) => state.isTimeRangeSelected, []));

  const handleCloseCalendar = () => setIsOpen(false);
  const handleOpenCalendar = () => setIsOpen(true);
  const handleToggleTimeRange = useCallback(() => setIsOpenTimeRange(!isOpenTimeRange), [isOpenTimeRange]);
  const handleNavigateToPricing = () => navigate(RoutePath.BILLING);

  const onChange = (
    timeRange: Date | any,
    label: string,
    isStepByDay: boolean,
    isTimeRange: boolean,
    endDate?: Date | null
  ) => {
    setTimeRangeData({
      timeRange,
      startDate: isTimeRange ? timeRange?.startDate || null : startDate,
      endDate: isTimeRange ? timeRange?.endDate || null : endDate,
    });
    setIsTimeRangeSelected(true);
    setIsStepByDay(isStepByDay);
    setDate(date);
    handleOnTimeRangeSelect(isTimeRange);
    setSelectedRangeLabel(label);
    setPage?.(1);
    refetchData?.();
  };

  const handleCustomRange = (selectedDate: Date | null) => {
    switch (true) {
      case !startDate:
        setStartDate(selectedDate);
        break;
      case startDate && !endDate:
        setEndDate(selectedDate);
        break;
      default:
        setStartDate(selectedDate);
        setEndDate(null);
        break;
    }

    onChange(date, selectedRangeLabel, isStepByDay, false, selectedDate);
  };

  const handleOnTimeRangeSelect = (isTimeRange: boolean | undefined) => {
    if (isTimeRange) {
      setEndDate(null);
      setStartDate(null);
      handleCloseCalendar();
    }
  };

  const renderTimeRangeOptions: JSX.Element[] = timeRangeOptions.map((option) => (
    <SC.TimeRangeButton
      key={option.label}
      upperCase={false}
      isDisabled={!option.isEnable}
      onClick={() =>
        onChange(option.monthRange || subDays(new Date(), option.days), option.label, option.isStepByDay, true)
      }
    >
      <IntlLabel label={option.label} />
    </SC.TimeRangeButton>
  ));

  const DatePickerContainer = ({ className, children }: DatePickerContainerProps): JSX.Element => (
    <div className={className}>
      <DatePickerContainerContent
        isMobile={getIsMobile()}
        isPremium={isPremium}
        children={children}
        isOpenTimeRange={isOpenTimeRange}
        timeRangeOptions={renderTimeRangeOptions}
        handleUpgradeAction={handleNavigateToPricing}
        handleSkipAction={handleCloseCalendar}
        handleToggleAction={handleToggleTimeRange}
      />
    </div>
  );

  useEffect(() => {
    if (startDate && endDate) {
      handleCloseCalendar();
      setIsStepByDay(false);
    }
  }, [startDate, setIsStepByDay, endDate, setTimeRangeData]);

  useEffect(() => {
    const REFETCH_DATA_INTERVAL = 100;

    setTimeout(() => {
      if (refetchData) {
        refetchData();
      }
    }, REFETCH_DATA_INTERVAL);
  }, [refetchData]);

  useEffect(() => {
    setIsTimeRangeSelected(false);
  }, [setIsTimeRangeSelected]);

  return (
    <SC.DatePicker isGrid={isGrid} hasCustomPlacement={hasCustomPlacement}>
      <ReactDatePicker
        selectsRange
        startDate={startDate}
        endDate={endDate}
        open={isOpen}
        wrapperClassName={className}
        shouldCloseOnSelect={!isOpen}
        onInputClick={handleOpenCalendar}
        onCalendarOpen={handleOpenCalendar}
        onClickOutside={handleCloseCalendar}
        calendarContainer={DatePickerContainer}
        withPortal={getIsMobile() && isOpen}
        onChange={(selectedDate: Date) => handleCustomRange(selectedDate)}
        customInput={
          <div>
            <DatePickerCustomInput
              isTimeRangeSelected={isTimeRangeSelected}
              isPremium={isPremium}
              isRounded={isRounded}
              selectedRangeLabel={selectedRangeLabel}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        }
      />
    </SC.DatePicker>
  );
};

export default DatePicker;
