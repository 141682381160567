import { IntlLabel, IntlTemplate } from '@plandok/i18n';
import { getIsMobile } from '@plandok/core';
import { Progress } from 'antd';
import React from 'react';

import * as SC from '../../styles';

export default function BookingFlowHeader({ title, description, step, nextStepTitle }: any) {
  const isMobile = getIsMobile();
  const percent = 25 * step;

  const nextStep = nextStepTitle ? (
    <div data-bf-top-next>
      <IntlLabel label="text.next" />: <IntlLabel label={nextStepTitle} />
    </div>
  ) : (
    <div />
  );

  return (
    <SC.HeaderContent>
      <div data-bf-progress>
        <Progress
          type="circle"
          percent={percent}
          width={isMobile ? 80 : 100}
          strokeWidth={10}
          format={() => <IntlTemplate label="ob.profile.step" params={[step]} />}
          strokeColor={{
            '0%': '#2D954B',
            '100%': '#2D954B',
          }}
        />
      </div>
      <div className="w-100">
        <div>
          <div>
            <div data-bf-top-title>
              <h2>
                <IntlLabel label={title} />
              </h2>
              {nextStep}
            </div>
            <p data-bf-top-description>
              <IntlLabel label={description} />
            </p>
          </div>
          <div />
        </div>
      </div>
    </SC.HeaderContent>
  );
}
