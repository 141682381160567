import styled, { css } from 'styled-components';
import { mediaMdUp } from '@plandok/core';

export const Container = styled.div<any>`
  display: flex;
  text-align: center;
  align-items: center;
  color: ${(props) => props.theme.primaryColor};
  font-weight: 500;
  cursor: pointer;

  span:first-of-type i {
    color: #adb6cc;
    font-size: 24px;
    margin-right: 10px;
  }

  span:last-of-type i {
    margin-left: auto;
    font-size: 12px;
  }

  ${mediaMdUp(css`
    font-size: 20px;
    margin: 0 auto;
    span:last-of-type i {
      margin-left: 10px;
    }
  `)}
` as any;
