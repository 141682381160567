export const columnsConfig = [
  {
    title: 'Discount type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: 'Date added',
    dataIndex: 'date',
    key: 'date',
  },
];

export const fakeData = [...Array(7)].map((_, i) => ({
  id: i,
  type: `Discount ${i}`,
  value: '30% off',
  date: 'Tuesday, 5 Feb 2019',
}));
