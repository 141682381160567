import { message } from 'antd';
import React from 'react';
import { Text, Button, withModal, Mutation } from '@plandok/core';
import * as SC from '../styles';
import * as api from '../../../../api';

type ChangeStripeAccountProps = {
  onClose: () => void;
};

function ChangeStripeAccountModal({ onClose }: ChangeStripeAccountProps) {
  const onSuccess = () => {};

  const actionHandle = async () => {
    const response = await api.onboardPaymentType('stripe_payment');

    if (response?.error) {
      console.log(response.error?.message);
      message.error(response.error?.message);

      return;
    }

    if (response?.link) {
      window.location.href = response.link;
    }
  };

  return (
    <>
      <Text label="modal.changeAccount.description" size="base" lh="xlarge" mb="xmedium" />
      <Mutation action={actionHandle} id={''} onSuccess={onSuccess}>
        {({ loading, action }: any) => (
          <SC.ChangeStripeBtnContainer>
            <Button disabled={loading} label="btn.cancel" onClick={onClose} whiteBtn upperCase={false} semiBold />
            <Button
              loading={loading}
              label="modal.changeAccount.title"
              onClick={action}
              type="primary"
              upperCase={false}
              semiBold
            />
          </SC.ChangeStripeBtnContainer>
        )}
      </Mutation>
    </>
  );
}

export default withModal('modal.changeAccount.title', { contentClassName: 'change-stripe-account-modal' })(
  ChangeStripeAccountModal
);
