import React from 'react';
import { Field, validateRequired } from '@plandok/core';
import withModifyModal from '../withModifyModal';
import * as api from 'api';

function CreateRefSource() {
  return (
    <Field.Input
      name="name"
      label="input.name.label"
      placeholder="input.nameRefSource.placeholder"
      validate={validateRequired}
    />
  );
}

export default withModifyModal({
  label: 'modal.newRefSource.title',
  editLabel: 'modal.editRefSource.title',
  entityName: 'referal',
  entityNameField: 'name',
  deleteAction: api.deleteRefSource,
  detailsAction: api.fetchRefSourceDetails,
  createAction: api.createRefSource,
  editAction: api.editRefSource,
  isModal: true,
})(CreateRefSource);
