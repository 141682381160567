// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from '../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookingLocationQueryVariables = Types.Exact<{
  locationId: Types.Scalars['Uuid'];
}>;

export type BookingLocationQuery = {
  __typename?: 'AppQuery';
  location?: {
    __typename?: 'App_Location';
    id: any;
    name: string;
    email?: string | null;
    phone?: string | null;
    position: number;
    onlineStatus: Types.App_LocationOnlineStatus;
    description?: string | null;
    address?: string | null;
    slug?: string | null;
    previewToken?: string | null;
    zipCode?: string | null;
    city?: string | null;
    state?: string | null;
    addressDetails?: string | null;
    previewUrl?: string | null;
    photos: Array<{ __typename?: 'App_LocationPhoto'; name?: string | null; url?: string | null } | null>;
    workingHours?: {
      __typename?: 'App_LocationWorkingHours';
      monday?: {
        __typename?: 'App_LocationWorkingTime';
        open: boolean;
        opensAt?: number | null;
        closesAt?: number | null;
      } | null;
      tuesday?: {
        __typename?: 'App_LocationWorkingTime';
        open: boolean;
        opensAt?: number | null;
        closesAt?: number | null;
      } | null;
      wednesday?: {
        __typename?: 'App_LocationWorkingTime';
        open: boolean;
        opensAt?: number | null;
        closesAt?: number | null;
      } | null;
      thursday?: {
        __typename?: 'App_LocationWorkingTime';
        open: boolean;
        opensAt?: number | null;
        closesAt?: number | null;
      } | null;
      friday?: {
        __typename?: 'App_LocationWorkingTime';
        open: boolean;
        opensAt?: number | null;
        closesAt?: number | null;
      } | null;
      saturday?: {
        __typename?: 'App_LocationWorkingTime';
        open: boolean;
        opensAt?: number | null;
        closesAt?: number | null;
      } | null;
      sunday?: {
        __typename?: 'App_LocationWorkingTime';
        open: boolean;
        opensAt?: number | null;
        closesAt?: number | null;
      } | null;
    } | null;
  } | null;
};

export const BookingLocationDocument = gql`
  query BookingLocation($locationId: Uuid!) {
    location(id: $locationId) {
      id
      name
      email
      phone
      position
      onlineStatus
      description
      address
      slug
      previewToken
      zipCode
      city
      state
      addressDetails
      photos {
        name
        url
      }
      previewUrl
      workingHours {
        monday {
          open
          opensAt
          closesAt
        }
        tuesday {
          open
          opensAt
          closesAt
        }
        wednesday {
          open
          opensAt
          closesAt
        }
        thursday {
          open
          opensAt
          closesAt
        }
        friday {
          open
          opensAt
          closesAt
        }
        saturday {
          open
          opensAt
          closesAt
        }
        sunday {
          open
          opensAt
          closesAt
        }
      }
    }
  }
`;

/**
 * __useBookingLocationQuery__
 *
 * To run a query within a React component, call `useBookingLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useBookingLocationQuery(
  baseOptions: Apollo.QueryHookOptions<BookingLocationQuery, BookingLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingLocationQuery, BookingLocationQueryVariables>(BookingLocationDocument, options);
}
export function useBookingLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingLocationQuery, BookingLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingLocationQuery, BookingLocationQueryVariables>(BookingLocationDocument, options);
}
export type BookingLocationQueryHookResult = ReturnType<typeof useBookingLocationQuery>;
export type BookingLocationLazyQueryHookResult = ReturnType<typeof useBookingLocationLazyQuery>;
export type BookingLocationQueryResult = Apollo.QueryResult<BookingLocationQuery, BookingLocationQueryVariables>;
