import { IntlLabel, LabelKey } from '@plandok/i18n';
import { AuthBlock } from '@plandok/core';
import { Tabs } from 'antd';
import { RoutePath } from 'constants/routes';
import { useLocation } from 'hooks/router';
import React from 'react';

interface LinkTabsProps {
  tabs: {
    title: LabelKey;
    path: RoutePath;
    permission: any;
  }[];
  className?: string;
}

export default function LinkTabs({ tabs, className }: LinkTabsProps) {
  const { navigate, location } = useLocation();
  const handleTabClick = (key: string) => navigate(key);
  return (
    <Tabs
      className={className}
      defaultActiveKey={location.pathname}
      activeKey={location.pathname}
      onChange={handleTabClick}
      animated={true}
    >
      {tabs.map((e) => (
        <Tabs.TabPane
          tab={
            <AuthBlock section={e.permission} d>
              <IntlLabel label={e.title} />
            </AuthBlock>
          }
          key={e.path}
        />
      ))}
    </Tabs>
  );
}
