import { Icon } from 'antd';
import styled from 'styled-components';
import React from 'react';

const Container = styled.div`
  position: absolute;
  right: 10px;
  top: 7px;
  &:hover {
    opacity: 0.8;
  }
`;

export default function ClearDatePicker(props: any) {
  return (
    <Container {...props}>
      <Icon type="close" />
    </Container>
  );
}
