import { useEffect, useState } from 'react';
import * as api from 'api';

export const useGetEstimatedCosts = (staffCount?: number) => {
  const [estimatedCostsData, setEstimatedCostsData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const { data } = staffCount
          ? await api.fetchEstimatedCostsWithStaffCount('', staffCount)
          : await api.fetchEstimatedCosts();

        setEstimatedCostsData(data);
      } catch (e) {
        console.error('Error when getting estimated costs');
      } finally {
        setIsLoading(false);
      }
    })();
  }, [staffCount]);

  return { estimatedCostsData, isLoading };
};
