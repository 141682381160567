import React from 'react';
import SetupForm from '../SetupForm';

export default function SetupRescheduleForm() {
  return (
    <SetupForm
      notificationId="rescheduling"
      checkBoxLabel="input.enableReschedule.label"
      checkBoxDescription="input.enableReschedule.description"
    />
  );
}
