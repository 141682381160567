import { NavLink } from 'react-router-dom';
import React from 'react';
import { CustomIcon, AuthBlock } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { NotReleased, MenuItem } from 'components';
import SidebarIcons from './SidebarIcons';

export default function SidebarItem({ item, siderCollapsed, ...rest }: any) {
  const title = <IntlLabel label={item.title} />;
  return (
    <AuthBlock section={item.permission}>
      {item.notReleased && (
        <NotReleased>
          <MenuItem key={item.path} siderCollapsed={siderCollapsed} title={title} {...rest}>
            <NavLink to={item.path}>
              <i className="anticon">{(SidebarIcons as any)[item.iconType]}</i>
              <span>{title}</span>
              {item.isPremium && (
                <div style={{ position: 'absolute', bottom: '60%', left: '60%' }}>
                  <CustomIcon type="star" />
                </div>
              )}
            </NavLink>
          </MenuItem>
        </NotReleased>
      )}
      {!item.notReleased && (
        <MenuItem key={item.path} siderCollapsed={siderCollapsed} title={title} {...rest}>
          <NavLink to={item.path}>
            <i className="anticon">{(SidebarIcons as any)[item.iconType]}</i>
            <span>{title}</span>
            {item.isPremium && (
              <div style={{ position: 'absolute', bottom: '60%', left: '60%' }}>
                <CustomIcon type="star" />
              </div>
            )}
          </NavLink>
        </MenuItem>
      )}
    </AuthBlock>
  );
}
