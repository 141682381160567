import styled, { css } from 'styled-components';
import { mediaMobileDown, mediaMdUp } from '@plandok/core';

export const Item = styled.div<any>`
  display: flex;
  gap: ${({ gap }) => `${gap}px` || 0};

  ${mediaMobileDown(css<any>`
    display: ${(props) => (props.mobileHidden ? 'none' : 'flex')};
  `)}
`;

export const Container = styled.div<any>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  ${mediaMobileDown(css<any>`
    margin-bottom: 15px;
    display: ${(props) => (props.mobileHidden ? 'none' : 'flex')};
    ${(props) =>
      props.mobileColumn &&
      css`
        flex-direction: column;
        ${Item}:not(:last-of-type) {
          margin-bottom: 10px;
        }
      `}
  `)}

  ${mediaMdUp(css`
    display: ${(props: any) => (props.mobileOnly ? 'none' : 'flex')} !important;
  `)}
`;

export const Space = styled.div`
  min-width: 20px;
  min-height: 1px;
`;
