import { createContext, useContext } from 'react';

export const PwaContext = createContext<{ showDelayed?: boolean; showPrompt?: Function | null }>({
  showDelayed: false,
  showPrompt: null,
});

const usePwaContext = () => useContext(PwaContext);

export default usePwaContext;
