import React, { useState } from 'react';
import { Button, CustomIcon, Dropdown, Mutation } from '@plandok/core';
import useGetHasPermission from 'hooks/permission/useGetHasPermission';
import { PermissionType } from 'constants/auth';
import * as api from 'api';
import { Status } from '../helpers';
import * as SC from '../styles';

export default function AppointmentStatusBtn(props: any) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const getHasPermission = useGetHasPermission();
  const canEdit = getHasPermission(PermissionType.CAN_BOOK_APPOINTMENTS);

  const onClick = () => setIsExpanded(!isExpanded);

  return (
    <SC.BtnContainer>
      <Mutation action={api.changeAppointmentStatus} id={props.id} onSuccess={props.onSuccess}>
        {({ loading, action }: any) => (
          <>
            <Dropdown
              disabled={!canEdit}
              onChange={(status: any) => action({ status })}
              menu={[
                { id: 'new', element: <Status status="new" /> },
                { id: 'confirmed', element: <Status status="confirmed" /> },
                { id: 'completed', element: <Status status="completed" /> },
                { id: 'no_show', element: <Status status="no_show" /> },
              ]}
            >
              <Button data-appointment-btn ghost loading={loading} upperCase={false} onClick={onClick}>
                <Status status={props.status} />
                <span>
                  <CustomIcon type={isExpanded ? 'arrowUp' : 'arrowDown'} />
                </span>
              </Button>
            </Dropdown>
          </>
        )}
      </Mutation>
    </SC.BtnContainer>
  );
}
