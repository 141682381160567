import React from 'react';
import { CustomIcon, Text } from '@plandok/core';
import { SUGGEST_FEATURE_URL, VIEW_UPDATES_URL } from 'constants/external-links';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { NoInternetConnection } from 'components';
import { openCrisp } from 'helpers/app/crisp';
import MobileHeader from './components/MobileHeader';
import * as SC from './styles';

export default function HelpPage() {
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();

  return (
    <NoInternetConnection>
      <SC.AppLayoutForHelpPage
        headerTitle=""
        mobileHeader={MobileHeader}
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <SC.PageContainer>
          <SC.HelpTitle label="modal.help.title" weight="semiBold" />

          <SC.LiveChatContainer onClick={openCrisp}>
            <div>
              <CustomIcon type="liveChat" />
            </div>
            <div>
              <span>
                <Text label="modal.help.liveChat.title" size="large" weight="semiBold" />
                <Text mb="none" label="modal.help.liveChat.description" size="base" />
              </span>
              <CustomIcon type="caretRight" />
            </div>
          </SC.LiveChatContainer>
          <SC.LinkContainer href={SUGGEST_FEATURE_URL} target="_blank" rel="noopener noreferrer" spaceBellow>
            <div>
              <CustomIcon type="suggestFeature" />
            </div>
            <div>
              <span>
                <Text label="modal.help.suggestFeature.title" size="large" weight="semiBold" />
                <Text mb="none" label="modal.help.suggestFeature.description" size="base" />
              </span>
              <CustomIcon type="newTab" />
            </div>
          </SC.LinkContainer>

          <SC.LinkContainer href={VIEW_UPDATES_URL} target="_blank" rel="noopener noreferrer">
            <div>
              <CustomIcon type="viewUpdates" />
            </div>
            <div>
              <span>
                <Text label="modal.help.viewUpdates.title" size="large" weight="semiBold" />
                <Text mb="none" label="modal.help.viewUpdates.description" size="base" />
              </span>
              <CustomIcon type="newTab" />
            </div>
          </SC.LinkContainer>
        </SC.PageContainer>
      </SC.AppLayoutForHelpPage>
    </NoInternetConnection>
  );
}
