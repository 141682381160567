import React from 'react';
import { useModal } from '@plandok/core';
import { BookingLinkCard } from './components/BookingLinkCard';
import facebook from './icons/facebook.svg';
import direct from './icons/direct.svg';
import * as SC from './styles';

export default function BookingLinks() {
  const [, { showModal }] = useModal();

  const viewDirectLinkModal = () => showModal({ type: 'VIEW_DIRECT_LINK_MODAL' });
  const viewFacebookLinkModal = () => showModal({ type: 'VIEW_FACEBOOK_LINK_MODAL' });

  return (
    <SC.Container>
      <BookingLinkCard
        title="ob.links.direct.title"
        description="ob.links.direct.description"
        buttonText="ob.btn.showLink"
        icon={direct}
        onClick={viewDirectLinkModal}
      />
      <BookingLinkCard
        title="ob.links.facebook.title"
        description="ob.links.facebook.description"
        buttonText="ob.btn.showLink"
        icon={facebook}
        onClick={viewFacebookLinkModal}
      />
    </SC.Container>
  );
}
