import * as Sentry from '@sentry/browser';
import React, { useContext } from 'react';
import { message } from 'antd';
import { IntlLabel, LabelKey, ReactIntl } from '@plandok/i18n';
import { useAppContext } from '@plandok/core';
import { useGetProfile } from 'hooks/info/useGetProfile';
import { RoutePath } from 'constants/routes';
import { usePwaContext } from 'components';
import { useLocation } from 'hooks/router';
import * as api from 'api';

export function useOnLogin(msg?: LabelKey) {
  const [, { setAppContext }] = useAppContext();
  const { changeLang } = useContext(ReactIntl);
  const { navigate } = useLocation();
  const getProfile = useGetProfile();
  const pwaContext = usePwaContext();

  const onRegisterSuccess = (result: any, form: any) => {
    api.authSuccess(result);

    setAppContext(result.context);

    changeLang(result.context.languageIsoCode);

    message.success(<IntlLabel label={msg || 'notification.success.register'} lang={result.context.languageIsoCode} />);

    setTimeout(() => {
      navigate(RoutePath.CALENDAR);
      if (pwaContext?.showDelayed) {
        pwaContext?.showPrompt?.();
      }
    }, 100);

    getProfile();

    if (form?.email) {
      try {
        Sentry?.configureScope?.(function (scope: any) {
          scope?.setUser?.({ email: form?.email });
        });
      } catch (e) {
        console.error(e, 'Failed to add additional data to Sentry reports');
      }
    }
  };

  return onRegisterSuccess;
}
