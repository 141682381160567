import React, { Fragment } from 'react';
import { IntlDate, IntlLabel } from '@plandok/i18n';
import { Log, LogType } from '../types';
import { getLogType } from '../helpers';

type HistoryLogProps = {
  getLogLabel: (log: Log) => string;
  log: Log;
  dateFormat: string;
};

export default function HistoryLog({ getLogLabel, log, dateFormat }: HistoryLogProps) {
  return (
    <Fragment key={log.id}>
      <IntlLabel label={getLogType(log?.type as LogType)} />
      <IntlLabel label={getLogLabel(log)} />
      <IntlDate date={new Date(log?.createdAt)} dateFormat={dateFormat} />
      <br />
    </Fragment>
  );
}
