export const getFirstKey = (
  obj: any,
  ignoreList: string[] = ['totalCount', 'meta', 'deprecations', 'extra', 'extra.deprecations']
) => {
  return (obj || {})[Object.keys(obj || {}).filter((e) => !ignoreList.includes(e))[0]] || [];
};

export const extractOptionsByMapping = (options: any[], mapping: any) => {
  return (
    options?.map((e: any) => {
      return {
        code: e[mapping.code || 'id'],
        name: !Array.isArray(mapping.name)
          ? e[mapping.name || 'name']
          : mapping.name.map((key: string) => e[key] || '').join(' '),
        serviceGroup: e[mapping.serviceGroup || 'serviceGroup'],
      };
    }) || []
  );
};

export const extractOptions = (data: any, mapping: any = {}) => {
  const options = getFirstKey(data);
  if (!Array.isArray(options)) {
    console.error(`Wrong data format, expected array, but got: ${options}`);
    return [];
  }

  return extractOptionsByMapping(options, mapping);
};

export const filterOptions = (options: any[], isGroup: any, searchStr: string, predefinedOptions?: any[]) => {
  let allOptions = options;
  if (predefinedOptions) {
    allOptions = [...predefinedOptions, ...(options || [])];
  }
  if (!searchStr) {
    return allOptions;
  }
  if (isGroup) {
    return allOptions
      .map((e) => {
        return {
          ...e,
          options: e.options?.filter?.((el: any) => el.name?.toUpperCase?.()?.includes?.(searchStr?.toUpperCase?.())),
        };
      })
      .filter((e) => e.options?.length);
  }
  return allOptions.filter((e) => e.code.toUpperCase()?.includes(searchStr?.toUpperCase()));
};
