import { Button, Text } from '@plandok/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Spin } from 'antd';
import * as api from 'api';
import { AuthLayout, NoInternetConnection } from 'components';
import { RoutePath } from 'constants/routes';
import { useLocation } from 'hooks/router';
import PaymentActionComponent from 'pages/plans/PaymentConfirmationPage/PaymentActionComponent';
import PaymentSummaryComponent from 'pages/plans/PaymentConfirmationPage/PaymentSummaryComponent';
import React, { useEffect, useState } from 'react';

export type PaymentDataType = {
  publicKey: string;
  description: string;
  paymentId: string;
  paymentType: string;
  paymentStatus: string;
  clientSecret: string;
  price: PaymentPriceType;
};

type PaymentPriceType = {
  priceTaxExcl: string;
  priceTaxIncl: string;
  taxAmount: string;
  tax: string;
  hasTax: boolean;
};

export default function PaymentConfirmationPage(props: any) {
  const { urlQuery, navigate }: any = useLocation();
  const { id, paymentMethod } = urlQuery();
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState<PaymentDataType | undefined>();
  const [stripePromise, setStripePromise] = useState<any | undefined>();

  const loadPaymentData = async () => {
    setLoading(true);
    let response = await api.fetchPaymentInformation(id, paymentMethod);

    setPaymentData(response?.data);
    setStripePromise(loadStripe(response?.data?.publicKey || ''));
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      let response = await api.fetchPaymentInformation(id, paymentMethod);

      setPaymentData(response?.data);
      setStripePromise(loadStripe(response?.data?.publicKey || ''));
      setLoading(false);
    })();
  }, [id, paymentMethod]);

  return (
    <NoInternetConnection>
      <AuthLayout
        title="title.payment.confirmation"
        mobileDescription="title.payment.confirmation.description"
        page={RoutePath.PAYMENT_CONFIRMATION}
      >
        <Spin spinning={loading}>
          {paymentData?.paymentStatus === 'requires_action' ? (
            <PaymentSummaryComponent paymentData={paymentData} />
          ) : paymentData?.paymentStatus === 'succeeded' ? (
            <div style={{ width: '100%', height: '200px', textAlign: 'center' }}>
              <img
                style={{ width: '100%', height: '100%', margin: '30px 0' }}
                src="/img/illustrations/paymentDone.svg"
                alt="placeholder"
              />
              <Text label="payment.already.validated" size="mlarge" weight="semiBold" mb="none" />
            </div>
          ) : (
            <div style={{ width: '100%', height: '200px', textAlign: 'center' }}>
              <img
                style={{ width: '100%', height: '100%', margin: '30px 0' }}
                src="/img/illustrations/paymentCancelled.svg"
                alt="placeholder"
              />
              <Text label="payment.already.cancelled" size="mlarge" weight="semiBold" mb="none" />
            </div>
          )}

          {paymentData?.paymentStatus === 'requires_action' && stripePromise ? (
            <Elements stripe={stripePromise}>
              <PaymentActionComponent
                paymentId={id}
                loadPaymentData={loadPaymentData}
                paymentData={paymentData}
                paymentMethod={paymentMethod}
              />
            </Elements>
          ) : (
            paymentData?.paymentStatus !== 'succeeded' && (
              <div style={{ textAlign: 'center' }}>
                <Button
                  type="primary"
                  block
                  label="btn.retry.payment"
                  style={{ width: '50%', margin: '120px 0' }}
                  onClick={() =>
                    navigate(
                      paymentData?.paymentType === 'notification_charge'
                        ? RoutePath.NOTIFICATION_PAYMENT
                        : RoutePath.UPDATE_SUBSCRIPTION
                    )
                  }
                />
              </div>
            )
          )}
        </Spin>
      </AuthLayout>
    </NoInternetConnection>
  );
}
