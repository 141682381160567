import { Col, Icon } from 'antd';
import React from 'react';
import { CustomIcon, useModal, validateRequired, Field, Text } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import * as SC from './style';

export default function CampaignSMS() {
  const [, { showModal }] = useModal();

  const onPricing = () => showModal({ type: 'PRICING_MODAL', modalProps: { String } });

  return (
    <SC.CardContainer>
      <Field.Row style={{ margin: 0 }}>
        <Col span={24} md={12}>
          <SC.Text>
            <Text label="Message and sender ID" size="mlarge" weight="semiBold" />
          </SC.Text>
          <Field.Input
            name="smsSenderName"
            label="input.smsSender.label"
            defaultValue="Plandok"
            validate={validateRequired}
            limit={11}
          />
          <Field.TextArea
            name="message"
            label="Your message"
            defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            rows="8"
          />
          <SC.Characters>
            <SC.Count>
              <Text label="reminder.charactersCount" size="small" mb="none" />
              <Text label="0/160 (1 SMS = 0.08€)" size="small" weight="semiBold" />
            </SC.Count>
            <SC.Pricing onClick={onPricing}>
              <Icon type="exclamation-circle" /> <IntlLabel label="Pricing" />
            </SC.Pricing>
          </SC.Characters>
        </Col>
        <Col span={24} md={8}>
          <Text label="Message preview" size="base" mb="small" isCenter />
          <SC.CardImage>
            <CustomIcon type="phoneFrame" />
            <Field.PreviewInput name="smsSenderName" text="input.smsSender.label" />
            <Field.PreviewArea name="message" />
          </SC.CardImage>
        </Col>
      </Field.Row>
    </SC.CardContainer>
  );
}
