import React from 'react';
import StartCampaignForm from './components/StartCampaignForm';
import { NotReleased, ModifyMassLayout } from 'components';
import withFormApi from 'hocs/form/withFormApi';
import { RoutePath } from 'constants/routes';

export const MassageFormWithApi = withFormApi({
  backPath: RoutePath.MARKETING,
  successMessage: 'Message',
})(StartCampaignForm);

export default function StartCampaignPage() {
  return (
    <NotReleased>
      <ModifyMassLayout title="Review" step="Campaign setup. Step 3 of 3">
        <MassageFormWithApi />
      </ModifyMassLayout>
    </NotReleased>
  );
}
