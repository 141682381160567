import React from 'react';

export default function IconEmptyClientList() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="79.985" height="79.982" viewBox="0 0 79.985 79.982">
      <g id="recruitment" transform="translate(0 -0.009)">
        <g id="Group_142" data-name="Group 142" transform="translate(0 16.61)">
          <g id="Group_141" data-name="Group 141">
            <path
              id="Path_90"
              data-name="Path 90"
              d="M72.007,131.616a6.535,6.535,0,0,0,1.12-3.666V126.7a6.571,6.571,0,0,0-11.213-4.653,23.123,23.123,0,0,0-43.844,0A6.571,6.571,0,0,0,6.858,126.7v1.253a6.535,6.535,0,0,0,1.12,3.666A10.069,10.069,0,0,0,0,141.452V144.6a1.562,1.562,0,0,0,1.562,1.562H9.686a1.562,1.562,0,0,0,0-3.124H3.124v-1.583a6.936,6.936,0,0,1,6.928-6.928h7.394a22.99,22.99,0,0,0,3.068,7.316L2.69,159.665a5.854,5.854,0,0,0,8.28,8.279l9.827-9.827a1.562,1.562,0,0,0-2.209-2.209L8.76,165.735A2.73,2.73,0,1,1,4.9,161.874l17.5-17.5a23.11,23.11,0,0,0,40.142-9.852h7.394a6.936,6.936,0,0,1,6.928,6.928v1.583H62.176a1.562,1.562,0,0,0,0,3.124H78.423a1.562,1.562,0,0,0,1.562-1.562v-3.145A10.069,10.069,0,0,0,72.007,131.616Zm-55.126-3.666a3.449,3.449,0,0,1-6.9,0V126.7a3.449,3.449,0,1,1,6.9,0Zm23.111,21.441a20,20,0,1,1,20-20A20.019,20.019,0,0,1,39.992,149.392ZM70,127.951a3.449,3.449,0,0,1-6.9,0V126.7a3.449,3.449,0,1,1,6.9,0Z"
              transform="translate(0 -106.275)"
              fill="#adb6cc"
            />
          </g>
        </g>
        <g id="Group_144" data-name="Group 144" transform="translate(23.121 22.859)">
          <g id="Group_143" data-name="Group 143">
            <path
              id="Path_91"
              data-name="Path 91"
              d="M164.872,146.275a16.864,16.864,0,0,0-11.57,29.14,1.562,1.562,0,0,0,.181.169,16.84,16.84,0,0,0,22.778,0,1.548,1.548,0,0,0,.181-.169,16.864,16.864,0,0,0-11.57-29.14Zm0,30.619a13.68,13.68,0,0,1-8.514-2.961,9.641,9.641,0,0,1,17.027,0A13.68,13.68,0,0,1,164.872,176.894ZM161.58,162.4v-1.214a3.292,3.292,0,0,1,6.584,0V162.4a3.292,3.292,0,1,1-6.584,0Zm14.091,9.245a12.653,12.653,0,0,0-5.978-5.018,6.388,6.388,0,0,0,1.6-4.227v-1.214a6.416,6.416,0,0,0-12.833,0V162.4a6.388,6.388,0,0,0,1.6,4.227,12.655,12.655,0,0,0-5.978,5.018,13.747,13.747,0,1,1,21.6,0Z"
              transform="translate(-148 -146.275)"
              fill="#adb6cc"
            />
          </g>
        </g>
        <g id="Group_146" data-name="Group 146" transform="translate(22.308 61.607)">
          <g id="Group_145" data-name="Group 145">
            <path
              id="Path_92"
              data-name="Path 92"
              d="M145.467,394.767a1.561,1.561,0,1,0,.458,1.1A1.574,1.574,0,0,0,145.467,394.767Z"
              transform="translate(-142.8 -394.309)"
              fill="#adb6cc"
            />
          </g>
        </g>
        <g id="Group_148" data-name="Group 148" transform="translate(38.462 0.009)">
          <g id="Group_147" data-name="Group 147" transform="translate(0 0)">
            <path
              id="Path_93"
              data-name="Path 93"
              d="M247.765.009A1.562,1.562,0,0,0,246.2,1.571V6.886a1.562,1.562,0,1,0,3.124,0V1.571A1.562,1.562,0,0,0,247.765.009Z"
              transform="translate(-246.203 -0.009)"
              fill="#adb6cc"
            />
          </g>
        </g>
        <g id="Group_150" data-name="Group 150" transform="translate(38.461 10.476)">
          <g id="Group_149" data-name="Group 149">
            <path
              id="Path_94"
              data-name="Path 94"
              d="M248.867,67.467a1.561,1.561,0,1,0,.458,1.1A1.571,1.571,0,0,0,248.867,67.467Z"
              transform="translate(-246.2 -67.009)"
              fill="#adb6cc"
            />
          </g>
        </g>
        <g id="Group_152" data-name="Group 152" transform="translate(47.855 5.679)">
          <g id="Group_151" data-name="Group 151">
            <path
              id="Path_95"
              data-name="Path 95"
              d="M311.744,36.764a1.563,1.563,0,0,0-2.209,0l-2.746,2.746A1.562,1.562,0,0,0,309,41.72l2.746-2.746A1.562,1.562,0,0,0,311.744,36.764Z"
              transform="translate(-306.331 -36.307)"
              fill="#adb6cc"
            />
          </g>
        </g>
        <g id="Group_154" data-name="Group 154" transform="translate(26.259 5.672)">
          <g id="Group_153" data-name="Group 153">
            <path
              id="Path_96"
              data-name="Path 96"
              d="M173.53,39.489l-2.772-2.772a1.562,1.562,0,1,0-2.209,2.209l2.772,2.772a1.562,1.562,0,1,0,2.209-2.209Z"
              transform="translate(-168.091 -36.259)"
              fill="#adb6cc"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
