import React from 'react';
import { withModal, CustomIcon, Text } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';

function ViewMessageModal(props: { modalProps: { text: string } }) {
  return <Text size="base">{(props.modalProps || {}).text}</Text>;
}

export default withModal(
  <span>
    <CustomIcon type="message" /> <IntlLabel label="modal.message.title" />
  </span>
)(ViewMessageModal);
