import React from 'react';

export default function DragIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.865" height="28.109" viewBox="0 0 16.865 28.109">
      <g id="Group_64" data-name="Group 64" transform="translate(-428 -378)">
        <circle
          id="Ellipse_27"
          data-name="Ellipse 27"
          cx="2.811"
          cy="2.811"
          r="2.811"
          transform="translate(428 378)"
          fill="#adb6cc"
        />
        <circle
          id="Ellipse_32"
          data-name="Ellipse 32"
          cx="2.811"
          cy="2.811"
          r="2.811"
          transform="translate(439.243 378)"
          fill="#adb6cc"
        />
        <circle
          id="Ellipse_28"
          data-name="Ellipse 28"
          cx="2.811"
          cy="2.811"
          r="2.811"
          transform="translate(428 389.243)"
          fill="#adb6cc"
        />
        <circle
          id="Ellipse_31"
          data-name="Ellipse 31"
          cx="2.811"
          cy="2.811"
          r="2.811"
          transform="translate(439.243 389.243)"
          fill="#adb6cc"
        />
        <circle
          id="Ellipse_29"
          data-name="Ellipse 29"
          cx="2.811"
          cy="2.811"
          r="2.811"
          transform="translate(428 400.487)"
          fill="#adb6cc"
        />
        <circle
          id="Ellipse_30"
          data-name="Ellipse 30"
          cx="2.811"
          cy="2.811"
          r="2.811"
          transform="translate(439.243 400.487)"
          fill="#adb6cc"
        />
      </g>
    </svg>
  );
}
