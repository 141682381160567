import React from 'react';
import { Field } from '@plandok/core';
import withModifyModal from '../withModifyModal';
import * as api from 'api';

function CreateLocationModal() {
  return (
    <>
      <Field.Input name="name" label="input.locationName.label" placeholder="input.locationName.placeholder" />
      <Field.CombinedInput
        name="contactPhoneNumber"
        label="input.contactNumber.label"
        placeholder="input.contactNumber.placeholder"
      />
      <Field.Input
        name="contactEmail"
        label="input.contactEmail.label"
        placeholder="input.email.placeholder"
        tooltip="tooltip.contactEmail"
      />
      <Field.Input name="address" label="input.address.label" placeholder="input.address.placeholder" />
    </>
  );
}

export default withModifyModal({
  label: 'modal.newLocation.title',
  editLabel: 'modal.editLocation.title',
  entityName: 'location',
  entityNameField: 'name',
  deleteAction: api.deleteLocation,
  detailsAction: api.fetchLocationDetails,
  createAction: api.createLocation,
  editAction: api.editLocation,
  combinedFields: ['contactPhoneNumber'],
  excludeList: ['planId', 'planType', 'isAlertLoading', 'locationPage'],
})(CreateLocationModal);
