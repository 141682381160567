import { Layout } from 'antd';
import React from 'react';
import { MobileSidebar, NoInternetConnection } from 'components';
import MobileMenu from './components/MobileMenu';

export default function MobileMenuPage() {
  return (
    <NoInternetConnection>
      <Layout>
        <MobileSidebar />
        <MobileMenu />
      </Layout>
    </NoInternetConnection>
  );
}
