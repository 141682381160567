import React, { Dispatch, SetStateAction } from 'react';
import { getPercentDiff } from 'helpers/common';
import {
  App_AppointmentsAnalytics,
  App_ClientsAnalytics,
  App_OccupancyAnalytics,
} from '../../../../graphql/generatedTypes';
import { TotalAppointmentsChart } from './components/TotalAppointments/TotalAppointmentsChart';
import { CancellationReasons } from './components/CancellationReasons/CancellationReasons';
import { TotalAppointments } from './components/TotalAppointments/TotalAppointments';
import { AnalyticsQuery } from '../../../../graphql/queries/analytics.generated';
import { TopServices } from './components/TopServices/TopServices';
import DatePicker from './components/DatePicker/DatePicker';
import { Occupancy } from './components/Occupancy/Occupancy';
import { Clients } from './components/Clients/Clients';
import * as SC from './styles';

type TimeRangeData = { timeRange: Date; startDate: Date | null; endDate: Date | null };

type AnalyticsDashboardProps = {
  analyticsData: AnalyticsQuery;
  isPremium: boolean;
  isStepByDay: boolean;
  setIsStepByDay: Dispatch<SetStateAction<boolean>>;
  setTimeRangeData: Dispatch<SetStateAction<TimeRangeData>>;
};

export const AnalyticsDashboard = ({
  analyticsData,
  isPremium,
  isStepByDay,
  setTimeRangeData,
  setIsStepByDay,
}: AnalyticsDashboardProps) => {
  const currentPeriodAnalytics = analyticsData?.currentPeriodAnalytics;
  const previousPeriodAnalytics = analyticsData?.previousPeriodAnalytics;

  const appointments = currentPeriodAnalytics?.appointments || ({} as App_AppointmentsAnalytics);
  const appointmentsChartPoints = currentPeriodAnalytics?.graph?.points;

  const cancellationReasons = currentPeriodAnalytics?.topCancellationReasons || [];
  const occupancy = currentPeriodAnalytics?.occupancy || ({} as App_OccupancyAnalytics);
  const clients = currentPeriodAnalytics?.clients || ({} as App_ClientsAnalytics);

  const services = currentPeriodAnalytics?.topServices || [];
  const sortedServices = [...services].sort((a: any, b: any) => b?.bookingCount - a?.bookingCount);

  const totalAppointmentsCount = currentPeriodAnalytics?.appointments?.totalAppointments || 0;
  const previousAppointmentsCount = previousPeriodAnalytics?.appointments?.totalAppointments || 0;
  const appointmentsPercentDiff = getPercentDiff(totalAppointmentsCount, previousAppointmentsCount);

  const occupancyPercent = currentPeriodAnalytics?.occupancy?.percent || 0;
  const previousOccupancyPercent = previousPeriodAnalytics?.occupancy?.percent || 0;
  const occupancyPercentDiff = getPercentDiff(occupancyPercent, previousOccupancyPercent);

  const timeRangeOptions = [
    { label: 'analytics.timeRange.last7days', days: 7, isStepByDay: true, isEnable: true },
    { label: 'analytics.timeRange.last30days', days: 30, isStepByDay: true, isEnable: isPremium },
    { label: 'analytics.timeRange.last3months', days: 91, isStepByDay: false, isEnable: isPremium },
    { label: 'analytics.timeRange.last6Months', days: 182, isStepByDay: false, isEnable: isPremium },
    { label: 'analytics.timeRange.lastYear', days: 365, isStepByDay: false, isEnable: isPremium },
  ];

  return (
    <SC.Container>
      <DatePicker
        isRounded
        isGrid
        timeRangeOptions={timeRangeOptions}
        isPremium={isPremium}
        isStepByDay={isStepByDay}
        setTimeRangeData={setTimeRangeData}
        setIsStepByDay={setIsStepByDay}
      />
      <TotalAppointments appointments={appointments} previousMonthPercent={appointmentsPercentDiff} />
      <TotalAppointmentsChart chartPoints={appointmentsChartPoints} />
      <CancellationReasons cancellationReasons={cancellationReasons} />
      <Occupancy occupancy={occupancy} occupancyPercentDiff={occupancyPercentDiff} />
      <TopServices services={sortedServices} />
      <Clients clients={clients} />
    </SC.Container>
  );
};
