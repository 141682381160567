import { flow, isNumber } from 'lodash';
import React, { useState } from 'react';
import addDays from 'date-fns/addDays';
import { Divider, Icon } from 'antd';
import { Mutation, useModal, withModal, validateRequired, Form, Field, Button } from '@plandok/core';
import { IntlDate, IntlLabel, IntlTemplate } from '@plandok/i18n';
import { getTimeOptions } from 'pages/dashboard/ModifyAppointmentPage/config/options';
import { formatDateField } from 'helpers/date/field';
import * as api from 'api';
import { filterSpecificDate, getHourRequestParams, getIsRequireConfirm, getIsRequireConfirmUpdate } from './helpers';
import * as SC from '../../styles';

const startRepeatOptions = [
  { code: 'norepeat', name: 'dropdown.repeat.no' },
  { code: 'weekly', name: 'dropdown.repeat.weekly' },
];

const endRepeatOptions = [
  { code: 'ongoing', name: 'dropdown.endRepeat.ongoing' },
  { code: 'specific', name: 'dropdown.endRepeat.weekly' },
];

const SHIFTS_COUNT = 2;

const FIELD_NAME_KEYS: Record<string, { start: string; end: string }> = {
  secondShift: {
    start: 'shiftStartSecond',
    end: 'shiftEndSecond',
  },
  thirdShift: {
    start: 'shiftStartThird',
    end: 'shiftEndThird',
  },
  fourthShift: {
    start: 'shiftStartFourth',
    end: 'shiftEndFourth',
  },
};

function CreateStaffHourModal({ modalProps, hideAllModals, isEdit, onClose }: any) {
  const [shiftsError, setShiftsError] = useState('');
  const [, { showModal }] = useModal();

  const { timeFormat, locationId, staffId, date, initialValues } = modalProps ?? {};
  const { shiftStartSecond, shiftStartThird, shiftStartFourth, endRepeatDate } = initialValues ?? {};

  const defaultAnotherShiftValues = [
    shiftStartSecond && 'secondShift',
    shiftStartThird && 'thirdShift',
    shiftStartFourth && 'fourthShift',
  ].filter(Boolean);

  const [anotherShifts, setAnotherShifts] = useState(defaultAnotherShiftValues);

  const options = getTimeOptions(timeFormat);
  const canAddShift = anotherShifts.length <= SHIFTS_COUNT;

  const closeAnotherShift = (shift: string) => {
    const filteredShifts = anotherShifts.filter((anotherShift) => anotherShift !== shift);
    setAnotherShifts(filteredShifts);
  };

  const onConfirmDelete = () => {
    if (getIsRequireConfirm(modalProps.initialValues, date)) {
      return new Promise<void>((resolve) => {
        const actionThis = () =>
          api
            .deleteStaffRanges(staffId, {
              start_date: formatDateField(date),
              end_date: formatDateField(date),
              location_id: locationId,
            })
            .then(() => resolve());

        const actionAll = () =>
          api
            .deleteStaffRanges(staffId, {
              start_date: formatDateField(date),
              end_date: endRepeatDate ? formatDateField(endRepeatDate) : null,
              location_id: locationId,
            })
            .then(() => resolve());

        showModal({
          type: 'CONFIRM_WORK_TIME_MODIFY',
          modalProps: {
            isDelete: true,
            firstName: modalProps?.firstName,
            date: modalProps?.date,
            actionThis,
            actionAll,
          },
        });
      });
    }

    return api.deleteStaffRanges(staffId, {
      start_date: formatDateField(date),
      end_date: formatDateField(endRepeatDate),
      location_id: locationId,
    });
  };

  const onConfirmUpdate = (form: any) => {
    if (shiftsError) {
      return Promise.reject({});
    }

    if (getIsRequireConfirmUpdate(modalProps.initialValues, form, date)) {
      return new Promise<void>((resolve) => {
        const actionThis = () =>
          api.createStaffHours(staffId, getHourRequestParams(form, date, anotherShifts, true)).then(() => resolve());

        const actionAll = () =>
          api.createStaffHours(staffId, getHourRequestParams(form, date, anotherShifts)).then(() => resolve());

        showModal({
          type: 'CONFIRM_WORK_TIME_MODIFY',
          modalProps: {
            firstName: modalProps?.firstName,
            date: modalProps?.date,
            actionThis,
            actionAll,
          },
        });
      });
    }

    return api.createStaffHours(staffId, getHourRequestParams(form, date, anotherShifts));
  };

  return (
    <Form
      initialValues={
        modalProps?.initialValues || {
          repeat: 'norepeat',
          shiftStart: 540,
          shiftEnd: 1020,
          locationId,
        }
      }
      onSuccess={flow(modalProps.onSuccess, hideAllModals)}
      onSubmit={onConfirmUpdate}
      mutators={{
        resetRepeat: (args: any, state: any, utils: any) => {
          utils.changeValue(state, 'endRepeat', () => null);
        },
      }}
    >
      {({ values, form, formError }: any) => {
        const changeFormFieldsByKeys = (
          shiftStartKey: string,
          shiftEndKey: string,
          shiftStartAddTime = 60,
          shiftEndAddTime = 60
        ) => {
          if (isNumber(values.shiftEnd)) {
            const shiftStart = Math.min(1440, values.shiftEnd + shiftStartAddTime);
            const shiftEnd = Math.min(1440, shiftStart + shiftEndAddTime);
            form.change(shiftStartKey, shiftStart !== 1440 ? shiftStart : 0);
            form.change(shiftEndKey, shiftEnd !== 1440 ? shiftEnd : 0);
          }
        };

        const shifts = [
          values.shiftStart,
          values.shiftEnd,
          values.shiftStartSecond,
          values.shiftStartThird,
          values.shiftStartFourth,
          values.shiftEndSecond,
          values.shiftEndThird,
          values.shiftEndFourth,
        ].filter(Boolean);

        const hasDuplicatedShifts = shifts.length !== new Set(shifts).size;

        if (hasDuplicatedShifts) {
          setShiftsError('form.error.uniqShifts');
        } else {
          setShiftsError('');
        }

        const openAnotherShift = () => {
          if (!anotherShifts.length) {
            changeFormFieldsByKeys('shiftStartSecond', 'shiftEndSecond');
            setAnotherShifts((shifts) => [...shifts, 'secondShift']);
          }
          if (anotherShifts.length === 1) {
            changeFormFieldsByKeys('shiftStartThird', 'shiftEndThird', 180, 60);
            setAnotherShifts((shifts) => [...shifts, 'thirdShift']);
          }
          if (anotherShifts.length === 2) {
            changeFormFieldsByKeys('shiftStartFourth', 'shiftEndFourth', 300, 60);
            setAnotherShifts((shifts) => [...shifts, 'fourthShift']);
          }
        };

        return (
          <>
            <Field.Row>
              <Field.Select name="shiftStart" label="input.shiftStart.label" md={12} options={options} />
              <Field.Select name="shiftEnd" label="input.shiftEnd.label" md={11} options={options} />
            </Field.Row>

            {anotherShifts.map((anotherShift) => (
              <Field.Row key={anotherShift} className="position-relative">
                <Field.Select
                  name={FIELD_NAME_KEYS[anotherShift].start}
                  label="input.shiftStart.label"
                  md={12}
                  options={options}
                />
                <Field.Select
                  name={FIELD_NAME_KEYS[anotherShift].end}
                  label="input.shiftEnd.label"
                  md={11}
                  options={options}
                />
                <SC.RemoveRow onClick={() => closeAnotherShift(anotherShift)}>
                  <Icon type="close" />
                </SC.RemoveRow>
              </Field.Row>
            ))}

            {canAddShift && (
              <Divider orientation="left">
                <SC.AddItem onClick={openAnotherShift} data-add-more-shifts>
                  <Icon type="plus-circle" theme="filled" /> <IntlLabel label="btn.add.shift" />
                </SC.AddItem>
              </Divider>
            )}

            {values.repeat === 'weekly' ? (
              <Field.Row>
                <Field.Select name="repeat" label="input.repeat.label" options={startRepeatOptions} md={11} translate />
                {values.endRepeat === 'specific' ? (
                  <Field.Date
                    name="endRepeatDate"
                    label="input.endRepeat.label"
                    placeholder="input.endRepeatDate.placeholder"
                    md={12}
                    filterDate={filterSpecificDate(date)}
                    onClear={() => form.change('endRepeat', 'ongoing')}
                  />
                ) : (
                  <Field.Select
                    name="endRepeat"
                    label="input.endRepeat.label"
                    options={endRepeatOptions}
                    md={12}
                    translate
                    validate={validateRequired}
                    onCustomChange={(val: any) => {
                      if (val === 'specific') {
                        form.change('endRepeatDate', addDays(date, 7));
                      }
                    }}
                  />
                )}
              </Field.Row>
            ) : (
              <Field.Select
                name="repeat"
                label="input.repeat.label"
                options={startRepeatOptions}
                onCustomChange={(val: any) => {
                  if (val === 'weekly') {
                    form.change('endRepeat', 'ongoing');
                  }
                }}
                translate
              />
            )}

            {(formError || shiftsError) && <Form.Alert text={formError || <IntlLabel label={shiftsError} />} />}

            <SC.ModalFooter>
              {isEdit && (
                <Mutation action={onConfirmDelete} id={staffId} onSuccess={flow(modalProps.onSuccess, hideAllModals)}>
                  {({ action }: any) => <Button label="btn.delete" block type="danger" onClick={action} />}
                </Mutation>
              )}
              <Button label="btn.cancel" block onClick={onClose} />
              <Button label="btn.save" block type="primary" htmlType="submit" />
            </SC.ModalFooter>
          </>
        );
      }}
    </Form>
  );
}

const editTitle = ({ firstName, date }: any) => (
  <>
    <span>
      <IntlTemplate label="modal.editHours.title" params={[firstName]} />
    </span>
    <br />
    <SC.TitleDescription>
      <IntlDate date={date} dateFormat="EEEE, dd MMM yyyy" />
    </SC.TitleDescription>
  </>
);

const createTitle = ({ firstName, date }: any) => (
  <>
    <span>
      <IntlTemplate label="modal.createHours.title" params={[firstName]} />
    </span>
    <br />
    <SC.TitleDescription>
      <IntlDate date={date} dateFormat="EEEE, dd MMM yyyy" />
    </SC.TitleDescription>
  </>
);

export default withModal(createTitle, { editLabel: editTitle })(CreateStaffHourModal);
