import flow from 'lodash/flow';
import noop from 'lodash/noop';
import { Icon } from 'antd';
import React from 'react';
import { Button, Form, useLocation } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { ModifyMassLayout, Tabs } from 'components';
import { RoutePath } from 'constants/routes';
import CampaignEmail from './CampaignEmail';
import CampaignSMS from './CampaignSMS';
import * as SC from '../styles';

const staffTabs = [
  { key: 'SMS setup', tab: 'SMS setup' },
  { key: 'Email setup', tab: 'Email setup' },
];

export default function CampaignSetupForm(props: any) {
  const { navigate, goBack } = useLocation();

  const reView = () => navigate(RoutePath.START_CAMPAIGN_PAGE);
  const handleSubmit = (form: any) =>
    props.onSubmit({
      ...form,
      price: form.price || 0,
    });

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        onSuccess={flow(props.onSuccess, props.modalProps?.onSuccess || noop)}
        initialValues={props.initialValues}
      >
        {({ formError, values, submitting }: any) => {
          const isDisabled = !values?.enabled;
          const isDisableButton = !values?.enableButton;
          const isDisableSocial = !values?.enableSocial;
          return (
            <SC.CampaignSetupContainer>
              <Tabs tabs={staffTabs as any} forceRender>
                <CampaignSMS />
                <CampaignEmail
                  isDisabled={isDisabled}
                  isDisableButton={isDisableButton}
                  isDisableSocial={isDisableSocial}
                  values={values}
                />
              </Tabs>
              <Form.Alert text={formError} />
              <ModifyMassLayout.Footer>
                <Button block type="primary" ghost upperCase={false} onClick={goBack}>
                  <Icon type="arrow-left" style={{ marginRight: '10px' }} />
                  <IntlLabel label="Settings" />
                </Button>
                <Button block label="Review" type="primary" upperCase={false} loading={submitting} onClick={reView}>
                  <Icon type="arrow-right" style={{ marginLeft: '10px' }} />
                </Button>
              </ModifyMassLayout.Footer>
            </SC.CampaignSetupContainer>
          );
        }}
      </Form>
    </>
  );
}
