import styled, { css } from 'styled-components';
import { mediaMobileDown } from '@plandok/core';

export const StyledFilterContainer = styled.div<any>`
  display: ${(props: any) => (props.isMobile ? 'none' : 'flex')};
  justify-content: space-between;
  overflow: auto;
  [data-filter-group] {
    display: flex;
  }

  [data-filter-item-select] {
    min-width: 150px;
    &:not(:last-of-type) {
      margin-right: 17px;
    }
  }

  ${mediaMobileDown(css`
    display: none;
  `)}
`;

export const PrintHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid grey;
`;

export const PrintContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid grey;
  padding: 0.5rem;
`;

export const PrintContainer = styled.div`
  margin: 1rem;
`;
