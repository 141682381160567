import React from 'react';

import { message } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { RoutePath } from '../../constants/routes';

export default function withFormApi({
  createAction,
  editAction,
  detailsAction,
  backPath,
  successMessage,
  isModal,
  detailsSelect,
  emptyId,
  rescheduleItemDate,
  rescheduleItemTime,
  rescheduleItemStaff,
  setRescheduleItemDate,
  setRescheduleItemTime,
  setRescheduleItemStaff,
}: {
  createAction?: (props: any) => any;
  editAction?: (id: string, props: any) => any;
  detailsAction?: any;
  backPath?: RoutePath | string;
  successMessage?: string;
  isModal?: boolean;
  detailsSelect?: (
    response: any,
    rescheduleItemDate?: string | Date,
    rescheduleItemTime?: number,
    rescheduleItemStaff?: string
  ) => any;
  emptyId?: boolean;
  rescheduleItemDate?: string | Date;
  rescheduleItemTime?: number;
  rescheduleItemStaff?: string;
  setRescheduleItemDate?: (rescheduleItemDate: string | Date | undefined) => void;
  setRescheduleItemTime?: (rescheduleItemTime: number | undefined) => void;
  setRescheduleItemStaff?: (rescheduleItemStaff: string) => void;
}) {
  return (WrappedComponent: any) => {
    return withRouter(
      class extends Component<any> {
        state = {
          initialValues: this.props.initialValues || {},
          isLoading: false,
        };

        componentDidMount(): void {
          if (!this.props.skipDetails && (emptyId || this.getIsEdit())) {
            this.getDetails();
          }
        }

        onSubmit = (form: any) => {
          if (!this.props.skipDetails && this.getIsEdit() && editAction) {
            return editAction(this.getId(), form).then((res: any) => {
              if (!res?.errors && this.props.onSuccess) {
                this.props.onSuccess(res);
              }

              setRescheduleItemDate && setRescheduleItemDate(undefined);
              setRescheduleItemTime && setRescheduleItemTime(undefined);
              setRescheduleItemStaff && setRescheduleItemStaff('');

              return res;
            });
          }
          return createAction!(form).then((res: any) => {
            if (!res?.errors && this.props.onSuccess) {
              this.props.onSuccess(res, undefined, form);
            }
            return res;
          });
        };

        goBack = () => {
          if (this.props.onBack) {
            this.props.onBack();
          } else if (backPath) {
            this.props.history.push(backPath);
          }
        };

        onSuccess = () => {
          if (successMessage) {
            message.success(successMessage);
          }
          this.goBack();
        };

        onCancel = () => this.goBack();

        getId = () => {
          if (this.props.customId) {
            return this.props.customId;
          }
          return isModal ? (this.props.modalProps || {}).id : get(this.props, 'match.params.id');
        };

        getIsEdit = () => !!this.getId();

        getDetails = async () => {
          if (!detailsAction) {
            return;
          }

          this.setState({ isLoading: true });

          const response = await detailsAction!(this.getId());

          if (response) {
            this.setState({
              initialValues: detailsSelect
                ? detailsSelect(response, rescheduleItemDate, rescheduleItemTime, rescheduleItemStaff)
                : response,
              isLoading: false,
            });
          }
        };

        render() {
          return (
            <WrappedComponent
              onSubmit={this.onSubmit}
              onSuccess={this.onSuccess}
              onCancel={this.onCancel}
              entityId={this.getId()}
              isEdit={this.getIsEdit()}
              {...this.props}
              {...this.state}
            />
          );
        }
      }
    );
  };
}
