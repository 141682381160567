import { StateCalendar } from 'pages/dashboard/CalendarPage/constants';
import { WorkingHours } from 'pages/dashboard/CalendarPage/types';
import theme from '../../styles/theme';
import { getIsMobile } from '@plandok/core';

export const getIsInRange = (fCard: WorkingHours, sCard: WorkingHours) =>
  (fCard.startTime >= sCard.startTime && fCard.endTime <= sCard.endTime) ||
  (fCard.startTime <= sCard.startTime && fCard.endTime >= sCard.endTime) ||
  (fCard.startTime >= sCard.startTime && fCard.startTime < sCard.endTime) ||
  (fCard.endTime > sCard.startTime && fCard.endTime <= sCard.endTime);

export const getRowCountList = (sortedBookings: any[]) =>
  sortedBookings.map((e: any) => {
    const arr = sortedBookings.filter((el) => getIsInRange(e, el));

    return {
      id: e.id,
      index: arr.findIndex(({ id }) => id === e.id),
      count: arr.length,
    };
  });

export const getElementSizePositionsByViewType = (
  bookings: any[],
  columnWidth: number,
  hourHeight: number,
  customViewType: string,
  calendarStartTime: number,
  calendarEndTime: number,
  workingHoursStartTime: number
) => {
  const sortedBookings = bookings.sort((a, b) => a.startTime - b.startTime);
  const rowCountList = getRowCountList(sortedBookings);
  const isMobile = getIsMobile();

  const MOBILE_ADJUSTMENT = isMobile ? 10 : 0;

  const getTopByViewType = (startTime: number) => {
    const fullCalendar = theme.calendarHeaderHeight + hourHeight * (startTime / 60) + MOBILE_ADJUSTMENT;

    switch (customViewType) {
      case StateCalendar.CUSTOM_RANGE:
        const skipCustomHoursHeight = (calendarStartTime / 60) * hourHeight;

        return `${fullCalendar - skipCustomHoursHeight}px`;
      case StateCalendar.WORKING_HOUR:
        const skipWorkingHoursHeight = (workingHoursStartTime / 60) * hourHeight;

        return `${fullCalendar - skipWorkingHoursHeight}px`;
      case StateCalendar.FULL_CALENDAR:
        return `${fullCalendar}px`;
      default:
        return `${fullCalendar}px`;
    }
  };

  const getBookingsByViewType = () => {
    switch (customViewType) {
      case StateCalendar.CUSTOM_RANGE:
        return bookings.filter(
          ({ startTime, endTime }) => startTime >= calendarStartTime && endTime <= calendarEndTime
        );
      case StateCalendar.WORKING_HOUR:
      case StateCalendar.FULL_CALENDAR:
        return bookings;
      default:
        return bookings;
    }
  };

  const preparedBookings = getBookingsByViewType();

  return preparedBookings.map((e) => {
    const { index, count } = rowCountList.find((el) => el.id === e.id)!;

    const duration = e.endTime > 1440 ? 1440 - e.startTime : e.duration;
    const top = getTopByViewType(e.startTime);

    let result: any = {
      top,
      height: `${(duration * hourHeight) / 60}px`,
    };
    result.width = `${columnWidth / count}px`;
    result.left = `${(100 * index) / count}%`;

    return result;
  });
};

export const getResizeLimit = (taskCard: any, hourHeight: number) => ({
  left: 0,
  right: 0,
  top: -((taskCard.duration - 5) / 60) * hourHeight,
  bottom: Infinity,
});
