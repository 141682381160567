import { mediaMdDown, mediaMobileDown } from '@plandok/core';
import styled, { css } from 'styled-components';

export const Container = styled.div<any>`
  min-height: 100vh;
  background: #f8f9fc;
  display: flex;

  ${mediaMobileDown(css`
    background: ${(props) => ((props as any).mdWhite ? 'white' : 'initial')};
    padding-bottom: 100px;
  `)}

  ${mediaMdDown(css`
    padding-bottom: 185px;
  `)}
`;

export const CloseIcon = styled.div`
  font-size: 36px;
  color: #adb6cc;
  position: absolute;
  right: 20px;
  top: 16px;
  cursor: pointer;
  z-index: 2;

  ${mediaMobileDown(css`
    font-size: 18px;
  `)}
`;

export const Content = styled.div`
  margin: 30px auto 0 auto;
  max-width: 1160px;
  display: block;
  width: 100%;

  ${mediaMobileDown(css`
    margin-top: 20px;
  `)}
`;

export const Title = styled.h2<any>`
  text-align: center;
  font-size: 45px;
  color: ${(props) => props.theme.normalColor};

  ${mediaMobileDown(css`
    margin-bottom: 40px;
    padding-left: 15px;
    font-size: 25px;
    text-align: left;
  `)}
` as any;
