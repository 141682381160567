import { Avatar, Drawer, Icon } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { IntlLabel, useIntlOptions } from '@plandok/i18n';
import { CustomIcon } from '@plandok/core';
import { staffOptions } from '../CalendarFilter/constants';
import calendarState from '../../store/calendar.state';
import { viewTypeOptions } from 'constants/data';
import { getAvatarSign } from 'helpers/common';
import * as SC from './styles';

const LocationComponent = (props: any) => {
  return (
    (props.options || []).map((e: any) => {
      const change = () => props.onChange(e.code);
      return (
        <SC.Item key={e.code} active={e.code === props.value} onClick={change}>
          {e.name}
        </SC.Item>
      );
    }) || null
  );
};

const SelectLocation = (props: any) => {
  return (
    props.options?.map((e: any) => (
      <SC.Item key={e.code} active={e.code === props.value} onClick={() => props.onChange(e.code)}>
        {e.isPredefined ? (
          <SC.IconCircle>
            <Icon type={e.icon} />
          </SC.IconCircle>
        ) : (
          <SC.AvatarContainer>
            <Avatar size="large">{getAvatarSign(e.name)}</Avatar>
          </SC.AvatarContainer>
        )}
        <IntlLabel label={e.name as any} />
      </SC.Item>
    )) || null
  );
};

function CalendarMobileFilter(props: any) {
  const { convertOptions } = useIntlOptions();

  const changeFilter = (key: string) => (value: any) => {
    calendarState.changeFilter(key)(value);
    props.onClose();
  };
  const showAllStaff = calendarState?.isLoading || calendarState.employeesOptions?.length > 1;
  return (
    <Drawer placement="right" closable={false} onClose={props.onClose} visible={props.visible}>
      <SC.CloseMenu onClick={props.onClose}>
        <Icon type="close-circle" />
      </SC.CloseMenu>
      <h3>
        <IntlLabel label="title.calendarView" />
      </h3>
      <div className="d-flex">
        {viewTypeOptions.map((e) => (
          <SC.IconItem
            key={e.code}
            active={e.code === calendarState.filterParams.viewType}
            onClick={() => changeFilter('viewType')(e.code)}
          >
            <SC.IconCircle>
              <i data-calendar-custom-icon>
                <CustomIcon type={e.icon as any} />
              </i>
            </SC.IconCircle>
            <IntlLabel label={e.name as any} />
          </SC.IconItem>
        ))}
      </div>
      <br />
      <h3>Location</h3>
      <div>
        <LocationComponent
          onChange={changeFilter('location')}
          value={calendarState.filterParams.location}
          options={calendarState.locationOptions}
        />
      </div>
      <br />
      <h3>Staff</h3>
      <div>
        <SelectLocation
          options={[...(showAllStaff ? convertOptions(staffOptions) : []), ...(calendarState.employeesOptions || [])]}
          onChange={calendarState.changeFilter('staff')}
          value={calendarState.filterParams.staff}
        />
      </div>
    </Drawer>
  );
}

export default observer(CalendarMobileFilter);
