import styled, { css } from 'styled-components';
import { mediaMobileDown } from '@plandok/core';

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  ${mediaMobileDown(css`
    display: none;
  `)}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

export const AddCategoryWrapper = styled.div`
  max-width: 670px;
  margin: 0 auto;
  text-align: center;
  padding-left: 60px;
  ${mediaMobileDown(css`
    display: none;
  `)}
`;

export const AddCategory = styled.div`
  background-color: #13316d;
  padding: 5px 10px;
  color: white;
  border-radius: 20px;
  max-width: 210px;
  margin: auto;
  cursor: pointer;
`;
