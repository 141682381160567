import React, { memo, useMemo, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { ThemeProvider } from 'styled-components';
import { isFirefox } from 'react-device-detect';
import cn from 'classnames';
import { Button, Text, useLocation } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { CalendarItem, WorkingHours } from 'pages/dashboard/CalendarPage/types';
import { StateCalendar } from 'pages/dashboard/CalendarPage/constants';
import CalendarList from './components/CalendarList/CalendarList';
import TimeColumn from './components/TimeColumn';
import GlobalStyle from './styles/global-style';
import { RoutePath } from '../constants/routes';
import * as helpers from './support/helpers';
import { ModeType } from './support/types';
import * as SC from './styles';

export interface ICalendarActions {
  onMove(timeCard: any, nextStartTime: number, nextColumnIndex: number, indexChanged: boolean): any;
  onResize(timeCard: any, nextEndTime: number): any;
  onCardClick(timeCard: any): any;
  onTimeSlotClick(time: number, info: any): any;
}

// #2b72bd
export interface ICustomComponents {
  headerComponent: any;
  tooltipComponent: any;
  timeCardComponent?: any;
}

export interface ICalendarProps extends ICalendarActions, ICustomComponents {
  timeInterval: number;
  modeType: ModeType;
  date: Date;
  isCalendarLoading: boolean;
  data: CalendarItem[];
  isSelectSlotMode?: boolean;
  staffFromFilterParams?: string;
  timeFormat: string;
  // You need to specify if calendar is in iframe
  parentFrameId?: string;
  customViewType: string;
  customViewTime: WorkingHours;
  workingHoursStartTime: number;
  workingHoursEndTime: number;
}

function EpicCalendar(props: ICalendarProps) {
  const { navigate } = useLocation();

  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const dataLength = props.data?.length;
  const hours: number[] = [];

  const appTheme = useMemo(() => helpers.getThemeConfig(props, containerWidth), [props, containerWidth]);
  const actions = useMemo(
    () => ({
      onMove: props.onMove,
      onResize: props.onResize,
      onCardClick: props.onCardClick,
      onTimeSlotClick: props.onTimeSlotClick,
    }),
    [props.onMove, props.onResize, props.onCardClick, props.onTimeSlotClick]
  );
  const getContainerRef = () => containerRef.current;

  const checkBookings = props.data.reduce((acc, { bookings }) => acc + bookings.length, 0);

  props.data.forEach((data) => {
    if (data.workingHours.length) {
      const workingHours = (data.workingHours[0].endTime - data.workingHours[0].startTime) / 60;

      hours.push(workingHours);
    }
  });

  const checkWorkingHours = hours.reduce((acc, val) => acc + val, 0);
  const isDisplayCalendar =
    (props.customViewType === StateCalendar.WORKING_HOUR && (checkBookings || checkWorkingHours)) ||
    props.customViewType === StateCalendar.FULL_CALENDAR ||
    props.customViewType === StateCalendar.CUSTOM_RANGE;

  return (
    <ThemeProvider theme={appTheme}>
      <SC.Container>
        <GlobalStyle />
        <ReactResizeDetector handleWidth onResize={setContainerWidth} />
        {!!props.data.length && props.staffFromFilterParams && isDisplayCalendar ? (
          <SC.Content>
            <SC.CalendarBody
              ref={containerRef}
              id="epic-calendar__body"
              className={cn('epic-calendar__body', { 'epic-calendar__select-slot-mode': props.isSelectSlotMode })}
              isFirefox={isFirefox}
            >
              <TimeColumn
                dataLength={dataLength}
                hourHeight={appTheme.hourHeight}
                modeType={props.modeType}
                date={props.date}
                customViewType={props.customViewType}
                customViewTime={props.customViewTime}
                workingHoursStartTime={props.workingHoursStartTime}
                workingHoursEndTime={props.workingHoursEndTime}
                timeFormat={props.timeFormat}
              />
              <CalendarList
                timeInterval={props.timeInterval}
                data={props.data}
                hourHeight={appTheme.hourHeight}
                columnWidth={appTheme.columnWidth}
                getContainerRef={getContainerRef}
                actions={actions}
                parentFrameId={props.parentFrameId}
                headerComponent={props.headerComponent}
                tooltipComponent={props.tooltipComponent}
                timeCardComponent={props.timeCardComponent}
                modeType={props.modeType}
                isSelectSlotMode={props.isSelectSlotMode}
                customViewType={props.customViewType}
                customViewTime={props.customViewTime}
                workingHoursStartTime={props.workingHoursStartTime}
                workingHoursEndTime={props.workingHoursEndTime}
                timeFormat={props.timeFormat}
              />
            </SC.CalendarBody>
          </SC.Content>
        ) : (
          <>
            {!!props.data.length && checkWorkingHours <= 0 ? (
              <SC.Content>
                <SC.CalendarBody
                  ref={containerRef}
                  id="epic-calendar__body"
                  className={cn('epic-calendar__body', { 'epic-calendar__select-slot-mode': props.isSelectSlotMode })}
                  isFirefox={isFirefox}
                >
                  <TimeColumn
                    dataLength={dataLength}
                    hourHeight={appTheme.hourHeight}
                    modeType={props.modeType}
                    date={props.date}
                    customViewType={StateCalendar.FULL_CALENDAR}
                    customViewTime={{ startTime: 0, endTime: 1380 }}
                    workingHoursStartTime={props.workingHoursStartTime}
                    workingHoursEndTime={props.workingHoursEndTime}
                    timeFormat={props.timeFormat}
                  />
                  <CalendarList
                    timeInterval={props.timeInterval}
                    data={props.data}
                    hourHeight={appTheme.hourHeight}
                    columnWidth={appTheme.columnWidth}
                    getContainerRef={getContainerRef}
                    actions={actions}
                    parentFrameId={props.parentFrameId}
                    headerComponent={props.headerComponent}
                    tooltipComponent={props.tooltipComponent}
                    timeCardComponent={props.timeCardComponent}
                    modeType={props.modeType}
                    isSelectSlotMode={props.isSelectSlotMode}
                    customViewType={StateCalendar.FULL_CALENDAR}
                    customViewTime={{ startTime: 0, endTime: 1380 }}
                    workingHoursStartTime={props.workingHoursStartTime}
                    workingHoursEndTime={props.workingHoursEndTime}
                    timeFormat={props.timeFormat}
                  />
                </SC.CalendarBody>
              </SC.Content>
            ) : (
              <SC.EmptyState>
                <div>
                  <img src="/img/illustrations/empty-staff.svg" alt="placeholder" />
                </div>
                {!props.isCalendarLoading && (
                  <>
                    <Text size="mlarge" weight="semiBold" isCenter>
                      <IntlLabel label="staff.emptyState.title" />
                    </Text>
                    <Text size="base" weight="medium" isCenter>
                      <IntlLabel label="staff.emptyState.description" />
                    </Text>
                    <Button
                      type="primary"
                      htmlType="submit"
                      upperCase={false}
                      smallRadius
                      style={{ minWidth: '244px' }}
                      onClick={() => navigate(`${RoutePath.STAFF}#hours`)}
                    >
                      <IntlLabel label="staff.viewStaffMembers.button" />
                    </Button>
                  </>
                )}
              </SC.EmptyState>
            )}
          </>
        )}
      </SC.Container>
    </ThemeProvider>
  );
}

EpicCalendar.defaultProps = {
  data: [],
};

export default memo(EpicCalendar);
