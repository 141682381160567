import React from 'react';
import { IntlLabel } from '@plandok/i18n';
import { MobileBackButton } from 'components';

export const AnalyticsMobileHeader = (title: string) => (
  <>
    <MobileBackButton />
    <div>
      <IntlLabel label={title} />
    </div>
    <div />
  </>
);
