import { IntlLabel, IntlTemplate, LabelKey } from '@plandok/i18n';
import { Checkbox, Switch } from 'antd';
import cn from 'classnames';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import withFormBlock from '../FormBlock/withFormBlock';
import * as SC from './styles';

interface FormCheckboxProps extends FieldRenderProps<any, any> {
  submitting?: boolean;
  placeholder?: string;
  description?: LabelKey;
  textKey?: LabelKey;
  children?: React.ReactNode;
  isSwitch?: boolean;
  infoCheckbox?: boolean;
  labelParams?: string[] | number[];
}

const FormCheckbox = ({
  input,
  submitting,
  textKey,
  children,
  isSwitch,
  infoCheckbox,
  description,
  className,
  labelParams,
}: FormCheckboxProps) => {
  if (isSwitch) {
    const onChange = () => input.onChange(!input.value);

    return (
      <>
        <div className="align-center">
          <Switch
            size="small"
            checked={Boolean(input.value)}
            onChange={onChange}
            className={cn({ 'info-checkbox': infoCheckbox })}
          />
          <SC.SwitchText onClick={onChange}>
            <IntlLabel label={textKey} />
          </SC.SwitchText>
        </div>
        <SC.Description>
          <IntlLabel label={description} />
        </SC.Description>
      </>
    );
  }

  return (
    <Checkbox {...input} className={className} disabled={submitting}>
      {!labelParams && <IntlLabel label={textKey} />}
      {labelParams && <IntlTemplate label={textKey} params={labelParams} />}
      {children}
    </Checkbox>
  );
};

export default withFormBlock<FormCheckboxProps>(FormCheckbox);
