import styled, { css } from 'styled-components';
import { Button } from '@plandok/core';

type ContainerProps = {
  isMobile: boolean;
};

export const Container = styled.div<ContainerProps>`
  position: fixed;
  bottom: 3rem;
  z-index: 15;
  background: #0a1c43;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  left: 10rem;
  border-radius: 10px;
  min-width: max-content;

  ${({ isMobile }) =>
    isMobile &&
    css`
      bottom: 5rem;
      left: 1rem;
      width: max-content;
      padding: 16px;
    `}
`;

export const BtnClose = styled(Button)<ContainerProps>`
  background: inherit;
  border-radius: 5px;
  padding: 3px 4px;
  margin-left: 16px;
  display: inherit;
  align-items: center;
  height: 20px;
  width: max-content;

  :hover {
    background: #59698b;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-left: 28px;
    `}
`;
