import { CustomIcon, Text } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import React from 'react';

import { getColorType, getCustomIconByPercent } from '../../../../../../helpers/common';
import * as SC from './styles';

type TotalAppointmentsHeaderProps = {
  previousMonthPercent: number;
  totalPercent?: number | null;
};

export const TotalAppointmentsHeader = ({ totalPercent, previousMonthPercent }: TotalAppointmentsHeaderProps) => (
  <>
    <Text size="mlarge" weight="bold" colorType="base" mb="xsmall">
      <IntlLabel label="analytics.totalAppointments.title" />
    </Text>
    <SC.TotalCount>
      <Text size="xxxlarge" weight="medium" colorType="base">
        {totalPercent}
      </Text>
      <CustomIcon type={getCustomIconByPercent(previousMonthPercent)} />
      <Text size="base" weight="semiBold" colorType={getColorType(previousMonthPercent)} ml="xxsmall">
        {previousMonthPercent}% &nbsp;
      </Text>
      <Text size="base" weight="semiBold" colorType={getColorType(previousMonthPercent)}>
        <IntlLabel label="analytics.lastPeriod.title" />
      </Text>
    </SC.TotalCount>
  </>
);

export default TotalAppointmentsHeader;
