import { Card, mediaMdDown } from '@plandok/core';
import styled, { css } from 'styled-components';

export const ElementItem = styled.div`
  padding: 20px 0 12px;
  a {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
  }
  div {
    line-height: 19px;
  }
  :not(:last-of-type) {
    border-bottom: 1px solid #e9ecf3;
  }

  ${mediaMdDown(css`
    padding: 8px 0 12px;
    a {
      font-size: 16px;
      line-height: 19px;
    }
    div {
      font-size: 14px;
      line-height: 17px;
    }
  `)}
`;

export const SettingCard = styled(Card)`
  padding: 30px 40px ${({ theme }) => (theme.account ? '53px' : '18px')};
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  border-radius: 24px;
  border: none;

  :nth-last-of-type(2) {
    margin-bottom: 20px;
  }

  ${mediaMdDown(css`
    padding: 8px 16px;
    box-shadow: none;

    :nth-last-of-type(2) {
      margin-bottom: 0;
    }
  `)}
`;
