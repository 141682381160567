import React, { useContext, useEffect, useState } from 'react';
import flow from 'lodash/flow';
import { CustomIcon, Text, useLocation, useModal } from '@plandok/core';
import { getLabel, IntlTemplate, ReactIntl } from '@plandok/i18n';
import { PriceType } from 'pages/plans/BillingPage/PricingPage/constants';
import { getPlanPriceByStaffCount } from 'helpers/common';
import { RoutePath } from 'constants/routes';
import * as SC from '../styles';

type PricingItemProps = {
  active?: boolean;
  planName: string;
  advantages: string[];
  planType: string;
  isFree?: boolean;
  fetchData?: () => void;
  planId?: string;
  currentStaffCount?: number;
  plansPage?: boolean;
  fetchCurrentPlan?: () => void;
};

const PricingItem = ({
  active = false,
  planName,
  advantages,
  planType,
  isFree,
  fetchData,
  planId,
  currentStaffCount = 1,
  plansPage,
  fetchCurrentPlan,
}: PricingItemProps) => {
  const [staffCount, setStaffCount] = useState(currentStaffCount);
  const { lang } = useContext(ReactIntl);
  const [, { showModal }] = useModal();
  const { navigate } = useLocation();

  useEffect(() => {
    setStaffCount(currentStaffCount);
  }, [currentStaffCount]);

  const decrement = () => {
    if (staffCount === 1) {
      return;
    }

    setStaffCount((staffCount) => staffCount - 1);
  };

  const increment = () => setStaffCount((staffCount) => staffCount + 1);

  const goToPlanCreate = () => navigate(RoutePath.UPDATE_SUBSCRIPTION);

  const viewPricingFeatureModal = () =>
    showModal({
      type: 'PRICING_FEATURE_MODAL',
      modalProps: { currentStaffCount },
    });

  const viewConfirmUnsubscribeModal = () =>
    fetchData &&
    fetchCurrentPlan &&
    showModal({ type: 'CONFIRM_UNSUBSCRIBE', modalProps: { onSuccess: flow(fetchData, fetchCurrentPlan) } });

  return (
    <SC.PricingItem theme={{ active, planType, plansPage }}>
      <SC.PlanName theme={{ planType, plansPage }} colorType="base" weight="medium" size="mlarge" label={planName} />
      <SC.PricingInfoContainer>
        {planType === PriceType.FREE ? (
          <SC.Price colorType="base" weight="bold" label="plan.free" mb="xmedium" />
        ) : (
          <>
            {plansPage && (
              <SC.ContainerCounter>
                <Text label="pricing.numberStuff" mb="small" size="medium" lh="mlarge" />
                <div className="d-flex">
                  <div onClick={decrement}>
                    <CustomIcon type="decreaseCircleIcon" />
                  </div>
                  <SC.InputCalc type="text" value={staffCount} readOnly />
                  <div onClick={increment}>
                    <CustomIcon type="increaseCircleIcon" />
                  </div>
                </div>
              </SC.ContainerCounter>
            )}

            {staffCount && (
              <SC.WrapperPrice theme={{ plansPage }}>
                <SC.Price colorType="base" weight="bold" mb="none">
                  {getPlanPriceByStaffCount(staffCount)}€
                </SC.Price>
                <Text colorType="base" label="period.perMonth" mb="none" size="small" ml="xxsmall" />
              </SC.WrapperPrice>
            )}

            {!plansPage && (
              <>
                <div className="text-start">
                  <SC.PriceDescriptionInfo onClick={viewPricingFeatureModal}>
                    <CustomIcon type="infoСircle" />
                    <Text size="medium" ml="xsmall" mb="none" lh="mlarge" color="#2472e8">
                      <span>{getLabel('plan.premium.description', lang)} </span>
                      <span>{getLabel('plan.premium.viewPricing', lang)}</span>
                    </Text>
                  </SC.PriceDescriptionInfo>
                  <SC.StaffCountInfo colorType="base" mb="xmedium" size="medium">
                    <IntlTemplate params={[currentStaffCount]} label="plan.premium.numberStuff" />
                  </SC.StaffCountInfo>
                </div>

                <SC.BtnContainer>
                  {!isFree && active ? (
                    <SC.BtnCancelSubscription
                      minorBtn
                      label="btn.cancelSubscription"
                      onClick={viewConfirmUnsubscribeModal}
                      upperCase={false}
                    />
                  ) : (
                    <SC.BtnUpgrade label="btn.upgradeNow" upperCase={false} onClick={goToPlanCreate} />
                  )}
                </SC.BtnContainer>
              </>
            )}
          </>
        )}

        {plansPage && (
          <SC.BtnContainer>
            <a href="https://app.plandok.com/registration" target="_blank" rel="noopener noreferrer">
              <SC.BtnUpgrade label="btn.getStarted" upperCase={false} />
            </a>
          </SC.BtnContainer>
        )}
      </SC.PricingInfoContainer>

      <SC.ContainerFeatures theme={{ planType }}>
        {advantages?.map((item: string) => (
          <SC.ItemFeature theme={{ planType }} key={item}>
            <div>
              <CustomIcon type="checkmark" />
            </div>
            <Text colorType="base" size="medium" label={item} mb="none" lh="mlarge" />
          </SC.ItemFeature>
        ))}
      </SC.ContainerFeatures>

      {planType === PriceType.PREMIUM && (
        <SC.CostInfo label="plan.premium.costsInfo" mb="none" weight="semiBold" size="base" lh="xxlarge" />
      )}
    </SC.PricingItem>
  );
};

export default PricingItem;
