import styled, { css } from 'styled-components';
import { mediaMobileDown } from '@plandok/core';

export const Container = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mediaMobileDown(css`
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
  `)};
`;

export const SPlaceholderTitle = styled.h2`
  line-height: '1.1';
  font-size: 42px;
  color: #13316d;
  font-weight: 600;
  margin: 0px;
  ${mediaMobileDown(css`
    font-size: 28px;
  `)};
`;

export const SPlaceholderDescription = styled.div`
  font-size: 18px;
  color: #13316d;
  font-weight: 400;
  width: 710px;
  padding-bottom: 30px;
  text-align: center;
  ${mediaMobileDown(css`
    font-size: 14px;
    width: 260px;
  `)};
`;

export const CampaignsSwitchBtns = styled.div`
  border: 1px solid #dce1ed;
  box-sizing: border-box;
  border-radius: 23px;
  margin-bottom: 30px;
  button {
    min-width: 176.5px;
    border: none;
    box-shadow: none;
    ${mediaMobileDown(css`
      min-width: 138px;
    `)};
  }
`;

export const CampaignsCardGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const CampaignsCardItem = styled.div`
  width: 550px;
  height: 210px;
  display: flex;
  flex-direction: row;
  padding: 20px 30px;
  justify-content: sp
  background: #FFFFFF;
  box-shadow: 4px 5px 11px rgba(38, 60, 122, 0.2);
  border-radius: 10px;
  margin: 20px;
  svg {
    margin-top: 5px;
  }
`;

export const CampaignsCardText = styled.div`
  width: 100%;
  margin-left: 30px;
  position: relative;
`;

export const CampaignsCardTextHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CampaignsCardFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 5px;
  button.ant-switch.ant-switch-checked {
    background: #009329 !important;
  }
`;

export const SwitchBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CampaignsCardBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2472e8 !important;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  svg: first-child {
    margin-right: 10px;
    margin-top: 0px;
  }
  svg {
    margin-left: 10px;
    margin-top: 0px;
  }
  &:hover {
    opacity: 0.8;
  }
  ${mediaMobileDown(css`
    font-size: 16px;
  `)};
`;

export const Pricing = styled.div`
  margin: 130px 0 30px 0;
  color: #13316d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  ${mediaMobileDown(css`
    margin: 0;
  `)};
`;

export const MobileCampaignsCard = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 4px 5px 11px rgba(38, 60, 122, 0.2);
  border-radius: 10px;
  margin-bottom: 20px;
  width: 100%;
  .ant-collapse {
    border: none;
  }
  .ant-collapse-item {
    border: none;
  }
  .ant-collapse-extra {
    float: left !important;
    margin-right: 10px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: auto;
    right: 23px !important;
    svg {
      transform: rotate(90deg);
    }
  }
  .ant-collapse-item-active > .ant-collapse-header > .ant-collapse-arrow {
    svg {
      transform: rotate(-90deg) !important;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 20px 20px 28px 20px;
    line-height: 30px;
    font-size: 18px;
    font-weight: 600;
    color: #13316d;
  }
  .ant-collapse-content {
    border: none;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 16px 16px 20px;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MobileCampaignsCardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button.ant-switch.ant-switch-checked {
    background: #009329 !important;
  }
`;

export const ActiveText = styled.div`
  position: absolute;
  font-size: 16px;
  font-weight: 400;
  top: 40px;
  left: 55px;
`;

export const CampaignsCardDropdown = styled.div`
  background: #ffffff;
  box-shadow: 4px 5px 11px rgba(38, 60, 122, 0.2);
  border-radius: 5px;
  .ant-dropdown-menu {
    text-align: center;
    border-radius: 5px !important;
    padding: 0;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    font-weight: 500;
    color: #13316d;
  }
  .ant-dropdown-menu-item {
    padding: 10px 20px;
    &:hover {
      background-color: #f0f3f9 !important;
      border-radius: 0px !important;
    }
  }
`;

export const Control = styled.div`
  position: absolute;
  right: 0;
  font-size: 40px;
  ${mediaMobileDown(css`
    right: 15px;
  `)};
`;

export const MessageLog = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  border-radius: 20px;
  margin: 2px;
  ${mediaMobileDown(css`
    margin: 50px 0;
    width: 350px;
  `)};
`;
