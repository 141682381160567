import omit from 'lodash/omit';
import React from 'react';
import { countryOptions, Field, validateRequired } from '@plandok/core';
import { BillingInformationType } from 'pages/plans/components/CheckoutForm';
import * as api from 'api';

type BillingInfoFieldsProps = {
  hasPrefix?: boolean;
  values?: { billingInformation: BillingInformationType };
};

export default function BillingInfoFields({ hasPrefix = false, values }: BillingInfoFieldsProps) {
  const prefix = hasPrefix ? 'billingInformation.' : '';

  const onCustomChange = async (country: string) => {
    if (values) {
      await api.updateBillingInfo(omit({ ...values.billingInformation, country }, ['onlineCustomerId']));
    }
  };

  return (
    <Field.Row gutter={40}>
      <Field.Input
        name={`${prefix}firstName`}
        label="input.firstName.label"
        placeholder="input.firstName.placeholder"
        validate={validateRequired}
        md={12}
      />
      <Field.Input
        name={`${prefix}lastName`}
        label="input.lastName.label"
        placeholder="input.lastName.placeholder"
        validate={validateRequired}
        md={12}
      />
      <Field.Input
        name={`${prefix}company`}
        label="input.company.label"
        placeholder="input.company.placeholder"
        validate={validateRequired}
        md={12}
      />
      <Field.Input
        name={`${prefix}address`}
        label="input.address.label"
        placeholder="input.address.placeholder"
        validate={validateRequired}
        md={12}
      />
      <Field.Input
        name={`${prefix}city`}
        label="input.city.label"
        placeholder="input.city.placeholder"
        validate={validateRequired}
        md={6}
      />
      <Field.Input
        name={`${prefix}postCode`}
        label="input.zip.label"
        placeholder="input.zip.placeholder"
        validate={validateRequired}
        md={6}
      />
      <Field.Select
        onCustomChange={onCustomChange}
        options={countryOptions}
        name={`${prefix}country`}
        label="input.businessCountry.label"
        placeholder="input.businessCountry.placeholder"
        validate={validateRequired}
        md={12}
      />
      <Field.Input
        name={`${prefix}registrationNumber`}
        label="input.registrationNumber.label"
        placeholder="input.registrationNumber.label"
        md={12}
      />
      <Field.Input
        name={`${prefix}vatNumber`}
        label="input.vatNumber.label"
        placeholder="input.vatNumber.label"
        md={12}
      />
    </Field.Row>
  );
}
