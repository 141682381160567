import styled from 'styled-components';
import React, { memo } from 'react';
import { Icon } from 'antd';
import { IntlLabel } from '@plandok/i18n';
import { useHotkeys } from 'hooks/hotkey';
import { getIsMobile } from '@plandok/core';
import { useStore } from '../../../../store/store';

const Container = styled.div<any>`
  position: ${({ isActive }) => (isActive ? 'relative' : 'absolute')};
  left: 0;
  right: 0;
  top: ${({ isActive, isMobile }) => (isActive ? (isMobile ? '10px' : '-15px') : '-100px')};
  z-index: 1000;
  transform: ${({ isActive }) => (isActive ? 'translate3d(0, 0, 0)' : 'translate3d(0, -70px, 0)')};
  transition: -webkit-transform 375ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background: #1f4fac;
  color: white;
  height: 70px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  div[data-block-time-block]:first-of-type {
    width: 100%;
  }

  div[data-block-time-block]:last-of-type {
    width: 50px;
    height: 30px;
    font-size: 20px;
    button {
      background: transparent !important;
      border: none !important;
      outline: none !important;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
  }
`;

export default memo(function BlockTimeSelectBanner(props: { closeActive: any; isActive: any; isBlock: boolean }) {
  useHotkeys('esc', props.closeActive, props.isActive);
  const isMobile = getIsMobile();

  const setRescheduleItemId = useStore((state) => state.setRescheduleItemId);

  const closeHandler = () => {
    props.closeActive();
    setRescheduleItemId('');
  };

  return (
    <Container isActive={props.isActive} isMobile={isMobile}>
      <div data-block-time-block>
        <IntlLabel label={props.isBlock ? 'title.blockTime.select' : 'title.appointmentSlot.select'} />
      </div>
      <div data-block-time-block>
        <button onClick={props.isActive ? closeHandler : () => {}}>
          <Icon type="close-circle" />
        </button>
      </div>
    </Container>
  );
});
