import ReactDatePicker from 'react-datepicker';
import React, { useContext } from 'react';
import { getIsMobile, validateRequired } from '@plandok/core';
import { ReactIntl } from '@plandok/i18n';
import { DatepickerHeader } from 'components';
import styled from 'styled-components';
import * as SC from './styles';

const StyledReactDatePicker = styled(ReactDatePicker)`
  width: 100% !important;
`;

export default function AppointmentSpecificDate({
  input,
  withPortal = getIsMobile(),
  disabled,
  defaultValue,
  initialDate,
}: any) {
  const { lang } = useContext(ReactIntl);

  return (
    <StyledReactDatePicker
      renderCustomHeader={DatepickerHeader}
      withPortal={withPortal}
      customInput={
        <SC.Select
          name="specificDate"
          options={undefined}
          validate={validateRequired}
          label="rp.input.selectDate.title"
          translate
          defaultValue={defaultValue}
          placeholder="-"
          dropdownClassName="rec-specific-date-dropdown"
        />
      }
      selected={initialDate || input.value}
      disabled={disabled}
      locale={lang}
      {...input}
    />
  );
}
