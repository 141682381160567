import React, { useState } from 'react';
import { Popover } from 'antd';
import { CustomIcon, IconButton, Text, useModal } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { useUserQuery } from 'graphql/queries/userQuery.generated';
import { exportDataToCSV } from 'helpers/async';
import { MobileBackButton } from 'components';
import './ClientFilter/index';

export default function ClientsMobileHeader() {
  const [, { showModal, hideModal }] = useModal();
  const [visible, setVisible] = useState(false);
  const { data } = useUserQuery();

  const isPremium = data?.user?.partner?.subscription.premium ?? false;

  const openImportClientsModal = () => showModal({ type: 'IMPORT_CLIENTS' });
  const closePremiumModal = () => hideModal('PREMIUM_FEATURE_MODAL');
  const openPremiumModal = () =>
    showModal({
      type: 'PREMIUM_FEATURE_MODAL',
      modalProps: {
        onExit: closePremiumModal,
      },
    });

  const exportClients = () => {
    if (isPremium) {
      exportDataToCSV('client/export', 'clients');
    }
    if (!isPremium) {
      openPremiumModal();
    }
  };

  return (
    <>
      <MobileBackButton />
      <div>
        <IntlLabel label="sidebar.client" />
      </div>

      <Popover
        content={
          <div>
            <div onClick={exportClients}>
              <div className="d-flex">
                <Text size="small" weight="normal" colorType="base" mb="none">
                  <IntlLabel label="client.options.export.title" />
                </Text>
                <div style={{ marginTop: '-6px' }}>
                  <CustomIcon type="star" />
                </div>
              </div>
              <Text size="xsmall" weight="normal" colorType="lightBlue" mb="small">
                <IntlLabel label="client.options.export.description" />
              </Text>
            </div>
            <Text size="small" weight="normal" colorType="base" mb="none" onClick={openImportClientsModal}>
              <IntlLabel label="client.options.import.title" />
            </Text>
          </div>
        }
        trigger="click"
        placement="bottomRight"
        visible={visible}
        overlayClassName="client-options-popover"
      >
        <IconButton icon="dots" onClick={() => setVisible(!visible)} />
      </Popover>
    </>
  );
}
