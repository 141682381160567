import { OnlineBookingHash } from 'constants/routes';

export const bookingsTabsConfig: any = [
  {
    icon: 'overview',
    path: OnlineBookingHash.OVERVIEW,
    title: 'ob.overview.tab',
    description: 'ob.overview.tab.description',
  },
  {
    icon: 'profile',
    path: OnlineBookingHash.PROFILE,
    title: 'ob.profile.tab',
    description: 'ob.profile.tab.description',
  },
  {
    icon: 'links',
    path: OnlineBookingHash.LINKS,
    title: 'ob.links.tab',
    description: 'ob.links.tab.description',
  },
  {
    icon: 'analytics',
    path: OnlineBookingHash.ANALYTICS_TRACKING,
    title: 'sidebar.analytics',
    description: 'ob.analytics.tab.description',
  },
  {
    icon: 'settings',
    path: OnlineBookingHash.SETTINGS,
    title: 'ob.settings.tab',
    description: 'ob.settings.tab.description',
  },
  {
    icon: 'payments',
    path: OnlineBookingHash.PAYMENTS,
    title: 'ob.payments.tab',
    description: 'ob.payments.tab.description',
  },
];
