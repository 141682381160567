import { Col, Divider } from 'antd';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import * as api from 'api';
import React from 'react';
import { Button, Field, Form, Text, durationOptions, useModal, validateRequired } from '@plandok/core';
import { useCurrencySign } from '@plandok/i18n';
import { ModifyLayout, MultiCheckboxField, DeleteButton } from 'components';
import { RoutePath } from 'constants/routes';
import * as SC from '../styles';

const setAllAsDefault = (onChange: any, options: any) => onChange(options.map((e: any) => e.code));

export default function ServiceForm(props: any) {
  const [, { showModal, hideModal }]: any = useModal();
  const sign = useCurrencySign();

  const handleSubmit = (form: any) =>
    props.onSubmit({
      ...form,
      price: form.price || 0,
    });

  return (
    <Form
      onSubmit={handleSubmit}
      onSuccess={flow(props.onSuccess, props.modalProps?.onSuccess || noop)}
      initialValues={props.initialValues}
    >
      {({ values, formError, submitting }: any) => (
        <>
          <SC.CardContainer>
            <Field.Row>
              <Col span={24} md={12}>
                <Field.Input
                  name="name"
                  label="input.serviceName.label"
                  placeholder="input.serviceName.placeholder"
                  validate={validateRequired}
                />
                <Field.AsyncSelect
                  name="serviceGroupId"
                  label="input.serviceCategory.label"
                  placeholder="input.serviceCategory.placeholder"
                  asyncType="category"
                  modalType="CREATE_CATEGORY"
                  addBtn="modal.newCategory.title"
                  showModal={showModal}
                  hideModal={hideModal}
                />
                <Field.Row>
                  <Field.Select
                    name="duration"
                    label="input.duration.label"
                    placeholder="input.duration.placeholder"
                    options={durationOptions}
                    md={11}
                    validate={validateRequired}
                  />
                  <Field.Input
                    type="number"
                    name="price"
                    label="input.price.label"
                    placeholder="input.price.placeholder"
                    md={12}
                    suffix={sign}
                  />
                </Field.Row>
                <SC.Text>
                  <Text label="service.onlineBookings.title" bold />
                </SC.Text>
                <SC.Divider as={Divider} />
                <Field.Checkbox name="isBookableOnline" textKey="input.isBookableOnline.label" isSwitch />

                <Field.TextArea
                  name="description"
                  label="input.serviceDescription.label"
                  placeholder="input.serviceDescription.placeholder"
                  row="4"
                />
              </Col>
              <Col span={24} md={11}>
                <Field.AsyncSelect
                  selectComponent={MultiCheckboxField}
                  name="employeeIds"
                  asyncType="employee"
                  title="input.staff.label"
                  description="input.staffSelect.description"
                  onInitialLoad={!props.isEdit && setAllAsDefault}
                />
              </Col>
            </Field.Row>
          </SC.CardContainer>
          <Form.Alert text={formError} />
          <ModifyLayout.Footer>
            <DeleteButton
              deleteAction={api.deleteService}
              entityName="service"
              name={values?.name}
              id={props.entityId}
              backPath={RoutePath.SERVICES}
            />
            <Button block label="btn.cancel" onClick={props.onCancel} />
            <Button block type="primary" label="btn.save" htmlType="submit" loading={submitting} />
          </ModifyLayout.Footer>
        </>
      )}
    </Form>
  );
}
