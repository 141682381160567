import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useModal, withModal, Button, Form } from '@plandok/core';
import withFormApi from 'hocs/form/withFormApi';
import * as SC from './styles';

const withModifyModal =
  ({
    label,
    editLabel,
    saveBtnLabel = 'btn.save',
    deleteAction,
    isCancel = true,
    detailsAction,
    createAction,
    editAction,
    validateForm,
    entityName,
    entityNameField,
    excludeList,
    combinedFields,
    contentClassName,
  }: any) =>
  (WrappedComponent: any) => {
    return withModal(label, {
      editLabel,
      contentClassName,
    })(
      withFormApi({
        detailsAction,
        createAction,
        editAction,
        isModal: true,
      })((props: any) => {
        const initialValues = isEmpty(props.initialValues) ? props.modalProps?.initialValues : props.initialValues;
        const [, { showModal }] = useModal();
        const onSuccess = () => {
          if (props.modalProps && props.modalProps.onSuccess) {
            props.modalProps.onSuccess();
          }
          setTimeout(props.onClose, 100);
        };
        const isEdit = !!props.id;

        const onDelete = () =>
          showModal({
            type: 'CONFIRM_DELETE',
            modalProps: {
              id: props.id,
              action: deleteAction,
              onSuccess,
              entityName,
              name: (initialValues || {})[entityNameField],
            },
          });

        return (
          <Form
            onSubmit={props.onSubmit}
            initialValues={initialValues}
            onSuccess={onSuccess}
            validate={validateForm}
            excludeList={excludeList}
            combinedFields={combinedFields}
          >
            {({ submitting, formError, values, form }: any) => {
              return (
                <>
                  <WrappedComponent values={values} form={form} isEdit={isEdit} />
                  <Form.Alert text={formError} />
                  <SC.ModalFooter>
                    {props.id && !props.modalProps.disableDelete && (
                      <Button disabled={submitting} onClick={onDelete} label="btn.delete" block type="danger" />
                    )}
                    {isCancel && <Button disabled={submitting} label="btn.cancel" block onClick={props.onClose} />}
                    <Button loading={submitting} label={saveBtnLabel} block type="primary" htmlType="submit" />
                  </SC.ModalFooter>
                </>
              );
            }}
          </Form>
        );
      })
    );
  };

export default withModifyModal;
