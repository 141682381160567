import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 30px 0 15px;
`;

export const DeleteYourAccountWrapper = styled.div`
  max-width: max-content;

  :hover {
    cursor: pointer;
  }
  div {
    color: #ff5e5e;
    line-height: 19px;
  }
`;
