import { createContext } from 'react';

import { Language } from '../languages';

const ReactIntl = createContext({
  lang: Language.ENG,
  changeLang: (() => {}) as any,
});

export default ReactIntl;
