import flow from 'lodash/flow';
import noop from 'lodash/noop';
import React from 'react';
import { Button, Field, Form, Text, useModal, withModal } from '@plandok/core';
import { Tabs } from 'components';
import * as SC from '../styles';

const staffTabs = [
  { key: 'preview sms', tab: 'Preview SMS ' },
  { key: 'preview email', tab: 'Preview Email' },
];

function PreviewMessageModal(props: any) {
  const [, { showModal }] = useModal();

  const sendMessage = () => {
    showModal({
      type: 'SEND_MESSAGE_MODAL',
      modalProps: {},
    });
  };

  return (
    <Form onSuccess={flow(props.onClose, props.modalProps?.onSuccess || noop)}>
      {({ submitting }: any) => (
        <div>
          <Tabs tabs={staffTabs} forceRender>
            <div>
              <SC.CardImage imgUrl="./img/illustrations/Phone-frame.svg">
                <Field.PreviewInput name="smsSenderName" text="input.smsSender.label" />
                <Field.PreviewArea name="message" />
              </SC.CardImage>
              <SC.ActionContainer>
                <Button
                  label="page.marketing.btn.sendMeTestMessage"
                  type="primary"
                  onClick={sendMessage}
                  upperCase={false}
                />
                <Text label="3 free tests left for today" size="small" />
              </SC.ActionContainer>
            </div>
            <div>
              <SC.MessagePreviewContainer>
                <div />
                <Field.EmailPreInput name="messageTitle" />
                <Field.EmailPreArea name="message" />
                <Field.PreviewBtn name="buttonName" />
              </SC.MessagePreviewContainer>
              <SC.ActionContainer>
                <Button
                  label="page.marketing.btn.sendMeTestMessage"
                  type="primary"
                  loading={submitting}
                  onClick={sendMessage}
                  upperCase={false}
                />
              </SC.ActionContainer>
            </div>
          </Tabs>
        </div>
      )}
    </Form>
  );
}

export default withModal('modal.previewMessage.title', { contentClassName: 'preview-message-modal' })(
  PreviewMessageModal
);
