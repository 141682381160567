import React, { useEffect, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { useQuery } from 'react-query';
import { Card } from '@plandok/core';
import { AppLayout, NoInternetConnection } from 'components';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { queryProfileDetails, QueryType } from 'api/query';
import { useLocation } from 'hooks/router';
import { replaceHistoryState } from '../CalendarPage/store/calendar-state.helpers';
import ClientDetailsMobileHeader from './components/ClientDetailsMobileHeader';
import ClientAppointmentsBlock from './components/ClientAppointmentsBlock';
import calendarState from '../CalendarPage/store/calendar.state';
import ClientTotalBlock from './components/ClientTotalBlock';
import ClientInfoBlock from './components/ClientInfoBlock';
import BtnUndo from '../CalendarPage/components/BtnUndo';

export default function ClientDetailsPage() {
  const [isVisibleUndo, setIsVisibleUndo] = useState(false);
  const [timeFormat, setTimeFormat] = useState('');
  const { params, location }: any = useLocation();

  const { data, isFetching, refetch } = useQuery([QueryType.PROFILE_DETAILS, params.id], queryProfileDetails);
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();

  useEffect(() => {
    calendarState.initialFetchData().then(() => {
      const { timeFormat } = calendarState.data ?? {};

      setTimeFormat(timeFormat);
    });

    const deleteAppointment = () => {
      setIsVisibleUndo(false);
      replaceHistoryState();
    };

    if (location.state?.id) {
      setIsVisibleUndo(true);
      setTimeout(deleteAppointment, 10000);
    }
  }, [location]);

  const isLoading = isFetching || !timeFormat;

  return (
    <NoInternetConnection>
      <>
        <BtnUndo
          isVisibleUndo={isVisibleUndo}
          setIsVisibleUndo={setIsVisibleUndo}
          deletedAppointment={location.state}
          refetch={refetch}
        />

        <AppLayout
          headerTitle="client.page.title"
          mobileHeader={() => <ClientDetailsMobileHeader id={params.id} phoneNumber={data?.profile?.phoneNumber} />}
          isSubscriptionLoading={isSubscriptionLoading}
          planType={planType}
        >
          <AppLayout.Container>
            <Row justify="space-between" type="flex">
              <Col md={7} span={24}>
                {isLoading ? (
                  <Card>
                    <Skeleton avatar loading active />
                  </Card>
                ) : (
                  <ClientInfoBlock data={data} id={params.id} />
                )}
              </Col>
              <Col md={16} span={24}>
                {isLoading ? (
                  <Card className="mb-2">
                    <Skeleton loading active />
                  </Card>
                ) : (
                  <ClientTotalBlock data={data} />
                )}
                {isLoading ? (
                  <Card>
                    <Skeleton loading active />
                  </Card>
                ) : (
                  <ClientAppointmentsBlock data={data} pathName={location?.pathname} timeFormat={timeFormat} />
                )}
              </Col>
            </Row>
          </AppLayout.Container>
        </AppLayout>
      </>
    </NoInternetConnection>
  );
}
