import { Route, Redirect } from 'react-router-dom';
import React from 'react';
import { RoutePath } from 'constants/routes';

export default function PublicRoute({ path, appComponent: Component, isAuthenticated, ...props }: any) {
  return (
    <Route
      {...props}
      path={path}
      render={() => (isAuthenticated ? <Redirect to={RoutePath.CALENDAR} /> : <Component />)}
    />
  );
}
