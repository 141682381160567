import React, { useState } from 'react';
import { AsyncSelectInput } from '@plandok/core';
import { AppSetupList, NoInternetConnection } from 'components';
import * as api from 'api';
import { resourcesColumn } from './config';

export default function ResourcesPage() {
  const [location, setLocation] = useState(undefined);

  return (
    <NoInternetConnection>
      <AppSetupList
        btnLabel="btn.new.resource"
        columns={resourcesColumn}
        title="resources.list.title"
        alwaysAllowDelete
        controlItem={
          <AsyncSelectInput
            asyncType="location"
            className="resource-select-input"
            input={{ value: location, onChange: setLocation }}
            selectFirstAsDefault
            hideForOne
          />
        }
        modalType="CREATE_RESOURCE"
        fetchData={api.fetchResources}
        filterParams={location ? { 'filter[location_id]': location } : undefined}
        initialValues={{ locationId: location }}
        placeholder={{
          imgName: 'resources',
          titleLabel: 'state.resources.title',
          descriptionLabel: 'state.resources.description',
          btnLabel: 'state.resources.button',
        }}
      />
    </NoInternetConnection>
  );
}
