import cn from 'classnames';
import React from 'react';
import { getIsMobile } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { AppLayout, MobileBackButton, HashTabs, NotReleased, NoInternetConnection } from 'components';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { MarketingHash } from 'constants/routes';
import MarketingMassmessages from './MarketingMassmessages/MarketingMassmessages';
import MarketingCampaigns from './MarketingCampaigns/MarketingCampaigns';
import MarketingOverview from './MarketingOverview/MarketingOverview';
import { MarketingTabsConfig } from './config';
import './style.less';

const MobileHeader = () => (
  <>
    <MobileBackButton />
    <div>
      <IntlLabel label="title.marketing.permission" />
    </div>
    <div />
  </>
);

export default function MarketingPage() {
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const isMobile = getIsMobile();

  return (
    <NotReleased>
      <NoInternetConnection>
        <AppLayout
          headerTitle="title.marketing.permission"
          mobileHeader={MobileHeader}
          className={cn('marketing-page', { 'Marketing-page__is-mobile': isMobile })}
          isSubscriptionLoading={isSubscriptionLoading}
          planType={planType}
        >
          <HashTabs tabs={MarketingTabsConfig} defaultTab={MarketingHash.OVERVIEW}>
            <MarketingOverview />
            <MarketingMassmessages />
            <MarketingCampaigns />
          </HashTabs>
        </AppLayout>
      </NoInternetConnection>
    </NotReleased>
  );
}
