import React, { useContext, useEffect } from 'react';
import { Text, useLocation } from '@plandok/core';
import { ReactIntl } from '@plandok/i18n';
import { NoInternetConnection, PricingCalculation } from 'components';
import { languages } from '../PlansPage';
import * as SC from './styles';

export default function PricingCalculationPage() {
  const { changeLang } = useContext(ReactIntl);
  const { location } = useLocation();

  useEffect(() => {
    const checkLang = languages.some((lang) => location.pathname.startsWith(lang));
    changeLang(checkLang ? location.pathname.split('/')[1] : 'en');
  }, [changeLang, location]);

  return (
    <NoInternetConnection>
      <SC.PricingPageContainer>
        <div>
          <Text label="modal.title.pricing" mb="large" size="xlarge" weight="semiBold" lh="xxxlarge" isCenter />
          <PricingCalculation />
        </div>
      </SC.PricingPageContainer>
    </NoInternetConnection>
  );
}
