import { monthShortOptions, weekOptions } from '@plandok/core';
import { IntlDate, IntlLabel } from '@plandok/i18n';
import { getDay } from 'date-fns';
import endOfWeek from 'date-fns/endOfWeek';
import getMonth from 'date-fns/getMonth';
import addDays from 'date-fns/addDays';
import React from 'react';

import { getCalendarRangeStart } from '../../../../helpers/date/calendar';

export default function DateFilterDisplay({ isWeekMode, date }: any) {
  const rangeDate = getCalendarRangeStart(date, isWeekMode);

  const translatedShortMonth = (date: Date) => monthShortOptions.find(({ code }) => code === getMonth(date))?.name;

  const weekDay = weekOptions.find(({ code }) => code === getDay(date))?.name;

  return (
    <span className="capitalize user-select-none">
      {isWeekMode ? (
        <>
          <IntlDate date={rangeDate} dateFormat="dd - " />
          <IntlDate date={addDays(endOfWeek(date), 1)} dateFormat="dd " />
          <IntlLabel label={translatedShortMonth(date)} />
          <IntlDate date={addDays(endOfWeek(date), 1)} dateFormat=", yyyy" />
        </>
      ) : (
        <>
          <IntlLabel label={weekDay} />
          <IntlDate date={rangeDate} dateFormat=" dd " />
          <IntlLabel label={translatedShortMonth(date)} />
          <IntlDate date={rangeDate} dateFormat=", yyyy" />
        </>
      )}
    </span>
  );
}
