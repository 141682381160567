import React from 'react';
import { Button, useModal } from '@plandok/core';
import { useLocation } from 'hooks/router';

export default function DeleteButton({ onSuccess, deleteAction, id, backPath, entityName, name }: any) {
  const { navigate } = useLocation();
  const [, { showModal }] = useModal();
  const onSuccessAction = onSuccess || (() => backPath && navigate(backPath));
  if (!id) {
    return null;
  }

  const deleteItem = () =>
    showModal({
      type: 'CONFIRM_DELETE',
      modalProps: { id, action: deleteAction, entityName, name, onSuccess: onSuccessAction },
    });

  return <Button onClick={deleteItem} label="btn.delete" block type="danger" />;
}
