import { Text } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import React from 'react';

type Status = 'new' | 'confirmed' | 'no_show' | 'completed';

export const getStatus = (status: Status): string => {
  if (status === 'new') return 'appointment.status.new';
  if (status === 'confirmed') return 'appointment.status.confirmed';
  if (status === 'no_show') return 'appointment.status.notShow';
  if (status === 'completed') return 'appointment.status.completed';
  if (status === 'cancelled') return 'appointment.status.cancelled';
  return '';
};

export const appointmentsListColumns = [
  {
    title: <IntlLabel label="table.appointmentsList.appointment" />,
    key: 'id',
    dataIndex: 'id',
    render: (value: string) => (
      <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
        {value}
      </Text>
    ),
  },
  {
    title: <IntlLabel label="table.appointmentsList.client" />,
    key: 'client',
    dataIndex: 'client',
    render: (value: string) => {
      return (
        <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
          <IntlLabel label={value.length !== 1 ? value : 'calendar.tooltip.emptyCustomer'} />
        </Text>
      );
    },
  },
  {
    title: <IntlLabel label="table.appointmentsList.service" />,
    key: 'serviceName',
    dataIndex: 'serviceName',
    render: (value: string) => (
      <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
        {value}
      </Text>
    ),
  },
  {
    title: <IntlLabel label="table.appointmentsList.date" />,
    key: 'date',
    dataIndex: 'date',
    render: (value: string) => (
      <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
        {value}
      </Text>
    ),
  },
  {
    title: <IntlLabel label="table.appointmentsList.time" />,
    key: 'time',
    dataIndex: 'time',
    render: (value: string) => (
      <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
        {value}
      </Text>
    ),
  },
  {
    title: <IntlLabel label="table.appointmentsList.duration" />,
    key: 'duration',
    dataIndex: 'duration',
    render: (value: string) => (
      <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
        {value}
      </Text>
    ),
  },
  {
    title: <IntlLabel label="table.appointmentsList.location" />,
    key: 'locationName',
    dataIndex: 'locationName',
    render: (value: string) => (
      <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
        {value}
      </Text>
    ),
  },
  {
    title: <IntlLabel label="table.appointmentsList.staff" />,
    key: 'employee',
    dataIndex: 'employee',
    render: (value: string) => (
      <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
        {value}
      </Text>
    ),
  },
  {
    title: <IntlLabel label="table.appointmentsList.price" />,
    key: 'price',
    dataIndex: 'price',
    render: (value: any) => {
      const totalPrice = JSON.parse(JSON.stringify(value));
      return (
        <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
          {totalPrice.amount} {totalPrice.currency}
        </Text>
      );
    },
  },
  {
    title: <IntlLabel label="table.appointmentsList.status" />,
    key: 'status',
    dataIndex: 'status',
    render: (value: Status) => (
      <Text size="base" weight="normal" mb="none" style={{ color: '#62759D' }}>
        <IntlLabel label={getStatus(value)} />
      </Text>
    ),
  },
];
