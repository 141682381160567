import React, { ReactNode } from 'react';
import { LabelKey } from '@plandok/i18n';
import { AppLayout, HashTabs, NoInternetConnection } from 'components';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { SetupTabHash } from 'constants/routes';
import SetupLayoutMobileHeader from './components/SetupLayoutMobileHeader';
import SetupLayoutFormContainer from './SetupLayoutFormContainer';
import { setupTabConfigs } from './config';

type SetupPageLayoutProps = {
  children: ReactNode;
  title?: LabelKey;
};

function SetupPageLayout({ children, title = 'setup.page.title' }: SetupPageLayoutProps) {
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();

  return (
    <NoInternetConnection>
      <AppLayout
        headerTitle="setup.page.title"
        mobileHeader={() => <SetupLayoutMobileHeader label={title} />}
        mobileWhiteBg={true}
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <HashTabs tabs={setupTabConfigs} defaultTab={SetupTabHash.REMINDERS}>
          {children}
        </HashTabs>
      </AppLayout>
    </NoInternetConnection>
  );
}

SetupPageLayout.Body = SetupLayoutFormContainer;

export default SetupPageLayout;
