import React, { useRef } from 'react';
import { Layout } from 'antd';
import cn from 'classnames';
import { CustomIcon, getIsMobile } from '@plandok/core';
import useCheckUpdateApp from 'hooks/use-check-update-app';
import { sidebarConfig } from 'config/sidebar-config';
import useElementSize from 'hooks/use-element-size';
import SupportButton from './components/SupportButton';
import UpdateButton from './components/UpdateButton';
import SidebarItem from './components/SidebarItem';
import MobileSidebar from '../MobileSidebar';
import * as SC from '../../styles';

type SidebarProps = {
  disableShade?: boolean;
  isVisibleUpdateBtn?: boolean;
  setIsVisibleUpdateBtn?: (value: boolean) => void;
  setIsVisibleUpdateNotification?: (value: boolean) => void;
};

export default function Sidebar({
  disableShade,
  isVisibleUpdateBtn,
  setIsVisibleUpdateBtn,
  setIsVisibleUpdateNotification,
}: SidebarProps) {
  const isMobile = getIsMobile();
  const menuRef = useRef(null);

  const { isUpdated, isHiddenNewVersionNotification } = useCheckUpdateApp();
  const { height } = useElementSize(menuRef);

  return (
    <>
      {!isMobile && (
        <Layout.Sider
          theme="dark"
          collapsed={true}
          className={cn('app-sidebar', { 'app-sidebar--no-shadow': disableShade })}
        >
          <div ref={menuRef}>
            <div className="app-sidebar-trigger">
              <CustomIcon type="logo" />
            </div>
            <SC.StyledMenu mode="inline" theme="dark">
              {sidebarConfig.map((sidebarItem) => (
                <SidebarItem item={sidebarItem} key={sidebarItem.title} siderCollapsed={true} />
              ))}
              {isVisibleUpdateBtn && !isUpdated && isHiddenNewVersionNotification && (
                <UpdateButton
                  isFixed={height > 734}
                  collapsed={true}
                  setIsVisibleUpdateBtn={setIsVisibleUpdateBtn}
                  setIsVisibleUpdateNotification={setIsVisibleUpdateNotification}
                />
              )}
              <SupportButton isFixed={height > 734} collapsed={true} />
            </SC.StyledMenu>
          </div>
        </Layout.Sider>
      )}
      <MobileSidebar />
    </>
  );
}
