/* eslint-disable */
/*
  Generated file from translation.csv
  to update it - make change in google sheet and run 'yarn generate:file:labels' see more docs in readme.md
*/

const labels = {
  'login.title': 'Accedi al tuo account',
  'label.email': 'Indirizzo email',
  'placeholder.email': 'e.g johndoe@gmail.com',
  'input.password.label': 'Password',
  'input.password.placeholder': 'Inserisci la password',
  'login.btn': 'Accedi a Plandok',
  'link.sign.in': 'Accedi',
  'label.password': 'Inserisci la password',
  'login.forgot.password': 'Hai dimenticato la password?',
  'login.no.account': 'Non hai un account?',
  'login.description': 'Se hai problemi ad accedere al tuo account, puoi provare a resettare la tua password',
  'register.title': 'Crea un account',
  'register.description':
    'Crea un account su Plandok, il software per la pianificazione e prenotazione di appuntamenti',
  'register.btn': 'Crea il mio account',
  'register.agreement.agree': 'Accetto:',
  'register.agreement.terms': "I Termini d'uso del Sito Web",
  'register.agreement.policy': 'La Politica della Privacy',
  'register.agreement.and': 'e',
  'register.agreement.partner': 'Le condizioni commerciali dei partner',
  'register.have.account': 'Hai già un account?',
  'register.validation.confirm': 'Prima di procedere, revisiona e accetta i termini illustrati sopra.',
  'link.sign.up': 'Registrati',
  'text.alreadyHaveAccount': 'Hai già un account?',
  'input.company.label': 'Azienda',
  'input.company.placeholder': 'Azienda',
  'input.country.label': 'Paese',
  'input.country.placeholder': 'Nome del Paese',
  'input.timeZone.label': 'Fuso orario',
  'input.timeZone.placeholder': 'GMT +02.00',
  'input.currency.label': 'Valuta',
  'input.language.label': 'Lingua (Language)',
  'input.language.placeholder': 'Inglese',
  'input.currency.placeholder': 'Euro - EUR',
  'reset.title': 'Resetta la Password',
  'reset.btn.text': 'Invia le istruzioni per Resettare la Password',
  'reset.pwd.btn': 'Resetta la Password',
  'reset.no.password': 'Non vuoi resettare la password?',
  'reset.description':
    "Inserisci l'indirizzo email registrato per ricevere un link sicuro da cui impostare una nuova password",
  'sidebar.calendar': 'Calendario',
  'sidebar.client': 'Clienti',
  'sidebar.staff': 'Staff',
  'sidebar.service': 'Servizi',
  'sidebar.notification': 'Messaggi',
  'sidebar.settings': 'Impostazioni',
  'sidebar.menu': 'Menu',
  'sidebar.analytics': 'Analitica',
  'sidebar.language': 'Lingua (Language)',
  'sidebar.logout': 'Logout',
  'user.menu.settings': 'Le mie Impostazioni',
  'user.menu.language': 'Cambia Lingua',
  'user.menu.logout': 'Log out',
  'input.globalSearch.placeholder': 'Cosa stai cercando?',
  'input.globalSearch.description': 'Cerca per nome del cliente, cellulare, email o codice di prenotazione',
  'title.upcomingAppointments': 'Prossimi Appuntamenti',
  'calendar.day': 'Giorno',
  'calendar.week': 'Settimana',
  'calendar.monday': 'Lunedì',
  'calendar.tuesday': 'Martedì',
  'calendar.wednesday': 'Mercoledì',
  'calendar.thursday': 'Giovedì',
  'calendar.friday': 'Venerdì',
  'calendar.saturday': 'Sabato',
  'calendar.sunday': 'Domenica',
  'calendar.mon': 'Lun',
  'calendar.tue': 'Mar',
  'calendar.wed': 'Mer',
  'calendar.thu': 'Gio',
  'calendar.fri': 'Ven',
  'calendar.sat': 'Sab',
  'calendar.sun': 'Dom',
  'calendar.today': 'Oggi',
  'calendar.january': 'Gennaio',
  'calendar.february': 'Febbraio',
  'calendar.march': 'Marzo',
  'calendar.april': 'Aprile',
  'calendar.may': 'Maggio',
  'calendar.june': 'Giugno',
  'calendar.july': 'Luglio',
  'calendar.august': 'Agosto',
  'calendar.september': 'Settembre',
  'calendar.october': 'Ottobre',
  'calendar.november': 'Novembre',
  'calendar.december': 'Dicembre',
  'calendar.jan': 'Gen',
  'calendar.feb': 'Feb',
  'calendar.mar': 'Mar',
  'calendar.apr': 'Apr',
  'calendar.may.short': 'Mag',
  'calendar.jun': 'Giu',
  'calendar.jul': 'Lug',
  'calendar.aug': 'Ago',
  'calendar.sept': 'Set',
  'calendar.oct': 'Ott',
  'calendar.nov': 'Nov',
  'calendar.dec': 'Dic',
  'calendar.allStaff': 'Tutti',
  'calendar.workingStaff': 'Al lavoro',
  'calendar.tooltip.time': 'Tempo',
  'calendar.tooltip.category': 'Categoria',
  'calendar.tooltip.service': 'Servizio',
  'calendar.tooltip.staff': 'Personale',
  'calendar.tooltip.price': 'Prezzo',
  'calendar.tooltip.repeating': 'Ripetutamente',
  'btn.viewDetails': 'Visualizza dettagli',
  'calendar.tooltip.emptyCustomer': 'Walk-in',
  'calendar.tooltip.bookedOnline': 'Prenotazione online',
  'calendar.print.description':
    'Per utilizzare la funzione di stampa il tuo piano deve essere aggiornato. Per aggiornare visitare',
  'calendar.print.appointmentsBetween.title': 'Appuntamenti tra',
  'calendar.print.appointmentsAnd.title': 'E',
  'calendar.billing.title': 'Piano e fatturazione',
  'calendar.btn.print.title': 'Stampa',
  'datepicker.next.week': 'La prossima settimana',
  'datepicker.in.two.weeks': 'Tra 2 settimane',
  'datepicker.in.three.weeks': 'Tra 3 settimane',
  'datepicker.in.four.weeks': 'Tra 4 settimane',
  'datepicker.in.five.weeks': 'Tra 5 settimane',
  'datepicker.in.six.weeks': 'Tra 6 settimane',
  'modal.splitAppointment.title': 'Dividi Appuntamento',
  'modal.splitAppointment.text':
    'Questo appuntamento è parte di un gruppo, spostandolo ad un altro giorno verrà diviso e diventerà una prenotazione separata. Sei sicuro?',
  'blocktime.create.modal.title': 'Slot di Tempo',
  'blocktime.edit.modal.title': 'Modifica Slot di Tempo',
  'appointment.create.menu': 'Appuntamento',
  'blocktime.create.menu': 'Slot di Tempo',
  'appointment.new.title': 'Nuovo Appuntamento',
  'input.startTime.label': 'Tempo di inizio',
  'input.endTime.label': 'Tempo di fine',
  'input.startDate.label': "Data d'inizio",
  'input.endDate.label': 'Data di fine',
  'input.startDate.description.label': 'Seleziona la data di inizio',
  'input.endDate.description.label': 'Seleziona la data di fine',
  'input.closedDatedDescription.placeholder': 'per esempio. vacanze',
  'input.service.label': 'Servizio',
  'input.services.label': 'servizi',
  'input.service.placeholder': 'Scegli un servizio',
  'input.staff.label': 'Personale',
  'input.staff.placeholder': 'Seleziona personale',
  'input.pickDateAndTime.title': 'Scegliere data e ora',
  'input.pickDate.title': 'Scegliere una data',
  'service.add.another': 'Aggiungi un altro servizio',
  'input.appointmentNotes.label': "Note dell'appuntamento",
  'input.emailSubject.placeholder': 'Note sul tuo appuntamento',
  'input.appointmentNotes.placeholder': "Aggiungi nota per l'appuntamento (visibile solo dal personale)",
  'input.searchClients.placeholder': 'Cerca Cliente',
  'input.searchService.placeholder': 'Ricerca',
  'appointment.empty.list':
    'Usa la ricerca per aggiungere un cliente, o lascia il campo vuoto per salvarlo come appuntamento walk-in',
  'appointment.clients.empty.list': "L'elenco dei clienti è vuoto.",
  'appointment.clients.addClient': 'Crea cliente',
  'client.create.new': 'Crea nuovo cliente',
  'client.choose': 'Scegli cliente',
  'client.menu.edit': 'Modifica dettagli cliente',
  'client.menu.remove': "Rimuovi dall'appuntamento",
  'appointment.btn.save': 'Salva',
  'appointment.vat': 'IVA ({0}%)',
  'appointment.total': 'Totale',
  'appointment.view.title': 'Visualizza Appuntamento',
  'appointment.status.btn': "Stato dell'appuntamento",
  'appointment.btn.cancel': 'Eliminare',
  'btn.cancelAppointment': 'Elimina',
  'modal.cancelAppointment.title': 'Elimina',
  'title.clients': 'Clienti',
  'client.create.title': 'Nuovo Cliente',
  'client.options.export.title': 'Esporta clienti',
  'client.options.export.description': 'Scarica CSV con i dati del cliente',
  'client.options.import.title': 'Importa clienti',
  'client.export.premium.description': 'Per poter utilizzare la funzione Esporta, il tuo piano deve essere aggiornato.',
  'client.export.premium.visit': 'Per aggiornare visitare ',
  'client.import.instructions.description':
    'Segui le istruzioni di seguito per importare clienti nel tuo account Plandok',
  'client.import.instructions.download': 'Scarica il file di importazione utilizzando il pulsante in basso',
  'client.import.instructions.copy': 'Copia e incolla i dettagli del tuo cliente nel file',
  'client.import.instructions.email': 'Inviacelo via e-mail a info@plandok.com',
  'client.import.instructions.account': 'e importeremo sul tuo account',
  'table.clients.name': 'Nome',
  'table.clients.phone': 'Telefono',
  'table.clients.mobile': 'Telefono',
  'table.clients.email': 'Email',
  'table.clients.gender': 'Genere',
  'client.page.title': 'Profilo del cliente',
  'btn.more.options': 'Altre Opzioni',
  'btn.showAll': 'Mostra Tutto',
  'btn.collapse': 'Comprimi',
  'btn.reschedule': 'Riprogrammare',
  'title.edit.client': 'Modifica Cliente',
  'menu.edit.client': 'Modifica cliente',
  'menu.delete.client': 'Elimina cliente',
  'title.mobilePhone': 'Telefono Cellulare',
  'title.telephoneNumber': 'Numero di telefono',
  'title.email': 'Email',
  'title.address': 'Indirizzo',
  'title.dateOfBirth': 'Data di Nascita',
  'title.gender': 'Sesso',
  'client.statistics': 'Dati Statistici',
  'client.totalSales': 'Ricavi totali',
  'client.totalBookings': 'Numero totale di Prenotazioni',
  'client.outstanding': 'In sospeso',
  'client.allBooking': 'Tutte le prenotazioni',
  'client.completed': 'Completate',
  'client.cancelled': 'Annullate',
  'client.noShow': 'Mancate Presentazioni',
  'appointment.list.title': 'Appuntamenti',
  'upcoming.list.title': 'Futuri',
  'past.list.title': 'Passati',
  'modal.confirmClientDelete.text.0': 'Sei sicuro di voler eliminare definitivamente il cliente dal database?',
  'modal.confirmClientDelete.text.1': '',
  'input.date.label': 'Data Precisa',
  'input.date.placeholder': 'Seleziona Data',
  'input.firstName.label': 'Nome',
  'input.firstName.placeholder': 'es. John',
  'input.lastName.label': 'Cognome',
  'input.lastName.placeholder': 'es. Doe',
  'input.mobile.label': 'Numero di cellulare',
  'input.mobile.placeholder': 'Inserisci il numero',
  'input.email.label': 'Indirizzo Email',
  'input.email.placeholder': 'es. johndoe@gmail.com',
  'input.title.label': 'Titolo',
  'input.title.placeholder': 'per esempio. Dentista',
  'input.notificationType.label': 'Invia notifica via',
  'tab.personalInfo': 'Informazioni Personali',
  'input.gender.label': 'Sesso',
  'input.referal.label': 'Fonte di Riferimento',
  'input.referal.placeholder': 'Seleziona Fonte',
  'input.birthDay.label': 'Compleanno',
  'input.month.placeholder': 'Mese',
  'input.day.placeholder': 'Giorno',
  'input.year.placeholder': 'Anno',
  'input.clientsNotes.label': 'Note del Cliente',
  'input.clientsNotes.placeholder': 'Inserisci le note del cliente',
  'input.bookingDisplay.label': 'Mostra su tutte le prenotazioni',
  'input.serviceDescription.label': 'Descrizione del servizio',
  'input.serviceDescription.placeholder':
    'Aggiungi una breve descrizione che sarà visibile ai clienti nelle prenotazioni online',
  'input.isBookableOnline.label': 'Disponibile per prenotazioni online',
  'service.onlineBookings.title': 'Prenotazioni online',
  'tab.address': 'Indirizzo',
  'input.address.label': 'Indirizzo',
  'input.address.placeholder': 'Inserisci il tuo indirizzo',
  'input.city.label': 'Città',
  'input.city.placeholder': 'Inserisci la città',
  'input.zip.label': "Codice d'avviamento Postale",
  'input.zip.placeholder': "Inserisci Codice d'avviamento Postale",
  'btn.edit': 'Modifica',
  'btn.cancel': 'Annulla',
  'btn.save': 'Salva',
  'btn.save.changes': 'Salva',
  'tabs.staffWorking.title': 'Orario di Lavoro',
  'tabs.staffMembers.title': 'Membri del Personale',
  'tabs.userPermissions.title': "Permessi dell'Utente",
  'tabs.closedDates.title': 'Date chiuse',
  'btn.new.staff': 'Nuovo Membro dello Staff',
  'table.staff.name': 'Nome',
  'table.staff.mobileNumber': 'Telefono',
  'table.staff.email': 'Email',
  'table.staff.userPermission': "Permessi dell'Utente",
  'table.staff.dataRange': 'Intervallo di date',
  'table.staff.numberOfDays': 'Numero di giorni',
  'table.staff.location': 'Posizione',
  'table.staff.description': 'Descrizione',
  'table.appointmentsList.appointment': 'Appuntamento',
  'table.appointmentsList.client': 'Cliente',
  'table.appointmentsList.service': 'Servizio',
  'table.appointmentsList.date': 'Data',
  'table.appointmentsList.time': 'Tempo',
  'table.appointmentsList.duration': 'Durata',
  'table.appointmentsList.location': 'Posizione',
  'table.appointmentsList.staff': 'Personale',
  'table.appointmentsList.price': 'Prezzo',
  'table.appointmentsList.status': 'Stato',
  'table.appointmentsCancellations.ref': 'Rif',
  'table.appointmentsCancellations.client': 'Cliente',
  'table.appointmentsCancellations.service': 'Servizio',
  'table.appointmentsCancellations.scheduledDate': 'Data programmata',
  'table.appointmentsCancellations.cancelledDate': 'Data annullata',
  'table.appointmentsCancellations.cancelledBy': 'Annullato da',
  'table.appointmentsCancellations.reason': 'Motivo',
  'table.appointmentsCancellations.price': 'Prezzo',
  'table.appointmentsCancellations.totalCount': 'Conteggio totale',
  'table.clients.appointments': 'Appuntamenti',
  'table.clients.noShows': 'Nessuno spettacolo',
  'table.clients.totalSales': 'Vendite totali',
  'table.clients.added': 'Aggiunto',
  'table.clients.daysAbsent': 'Giorni di assenza',
  'table.clients.lastAppointment': 'Ultimo appuntamento',
  'table.clients.lastLocation': 'Ultima posizione',
  'table.appointmentsByService.serviceName': 'Nome di Servizio',
  'table.appointmentsByService.appointments': 'Appuntamenti',
  'table.appointmentsByService.totalValue': 'Valore complessivo',
  'table.appointmentsByStaff.staffMember': 'Membro del personale',
  'table.appointmentsByStaff.appointments': 'Appuntamenti',
  'table.appointmentsByStaff.totalValue': 'Valore complessivo',
  'table.pagination.showing.title': 'Mostrando',
  'table.pagination.results.title': 'risultati',
  'clients.duplicate.title': 'Trovati client duplicati.',
  'clients.duplicate.description': 'Esistono client simili che possono essere uniti.',
  'clients.merge.btn.title': 'Unisci',
  'clients.merge.modal.title': 'Unisci clienti',
  'clients.merge.selectAll.title': 'Seleziona tutti i duplicati',
  'clients.merge.duplicatesNotFound.title': 'Duplicati non trovati',
  'clients.merge.duplicatesNotFound.description': "Non ci sono clienti disponibili per l'unione",
  'clients.mergeConfirm.modal.title': "Conferma l'unione",
  'clients.mergeConfirm.modal.description':
    'Sei sicuro di voler unire {0} duplicati? Questa azione è permanente e non può essere annullata.',
  'clients.mergeConfirm.checkbox.title': 'Capisco che la fusione dei client non può essere annullata',
  'clients.mergeConfirm.btn.title': 'Conferma e unisci',
  'clients.mergeConfirm.cancel.btn.title': 'Annulla',
  'clients.byNumber.btn.title': 'Per telefono {0}',
  'clients.byEmail.btn.title': 'Tramite e-mail {0}',
  'clients.byName.btn.title': 'Per nome {0}',
  'title.newStaff': 'Nuovo membro dello staff',
  'title.editStaff': 'Modifica membro dello staff',
  'description.extraCharge': "L'aggiunta di nuovo personale comporta un costo aggiuntivo all'abbonamento mensile",
  'tabs.details': 'Dettagli',
  'input.staffPicture.label': 'Immagine',
  'input.cropImage.title': "Ritaglia l'immagine",
  'input.cropImage.btn.apply.title': 'Fare domanda a',
  'input.stafffirstName.label': 'Nome',
  'input.stafffirstName.placeholder': 'es. John',
  'input.stafflastName.label': 'Cognome',
  'input.stafflastName.placeholder': 'es. Doe',
  'input.stafmobile.label': 'Telefono',
  'input.stafemail.label': 'Email',
  'input.stafemail.placeholder': 'es. johndoe@gmail.com',
  'input.permission.label': "Permessi dell'Utente",
  'input.uploadProfile.label': 'Carica immagine del profilo',
  'input.changeProfile.label': 'Cambia immagine del profilo',
  'input.notes.label': 'Note',
  'input.staffNotes.placeholder': 'Aggiungi note private visibili solo nelle impostazioni dello staff (opzionale)',
  'input.blockTime.placeholder': 'es. appuntamento per pranzo',
  'tabs.locations': 'Sedi',
  'tabs.integrations': 'Integrazioni',
  'input.staffLocations.description': 'Determina le sedi in cui può essere richiesto questo membro dello staff',
  'input.multiLocation.description': 'Determina le sedi in cui può essere richiesto questo membro dello staff',
  'tabs.services': 'Servizi',
  'input.staffServices.description': 'Determina i servizi forniti dal membro dello staff.',
  'input.multiServices.description': 'Determina i servizi forniti dal membro dello staff.',
  'input.shiftStart.label': 'Inizio del turno',
  'input.shiftEnd.label': 'Fine del turno',
  'edit.staff.title.resendPassword': 'Reinviare la password',
  'edit.staff.info.resendPassword':
    "Il membro dello staff non ha ricevuto un'e-mail con la password? Fare clic su un pulsante qui sotto per inviare nuovamente un'e-mail con le istruzioni per la configurazione della password.",
  'edit.staff.button.resendPassword': "Invia nuovamente l'e-mail con la password",
  'btn.add.shift': 'Aggiungi un altro turno',
  'break.time': '{0} ora/e di pausa',
  'input.repeat.label': 'Si ripete',
  'input.repeatWeekly.label': 'Settimanalmente',
  'input.repeatDoNot.label': 'Non si ripete',
  'input.endRepeat.label': 'La ripetizione termina',
  'input.endRepeatOngoing.label': 'Continuativo',
  'input.endRepeatSpecificDate.label': 'Data Specifica',
  'input.endRepeatDate.placeholder': 'Scegli Data',
  'btn.deleteUpcomingShift': 'Elimina turni futuri',
  'btn.deleteThisShift': 'Elimina solo questo turno',
  'btn.updateUpcomingShift': 'Aggiorna turni futuri',
  'btn.updateThisShift': 'Aggiorna solo questo turno',
  'modal.repeatShift.title': 'Turno Periodico',
  'form.error.uniqShifts': 'I turni dovrebbero essere unici',
  'btn.add.new': 'Aggiungi nuovo',
  'btn.addNewService': 'Aggiungi nuovo servizio',
  'btn.newCategory': 'Nuova Categoria',
  'btn.newServiceGroup': 'Nuova Categoria',
  'btn.editCategory': 'Modifica categoria',
  'btn.deleteCategory': 'Elimina categoria',
  'btn.downloadImportFile': 'Scarica il file di importazione',
  'serviceGroup.delete.success': 'Categoria eliminata con successo!',
  'service.new.title': 'Nuovo Servizio',
  'service.edit.title': 'Modifica Servizio',
  'input.serviceName.label': 'Nome del servizio',
  'input.serviceName.placeholder': 'es. Massaggio',
  'input.serviceCategory.label': 'Categoria del Servizio',
  'input.serviceCategory.placeholder': 'Seleziona la categoria del servizio',
  'input.price.label': 'Prezzo',
  'input.price.placeholder': '0',
  'input.duration.label': 'Durata',
  'input.duration.placeholder': 'Durata',
  'input.staffSelect.description': 'Seleziona il personale che svolge questo servizio',
  'input.newCategoryName.label': 'Nome della categoria',
  'input.newCategoryDescription.label': 'Descrizione della categoria',
  'input.newCategoryDescription.placeholder': 'Descrivi questa categoria, verrà mostrata in Prenotazioni online',
  'modal.newCategory.title': 'Nuova Categoria',
  'modal.editCategory.title': 'Modifica Categoria',
  'input.chooseCategory.label': 'Scegli il colore della categoria:',
  'client.messages.title': 'Messaggi del Cliente',
  'tabs.messagesLog': 'Registro dei Messaggi',
  'tabs.messagesLog.description': 'Elenco dei messaggi inviati ai tuoi clienti',
  'tabs.messagesSettings': 'Impostazioni',
  'tabs.messagesSettings.description':
    'Modifica le impostazioni dei messaggi per adattarle alle tue esigenze aziendali',
  'table.header.timeSent': 'Inviato',
  'table.header.client': 'Cliente',
  'table.header.appointment': 'Appuntamento',
  'table.header.destination': 'Indirizzo',
  'table.header.type': 'Tipo',
  'table.header.message': 'Messaggio',
  'table.header.status': 'Stato',
  'table.header.cost': 'Costo',
  'modal.message.title': 'Visualizza Messaggio',
  'setup.page.title': 'Configurazione',
  'title.accountSettings': "Impostazioni dell'account",
  'title.accountSetup': "Configurazione dell'account",
  'title.accountSetup.description': 'Gestisci impostazioni quali il nome della tua azienda e il fuso orario',
  'resources.list.title': 'Risorse',
  'title.resources': 'Risorse',
  'title.resources.description': 'Configura le risorse prenotabili, come ad esempio stanze, sedie o attrezzature',
  'title.locations': 'Sedi',
  'title.locations.description': 'Gestisci più outlet per la tua attività',
  'calendar.settings.title': 'Impostazioni del Calendario',
  'calendar.btn.selectFromCalendar': 'Selezionare dal calendario',
  'title.calendarSettings': 'Impostazioni del Calendario',
  'title.calendarSettings.description': 'Regola la combinazione di colori e il layout del tuo calendario',
  'title.clientSettings': 'Impostazioni del cliente',
  'title.onlineBookingSettings': 'Impostazioni della prenotazione online',
  'title.salesSettings': 'Impostazioni di vendita',
  'title.receiptSequencing': 'Sequenza delle ricevute',
  'title.receiptSequencing.description':
    'Configurare i dettagli visualizzati sulle ricevute di vendita emesse ai clienti',
  'title.receiptTemplate': 'Modello di ricevuta',
  'title.taxes.description': "Gestite le aliquote d'imposta applicate agli articoli venduti alla cassa",
  'title.paymentTypes': 'Tipi di pagamento',
  'title.paymentTypes.description': 'Impostate i tipi di pagamento manuali da utilizzare durante il checkout',
  'title.discounts.description': 'Impostare gli sconti',
  'title.receiptPrefix': 'Ricevuta №. Prefisso',
  'title.receiptNumber': 'Numero di ricevuta successivo',
  'title.change': 'Modifica',
  'title.saleReceiptTemplate': 'Modello di ricevuta di vendita',
  'description.saleReceiptTemplate':
    'Personalizzate il contenuto delle ricevute di vendita rilasciate ai vostri clienti',
  'setting.automaticallyPrint': 'Stampa automatica delle ricevute al termine della vendita',
  'setting.showMobile': "Mostrare il cellulare e l'e-mail del cliente sulla ricevuta di vendita",
  'setting.showAddress': "Mostra l'indirizzo del cliente sulla ricevuta di vendita",
  'title.receiptTitle': 'Titolo della ricevuta',
  'title.referrals': 'Fonti di Riferimento',
  'title.referrals.description':
    'Configura fonti personalizzate per tenere traccia di come i tuoi clienti vengono a conoscenza della tua attività',
  'title.clientNotifications': 'Impostazioni delle Notifiche dei Clienti',
  'title.clientNotifications.description': 'Gestisci i messaggi da mandare ai clienti riguardo i loro appuntamenti',
  'cancelReason.list.title': 'Motivi di Annullamento',
  'title.cancellationReasons': 'Motivi di Annullamento',
  'title.cancellationReasons.description': 'Tieni traccia dei motivi dietro le assenze dei tuoi clienti',
  'title.onlineBooking.description': 'Gestite la disponibilità e le impostazioni della prenotazione online dei tour',
  'title.pointOfSale': 'Punto vendita',
  'discount.list.title': 'Sconti',
  'title.discountTypes': 'Tipi di Sconto',
  'title.discountTypes.description': 'Configura manualmente le tipologie di sconto da utilizzare durante il checkout',
  'title.premiumFeature': 'Funzionalità premium',
  'company.details.title': "Dettagli dell'Azienda",
  'input.businessName.label': "Nome dell'azienda",
  'input.businessName.placeholder': 'es. Eaglewings',
  'input.description.label': 'Descrizione',
  'input.description.placeholder': 'Inserisci la descrizione',
  'input.businessAddress.label': 'Indirizzo',
  'input.businessAddress.placeholder': "Inserisci l'indirizzo",
  'input.website.label': 'Sito web',
  'input.website.placeholder': 'Inserisci il nome del sito web',
  'input.contactNumber.label': 'Numero di telefono',
  'input.contactNumber.placeholder': 'Inserisci il numero',
  'input.businessTimeZone.label': 'Fuso orario',
  'input.businessTimeZone.placeholder': 'GMT +02.00',
  'input.businessCountry.label': 'Paese',
  'input.businessCountry.placeholder': 'Scegli il Paese',
  'input.timeFormat.label': "Formato dell'ora",
  'input.timeFormat.placeholder': '24 ore',
  'table.header.resourceName': 'Risorse',
  'btn.new.resource': 'Nuova Risorsa',
  'input.resourceName.label': 'Nome della Risorsa',
  'input.resourceName.placeholder': 'Inserisci il Nome',
  'input.resourceDescription.label': 'Descrizione',
  'input.resourceDescription.placeholder': 'Inserisci la descrizione',
  'input.resourceLocation.placeholder': 'Nome della sede',
  'resourcesTooltip.location': 'Location tooltip for resource',
  'modal.newResource.title': 'Crea nuova risorsa',
  'modal.editResource.title': 'Modifica Risorsa',
  'table.header.locationName': 'Sede',
  'table.header.locatonAddress': 'Indirizzo',
  'table.header.location.Phone': 'Numero di Telefono',
  'modal.newLocation.title': 'Nuova Sede',
  'modal.editLocation.title': 'Modifica Sede',
  'btn.new.location': 'Nuova Sede',
  'btn.addExtraLocation': "Aggiungere un'altra posizione",
  'input.locationName.label': 'Nome della sede',
  'input.locationName.placeholder': 'Inserisci il Nome della Sede',
  'input.profile.locationName.placeholder': 'Per esempio. Salone del barbiere',
  'input.slug.label': 'Slug',
  'input.locationTips.label': 'Suggerimenti sulla posizione',
  'input.slug.explanation': "Sarà visibile nell'URL della tua posizione",
  'input.contactEmail.label': 'Email di Contatto',
  'input.locationAddress.label': 'Indirizzo',
  'input.locationAddress.placeholder': "Inserisci l'Indirizzo della Sede",
  'tooltip.contactEmail': 'Le risposte alle notifiche di appuntamento dei clienti saranno mandate a questa email.',
  'input.appointmentColors.label': "Colore dell'Appuntamento",
  'input.colorBy.service': 'Per Gruppo di Servizi',
  'input.colorBy.employee': 'Per Dipendente',
  'input.colorBy.appointment': "Per Stato dell'Appuntamento",
  'input.timeSlot.label': 'Intervallo dello slot di tempo',
  'input.defaultViewType.label': 'Visualizzazione Predefinita',
  'input.calendarTimeRange.label': 'Intervallo di tempo del calendario',
  'input.calendarTimeRange.allTimes.option': 'Tutti gli orari (00:00 - 24:00)',
  'input.calendarTimeRange.onlyWorkingHours.option': 'Solo ore lavorative',
  'input.calendarTimeRange.customRange.option': 'Intervallo personalizzato',
  'input.weekStart.label': 'Giorno di inizio della settimana',
  'table.header.refSourcetitle': 'Fonte di Riferimento',
  'table.header.refSourceAddDate': 'Data di Inserimento',
  'btn.new.referral': 'Nuova Fonte di Riferimento',
  'input.nameRefSource.label': 'Nome della Fonte di Riferimento',
  'input.nameRefSource.placeholder': 'es. Pubblicità su Facebook',
  'modal.newRefSource.title': 'Nuova Fonte di Riferimento',
  'modal.editRefSource.title': 'Modifica Fonte di Riferimento',
  'input.sendBy.label': 'Invia tramite',
  'input.emailSubject.label': "Oggetto dell'email",
  'input.emailTemplate.label': "Modello dell'email",
  'input.emailTemplate.placeholder': "Inserisci il modello dell'email",
  'input.subject.label': "Oggetto dell'email",
  'input.subject.placeholder': "Inserisci l'oggetto dell'email",
  'title.messagingSettings.label': 'Impostazioni di Messaggistica',
  'title.messagingSettings.description':
    "I tipi di messaggi configurati qui saranno automaticamente inviati ai clienti. Tutti i messaggi generati possono essere visualizzati con facilità sulla pagina dei messaggi (nel menu principale). Regola le impostazioni su come e quando i messaggi vengono inviati, e modifica i modelli per personalizzare il testo. Usa i tag qui sotto per includere i dettagli degli appuntamenti all'interno dei messaggi.",
  'client.first.name': 'CLIENT_FIRST_NAME',
  'client.last.name': 'CLIENT_LAST_NAME',
  'staff.first.name': 'STAFF_FIRST_NAME',
  'staff.last.name': 'STAFF_LAST_NAME',
  'booking.date.time': 'BOOKING_DATE_TIME',
  'booking.date': 'BOOKING_DATE',
  'booking.time': 'BOOKING_TIME',
  'service.name': 'SERVICE_NAME',
  'business.name': 'BUSINESS_NAME',
  'business.phone': 'BUSINESS_PHONE',
  'business.address': 'BUSINESS_ADDRESS',
  'business.email': 'BUSINESS_EMAIL',
  'location.name': 'LOCATION_NAME',
  'location.phone': 'LOCATION_PHONE',
  'location.address': 'LOCATION_ADDRESS',
  'location.email': 'LOCATION_EMAIL',
  'tabs.reminders.title': 'Promemoria',
  'input.enableReminder.label': 'Abilita Messaggi di Promemoria',
  'input.enableReminder.description': 'Invia i messaggi in automatico ai clienti prima del loro appuntamento',
  'input.reminder.title': 'Promemoria {0}',
  'input.message.title': 'Messaggio {0}',
  'input.reminder.description': 'Canali e tempo di invio',
  'input.reminder.channels': 'Seleziona i canali su cui verrà inviato questo messaggio',
  'input.reminderBefore.label': 'I promemoria saranno inviati prima',
  'input.smsSender.label': 'ID Mittente SMS',
  'input.messageSender.label': 'ID mittente del messaggio',
  'input.smsSender.placeholder': "Inserisci l'ID del mittente SMS",
  'input.smsTemplate.label': "Modello dell'SMS",
  'input.messageTemplate.label': 'Modello di messaggio',
  'input.smsTemplate.placeholder': "Inserisci il modello dell'SMS",
  'input.whatsAppSender.placeholder': 'Plandok',
  'reminder.whatsApp.notificate':
    'Il modello di messaggio di WhatsApp non è attualmente modificabile a causa di limitazioni. Tuttavia, stiamo lavorando attivamente per renderlo completamente modificabile.',
  'reminder.charactersCount': 'I caratteri contano:',
  'reminder.pricing': 'Prezzo',
  'modal.pricing.description':
    'Paesi diversi hanno tariffe diverse. Noi forniamo la migliore tariffa possibile dai nostri partner. Può variare.',
  'modal.pricing.subDescription':
    'I costi saranno applicati una volta inviati i messaggi e aggiunti alla prossima fattura.',
  'modal.pricing.counterDescription':
    'Un SMS standard è limitato a 160 caratteri e a 70 caratteri per i messaggi con caratteri speciali.',
  'modal.pricing.learnMore': 'Per saperne di più',
  'modal.pricing.priceRates': 'Tariffe',
  'modal.pricing.messagePrice': 'Prezzo di 1 messaggio:',
  'modal.sendTestMessageByNumber.title': 'Inviare un messaggio di prova a questo numero:',
  'modal.sendTestMessageByNumber.description': 'Si applicano le tariffe previste per i messaggi normali.',
  'modal.sendTestMessageByEmail.title': 'Invia un messaggio di prova a questo indirizzo e-mail:',
  'modal.sendTestMessageByEmail.placeholder': "Inserire l'indirizzo e-mail",
  'btn.send': 'Inviare',
  'reminder.sendTestMessage': 'Invia messaggio di prova',
  'button.reminder.addTag': 'Aggiungi Tag',
  'button.reminder.addAnotherReminder': 'AGGIUNGI UN ALTRO PROMEMORIA',
  'button.message.addAnotherMessage': 'AGGIUNGI UN ALTRO MESSAGGIO',
  'button.reminder.upgrade': "Esegui l'upgrade per accedere",
  'label.emailSettings': 'Impostazioni Email',
  'label.SMSSettings': 'Impostazioni SMS',
  'label.whatsAppSettings': 'Impostazioni WhatsApp',
  'tooltip.sender.0': "Verrà mostrato come nome del mittente dell'SMS",
  'tooltip.sender.1': 'Massimo 11 caratteri',
  'tooltip.sender.2': 'Potrebbe non funzionare in alcuni paesi',
  'tooltip.sender.3': 'Solo numeri e lettere latine',
  'tabs.confirmations.title': 'Conferma',
  'btn.appBookings': 'Prenotazioni in App',
  'btn.onlineBookings': 'Prenotazioni online',
  'input.enableAppConfirmation.label': 'Abilita le conferme delle prenotazioni in App',
  'input.enableOnlineConfirmation.label': 'Abilita le conferme delle prenotazioni online',
  'input.enableConfirmationApp.description':
    'Invia automaticamente ai clienti quando un appuntamento è stato prenotato per loro',
  'input.enableConfirmationOnline.description': 'Invio automatico ai clienti quando prenotano un appuntamento',
  'messageSetup.header': 'Impostazione dei messaggi',
  'channels.subHeader': 'Canali',
  'tabs.reschedules.title': 'Rinvia',
  'input.enableReschedule.label': 'Abilita Messaggi di Rinvio',
  'input.enableReschedule.description':
    "Invio automatico ai clienti quando l'orario di inizio dell'appuntamento viene modificato",
  'tabs.cancellations.title': 'Annullamento',
  'input.enableCancellation.label': 'Attiva Messaggi di Annullamento',
  'input.enableCancellations.description': "Invio automatico ai clienti quando l'appuntamento viene annullato",
  'tabs.thanks.title': 'Ringraziamento',
  'input.enableThank.label': 'Abilita Messaggi di Ringraziamento',
  'input.enableThankyou.description':
    "Il messaggio viene inviato ai clienti quando lo stato dell'appuntamento è segnato come completato",
  'tabs.lastVisit.title': 'Ultima visita',
  'input.enableLastVisit.label': 'Attiva Messaggi di Ultima visita',
  'input.enableLastVisit.description':
    'Invio automatico ai clienti quando non hanno visitato la tua attività per un determinato numero di giorni',
  'table.cancelReason.name': 'Motivi di Annullamento',
  'table.cancelReason.addDate': 'Data di Aggiunta',
  'btn.new.cancelReason': 'Nuovo Motivo di Annullamento',
  'modal.newCancelReason.title': 'Nuovo Motivo di Annullamento',
  'modal.editCancelReason.title': 'Modifica Motivo di Annullamento',
  'input.cancelReason.title': 'Nome del Motivo di Annullamento',
  'input.cancelReason.placeholder': 'es. Cliente non disponibile',
  'table.discounts.name': 'Sconti',
  'table.discounts.value': 'Valore',
  'table.discounts.addDate': 'Data di Creazione',
  'input.discountName.label': 'Nome dello Sconto',
  'input.discountName.placeholder': 'es. Anziani',
  'input.discountValue.label': 'Valore dello Sconto',
  'btn.new.discount': 'Nuovo Sconto',
  'input.discountPercentage.label': 'Percentuale %',
  'input.discountSum.label': 'Importo',
  'modal.newDiscount.title': 'Nuovo Tipo di Sconto',
  'modal.editDiscount.title': 'Modifica Sconto',
  'modal.deleteAccount.title': "Eliminazione dell'account",
  'modal.deleteAccount.description':
    "L'eliminazione del vostro account è un processo irreversibile e tutti i vostri dati saranno rimossi per sempre.",
  'modal.deleteAccount.successMessage': 'Il vostro account è stato eliminato con successo.',
  'title.mySettings': 'Le mie Impostazioni',
  'title.personalDetails': 'Informazioni Personali',
  'title.personalDetails.description':
    "Imposta il tuo nome e le tue informazioni di contatto, l'indirizzo email registrato qui viene usato per il login",
  'btn.continueWithGoogle.title': 'Continua con Google',
  'btn.continueWithFacebook.title': 'Continua con Facebook',
  'notification.googleAccountUnlinked': 'Account Google non collegato',
  'notification.googleAccountLinked': 'Account Google collegato',
  'notification.facebookAccountUnlinked': 'Account Facebook non collegato',
  'notification.facebookAccountLinked': 'Account Facebook collegato',
  'btn.linkAccount': "Collegare l'account",
  'btn.unlinkAccount': 'Conto scollegato',
  'btn.deleteYourAccount': 'Elimina il tuo account',
  'input.myName.label': 'Nome',
  'input.myName.placeholder': 'es. John',
  'input.mylastName.label': 'Cognome',
  'input.mylastName.placeholder': 'es. Doe',
  'title.changePassword': 'Cambia Password',
  'title.changePassword.description':
    "Per fare l'aggiornamento, inserisci la tua password attuale seguita da quella nuova. Se non conoscessi la password attuale, puoi fare il logout e usare il link Resetta Password sulla pagina di accesso.",
  'input.currentPassword.label': 'Password Attuale',
  'label.currentPassword.placeholder': 'Inserisci la Password Attuale',
  'input.newPassword.label': 'Nuova password',
  'input.newPassword.placeholder': 'Inserisci la Nuova Password',
  'input.verifyPassword.label': 'Ripeti Password',
  'input.verifyPassword.placeholder': 'Ripeti la Nuova Password',
  'btn.confirmDelete': 'Sì, Cancella ora',
  'btn.confirm': 'Conferma',
  'btn.confirmBook': 'Confermare e prenotare',
  'btn.confirmPay': 'Conferma e pagamento',
  'btn.delete': 'Cancella',
  'btn.close': 'Chiudi',
  'validation.required': 'Il campo è obbligatorio',
  'validation.email.format': 'Formato email non corretto',
  'validation.url.format': 'Formato url errato, deve iniziare con https:// o http://',
  'validation.password.length': 'La password deve essere di almeno 7 caratteri',
  'validation.password.letters': 'La password deve contenere numeri e lettere',
  'validation.startTime': "L'ora di inizio non può essere successiva o uguale all'ora di fine",
  'validation.endTime': "L'ora di fine non può essere precedente o uguale all'ora di inizio",
  'tooltip.password.title': 'La password deve:',
  'tooltip.password.characters': 'Avere almento 7 caratteri',
  'tooltip.password.number': 'Contenere un numero',
  'tooltip.password.letter': 'Contenere una lettera',
  'tooltip.location': 'Location tooltip',
  'footer.support.center': 'Hai bisogno di aiuto? Contattaci',
  'not.found': 'Non Trovato',
  'input.cancelReason.label': 'Motivo di Annullamento',
  'input.location.label': 'Sede',
  'input.location.placeholder': 'Nome della sede',
  'input.name.label': 'Nome',
  'title.recentlyAddedClients': 'Clienti (Aggiunti di Recente)',
  'title.calendarView': 'Vista Calendario',
  'text.manageProfile': 'Gestisci le impostazioni del tuo profilo',
  'modal.language.title': 'Scegli la lingua',
  'modal.language.placeholder': 'Cerca lingua',
  'dropdown.access.medium': 'Medio',
  'dropdown.access.high': 'Alto',
  'dropdown.access.low': 'Basso',
  'dropdown.access.basic': 'Di base',
  'dropdown.access.no': 'Nessun Accesso',
  'dropdown.access.owner': 'Proprietario',
  'button.select.all': 'Seleziona tutto',
  'button.selected': 'Selezionato',
  'dropdown.gender.unknown': 'Sconosciuto',
  'dropdown.gender.male': 'Maschio',
  'dropdown.gender.female': 'Femmina',
  'dropdown.notificationBy.none': 'Non inviare',
  'dropdown.notificationBy.sms': 'SMS',
  'dropdown.notificationBy.email': 'Email',
  'dropdown.notificationBy.combined': 'SMS e Email',
  'notification.success.login': 'Log in avvenuto con successo!',
  'notification.success.register': 'Registrazione avvenuta con successo!',
  'dropdown.time.minutes': 'minuti',
  'dropdown.repeat.no': 'Non ripetere',
  'dropdown.repeat.weekly': 'Settimanalmente',
  'dropdown.endRepeat.ongoing': 'Continuativo',
  'dropdown.endRepeat.weekly': 'Data Specifica',
  'dropdown.time.appointment.immediately': 'Subito',
  'dropdown.time.appointment.upTo1Hour': 'Fino a 1 ora',
  'dropdown.time.appointment.upTo2Hours': 'Fino a 2 ore',
  'dropdown.time.appointment.upTo3Hours': 'Fino a 3 ore',
  'dropdown.time.appointment.upTo4Hours': 'Fino a 4 ore',
  'dropdown.time.appointment.upTo5Hours': 'Fino a 5 ore',
  'dropdown.time.appointment.upTo6Hours': 'Fino a 6 ore',
  'dropdown.time.appointment.upTo7Hours': 'Fino a 7 ore',
  'dropdown.time.appointment.upTo8Hours': 'Fino a 8 ore',
  'dropdown.time.appointment.upTo9Hours': 'Fino a 9 ore',
  'dropdown.time.appointment.upTo10Hours': 'Fino a 10 ore',
  'dropdown.time.appointment.upTo11Hours': 'Fino a 11 ore',
  'dropdown.time.appointment.upTo12Hours': 'Fino a 12 ore',
  'dropdown.time.appointment.upTo13Hours': 'Fino a 13 ore',
  'dropdown.time.appointment.upTo14Hours': 'Fino a 14 ore',
  'dropdown.time.appointment.upTo15Hours': 'Fino a 15 ore',
  'dropdown.time.appointment.upTo16Hours': 'Fino a 16 ore',
  'dropdown.time.appointment.upTo17Hours': 'Fino a 17 ore',
  'dropdown.time.appointment.upTo18Hours': 'Fino a 18 ore',
  'dropdown.time.appointment.upTo19Hours': 'Fino a 19 ore',
  'dropdown.time.appointment.upTo20Hours': 'Fino a 20 ore',
  'dropdown.time.appointment.upTo21Hours': 'Fino a 21 ore',
  'dropdown.time.appointment.upTo22Hours': 'Fino a 22 ore',
  'dropdown.time.appointment.upTo23Hours': 'Fino a 23 ore',
  'dropdown.time.appointment.upTo24Hours': 'Fino a 1 giorno',
  'dropdown.time.appointment.upTo48Hours': 'Fino a 2 giorni',
  'dropdown.time.appointment.upTo3days': 'Fino a 3 giorni',
  'dropdown.time.appointment.upTo4days': 'Fino a 4 giorni',
  'dropdown.time.appointment.upTo5days': 'Fino a 5 giorni',
  'dropdown.time.appointment.upTo6days': 'Fino a 6 giorni',
  'dropdown.time.appointment.upTo1week': 'Fino a 1 settimana',
  'dropdown.time.appointment.upTo2weeks': 'Fino a 2 settimane',
  'dropdown.time.appointment.upTo15Min': 'Fino a 15 minuti',
  'dropdown.time.appointment.upTo30Min': 'Fino a 30 minuti',
  'dropdown.time.appointment.upTo1Month': 'Fino a 1 mese',
  'dropdown.time.appointment.upTo2Months': 'Fino a 2 mesi',
  'dropdown.time.appointment.upTo3Months': 'Fino a 3 mesi',
  'dropdown.time.appointment.upTo4Months': 'Fino a 4 mesi',
  'dropdown.time.appointment.upTo5Months': 'Fino a 5 mesi',
  'dropdown.time.appointment.upTo6Months': 'Fino a 6 mesi',
  'dropdown.time.appointment.interval5Min': '5 minuti',
  'dropdown.time.appointment.interval10Min': '10 minuti',
  'dropdown.time.appointment.interval15Min': '15 minuti',
  'dropdown.time.appointment.interval20Min': '20 minuti',
  'dropdown.time.appointment.interval30Min': '30 minuti',
  'dropdown.time.appointment.interval40Min': '40 minuti',
  'dropdown.time.appointment.interval45Min': '45 minuti',
  'dropdown.time.appointment.interval50Min': '50 minuti',
  'dropdown.time.appointment.interval60Min': '60 minuti',
  'dropdown.time.appointment.interval75Min': '75 minuti',
  'dropdown.time.appointment.interval90Min': '90 minuti',
  'dropdown.time.appointment.interval120Min': '120 minuti',
  'dropdown.time.appointment.interval150Min': '150 minuti',
  'dropdown.time.appointment.interval180Min': '180 minuti',
  'dropdown.time.appointment.interval240Min': '240 minuti',
  'dropdown.time.appointment.anyTime': 'In qualsiasi momento',
  'dropdown.time.appointment.disabled': 'Spento',
  'dropdown.time.appointment.doesntRepeat': 'Non si ripete',
  'dropdown.time.appointment.daily': 'Quotidiano',
  'dropdown.time.appointment.every2Days': 'Ogni 2 giorni',
  'dropdown.time.appointment.every3Days': 'Ogni 3 giorni',
  'dropdown.time.appointment.every4Days': 'Ogni 4 giorni',
  'dropdown.time.appointment.every5Days': 'Ogni 5 giorni',
  'dropdown.time.appointment.every6Days': 'Ogni 6 giorni',
  'dropdown.time.appointment.every7Days': 'Ogni 7 giorni',
  'dropdown.time.appointment.weekly': 'settimanalmente',
  'dropdown.time.appointment.every2weeks': 'Ogni 2 settimane',
  'dropdown.time.appointment.every3weeks': 'Ogni 3 settimane',
  'dropdown.time.appointment.every4weeks': 'Ogni 4 settimane',
  'dropdown.time.appointment.every5weeks': 'Ogni 5 settimane',
  'dropdown.time.appointment.every6weeks': 'Ogni 6 settimane',
  'dropdown.time.appointment.every7weeks': 'Ogni 7 settimane',
  'dropdown.time.appointment.every8weeks': 'Ogni 8 settimane',
  'dropdown.time.appointment.every9weeks': 'Ogni 9 settimane',
  'dropdown.time.appointment.every10weeks': 'Ogni 10 settimane',
  'dropdown.time.appointment.monthly': 'Mensile',
  'dropdown.time.appointment.every2months': 'Ogni 2 mesi',
  'dropdown.time.appointment.every3months': 'Ogni 3 mesi',
  'dropdown.time.appointment.every4months': 'Ogni 4 mesi',
  'dropdown.time.appointment.every5months': 'Ogni 5 mesi',
  'dropdown.time.appointment.every6months': 'Ogni 6 mesi',
  'dropdown.time.appointment.every7months': 'Ogni 7 mesi',
  'dropdown.time.appointment.every8months': 'Ogni 8 mesi',
  'dropdown.time.appointment.every9months': 'Ogni 9 mesi',
  'dropdown.time.appointment.every10months': 'Ogni 10 mesi',
  'dropdown.time.appointment.every11months': 'Ogni 11 mesi',
  'dropdown.time.appointment.after2times': 'Dopo 2 volte',
  'dropdown.time.appointment.after3times': 'Dopo 3 volte',
  'dropdown.time.appointment.after4times': 'Dopo 4 volte',
  'dropdown.time.appointment.after5times': 'Dopo 5 volte',
  'dropdown.time.appointment.after6times': 'Dopo 6 volte',
  'dropdown.time.appointment.after7times': 'Dopo 7 volte',
  'dropdown.time.appointment.after8times': 'Dopo 8 volte',
  'dropdown.time.appointment.after9times': 'Dopo 9 volte',
  'dropdown.time.appointment.after10times': 'Dopo 10 volte',
  'dropdown.time.appointment.after11times': 'Dopo 11 volte',
  'dropdown.time.appointment.after12times': 'Dopo 12 volte',
  'dropdown.time.appointment.after13times': 'Dopo 13 volte',
  'dropdown.time.appointment.after14times': 'Dopo 14 volte',
  'dropdown.time.appointment.after15times': 'Dopo 15 volte',
  'dropdown.time.appointment.after20times': 'Dopo 20 volte',
  'dropdown.time.appointment.after25times': 'Dopo 25 volte',
  'dropdown.time.appointment.after30times': 'Dopo 30 volte',
  'dropdown.time.appointment.specificDate': 'Data specifica',
  'modal.createHours.title': 'Crea {0} ore',
  'modal.editHours.title': 'Modifica {0} ore',
  'modal.deleteItem.confirm.0': 'Sei sicuro di volerlo eliminare definitivamente dal database?',
  'modal.deleteItem.confirm.1': '',
  'modal.deleteItem.confirm.2': '',
  'modal.entity.category': 'Categoria',
  'modal.entity.referal': 'Fonte di riferimento',
  'modal.entity.resource': 'Risorsa',
  'modal.entity.location': 'Sede',
  'modal.deleteReminder.title': 'Cancellare il promemoria',
  'modal.deleteMessage.title': 'Cancellare il messaggio',
  'modal.deleteReminder.description': 'Siete sicuri di voler cancellare questo promemoria ?',
  'modal.deleteMessage.description': 'Sei sicuro di voler cancellare questo messaggio?',
  'modal.deleteReminder.confirm': 'Sì, eliminare',
  'modal.deletePaymentMethod.title': 'Cancellare il metodo di pagamento',
  'modal.deletePaymentMethod.description': 'Questo metodo di pagamento verrà eliminato definitivamente',
  'closedDate.create.modal.title': 'Aggiungi data di chiusura',
  'closedDate.edit.modal.title': 'Modifica data di chiusura',
  'staff.access.owner': 'Proprietario',
  'staff.access.high': 'Alto',
  'staff.access.medium': 'Medio',
  'staff.access.low': 'Basso',
  'staff.access.basic': 'Di Base',
  'staff.access.no_access': 'Nessun Accesso',
  'staff.create.title': 'Aggiungi data di chiusura',
  'staff.emptyState.title': 'Nessun membro del personale programmato.',
  'staff.emptyState.description':
    'Consulta la sezione relativa ai membri dello staff per aggiungere gli orari di lavoro.\n',
  'staff.viewStaffMembers.button': 'Visualizza i membri dello staff',
  'staff.picture.changePhoto': 'Cambia foto',
  'staff.picture.removePhoto': 'Rimuovi foto',
  'input.clientSearch.placeholder': 'Cerca per nome, email o cellulare',
  'input.blockedDate.label': 'Data',
  'appointment.notes.title': "Note dell'Appuntamento",
  'appointment.history.title': 'Cronologia degli appuntamenti',
  'appointment.history.by.title': ' da {0}',
  'appointment.log.created.label': 'Creato da',
  'appointment.log.updated.label': 'Aggiornato da',
  'appointment.log.rescheduled.label': 'Riprogrammato da',
  'appointment.log.cancelled.label': 'Annullato da',
  'appointment.log.changed.label': 'Stato modificato da',
  'appointment.notificationLog.rescheduling': 'Notifica di riprogrammazione inviata il',
  'appointment.notificationLog.cancellation': 'Notifica di cancellazione inviata',
  'appointment.notificationLog.confirmation': 'Notifica di conferma inviata',
  'appointment.notificationLog.reminder': 'Notifica di promemoria inviata',
  'appointment.notificationLog.thankYou': 'Grazie notifica inviata',
  'appointment.notificationLog.lastVisit': "Notifica dell'ultima visita inviata",
  'state.cancelReason.title': 'Configura motivi di annullamento',
  'state.cancelReason.description':
    "I motivi di annullamento ti aiutano a capire perché i tuoi clienti non si siano presentati all'appuntamento.",
  'state.cancelReason.button': 'Aggiungi motivo di annullamento',
  'state.resources.title': 'Gestisci le tue risorse',
  'state.resources.description':
    'La tua attività otterrà un maggior successo solo se sei ben a conoscenza delle risorse disponibili. Tieni traccia di stanze, mobili e qualsiasi altro dispositivo per assicurare un servizio costante senza interruzioni.',
  'state.resources.button': 'Aggiungi risorsa',
  'state.referralSource.title': 'Gestisci le Fonti di Riferimento',
  'state.referralSource.description':
    'Una fonte di riferimento indica la provenienza dei clienti. Tienine traccia e usa queste informazioni per incrementare la visibilità e il tasso di conversione della tua attività.',
  'state.referralSource.button': 'Aggiungi fonte di riferimento',
  'state.locations.title': 'Imposta le sedi',
  'state.locations.description':
    'Qui puoi gestire le diverse sedi della tua attività, impostare diversi orari di lavoro del tuo staff per ciascuna sede e avere calendari personalizzati per ciascuna.',
  'state.staff.title': 'Gestisci il tuo staff',
  'state.staff.description':
    'Crea dei profili per i tuoi impiegati, così da poter aggiungere e modificare le loro agende, tenendo traccia dei loro appuntamenti e degli orari di lavoro.',
  'state.messageLog.title': 'Tieni traccia dei messaggi',
  'state.messageLog.description':
    'I messaggi tengono informati i clienti di futuri appuntamenti. Qui potrai tracciare i messaggi e il loro stato. Per configurare i messaggi automatizzati, vai su',
  'state.messageLog.link': 'Impostazioni di Notifica',
  'state.clients.title': 'Gestisci i tuoi clienti',
  'state.clients.description':
    "Prendersi cura dei propri clienti comincia con l'assicurarsi che il loro profilo contenga tutte le informazioni necessarie per contattarli e per avere un'esperienza senza intoppi.",
  'state.clients.button': 'aggiungi cliente',
  'state.services.title': 'Configura gruppi e servizi',
  'state.services.description':
    'I gruppi sono essenziali, in quanto ti consentono di raggruppare servizi simili da te offerti. Oltre a questo, rendono il tuo calendario più piacevole alla vista e più facilmente accessibile!',
  'state.services.button': 'Aggiungi gruppo di servizi',
  'state.calendar.title': 'Nessun Personale Previsto',
  'state.calendar.description': 'Per configurare le ore di lavoro dello staff, visualizza la Sezione Staff',
  'state.calendar.button': '',
  'state.closedDates.title': 'Date chiuse',
  'state.closedDates.description':
    'Elenca le date in cui la tua attività è chiusa per festività, manutenzione o qualsiasi altro motivo. In questi giorni i clienti non potranno effettuare prenotazioni online.',
  'state.closedDates.button': 'Aggiungi data di chiusura',
  'title.billingPlan': 'Piano e Fatturazione',
  'btn.upgrade': 'Aggiorna',
  'header.usage': 'UTILIZZO',
  'header.dropdown.subscription.plan': '{0} Piano',
  'header.dropdown.subscription.staff': '({0} Membri dello staff)',
  'header.dropdown.notifications.description': '{0} messaggi',
  'header.paymentDetails': 'Visualizza i dettagli del pagamento',
  'title.billingPlan.description':
    'Gestisci i tuoi account. Visualizza i dettagli sul piano tariffario, le transazioni e scarica le fatture.',
  'plan.free.title': 'Starter',
  'plan.individual.title': 'Individual (1 solo membro dello staff)',
  'plan.business.title': 'Business',
  'btn.upgradeNow': 'Aggiorna',
  'btn.getStarted': 'Iniziare',
  'btn.currentPlan': 'Piano Corrente',
  'plan.free.option.0': '1 Posizione',
  'plan.free.option.1': 'Fino a 5 dipendenti',
  'plan.free.option.2': 'Appuntamenti illimitati',
  'plan.free.option.3': 'Clienti illimitati',
  'plan.free.option.4': 'Prenotazioni online illimitate',
  'plan.free.option.5': 'notifiche di posta elettronica',
  'plan.premium.option.0': 'Posizioni illimitate',
  'plan.premium.option.1': 'Appuntamenti illimitati',
  'plan.premium.option.2': 'Clienti illimitati',
  'plan.premium.option.3': 'Prenotazioni online illimitate',
  'plan.premium.option.4': 'Appuntamenti ricorrenti',
  'plan.premium.option.5': 'notifiche di posta elettronica',
  'plan.premium.option.6': 'Notifiche SMS* ',
  'plan.premium.option.7': 'Notifiche Whatsapp*',
  'plan.premium.option.8': 'Analisi illimitata',
  'plan.premium.option.9': 'Rapporti illimitati',
  'plan.premium.option.10': 'Stampa orari',
  'plan.premium.option.11': 'Esportazione cliente',
  'plan.premium.option.12': 'Supporto prioritario',
  'plan.premium.option.13': 'Rimosso il marchio Plandok dalle e-mail (e-mail gratuite fornite da Plandok)',
  'plan.premium.costsInfo': '*Costi SMS/WhatsApp non inclusi nel piano, si applicano costi aggiuntivi.',
  'plan.premium.activeUntilDate': 'Attivo fino a quando {0}',
  'period.perMonth': 'al mese (IVA escl.)',
  'plan.premium.description': "Il prezzo aumenterà con l'aggiunta di altri membri del personale.",
  'plan.premium.viewPricing': 'Visualizza i prezzi',
  'plan.premium.numberStuff': 'Il numero attuale di dipendenti: {0}',
  'premium.membership':
    "Il prezzo dell'abbonamento Premium può variare a seconda del numero di dipendenti di cui disponi.",
  'premium.price.perMonth': 'Prezzo premium al mese',
  'pricing.calculated': 'Il prezzo è calcolato come segue:',
  'pricing.calculator': 'Calcolatore prezzo',
  'pricing.numberStuff': 'Numero di personale',
  'pricing.extra.member': '{0} per ogni membro in più',
  'modal.title.pricing': 'Prezzo',
  'plan.foreverFree': 'Per sempre gratis',
  'plan.individual.0': 'Tutto quello del pacchetto starter, più:',
  'plan.individual.1': 'Notifiche personalizzate',
  'plan.individual.2': 'Rimuovi il branding Plandok',
  'plan.individual.option.3': 'Promemoria SMS illimitati',
  'plan.individual.4': 'Supporto prioritario',
  'plan.business.0': 'Tutto quello del pacchetto individual, più:',
  'plan.business.1': 'Sedi illimitate',
  'plan.business.2': 'Membri dello staff illimitati',
  'block.billingInfo.title': 'Informazioni di Fatturazione',
  'block.carInfo.title': 'Dati della Carta di Credito',
  'input.cardNumber.label': 'Numero di carta',
  'input.cardNumber.placeholder': '1234 5678 3456 2456',
  'input.expireDate.label': 'Data di scadenza',
  'input.expireDate.placeholder': '05 / 21',
  'input.cvv.label': 'CVV',
  'input.cvv.placeholder': '123',
  'modal.confirmCancel.subscription': "Sei sicuro di voler annullare l'iscrizione?",
  'modal.confirmCancel.subscription.description': 'Se lo fai, tornerai ad utilizzare la versione gratuita.',
  'btn.cancelSubscription.confirm': 'Sì, annulla la mia iscrizione',
  'loading.processingSubscribtion': 'Il tuo pagamento è in fase di elaborazione. Per favore, attendi…',
  'state.serviceGroup.description': 'Nessun servizio nel gruppo',
  'state.serviceGroup.btn': 'Aggiungi servizio',
  'appointment.status.new': 'Nuovo Appuntamento',
  'appointment.status.confirmed': 'Confermato',
  'appointment.status.notShow': 'Mancata Presentazione',
  'appointment.status.completed': 'Completato',
  'appointment.status.cancelled': 'Annullato',
  'billing.tab.plan': 'Il tuo piano',
  'billing.tab.costs': 'Costi stimati',
  'billing.tab.billing': 'Informazioni di Fatturazione',
  'billing.tab.invoice': 'Fatture',
  'plan.free': 'Gratis',
  'plan.deactivated.title': 'Premium è stato disattivato',
  'plan.active.title': 'Attivare',
  'payment.step.title': 'Pagamenti',
  'payment.error.title': 'Aggiorna il pagamento',
  'payment.payNow.title': 'Paga',
  'payment.dismiss.title': 'Congedare',
  'payment.tab.title.sub': 'Sottoscrizione',
  'payment.tab.subTitle': 'Piano premium',
  'payment.per.members': 'per membro',
  'payment.tab.title.notification': 'Notifiche',
  'payment.card.details': 'Estremi della carta',
  'payment.success.message': 'Il pagamento è andato a buon fine',
  'payment.payWithCard.title': 'Paga con carta',
  'payment.cardNumber.title': 'Numero di carta',
  'payment.expirationDate.title': 'Data di scadenza',
  'payment.digits.title': '(3 cifre)',
  'payment.amount': 'Importo del pagamento:',
  'payment.btn.pay': 'Paga',
  'invoice.table.date': 'Data',
  'invoice.table.invoice': 'Fattura',
  'invoice.table.download': 'Download',
  'invoice.table.status': 'Stato',
  'costs.billingPeriod.title': 'Costi stimati per questo periodo di fatturazione',
  'costs.billingPeriod.description':
    "Si tratta di un costo corrente per l'utilizzo in questo periodo di fatturazione. Di seguito è disponibile una ripartizione dei costi.",
  'costs.billingPeriod.additionalInfo':
    "Quando le spese per le notifiche raggiungeranno i 50 €, verranno addebitate automaticamente. Ma non preoccupatevi se l'importo è inferiore: sarà comodamente incluso nel vostro abbonamento mensile.",
  'costs.summary.title': 'Riepilogo da inizio mese',
  'costs.summary.description': 'Questi addebiti vengono calcolati nel saldo del tuo account',
  'costs.summary.detailedCharges': 'Spese dettagliate',
  'costs.summary.noData': 'Nessun dato',
  'costs.summary.quantity': 'Quantità',
  'costs.summary.subtotal': 'Totale parziale',
  'costs.summary.taxes': 'Le tasse',
  'dropdown.time.hours': 'ore',
  'message.planUpgrade': 'Upgrade del tuo piano effettuato con successo!',
  'costs.subscription': 'Sottoscrizione',
  'btn.cancelSubscription': 'Annulla iscrizione',
  'btn.no': 'No',
  'notification.success.update': 'Aggiornato con successo',
  'validation.password.match': 'La password e la conferma della password non combaciano',
  'blocked.time': 'Slot di tempo bloccato',
  'message.successReset.password':
    "Fatto! L'email per il reset della password è stata inviata, controlla nella tua posta in arrivo.",
  'title.newPassword': 'Inserisci la nuova password',
  'title.newPassword.description': 'Accedi in modo sicuro al tuo account creando una nuova password di accesso',
  'input.newPasswordRepeat.label': 'Conferma la nuova password',
  'btn.change.password': 'Cambia la Mia Password',
  'text.havingTrouble': "C'è qualche problema?",
  'link.resetPassword.again': 'Resetta nuovamente la tua password',
  'message.successNew.password': 'Password cambiata con successo',
  'title.repeatShifts': 'Turno periodico',
  'text.confirmShift.update':
    'Hai modificato un turno che si ripete settimanalmente. La cancellazione di turni futuri sovrascriverà i programmi continuativi di {0} di {0}',
  'text.confirmShift.delete':
    'Hai modificato un turno che si ripete settimanalmente. La cancellazione di turni futuri sovrascriverà i programmi continuativi di {0} di {0}',
  'btn.deleteUpcoming.shifts': 'Cancella turni futuri',
  'btn.deleteThis.shift': 'Cancella solo questo turno',
  'btn.updateUpcoming.shifts': 'Aggiorna turni futuri',
  'btn.updateThis.shift': 'Aggiorna solo questo turno',
  'text.permisionTab':
    "Configura quali sezioni sono disponibili per ciascun livello di permessi utente. Tutto lo staff collegato può acccedere al calendario, e gli account proprietari hanno l'accesso completo al sistema.",
  'title.permission.locations': 'Sedi',
  'title.permission.booking': 'Prenotazioni e Clienti',
  'title.permission.staff': 'Personale',
  'title.permission.setup': 'Configurazione',
  'title.permission.basic': 'Di Base',
  'title.permission.low': 'Basso',
  'title.permission.med': 'Medio',
  'title.permission.high': 'Alto',
  'title.permission.owner': 'Proprietario',
  'title.allLocations.permission': 'Accede a Tutte le Sedi',
  'title.accessCalendar.permission': 'Accede alla Sua Agenda',
  'title.accessOtherCalendar.permission': "Accede all'Agenda di altri membri del Personale",
  'title.canBook.permission': 'Può Prenotare Appuntamenti',
  'title.contactInfo.permission': 'Può visualizzare le informazioni di contatto del cliente',
  'title.clients.permission': 'Clienti',
  'title.messages.permission': 'Messaggi',
  'title.services.permission': 'Servizi',
  'title.workingHours.permission': 'Orario di Lavoro',
  'title.staffMembers.permission': 'Membri dello Staff',
  'title.permissionLevels.permission': 'Livelli di Permesso',
  'title.accountSetup.permission': "Impostazioni dell'Account",
  'title.clientSettings.permission': 'Impostazioni del Cliente',
  'title.onlineBookings.permission': 'Prenotazioni on-line',
  'title.analytics.permission': 'Analisi',
  'title.marketing.permission': 'Marketing',
  'notification.type.reminder': 'Promemoria',
  'notification.type.confirmation': 'Conferma',
  'notification.type.online_booking_confirmation': 'Conferma della prenotazione online',
  'notification.type.rescheduling': 'Rinvia',
  'notification.type.cancellation': 'Annullamento',
  'notification.type.thank_you': 'Ringraziamento',
  'notification.type.last_visit': 'Ultima visita',
  'notification.channel.sms': 'SMS',
  'notification.channel.email': 'Email',
  'notification.channel.whatsapp': 'WhatsApp',
  'notification.status.sent': 'Inviato',
  'notification.status.pending': 'In attesa',
  'notification.status.failed': 'Fallito',
  'dropdown.time.days': 'giorni',
  'title.appointment': 'appuntamento',
  'input.reminderBeforeLastVisit.label': 'La notifica sarà inviata dopo il numero di giorni indicato',
  'txt.with': 'con',
  'short.hours': 'h',
  'short.minutes': 'minuti',
  'input.registrationNumber.label': 'Numero aziendale',
  'input.vatNumber.label': 'Numero di partita IVA',
  'invoice.table.priceTaxIncl': 'Importo',
  'error.wrongSenderId': "L'ID mittente deve includere cifre o caratteri, devono esserci più di 3 e meno di 11 simboli",
  'sidebar.help': 'Aiuto',
  'modal.help.title': 'Come possiamo aiutarvi?',
  'modal.help.liveChat.title': 'Chat dal vivo',
  'modal.help.liveChat.description': 'Avete domande? Chiedetecelo subito',
  'modal.help.suggestFeature.title': 'Suggerisci una funzionalità',
  'modal.help.suggestFeature.description':
    'Condividi i suggerimenti, vedi quelli degli altri e vota per le funzionalità che desideri',
  'modal.help.viewUpdates.title': 'Visualizza gli aggiornamenti',
  'modal.help.viewUpdates.description': 'Visualizza i nostri ultimi aggiornamenti e miglioramenti',
  'warning.title.paidFeature': 'Funzionalità a Pagamento',
  'warning.smsFeatureMissing':
    "Per accedere alle impostazioni SMS, devi aggiornare il tuo piano. Per fare l'upgrade visita",
  'warning.link.billingAndPricing': 'Piano e Fatturazione',
  'warning.newPage.0': 'è stato aggiornato!',
  'warning.newPage.1': 'Se usavi una vecchia versione di Plandok, la tua password non funzionerà più, per favore',
  'warning.newPage.2': 'resetta la password',
  'warning.newPage.3': 'per goderti il nuovo sistema. Per maggiori informazioni, contattaci',
  'warning.newPage.4': 'o',
  'title.blockTime.select': 'Seleziona un orario',
  'calendar.allResources': 'Tutte le Risorse',
  'appointment.edit.title': 'Modifica Appuntamento',
  'appointment.create.title': 'Appuntamento creato con successo.',
  'appointment.update.title': 'Appuntamento aggiornato con successo.',
  'select.notify.title': "{0} non funziona nell'intervallo selezionato, ma puoi comunque prenotare gli appuntamenti.",
  'booking.notify.title': 'Il tempo selezionato è prenotato, ma puoi creare un appuntamento.',
  'textArea.characters.left': 'caratteri rimanenti',
  'textArea.characters.exceeded': 'il limite è stato superato di',
  'validation.characters.exceeded': 'Il limite di caratteri è stato superato',
  'staff.menu.members': 'Membri del Personale',
  'staff.menu.dates': 'giorni non lavorativi',
  'staff.menu.working': 'Orari del Personale',
  'staff.menu.permissions': "Permessi dell'Utente",
  'staff.menu.members.description': 'Aggiungi o rimuovi membri del team e controlla il loro livello di accesso',
  'staff.menu.dates.description': 'Pianifica le prossime chiusure aziendali o giorni festivi',
  'staff.menu.working.description':
    "Gestisci la disponibilità sull'agenda modificando gli orari di lavoro del personale",
  'staff.menu.permissions.description': 'Configura i permessi di accesso a funzionalità e informazioni sensibili',
  'message.successUpdateLocation': 'Fatto! Il profilo della località è stato aggiornato con successo.',
  'ob.title': 'Prenotazione online',
  'ob.overview.tab': 'Panoramica',
  'ob.massMessages.tab': 'Messaggi di massa',
  'ob.campaigns.tab': 'Campagne',
  'ob.profile.tab': 'Profilo in linea',
  'ob.links.tab': 'Collegamenti',
  'ob.settings.tab': 'Impostazioni',
  'ob.payments.tab': 'Pagamenti',
  'ob.status.online': 'in linea',
  'ob.status.offline': 'disconnesso',
  'ob.status.title': 'Stato',
  'ob.profile.step': '{0} di 4',
  'ob.profile.step.title': 'Profilo e posizione',
  'ob.profile.step.description':
    'Presenta la tua filiale e presenta i servizi forniti ai tuoi clienti. La parte "About" è una delle prime sezioni che i tuoi clienti esamineranno e se tali informazioni non sono sufficienti, assicurati di lasciare un numero di telefono in cui i clienti possono contattarti per ulteriori informazioni! Inserisci l\\\'indirizzo in cui fornisci i tuoi servizi, in modo che i tuoi clienti possano essere informati e visualizzare l\\\'indirizzo.',
  'ob.overview.placheholder.title': 'Lascia che i tuoi clienti prenotino appuntamenti online!',
  'ob.overview.placheholder.description':
    'Crea un profilo di prenotazioni online e consenti ai tuoi clienti di prenotare appuntamenti online. Inserisci semplicemente un link al tuo sito web o ai tuoi social media e il gioco è fatto.',
  'ob.overview.start.now': 'Parti ora',
  'ob.links.direct.title': 'Collegamento diretto',
  'ob.links.facebook.title': 'Collegamento a Facebook',
  'ob.links.instagram.title': 'Collegamento a Instagram',
  'ob.links.direct.description':
    'URL Collegamento al tuo profilo di prenotazione online, puoi utilizzarlo sul tuo sito web o sugli account dei social media.  Se non sei sicuro di come utilizzare il collegamento contattaci.',
  'ob.links.facebook.description':
    'Condividi questo collegamento su Facebook per attirare più clienti e rendere più facile per loro trovare la tua attività e prenotare un appuntamento.',
  'ob.links.instagram.description':
    'Rendilo più semplice e collega direttamente la tua prenotazione online!\nPuoi usarlo sul tuo sito web e ovunque tu ne abbia voglia.\nSe non sei sicuro di come utilizzare il collegamento, non preoccuparti, ci pensiamo noi!',
  'ob.analytics.tab.description':
    'Aggiungete Google Analytics e Facebook Pixel alla vostra pagina di prenotazione online per analizzare il traffico e il comportamento degli utenti.',
  'ob.analytics.google.title': 'Google Analytics',
  'ob.analytics.facebook.title': 'Pixel di Facebook',
  'ob.analytics.google.description':
    'Con Google Analytics, è possibile tracciare e analizzare il traffico del sito web e il comportamento degli utenti per ottenere informazioni su come migliorare il proprio sito web.',
  'ob.analytics.active': 'Attivo',
  'ob.analytics.inactive': 'Inattivo',
  'ob.analytics.setUp.btn': 'Configurato',
  'ob.analytics.google.description.1':
    'Google Analytics è uno strumento potente, che può aiutarvi a monitorare il traffico del sito web e il comportamento degli utenti. Con Google Analytics è possibile monitorare il numero di visitatori del vostro sito web, la loro provenienza, le pagine visitate e il tempo di permanenza sul sito.',
  'ob.analytics.google.description.2':
    'Inoltre, Google Analytics consente di impostare obiettivi e di tracciare le conversazioni, consentendo di comprendere meglio le prestazioni del vostro sito web e di migliorarle.',
  'ob.analytics.google.trackingId': 'ID di tracciamento',
  'ob.analytics.google.tooltip':
    "Per trovare il vostro ID di monitoraggio, dovete innanzitutto accedere al vostro account Google Analytics, selezionare il sito web o l'app che desiderate monitorare, quindi passare alla scheda 'Amministrazione'. Da qui, fare clic su 'Codice di monitoraggio'. L'ID di tracciamento verrà visualizzato nel formato 'UA-XXXX-Y'.",
  'ob.analytics.google.account': 'Non avete ancora un account Google Analytics?',
  'ob.analytics.click': 'Fare clic',
  'ob.analytics.here': 'qui',
  'ob.analytics.learn': 'per sapere come crearne uno.',
  'ob.analytics.facebook.description.1':
    'Facebook Pixel è uno strumento che consente di tracciare il comportamento degli utenti sul vostro sito web, comprese le conversioni, le visualizzazioni di pagina e altri eventi.',
  'ob.analytics.facebook.description.2':
    "Il Pixel di Facebook può fornirvi informazioni preziose sul vostro pubblico, come dati demografici, interessi, tipo di dispositivo, pagine viste e azioni intraprese, che possono aiutarvi a comprendere meglio e ottimizzare l'esperienza degli utenti sul vostro sito.",
  'ob.analytics.facebook.pixelId': 'ID pixel',
  'ob.analytics.facebook.tooltip':
    "Per trovare il vostro Pixel ID di Facebook, procedete come segue: Accedere al proprio account Facebook Ads Manager. Fate clic sul pulsante 'Menu' nell'angolo superiore sinistro dello schermo. Nella sezione 'Gestione eventi', selezionate 'Pixel'. Qui dovrebbe essere visualizzato un elenco di tutti i pixel associati al vostro account. Fare clic sul nome del pixel per il quale si desidera ottenere l'ID. L'ID del pixel verrà visualizzato nell'angolo in alto a sinistra dello schermo, accanto al nome del pixel.",
  'ob.analytics.facebook.account': 'Non avete ancora una pagina aziendale su Facebook?',
  'ob.analytics.google.account.mobile.1': 'Non avete ancora una pagina aziendale su Facebook?\n',
  'ob.analytics.google.account.mobile.2': 'Google Analytics? Fate clic su',
  'ob.analytics.google.account.mobile.3': 'per sapere come',
  'ob.analytics.google.account.mobile.4': 'crearne uno.',
  'ob.analytics.facebook.account.mobile.1': 'Non avete ancora una pagina Facebook',
  'ob.analytics.facebook.account.mobile.2': 'pagina aziendale? Fate clic su',
  'ob.analytics.facebook.account.mobile.3': 'per sapere come',
  'ob.analytics.facebook.account.mobile.4': 'crearne una.',
  'ob.analytics.successfullySaved.message': 'Salvato con successo',
  'ob.settings.booking.title': 'Disponibilità di prenotazione on-line',
  'ob.settings.booking.description':
    "Impostare l'orario in cui è possibile programmare il primo e l'ultimo appuntamento. Gestisci gli intervalli temporali degli appuntamenti e fornisci informazioni importanti ai clienti, oltre alla possibilità di scegliere un membro dello staff per il loro servizio.",
  'ob.settings.cancellation.title': 'Cancellazione e riprogrammazione',
  'ob.settings.cancellation.description':
    "Imposta l'intervallo di tempo in cui i clienti possono annullare o riprogrammare il loro appuntamento.",
  'ob.settings.locationsUrl.title': 'URL delle posizioni',
  'ob.settings.locationsUrl.description':
    'Configura e condividi un unico URL per tutte le posizioni prenotabili online. Questo è utile solo per le aziende con più sedi.',
  'ob.settings.businessName.explanation': 'Il tuo URL: book.plandok.com/nome-azienda/Location2556',
  'ob.settings.notifications.title': 'E-mail di notifica',
  'ob.settings.notifications.description':
    'Specificare il destinatario delle e -mail di notifica in merito a nuovi appuntamenti o modifiche apportate a loro.',
  'ob.settings.required.title': 'Campi obbligatori e facoltativi',
  'ob.settings.required.description':
    'Seleziona quali campi il cliente dovrà fornire quando effettua prenotazioni online.',
  'ob.settings.requiredField.title': 'Necessario',
  'ob.settings.optionalField.title': 'Opzionale',
  'ob.settings.requiredField.notification.title': 'Si è verificato un problema con le impostazioni',
  'ob.settings.requiredField.notification.description':
    'Spiacente, ma un campo deve essere obbligatorio, tranne "note".',
  'ob.settings.nameField.title': 'Nome',
  'ob.settings.lastNameField.title': 'Cognome',
  'ob.settings.phoneField.title': 'Numero di telefono',
  'ob.settings.emailField.title': 'E-mail',
  'ob.settings.notes.label': 'Testo segnaposto per le note',
  'ob.settings.notes.placeholder': 'Es. Inserisci le tue note',
  'ob.bookingLimit.title': 'Limite di prenotazione online',
  'ob.bookingLimit.description': 'Specificare il numero massimo di prenotazioni di servizi che si desidera impostare.',
  'ob.bookingLimit.label': 'Limitare le prenotazioni dei servizi',
  'ob.bookingLimit.unlimited.title': 'Illimitato',
  'ob.bookingLimit.1service.title': 'max. 1 Servizio',
  'ob.bookingLimit.2services.title': 'max. 2 Servizi',
  'ob.bookingLimit.3services.title': 'max. 3 Servizi',
  'ob.menu.edit.profile': 'Modifica Profilo',
  'ob.menu.turnOff.profile': 'Disattiva profilo',
  'ob.profile.status': 'Stato',
  'ob.overview.tab.description':
    'Fai crescere la tua attività consentendo ai tuoi clienti di prenotare appuntamenti online.',
  'ob.profile.tab.description':
    'Gestisci profili online per le tue sedi. Regola immagini, descrizioni, indirizzi e altro ancora.',
  'ob.links.tab.description':
    'Qui troverai un link per le prenotazioni online, che potrai condividere con i tuoi clienti.',
  'ob.settings.tab.description':
    'Modifica qui le impostazioni di prenotazione online, in modo che i tuoi clienti possano prenotare solo quando è conveniente per te.',
  'ob.payments.tab.description':
    'Selezionate i metodi di pagamento che i vostri clienti potranno utilizzare per pagare i servizi.',
  'ob.payments.page.description':
    'Qui potete selezionare i metodi di pagamento che i vostri clienti potranno utilizzare per pagare i servizi.',
  'ob.payOnSite.title': 'Pagamento in loco',
  'ob.payOnSite.description': 'I vostri clienti potranno pagare in loco con denaro contante.',
  'ob.payWithCard.title': 'Pagamento con carta',
  'ob.payWithCard.description': "I vostri clienti potranno pagare con carta di credito tramite l'applicazione Stripe.",
  'ob.payWithCard.info.1': 'State utilizzando il piano gratuito di Plandok, quindi dovrete pagare',
  'ob.payWithCard.info.2': 'Plandok e Stripe utilizzando questo metodo di pagamento.',
  'ob.payWithCard.info.3': ' per avere',
  'ob.payWithCard.info.4': 'commissioni Plandok.',
  'ob.payWithCard.info.5': 'Il piano Premium non esonera dal pagamento delle commissioni di Stripe. ',
  'ob.payWithCard.info.6': 'Controllare i prezzi di Stripe qui',
  'ob.stripeConnected.info': '(Stripe è collegato)',
  'ob.requiresSetup.info': "(Richiede la configurazione dell'account Stripe)",
  'ob.connectToStripe.btn': 'Connettersi a Stripe',
  'ob.connectToStripe.successMessage.title': 'Connessione riuscita a Stripe',
  'ob.waitingAuthorization.title': "Attendere l'autorizzazione in Stripe",
  'ob.stripeDashboard.title': 'Tutti i log dei pagamenti e i rimborsi sono disponibili in ',
  'ob.stripeDashboard.url': 'Cruscotto Stripe',
  'ob.yourStripeAccount.title': 'Il vostro account Stripe',
  'ob.visitStripe.option': 'Visita Stripe',
  'ob.changeAccount.option': 'Cambia account Stripe',
  'input.earliestAppointment.label': 'Primo appuntamento',
  'input.earliestAppointment.placeholder': "Subito prima dell'appuntamento",
  'input.latestAppointment.label': 'Ultimo appuntamento',
  'input.latestAppointment.placeholder': 'Non più di 6 mesi prima',
  'input.timeSlots.label': 'Intervalli di fascia oraria',
  'input.timeSlots.placeholder': '15 minuti',
  'checkbox.allowCustomers': 'Consenti ai clienti di scegliere i membri dello staff per il loro appuntamento',
  'input.importantInfo.label': 'Informazioni importanti',
  'input.importantInfo.placeholder':
    "Informazioni che verranno mostrate al cliente al momento della conferma dell'appuntamento",
  'input.changeAppointment.label': 'È possibile apportare modifiche agli appuntamenti',
  'input.changeAppointment.placeholder': 'In qualsiasi momento',
  'checkbox.sendToBooked': 'Invia al membro dello staff prenotato',
  'checkbox.sendSpecificEmail': 'Invia a un indirizzo email specifico',
  'input.specificEmail.label': 'Indirizzo e-mail specifico',
  'input.specificEmail.placeholder': "Inserisci l'indirizzo email",
  'input.locationsUrl.label': 'URL delle posizioni',
  'input.locationsUrl.placeholder': 'Per esempio. Johns-Barbiere-Shop',
  'ob.btn.save': 'Salva',
  'ob.btn.cancel': 'Annulla',
  'ob.btn.showLink': 'Mostra link',
  'ob.btn.active': 'Attivo',
  'ob.btn.inactive': 'Inattivo',
  'ob.linkModal.title': 'Link diretto per le prenotazioni',
  'ob.linkModal.description':
    'Ecco il link dove potrai raggiungere direttamente i tuoi servizi di prenotazione online!',
  'ob.linkModal.afterDescription':
    'Questo collegamento indirizza gli utenti direttamente ai tuoi servizi. Con un solo clic i clienti potranno raggiungere i tuoi profili aziendali e prenotare appuntamenti.\nQuesto collegamento è facile da usare e amichevole per tutti i dispositivi, siano essi un computer, un telefono o un tablet.',
  'ob.fbLinkModal.title': 'Pagina Facebook Prenota ora',
  'ob.fbLinkModal.description':
    'Imposta un pulsante Prenota ora integrato per la tua pagina aziendale Facebook per ricevere facilmente nuove prenotazioni direttamente da Facebook.\n',
  'ob.fbLinkModal.step1': 'Accedi alla tua pagina aziendale su Facebook',
  'ob.fbLinkModal.step2': 'Fai clic su "Aggiungi un pulsante" situato vicino all\'immagine di copertina',
  'ob.fbLinkModal.step3': 'Seleziona l\'opzione "Iscriviti" e inserisci il link di prenotazione sottostante',
  'ob.linkModalCopySuccess.title': 'Link copiato!',
  'ob.btn.copyLink': 'Copia link',
  'title.appointmentSlot.select': "Seleziona l'ora",
  'btn.back': 'Indietro',
  'btn.continue': 'Continua',
  'ob.hours.open': 'Aprire',
  'ob.hours.close': 'Chiuso',
  'ob.hours.step.title': 'Ore lavorative',
  'ob.hours.step.description': 'Fai sapere ai tuoi clienti quando la tua attività apre e chiude.',
  'ob.staffHours.step.title': 'Orario di lavoro del personale',
  'ob.staffHours.step.description':
    'Un passo importante, si prega di aggiungere orari di lavoro per il sistema da sapere quando i clienti possono prenotare online.',
  'ob.location.step.title': 'Informazioni sulla posizione',
  'ob.photo.step.title': 'Fotografie',
  'ob.photo.step.description': 'Fai sapere ai tuoi clienti come appare la tua attività.',
  'input.file.dnd.photos': 'Trascina e rilascia le foto',
  'btn.publish': 'Accendere',
  'text.next': 'Prossimo',
  'input.phone.number.label': 'Numero di telefono',
  'input.state.label': 'Stato',
  'input.state.placeholder': 'Inserisci uno stato (comune)',
  'input.addressDetails.label': "Dettagli dell'indirizzo",
  'input.addressDetails.placeholder':
    "Inserisci i dettagli dell'indirizzo come il codice della porta, altri ingressi, informazioni sul parcheggio, ecc.",
  'placeholder.no.photos': 'Niente foto',
  'btn.options': 'Opzioni',
  'btn.list.profile': 'Abilitare',
  'btn.preview': 'Anteprima',
  'btn.next': 'Prossimo',
  'market.ob.pleaseWait.title': 'Attendere prego ...',
  'market.ob.location.step.title': 'Seleziona la posizione',
  'market.ob.services.step.title': 'Seleziona i servizi',
  'market.ob.employee.step.title': 'Selezionare la data e il personale',
  'market.ob.timeSlot.step.title': 'Selezionare data e ora',
  'market.ob.personalInfo.step.title': 'Pagamento',
  'market.ob.appointmentInfo.step.title': "Informazioni sull'appuntamento",
  'market.ob.informationAboutYou.title': 'Informazioni su di voi',
  'market.ob.paymentMethod.title': 'Metodo di pagamento',
  'market.ob.securePayment.title': 'Pagamento sicuro',
  'market.ob.payWithCard.title': 'Pagamento con carta',
  'market.ob.payOnSite.title': 'Pagamento in loco',
  'market.ob.payOnSite.firstDescription': 'Dovrete pagare in salone dopo la procedura.',
  'market.ob.payOnSite.secondDescription':
    'Una piccola richiesta da parte nostra: le aziende sono spesso piccole imprese indipendenti, quindi se non puoi arrivare al tuo appuntamento programmato, riprogrammare o annullarlo.',
  'market.ob.validThru.title': 'Data di validità (mese/anno)',
  'market.ob.information.title': 'Informazioni',
  'market.ob.additionalInformation.title': 'Informazioni aggiuntive',
  'market.ob.reschedulingPolicy.title': 'Politica di cancellazione/riprogrammazione',
  'market.ob.reschedulingPolicy.description':
    "Le modifiche agli appuntamenti possono essere effettuate {0} prima dell'appuntamento.",
  'market.ob.fieldsAreRequired.title': '* I campi sono obbligatori',
  'market.ob.step.title': 'Passo {0} su 3',
  'market.ob.confirm.step.title': 'Visualizza e conferma',
  'market.ob.noServices.placeholder': 'Nessun servizio ancora selezionato',
  'market.ob.input.firstName.label': '{0} Nome di battesimo',
  'market.ob.input.firstName.placeholder': 'e.g. John',
  'market.ob.input.lastName.label': '{0} Cognome',
  'market.ob.input.lastName.placeholder': 'e.g. Doe',
  'market.ob.input.phone.label': '{0} Numero di cellulare',
  'market.ob.input.phone.placeholder': '670 70 822',
  'market.ob.input.email.label': '{0} Indirizzo e-mail',
  'market.ob.input.email.placeholder': 'john.doe@gmail.com',
  'market.ob.input.description.label': 'Note',
  'market.ob.staff.assignedSpecialist.label': 'con il personale',
  'market.ob.staff.noPreference.label': 'Nessuna preferenza',
  'market.ob.timeSlots.fullyBooked.title': 'Siamo spiacenti, non abbiamo orari disponibili per questo giorno.',
  'market.ob.timeSlots.choseAnotherDay.title': 'Si prega di scegliere un altro giorno.',
  'market.ob.timeSlots.closedSlot.title': "L'orario disponibile più vicino è ",
  'market.ob.timeSlots.selectClosest.title': 'Seleziona la data più vicina',
  'market.ob.timeSlots.note.title': 'Informazioni importanti',
  'market.ob.service.duration.title': 'min',
  'market.location.workingHours.title': 'Ore lavorative',
  'market.location.monday.title': 'Lunedì',
  'market.location.tuesday.title': 'Martedì',
  'market.location.wednesday.title': 'Mercoledì',
  'market.location.thursday.title': 'Giovedì',
  'market.location.friday.title': 'Venerdì',
  'market.location.saturday.title': 'Sabato',
  'market.location.sunday.title': 'Domenica',
  'market.location.meetOurTeam.title': 'Incontra il nostro team',
  'market.location.title': 'Contattate',
  'market.location.services.title': 'Servizi',
  'market.location.about.title': 'Chi',
  'market.location.aboutUs.title': 'Chi siamo',
  'market.confirmation.appointmentCanceled.title': 'Questo appuntamento è stato cancellato',
  'market.confirmation.appointmentConfirmed.title': 'Appuntamento confermato',
  'market.confirmation.appointmentConfirmed.description': "L'appuntamento è confermato, ecco i dettagli:",
  'market.confirmation.appointmentDate.title': "Data dell'appuntamento",
  'market.confirmation.scheduledAppointment.title': 'La cancellazione non è possibile online.',
  'market.confirmation.scheduledAppointment.description':
    "Se non è possibile presentarsi all'appuntamento previsto, si prega di riprogrammarlo o cancellarlo.",
  'market.modal.cancelAppointment.title': 'È sicuro di voler cancellare questo appuntamento ?',
  'market.modal.cancelledAppointment.title': "L'appuntamento è stato cancellato",
  'market.language.changer.title': 'Lingua (Language)',
  'market.btn.bookNow': 'Registrare',
  'market.servicesAvailable.title': 'Servizi',
  'market.poweredByPlandok.title': 'Plandok',
  'market.poweredByPlandok.description': 'Vuoi un sito web simile ? Visita',
  'market.btn.readMore': 'Leggi di più',
  'market.btn.showLess': 'Mostra meno',
  'market.btn.reviews': 'Recensioni',
  'market.btn.viewMore': 'Vedi di più',
  'market.btn.cancelAppointment': "Annullare l'appuntamento",
  'market.btn.cancel': 'Sì, annullare',
  'market.wh.closed.title': 'Chiuso',
  'market.ob.success.message': 'Fatto! La prenotazione è stata creata con successo',
  'market.ob.cancelAppointment.success.message': 'Appuntamento annullato con successo',
  'market.ob.cancelAppointment.error.message': "Annullamento dell'appuntamento non riuscito",
  'market.ob.cancelAppointment.confirm.title': "Sei sicuro di voler annullare l'appuntamento?",
  'market.location.getDirections.title': 'Ottenere indicazioni',
  'market.notFound.title': 'Pagina non trovata',
  'market.notFound.btn.goBack': 'Torna indietro',
  'market.ob.quantitativeLimit.title': 'Limite quantitativo',
  'market.ob.quantitativeLimit.description':
    'Siamo spiacenti, il proprietario ha imposto un limite al numero di servizi di prenotazione, consentendo la selezione di soli {0} serviz{0}',
  'market.ob.quantitativeLimit.three': 'tre',
  'market.ob.quantitativeLimit.two': 'due',
  'market.ob.quantitativeLimit.one': 'uno',
  'market.ob.quantitativeLimit.serviceSingular': 'io',
  'market.ob.quantitativeLimit.servicesPlural': 'i',
  'validation.characters.minLength': 'Questo valore è troppo basso. Dovrebbe contenere 3 o più caratteri.',
  'not.found.btn.label': 'Indietro',
  'not.found.subTitle.label': 'Siamo spiacenti, la pagina che hai visitato non esiste.',
  'analytics.title': 'Analitica',
  'analytics.dashboard.tab': 'Pannello di controllo',
  'analytics.reports.tab': 'Rapporti',
  'analytics.premium.title': 'Premio',
  'analytics.totalAppointments.title': 'Appuntamenti totali',
  'analytics.cancellationReasons.title': 'Principali motivi di annullamento',
  'analytics.occupancy.title': 'Occupazione',
  'analytics.topServices.title': 'I migliori servizi',
  'analytics.clients.title': 'Clienti',
  'analytics.completed.title': 'Completato',
  'analytics.notCompleted.title': 'Non completato',
  'analytics.canceled.title': 'Annullato',
  'analytics.noShowAppointments.title': 'Nessun appuntamento con lo spettacolo',
  'analytics.reasonName.title': 'Nome del motivo',
  'analytics.reasonCount.title': 'Contare',
  'analytics.lastPeriod.title': "l'ultimo periodo",
  'analytics.workingHours.title': 'Ore lavorative',
  'analytics.bookedHours.title': 'Orari prenotati',
  'analytics.blockedHours.title': 'Orari bloccati',
  'analytics.unbookedHours.title': 'Orari non prenotati',
  'analytics.service.title': 'Servizio',
  'analytics.thisMonth.title': 'Questo periodo',
  'analytics.lastMonth.title': "L'ultimo periodo",
  'analytics.totalClients.title': 'Clienti totali',
  'analytics.averageVisits.title': 'Visite medie per cliente ',
  'analytics.newClients.title': 'Nuovi clienti',
  'analytics.returningClients.title': 'Clienti di ritorno',
  'analytics.returnRate.title': 'Tasso di restituzione del cliente',
  'analytics.clientsByGender.title': 'Clienti per genere',
  'analytics.clientsBySource.title': 'Clienti per fonte di riferimento',
  'analytics.clientsByAge.title': 'Clienti per età',
  'analytics.timeRange.custom': 'Costume',
  'analytics.timeRange.today': 'Oggi',
  'analytics.timeRange.yesterday': 'Ieri',
  'analytics.timeRange.last1day': 'Ultimo giorno',
  'analytics.timeRange.last7days': 'Ultimi 7 giorni',
  'analytics.timeRange.last30days': 'Ultimi 30 giorni',
  'analytics.timeRange.last90days': 'Ultimi 90 giorni',
  'analytics.timeRange.last3months': 'Ultimi 3 mesi',
  'analytics.timeRange.lastMonth': 'Lo scorso mese',
  'analytics.timeRange.last6Months': 'Ultimi 6 mesi',
  'analytics.timeRange.lastYear': "L'anno scorso",
  'analytics.timeRange.last2year': 'Ultimo 2 anni',
  'analytics.timeRange.thisWeek': 'Questa settimana',
  'analytics.timeRange.thisMonth': 'Questo mese',
  'analytics.timeRange.thisYear': "Quest'anno",
  'analytics.timeRange.tomorrow': 'Ieri',
  'analytics.timeRange.next7days': 'I prossimi 7 giorni',
  'analytics.timeRange.next30days': 'Next 30 giorni',
  'analytics.menu.dashboard.description': 'Esplora i tuoi dati aziendali in grafici e statistiche',
  'analytics.menu.reports.description': 'Esplora i tuoi dati aziendali nei rapporti',
  'analytics.timeRange.allTime': 'Sempre',
  'analytics.timeRange.label': 'Intervallo di tempo',
  'analytics.services.lastPeriod.label': "L'ultimo periodo",
  'analytics.totalAppointments.appointment.label': 'Appuntamenti',
  'analytics.totalAppointments.onlineBook.label': 'Prenotazioni online',
  'analytics.gender.male': 'Maschio',
  'analytics.gender.female': 'Femmina',
  'analytics.gender.unknown': 'Sconosciuto',
  'analytics.noData': 'Non ci sono dati nel periodo selezionato',
  'analytics.premium.info.title': 'Aggiornamento alla versione Premium per visualizzare analisi per più di 7 giorni.',
  'analytics.premium.upgradeButton.title': 'ATTIVARE',
  'analytics.premium.skipButton.title': 'Salta',
  'reports.appointments.title': 'Appuntamenti',
  'reports.appointments.description':
    'Visualizza i ricavi previsti dei prossimi appuntamenti, monitora i tassi di cancellazione e i motivi.',
  'reports.appointmentsList.title': 'Elenco degli appuntamenti',
  'reports.appointmentsList.description': 'Visualizza tutti gli appuntamenti con le relative informazioni.',
  'reports.appointmentsSummary.title': 'Riepilogo appuntamenti',
  'reports.appointmentsSummary.description': 'Visualizza gli appuntamenti per Staff e Servizio.',
  'reports.appointmentsCancellations.title': 'Annullamenti appuntamenti',
  'reports.appointmentCancellations.title': 'Cancellazioni degli appuntamenti',
  'reports.appointmentsCancellations.description':
    'Visualizza tutti i motivi e la quantità degli appuntamenti annullati per il periodo selezionato.',
  'reports.summary.title': 'Riepilogo',
  'reports.clients.title': 'Clienti',
  'reports.clients.description':
    'Ottieni informazioni dettagliate su come i clienti interagiscono con la tua attività e su chi sono i tuoi top spender',
  'reports.clientsList.title': 'Clienti',
  'reports.clientsList.description': 'Elenco dei Clienti con i loro dati',
  'reports.exportCsv.title': 'Esporta CSV',
  'reports.appointmentsByStaff.title': 'Appuntamenti da parte del personale',
  'reports.appointmentsByService.title': 'Appuntamenti per Servizio',
  'reports.clearFilters.button.title': 'Cancella filtri',
  'reports.filterStaff.placeholder': 'Tutto il personale',
  'reports.filterLocations.placeholder': 'Tutti i posti',
  'reports.filters.title': 'Filtri',
  'reports.locations.mobile.title': 'Posizioni:',
  'reports.staff.mobile.title': 'Personale:',
  'reports.filters.apply.title': 'Fare domanda a',
  'reports.search.placeholder': 'Cerca per riferimento o cliente',
  'reports.subscription.description': 'Piano {0} (solo personale {0})',
  'reports.notifications.description': '{0} messaggi ({0})',
  'serviceGroup.addCategory.description':
    'Le categorie sono essenziali poiché ti consentono di raggruppare servizi simili.',
  'serviceGroup.addCategory.title': 'Aggiungi categoria',
  'app.install.title': 'Installa Plandok',
  'app.install.description.title':
    "L'installazione non utilizza quasi spazio di archiviazione e fornisce un modo rapido per tornare a questa app",
  'app.install.button.title': 'Installare',
  'app.skip.button.title': 'Saltare',
  'app.actionUndone.button.title': 'Azione annullata',
  'app.appointmentCancelled.button.title': 'Appuntamento cancellato',
  'app.undo.button.title': 'Annullare',
  'app.showAllTimes.button.title': 'Mostra tutti gli orari',
  'app.install.success.title': 'Applicazione installata con successo!',
  'rp.upgradeToPremiumLink.title': 'Passa a Premium',
  'rp.upgradeToPremium.title': 'per utilizzare questa funzionalità',
  'rp.repeatAppointment.title': 'Ripetere',
  'rp.input.frequency.title': 'Frequenza',
  'rp.input.endsAfter.title': 'Fine',
  'rp.input.selectDate.title': 'Seleziona la data',
  'rp.upgrade.title': 'Aggiorna il tuo piano',
  'rp.upgrade.description.title':
    'La versione Premium di Plandok non è attivata. Aggiorna il tuo piano per accedere completamente a tutte le funzionalità fornite da Plandok.',
  'rp.upgrade.button.title': 'Passa a Premium',
  'rp.repeating.title': 'Ripetendo',
  'rp.repeats.title': 'Si ripete',
  'rp.repeatsUntil.title': 'Fino a',
  'rp.repeatingTimes.title': '({0} volte)',
  'rp.cancel.upcoming.title': 'Elimina tutti gli appuntamenti ripetuti imminenti',
  'rp.update.upcoming.title': 'Aggiornare tutti gli appuntamenti ripetuti in arrivo',
  'rp.edit.appointment.title': 'Appuntamento di aggiornamento',
  'rp.edit.appointment.description.title':
    'Stai modificando un appuntamento ricorrente. Seleziona quali appuntamenti devono essere aggiornati:',
  'rp.edit.thisAppointment.title': 'Aggiorna solo questo appuntamento',
  'rp.edit.allAppointments.title': 'Aggiorna tutti i prossimi appuntamenti',
  'rp.edit.saveAppointment.title': 'Salva',
  'modal.cancelSubscriptionError.title': "Errore di annullamento dell'abbonamento",
  'modal.cancelSubscriptionError.text.1': 'Il piano gratuito è disponibile solo con un massimo di 5',
  'modal.cancelSubscriptionError.text.2': 'dipendenti',
  'modal.cancelSubscriptionError.text.3': 'e 1',
  'modal.cancelSubscriptionError.text.4':
    'Per utilizzare la versione gratuita, rimuovere le sedi / il personale per soddisfare i requisiti.',
  'modal.latestPaymentFailed.title': 'Ultimo pagamento non riuscito',
  'modal.latestPaymentFailed.text.1':
    'Per accedere a tutte le funzioni, pagare o utilizzare la versione gratuita rimuovere',
  'modal.latestPaymentFailed.text.2':
    'per soddisfare i requisiti. Il piano gratuito è disponibile solo con un massimo di 5 dipendenti e 1 sede.',
  'modal.extraCostsForNewMember.title': 'Costi aggiuntivi per i nuovi membri',
  'modal.extraCostsForNewMember.description':
    "L'aumento del numero di dipendenti comporta costi aggiuntivi per l'abbonamento mensile.",
  'modal.premiumUpgradeRequired.title': "È necessario l'aggiornamento Premium",
  'modal.premiumUpgradeRequired.description': 'Per aggiungere altre {0}, il piano deve essere aggiornato a Premium',
  'modal.premiumUpgradeRequired.location': 'sedi',
  'modal.premiumUpgradeRequired.staffMembers': 'membri dello staff',
  'modal.changeAccount.title': 'Cambiate account Stripe?',
  'modal.changeAccount.description':
    'Non sarà possibile accettare pagamenti mentre si cambia account. Ci vorrà circa un minuto per effettuare la modifica.',
  'btn.agree': 'Accettare',
  'btn.decline': 'Rifiuto',
  'paymentMethod.paymentMethod.title': 'Metodo di pagamento',
  'paymentMethod.currentPaymentMethod.title': 'Metodo di pagamento attuale',
  'paymentMethod.expirationDate': 'Data di scadenza {0}',
  'paymentMethod.otherPaymentMethods.title': 'Altri metodi di pagamento',
  'paymentMethod.addAnotherPaymentMethod.btn': 'Aggiungi un altro metodo di pagamento',
  'paymentMethod.addPaymentMethod.title': 'Aggiungi metodo di pagamento',
  'paymentMethod.noPaymentMethodAdded.title': 'Nessun metodo di pagamento aggiunto',
  'paymentMethod.noPaymentMethodAdded.description':
    'Il metodo di pagamento verrà aggiunto quando si seleziona il piano Premium',
  'paymentMethod.goToPlanPage.btn': 'Vai alla pagina del piano',
  'paymentMethod.add.btn': 'Aggiungi',
  'paymentMethod.makeDefault.btn': 'Imposta come predefinita',
  'paymentMethod.cardHasBeenAdded.message': 'La carta è stata aggiunta',
  'paymentMethod.cardHasBeenAssign.message': 'La carta è stata assegnata',
  'page.marketing.overview.messages.description':
    'Inviate messaggi di marketing personalizzati ai vostri clienti via SMS o e-mail con pochi semplici clic',
  'page.marketing.overview.btn.sendMessage': 'Invia messaggio',
  'page.marketing.overview.campaigns.title': 'Campagne ad invio automatico',
  'page.marketing.overview.campaigns.description':
    'Create campagne personalizzate in base a eventi come i compleanni dei clienti e altro ancora. \nInviate attraverso più canali per distribuire le campagne via e-mail e SMS.',
  'page.marketing.overview.btn.newCampaign': 'Nuova campagna',
  'page.marketing.massMessages.title': 'Impostazione di un nuovo messaggio',
  'page.marketing.massMessages.messageLog': 'Registro dei messaggi',
  'page.marketing.massMessages.sendOn': 'Invia su',
  'page.marketing.massMessages.sendTo': 'Invia a',
  'page.marketing.massMessages.channel': 'Canale',
  'page.marketing.campaigns.description':
    'Inviate messaggi di marketing automatizzati ai vostri clienti via SMS o e-mail con pochi semplici clic',
  'page.marketing.campaigns.birthdaySpecial.title': 'Speciale compleanno',
  'page.marketing.campaigns.winBackClients.title': 'Riconquistare i clienti',
  'page.marketing.campaigns.welcomeNewClients.title': 'Accogliere nuovi clienti',
  'page.marketing.campaigns.rewardRegularClients.title': 'Premiate i clienti abituali',
  'page.marketing.campaigns.birthdaySpecial.description':
    'Sorprendete i clienti nel loro giorno speciale: questa campagna viene inviata automaticamente ai clienti in prossimità del loro compleanno',
  'page.marketing.campaigns.winBackClients.description':
    "Indirizzare i clienti disimpegnati a tornare con un'offerta speciale. Questa campagna viene inviata ai clienti che non sono tornati dopo un certo periodo di tempo",
  'page.marketing.campaigns.welcomeNewClients.description':
    'Trasformare i nuovi arrivati in clienti abituali, incoraggiando i clienti della prima volta a prenotare di nuovo con uno sconto speciale. Questa campagna viene inviata automaticamente ai clienti un giorno dopo la loro prima vendita',
  'page.marketing.campaigns.rewardRegularClients.description':
    "Sorprendete i clienti top con un'offerta speciale, un modo sicuro per coinvolgere ancora di più i vostri clienti migliori. Questa campagna viene inviata automaticamente ai clienti in base alla recente attività di prenotazione",
  'page.marketing.campaigns.btn.active': 'Attivo',
  'page.marketing.campaigns.btn.inactive': 'Inattivo',
  'page.marketing.campaigns.btn.startCampaign': 'Avvia campagna',
  'page.marketing.campaigns.btn.editCampaign': 'Modifica campagna',
  'page.marketing.campaigns.btn.resetCampaign': 'Ripristinare la campagna',
  'modal.resetCampaign.description': 'Siete sicuri di voler resettare questa campagna ?',
  'modal.resetCampaign.btn.yesReset': 'Sì, ripristinare',
  'page.marketing.smsCreate.step.1': 'Impostazione degli SMS. Passo 1 di 4',
  'page.marketing.smsCreate.step.2': 'Impostazione degli SMS. Passo 2 di 4',
  'page.marketing.smsCreate.step.3': 'Impostazione degli SMS. Passo 3 di 4',
  'page.marketing.smsCreate.step.4': 'Impostazione degli SMS. Passo 4 di 4',
  'page.marketing.emailCreate.step.1': "Configurazione dell'e-mail. Passo 1 di 4",
  'page.marketing.emailCreate.step.2': "Configurazione dell'e-mail. Passo 2 di 4",
  'page.marketing.emailCreate.step.3': "Configurazione dell'e-mail. Passo 3 di 4",
  'page.marketing.emailCreate.step.4': "Configurazione dell'e-mail. Passo 4 di 4",
  'page.marketing.campaignCreate.step.1': 'Impostazione della campagna. Passo 1 di 3',
  'page.marketing.campaignCreate.step.2': 'Impostazione della campagna. Passo 2 di 3',
  'page.marketing.campaignCreate.step.3': 'Impostazione della campagna. Passo 3 di 3',
  'page.marketing.smsCreate.step.1.title': 'Messaggio e ID mittente',
  'page.marketing.smsCreate.step.1.defaultTitleForSms': "Inserire l'ID del mittente dell'SMS",
  'page.marketing.smsCreate.step.1.defaultMessageForSms': 'Inserire il messaggio',
  'page.marketing.emailCreate.step.1.defaultTitleForEmail': 'Inserire il titolo del messaggio',
  'page.marketing.emailCreate.step.1.defaultMessageForSms': 'Inserire il messaggio',
  'page.marketing.emailCreate.step.1.title': "Impostazioni dell'e-mail",
  'page.marketing.emailCreate.step.1.messageTitle': 'Titolo del messaggio',
  'page.marketing.emailCreate.step.1.replyToEmail': "Risposta all'e-mail",
  'page.marketing.emailCreate.step.1.clientReplies': 'Le risposte del cliente saranno inviate a questa e-mail',
  'page.marketing.emailCreate.step.1.photo.title': 'Foto',
  'page.marketing.emailCreate.step.1.enablePhoto': 'Attiva foto',
  'page.marketing.emailCreate.step.1.changePhoto': 'Cambia foto',
  'page.marketing.emailCreate.step.1.button.title': 'Pulsante',
  'page.marketing.emailCreate.step.1.enableButton': 'Attiva pulsante',
  'page.marketing.emailCreate.step.1.buttonName': 'Nome del pulsante',
  'page.marketing.emailCreate.step.1.buttonURL': 'URL del pulsante',
  'page.marketing.emailCreate.step.1.socialMedia.title': 'Social media',
  'page.marketing.emailCreate.step.1.enableSocialMedia': 'Abilita i link ai social media',
  'page.marketing.emailCreate.step.1.facebookPage': 'Pagina Facebook',
  'page.marketing.emailCreate.step.1.instagramPage': 'Pagina Instagram',
  'page.marketing.emailCreate.step.1.yourPage': 'La vostra pagina',
  'page.marketing.smsCreate.yourMessage': 'Il tuo messaggio',
  'page.marketing.btn.sendMeTestMessage': 'Inviami un messaggio di prova',
  'page.marketing.smsCreate.step.1.freeTests': '{0} test gratuiti rimasti per oggi',
  'page.marketing.smsCreate.messagePreview': 'Anteprima del messaggio',
  'page.marketing.smsCreate.btn.clientSelection': 'Selezione del cliente',
  'page.marketing.audience.title': 'Pubblico',
  'page.marketing.smsCreate.step.2.description': 'Scegliete quali clienti riceveranno il vostro messaggio',
  'page.marketing.smsCreate.step.2.allClient.title': 'Tutti i clienti',
  'page.marketing.smsCreate.step.2.allClient.description': 'Invia il messaggio a tutti i clienti',
  'page.marketing.smsCreate.step.2.clientGroups.title': 'Gruppi di clienti',
  'page.marketing.smsCreate.step.2.clientGroups.description': 'Invia il messaggio a gruppi di clienti selezionati',
  'page.marketing.smsCreate.step.2.totalClients': 'Totale clienti',
  'page.marketing.smsCreate.step.2.totalPrice': 'Prezzo totale',
  'page.marketing.smsCreate.step.3.description': "Scegliete l'ora in cui volete inviare il messaggio",
  'page.marketing.smsCreate.step.3.btn.selectDateTime': 'Selezionare data e ora personalizzate',
  'page.marketing.smsCreate.step.4.title': 'Pagamento per il messaggio di massa',
  'page.marketing.smsCreate.step.4.description': 'Inserisci i dati della tua carta di credito e paga',
  'page.marketing.smsCreate.step.4.paymentAmount': 'Importo del pagamento:',
  'btn.scheduleSend': "Programmare l'invio",
  'btn.previous': 'Precedente',
  'btn.payment': 'Pagamento',
  'btn.payAndFinish': 'Pagare e terminare',
  'btn.backToDashboard': 'Torna al cruscotto',
  'btn.saveAndClose': 'Salva e chiudi',
  'modal.editClientSelection.title': 'Modifica della selezione dei clienti',
  'modal.newClient.description': "Clienti aggiunti nell'ultimo periodo:",
  'modal.recentClient.title': 'Cliente recente',
  'modal.recentClient.description': "Clienti che hanno visitato nell'ultimo periodo:",
  'modal.clientByAge.title': 'Clienti per età',
  'modal.clientsByGender.title': 'Clienti per sesso',
  'modal.paymentConfirmation.title': 'Pagamento riuscito !',
  'modal.paymentConfirmation.messageSend': 'Il vostro messaggio sarà inviato',
  'modal.previewMessage.title': 'Anteprima del messaggio',
  'modal.previewSms.tab': 'Anteprima SMS',
  'modal.previewEmail.tab': 'Anteprima e-mail',
  'modal.editServiceSelection.title': 'Modifica della selezione del servizio',
  'modal.editServiceSelection.allServices': 'Tutti i servizi',
  'modal.campaignActivated.title': 'Campagna attivata !',
  'modal.campaignActivated.description': 'È possibile sospendere o modificare questa campagna in qualsiasi momento',
  'btn.all': 'Tutti {0}',
  'btn.included': 'Inclusi {0}',
  'btn.excluded': 'Esclusi {0}',
  'checkbox.allClients': 'Tutti i clienti {0}',
  'scheduleSend.time': 'tempo',
  'scheduleSend.timeSetTo': 'Tempo impostato su:',
  'scheduleSend.setTime': 'Imposta ora',
  'campaignSetup.step.1.header.title': 'Impostazione della campagna',
  'campaignSetup.step.2.header.title': 'Modifica messaggio',
  'campaignSetup.step.3.header.title': 'Revisione',
  'campaignSetup.channel.description': 'Scegliere i canali di invio della campagna',
  'campaignSetup.smsSetup.tab': 'Impostazione SMS',
  'campaignSetup.emailSetup.tab': "Impostazione dell'e-mail",
  'campaignSetup.almostDone.title': 'Quasi fatto !',
  'campaignSetup.almostDone.description':
    'La vostra campagna intelligente è pronta per essere avviata e, una volta attivata, invierà automaticamente messaggi ai clienti su base continuativa. Potrete facilmente modificare o sospendere la campagna in qualsiasi momento',
  'campaignSetup.approximatePrice.header': 'Prezzo approssimativo',
  'campaignSetup.approximatePrice.description': 'Per i prossimi 30 giorni',
  'campaignSetup.approximatePrice.approx': 'Prezzo approssimativo',
  'campaign.birthdaySpecial.description':
    'Questa campagna invia automaticamente ai clienti in prossimità del loro compleanno',
  'campaign.birthdaySpecial.sendToClient': 'Invia al cliente:',
  'campaign.birthdaySpecial.selectOption.1': 'Il giorno del compleanno',
  'campaign.birthdaySpecial.selectOption.2': 'Tre giorni prima del compleanno',
  'campaign.birthdaySpecial.selectOption.3': 'Una settimana prima del compleanno',
  'campaign.birthdaySpecial.selectOption.4': 'Due settimane prima del compleanno',
  'campaign.winBackClients.description':
    'Questa campagna viene inviata ai clienti che non sono tornati dopo un certo periodo di tempo',
  'campaign.winBackClients.clientsWhoDidntReturn': "Clienti che non sono tornati nell'ultimo periodo",
  'campaign.winBackClients.appliedTo': 'Applicato a',
  'campaign.winBackClients.all': ' tutti i ',
  'campaign.winBackClients.services': 'servizi',
  'campaign.winBackClients.editSelection': 'Modifica selezione',
  'campaign.welcomeNewClients.description':
    'Trasformate i nuovi arrivati in clienti abituali incoraggiando i clienti della prima volta a prenotare di nuovo con uno sconto speciale',
  'campaign.welcomeNewClients.smartCampaignNewClients':
    'Questa campagna intelligente viene inviata automaticamente ai nuovi clienti un giorno dopo la loro prima vendita',
  'campaign.rewardRegularClients.description':
    'Questa campagna viene inviata automaticamente ai clienti in base alle recenti attività di prenotazione',
  'campaign.rewardRegularClients.clientsWithAtLeast': 'Clienti con almeno',
  'campaign.rewardRegularClients.appointmentsInThe': 'appuntamenti negli',
  'campaign.rewardRegularClients.last': 'ultimi',
  'campaign.rewardRegularClients.appointments': 'appuntamenti',
  'campaign.rewardRegularClients.inTheLast': 'negli ultimi',
  'campaign.rewardRegularClients.months': 'mesi',
  'subscriptionPaymentFailed.title':
    'Aggiorna il metodo di pagamento fino a {0}, altrimenti tutte le funzionalità premium saranno interrotte, grazie.',
  'subscriptionCancelled.title':
    'Ci scusiamo ma il tuo Premium è stato disattivato per mancato pagamento. Se desideri continuare a utilizzare le funzionalità premium, attiva il piano Premium, grazie.',
  'notificationPaymentFailed.title':
    'Il tuo pagamento per le notifiche non è riuscito. Completa il pagamento entro il giorno {0}, altrimenti le notifiche verranno disabilitate, grazie.',
  'notificationCancelled.title':
    'Ci scusiamo ma le tue notifiche sono state disattivate a causa del mancato pagamento. Se desideri continuare a inviare notifiche effettua il pagamento, grazie.',
  'btn.hideOptions': 'Nascondi opzioni',
  'btn.showOptions': 'Mostra opzioni',
  'notification.googleDisconnected': 'Calendario Google disconnesso',
  'notification.googleConnected': 'Calendario Google connesso',
  'notification.appleDisconnected': 'Calendario Apple disconnesso',
  'notification.appleConnected': 'Calendario Apple connesso',
  'notification.microsoft365Disconnected': 'Calendario Microsoft365 disconnesso',
  'notification.microsoft365Connected': 'Calendario Microsoft365 collegato',
  'notification.linkCopied': 'Collegamento copiato',
  'calendarSync.title': 'Sincronizzazione del calendario',
  'calendarSync.description':
    "Sincronizzate in un solo modo gli appuntamenti di Plandok con il vostro calendario preferito, per un'organizzazione senza sforzo e per non perdere mai un evento importante.",
  'googleCalendar.title': 'Calendario Google',
  'appleCalendar.title': 'Calendario Apple',
  'microsoft365Calendar.title': 'Calendario Microsoft365',
  'linkYourCalendar.title': 'Collega i tuoi calendari',
  'connect.btn': 'Collegare',
  'disconnect.rtn': 'Disconnettersi',
  'connected.label': 'Collegato',
  'modal.workingHours.standartMethod.btn': 'Metodo standard',
  'modal.workingHours.customMethod.btn': 'Metodo personalizzato',
  'modal.workingHours.workingDates.input': 'Date di lavoro',
  'modal.workingHours.selectDates.placeholder': 'Seleziona le date',
  'noInternetConnection.notification.title': 'Nessuna connessione a Internet',
  'app.update.title': 'Nuova versione disponibile',
  'app.update.description.title':
    'Abbiamo aggiunto nuove funzioni e risolto alcuni bug per rendere la vostra esperienza con Plandok il più fluida possibile.',
  'app.update.button.title': '\r\nAggiorna',
  'app.notNow.button.title': 'Non ora',
  'app.updateAvailable.button.title': 'Nuova versione disponibile',
  'upgradePlan.success.message': "L'abbonamento è stato aggiornato con successo",
  'upgradePlan.error.message':
    "Errore di aggiornamento dell'abbonamento, si prega di contattare l'assistenza se la carta viene addebitata e l'abbonamento non viene aggiornato",
  'sidebar.inventory': 'Inventario',
  'products.tab': 'I prodotti',
  'suppliers.tab': 'Fornitori',
  'stockOrders.tab': 'Ordini di magazzino',
  'stockTracking.tab': 'Monitoraggio dello stock',
  'input.productsSearch.placeholder': 'Ricerca per nome del prodotto o codice a barre',
  'product.create.btn.title': 'Aggiungi un nuovo prodotto',
  'table.products.product': 'Prodotto',
  'table.products.barcode': 'Codice a barre',
  'table.products.brand': 'Marchio',
  'table.products.sale': 'Vendita',
  'table.products.saleOpt': 'Vendita Opz.',
  'saleOptions.tooltip.title': 'Opzioni di vendita',
  'saleOptions.tooltip.description': 'È possibile scegliere di abilitare/disabilitare la vendita durante il checkout.',
  'seeMore.title': 'vedi di più',
  'option.products.downloadCSV': 'Scarica CSV',
  'option.products.downloadExcel': 'Scarica Excel',
  'option.products.manageBrands': 'Gestire i marchi',
  'option.products.manageCategories': 'Gestire le categorie',
  'option.products.manageStock': 'Gestire lo stock',
  'option.products.importProducts': 'Importazione di prodotti',
  'modal.filter.categories.title': 'Categorie',
  'modal.filter.brands.title': 'Marchi',
  'modal.filter.stock.title': 'Stock',
  'modal.filter.allCategories.selectItem': 'Tutte le categorie',
  'modal.filter.allBrands.selectItem': 'Tutti i marchi',
  'modal.filter.allSuppliers.selectItem': 'Tutti i fornitori',
  'modal.filter.allProducts.selectItem': 'Tutti i prodotti',
  'input.suppliersSearch.placeholder': 'Ricerca per nome del fornitore',
  'supplier.create.btn.title': 'Aggiungi un nuovo fornitore',
  'table.suppliers.supplierName': 'Nome del fornitore',
  'table.suppliers.phoneNumber': 'Numero di telefono',
  'table.suppliers.physicalAddress': 'Indirizzo fisico',
  'validation.notification.reminderTime': 'Impossibile inviare notifiche con lo stesso orario di promemoria.',
  'validation.paymentType.switches': 'È necessario consentire almeno un metodo di pagamento',
  'plan.premium.option.14':
    'Commissione Plandok 0% per gli appuntamenti prenotati tramite Prenotazioni Online con carta di credito.',
  'title.dragAndExtend.appointment.permission': 'Può trascinare ed estendere gli appuntamenti',
  'button.lastVisit.addAnotherLastvisit': "AGGIUNGI UN'ALTRA ULTIMA VISITA",
  'notifications.template.badge.validate': 'Convalidare',
  'notifications.template.badge.refused': 'Rifiutato',
  'paid.by.card': 'Pagato con carta',
  'costs.manual.paid.link':
    'Oppure puoi pagare manualmente le tue notifiche con la tua carta, cliccando su questo link',
  'modal.remove.account.title': "Rimuovi l'account Stripe",
  'modal.remove.account.description': 'Il tuo account verrà rimosso e non potrai accettare pagamenti online. ',
  'ob.remove.account.option': "Rimuovi l'account Stripe",
  'btn.remove.account': 'Rimuovere',
};

export default labels;
