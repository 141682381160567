import React from 'react';
import { IntlLabel } from '@plandok/i18n';
import { LinkWithLanguage } from 'components';
import { RoutePath } from 'constants/routes';

export default function RegisterFooter() {
  return (
    <div className="text-center">
      <div data-footer-info>
        <IntlLabel label="text.alreadyHaveAccount" /> <LinkWithLanguage to={RoutePath.LOGIN} label="link.sign.in" />
      </div>
    </div>
  );
}
