import React from 'react';
import { GOOGLE_ANALYTICS_URL } from 'constants/external-links';
import googleAnalytics from '../images/google-analytics.svg';
import Analytics from '../components/Analytics';

export default function GoogleAnalytics() {
  const googleAnalyticsProps = {
    altKey: 'ob.analytics.google.title',
    FIELD_PLACEHOLDER: 'UA-XXXXX-Y',
    imgSrc: googleAnalytics,
    description1: 'ob.analytics.google.description.1',
    description2: 'ob.analytics.google.description.2',
    fieldName: 'googleTrackerId',
    fieldLabel: 'ob.analytics.google.trackingId',
    fieldTooltip: 'ob.analytics.google.tooltip',
    href: GOOGLE_ANALYTICS_URL,
    mobile1: 'ob.analytics.google.account.mobile.1',
    mobile2: 'ob.analytics.google.account.mobile.2',
    mobile3: 'ob.analytics.google.account.mobile.3',
    mobile4: 'ob.analytics.google.account.mobile.4',
  };

  return <Analytics {...googleAnalyticsProps} />;
}
