import React, { useEffect, useState } from 'react';
import { isFirefox } from 'react-device-detect';
import { useModal } from '@plandok/core';
import { Placeholder, AppLayout, NoInternetConnection } from 'components';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { onReschedule } from 'helpers/app/reschedule';
import withApiData from 'hocs/form/withApiData';
import { RoutePath } from 'constants/routes';
import { useLocation } from 'hooks/router';
import * as api from 'api';
import ServicesMobileHeader from './components/ServicesMobileHeader';
import MultipleAddButton from './components/MultipleAddButton';
import ServiceGroupList from './components/ServiceGroupList';
import * as helpers from './helpers';

let serviceGroupId: string;

function ServicesPage(props: any) {
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const [data, setItem] = useState(props.data);
  const [, { showModal }] = useModal();
  const { navigate } = useLocation();

  const createService = (serviceGroupId?: string) =>
    navigate(RoutePath.SERVICE_CREATE + (serviceGroupId ? `?serviceGroupId=${serviceGroupId}` : ''));

  const editService = (id: string, groupId: string) => {
    navigate(RoutePath.SERVICE_EDIT, id);
    serviceGroupId = groupId;
  };

  const createCategory = () =>
    showModal({
      type: 'CREATE_CATEGORY',
      modalProps: { onSuccess: props.fetchData, initialValues: { color: '#6cd5cb' } },
    });

  const editCategory = (id: string) =>
    showModal({ type: 'CREATE_CATEGORY', modalProps: { id, onSuccess: props.fetchData } });

  const onItemDrop = (id: any) => (params: any) => setItem(helpers.moveElementItem(data, id, params));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scrollToCurrentService = (id: string) => {
    // Firefox does not support center scroll
    document.getElementById(id)?.scrollIntoView?.({ block: isFirefox ? 'start' : 'center', behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToCurrentService(serviceGroupId);
  }, [scrollToCurrentService]);

  return (
    <NoInternetConnection>
      <AppLayout
        mobileHeader={ServicesMobileHeader}
        headerTitle="sidebar.service"
        customButton={
          !props.showPlaceholder ? (
            <MultipleAddButton
              actions={[
                {
                  icon: 'newServiceGroup',
                  title: 'btn.newServiceGroup',
                  action: createCategory,
                },
                {
                  icon: 'newService',
                  title: 'service.new.title',
                  action: createService,
                },
              ]}
            />
          ) : null
        }
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        {props.showPlaceholder ? (
          <Placeholder
            imgName="services"
            btnLabel="state.services.button"
            descriptionLabel="state.services.description"
            titleLabel="state.services.title"
            onClick={createCategory}
            position="relative"
          />
        ) : (
          <ServiceGroupList
            isLoading={props.isLoading}
            data={props.data}
            fetchServiceGroups={props.fetchData}
            useDragHandle
            onSortEnd={onReschedule({
              data: props.data,
              changeData: props.changeData,
              onPositionUpdate: async (id: string, newPosition: number) =>
                await api.changeServiceGroupPosition('', { id, newPosition }),
            })}
            changeData={props.changeData}
            onItemDrop={onItemDrop}
            createService={createService}
            createCategory={createCategory}
            editCategory={editCategory}
            editService={editService}
          />
        )}
      </AppLayout>
    </NoInternetConnection>
  );
}

export default withApiData(api.fetchCategories, { isSortable: true })(ServicesPage);
