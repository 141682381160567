import { Field } from '@plandok/core';
import styled from 'styled-components';

export const Select = styled(Field.Select)`
  min-width: 128px;

  .ant-select-selection__rendered {
    margin-left: 4px;
  }
`;
