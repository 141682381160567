import { useContext } from 'react';
import React from 'react';

import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { ReactIntl, getDateFnsLocale } from '@plandok/i18n';
import DatePickerInput from './components/DatePickerInput';
import DatepickerHeader from './components/DatepickerHeader';
import './styles/index.less';
import './styles/addon.less';

const DatePicker = ({ onClear, ...props }: ReactDatePickerProps & { value?: Date; onClear?: () => any }) => {
  const { lang } = useContext(ReactIntl);

  const localeConfig = getDateFnsLocale(lang);
  const baseLocaleConfig = {
    ...localeConfig,
    // overriding base date-fns locale to have weekStartsOn - 1 (Monday) instead of Sunday
    options: { weekStartsOn: 1, firstWeekContainsDate: 1 },
  };

  const changeRaw = (e: any) => {
    e.preventDefault();
  };

  return (
    <ReactDatePicker
      renderCustomHeader={DatepickerHeader}
      customInput={<DatePickerInput onInputChange={props.onChange} onClear={onClear} />}
      selected={props.value}
      selectsRange
      onChangeRaw={changeRaw}
      onFocus={(e) => e.target.blur()}
      locale={baseLocaleConfig ? (baseLocaleConfig as any) : lang}
      popperPlacement={'top-start'}
      {...props}
    />
  );
};

export default DatePicker;
