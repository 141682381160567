// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from '../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportBookingsByServiceQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  from: Types.Scalars['Date'];
  to: Types.Scalars['Date'];
  locationId?: Types.InputMaybe<Types.Scalars['Uuid']>;
  employeeId?: Types.InputMaybe<Types.Scalars['Uuid']>;
}>;

export type ReportBookingsByServiceQuery = {
  __typename?: 'AppQuery';
  reports?: {
    __typename?: 'App_Reports';
    bookingsByService?: {
      __typename?: 'App_BookingsByServiceReportConnection';
      totalCount?: number | null;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor?: string | null;
        endCursor?: string | null;
      };
      edges?: Array<{
        __typename?: 'App_BookingsByServiceReportEdge';
        cursor: string;
        node?: {
          __typename?: 'App_BookingsByServiceReport';
          name?: string | null;
          totalCount: number;
          totalPrice: { __typename?: 'Money'; amount: string; currency: string };
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export const ReportBookingsByServiceDocument = gql`
  query ReportBookingsByService(
    $offset: Int
    $limit: Int
    $from: Date!
    $to: Date!
    $locationId: Uuid
    $employeeId: Uuid
  ) {
    reports(from: $from, to: $to, offset: $offset, limit: $limit) {
      bookingsByService(locationId: $locationId, employeeId: $employeeId) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            name
            totalCount
            totalPrice {
              amount
              currency
            }
          }
        }
      }
    }
  }
`;

/**
 * __useReportBookingsByServiceQuery__
 *
 * To run a query within a React component, call `useReportBookingsByServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportBookingsByServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportBookingsByServiceQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      locationId: // value for 'locationId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useReportBookingsByServiceQuery(
  baseOptions: Apollo.QueryHookOptions<ReportBookingsByServiceQuery, ReportBookingsByServiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportBookingsByServiceQuery, ReportBookingsByServiceQueryVariables>(
    ReportBookingsByServiceDocument,
    options
  );
}
export function useReportBookingsByServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportBookingsByServiceQuery, ReportBookingsByServiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportBookingsByServiceQuery, ReportBookingsByServiceQueryVariables>(
    ReportBookingsByServiceDocument,
    options
  );
}
export type ReportBookingsByServiceQueryHookResult = ReturnType<typeof useReportBookingsByServiceQuery>;
export type ReportBookingsByServiceLazyQueryHookResult = ReturnType<typeof useReportBookingsByServiceLazyQuery>;
export type ReportBookingsByServiceQueryResult = Apollo.QueryResult<
  ReportBookingsByServiceQuery,
  ReportBookingsByServiceQueryVariables
>;
