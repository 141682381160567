import React, { memo } from 'react';
import { IntlLabel } from '@plandok/i18n';
import { Icon } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  border: 1px dashed #adb6cc;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #13316d;

  i {
    margin-right: 7px;
  }
`;

export default memo(function ButtonAddAnother({ label, onClick }: { label: string; onClick: () => void }) {
  return (
    <Container onClick={onClick}>
      <Icon type="plus" /> <IntlLabel label={label} />
    </Container>
  );
});
