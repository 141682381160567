import React, { useEffect } from 'react';
import { toJS } from 'mobx';
import { Row } from 'antd';
import { Button, getIsMobile, useModal } from '@plandok/core';
import tableStoreFactory from '../../../../components/common/Table/AppTable/store';
import staffDatesState from './store/staff-dates.state';
import { query } from '../../../../graphql';
import { AppTable } from '../../../../components';
import { closedDatesColumns } from './config';
import * as SC from '../../MessagesPage/styles';

function getMobileColumn(isMobile: boolean) {
  return ({ value }: any) => {
    return (
      <Row type="flex" justify="space-between" align="middle" className="w-100">
        <SC.MessageContainer isMobile={isMobile}>
          {closedDatesColumns.map((e, i) => (
            <div key={i} style={{ minWidth: '100px' }}>
              {(e as any)?.render?.(value.startDate, { ...value })}
            </div>
          ))}
        </SC.MessageContainer>
      </Row>
    );
  };
}

export default function StaffDatesPage() {
  const [, { showModal }] = useModal();
  const isMobile = getIsMobile();

  const refreshData = () => tableStoreFactory.getTableStoreInstance('closedDates').fetchFreshData();

  const openCreateClosedDate = () => {
    showModal({
      type: 'VIEW_CLOSED_DATE',
      modalProps: {
        onSuccess: refreshData,
      },
    });
  };

  const viewClosedDate = (id: string, initialValues: any) => {
    const values = toJS(initialValues)?.locations;
    showModal({
      type: 'VIEW_CLOSED_DATE',
      modalProps: {
        id,
        skipDetails: true,
        initialValues: { ...initialValues, locationIds: values?.map((e: any) => e.id) },
        onSuccess: refreshData,
      },
    });
  };

  useEffect(() => {
    staffDatesState.initialFetchData();
  }, []);

  return (
    <AppTable
      type="closedDates"
      columns={closedDatesColumns}
      viewDetails={viewClosedDate}
      mobileRowComponent={getMobileColumn(isMobile)}
      apiConfig={{
        apiType: 'gql',
        gqlQuery: query.CLOSED_DATES,
        mapping: {
          pageKey: 'page',
          dataKey: 'closedDates',
          totalCountKey: 'closedDates.totalCount',
          searchKey: 'query',
          emptyStateKey: 'closedDates.emptyState',
        },
      }}
      showPlaceholder
      placeholder={{
        imgName: 'dates',
        titleLabel: 'state.closedDates.title',
        descriptionLabel: 'state.closedDates.description',
        btnLabel: 'state.closedDates.button',
        onClick: openCreateClosedDate,
      }}
    >
      <Row type="flex" justify="end" className="w-100 mt-1 mb-2">
        <Button label="staff.create.title" color="primary" upperCase={false} onClick={openCreateClosedDate} minorBtn />
      </Row>
    </AppTable>
  );
}
