import React from 'react';

import ClientSelectionForm from './components/ClientSelectionForm';
import { ModifyMassLayout, NotReleased } from 'components';
import withFormApi from 'hocs/form/withFormApi';
import { RoutePath } from 'constants/routes';

export const ClientSelectionFormWithApi = withFormApi({
  backPath: RoutePath.MESSAGE_CREATE,
  successMessage: 'Massage',
})(ClientSelectionForm);

export default function ModifyMessagePage() {
  return (
    <NotReleased>
      <ModifyMassLayout title="Client Selection" step="SMS setup. Step 2 of 4">
        <ClientSelectionFormWithApi />
      </ModifyMassLayout>
    </NotReleased>
  );
}
