import React, { useState } from 'react';
import cn from 'classnames';
import { Button, getIsMobile } from '@plandok/core';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { AppLayout, AppTable, NoInternetConnection } from 'components';
import * as api from 'api';
import { mobileCampaignsCardData, messageLogColumns } from '../config';
import CampaignsMobileHeader from './CampaignsMobileHeader';
import MobileCampaignCard from './MobileCampaignsCard';
import * as SC from '../styles';

interface MarketingCampaignsProps {
  buttonTab: string;
  setButtonTab: (tabName: string) => void;
}

export default function MarketingCampaigns({ buttonTab, setButtonTab }: MarketingCampaignsProps) {
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const [switchBtn, setSwitchBtn] = useState(false);
  const isMobile = getIsMobile();

  return (
    <NoInternetConnection>
      <AppLayout
        headerTitle="Auto-sending Campaigns"
        mobileHeader={CampaignsMobileHeader}
        className={cn('campaigns-page', { 'campaigns-page__is-mobile': isMobile })}
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <SC.Container>
          <SC.CampaignsSwitchBtns>
            <Button
              label="Campaigns"
              upperCase={false}
              ghost={buttonTab !== 'campaigns'}
              onClick={() => setButtonTab('campaigns')}
              type="primary"
            />
            <Button
              label="Message log"
              upperCase={false}
              ghost={buttonTab !== 'message'}
              onClick={() => setButtonTab('message')}
              type="primary"
            />
          </SC.CampaignsSwitchBtns>
          {buttonTab === 'campaigns' &&
            mobileCampaignsCardData?.map((item, index) => (
              <MobileCampaignCard
                key={index}
                indexNumber={index}
                headerText={item.headerText}
                icon={item.icon}
                url={item.url}
                description={item.description}
                switchButton={switchBtn}
                onChange={(checked: boolean) => setSwitchBtn(checked)}
              />
            ))}
        </SC.Container>
        {buttonTab === 'message' && (
          <SC.MessageLog>
            <AppTable
              type="message"
              columns={messageLogColumns}
              mobileClassName="app_clients-list--mobile"
              apiConfig={{
                fetchData: api.fetchClients,
                mapping: {
                  pageKey: 'page[offset]',
                  maxCountKey: 'page[limit]',
                  dataKey: 'massMarketingCampaign',
                  totalCountKey: 'messageLog.totalCount',
                  searchKey: 'query',
                  emptyStateKey: 'messageLog.emptyState',
                },
              }}
            />
          </SC.MessageLog>
        )}
      </AppLayout>
    </NoInternetConnection>
  );
}
