import styled, { css } from 'styled-components';
import { mediaMdDown } from '@plandok/core';

export const CardImage = styled.div`
  width: 550px;
  background: rgba(173, 182, 204, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;
  div {
    text-align: center;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const CardInfo = styled.div`
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardInfoMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const CardInfoActions = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  button {
    min-width: 180px;
    margin-left: 1rem;
  }
`;

export const Card = styled.div<any>`
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 0.16);
  height: 250px;
  width: 100%;
  max-width: 1250px;
  display: flex;
  overflow: hidden;
  margin: 0 auto 60px auto;
  color: ${(p) => p.theme.primaryColor};
  h2 {
    color: ${(p) => p.theme.primaryColor};
  }

  ${mediaMdDown(css`
    flex-direction: column;
    height: inherit;
    ${CardImage} {
      width: 100%;
    }

    ${CardInfoActions} {
      justify-content: space-between;
      margin-top: 10px;
      button {
        min-width: inherit;
        width: 100%;
        max-width: 130px;
        &:first-of-type {
          margin-right: 20px;
        }
      }
    }

    ${CardInfo} {
      padding: 10px;

      h2 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;
      }
      [data-booking-card-profile-status] b {
        display: none;
      }
    }
  `)}
` as any;
