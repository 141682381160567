import { Avatar, Divider, Dropdown } from 'antd';
import cn from 'classnames';
import React from 'react';
import { Button, Card, getIsMobile, useModal } from '@plandok/core';
import { RoutePath } from 'constants/routes';
import { IClientDetails } from 'types/api';
import { useLocation } from 'hooks/router';
import { TextLong } from 'components';
import ClientMoreOptionMenu from './components/ClientMoreOptionMenu';
import ClientInfoItem from './components/ClientInfoItem';
import { getFormattedBirthday } from './helpers';
import { Gender } from '../../types/types';
import * as SC from './style';

export default function ClientInfoBlock({ data, id }: { id: string; data: IClientDetails }) {
  const { navigate } = useLocation();
  const [, { showModal }] = useModal();

  const isMobile = getIsMobile();

  if (!data?.profile) {
    return null;
  }
  const { profile } = data;
  const editClient = () => navigate(`${RoutePath.CLIENT_EDIT}/${id}`);

  const openDeleteClientModal = () => showModal({ type: 'CONFIRM_DELETE_CLIENT', modalProps: { id, profile } });

  const genderLabel = () => {
    if (profile.gender === Gender.MALE) return 'dropdown.gender.male';
    if (profile.gender === Gender.FEMALE) return 'dropdown.gender.female';
    return 'dropdown.gender.unknown';
  };

  return (
    <Card>
      <SC.MainInfo>
        <Avatar size="large" data-client-info-avatar>
          {profile.firstName?.[0]?.toUpperCase?.() ?? ' '}
          {profile.lastName?.[0]?.toUpperCase?.() ?? ''}
        </Avatar>
        <div data-client-info-name>
          {profile.firstName} {profile.lastName}
        </div>
        {!isMobile && (
          <div data-client-info-options>
            <Dropdown
              overlay={(props: any) => (
                <ClientMoreOptionMenu edit={editClient} delete={openDeleteClientModal} {...props} />
              )}
              trigger={['click']}
            >
              <Button whiteBtn label="btn.more.options" bold={false} upperCase={false} />
            </Dropdown>
          </div>
        )}
      </SC.MainInfo>
      <TextLong text={profile.notes}>
        <Divider />
      </TextLong>
      <SC.ItemList>
        <ClientInfoItem icon="phone" label="title.telephoneNumber" text={profile.phoneNumber} isPhone />
        <ClientInfoItem icon="email" label="title.email" text={profile.email} />
        <ClientInfoItem
          icon="address"
          label="title.address"
          text={cn(profile.address, profile.city, profile.postalCode)}
        />
        <ClientInfoItem icon="birth" label="title.dateOfBirth" text={getFormattedBirthday(profile)} />
        <ClientInfoItem icon="gender" label="title.gender" text={genderLabel()} />
        {/* TODO: Update later when referal be ready from BE */}
        {/* <ClientInfoItem icon="referal" label="input.referal.label" text={profile.gender} /> */}
      </SC.ItemList>
    </Card>
  );
}
