import { useQuery } from 'react-query';
import { Skeleton } from 'antd';
import React from 'react';
import { IntlLabel, IntlMoney } from '@plandok/i18n';
import { QueryType, queryAppointmentClientProfileDetails } from 'api/query';
import { AppointmentItem, TextLong } from 'components';
import { IClientAppointmentDetails } from 'types/api';
import ClientDetailsMain from './components/ClientDetailsMain';
import * as SC from './styles';

export default function ClientDetails(props: any) {
  const { data } = useQuery(
    [QueryType.APPOINTMENT_CLIENT_DETAILS, props.clientId],
    queryAppointmentClientProfileDetails
  );

  const client: IClientAppointmentDetails = data?.data;

  return client ? (
    <>
      <SC.Container>
        <SC.Header>
          <ClientDetailsMain
            removeClient={props.removeClient}
            data={client}
            clientId={props.clientId}
            openCreateClient={props.openCreateClient}
          />
          <TextLong text={client?.profile?.notes} />
        </SC.Header>
        <SC.Summary>
          <div>
            <div data-client-summary-major>{client?.bookingsTotalCount}</div>
            <div data-client-summary-minor>
              <IntlLabel label="client.totalBookings" />
            </div>
          </div>
          <div>
            <div data-client-summary-major>
              <IntlMoney value={client?.bookingsTotalPrice} />
            </div>
            <div data-client-summary-minor>
              <IntlLabel label="client.totalSales" />
            </div>
          </div>
        </SC.Summary>
        <SC.AppointmentTitle>
          <IntlLabel label="appointment.list.title" />
        </SC.AppointmentTitle>
        <SC.AppointmentList>
          {client?.upcomingBookings?.map((booking) => (
            <AppointmentItem key={booking.bookingId} value={booking} timeFormat={props.timeFormat} />
          ))}
        </SC.AppointmentList>
      </SC.Container>
      <SC.MobileContainer>
        <ClientDetailsMain
          removeClient={props.removeClient}
          data={client}
          clientId={props.clientId}
          openCreateClient={props.openCreateClient}
        />
      </SC.MobileContainer>
    </>
  ) : (
    <SC.Container className="p-2">
      <Skeleton active loading avatar />
      <br />
      <Skeleton active loading />
    </SC.Container>
  );
}
