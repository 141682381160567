import { CustomIcon, getIsMobile, Text } from '@plandok/core';
import { Spin, Switch } from 'antd';
import PaymentTypesController, {
  IPaymentType,
  PaymentTypeEnum,
} from 'pages/dashboard/BookingPage/BookingPayments/controllers/paymentTypeController';
import { Container } from 'pages/dashboard/CalendarPage/components/PaymentErrorNotification';
import React from 'react';
import PayCardContent from './components/PayCardContent';
import * as SC from './styles';

export default function BookingPayments() {
  const controller = PaymentTypesController();

  const isMobile = getIsMobile();

  const onSitePayment = controller.paymentTypes[PaymentTypeEnum.ON_SITE] as IPaymentType;
  const stripePayment = controller.paymentTypes[PaymentTypeEnum.STRIPE_PAYMENT] as IPaymentType;

  return (
    <SC.Container>
      {!isMobile && <Text label="ob.payments.tab" size="xxxlarge" weight="semiBold" mb="xmedium" lineHeight="49px" />}
      <Text label="ob.payments.page.description" lh="xxlarge" marginBottom={isMobile ? '24px' : '40px'} size="medium" />
      <Spin spinning={controller.loading}>
        {controller.error && (
          <Container>
            <div className="align-center">
              <span className="align-center">
                <CustomIcon type="clear" />
                <Text
                  weight="semiBold"
                  size={isMobile ? 'mlarge' : 'base'}
                  mb="none"
                  label="Error"
                  ml="xsmall"
                  lh={isMobile ? 'xxlarge' : 'small'}
                />
              </span>

              <Text
                size="small"
                mb={isMobile ? 'small' : 'none'}
                ml={isMobile ? 'none' : 'base'}
                lh="large"
                style={{ maxWidth: '596px' }}
              >
                {controller.error}
              </Text>
            </div>
          </Container>
        )}

        {onSitePayment?.id && (
          <SC.PaySiteCard>
            <CustomIcon type="cash" />
            <SC.PaySiteInfo>
              <Text
                label="ob.payOnSite.title"
                mb="small"
                weight="semiBold"
                fontSize="22px"
                lineHeight="26px"
                marginBottom={isMobile ? '4px' : '15px'}
              />
              <Text
                label="ob.payOnSite.description"
                size="small"
                lh="xlarge"
                color="#5A6F99"
                marginBottom={isMobile ? '16px' : '24px'}
              />
              <SC.SwitchContainer>
                <Switch
                  defaultChecked
                  checked={onSitePayment.isActive}
                  onChange={(value) => controller.changeStatus(PaymentTypeEnum.ON_SITE, value)}
                  disabled={!stripePayment.isActive}
                />
                <Text
                  color={onSitePayment.isActive ? '#009329' : '#7183a7'}
                  label={onSitePayment.isActive ? 'ob.btn.active' : 'ob.btn.inactive'}
                  size="medium"
                  lh="mlarge"
                  mb="none"
                  marginLeft="12px"
                />
              </SC.SwitchContainer>
            </SC.PaySiteInfo>
          </SC.PaySiteCard>
        )}

        {stripePayment?.id && (
          <SC.PayCardCard>
            <SC.PayCardContainer>
              <CustomIcon type="creditCard" />
              <SC.PayCardWrapper>
                <Text
                  label="ob.payWithCard.title"
                  mb="small"
                  weight="semiBold"
                  fontSize="22px"
                  lineHeight="26px"
                  marginBottom={isMobile ? '4px' : '16px'}
                />
                <Text
                  label="ob.payWithCard.description"
                  size="small"
                  lh="xlarge"
                  color="#5A6F99"
                  marginBottom={isMobile ? '9px' : '16px'}
                />

                {!isMobile && (
                  <PayCardContent
                    stripePayment={stripePayment}
                    onSitePayment={onSitePayment}
                    controller={controller}
                    changeStatus={(value) => controller.changeStatus(PaymentTypeEnum.STRIPE_PAYMENT, value)}
                  />
                )}
              </SC.PayCardWrapper>
            </SC.PayCardContainer>

            {isMobile && (
              <PayCardContent
                stripePayment={stripePayment}
                onSitePayment={onSitePayment}
                controller={controller}
                changeStatus={(value) => controller.changeStatus(PaymentTypeEnum.STRIPE_PAYMENT, value)}
              />
            )}
          </SC.PayCardCard>
        )}
      </Spin>
    </SC.Container>
  );
}
