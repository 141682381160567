import styled, { css } from 'styled-components';
import { Button, mediaMdDown, Text, Card, Field } from '@plandok/core';

export const Title = styled.h3`
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
  color: #13316d;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  span {
    cursor: pointer;
    display: inherit;
    align-items: center;
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 27px;
  div {
    color: ${({ theme }) => theme.color};
  }
`;

export const SubtitleBlock = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  white-space: nowrap;
  margin-top: 40px;

  div {
    justify-content: start;
  }

  div:first-child {
    align-self: end;
  }

  button {
    grid-row: span 2;
    justify-self: start;
    margin-left: 30px;
    padding: 8px 18px;
    background-color: #2472e8;
    border-color: #2472e8;
    font-weight: 500;
  }

  button:hover {
    background-color: #3884f6;
    border-color: #3884f6;
  }
`;

export const UpgradeButton = styled(Button)`
  padding: 8px 0px;
  background-color: #2472e8 !important;
  border-color: #2472e8 !important;
  font-weight: 500;
  margin-bottom: 16px;
  width: 100%;
  max-height: 36px;
  line-height: 19px;

  &:focus {
    background-color: #3884f6;
    border-color: #3884f6;
  }
`;

export const TagButton = styled(Button)`
  margin: 13px 0px 0px 17px;
  padding: 3px 11px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  height: auto;
  border: 2px solid #14316d;
  border-color: #14316d !important;
  color: #14316d !important;
`;

export const HStack = styled.div`
  display: inline-block;
  overflow: visible;
`;

export const StyledText = styled(Text)`
  line-height: 21px;

  & + .ant-col {
    padding: 0 !important;

    .ant-row {
      .ant-col {
        .ant-form-item-no-colon {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
`;

export const FeatureDescriptionWrapper = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #13316d;

  a {
    color: #2472e8;
    text-decoration: underline;
  }
`;

export const WrapperFields = styled.div`
  .ant-col {
    padding: 0 !important;

    .ant-row {
      div.ant-form-item-label {
        max-height: 40px;
      }
      .ant-col {
        margin-bottom: 5px;

        .ant-form-item-no-colon {
          font-size: 14px;
          line-height: 16px;
        }

        & + .ant-col {
          div.ant-select-selection-selected-value {
            color: #213e76;
          }

          .ant-form-item-control {
            .ant-form-item-children {
              .ant-input {
                color: #213e76;
              }
            }
          }
        }
      }
    }
  }
`;

export const EmailSettingsTitle = styled(Text)`
  margin-top: 40px;

  ${mediaMdDown(css`
    margin-top: 50px;
  `)}
`;

export const EmailSettingsTitleCommon = styled(Text)`
  margin-top: 20px;

  ${mediaMdDown(css`
    margin-top: 36px;
  `)}
`;

export const TemplateTextAreaWithTags = styled(Field.TextAreaWithTags)`
  margin-bottom: 8px !important;

  ${mediaMdDown(css`
    margin-bottom: 4px !important;
  `)}
`;

export const Footer = styled.div`
  margin: 30px auto 0px;
  text-align: center;

  button {
    min-width: 300px;
    font-weight: 500;
    font-size: 14px;
  }

  ${mediaMdDown(css`
    margin-top: 16px;
    padding-top: 12px;
    box-shadow: 0px 3px 25px rgba(19, 49, 109, 0.161);
  `)}
`;

export const BookingsSwitchBtns = styled.div`
  border: 1px solid #adb6cc;
  box-sizing: border-box;
  border-radius: 23px;
  margin-bottom: 30px;
  max-width: max-content;

  button {
    padding: 11px 18px;
    max-height: 36px;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    border: none;
    box-shadow: none;
  }

  ${mediaMdDown(css`
    margin: 0 auto;
  `)}
`;

export const MessageSetupHeader = styled(Text)`
  margin-bottom: 20px;

  ${mediaMdDown(css`
    margin-bottom: 30px;
  `)}
`;

export const CustomCard = styled(Card)`
  box-shadow: 0px 2px 10px rgba(38, 60, 122, 0.2);
  padding: 35px 42px !important;
  margin-bottom: ${(props) => props.theme} !important;

  ${mediaMdDown(css`
    padding: 13px 14px !important;
    width: calc(100vw - 30px);
    margin: 0 auto;
  `)}
`;
