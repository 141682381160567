import { SetupTabHash } from 'constants/routes';

export const setupTabConfigs: any = [
  {
    path: SetupTabHash.REMINDERS,
    title: 'tabs.reminders.title',
  },
  {
    path: SetupTabHash.CONFIRMATIONS,
    title: 'tabs.confirmations.title',
  },
  {
    path: SetupTabHash.RESCHEDULE,
    title: 'tabs.reschedules.title',
  },
  {
    path: SetupTabHash.CANCELLATIONS,
    title: 'tabs.cancellations.title',
  },
  {
    path: SetupTabHash.THANK_YOU,
    title: 'tabs.thanks.title',
  },
  {
    path: SetupTabHash.LAST_VISIT,
    title: 'tabs.lastVisit.title',
  },
];
