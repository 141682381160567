import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { flow, noop, omit } from 'lodash';
import React, { useState } from 'react';
import { Button, Form, Text, withModal } from '@plandok/core';
import { App_AppointmentBookingsInput } from 'graphql/generatedTypes';
import { mutate, mutation } from '../../../../graphql';
import * as SC from '../styles';

type Values = {
  bookings: App_AppointmentBookingsInput[];
  clientId: undefined | string;
  date: string;
  notes: string | null;
};

type RecurrenceAppointmentData = {
  bookings: App_AppointmentBookingsInput[];
  clientId: null | string;
  appointmentId: string;
  recurrenceId: string;
  employeeId: string;
};

type ModalProps = {
  values: Values;
  recurrenceAppointmentData: RecurrenceAppointmentData;
  updateNotRecurringAppointment: (value: Values) => void;
  onSuccess: () => void;
};

type UpdateAppointmentModalProps = {
  onClose: () => void;
  modalProps: ModalProps;
};

function UpdateAppointmentModal({ onClose, modalProps }: UpdateAppointmentModalProps) {
  const [isChecked, setIsChecked] = useState(true);

  const onChange = (event: CheckboxChangeEvent) => setIsChecked(event.target.checked);

  const onSubmitWithRepeat = async () =>
    await mutate(mutation.UPDATE_RECURRING_APPOINTMENT)(
      omit({ ...modalProps.values, ...modalProps.recurrenceAppointmentData }, 'locationId')
    );
  const onSubmitWithoutRepeat = () => modalProps.updateNotRecurringAppointment(omit(modalProps.values, 'locationId'));

  return (
    <Form
      onSubmit={isChecked ? onSubmitWithRepeat : onSubmitWithoutRepeat}
      onSuccess={flow(onClose, modalProps.onSuccess || noop)}
    >
      {({ submitting }: any) => (
        <>
          <SC.StyledCheckbox checked={isChecked} onChange={onChange}>
            <Text as="span" size="base" colorType="base" weight="normal" mb="none" label="rp.update.upcoming.title" />
          </SC.StyledCheckbox>

          <SC.StyledActionRow>
            <Button htmlType="submit" label="app.update.button.title" loading={submitting} minorBtn smallRadius />
          </SC.StyledActionRow>
        </>
      )}
    </Form>
  );
}

export default withModal('app.update.button.title')(UpdateAppointmentModal);
