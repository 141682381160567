import styled, { css } from 'styled-components';
import { mediaMdUp } from '@plandok/core';

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 75px;
  right: 12px;
  z-index: 3;
  button {
    height: 35px;
    background: #13316d;
    border-color: #13316d;
  }

  ${mediaMdUp(
    css`
      display: none;
    `
  )}
`;
