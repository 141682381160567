export const colorsPalette = [
  '#cdd3ce',
  '#fff8cb',
  '#ffec78',
  '#e7f286',
  '#d0dF47',
  '#b2ff9e',
  '#a6e5bd',
  '#d6ffe9',
  '#a5be00',
  '#90bab4',
  '#6cd5cb',
  '#91e3ee',
  '#a5dff8',
  '#90b2f4',
  '#bbc1e8',
  '#ad7df4',
  '#e2a6e6',
  '#ff9cbb',
  '#ebdeff',
  '#ffe7d2',
  '#eccaaa',
  '#ffbf69',
  '#f49661',
  '#b89e97',
];
