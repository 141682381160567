import styled, { css } from 'styled-components';
import { mediaMobileDown } from '@plandok/core';

export const Row = styled.tr`
  td {
    background: white;
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;
    transition: all 0.3s, border 0s;
    ${mediaMobileDown(css`
      padding: 8px !important;
    `)}
  }
`;

export const Head = styled.thead`
  th {
    ${mediaMobileDown(css`
      padding: 8px !important;
    `)}
  }
`;
