import { Col } from 'antd';
import React from 'react';
import {
  genderOptions,
  monthOptions,
  useModal,
  yearOptions,
  validateEmail,
  validateRequired,
  Form,
  Card,
  Field,
  Button,
} from '@plandok/core';
import { notificationType } from 'constants/data';
import { ModifyLayout, Tabs } from 'components';
import * as helpers from '../../helpers';
import { PermissionType } from '../../../../../constants/auth';

export default function ClientForm(props: any) {
  const [, { showModal, hideModal }]: any = useModal();

  return (
    <Form
      onSubmit={props.onSubmit}
      onSuccess={props.onSuccess}
      initialValues={props.initialValues}
      combinedFields={['phoneNumber']}
    >
      {({ submitting, formError, values }: any) => {
        const dayOptions = helpers.getDayOptions(values.birthdayMonth);

        const mappedMonthOptions = monthOptions.map(({ code, name }) => ({ name, code: Number(code) + 1 }));

        return (
          <>
            <Card minHeight="450px">
              <Field.Row gutter={0}>
                <Col span={24} md={11}>
                  <Field.Row>
                    <Field.Input
                      name="firstName"
                      label="input.firstName.label"
                      placeholder="input.firstName.placeholder"
                      md={12}
                      validate={validateRequired}
                    />
                    <Field.Input
                      name="lastName"
                      label="input.lastName.label"
                      placeholder="input.lastName.placeholder"
                      md={11}
                    />
                  </Field.Row>
                  <Field.CombinedInput
                    name="phoneNumber"
                    label="input.mobile.label"
                    placeholder="input.mobile.placeholder"
                  />
                  <Field.Input
                    name="email"
                    label="input.email.label"
                    placeholder="input.email.placeholder"
                    validate={validateEmail}
                  />
                  <Field.Select
                    name="notificationChannel"
                    label="input.notificationType.label"
                    options={notificationType}
                    validate={validateRequired}
                    translate
                  />
                </Col>
                <Col span={24} md={12}>
                  <Tabs
                    tabs={[
                      { key: 'tab.personalInfo', tab: 'tab.personalInfo' },
                      { key: 'tab.address', tab: 'tab.address' },
                    ]}
                  >
                    <Field.Row gutter={0}>
                      <Field.Row gutter={0}>
                        <Field.Select
                          md={11}
                          name="gender"
                          label="input.gender.label"
                          options={genderOptions}
                          validate={validateRequired}
                          translate
                        />
                        {/* TODO: Revert when referal be ready from BE */}
                        <Field.AsyncSelect
                          asyncType="refSources"
                          md={12}
                          name="referralSourceId"
                          label="input.referal.label"
                          placeholder="input.referal.placeholder"
                          addBtn="btn.new.referral"
                          section={PermissionType.CLIENT_SETTINGS}
                          modalType="CREATE_REF_SOURCE"
                          showModal={showModal}
                          hideModal={hideModal}
                        />
                      </Field.Row>
                      <Field.Row gutter={0}>
                        <Field.Select
                          name="birthdayMonth"
                          md={8}
                          label="input.birthDay.label"
                          placeholder="input.month.placeholder"
                          options={mappedMonthOptions}
                          translate
                        />
                        <Field.Select
                          name="birthdayDay"
                          md={7}
                          placeholder="input.day.placeholder"
                          disabled={!values.birthdayMonth}
                          options={dayOptions}
                        />
                        <Field.Select
                          name="birthdayYear"
                          md={8}
                          placeholder="input.year.placeholder"
                          options={yearOptions}
                        />
                      </Field.Row>
                      <Field.Input
                        name="notes"
                        label="input.clientsNotes.label"
                        placeholder="input.clientsNotes.placeholder"
                      />
                      <Field.Checkbox name="notesVisibleOnAppointments" textKey="input.bookingDisplay.label" isSwitch />
                    </Field.Row>
                    <Field.Row gutter={0}>
                      <Field.Input name="address" label="input.address.label" placeholder="input.address.placeholder" />
                      <Field.Row>
                        <Field.Input
                          md={12}
                          name="city"
                          label="input.city.label"
                          placeholder="input.city.placeholder"
                        />
                        <Field.Input
                          md={11}
                          name="postalCode"
                          label="input.zip.label"
                          placeholder="input.zip.placeholder"
                        />
                      </Field.Row>
                    </Field.Row>
                  </Tabs>
                </Col>
              </Field.Row>
            </Card>
            <Form.Alert text={formError} className="mt-1" />
            <ModifyLayout.Footer>
              <Button block label="btn.cancel" onClick={props.onCancel} disabled={submitting} />
              <Button block type="primary" label="btn.save" htmlType="submit" loading={submitting} />
            </ModifyLayout.Footer>
          </>
        );
      }}
    </Form>
  );
}
