import { FieldRenderProps } from 'react-final-form';
import { Checkbox } from 'antd';
import React from 'react';
import withFormBlock from '../FormBlock/withFormBlock';
import { LabelKey } from '@plandok/i18n';
import styled from 'styled-components';

interface FormCheckGroupProps extends FieldRenderProps<any, any> {
  submitting?: boolean;
  placeholder?: string;
  description?: LabelKey;
  textKey?: LabelKey;
  options?: { label: string; value: string; premium?: boolean }[];
  labelParams?: string[] | number[];
  onChange?: (values: any) => void;
}

const OptionsGrid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 24px;
`;

const FormCheckGroup = ({
  input,
  submitting,
  textKey,
  children,
  className,
  labelParams,
  options,
  onChange,
}: FormCheckGroupProps) => (
  <Checkbox.Group {...input} className={className} disabled={submitting}>
    <OptionsGrid>
      {options &&
        options.map(({ label, value }) => (
          <Checkbox key={label} value={value} onChange={onChange}>
            {label}
          </Checkbox>
        ))}
    </OptionsGrid>
  </Checkbox.Group>
);

export default withFormBlock<FormCheckGroupProps>(FormCheckGroup);
