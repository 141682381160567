export const currencyMap = {
  EUR: '€',
  USD: '$',
};

export const findCurrencySign = (currencyCode: string = 'EUR'): string => {
  const predefinedCurrency = (currencyMap as any)[currencyCode];
  if (predefinedCurrency) {
    return predefinedCurrency;
  }

  if (typeof Number.toLocaleString !== 'function') {
    return currencyMap.EUR;
  }
  return (
    (0)
      .toLocaleString(undefined, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim() || currencyMap.EUR
  );
};
