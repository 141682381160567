import React, { useState } from 'react';
import SetupForm from '../SetupForm';

export default function SetupConfirmationForm() {
  const [buttonTab, setButtonTab] = useState('app');

  return (
    <>
      {buttonTab === 'app' ? (
        <SetupForm
          notificationId="confirmation"
          checkBoxLabel="input.enableAppConfirmation.label"
          checkBoxDescription="input.enableConfirmationApp.description"
          buttonTab={buttonTab}
          setButtonTab={setButtonTab}
          switchBtns
        />
      ) : (
        <SetupForm
          notificationId="online_booking_confirmation"
          checkBoxLabel="input.enableOnlineConfirmation.label"
          checkBoxDescription="input.enableConfirmationOnline.description"
          buttonTab={buttonTab}
          setButtonTab={setButtonTab}
          switchBtns
        />
      )}
    </>
  );
}
