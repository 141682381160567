import { Component } from 'react';
import { registerLocale } from 'react-datepicker';
import React from 'react';

import { getLabelByIsoCode } from '../helpers';
import { LanguageFnsConfig } from '../languages';
import Intl from './ReactIntl';

Object.entries(LanguageFnsConfig).map(([langName, fnsBundle]) => {
  return registerLocale(langName, fnsBundle);
});

interface IntlProviderProps {
  getInitialLanguage: () => string;
}

class IntlProvider extends Component<IntlProviderProps & any, any> {
  constructor(props: any) {
    super(props);

    const lang = props.getInitialLanguage();
    this.setHtmlLocale(lang);
    this.state = {
      lang,
      changeLang: this.changeLang,
    };
  }

  setHtmlLocale = (lang?: string) => {
    if (lang) {
      document.documentElement?.setAttribute?.('lang', lang);
    }
  };

  changeLang = (code: string) => {
    const lang = getLabelByIsoCode(code);
    this.setHtmlLocale(lang);
    this.setState({ lang });
  };

  render() {
    return <Intl.Provider value={this.state as any}>{this.props.children}</Intl.Provider>;
  }
}

export default IntlProvider;
