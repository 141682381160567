import { Component } from 'react';
import ReactDOM from 'react-dom';
import React from 'react';

export default class Portal extends Component {
  constructor(props: any) {
    super(props);
    this.el = document.createElement('div');
  }

  state = {
    isLoaded: false,
  };

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    document.body.appendChild(this.el);
    this.setState({ isLoaded: true });
  }

  componentWillUnmount() {
    if (this.el) {
      document.body.removeChild(this.el);
    }
  }

  el: any;

  render() {
    if (!this.state.isLoaded) {
      return null;
    }

    return <div>{ReactDOM.createPortal(this.props.children, this.el) || null}</div>;
  }
}
