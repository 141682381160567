import { FieldRenderProps } from 'react-final-form';
import withFormBlock from './FormBlock/withFormBlock';
import { Divider } from 'antd';
import React from 'react';
import { mediaSmDown } from '../../../helpers/styles';
import styled, { css } from 'styled-components';
import { Button } from '@plandok/core';

const Container = styled.div`
  text-align: center;
  margin: 20px 0px 0px;
  color: #13316d;
  font-size: 26px;
  font-weight: 600;
  ${mediaSmDown(css`
    margin: 50px auto 0px auto;
  `)}
`;

interface FormInputProps {
  submitting?: boolean;
}

const EmailPreviewInputBtn = ({ input, submitting, meta, ...rest }: FormInputProps & FieldRenderProps<any, any>) => {
  const onClick = () => {
    console.log('aaaa= =>');
  };
  return (
    <Container>
      {input.value && (
        <>
          <Divider className="mt-0" />
          <Button type="primary" upperCase={false} onClick={onClick}>
            {input.value}
          </Button>
        </>
      )}
    </Container>
  );
};

export default withFormBlock<FormInputProps>(EmailPreviewInputBtn);
