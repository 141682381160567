import styled, { css } from 'styled-components';
import { mediaSmDown, Button } from '@plandok/core';

type TimeRangeButtonProps = {
  isDisabled?: boolean;
};

type DateRangeButtonProps = {
  isRounded?: boolean;
};

type DatePickerProps = {
  isGrid?: boolean;
  hasCustomPlacement?: boolean;
};

export const DatePicker = styled.div<DatePickerProps>`
  .react-datepicker-popper {
    transform: ${({ hasCustomPlacement }) => (hasCustomPlacement ? ' translate3d(8px, 0, 0) !important' : 'none')};
    margin-top: 3rem;
  }
  grid-area: date-picker;
  display: ${({ isGrid }) => (isGrid ? 'grid' : 'inherit')};
  place-content: center;
  margin: 0 auto;

  .react-datepicker {
    display: flex;
    padding: 0;

    ${mediaSmDown(css`
      display: inherit;
    `)}
  }
`;

export const DatePickerContainer = styled.div`
  display: flex;
  position: relative;
  padding: 0.625rem 1.25rem 0.625rem 0;
`;

export const UpgradeToPremium = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 25rem;
  text-align: center;
  padding: 0 6rem;

  > * {
    margin-top: 0.5rem;
  }
`;

export const UpgradeButton = styled(Button)`
  width: 15.875rem;
  background: #349447;
`;

export const ActiveArrow = styled.span`
  padding-left: 2rem;
`;

export const DateRange = styled.span`
  user-select: none;
  text-transform: capitalize;
  padding-right: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DatePickerIcon = styled.span`
  vertical-align: text-top;
`;

export const TimeRangeBlock = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  padding: 1.5rem;
  border-right: 1px solid #e9ecf3;

  ${mediaSmDown(css`
    border-bottom: 1px solid #e9ecf3;
  `)}
`;

export const DateRangeButton = styled(Button)<DateRangeButtonProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ isRounded }) => (isRounded ? '30px' : '5px')};
`;

export const DatePickerBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 12.3125rem;
  max-height: 28rem;

  ${mediaSmDown(css`
    z-index: 2;
    position: absolute;
    background: #fff;
    max-height: 30rem;
  `)}
`;

export const TimeRangeButton = styled(Button)<TimeRangeButtonProps>`
  margin: 0.1rem 0;
  width: 100%;
  padding-left: 1.5rem;
  background-color: #fff;
  box-shadow: unset;
  text-align: left;
  color: ${({ isDisabled }) => (isDisabled ? '#ADB6CC !important' : '#14316d !important')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'inherit')};

  &:active,
  &:focus {
    outline: none;
    background: white;
    color: #14316d;
    font-weight: 600;
  }
`;
