import React, { useState } from 'react';
import { Dropdown, Spin } from 'antd';
import { Button, CustomIcon, Text, useLocation } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { App_Location, App_LocationOnlineStatus } from 'graphql/generatedTypes';
import BookingOptionsMenu from './components/BookingOptionsMenu';
import { mutation, mutate } from '../../../../../../graphql';
import { RoutePath } from 'constants/routes';
import * as SC from './styles';

interface BookingProfileCardProps {
  location: App_Location;
  refetch(): any;
}

BookingProfileCard.defaultProps = {};

export default function BookingProfileCard({ location, refetch }: BookingProfileCardProps) {
  const { navigate } = useLocation();
  const [loading, setIsLoading] = useState(false);
  const isPublished = location.onlineStatus === App_LocationOnlineStatus.Published;
  const editProfile = () => navigate(RoutePath.BOOKING_CREATE, location?.id);
  const turnOffProfile = async () => {
    setIsLoading(true);
    try {
      await mutate(mutation.PUBLISH_LOCATION_BOOKING)({ id: location?.id, publish: false });
      await refetch();
    } finally {
      setIsLoading(false);
    }
  };

  const photo = location?.photos?.[0]?.url;

  return (
    <Spin spinning={loading}>
      <SC.Card>
        <SC.CardImage>
          {photo ? (
            <img src={photo} alt="Cau" />
          ) : (
            <div>
              <CustomIcon type="noPhotos" />
              <Text label="placeholder.no.photos" size="small" />
            </div>
          )}
        </SC.CardImage>
        <SC.CardInfo>
          <SC.CardInfoMain>
            <div>
              <h2>{location.name}</h2>
              <p>{location.addressDetails}</p>
            </div>
            <div data-booking-card-profile-status>
              <b>
                <IntlLabel label="ob.profile.status" />:
              </b>
              {isPublished ? (
                <span className="color-green bold">
                  <IntlLabel label="ob.status.online" />
                </span>
              ) : (
                <span className="color-red bold">
                  <IntlLabel label="ob.status.offline" />
                </span>
              )}
            </div>
          </SC.CardInfoMain>
          {isPublished ? (
            <SC.CardInfoActions>
              <Dropdown
                overlay={<BookingOptionsMenu editProfile={editProfile} turnOfProfile={turnOffProfile} />}
                trigger={['click']}
                placement="bottomRight"
              >
                <Button label="btn.options" type="primary" ghost upperCase={false} />
              </Dropdown>
              <a href={location?.previewUrl || ''} target="_blank" rel="noopener noreferrer">
                <Button label="btn.preview" type="primary" upperCase={false} />
              </a>
            </SC.CardInfoActions>
          ) : (
            <SC.CardInfoActions>
              <a href={location?.previewUrl || ''} target="_blank" rel="noopener noreferrer">
                <Button label="btn.preview" type="primary" upperCase={false} />
              </a>
              <Button label="btn.list.profile" type="primary" upperCase={false} onClick={editProfile} />
            </SC.CardInfoActions>
          )}
        </SC.CardInfo>
      </SC.Card>
    </Spin>
  );
}
