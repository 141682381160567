import React, { ChangeEvent, createRef, RefObject, useEffect, useRef, useState } from 'react';
import { Spin, message } from 'antd';
import { Button, Card, CustomIcon, Field, Form, Link, useModal } from '@plandok/core';
import { IntlLabel, IntlTemplate, LabelKey } from '@plandok/i18n';
import { PriceType } from 'pages/plans/BillingPage/PricingPage/constants';
import { RoutePath, SetupTabHash } from 'constants/routes';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import { reminderLastVisitTimeOffset, reminderTimeOffset } from 'constants/data';
import { useLocation } from 'hooks/router';
import { ButtonAddAnother, PremiumBlock } from 'components';
import NotificationFormApi from './hocs/NotificationFormApi';
import { onCheckChange, onTagSelected, validateTime } from './helpers';
import ChannelsBlock from '../common/ChannelsBlock';
import { notificationType } from '../../constants';
import * as SC from './styles';
import './styles.less';
import TemplateStatusBadge from './components/TemplateStatusBadge';

interface SmallSetupFormProps {
  notificationId: string;
  checkBoxLabel: LabelKey;
  checkBoxDescription: LabelKey;
  buttonTab?: string;
  setButtonTab?: (value: string) => void;
  switchBtns?: boolean;
  reminder?: boolean;
  lastVisit?: boolean;
}

export default function SetupForm({ notificationId, ...restProps }: SmallSetupFormProps) {
  return (
    <NotificationFormApi notificationId={notificationId}>
      {({ onSubmit, initialValues }: any) => (
        <NotificationForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          notificationId={notificationId}
          {...restProps}
        />
      )}
    </NotificationFormApi>
  );
}

function NotificationForm({
  initialValues,
  onSubmit,
  checkBoxLabel,
  checkBoxDescription,
  buttonTab,
  setButtonTab,
  switchBtns,
  reminder: isReminder,
  notificationId,
  lastVisit,
}: any) {
  const [, setRerender] = useState(Date.now());
  const [forms, setForms] = useState<any[]>([{ id: Date.now(), channels: [] }]);
  const [enabled, setEnabled] = useState<{ enabled: boolean }>({ enabled: true });
  const [reminderTimes, setReminderTimes] = useState<number[]>([]);
  const formSubmitRefs = useRef<(() => void)[]>([]);

  useEffect(() => {
    if (initialValues?.length) {
      setForms(initialValues);
      setEnabled({ enabled: initialValues?.every((v: any) => v.enabled === true) });
      setReminderTimes(initialValues.map((v: any) => v.reminderTime));
    } else {
      setReminderTimes([24]);
    }
  }, [initialValues]);

  useEffect(() => {
    if (forms.length > reminderTimes.length) {
      setReminderTimes([...reminderTimes, forms[0].reminderTime || 24]);
    } else if (forms.length < reminderTimes.length) {
      const updatedReminderTimes = forms.map((form) => {
        const correspondingTime = reminderTimes.find((_, index) => forms[index]?.id === form.id);
        return correspondingTime ?? 24;
      });
      setReminderTimes(updatedReminderTimes);
    }
  }, [forms, reminderTimes]);

  const customTextAreaForEmailRefs = useRef<RefObject<HTMLDivElement>[]>([]);
  const customTextAreaForSmsRefs = useRef<RefObject<HTMLDivElement>[]>([]);
  const customTextAreaForWhatsAppRefs = useRef<RefObject<HTMLDivElement>[]>([]);

  useEffect(() => {
    formSubmitRefs.current = formSubmitRefs.current.slice(0, forms.length);
    customTextAreaForEmailRefs.current = customTextAreaForEmailRefs.current.slice(0, forms.length);
    customTextAreaForSmsRefs.current = customTextAreaForSmsRefs.current.slice(0, forms.length);
    customTextAreaForWhatsAppRefs.current = customTextAreaForWhatsAppRefs.current.slice(0, forms.length);
    for (let i = 0; i < forms.length; i++) {
      customTextAreaForEmailRefs.current[i] = customTextAreaForEmailRefs.current[i] || createRef();
      customTextAreaForSmsRefs.current[i] = customTextAreaForSmsRefs.current[i] || createRef();
      customTextAreaForWhatsAppRefs.current[i] = customTextAreaForWhatsAppRefs.current[i] || createRef();
    }
  }, [forms, forms.length]);

  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const [, { showModal }] = useModal();
  const { location } = useLocation();

  const isPremiumPlan = !isSubscriptionLoading && planType === PriceType.PREMIUM;

  const onSuccess = () => message.success(<IntlLabel label="notification.success.update" />);
  const handleEnabled = () => {
    setEnabled({ enabled: !enabled });
  };
  const submitAllForms = () => {
    if (validateTime(reminderTimes)) {
      formSubmitRefs.current.forEach((submitForm) => submitForm && submitForm());
    } else {
      return message.error(<IntlLabel label="validation.notification.reminderTime" />);
    }
  };

  return (
    <>
      {switchBtns && (
        <>
          {location.hash === `#${SetupTabHash.CONFIRMATIONS}` && (
            <SC.BookingsSwitchBtns>
              <Button
                label="btn.appBookings"
                upperCase={false}
                ghost={buttonTab === 'online'}
                onClick={() => setButtonTab && setButtonTab('app')}
                type="primary"
              />
              <Button
                label="btn.onlineBookings"
                upperCase={false}
                ghost={buttonTab === 'app'}
                onClick={() => setButtonTab && setButtonTab('online')}
                type="primary"
              />
            </SC.BookingsSwitchBtns>
          )}
        </>
      )}

      <Card className="wrapper-checkbox">
        <Form onSubmit={onSubmit} initialValues={enabled} onSuccess={onSuccess}>
          {({ values: value, handleSubmit }: any) => {
            formSubmitRefs.current[0] = handleSubmit;
            return (
              <Field.Checkbox
                name="enabled"
                defaultChecked={value?.enabled || value}
                textKey={checkBoxLabel}
                isSwitch
                infoCheckbox
                description={checkBoxDescription}
                noLabelHolder
                onChange={handleEnabled}
              />
            );
          }}
        </Form>
      </Card>

      <Spin spinning={false}>
        {
          <>
            {forms?.map((_: any, index: number) => (
              <Form onSubmit={onSubmit} initialValues={_} onSuccess={onSuccess} key={_.id}>
                {({ values: reminder, handleSubmit }: any) => {
                  const { onTagSelectedForEmail, onTagSelectedForSms, onTagSelectedForWhatsApp } = onTagSelected(
                    reminder,
                    setRerender,
                    customTextAreaForEmailRefs.current[index],
                    customTextAreaForSmsRefs.current[index],
                    customTextAreaForWhatsAppRefs.current[index]
                  );
                  formSubmitRefs.current[index + 1] = handleSubmit;
                  return (
                    <SC.CustomCard key={reminder.id} theme="30px">
                      <SC.Title>
                        {isReminder ? (
                          <IntlTemplate
                            label={lastVisit ? 'input.message.title' : 'input.reminder.title'}
                            params={[index + 1]}
                          />
                        ) : (
                          <SC.MessageSetupHeader
                            colorType="base"
                            weight="semiBold"
                            lh="xxxlarge"
                            size="llarge"
                            label="messageSetup.header"
                          />
                        )}

                        {index === 0 && <TemplateStatusBadge status={reminder.status} />}

                        {index > 0 && (
                          <span
                            onClick={() =>
                              showModal({
                                type: 'DELETE_REMINDER',
                                modalProps: {
                                  notificationType: notificationId,
                                  templateId: reminder.id,
                                  forms: forms,
                                  setForms: setForms,
                                  label: lastVisit
                                    ? 'modal.deleteMessage.description'
                                    : 'modal.deleteReminder.description',
                                },
                              })
                            }
                          >
                            <CustomIcon type="basket" />
                          </span>
                        )}
                      </SC.Title>
                      {index > 0 && <TemplateStatusBadge status={reminder.status} />}
                      <SC.StyledText
                        colorType="base"
                        mb="small"
                        size="medium"
                        weight="semiBold"
                        label="input.reminder.description"
                      />
                      <Field.CheckboxGroup
                        label="input.reminder.channels"
                        name="channels"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckChange(e, reminder)}
                        options={notificationType}
                        className="checkboxGroup"
                      />
                      {!isPremiumPlan && (
                        <PremiumBlock>
                          <SC.FeatureDescriptionWrapper>
                            <IntlLabel label="warning.smsFeatureMissing" />
                            &nbsp;
                            <Link to={RoutePath.BILLING} label="warning.link.billingAndPricing" />
                          </SC.FeatureDescriptionWrapper>
                        </PremiumBlock>
                      )}
                      {isReminder && (
                        <SC.WrapperFields>
                          <Field.Select
                            name="notificationTime"
                            validate={(value: number) => {
                              setReminderTimes((prev) =>
                                prev.map((reminderTime, timeIndex) => (timeIndex === index ? value : reminderTime))
                              );
                            }}
                            label={lastVisit ? 'input.reminderBeforeLastVisit.label' : 'input.reminderBefore.label'}
                            options={lastVisit ? reminderLastVisitTimeOffset : reminderTimeOffset}
                            translate
                          />
                        </SC.WrapperFields>
                      )}
                      {customTextAreaForEmailRefs.current[index]?.hasOwnProperty('current') && (
                        <ChannelsBlock
                          notificationType={notificationId}
                          channels={reminder?.channels && reminder.channels}
                          values={reminder}
                          onTagSelectedForEmail={onTagSelectedForEmail}
                          customTextAreaForEmail={customTextAreaForEmailRefs.current[index]}
                          onTagSelectedForSms={onTagSelectedForSms}
                          customTextAreaForSms={customTextAreaForSmsRefs.current[index]}
                          onTagSelectedForWhatsApp={onTagSelectedForWhatsApp}
                          customTextAreaForWhatsApp={customTextAreaForWhatsAppRefs.current[index]}
                          onSubmit={onSubmit}
                        />
                      )}
                    </SC.CustomCard>
                  );
                }}
              </Form>
            ))}

            {(isReminder || lastVisit) && (
              <ButtonAddAnother
                onClick={() =>
                  setForms((prev: any) => {
                    const firstTemplate = { ...prev[0] };
                    firstTemplate.id = Date.now();
                    return [...prev, firstTemplate];
                  })
                }
                label={
                  lastVisit
                    ? 'button.lastVisit.addAnotherLastvisit'
                    : isReminder
                    ? 'button.reminder.addAnotherReminder'
                    : ''
                }
              />
            )}
          </>
        }
      </Spin>

      <SC.Footer>
        <Button type="primary" onClick={submitAllForms} htmlType="submit" label="btn.save.changes" />
      </SC.Footer>
    </>
  );
}
