import { Button } from '@plandok/core';
import { useStripe } from '@stripe/react-stripe-js';
import { Col, message, Row } from 'antd';
import { PaymentDataType } from 'pages/plans/PaymentConfirmationPage/index';
import * as SC from 'pages/plans/UpdateSubscriptionPage/styles';
import React, { useState } from 'react';
import * as api from 'api';

function PaymentActionComponent({
  paymentData,
  paymentMethod,
  loadPaymentData,
  paymentId,
}: {
  paymentData?: PaymentDataType;
  paymentMethod: string;
  paymentId: string;
  loadPaymentData: () => Promise<any>;
}) {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);

  const confirmPayment = async () => {
    setLoading(true);
    const { error: confirmPayment }: any = await stripe?.confirmCardPayment(paymentData?.clientSecret ?? '', {
      payment_method: paymentMethod ?? '',
    });
    setLoading(false);

    if (!confirmPayment) {
      await loadPaymentData();
    } else {
      message.error('failed.to.confirm.payment');
    }
  };

  const cancelledPayment = async () => {
    setLoading(true);
    const response = await api.cancelPaymentIntent(paymentId);
    setLoading(false);

    if (response?.error) {
      message.error(response?.error?.message);
    }

    await loadPaymentData();
  };

  return (
    <SC.ValidationCardContainer>
      <Row gutter={[12, 12]} style={{ width: '100%' }} justify="space-between">
        <Col md={12} xs={24}>
          <Button type="danger" block loading={loading} onClick={cancelledPayment} label="btn.cancel" />
        </Col>
        <Col md={12} xs={24}>
          <Button type="primary" block loading={loading} onClick={confirmPayment} label="btn.confirm" />
        </Col>
      </Row>
    </SC.ValidationCardContainer>
  );
}

export default PaymentActionComponent;
