import styled, { css } from 'styled-components';
import { mediaMobileDown } from '../../../../helpers/styles';

export const Container = styled.div<any>`
  max-height: ${(props) => (props.open ? 'initial' : `${props.visibleCount * 70}px`)};
  position: relative;
  overflow: hidden;
  font-size: 14px;
  color: ${(props) => props.theme.primaryColor};
  margin-bottom: 5px;
`;

export const FadeContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-image: linear-gradient(to bottom, transparent, white);
`;

export const PrintContainer = styled.div`
  display: flex;
  align-items: center;

  ${mediaMobileDown(css`
    justify-content: space-between;
    div {
      margin-left: 0;
    }
  `)}
`;

export const Toggle = styled.div`
  position: relative;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  margin-right: 1.5rem;
  color: ${(props) => props.theme.primaryColor};
  i {
    font-size: 12px;
  }
`;
