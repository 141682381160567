export interface IntlService {
  useCurrencyCode: () => string;
}

class IntlContainer {
  private service?: IntlService;

  registerService = (service: IntlService) => {
    if (this.service) {
      throw new Error('Service already exists');
    }
    this.service = service;
  };

  getService = () => {
    if (!this.service) {
      throw new Error('Register service to use it');
    }
    return this.service;
  };
}

export const IoC = new IntlContainer();
