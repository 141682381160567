import React from 'react';

import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import DatepickerHeader from './components/DatepickerHeader';
import './styles/index.less';
import './styles/addon.less';

const CustomTimeDatePicker = ({ onClear, ...props }: ReactDatePickerProps & { value?: Date; onClear?: () => any }) => {
  return (
    <ReactDatePicker
      renderCustomHeader={DatepickerHeader}
      {...props}
      inline
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="time"
      dateFormat="MMMM d, yyyy h:mm aa"
    />
  );
};

export default CustomTimeDatePicker;
