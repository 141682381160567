import React from 'react';

import { IntlLabel, IntlDate } from '@plandok/i18n';
import { parseDateFieldWithTime } from '../../../../../helpers/date/field';

export const messageLogColumns = [
  {
    title: <IntlLabel label="table.header.message" />,
    width: '200px',
    key: 'message',
    // render: (value: any) => {
    //   return (
    //     <span className="cursor-pointer text-underline color-link">
    //       {value.message?.substring?.(0, 40)}
    //       {value.message.length > 40 ? '...' : ''}
    //     </span>
    //   );
    // },
  },
  {
    title: <IntlLabel label="Send on" />,
    width: '180px',
    render: (value: any) => {
      const date = parseDateFieldWithTime(value.createdAt);
      return <span>{date && <IntlDate date={date} dateFormat="dd MMM yyyy, HH:mm" />}</span>;
    },
  },
  {
    title: <IntlLabel label="Sent to" />,
    dataIndex: 'sendTo',
    key: 'sendTo',
  },
  {
    title: <IntlLabel label="Channel" />,
    dataIndex: 'Channel',
    key: 'Channel',
  },
  {
    title: <IntlLabel label="Price" />,
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: <IntlLabel label="table.header.status" />,
    key: 'status',
    dataIndex: 'status',
  },
];
