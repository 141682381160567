import React, { ComponentType } from 'react';
import { Avatar, Dropdown } from 'antd';
import cn from 'classnames';
import { getIsMobile, AuthBlock, useAppContext, useModal, Button } from '@plandok/core';
import { IntlLabel, LabelKey } from '@plandok/i18n';
import { PriceType } from 'pages/plans/BillingPage/PricingPage/constants';
import { PermissionLevel, PermissionType } from 'constants/auth';
import { RoutePath } from 'constants/routes';
import { useLocation } from 'hooks/router';
import HeaderUserMenuUsage from './components/HeaderUserMenuUsage';
import HeaderSearchBtn from './components/HeaderSearchBtn';
import HeaderUserMenu from './components/HeaderUserMenu';
import HeaderUsage from './components/HeaderUsage';
import * as SC from './styles';

type AppHeaderProps = {
  mobileHeader: ComponentType;
  headerTitle: LabelKey;
  mobileBackground?: string;
  headerIconTitle?: string;
  planType: string;
  isSubscriptionLoading: boolean;
};

export default function AppHeader({
  mobileHeader: MobileHeader = () => null,
  mobileBackground,
  headerTitle,
  headerIconTitle,
  planType,
  isSubscriptionLoading,
}: AppHeaderProps) {
  const [{ access, displayName }] = useAppContext();
  const { navigate, location } = useLocation();
  const [, { showModal }] = useModal();

  const isMobile = getIsMobile();

  const isDisplayUsage = location.pathname === RoutePath.CALENDAR || location.pathname === RoutePath.BILLING;
  const isPremiumPlan = !isSubscriptionLoading && planType === PriceType.PREMIUM;
  const isFreePlan = !isSubscriptionLoading && planType === PriceType.FREE;
  const isOwner = access?.permissionLevel === PermissionLevel.OWNER;

  const openSelectLanguage = () => showModal({ type: 'LANGUAGE_SELECT' });
  const goToPricingPage = () => navigate(RoutePath.BILLING);
  const onLogout = () => navigate(RoutePath.LOGOUT);

  return (
    <>
      {!isMobile && (
        <SC.Header className="ant-layout-header">
          {headerIconTitle ? (
            <div className="d-flex">
              <SC.Title>
                <IntlLabel label={headerTitle} />
              </SC.Title>
            </div>
          ) : (
            <SC.Title>
              <IntlLabel label={headerTitle} />
            </SC.Title>
          )}

          <SC.UserInfo>
            <AuthBlock section={PermissionType.ACCOUNT_SETTINGS}>
              {isFreePlan && (
                <Button
                  type="default"
                  label="btn.upgrade"
                  data-pricing-link
                  className="mr-4"
                  onClick={goToPricingPage}
                  style={{ background: '#349447' }}
                />
              )}
            </AuthBlock>
            <HeaderSearchBtn />

            {isPremiumPlan && isOwner && isDisplayUsage && (
              <Dropdown overlay={() => <HeaderUserMenuUsage goToPricingPage={goToPricingPage} />} trigger={['click']}>
                <div>
                  <HeaderUsage />
                </div>
              </Dropdown>
            )}

            <Dropdown
              overlay={(headerProps: any) => (
                <HeaderUserMenu {...headerProps} openSelectLanguage={openSelectLanguage} logout={onLogout} />
              )}
              trigger={['click']}
            >
              <Avatar>{displayName?.[0]?.toUpperCase?.() ?? ' '}</Avatar>
            </Dropdown>
          </SC.UserInfo>
        </SC.Header>
      )}
      <SC.MobileHeader
        className={cn('ant-layout-header ant-layout-header-mobile', { 'is-mobile': isMobile })}
        background={mobileBackground}
        isMobile={isMobile}
      >
        <div data-mobile-header-content>
          <MobileHeader />
        </div>
      </SC.MobileHeader>
    </>
  );
}
