import { Divider } from 'antd';
import React from 'react';
import { App_AppointmentsAnalytics } from '../../../../../../graphql/generatedTypes';
import * as StyledCommon from '../../styles';
import { NoData } from '../NoData/NoData';
import * as SC from './styles';
import TotalAppointmentsHeader from './TotalAppointmentsHeader';
import TotalAppointmentsStatus from './TotalAppointmentsStatus';

type TotalAppointmentsStatusProps = {
  appointments: App_AppointmentsAnalytics;
  previousMonthPercent: number;
};

export const TotalAppointments = ({ appointments, previousMonthPercent }: TotalAppointmentsStatusProps) => {
  const appointmentsLength = Object.keys(appointments)?.length;

  return (
    <SC.TotalAppointments>
      <TotalAppointmentsHeader
        totalPercent={appointments.totalAppointments}
        previousMonthPercent={previousMonthPercent}
      />
      <SC.Divider as={Divider} />
      <StyledCommon.ScrollableContent>
        {!!appointmentsLength && <TotalAppointmentsStatus appointments={appointments} />}
      </StyledCommon.ScrollableContent>
      {!appointmentsLength && <NoData />}
    </SC.TotalAppointments>
  );
};

export default SC.TotalAppointments;
