import { gql } from '@apollo/client';

export const CLOSED_DATES = gql`
  query ClosedDatesResponse($after: String, $first: Int, $before: String, $last: Int) {
    closedDates(after: $after, first: $first, before: $before, last: $last) {
      totalCount
      emptyState
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          startDate
          endDate
          description
          locations {
            id
            name
          }
        }
        cursor
      }
    }
  }
`;

export const MASS_MARKETING_CAMPAIGN = gql`
  query massMarketingCampaign($id: Uuid!) {
    massMarketingCampaign(id: $id) {
      id
      scheduledAt
      smsMessage {
        text
        sender
      }
      emailMessage {
        subject
        title
        message
        replyTo
        photo
        ctaName
        ctaUrl
        facebookUrl
        instagramUrl
      }
      clients {
        id
        firstName
        gender
        email
        note
        noteVisibleOnAppointments
      }
    }
  }
`;
