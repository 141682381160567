import React from 'react';
import { AppLayout, MobileBackButton, NoInternetConnection } from 'components';
import { IntlLabel } from '@plandok/i18n';
import cn from 'classnames';
import useQuery from '../../../../hooks/data/useQuery';
import * as api from '../../../../api';
import { Item } from '../../../../types';
import { List } from 'antd';
import { RoutePath } from '../../../../constants/routes';
import { CustomIcon } from '@plandok/core';
import { useLocation } from '../../../../hooks/router';
import { extendedBillingTabs } from '../config';

const MobileBillingMenuPage = () => {
  const { data, isLoading }: any = useQuery({
    fetchData: api.fetchSubscriptionInfo,
  });
  const { navigate } = useLocation();

  return (
    <NoInternetConnection>
      <AppLayout
        mobileHeader={() => (
          <>
            <MobileBackButton />
            <div>
              <IntlLabel label="title.billingPlan" />
            </div>
            <div />
          </>
        )}
        headerTitle="title.billingPlan"
        className={cn('billing-page', { 'billing-page__is-mobile': true })}
        planType={data?.type}
        isSubscriptionLoading={isLoading}
      >
        <List
          itemLayout="horizontal"
          className="staff-menu__list"
          dataSource={extendedBillingTabs}
          renderItem={(item: Item) => (
            <List.Item onClick={() => navigate(item.hasBasePath ? item.path : `${RoutePath.BILLING}#${item.path}`)}>
              <List.Item.Meta
                avatar={<CustomIcon type={item.icon} />}
                title={<IntlLabel label={item.title} />}
                description={<IntlLabel label={item.description} />}
              />
              <div>
                <CustomIcon type="arrowForward" />
              </div>
            </List.Item>
          )}
        />
      </AppLayout>
    </NoInternetConnection>
  );
};

export default MobileBillingMenuPage;
