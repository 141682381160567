import { ApolloClient, InMemoryCache, createHttpLink, MutationOptions, QueryOptions } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import * as query from './queries/queries';
import * as mutation from './mutations/mutations';
import * as helpers from './helpers';
import { onError } from 'apollo-link-error';
import { message as antMessage } from 'antd';
import { relayStylePagination } from '@apollo/client/utilities';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql/app`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('app-token');

  // get unauthorized error state and call refresh token again
  onError(({ graphQLErrors, networkError, operation, forward }: any) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (!err) return null;
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            // error code is set to UNAUTHENTICATED
            // when AuthenticationError thrown in resolver

            // modify the operation context with a new token
            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: token ? `Bearer ${token}` : '',
              },
            });
            // retry the request, returning the new observable
            return forward(operation);
        }
      }
    }
    if (networkError) {
      antMessage.error(`[Network error]: ${networkError}`);
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // apollo-link-retry
    }
  });

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const graphqlClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          reports: relayStylePagination(),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  },
});

/**
 * Function execute mutation without hooks
 * @example  mutate(mutation.UPDATE_CLOSED_DATE)(formValues)
 */
const mutate =
  <TVariables = any>(mut: MutationOptions['mutation']) =>
  (input?: TVariables, options?: Omit<MutationOptions<any, TVariables>, 'mutation'>) =>
    graphqlClient
      .mutate({ ...options, mutation: mut, variables: { input }, ...options })
      .then(helpers.mapMutationResponseResult)
      .catch(helpers.mapMutationErrorResponse);

/**
 * Function execute query without hooks
 * @example  query(queryCall.CLOSED_DATES)
 */
const queryCall =
  <TVariables = any>(query: QueryOptions['query'], variables?: TVariables, dataKey?: string) =>
  async () => {
    const response = await graphqlClient.query({ query, variables });
    try {
      return dataKey ? response.data[dataKey] : response.data;
    } catch (error) {
      antMessage.error('Failed to perform action');
      return null as any;
    }
  };

export { query, graphqlClient, mutate, queryCall, mutation };
export * from './types';
