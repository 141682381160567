import { PermissionType } from 'constants/auth';
import useHasPermission from 'hooks/permission/useHasPermission';
import React from 'react';

import { Menu } from 'antd';
import { IntlLabel } from '@plandok/i18n';

export default function ClientMoreOptionMenu(props: { edit(): any; delete(): any }) {
  const hasPermission = useHasPermission(PermissionType.CAN_SEE_CLIENT_INFO);

  return (
    <Menu className="app-common-dropdown" {...props}>
      <Menu.Item onClick={props.edit} disabled={!hasPermission}>
        <IntlLabel label="menu.edit.client" />
      </Menu.Item>
      <Menu.Item onClick={props.delete}>
        <IntlLabel label="menu.delete.client" />
      </Menu.Item>
    </Menu>
  );
}
