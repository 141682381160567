import ReactForm from './field/Form';
import FormInput from './field/FormInput';
import PreviewInput from './field/PreviewInput';
import FormSelect from './field/FormSelect';
import FormDatepicker from './field/FormDatepicker';
import FormCheckbox from './field/FormCheckbox';
import FormTextArea from './field/FormTextArea';
import FormTextAreaWithTags from './field/FormTextAreaWithTags';
import PreviewArea from './field/PreviewArea';
import FormSwitch from './field/FormSwitch/FormSwitch';
import FormCombinedInput from './field/FormCombinedInput/FormCombinedInput';
import FormSocialInput from './field/FormSocialInput/FormSocialInput';
import { withField } from './support';
import FieldRow from './support/FieldRow';
import FormColorSelect from './field/FormColorSelect';
import FormAsyncSelect from './field/FormAsyncSelect';
import EmailPreviewInput from './field/EmailPreviewInput';
import EmailPreviewArea from './field/EmailPreviewArea';
import EmailPreviewInputBtn from './field/EmailPreviewInputBtn';
import CustomTimeInput from './field/CustomTimeInput';
import FormCheckGroup from './field/FormCheckGroup';
import PictureEditInput from './field/PictureEditInput';

export const Form = ReactForm;

export class Field {
  static Input = withField(FormInput);
  static Select = withField(FormSelect);
  static AsyncSelect = withField(FormAsyncSelect);
  static Date = withField(FormDatepicker);
  static Checkbox = withField(FormCheckbox);
  static CheckboxGroup = withField(FormCheckGroup);
  static TextArea = withField(FormTextArea);
  static TextAreaWithTags = withField(FormTextAreaWithTags);
  static Switch = withField(FormSwitch);
  static ColorSelect = withField(FormColorSelect);
  static CombinedInput = withField(FormCombinedInput);
  static SocialInput = withField(FormSocialInput);
  static PreviewBtn = withField(EmailPreviewInputBtn);
  static Row = FieldRow;
  static PreviewInput = withField(PreviewInput);
  static PreviewArea = withField(PreviewArea);
  static EmailPreInput = withField(EmailPreviewInput);
  static EmailPreArea = withField(EmailPreviewArea);
  static CustomInput = withField(CustomTimeInput);
  static PictureInput = withField(PictureEditInput);
}
