import styled, { css } from 'styled-components';
import { mediaMobileDown } from '@plandok/core';

export const CampaignSetupContainer = styled.div`
  margin-top: 10px;
  .ant-tabs-nav-wrap {
    text-align: center;
  }
  ${mediaMobileDown(css`
    margin-top: 100px;
    width: 355px;
  `)}
`;
