import { LocalStorage } from '@plandok/core';
import { getLabelByIsoCode } from './';
import { Language } from '../languages';

export const getLanguageFromUrl = () => {
  const prefix = window.location.pathname.substr(1, 2);
  return Object.values(Language).find((e) => e === prefix) || Language.ENG;
};

export const getInitialLanguage = () => {
  const appContext = LocalStorage.getAppContext();
  if (appContext) {
    return getLabelByIsoCode(appContext?.languageIsoCode);
  }
  return getLanguageFromUrl();
};

export const formatDurationWithTranslations = (h: string, min: string) => (duration: number) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  if (!hours && !minutes) {
    return `0${min}`;
  }
  return (hours ? `${hours}${h}` : '').concat(minutes ? ` ${minutes}${min}` : '');
};
