/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Avatar, Icon, Row } from 'antd';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, useLocation, useModal } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { PriceType } from 'pages/plans/BillingPage/PricingPage/constants';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import useCheckUpdateApp from 'hooks/use-check-update-app';
import { NotReleased, SidebarIcons } from 'components';
import { sidebarConfig } from 'config/sidebar-config';
import { useProfile } from 'hooks/info/useGetProfile';
import { RoutePath } from 'constants/routes';
import MobileSupportBtn from './MobileSupportBtn';
import * as SC from './styles';

export default function MobileMenu() {
  const { isUpdated, isHiddenNewVersionNotification } = useCheckUpdateApp();
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const [, { showModal }] = useModal();
  const { navigate } = useLocation();
  const displayName = useProfile();

  const isFreePlan = !isSubscriptionLoading && planType === PriceType.FREE;

  const changeLanguage = () => showModal({ type: 'LANGUAGE_SELECT' });
  const onLogout = () => navigate(RoutePath.LOGOUT);
  const goToCalendarPage = () => {
    localStorage.setItem('isHiddenNewVersionNotification1', 'false');
    navigate(RoutePath.CALENDAR);
  };

  return (
    <SC.MobileMenuContainer>
      <SC.Header>
        <Link to={RoutePath.SEARCH}>
          <Icon type="search" />
        </Link>
      </SC.Header>
      <Row>
        <SC.ManageUser as={Link} to={RoutePath.PERSONAL_SETTINGS}>
          <div data-user-avatar>
            <Avatar size="large" icon="user" />
          </div>
          <div data-manage-user-info>
            <div data-manage-user-title>{displayName}</div>
            <div>
              <IntlLabel label="text.manageProfile" />
            </div>
          </div>
          <Icon type="right" data-menu-right-icon />
        </SC.ManageUser>
        {sidebarConfig.map((sidebarItem) => (
          <Fragment key={sidebarItem.title}>
            {sidebarItem.notReleased ? (
              <NotReleased>
                <SC.Item>
                  <Link to={sidebarItem?.mobilePath ?? sidebarItem.path}>
                    <i>{(SidebarIcons as any)[sidebarItem.iconType as any]}</i>
                    <IntlLabel label={sidebarItem.title} />
                    <Icon type="right" />
                  </Link>
                </SC.Item>
              </NotReleased>
            ) : (
              <SC.Item>
                <Link to={sidebarItem?.mobilePath ?? sidebarItem.path}>
                  <i>{(SidebarIcons as any)[sidebarItem.iconType as any]}</i>
                  <IntlLabel label={sidebarItem.title} />
                  <Icon type="right" />
                </Link>
              </SC.Item>
            )}
          </Fragment>
        ))}
        <MobileSupportBtn />
        <SC.SpaceItem>
          {isFreePlan && (
            <Button
              type="default"
              label="btn.upgrade"
              data-pricing-link
              className="mr-4"
              onClick={() => navigate(RoutePath.BILLING)}
            />
          )}
        </SC.SpaceItem>
        <SC.Item onClick={changeLanguage}>
          <a href="javascript:void(0)">
            <Icon type="global" />
            <IntlLabel label="sidebar.language" />
            <Icon type="right" />
          </a>
        </SC.Item>
        <SC.Item>
          <a href="javascript:void(0)" onClick={onLogout}>
            <Icon type="logout" />
            <IntlLabel label="sidebar.logout" />
            <Icon type="right" />
          </a>
        </SC.Item>
        {!isUpdated && isHiddenNewVersionNotification && (
          <SC.UpdateContainer onClick={goToCalendarPage}>
            <Button lightBtn label="app.updateAvailable.button.title" upperCase={false} semiBold />
          </SC.UpdateContainer>
        )}
      </Row>
    </SC.MobileMenuContainer>
  );
}
