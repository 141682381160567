import { Spin, message } from 'antd';
import React from 'react';
import { useAppContext } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { AppLayout, NoInternetConnection } from 'components';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import withApiData from 'hocs/form/withApiData';
import withFormApi from 'hocs/form/withFormApi';
import * as api from 'api';
import CompanyMobileHeader from './components/CompanyMobileHeader';
import CompanyDetailsForm from './components/CompanyDetailsForm';

const CompanyDetailsFormWithApi = withFormApi({
  createAction: api.updateCompanyDetails,
  successMessage: 'Updated.',
})(CompanyDetailsForm);

function CompanyDetailsPage({ data, isLoading }: any) {
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();
  const [, { updateCompanyDetails }] = useAppContext();

  return (
    <NoInternetConnection>
      <AppLayout
        headerTitle="setup.page.title"
        mobileHeader={CompanyMobileHeader}
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <AppLayout.Container maxWidth="680px">
          {data ? (
            <CompanyDetailsFormWithApi
              initialValues={data}
              onSuccess={(_: any, _a: any, form: any) => {
                if (form?.timeZone && form?.countryIsoCode) {
                  updateCompanyDetails({ timeZone: form.timeZone, country: form.countryIsoCode });
                  message.success(<IntlLabel label={'notification.success.update'} />);
                }
              }}
            />
          ) : (
            <Spin spinning={isLoading}>
              <CompanyDetailsForm />
            </Spin>
          )}
        </AppLayout.Container>
      </AppLayout>
    </NoInternetConnection>
  );
}

export default withApiData(api.fetchCompanyDetails, { exactData: true })(CompanyDetailsPage);
