import cn from 'classnames';
import { Col } from 'antd';
import { IntlLabel } from '@plandok/i18n';
import SelectColor from '../input/SelectColor';
import { extractFieldError } from '@plandok/core';
import React from 'react';

const FormColorSelect = ({ input, meta, submitting, label }: any) => {
  const error = extractFieldError(meta, submitting);

  return (
    <Col md={24} className={cn({ 'has-error': !!error })}>
      <SelectColor onChange={input.onChange} value={input.value} label={label} />
      {error && (
        <div className="ant-form-explain">
          <IntlLabel label={error} />
        </div>
      )}
    </Col>
  );
};

export default FormColorSelect;
