import styled, { css } from 'styled-components';
import { mediaMobileDown } from '@plandok/core';

export const BtnContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  button {
    width: 100%;
    max-width: 320px;
  }

  ${mediaMobileDown(css`
    margin-top: 20px;
  `)}
`;
