import styled from 'styled-components';

export const Button = styled.button<any>`
  height: ${(p) => p.size || '28px'};
  width: ${(p) => p.size || '28px'};
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background: transparent;
  border: none;
  position: relative;
  z-index: 1;

  svg {
    position: absolute; /* Fix for https://gitlab.com/plandok/plandok-app/-/issues/449 */
  }

  &:hover,
  &:focus,
  &:active {
    background: ${(p) => p.color || '#e9ecf3'};
  }
`;
