import styled from 'styled-components';
import { Icon } from 'antd';
import React from 'react';
import { IntlDate, IntlLabel, IntlTemplate } from '@plandok/i18n';
import { CustomIcon, Text } from '@plandok/core';

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 2.4rem 1fr 1rem;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  padding: 1.8rem 0 0;

  svg:first-child {
    height: 1.4375rem;
    width: 1.5625rem;
  }
  svg:last-child {
    height: 0.75rem;
    width: 0.75rem;
  }
  .repeat-selected {
    grid-column: 1/4;
    padding-left: 39px;
  }
`;

type AppointmentRepeatMobileProps = {
  frequency: string | number | undefined;
  repeatingValue: string;
  until?: Date;
  onClick: () => void;
};

const AppointmentRepeatMobile = ({ until, repeatingValue, frequency, onClick }: AppointmentRepeatMobileProps) => (
  <StyledContainer onClick={onClick}>
    <CustomIcon type="repeatMobile" />
    <Text size="base" colorType="base" weight="medium" mb="none">
      <IntlLabel label="rp.repeatAppointment.title" />
    </Text>
    <Icon type="down" />
    <Text className="repeat-selected" size="base" colorType="grey" weight="normal" mb="none">
      {frequency ? (
        <Text size="small" colorType="base" weight="normal" mb="none">
          <IntlLabel label="rp.repeats.title" /> <IntlLabel label={frequency} />{' '}
          <IntlLabel label="rp.repeatsUntil.title" />
          &nbsp;
          <IntlDate date={until} dateFormat="EEE, dd MMM yyyy" />
          &nbsp;
          {parseInt(repeatingValue) <= 30 && <IntlTemplate label="rp.repeatingTimes.title" params={[repeatingValue]} />}
        </Text>
      ) : (
        <IntlLabel label="dropdown.time.appointment.doesntRepeat" />
      )}
    </Text>
  </StyledContainer>
);

export default AppointmentRepeatMobile;
