import { ChangeEvent, RefObject } from 'react';
import { LabelKey } from '@plandok/i18n';

export const validateSmsSenderId = (val: any): LabelKey | undefined => {
  if (!val) {
    return undefined;
  }
  // eslint-disable-next-line no-useless-escape
  return val?.toString()?.match(/^[\.\sa-zA-Z0-9]{3,11}$/) ? undefined : 'error.wrongSenderId';
};

export const validateTime = (reminderTimes: number[]) => {
  return new Set(reminderTimes).size === reminderTimes.length;
};

const insertHTML = (html: string) => {
  const selection = window.getSelection();
  const range = selection?.getRangeAt(0);
  const insertion = document.createRange().createContextualFragment(html);

  range?.deleteContents();
  range?.insertNode(insertion);
};

export const onCheckChange = (e: ChangeEvent<HTMLInputElement>, values: any) =>
  (values.channels = values.channels.includes(e.target.value)
    ? values.channels.filter((channel: string) => channel !== e.target.value)
    : [...values.channels, e.target.value]);

export const onTagSelected = (
  values: any,
  setRerender: (value: number) => void,
  customTextAreaForEmail: RefObject<HTMLDivElement>,
  customTextAreaForSms: RefObject<HTMLDivElement>,
  customTextAreaForWhatsApp: RefObject<HTMLDivElement>
) => {
  const applyTagToTemplate = (customTextArea: RefObject<HTMLDivElement>, template: string, tag: string) => {
    customTextArea.current?.focus();
    insertHTML(` ${tag} `);
    values[template] = customTextArea.current?.innerText;
    setRerender(Date.now());
  };

  const applyCreatedTag = (customTextArea: RefObject<HTMLDivElement>) => (template: string, tag: string) =>
    applyTagToTemplate(customTextArea, template, tag);

  const onTagSelectedForEmail = applyCreatedTag(customTextAreaForEmail);
  const onTagSelectedForSms = applyCreatedTag(customTextAreaForSms);
  const onTagSelectedForWhatsApp = applyCreatedTag(customTextAreaForWhatsApp);

  return {
    onTagSelectedForEmail,
    onTagSelectedForSms,
    onTagSelectedForWhatsApp,
  };
};
