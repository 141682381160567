import styled from 'styled-components';
import { Collapse } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-size: 20px;
`;

export const Description = styled.div`
  font-size: 14px;
  color: #adb6cc;
  margin-bottom: 5px;
`;

export const Divider = styled.div`
  margin: 5px 0 !important;
`;

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 250px;
  min-height: 250px;
  max-height: 250px;
`;

export const SelectItem = styled.div<any>`
  padding: 10px;
  :hover {
    border-radius: 6px;
    background: #e9ecf3;
  }
  span {
    font-weight: ${(props) => (props.bold ? '500' : 'initial')};
    font-size: 18px;
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const WrapperCheckbox = styled.div<any>`
  padding: 8px 20px;
  
  .ant-checkbox-wrapper {
    display: flex;

    .ant-checkbox {
      display: flex;
      align-items: center;

      :after {
        border: none;
      }
    }
  }

  :hover {
    border-radius: 2px;
    background: #e9ecf3;
  }

  span.ant-checkbox.ant-checkbox-indeterminate {
    span.ant-checkbox-inner {
      &:after {
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
      }
    }
  }
  
  span.ant-checkbox-inner {
    background: #ffffff;
    border: 2px solid #13316d;
    border-radius: 3px;
    width: 24px;
    height: 24px;
  
    &:after {
      left: 17%;
      top: 48%;
      width: 10px;
      height: 15px;
    }
  }
  
  .ant-checkbox + span {
    padding: 0;
    margin-left: 16px;
    font-size: 18px;
    line-height: 21px;
    color: #13316d;
`;

export const StyledCollapse = styled(Collapse)`
  background-color: #ffffff;
  margin-top: 15px;

  .ant-collapse-item {
    border: none;
  }
`;

export const StyledPanel = styled(Collapse.Panel)`
  .ant-collapse-header {
    border-left: 4px solid ${({ theme }) => theme};
    border-radius: 2px;
    background: #f8f9fc;
    padding: 10px 16px !important;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px !important;
    color: #13316d !important;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    svg {
      margin-top: 8px;
    }
  }

  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 8px 0px 24px !important;
    }
  }
`;
