import { flow, noop } from 'lodash';
import { Divider } from 'antd';
import React from 'react';
import { addedClientdateOption, withModal, validateRequired, Form, Text, Field, Button } from '@plandok/core';
import { addedClientViewTypeOptions } from 'constants/data';
import { NotReleased } from 'components';
import * as api from 'api';
import * as SC from '../styles';

function CreateNewClientModal(props: any) {
  const { groupName, updateGender } = props.modalProps;

  const newClient = () => {
    updateGender(groupName);
    props.onClose();
  };

  return (
    <NotReleased>
      <Form
        onSubmit={(form: any) => api.closeAppointment(props.modalProps.Number, form)}
        onSuccess={flow(props.onClose, props.modalProps?.onSuccess || noop)}
      >
        {({ submitting }: any) => {
          return (
            <SC.StyledContainer>
              <Text label="Clients added in the last:" size="base" mb="none" />
              <Field.Row className="time-row">
                <Field.Select
                  name="addedDate"
                  options={addedClientdateOption}
                  md={10}
                  validate={validateRequired}
                  defaultValue={30}
                />
                <Field.Select
                  name="defaultView"
                  options={addedClientViewTypeOptions}
                  validate={validateRequired}
                  translate
                  md={14}
                  defaultValue="days"
                />
              </Field.Row>
              <SC.Divider as={Divider} />
              <Button type="primary" label="btn.save" onClick={newClient} loading={submitting} upperCase={false} />
            </SC.StyledContainer>
          );
        }}
      </Form>
    </NotReleased>
  );
}

export default withModal('New Client', { contentClassName: 'age-modal-content' })(CreateNewClientModal);
