import React from 'react';

import * as SC from '../../styles';

export default function ResizeHandle({ isLoading, ...props }: any) {
  return (
    <SC.ResizeToggle {...props}>
      <div />
    </SC.ResizeToggle>
  );
}
