import React, { useContext } from 'react';
import * as api from 'api';
import { getLabel, ReactIntl } from '@plandok/i18n';
import { ModifyLayout, NoInternetConnection } from 'components';
import { formatDateField } from 'helpers/date/field';
import withFormApi from 'hocs/form/withFormApi';
import { RoutePath } from 'constants/routes';
import { useLocation } from 'hooks/router';
import { useStore } from 'store/store';
import AppointmentForm from './components/AppointmentForm';
import { detailsAppointmentSelect } from './helpers';

export default function ModifyAppointmentPage() {
  const { location, params: urlParams } = useLocation();
  const { lang } = useContext(ReactIntl);

  const isEdit = !!(urlParams as any)?.id;
  const params = new URLSearchParams(location.search || '');
  const startTime = params?.get('startTime');
  const employeeId = params?.get('employeeId') || '';
  const locationId = params?.get('locationId');
  const date = params?.get('date');

  const setRescheduleItemDate = useStore((state) => state.setRescheduleItemDate);
  const setRescheduleItemTime = useStore((state) => state.setRescheduleItemTime);
  const setRescheduleItemStaff = useStore((state) => state.setRescheduleItemStaff);

  const rescheduleItemDate = useStore((state) => state.rescheduleItemDate);
  const rescheduleItemTime = useStore((state) => state.rescheduleItemTime);
  const rescheduleItemStaff = useStore((state) => state.rescheduleItemStaff);

  const AppointmentFormWithApi = withFormApi({
    detailsAction: api.fetchAppointmentDetails,
    createAction: api.createAppointment,
    editAction: api.updateAppointment,
    detailsSelect: detailsAppointmentSelect,
    backPath: RoutePath.CALENDAR,
    successMessage: getLabel(`appointment.${isEdit ? 'update' : 'create'}.title`, lang),
    rescheduleItemDate,
    rescheduleItemTime,
    rescheduleItemStaff,
    setRescheduleItemDate,
    setRescheduleItemTime,
    setRescheduleItemStaff,
  })(AppointmentForm);

  return (
    <NoInternetConnection>
      <ModifyLayout title={isEdit ? 'appointment.edit.title' : 'appointment.new.title'}>
        <AppointmentFormWithApi
          defaultEmployeeId={employeeId}
          initialValues={{
            date: formatDateField(new Date(date ? parseInt(date, 10) : new Date())),
            locationId,
            bookings: [
              {
                startTime: startTime ? parseInt(startTime, 10) : undefined,
                employeeId,
              },
            ],
          }}
        />
      </ModifyLayout>
    </NoInternetConnection>
  );
}
