import { formatTime } from '@plandok/core';

export const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  if (!hours && !minutes) {
    return '0min';
  }

  return (hours ? `${hours}h` : '').concat(minutes ? ` ${minutes}min` : '');
};

export const formatStartTimeAndDuration = (startTime: number, duration: number, timeFormat: string) =>
  `${formatTime(startTime, timeFormat)} - ${formatTime(startTime + duration, timeFormat)}`;
