import { Icon } from 'antd';
import { IntlDate } from '@plandok/i18n';
import React from 'react';

export default function DatepickerHeader(props: any) {
  return (
    <>
      <button
        type="button"
        className="react-datepicker__navigation react-datepicker__navigation--previous"
        onClick={props.decreaseMonth}
      >
        <Icon type="left" />
      </button>
      <button
        type="button"
        className="react-datepicker__navigation react-datepicker__navigation--next"
        onClick={props.increaseMonth}
      >
        <Icon type="right" />
      </button>
      <div className="react-datepicker__header">
        <div className="react-datepicker__current-month">
          <IntlDate date={props.date} dateFormat="MMMM yyyy" />
        </div>
        <div className="react-datepicker__header__dropdown react-datepicker__header__dropdown--scroll" />
      </div>
    </>
  );
}
