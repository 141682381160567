import React, { useCallback } from 'react';
import styled from 'styled-components';
import { StickyTable } from 'components';

const Cell = styled(StickyTable.Cell)`
  padding: 5px 10px 5px 5px;
  border-right: 1px solid #e9ecf3;
  cursor: pointer;

  :hover {
    background: #6ce1f71a;
  }
` as any;

const WorkItem = styled.div`
  background-color: #b4dff2;
  padding: 10px;
  border-radius: 2px;
  &:not(:first-of-type) {
    margin-top: 5px;
  }
`;

export default function WorkStaffCell({ createStaffWorkHours, index, shiftsInfo, editWorkTime }: any) {
  const createWorkTime = useCallback(() => {
    if (shiftsInfo?.element) {
      editWorkTime(shiftsInfo?.element, index);
    } else {
      createStaffWorkHours(index);
    }
  }, [createStaffWorkHours, shiftsInfo, index, editWorkTime]);

  return (
    <Cell data-staff-hour-cell data-staff-hour-cell-item className="br-1" onClick={createWorkTime}>
      {shiftsInfo?.shifts?.map((e: any) => <WorkItem key={e}>{e}</WorkItem>) || []}
    </Cell>
  );
}
