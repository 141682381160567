import styled, { css } from 'styled-components';
import React, { memo } from 'react';
import { Text, Button, useLocation, getIsMobile, mediaMdDown } from '@plandok/core';
import { BtnDismiss, BtnsWrapper, Container } from './PaymentErrorNotification';
import { RoutePath } from 'constants/routes';

const BtnActivate = styled(Button)`
  margin-left: 33px;
  height: 36px !important;
  line-height: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background: #349447;
  padding: 10px 36px;

  ${mediaMdDown(css`
    margin: 0;
  `)};
`;

type PremiumDeactivatedNotificationProps = {
  type?: string;
  setIsDisplayPremiumDeactivatedAlert: (isDisplayAlert: boolean) => void;
};

export default memo(function PremiumDeactivatedNotification({
  type,
  setIsDisplayPremiumDeactivatedAlert,
}: PremiumDeactivatedNotificationProps) {
  const { navigate } = useLocation();
  const isMobile = getIsMobile();

  const goToBillingPage = () => navigate(RoutePath.BILLING);
  const dismiss = () => {
    sessionStorage.setItem('isPremiumDeactivatedDismiss', 'true');
    setIsDisplayPremiumDeactivatedAlert(false);
  };

  return (
    <Container>
      <div className="align-center">
        <Text
          weight="semiBold"
          size={isMobile ? 'mlarge' : 'base'}
          mb={isMobile ? 'small' : 'none'}
          label="plan.deactivated.title"
          lh={isMobile ? 'xxlarge' : 'small'}
        />

        <Text
          size="small"
          mb={isMobile ? 'small' : 'none'}
          ml={isMobile ? 'none' : 'large'}
          style={{ maxWidth: '574px' }}
          lh="large"
          label={type === 'subscription_cancelled' ? 'subscriptionCancelled.title' : 'notificationCancelled.title'}
        />

        <BtnsWrapper>
          <BtnActivate label="plan.active.title" onClick={goToBillingPage} upperCase={false} semiBold />
          <BtnDismiss label="payment.dismiss.title" onClick={dismiss} upperCase={false} whiteBtn semiBold />
        </BtnsWrapper>
      </div>
    </Container>
  );
});
