import React from 'react';

import { IntlDate, IntlLabel } from '@plandok/i18n';
import { Icon } from 'antd';
import { monthOptions } from '@plandok/core';
import { getMonth } from 'date-fns';

export default function DatepickerHeader(props: any) {
  const translatedMonth = monthOptions.find(({ code }) => code === getMonth(props.date))?.name;

  return (
    <>
      <button
        type="button"
        className="react-datepicker__navigation react-datepicker__navigation--previous"
        onClick={props.decreaseMonth}
      >
        <Icon type="left" />
      </button>
      <button
        type="button"
        className="react-datepicker__navigation react-datepicker__navigation--next"
        onClick={props.increaseMonth}
      >
        <Icon type="right" />
      </button>
      <div className="react-datepicker__header">
        <div className="react-datepicker__current-month">
          <IntlLabel label={translatedMonth} />
          <IntlDate date={props.date} dateFormat=" yyyy" />
        </div>
        <div className="react-datepicker__header__dropdown react-datepicker__header__dropdown--scroll" />
      </div>
    </>
  );
}
