import { Spin } from 'antd';
import React from 'react';
import { AppLayout, NoInternetConnection } from 'components';
import useGetCurrentPlan from 'hooks/use-get-current-plan';
import withApiData from 'hocs/form/withApiData';
import withFormApi from 'hocs/form/withFormApi';
import * as api from 'api';
import CalendarSettingsMobileHeader from './components/CalendarSettingsMobileHeader';
import CalendarSettingsForm from './components/CalendarSettingsForm';
import calendarState from '../CalendarPage/store/calendar.state';

const CalendarSettingsFormWithApi = withFormApi({
  createAction: api.updateCalendarConfig,
  successMessage: 'Updated Calendar Config',
})(CalendarSettingsForm);

function CalendarSettingsPage({ data, isLoading }: any) {
  const { isSubscriptionLoading, planType } = useGetCurrentPlan();

  return (
    <NoInternetConnection>
      <AppLayout
        headerTitle="setup.page.title"
        mobileHeader={CalendarSettingsMobileHeader}
        isSubscriptionLoading={isSubscriptionLoading}
        planType={planType}
      >
        <AppLayout.Container maxWidth="680px">
          <Spin spinning={isLoading}>
            {data ? (
              // TODO: This is temporary fix with `excludeList`. Find proper solution
              <CalendarSettingsFormWithApi
                initialValues={data}
                onSuccess={() => calendarState.resetInitialization()}
                excludeList={['customViewTime']}
              />
            ) : (
              <CalendarSettingsForm />
            )}
          </Spin>
        </AppLayout.Container>
      </AppLayout>
    </NoInternetConnection>
  );
}

export default withApiData(api.fetchCalendarConfig, { exactData: true })(CalendarSettingsPage);
