import styled from 'styled-components';

export const Description = styled.div`
  color: #848fab;
`;

export const SwitchText = styled.div`
  margin-left: 10px;
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
`;
