import React, { useEffect, useState } from 'react';
import { message, Spin } from 'antd';
import pick from 'lodash/pick';
import { useLocation } from '@plandok/core';
import { IntlLabel } from '@plandok/i18n';
import { useBookingLocationQuery } from 'graphql/queries/bookingLocation.generated';
import { NoInternetConnection } from 'components';
import { RoutePath } from 'constants/routes';
import WorkingHoursStep, { weekDays } from './components/steps/WorkingHoursStep';
import ProfileLocationStep from './components/steps/ProfileLocationStep';
import StaffWorkingPage from '../StaffPage/StaffWorkingPage';
import BookingFlowStep from './components/BookingFlowStep';
import PhotosStep from './components/steps/PhotosStep';
import { convertData, uploadFiles } from './service';
import { mutation, mutate } from '../../../graphql';
import { CreateBookingStepType } from './types';
import * as SC from './styles';
import './styles.less';

const steps: CreateBookingStepType[] = [
  {
    title: 'ob.profile.step.title',
    description: 'ob.profile.step.description',
    component: ProfileLocationStep,
    update: (form: any) => {
      const formatSlug = form.slug.toLowerCase();
      const formCopy = { ...form, slug: formatSlug };

      mutate(mutation.UPDATE_BOOKING_BUSINESS_DETAILS)(pick(formCopy, 'id', 'name', 'slug', 'phone'));

      return mutate(mutation.UPDATE_BOOKING_ADDRESS)(
        pick(form, 'id', 'city', 'state', 'address', 'zipCode', 'addressDetails')
      );
    },
  },
  {
    title: 'ob.staffHours.step.title',
    description: 'ob.staffHours.step.description',
    component: StaffWorkingPage,
    update: () => {},
  },
  {
    title: 'ob.hours.step.title',
    description: 'ob.hours.step.description',
    component: WorkingHoursStep,
    isSmallForm: true,
    update: (form: any) => {
      const data: any = pick(form, 'id');

      data.workingHours = weekDays.reduce((acc, { name: k, ...d }: any) => {
        const wh = form?.workingHours?.[k];
        return {
          ...acc,
          [k]: {
            ...pick(wh, 'opensAt', 'closesAt'),
            open: wh?.open || false,
          },
        };
      }, {});

      return mutate(mutation.UPDATE_BOOKING_WORKING_HOURS)(data);
    },
  },
  {
    title: 'ob.photo.step.title',
    description: 'ob.photo.step.description',
    component: PhotosStep,
    update: (form: any) => uploadFiles(form.id, form.photos || []),
  },
];

export default function CreateBookingFlow() {
  const [step, setStep] = useState(0);
  const [initialValues, setInitialValues] = useState<any>();
  const [formErrors, setFormErrors] = useState({} as any);
  const { goBack, params, navigate } = useLocation<any>();

  const { data, error, loading } = useBookingLocationQuery({
    variables: { locationId: params?.locationId },
  });

  useEffect(() => {
    if (data && !initialValues) {
      convertData(data).then((res) => setInitialValues(res));
    }
  }, [data, initialValues]);

  const onClose = () => goBack();

  const onNext = async (form: any) => {
    const result = await steps[step].update!(form);
    const { errors } = result ?? {};

    if (errors) {
      setFormErrors(errors);
    }
    return result;
  };

  const onSuccess = () => {
    const finalStep = step === 3;

    if (finalStep) {
      message.success(<IntlLabel label="message.successUpdateLocation" />);
      return navigate(`${RoutePath.BOOKING}#profile`);
    }
    setStep(step + 1);
  };

  const onBack = () => {
    if (!step) {
      return goBack();
    }
    setStep(step - 1);
  };

  const stepConfig = steps[step];
  const nextStepTitle = steps[step + 1]?.title;

  return (
    <NoInternetConnection>
      <SC.Container className="create-booking-page">
        <Spin spinning={loading}>
          <BookingFlowStep
            initialValues={initialValues || {}}
            step={step}
            stepConfig={stepConfig}
            onBack={onBack}
            onNext={onNext}
            onSuccess={onSuccess}
            onClose={onClose}
            nextStepTitle={nextStepTitle}
            error={error?.message}
            formErrors={formErrors}
          />
        </Spin>
      </SC.Container>
    </NoInternetConnection>
  );
}
