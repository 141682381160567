import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { RoutePath } from 'constants/routes';

const PrivateRoute = ({ component: Component, isAuthenticated, isLoading, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : isLoading ? (
          () => <div>LOADING</div>
        ) : (
          <Redirect to={{ pathname: RoutePath.LOGIN, state: props.location }} />
        )
      }
    />
  );
};

export default PrivateRoute;
