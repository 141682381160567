import styled from 'styled-components';

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryColor};
  width: 296px;
  font-size: 14px;
`;

interface FlexProps {
  flexDirection?: 'row' | 'column';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  gap?: string;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  gap: ${({ gap }) => gap || 'unset'};
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const CardHeader = styled(Column)`
  display: flex;
  padding-bottom: 6px;
  border-bottom: 1px solid #e9ecf3;
`;

export const Separator = styled.div`
  flex: 1;
  height: 1px;
  margin-bottom: 8px;
  border-bottom: 1px solid #e9ecf3;
`;

export const UserInfo = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  div:first-of-type {
    font-weight: 500;
  }
  div:last-of-type {
    color: #adb6cc;
  }
`;

export const UserNotes = styled.div`
  padding-top: 12px;
  display: flex;
  text-wrap: wrap;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  > div {
    flex-direction: column;
    margin: 2px;
  }
  > div:last-of-type {
    display: flex;
    align-items: flex-end;
  }
`;

export const StatusPadding = styled.div`
  padding: 14px 0 8px;
`;
